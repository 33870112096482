
import React from 'react';
import { InputField } from '@jutro/components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniFormat } from 'wni-portals-util-js';
import { WNIAddressLookupService } from 'wni-capability-address';

const LookupZipcodeComponent = (props) => {
    const {
        id,
        label,
        labelPosition,
        path: lookupPath,
        value,
        className,
        required,
        placeholder,
        disabled,
        onSelection,
        updateShowLoader,
        writeValue,
        showErrors,
        readOnly,
        validationMessages,
        autoFilled,
        useAuthenticationData,
        chosenCountry
    } = props;
    const useAuth = useAuthentication();
    const authData = useAuthenticationData || useAuth;
    const { authHeader } = authData;
    const formatValue = chosenCountry === 'CA' ? WniFormat.zipCodeFormatForCanada(value) : WniFormat.zipcodeFormat(value);

    const lookupServiceFn = (val) => {
        updateShowLoader(false);
        if (chosenCountry === 'CA') {
            WNIAddressLookupService.lookupAddressUsingZipCodeAndCountry(
                val, chosenCountry, authHeader
            ).then((addressList) => {
                updateShowLoader(true);
                onSelection(addressList);
            }).catch(() => {
                updateShowLoader(true);
            });
        }else {
            WNIAddressLookupService.lookupAddressUsingZipCode(
                val, authHeader
            ).then((addressList) => {
                updateShowLoader(true);
                onSelection(addressList);
            }).catch(() => {
                updateShowLoader(true);
            });
        }
        
    };


    const getAddressFromZipCode = (target, valueObject) => {
        const isValueChanged = WniFormat.isValueChanged(valueObject); // after onBlur, if value change or not
        if (!isValueChanged) {
            return false;
        }
        if (!formatValue || !autoFilled) {
            return false;
        }
        if (!WniFormat.isZipCodeMatchPattern(chosenCountry, valueObject.value)) {
            return false;
        }
        lookupServiceFn(formatValue);
        return true;
    };
    const onValueChange = (val, path) => {
        if (chosenCountry === 'CA') {
            const formatPostalcode = WniFormat.zipCodeFormatForCanada(val);
            writeValue(formatPostalcode, path);
        } else {
            const formatZipcode = WniFormat.zipcodeFormat(val);
            writeValue(formatZipcode, path);
        }
    };

    //------------------------------------------
    return (
        <InputField
            id={id}
            disabled={disabled}
            path={lookupPath}
            value={formatValue}
            className={className}
            label={label}
            required={required}
            showRequired
            placeholder={placeholder}
            readOnly={readOnly}
            labelPosition={labelPosition}
            onValueChange={onValueChange}
            onBlur={getAddressFromZipCode}
            showErrors={showErrors}
            validationMessages={validationMessages}
        />
    );
};

LookupZipcodeComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    path: PropTypes.string.isRequired,
    value: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onSelection: PropTypes.func.isRequired,
    writeValue: PropTypes.func.isRequired,
    updateShowLoader: PropTypes.func,
    showErrors: PropTypes.bool,
    labelPosition: PropTypes.string,
    readOnly: PropTypes.bool,
    validationMessages: PropTypes.arrayOf(PropTypes.string || PropTypes.shape({})),
    autoFilled: PropTypes.bool
};
LookupZipcodeComponent.defaultProps = {
    label: '',
    placeholder: '',
    value: '',
    required: false,
    disabled: false,
    showErrors: false,
    labelPosition: 'left',
    readOnly: false,
    validationMessages: [],
    updateShowLoader: _.noop,
    autoFilled: true
};
export default LookupZipcodeComponent;
