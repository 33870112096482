import React from 'react';
import _ from 'lodash';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import {
    ErrorsAndWarningsUtil,
    ValidationIssueUtil,
    QuoteCoveragesUtil,
    QuoteUtil
} from 'wni-portals-util-js';
import { Button } from '@jutro/components';
import { DomRenderUtil } from 'wni-portals-util-react';
// import messages from 'gw-capability-quoteandbind-pa-react/pages/Quote/QuotePage.messages';
import HOQuoteConstants from '../config/HOQuoteConstants';

import customMessages from '../HOQuotePage.messages';

const {
    SECTION_I_CATEGORY,
    SECTION_II_CATEGORY,
} = HOQuoteConstants;

export default function HOQuoteUIHelperGenerator(quotePageConstants) {
    
    const {
        LOB_NAME,
        LOB_OFFERING_PATH,
        SXS_PERIODS_PATH,
        SXS_COVERAGES_PATH,
    } = quotePageConstants; // HOQuoteConstants;

    // const { structureClauseTableData } = QuoteCoveragesUtil;

    function structureClauseTableData(coverage) {
        // putting ID into an object as the Jutro table component expects an object
        return {
            publicID: coverage.publicID,
            codeIdentifier: coverage.code_Ext,
            // to be used in pouplateCovGroupWithCategoryPremium()
            categoryCode: coverage.coverageCategoryCode,
        };
    }

    /**
     * Consider to extract this method into HOQuoteCoeveragesUtil
     * 
     * See QuoteCoveragesUtil.filterCovOnQuotePage()
     * 
     * @param {obejct} coverageDTO CoverageDTO
     * @returns {boolean}
     */
    function filterCovOnHOQuotePage(coverageDTO) {
        const {
            code_Ext: clausePatternCode,
            selected
        } = coverageDTO;

        return selected;
    }


    function generateClauseData(columnData, coveragePath,
        // completeCoveragePathGetter,
        vehicleFixedId = undefined) {
        // const covPathGetter = completeCoveragePathGetter || (() => `coverages.${coveragePath}`);

        const retval = columnData.map(({ lobOffering, code }) => {
            let completeCoveragePath = `coverages.${coveragePath}`;
            // const completeCoveragePath = covPathGetter(lobOffering);

            if (!_.isNil(vehicleFixedId)) {
                const vehicleCoverages = _.get(lobOffering, 'data.coverages.vehicleCoverages');
                const vehicleCoverageIndex = vehicleCoverages.findIndex((vc) => vc.fixedId === vehicleFixedId);
                completeCoveragePath = `coverages.${coveragePath}.children[${vehicleCoverageIndex}].coverages`;
            }
            // if (completeCoveragePathGetter && _.isFunction(completeCoveragePathGetter)) {
            //     completeCoveragePath = completeCoveragePathGetter(lobOffering);
            // }

            return {
                code: code,
                path: `${lobOffering.path}.${completeCoveragePath}`,
                clauses: _.get(lobOffering.data, completeCoveragePath.replace(/\.children/, '')),
                pairClauses: _.get(lobOffering.pairData, completeCoveragePath.replace(/\.children/, '')),
            };
        });
        return retval;
    }

    function structureCustomQuote(submissionVM, affectedQuote, clauses) {
        // convert OfferingDTO to CustomQuotedDTO structure
        return {
            quote: affectedQuote,
            quoteID: submissionVM.quoteID.value,
            sessionUUID: submissionVM.sessionUUID.value,
            periodStart: submissionVM.baseData.periodStartDate.value,
            periodEnd: submissionVM.baseData.periodEndDate.value,
            coverages: clauses
        };
    }

    function getLineCoveragesUniqueID(submissionVM, periodToCovIssuessMap = {}) {
        const offerings = _.get(submissionVM, `lobData.${LOB_NAME}.offerings.value`);
        // const lineCoverages = _.uniqBy(offerings.flatMap((offering) => (
        //     offering.coverages.lineCoverages.map(structureClauseTableData)
        // )), 'publicID');
        // const allLineCovs = offerings.flatMap((offering) => (offering.coverages.lineCoverages))
        //     .filter(QuoteCoveragesUtil.filterCovOnQuotePage);
        const allCoverages = {
            primaryCovs: [],
            commonOptionalCovs: [],
            // additionalCovs: [],
            otherOptionalCovs: [],
            exclusionCovs: [],
        };
        offerings.forEach((offering) => {
            const {
                coverages: {
                    primaryCoverages_Ext: primaryCoverages,
                    commonOptionalCoverages_Ext: commonOptionalCoverages = [],
                    // additionalCoverages: additionalCoveragesParam = [],
                    otherOptionalCoverages_Ext: otherOptionalCovs = [],
                    exclusion_Ext: exclusionCovs = [],
                }
            } = offering;
            allCoverages.primaryCovs.push(primaryCoverages.filter(filterCovOnHOQuotePage));
            allCoverages.commonOptionalCovs.push(commonOptionalCoverages.filter(filterCovOnHOQuotePage));
            // allCoverages.additionalCovs.push(additionalCoveragesParam.filter(filterCovOnHOQuotePage));
            allCoverages.otherOptionalCovs.push(otherOptionalCovs.filter(filterCovOnHOQuotePage));
            allCoverages.exclusionCovs.push(exclusionCovs.filter(filterCovOnHOQuotePage));
        });
        const lineCoveragesLiabGroup = _.uniqBy((allCoverages.primaryCovs.flat().map(structureClauseTableData)), 'publicID');
        const commonOptionalCoveragesGroup = _.uniqBy((allCoverages.commonOptionalCovs.flat().map(structureClauseTableData)), 'publicID');
        // const additionalCoverages = _.uniqBy((allCoverages.additionalCovs.flat().map(structureClauseTableData)), 'publicID');
        const otherOptionalCoveragesGroup = _.uniqBy((allCoverages.otherOptionalCovs.flat().map(structureClauseTableData)), 'publicID');
        const exclusionCoveragesGroup = _.uniqBy(allCoverages.exclusionCovs.flat().map(structureClauseTableData), 'publicID');

        // const allLineCovs = offerings.flatMap((offering) => {
        //     const {
        //         publicID_Ext: periodPublicID,
        //         coverages: {
        //             // baseCoverages: lineCoverages, // no lineCoverages for HO, only baseCoverages in OOTB plugin
        //             primaryCoverages_Ext: lineCoverages,
        //         },
        //     } = offering;
        //     const periodCovIssues = periodToCovIssuessMap[periodPublicID];

        //     // const retval = lineCoverages.filter((lineCov) => QuoteCoveragesUtil.filterCovOnQuotePage(lineCov,
        //     //     periodCovIssues));
        //     const retval = lineCoverages;
        //     return retval;
        // });
        // const lineCoveragesLiabGroup = _.uniqBy((
        //     allLineCovs // .filter(QuoteCoveragesUtil.belongsToLiabilityTable)
        //         .map(structureClauseTableData)
        // ), 'publicID');

        // const optionalCoverages = offerings.flatMap((offering) => {
        //     const {
        //         coverages: {
        //             commonOptionalCoverages_Ext: commonOptionalCoverages = [],
        //         }
        //     } = offering;
        //     return commonOptionalCoverages;
        // });
        // const optionalCoveragesGroup = _.uniqBy(optionalCoverages.map(structureClauseTableData), 'publicID');


        // const additionalCoverages = offerings.flatMap((offering) => {
        //     const {
        //         coverages: {
        //             additionalCoverages: additionalCoveragesParam = []
        //         }
        //     } = offering;
        //     return additionalCoveragesParam;
        // });
        // const additionalCoveragesGroup = _.uniqBy(additionalCoverages.map(structureClauseTableData), 'publicID');

        const lineCoveragesOtherGroup = [];
        // const lineCoveragesOtherGroup = _.uniqBy((
        //     allLineCovs.filter((cov) => !QuoteCoveragesUtil.belongsToLiabilityTable(cov))
        //         .map(structureClauseTableData)
        // ), 'publicID');
        // const vehicleCoverages = _.uniqBy(offerings.flatMap((offering) => (
        //     offering.coverages.vehicleCoverages.flatMap(({ coverages }) => (
        //         coverages.filter(QuoteCoveragesUtil.filterCovOnQuotePage).map(structureClauseTableData)
        //     ))
        // )), 'publicID');
        return {
            // lineCoverages,
            lineCoveragesLiabGroup,
            commonOptionalCoveragesGroup,
            // additionalCoverages,
            otherOptionalCoveragesGroup,
            lineCoveragesOtherGroup,
            exclusionCoveragesGroup,
            // vehicleCoverages,
        };
    }

    /**
     * Get a map of vehicleCoverages with form of:
     *  {
     *     vehicle.fixedId: [ [publicID: clause_public_ID}]]
     *  }
     * @param {object} submissionVM
     * @param {object} periodToCovIssuessMap (Optional)
     * @param {array} vehicleCoverageSets (Optional)
     * @returns {object}
     */
    function getVehicleCoveragesUniqueID(submissionVM, periodToCovIssuessMap = {},
        vehicleCoverageSets = []) {
        const vehicles = _.get(submissionVM, `lobData.${LOB_NAME}.coverables.vehicles.value`);
        const vehicleFixedIdList = vehicles.map((vehicle) => vehicle.fixedId);

        const offerings = _.get(submissionVM, `lobData.${LOB_NAME}.offerings.value`);

        const vehicleCoveragesMap = {};
        vehicleFixedIdList.forEach((vehicleFixedId) => {
            const vehicleCoveragesArray = QuoteCoveragesUtil.getStructuredVehicleCoverages(offerings,
                vehicleFixedId, (vehCov, periodPublicID) => {
                    const periodCovIssues = periodToCovIssuessMap[periodPublicID];
                    return QuoteCoveragesUtil.filterCovOnQuotePage(vehCov, periodCovIssues, vehicleFixedId);
                });
            vehicleCoveragesMap[vehicleFixedId] = vehicleCoveragesArray;
        });

        // ====for Custom Eqiupment Coverages BEGINS========================
        const vehicleFixedIds = QuoteCoveragesUtil.getVehicleFixedIdsFromCoverageSets(vehicleCoverageSets);
        if (!_.isEmpty(vehicleFixedIds)) {
            vehicleFixedIds.forEach((vehicleFixedId) => {
                const vehCovArray = vehicleCoveragesMap[vehicleFixedId];
                const customEquipCov = vehCovArray.find((vehCov) => {
                    return vehCov.codeIdentifier === 'PACustomEquipCov_Ext';
                });
                // Custom Equipment does not exist in current table row, which means
                // we need to either extract it from existing vehicleCov list, or
                // populate with a dummy one
                if (!customEquipCov) {
                    const unselectedCustomEquipCovs = QuoteCoveragesUtil.getStructuredVehicleCoverages(
                        offerings, vehicleFixedId, (vehCov) => vehCov.code_Ext === 'PACustomEquipCov_Ext'
                    );
                    if (_.isEmpty(unselectedCustomEquipCovs)) {
                        // If there is not existing CustomEquipment Covrage, then populate a dummy one
                        vehCovArray.push({
                            // publicID: undefined,
                            publicID: 'PACustomEquipCov_Ext', // zikheonbti42t37080emncndvhb
                            codeIdentifier: 'PACustomEquipCov_Ext',
                        });
                    } else {
                        // Otherwise populate with existing unchecked CustomEquipmentCoverage
                        const [ceCov] = unselectedCustomEquipCovs;
                        vehCovArray.push(ceCov);
                    }
                }
            });
        }
        // ====for Custom Eqiupment Coverages ENDS========================
        return vehicleCoveragesMap;
    }

    function filterLobOffering(lobOffering, sxsCoverages) {
        if (_.isEmpty(sxsCoverages)) {
            return lobOffering;
        }
        const {
            coverages: {
                lineCoverages,
                // vehicleCoverages
            },
            ...extraProps
        } = lobOffering;
        const {
            lineCoverages: sxsLineCoverages,
            // vehicleCoverages: sxsVehicleCoverages,
        } = sxsCoverages;

        // const sxsLinCovPublicIDArray = sxsLineCoverages.map((lineCov) => lineCov.publicID);
        // const sxsVehCovPublicIDArrayMap = sxsVehicleCoverages.map(({ publicID, fixedId, coverages }) => {
        //     return {
        //         publicID,
        //         fixedId,
        //         coverages: coverages.map((cov) => cov.publicID),
        //     };
        // });

        const newLineCoverages = lineCoverages.filter(({ publicID }) => {
            return sxsLineCoverages.find((lineCov) => lineCov.publicID === publicID);
        });
        // const newVehicleCoverages = vehicleCoverages.map(({ publicID, fixedId, coverages }) => {
        //     const sxsVeh = sxsVehicleCoverages.find((sxsVehCov) => sxsVehCov.fixedId === fixedId);
        //     let filteredVehCovs = null;

        //     const sxsVehCovs = _.get(sxsVeh, 'coverages');
        //     if (!_.isEmpty(sxsVehCovs)) {
        //         filteredVehCovs = coverages.filter(({ publicID: covPublicID }) => {
        //             return sxsVehCovs.find(((scov) => scov.publicID === covPublicID));
        //         });
        //     } else {
        //         filteredVehCovs = [];
        //     }
        //     return {
        //         publicID,
        //         fixedId,
        //         coverages: filteredVehCovs
        //     };
        // });
        return {
            coverages: {
                lineCoverages: newLineCoverages,
                // vehicleCoverages: newVehicleCoverages,
            },
            ...extraProps
        };
    }

    // ============================================================
    function generateColumnData({
        submissionVM, sxsDataDTO,
        paymentMethod,
        // installmentPlansMap = {},
        // filterLobOfferings = false,
        ...extraColumnData
    }) {
        if (!sxsDataDTO) {
            return [];
        }

        const lobOfferingPath = LOB_OFFERING_PATH;
        const sxsPeriodsPath = SXS_PERIODS_PATH;
        const sxsCoveragesPath = SXS_COVERAGES_PATH;

        const lobOfferings = _.get(submissionVM.value, `${lobOfferingPath}`);
        const sxsPeriods = _.get(sxsDataDTO, `${sxsPeriodsPath}`);
        const sxsCoverages = _.get(sxsDataDTO, `${sxsCoveragesPath}`);
        // const sxsVehicleCovSets = _.get(sxsDataDTO, `${LOB_NAME}.vehicleSets`);

        // if (lobOfferings.length !== sxsPeriods.length) {
        //     return [];
        // }
        const sxsPeriodsMatchLobOfferings = sxsPeriods.every((sxsPeriod) => lobOfferings.some(
            (lobOffering) => sxsPeriod.publicID === lobOffering.publicID_Ext
        ));
        if (!sxsPeriodsMatchLobOfferings) {
            return [];
        }


        const errorsAndWarnings = _.get(sxsDataDTO, 'errorsAndWarnings', {});

        // Basically, SideBySidePeriod Info such as PolicyType, Premium, etc. are put into 'quote',
        // along with line & vehicle coverages data
        // const periodStatus = _.get(submissionVM, 'baseData.periodStatus.value');
        const columnData = lobOfferings.map((lobOffering, lobOfferingIndex) => {
            const sxsPeriodIndex = sxsPeriods.findIndex(
                (sp) => sp.publicID === lobOffering.publicID_Ext
            );
            const sxsPeriod = sxsPeriods[sxsPeriodIndex];

            const {
                publicID: sxsPeriodPublicID,
                columnIndex,
                pairPeriodPublicId_Ext: sxsPairPeriodPublicID,
            } = sxsPeriod;
            const sxsPeriodUWIssues = ErrorsAndWarningsUtil.filterUWIssuesBasedOnPeriod(
                _.get(errorsAndWarnings, 'underwritingIssues'), sxsPeriodPublicID
            );
            const sxsPeirodEligibilityIssues = ErrorsAndWarningsUtil.filterEligibilityIssuesBasedOnPeriod(
                _.get(errorsAndWarnings, `eligibilityIssues_Ext.${LOB_NAME}`), sxsPeriodPublicID
            );
            // const vehicleCoverageSets = QuoteCoveragesUtil.filterVehicleCoverageSets(sxsVehicleCovSets, columnIndex);

            // const sxsLobOffering = filterLobOfferings ? filterLobOffering(lobOffering, sxsCoverages) : lobOffering;
            const sxsLobOffering = lobOffering;
            const sxsPairLobOffering = QuoteUtil.getLobOfferingByPeriodPublicID(lobOfferings, sxsPairPeriodPublicID);

            // const installmentPlans = installmentPlansMap[sxsPeriod.publicID];

            return {
                name: sxsPeriod.branchName,
                code: sxsPeriod.columnIndex,
                sxsPeriod: {
                    path: `${sxsPeriodsPath}.children.${sxsPeriodIndex}`,
                    data: sxsPeriod,
                    underwritingIssues: sxsPeriodUWIssues,
                    eligibilityIssues: sxsPeirodEligibilityIssues,
                    vehicleCoverageSets: [],
                    paymentMethod,
                    // installmentPlans,
                    ...extraColumnData,
                },
                lobOffering: {
                    path: `${lobOfferingPath}.children.${lobOfferingIndex}`,
                    // data: lobOffering,
                    data: sxsLobOffering,
                    pairData: sxsPairLobOffering,
                    index: lobOfferingIndex,
                }
            };
        });
        return _.sortBy(columnData, ['code']);
    }


    function populateHOCovGroupWithCategoryPremium(clauseTableRowList, hoSxsPeriodInfoDTO, translator) {
        if (! (hoSxsPeriodInfoDTO && hoSxsPeriodInfoDTO.coveragePremiums)) {
            return clauseTableRowList;
        }
        const {
            coveragePremiums: {
                sectionIPremiumTotalAmount,
                sectionIIPremiumTotalAmount,
                //
                optionalPremiumTotalAmount,
            },
        } = hoSxsPeriodInfoDTO;
        const retval = [];

        let secIHeaderPopulated = false;
        let secIIHeaderPopulated = false;
        clauseTableRowList.forEach((tableRow) => {
            const {
                publicID,
                codeIdentifier,
                //
                categoryCode
            } = tableRow;

            if (!secIHeaderPopulated && SECTION_I_CATEGORY === categoryCode) {
                secIHeaderPopulated = true;
                retval.push({
                    //
                    sectionTitle: translator(customMessages.sectionICovSectionTitle),
                    sectionPremium: sectionIPremiumTotalAmount,
                });
            }
            if (!secIIHeaderPopulated && SECTION_II_CATEGORY === categoryCode) {
                secIIHeaderPopulated = true;
                retval.push({
                    //
                    sectionTitle: translator(customMessages.sectionIICovSectionTitle),
                    sectionPremium: sectionIIPremiumTotalAmount,
                });
            }

            retval.push(tableRow);
        });

        return retval;
    }

    // Line Coverages and Vehicle Coverages Info
    function generateTableData(submissionVM, columnData, translator, sxsDataDTO = undefined) {
        const periodToCovIssuesMap = QuoteCoveragesUtil.getPeriodToCovIssuesMap(sxsDataDTO, `${LOB_NAME}`);
        // const sxsVehicleCovSets = _.get(sxsDataDTO, `${LOB_NAME}.vehicleSets`);
        // line coverages
        const lineCoveragesUniqueIDMap = getLineCoveragesUniqueID(submissionVM, periodToCovIssuesMap);


        const sxsPeriods = _.get(sxsDataDTO, SXS_PERIODS_PATH);

        // To be refactored
        const hoSxsPeriodInfoDTO = sxsPeriods && sxsPeriods[0];

        // const lineCoverages = {
        //     header: translator(messages.generalCoverages),
        //     data: coveragesUniqueIDMap.lineCoverages,
        //     tableContent: generateClauseData(columnData, 'lineCoverages')
        // };

        // 
        const liabGroupPopulatedWithCategoryPremiums = populateHOCovGroupWithCategoryPremium(
            lineCoveragesUniqueIDMap.lineCoveragesLiabGroup, 
            hoSxsPeriodInfoDTO, translator);
        const lineCoveragesLiabGroup = {
            header: translator(customMessages.policyLevelQuoteDetails),
            data: liabGroupPopulatedWithCategoryPremiums, // lineCoveragesUniqueIDMap.lineCoveragesLiabGroup,
            tableContent: generateClauseData(columnData, 'primaryCoverages_Ext'), // 'lineCoverages')
        };

        const commonOptionalCoveragesGroup = {
            header: translator(customMessages.commonOptionalCoverages),
            data: lineCoveragesUniqueIDMap.commonOptionalCoveragesGroup,
            tableContent: generateClauseData(columnData, 'commonOptionalCoverages_Ext')
        };
        
        const otherOptionalCoveragesGroup = {
            header: translator(customMessages.otherOptionalCoverages),
            data: lineCoveragesUniqueIDMap.otherOptionalCoveragesGroup,
            tableContent: generateClauseData(columnData, 'otherOptionalCoverages_Ext')
        };

        const exclusionCoveragesGroup = {
            header: translator(customMessages.exclusionCoverages),
            data: lineCoveragesUniqueIDMap.exclusionCoveragesGroup,
            tableContent: generateClauseData(columnData, 'exclusion_Ext')
        };

        // const additionalCoveragesGroup = {
        //     header: translator(customMessages.additionalCoverages),
        //     data: lineCoveragesUniqueIDMap.additionalCoveragesGroup,
        //     tableContent: generateClauseData(columnData, 'additionalCoverages')
        // };

        // // vehicle coverages
        // const vehicles = _.get(submissionVM.value, `lobData.${LOB_NAME}.coverables.vehicles`);
        // const vehicleCoveragesIDMap = getVehicleCoveragesUniqueID(submissionVM, periodToCovIssuesMap, sxsVehicleCovSets);
        // const vehicleCoverages = vehicles.map((vehicle) => ({
        //     header: `${translator(customMessages.vehicleSpecificCoverage)}${QuoteUtil.getVehicleDescTitle(vehicle, ' - ')}`,
        //     // data: coveragesUniqueIDMap.vehicleCoverages,
        //     data: vehicleCoveragesIDMap[vehicle.fixedId],
        //     tableContent: generateClauseData(columnData, 'vehicleCoverages', vehicle.fixedId),
        //     // (lobOffering) => {
        //     //     const vehicleCovs = _.get(lobOffering, 'data.coverages.vehicleCoverages');
        //     //     const vehicleCovIdx = vehicleCovs.findIndex((vc) => vc.fixedId === vehicle.fixedId);
        //     //     const retval = `coverages.vehicleCoverages.children[${vehicleCovIdx}].coverages`;
        //     //     return retval;
        //     // })
        //     vehicleFixedId: vehicle.fixedId,
        // }));

        return [
            // lineCoverages,
            lineCoveragesLiabGroup,
            commonOptionalCoveragesGroup,
            otherOptionalCoveragesGroup,
            exclusionCoveragesGroup,
            // additionalCoveragesGroup,
            // ...vehicleCoverages,
            // lineCoveragesOtherGroup,
        ];
    }

    function getCustomQuote(vm, lobPath, quotePath, lobName, filterChangedClauses = false) {
        const lobOffering = _.get(vm, `${lobPath}.value`);
        const quoteOffering = _.get(vm, `${quotePath}.value`);

        let clausesToUpdate = {
            [lobName]: lobOffering.coverages
        };

        if (filterChangedClauses) {
            // eslint-disable-next-line max-len
            clausesToUpdate = ClausesUtil.structureClausesForServer(lobOffering.coverages, lobName, null);
        }

        return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
    }


    return {
        generateColumnData,
        generateTableData,
        //
        // getCustomQuote,
    };
}

