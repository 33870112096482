
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { UWIssueUtil } from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import messages from './UnderwritingIssuesSimpleList.messages';
import metadata from './UnderwritingIssuesSimpleList.metadata.json5';
import styles from './UnderwritingIssuesSimpleList.module.scss';

function UnderwritingIssuesSimpleList(props) {
    const {
        uwIssues: infoUWIssues,
    } = props;

    const translator = useTranslator();
    const infoUWIssueBaseTitle = translator(messages.requiringPostIssueReview);

    useEffect(() => {
    }, []);

    //----------------------------------
    const renderedListContent = renderHelper.getIssuesRenderContent(infoUWIssues);
    const overrideProps = {
        '@all': {
        },
        uwIssuesListTitleSection: {
            className: styles.flex
        },
        uwIssuesListTitleContainer: {
            content: infoUWIssueBaseTitle
        },
        uwIssuesListContainer: {
            visible: !_.isEmpty(infoUWIssues),
            content: renderedListContent,
            className: styles.font
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    if (_.isEmpty(infoUWIssues)) {
        return null;
    }
    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} />;
    // return (
    //     <ViewModelForm
    //         uiProps={metadata.componentContent}
    //         model={{}}
    //         overrideProps={overrideProps}
    //         callbackMap={resolvers.resolveCallbackMap}
    //         classNameMap={resolvers.resolveClassNameMap}
    //         componentMap={resolvers.resolveComponentMap}
    //     />
    // );
}

UnderwritingIssuesSimpleList.propTypes = {
    uwIssues: PropTypes.arrayOf(PropTypes.shape({})),
};

UnderwritingIssuesSimpleList.defaultProps = {
    uwIssues: [],
};

export default UnderwritingIssuesSimpleList;
