import React, {
    useState
} from 'react'
import { Accordion, AccordionCard } from '@jutro/components'
import PoliciesClausesArray from '../PoliciesSummaryClausesArray'
import PoliciesSummaryClausesDisplayablesArray from '../PoliciesSummaryClausesDisplayablesArray'

const commonAccordionCardProps = {
    chevron: true,
    className: 'accordion',
    cardBodyClassName: 'accordionCardBody',
    cardHeadingClassName: 'accordionCardHeader',
}

const WCRatingPeriodsComponent = (props) => {
    const {
        ratingPeriodsCovs = []
    } = props

    const hasMultiRatingPeriods = ratingPeriodsCovs.length > 1

    if (hasMultiRatingPeriods) {
        return <Accordion>
            {ratingPeriodsCovs.map((ratingPeriodCovs) => {
                const {
                    number,
                    coverages
                } = ratingPeriodCovs

                return <AccordionCard
                    key={`period_${number}_coverages`}
                    id={`period_${number}_coverages`}
                    title = {`Period ${number}`}
                    {...commonAccordionCardProps}
                >
                    <PoliciesClausesArray clauses={coverages} />
                </AccordionCard>
            })}
        </Accordion>
    }
    const {
        coverages
    } = ratingPeriodsCovs[0]
    return <PoliciesClausesArray clauses={coverages} />
    
}

const WCMWorkersCompCoverages = (props) => {
    const {
        wcCoverages
    } = props

    const [accordionStates, setAccordionStates] = useState(['coverages', 'additionalCoverages', 'exclusionsAndConditions'])

    const {
        lineDetails = [],
        lineCoverages = [],
        lineAdditionalCoverages = [],
        lineExclusions = [],
        lineConditions = [],
        stateSpecificCoverages = [],
    } = wcCoverages

    

    return <Accordion
        accordionStates={accordionStates}
        onUpdateAccordionStates= {(ids) => setAccordionStates(ids)}
        closeOthers={false}
    >
        <AccordionCard
            id='coverages'
            title = 'Coverages'
            {...commonAccordionCardProps}
        >
            <PoliciesSummaryClausesDisplayablesArray displayables={lineDetails} />
            <PoliciesClausesArray clauses={lineCoverages} />
            <Accordion>
                {stateSpecificCoverages.map((stateSpecificCoveragesDTO) => {
                    const {
                        state,
                        stateStandardCovsPart1,
                        stateStandardCovsPart2,
                        ratingPeriodsCovs= [],
                        stateEmployees = []
                    } = stateSpecificCoveragesDTO

                    return <AccordionCard
                        key={`${state}_coverages`}
                        id={`${state}_coverages`}
                        title = {state}
                        {...commonAccordionCardProps}
                    >
                        <WCRatingPeriodsComponent ratingPeriodsCovs={ratingPeriodsCovs} />
                        <PoliciesClausesArray clauses={stateStandardCovsPart1} />
                        <PoliciesClausesArray clauses={stateStandardCovsPart2} />
                        <Accordion>
                            {stateEmployees.map((stateEmployee) => {
                                const {
                                    employeeDisplayName,
                                    publicID,
                                    employeeStandardCoverages,
                                    employeeSplittableCoverages,
                                } = stateEmployee

                                return <AccordionCard
                                        key={`${publicID}_coverages`}
                                        id={`${publicID}_coverages`}
                                        title = {employeeDisplayName}
                                        {...commonAccordionCardProps}
                                >
                                    <PoliciesClausesArray clauses={employeeStandardCoverages} />
                                    <PoliciesClausesArray clauses={employeeSplittableCoverages} />
                                </AccordionCard>
                            })}
                        </Accordion>
                    </AccordionCard>
                })}
            </Accordion>
        </AccordionCard>
        <AccordionCard
            id='additionalCoverages'
            title = 'Additional Coverages'
            {...commonAccordionCardProps}
        >
            <PoliciesClausesArray clauses={lineAdditionalCoverages} />
            <Accordion>
                {stateSpecificCoverages.map((stateSpecificCoveragesDTO) => {
                    const {
                        state,
                        stateAdditionalCovs,
                    } = stateSpecificCoveragesDTO
                    return <AccordionCard
                        key={`${state}_coverages`}
                        id={`${state}_coverages`}
                        title = {state}
                        {...commonAccordionCardProps}
                    >
                        <PoliciesClausesArray clauses={stateAdditionalCovs} />
                    </AccordionCard>
                })}
            </Accordion>
        </AccordionCard>
        <AccordionCard
            id='exclusionsAndConditions'
            title = 'Exclusions and Conditions'
            {...commonAccordionCardProps}
        >
            <PoliciesClausesArray clauses={lineExclusions} />
            <PoliciesClausesArray clauses={lineConditions} />
        </AccordionCard>
    </Accordion>
}

export default WCMWorkersCompCoverages