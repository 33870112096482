
import React, {
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import {
    DropdownMenuLink
} from '@jutro/components';
import { PolicyChangeCommonMessages as messages } from 'wni-platform-translations';

import metadata from './BaseAddNewChangeVersionComponent.metadata.json5';
import styles from './BaseAddNewChangeVersionComponent.module.scss';
// import messages from './BaseAddNewChangeVersionComponent.messages';




const defaultGetOtherBranchMenuName = (accountJobPeriodDTO) => {
    const {
        publicID: periodPublicID,
        branchName,
        // hoCoverageForm,
    } = accountJobPeriodDTO;


    const menuName = `${branchName}`; // ${hoCoverageForm}
    return menuName;
};


/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function BaseAddNewChangeVersionComponent(props) {
    
    const translator = useTranslator();

    const {
        // sxsDataDTO: walSxsDataDTO = {},
        onCreateNewVersion,
        //
        accountJobPeriodDTOList,
        onSwitchToBranch,
        //
        menuItems,
        // ================
        getOtherBranchMenuName,
    } = props;

    // =====================================
    const renderSwitchToOtherBranchMenuItems = useCallback((accountJobPeriodDTOListParam) => {
        const retval =  accountJobPeriodDTOListParam.map((accountJobPeriodDTO) => {
            const {
                publicID: periodPublicID,
                branchName,
                // hoCoverageForm,
            } = accountJobPeriodDTO;


            // const menuName = `${branchName}`; // ${hoCoverageForm}
            const menuName = getOtherBranchMenuName(accountJobPeriodDTO);
            const onMenuClick = async() => onSwitchToBranch(periodPublicID);

            return (
                <DropdownMenuLink
                    type="action"
                    onClick={onMenuClick}
                    key={`${menuName}-MenuLink`}
                >
                    {menuName}
                </DropdownMenuLink>
            );
        });

        return retval;
    }, [onSwitchToBranch, getOtherBranchMenuName]);


    // const writeValue = (value, path) => {
    //     onValueChange(value, path);
    // };
    const renderCreateVersionMenuItems = (menuItemOptions) => {
        const retval =  menuItemOptions.map((menuItem) => {
            const {
                nameMsgKey,
                createService
            } = menuItem;

            const menuName = translator(messages[nameMsgKey]);
            const onMenuClick = async() => onCreateNewVersion(createService);
            
            return (
                <DropdownMenuLink
                    type="action"
                    onClick={onMenuClick}
                    key={`${menuName}-MenuLink`}
                >
                    {menuName}
                </DropdownMenuLink>
            );
        });

        return retval;
    };

    const renderCreateVersionMenuItemsV2 = useCallback(() => {

        // const menuItems = [
        //     {  
        //         menuName: translator(messages.createNewVersion),
        //         createService: BaseMultiQuoteChangeService.createDuplicateQuote,
        //         menuVisible: canAddNewVersion,
        //     },
        // ];

        // =======================
        const visibleMenuItems =  menuItems.filter((item) => item.menuVisible);

        const retval =  visibleMenuItems.map((menuItem) => {
            const {
                menuName,
                createService,
                menuVisible,
            } = menuItem;

            const onMenuClick = async() => onCreateNewVersion(createService);
            
            return (
                <DropdownMenuLink
                    type="action"
                    onClick={onMenuClick}
                    key={`${menuName}-MenuLink`}
                    visible={menuVisible}
                >
                    {menuName}
                </DropdownMenuLink>
            );
        });

        return retval;
    }, [menuItems, onCreateNewVersion]);

    const getMenuButtonText = useCallback((chooseOptions, addOptions) => {
        let retval = messages.addVersions;
        if (!_.isEmpty(chooseOptions)) {
            if (_.isEmpty(addOptions)) {
                retval = messages.chooseVersions;
            } else {
                retval = messages.chooseAddVersions;
            }
        }

        return retval;
    }, []);

    //----------------------------------
    // const createVersionMenuItems = renderCreateVersionMenuItems(CREATE_VERSION_OPTIONS);
    const createVersionMenuItems = renderCreateVersionMenuItemsV2();
    const switchVersionMenuItems = renderSwitchToOtherBranchMenuItems(accountJobPeriodDTOList);
    const allMenuItems = [...switchVersionMenuItems, ...createVersionMenuItems];
    const menuButtonText = getMenuButtonText(switchVersionMenuItems, createVersionMenuItems);
    

    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        chooseAddNewVersionDropdown: {
            buttonText: menuButtonText,
            disabled: _.isEmpty(allMenuItems),
            content: allMenuItems,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            // resolveValue={readValue}
            // onValueChange={writeValue}
            // showErrors={showErrors}
        />
    );
}

BaseAddNewChangeVersionComponent.propTypes = {
    /**
     * onCreateNewVersion() will be called with the JsonRpc Method as parameter
     */
    onCreateNewVersion: PropTypes.func.isRequired,
    /**
     * The MenuItem array
     */
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        menuName: PropTypes.string,
        createService: PropTypes.func,
        menuVisible: PropTypes.bool,
    })).isRequired,
    // ====================================
    /**
     * A list of AccountJobPeriodDTO instances
     */
    accountJobPeriodDTOList: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Callback to switch to other branch
     */
    onSwitchToBranch: PropTypes.func,
    /**
     * Get other branch menu name from existing accountJobPeriodDTO
     */
    getOtherBranchMenuName: PropTypes.func,
};

BaseAddNewChangeVersionComponent.defaultProps = {
    accountJobPeriodDTOList: [],
    onSwitchToBranch: _.noop,
    //
    getOtherBranchMenuName: defaultGetOtherBranchMenuName,
};

export default BaseAddNewChangeVersionComponent;
