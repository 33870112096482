import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WniAccountsUtil } from 'wni-portals-util-js';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import metadata from './EditAccountDetailsPopUp.metadata.json5';
import styles from './EditAccountDetailsPopUp.module.scss';

const DTO_PATH = 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO';
const EditAccountDetailsPopUp = (props) => {
    const {
        accountData,
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        isComponentValid,
        setComponentValidation,
        viewModelService,
        useBusinessData
    } = props;
    const {
        accountHolder : {
            contactType_Ext: contactType,
            primaryPhoneType
        } = {},
        accountNumber
    } = accountData;
    const [accountDetailsVM, setAccountDetailsVM] = useState(null);
    const isCLAccount = WniAccountsUtil.isCLAccount(contactType);
    const { enableCommercialLine } = useBusinessData;
    const handleSave = useCallback(() => {
        const requestObject = {
            accountHolder: accountDetailsVM.value,
            accountNumber
        };
        onResolve(requestObject);
    }, [accountDetailsVM, accountNumber, onResolve]);

    useEffect(() => {
        const accountHolderVM = viewModelService.create(accountData.accountHolder, 'pc', DTO_PATH);
        setAccountDetailsVM(accountHolderVM);
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPhonePath = () => {
        let phonePath = `${primaryPhoneType}Number`;
        switch(primaryPhoneType){
            case 'mobile':
                phonePath = 'cellNumber';
                break;
            default:
                phonePath = `${primaryPhoneType}Number`
                break;
        }
        return phonePath
    };

    const writeValue = useCallback((value, path) => {
        if (accountDetailsVM) {
            const model = viewModelService.clone(accountDetailsVM);
            _.set(model, path, value);
            setAccountDetailsVM(model);
        }
    }, [accountDetailsVM, viewModelService]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            showRequired: true
        },
        phoneNumber: {
            path: getPhonePath()
        },
        printPreferenceField: {
            // POI-55634 apply print preference to PL + CL
            visible: enableCommercialLine
        },
        changeAddressNotification: {
            visible: enableCommercialLine
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader
                title={title}
                onClose={onReject}
            />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountDetailsVM}
                    overrideProps={overrideProps}
                    classNameMap={styles}
                    onValueChange={writeValue}
                    onValidationChange={setComponentValidation}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave} disabled={!isComponentValid}>
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
};

EditAccountDetailsPopUp.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func.isRequired,
        clone: PropTypes.func.isRequired,
    }).isRequired,
    accountData: PropTypes.shape({
        accountNumber: PropTypes.string.isRequired,
        accountHolder: PropTypes.shape({
            primaryAddress: PropTypes.shape({}).isRequired,
            emailAddress1: PropTypes.shape({}).isRequired,
            subtype: PropTypes.shape({}).isRequired,
            cellNumber: PropTypes.string.isRequired,
            homeNumber: PropTypes.string.isRequired,
            workNumber: PropTypes.string.isRequired,
            primaryPhoneType: PropTypes.string
        }).isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    actionBtnLabel: PropTypes.shape({}).isRequired,
    cancelBtnLabel: PropTypes.shape({}).isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    ...validationPropTypes,
};
EditAccountDetailsPopUp.defaultProps = {
    size: 'lg'
}
export default withValidation(EditAccountDetailsPopUp);
