import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {
    Button,
    ModalNext,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WindowUtil } from 'wni-portals-util-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WCStateInfoService } from 'wni-capability-quoteandbind-wc';

import metadata from './WCSplitPeriodPopup.metadata.json5';

function WCSplitPeriodPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        submissionVM,
        selection,
        messages,
        isReadOnly,
        viewModelService,
        translator,
        cancelBtnLabel,
        actionBtnLabel,
        useDependenciesData,
        useAuthenticationData
    } = props;

    const {
        jobID, 
        sessionUUID
    } = submissionVM.value;

    const {setLoadingMask } = useDependenciesData.loadingMask
    const periodStartDate = _.get(submissionVM, 'baseData.periodStartDate.value');
    const periodEndDate = _.get(submissionVM, 'baseData.periodEndDate.value');
    const stateSpecificInfoVM = _.get(submissionVM, 'lobData.workersComp.coverables.stateSpecificInfo');
    // const initialSplitDate = _.get(stateSpecificInfoVM.value, 'splitDate');
    const initialSplitDate = _.get(submissionVM, 'baseData.effectiveDate_Ext.value');

    const [showErrors, updateShowErrors] = useState(false);
    const [splitDate, setSplitDate] = useState(initialSplitDate);
    const [splitType, setSplitType] = useState(null);

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid
    } = useValidation('WCSplitPeriodPopupPage');
    
    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    const handleSave = async () => {
        if(!isComponentValid || !splitDate) {
            handleValidation();
            return false;
        };
        setLoadingMask(true);

        const res = await WCStateInfoService.addSplitPeriod(jobID, sessionUUID, selection, splitDate,
            splitType,
            useAuthenticationData.authHeader);
        onResolve(res)
        setLoadingMask(false);
    }

    const onSplitDateChange = (date) => {
        setSplitDate(date);
    }

    const onSplitTypeChange = (type) => {
        setSplitType(type)
    }

    const getSplitDateFieldOverrideProps = () => {
        const stateSpecifics = _.get(stateSpecificInfoVM.value, 'stateSpecifics')
        let shouldShowNewValidation = false;
        let validationDate = '';

        const selectedStates = selection.map((item, index) => {
            return stateSpecifics.filter(v => v.publicID === item)[0];
        })
        selectedStates.forEach(item => {
            const splitDateArray = item.splitDates;
            splitDateArray.forEach(date => {
                if(moment(date).format('MM/DD/YYYY') === moment(splitDate).format('MM/DD/YYYY')) {
                    shouldShowNewValidation = true;
                    validationDate = moment(splitDate).format('MM/DD/YYYY');
                }
            });
        });

        const splitDateFieldOverrideProps = {
            value: splitDate,
            minDate: periodStartDate,
            maxDate: periodEndDate,
            showErrors: showErrors || shouldShowNewValidation,
            onValueChange: onSplitDateChange
        }

        if(shouldShowNewValidation) {
            _.set(splitDateFieldOverrideProps, 'validationMessages',
            [translator(messages.splitDateValidation, {
                date: validationDate 
            })]);
        }

        return splitDateFieldOverrideProps
    }
 
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        splitDate: {
            ...getSplitDateFieldOverrideProps()
        },
        splitType: {
            value: splitType,
            onValueChange: onSplitTypeChange
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            stateSpecificInfoVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="wcSplitPeriodPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={stateSpecificInfoVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                    onValidationChange={onValidate}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

WCSplitPeriodPopup.defaultProps = {
    size: 'md',
};

export default WCSplitPeriodPopup;

