import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { useTranslator } from '@jutro/locale';
import {
    Redirect,
    useLocation,
    useParams,
    withRouter,
    useHistory
} from 'react-router-dom';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/prefer-default-export
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { ServiceErrorUtil, WizardUtil } from 'wni-portals-util-js';
import { WniAccountQuoteService } from 'wni-capability-gateway';
import { WizardConstants } from 'wni-portals-config-js';
import { messages as platformMessages } from '@xengage/gw-platform-translations'
import { WniPlatformMessages as customMessages } from 'wni-platform-translations';
import { useWniModal } from 'wni-components-platform-react';
import { MultiModeWizard } from 'wni-portals-wizard-react';
import messages from './AQAccountQuotesWizard.messages';
import wizardConfig from './config/account-quotes-wizard-config.json5';
import wizardStepToFieldMapping from './config/account-quotes-wizard-step-to-field-mapping.json5';
import internalWizardConfig from './config/account-quotes-internal-wizard-config.json5';
import externalAOWizardConfig from './config/account-quotes-external-ao-wizard-config.json5';


function getInitialWizardPageData(locationState) {
    if (!locationState) { // locationState could be undefined
        return {};
    }
    if (_.get(locationState, 'submissionWizardExitPage')) {
        const {
            locked,
            submissionWizardExitPage
        } = locationState;
        return {
            [WizardConstants.isUwLocked]: locked,
            [WizardConstants.lastExitWizardPage]: submissionWizardExitPage
        };
    }
    const {
        isNewAccount,
        productSelected,
    } = locationState;
    return {
        [WizardConstants.isNewAccount]: isNewAccount,
        [WizardConstants.productSelected]: productSelected
    };
}


function AQAccountQuotesWizard(props) {
    const modalApi = useWniModal();
    const {
        history,
        location: {
            state: locationState
        },
        match,
        location
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { steps, title } = wizardConfig;
    const { accountNumber } = useParams();
    const mode = _.get(locationState, 'mode') || null;
    const { authHeader } = useAuthentication();
    const [initialSubmission, setInitialSubmission] = useState();
    // const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { steps: internalSteps, title: internalTitle } = internalWizardConfig;
    const { steps: externalAOSteps, title: externalAOTitle } = externalAOWizardConfig;

    const TODO_FUNC = _.noop;

    const initAccountDetails = async () => {
        setIsLoading(true);
        // refrence: AccountQuoteDataDTO
        const accountDetailsResponse = await WniAccountQuoteService
            .retrieveAccountQuoteData(accountNumber, authHeader);
        const accountQuoteDataVM = viewModelService.create(
            accountDetailsResponse,
            'pc',
            'wni.edge.capabilities.gateway.account.dto.AccountQuoteDataDTO'
        );
        setInitialSubmission(accountQuoteDataVM);
        setIsLoading(false);
    };

    useEffect(() => {
        if (!viewModelService) {
            return;
        }
        initAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    const internalOnInitialization = useCallback(({ updateFurthestPageVisited }) => {
        updateFurthestPageVisited('AQAccountSummaryPage');
    }, []);

    const externalAOOnInitialization = useCallback(({ updateFurthestPageVisited }) => {
        updateFurthestPageVisited('AQAccountBillingPage');
    }, []);

    const handleError = useCallback((error) => {
        // const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        // const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].quoteID');
        // const quoteIDFromLocation = _.get(location, 'state.quoteentry.quoteID');
        // const quoteID = quoteIDFromWizard || quoteIDFromLocation;
        // const redirectPath = `/quotes/${quoteID}/summary`;
        const redirectPath = '/';
        const state = {
            pathname: redirectPath,
            state: error
        };

        const errorMsg = ServiceErrorUtil.getErrorMessage(error, messages.anErrorOccurred);
        return modalApi.showAlert({
            // title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
            // message: isQuotePage ? quoteErrorMsg : dtoErrorMsg,
            title: platformMessages.genericError,
            message: errorMsg,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: platformMessages.OK
        }).then(() => {
            // setHasErrorOccurred(true);
            return <Redirect to={state} />;
        });
    }, [location]);
    // =======================================================
    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    // =======================================================
    const initialWizardPageData = getInitialWizardPageData(locationState);
    return (
        <div className="WizardPageContainer">
            <ErrorBoundary onError={handleError}>
                <MultiModeWizard
                    initialData={initialSubmission}
                    modeToWizardPropsMap={
                        {
                            default: {
                                initialSteps: steps,
                                wizardTitle: title,
                                wizardStepToFieldMapping,
                                onCancel: TODO_FUNC,
                                initialWizardPageData,
                                skipCompletedSteps: true,
                            },
                            internal: {
                                initialSteps: internalSteps,
                                wizardTitle: internalTitle,
                                wizardStepToFieldMapping,
                                onCancel: TODO_FUNC,
                                initialWizardPageData,
                                skipCompletedSteps: true,
                                onWizardInitialization: internalOnInitialization,
                            },
                            ao: {
                                initialSteps: externalAOSteps,
                                wizardTitle: externalAOTitle,
                                wizardStepToFieldMapping,
                                onCancel: TODO_FUNC,
                                initialWizardPageData,
                                skipCompletedSteps: true,
                                onWizardInitialization: externalAOOnInitialization,
                            }
                        }
                    }
                    initialMode={_.isEmpty(mode) ? 'default' : mode}
                />
            </ErrorBoundary>
        </div>
    );
}

AQAccountQuotesWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            jobNumber: PropTypes.string
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default withRouter(AQAccountQuotesWizard);
