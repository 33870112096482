import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Button } from '@jutro/components'
import { Flex } from '@jutro/layout'
import { useValidation } from '@xengage/gw-portals-validation-react';
import {React, useState, useCallback, useContext, useEffect} from 'react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CPRisksService, CPRetrieveRiskItemSummaryService } from 'wni-capability-quoteandbind-cp';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal, ValidationIssuesComponent } from 'wni-components-platform-react';
import { ValidationIssueUtil } from 'wni-portals-util-js';
import metadata from '../Common/CommonRiskItemDetails.metadata.json5';
import DetailsFieldMap from '../Common/DetailsFieldMap'
import SpecialClassClauses from '../SpecialClassClauses';
import riskItemMessages from '../RiskItemComponent/RiskItemComponent.messages';
import SearchClassCodeDescriptionPopup from '../Common/SearchClassCodeDescriptionPopup';


const SpecialClass = (props) => {
    const modalApi = useWniModal();
    const translator = useTranslator();

    const {
        onValidate,
        isComponentValid,
    } = useValidation('SpecialClass');

    const { authHeader } = useAuthentication();
    const [specialClassClauses, setSpecialClassClauses] = useState()
    
    const [showErrors, updateShowErrors] = useState(false)
    const [validationIssues, updateValidationIssues] = useState([]);
    const {
        riskItem,
        jobID,
        sessionUUID,
        submissionVM,
        updateSubmissionVM,
        isReadOnly,
        handleCloseRisk,
    } = props

    const {
        locationPublicID,
        publicID: specialClassPublicID,
        locationDescription
    } = riskItem

    		
    const serviceProps = {
        jobID,
        sessionUUID,
        authHeader
    }

    const viewModelService = useContext(ViewModelServiceContext);
    const dtoName = 'wni.edge.capabilities.quote.lob.commercialproperty.dto.CPSpecialClassDTO';
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [currentRowVM, setCurrentRowVM] = useState({})

    const fieldModels = _.get(currentRowVM?.value, 'specialClassDisplayables', []);
    const showSearchModal = useCallback((tableData, isClassDescriptionSearch) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            tableData,
            isClassDescriptionSearch
        };
        return modalApi.showModal(
            <SearchClassCodeDescriptionPopup {...componentProps} />
        );
    }, [modalApi]);

    const getSpecialClassDetailsFunc = useCallback(async () => {
        const res = await CPRisksService.getSpecialClassDetails(jobID, sessionUUID, locationPublicID, specialClassPublicID, true, authHeader);
        return res
    }, [authHeader, jobID, locationPublicID, sessionUUID, specialClassPublicID])

    const updateSpecialClassDetailsFunc = useCallback(async (dto) => {
        const [needsRefresh, notNeedRefresh] = _.partition(dto.specialClassDisplayables?.filter(elt => elt.updated), 'needsRefresh');
        const orderedDisplayables = notNeedRefresh.concat(needsRefresh);
        Object.assign(dto, {'changedSpecialClassDisplayables': orderedDisplayables});
        const res = await CPRisksService.updateSpecialClassDetails(jobID, sessionUUID, locationPublicID, specialClassPublicID, dto, authHeader);
        return res
    }, [authHeader, jobID, locationPublicID, sessionUUID, specialClassPublicID])

    const getSpecialClassClausesFunc = useCallback(async () => {
        const res = await CPRisksService.getSpecialClassClauses(jobID, sessionUUID, locationPublicID, specialClassPublicID, authHeader)
        return res
    }, [authHeader, specialClassPublicID, jobID, locationPublicID, sessionUUID])

    const updateSpecialClassClausesFunc = async (dto) => {
        const res = await CPRisksService.updateSpecialClassClauses(jobID, sessionUUID, locationPublicID, specialClassPublicID, dto, authHeader)
        return res
    }

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true)
        const res = await serviceCallFunc()
        setLoadingMask(false)
        return res
    }

    const initFieldData = useCallback(async () => {
        // const specialClassDto = await getSpecialClassDetailsFunc()
        // const newBuildingClauses = await getLocationClausesFunc()
        const [
            specialClassDto, 
            newSpecialClassClauses
        ] = await withLoadingMask(() => Promise.all([
            getSpecialClassDetailsFunc(),
            getSpecialClassClausesFunc()
        ]))
        
        const res = viewModelService.create(specialClassDto, 'pc', dtoName);
        setSpecialClassClauses(newSpecialClassClauses)
        setCurrentRowVM(res)
        
    // !!! Never add setLoadingMask into dependency, which always different after render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getSpecialClassClausesFunc,
        viewModelService
    ])

    
    const updateService = async(serviceData) => {

        const {
            specialClass: updatedDetailsDto,
            specialClassClauses: newSpecialClassClauses
        } = await withLoadingMask(() => updateSpecialClassDetailsFunc(serviceData));
        setSpecialClassClauses(newSpecialClassClauses);
        const displayables = updatedDetailsDto?.specialClassDisplayables;
        const clonedDisplayables = _.set(_.clone(updatedDetailsDto), 'specialClassDisplayables', displayables);
        const res = viewModelService.create(clonedDisplayables, 'pc', dtoName);
        setCurrentRowVM(res);
        return res
    };

  
    const writeValue = (fieldItem, path, fieldModel = {}) => {
        const newVm = viewModelService.clone(currentRowVM);
        _.set(newVm.value, path, fieldItem);
        setCurrentRowVM(newVm);
        _.set(currentRowVM.value, path, fieldItem);
        if(fieldModel.triggerFunc === 'onValueChange' && fieldItem.needsRefresh) {
            updateService(currentRowVM.value);
        }
    }

    const onBlur = (value, fieldItem) => {
        updateService(currentRowVM.value, value, fieldItem)
    };

    const writeSearchedDetailsValue = async(fieldItem, seletedRow) => {
        const { propertyName } = fieldItem;
        setLoadingMask(true)
        const updatedSpecialClassDtos = await CPRetrieveRiskItemSummaryService.getUpdatedSpecialClassDetails(jobID, sessionUUID, locationPublicID, specialClassPublicID, propertyName, seletedRow, authHeader);
        const res = viewModelService.create(updatedSpecialClassDtos, 'pc', dtoName);
        setCurrentRowVM(res);
        setLoadingMask(false);
    }

    const handleSearch = async(fieldItem = {}) => {
        const { propertyName } = fieldItem;   
        const tableData = await  withLoadingMask(() => CPRetrieveRiskItemSummaryService.fetchSpecialClassClassCodeTable(jobID, sessionUUID, locationPublicID, specialClassPublicID, propertyName, authHeader));
        const isClassDescriptionSearch = propertyName === "ClassDescription";
        showSearchModal(tableData, isClassDescriptionSearch)
            .then((seletedRow) => {
                const ClassCodeDisplayable = fieldModels.find(elt => elt.propertyName === propertyName);
                writeSearchedDetailsValue(ClassCodeDisplayable, seletedRow);
            }).catch(() => {
                _.noop();
            })
    };

    useEffect(() => {
        initFieldData()
    }, [initFieldData])

    

    const handleClose = async () => {
        if (isReadOnly) {
            handleCloseRisk((oldTreeRows) => oldTreeRows)
            return
        }
        if (!isComponentValid) {
            updateShowErrors(true)
            return
        }
        const newVm = viewModelService.clone(currentRowVM);
        const allUpdatedDisplayables = _.get(newVm, 'value.specialClassDisplayables', [])?.filter(elt => elt.updated);
        if (!_.isEmpty(allUpdatedDisplayables))  {
            updateService(newVm.value);
        }
        const specialClassSummary = await withLoadingMask(() => CPRetrieveRiskItemSummaryService.retrieveSpecialClassSummary(
            jobID, sessionUUID, locationPublicID, specialClassPublicID, authHeader
        ))
        const {coverableColumnBasicInfo, errorsAndWarnings} = specialClassSummary;
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);
        const generateNewTiskTreeRowFunc = (oldRiskTreeRows) => {
            const newRiskTreeRows = _.clone(oldRiskTreeRows)
            const locationIndex = oldRiskTreeRows.findIndex(location => location.publicID === locationPublicID)
            const location = oldRiskTreeRows[locationIndex]
            const specialClasses = location.specialCLassTreeRows
            const specialClassIndex = specialClasses.findIndex(specialClass => specialClass.publicID === specialClassPublicID)
            _.set(newRiskTreeRows, `[${locationIndex}].specialCLassTreeRows[${specialClassIndex}].coverableColumnBasicInfo`, coverableColumnBasicInfo)

            return newRiskTreeRows
        }
        if (!_.isEmpty(newValidationIssues)) {
            updateShowErrors(true)
            return
        }
        handleCloseRisk((oldTreeRows) => generateNewTiskTreeRowFunc(oldTreeRows))
    }
    
    const overrideProps = {
        fieldSetModel: {
            vm: currentRowVM,
            dataPath: 'specialClassDisplayables',
            onValueChange: writeValue,
            onSearch: handleSearch,
            onValidate,
            onBlur,
            showErrors,
            isReadOnly,
            // filterSizeClassMapsInChange
        },
        riskItemDetailsCard: {
            title: 'Special Class Details'
        },
        locationInput: {
            visible: true,
            value: locationDescription
        },
    }
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            fieldsetmap: DetailsFieldMap
        }
    };
    return (
        <>
            <ValidationIssuesComponent validationIssues={validationIssues}/>
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                // onValueChange={onValueChange}
                onValidationChange={onValidate}
                // resolveValue={readValue}
                // classNameMap={resolvers.resolveClassNameMap}
                // callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            <SpecialClassClauses
            	riskItem={riskItem}
                serviceProps={serviceProps}
                submissionVM={submissionVM}
                updateSubmissionVM={updateSubmissionVM}
                updateClauseFunc={updateSpecialClassClausesFunc}
                riskClauses={specialClassClauses}
                setRiskClauses={setSpecialClassClauses}
                showErrors={showErrors}
                isEditable={!isReadOnly}
                onValidate={onValidate}
            />
            <Flex justifyContent="right">
                <Button onClick={handleClose}>
                    {translator(riskItemMessages.Close)}
                </Button>
            </Flex>
        </>
    );
}

export default SpecialClass