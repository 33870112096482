import React, {useEffect, useCallback} from 'react'
import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { CPCoverageService } from 'wni-capability-quoteandbind-cp';

import AccordionCardTitle from '../components/CPClauseAccordionCardTitle/CPClauseAccordionCardTitle';
import messages from './CPExclusionsAndConditionsAccordionCard.messages'
import CoverageContext from '../context/CPCoverageContext';
import CPCoverageUtil from '../util/CPCoverageUtil';
import CPClausesComponentVM from '../components/CPClausesComponentVM/CPClausesComponentVM';
import CPSingleClauseComponentVM from '../components/CPSingleClauseComponentVM/CPSingleClauseComponentVM';


const CPExclusionsAndConditionsAccordionCard = (props) => {

    const {
        lineClauses,
        setLineClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        loadingClauses,
        setLoadingClauses,
        onClickAddSearchCoverage,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { exclusion, condition } = lineClauses;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();
    const covType = 'exclusionsAndConditions';
    const validationID = 'CPExclusionsAndConditionsAccordionCard'

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate])

    const changeClauses = useCallback(
        (value, changedPath, clauses, clausesPath) => {
            const newClauses = CPCoverageUtil.setClauseValue(clauses, clausesPath, value, changedPath);
            const newLineClauses = _.clone(lineClauses)
            _.set(newLineClauses, clausesPath, newClauses);
            setLineClauses(newLineClauses);
            setIsEditing(false);
            return newLineClauses;
        },
        [lineClauses, setIsEditing, setLineClauses]
    );

    const changeExclusions = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, exclusion, 'exclusion');
        },
        [changeClauses, exclusion]
    );

    const changeConditions = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, condition, 'condition');
        },
        [changeClauses, condition]
    );
    const updateVMToServer = useCallback(async (newLineClauses, setLoadingClausesFunc, setEditingFunc) => {
        const lineClausesToUpdate = CPCoverageUtil.generateUpdatedlineClausesDTO(newLineClauses);
        const updatedLineClausesPublicIDs = CPCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedLineClausesDTO(lineClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedLineClausesPublicIDs)
        const lineClausesFromServer = await CPCoverageService.updateLineClauses(
            jobID,
            sessionUUID,
            lineClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setLineClauses(lineClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setLineClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewLineClausesToServer = useCallback(async (newLineClauses) => {
        updateVMToServer(newLineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);



    const changeExclusionAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeExclusions(value, changedPath);

            updateNewLineClausesToServer(newSublineClauses);
        },
        [changeExclusions, updateNewLineClausesToServer]
    );

    const changeConditionAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeConditions(value, changedPath);

            updateNewLineClausesToServer(newSublineClauses);
        },
        [changeConditions, updateNewLineClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewLineClausesToServer(lineClauses);
        },
        [lineClauses, updateNewLineClausesToServer]
    )

    return (
        <AccordionCard
            id="commercialPropertyExclusionsAndConditions"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={isEditable ?
                <AccordionCardTitle
                    title={translator(messages.coveragesTitle)}
                    actionText={messages.AddExclusionsAndConditions}
                    onClick={() => {onClickAddSearchCoverage(covType)}}
                /> : translator(messages.coveragesTitle)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <CPClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeExclusionAndSync}
                        onChangeClause={changeExclusions}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="exclusion"
                        value={exclusion}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            CPSingleClauseComponentVM: CPSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        hideCheckBoxForRequired
                    />
                    <CPClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeConditionAndSync}
                        onChangeClause={changeConditions}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="condition"
                        value={condition}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            CPSingleClauseComponentVM: CPSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        hideCheckBoxForRequired
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    )
}

export default CPExclusionsAndConditionsAccordionCard
