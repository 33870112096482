import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CheckboxField
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { Flex } from '@jutro/layout';
import { WniDataTable } from 'wni-common-base-components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import messages from '../../CPBlanketsPage.messages';
import metadata from './CPGroupedRisksPopup.metadata.json5';

function CPGroupedRisksPopup(props) {
    const translator = useTranslator();
    const {
        isOpen,
        onReject,
        onResolve,
        applyRisks,
        tableData,
        renderDescriptionCell
    } = props;

    const [showErrors, updateShowErrors] = useState(false);
    const [risksErrorMessage, updateRisksErrorMessage] = useState([]);

    const handleClose = useCallback(() => {
        onReject();
    }, [onReject]);

    const newTableData = useMemo(() => {
        return _.map(tableData, (item, index) => {
            return {
                ...item,
                unselectable: !item.checkboxVisible,
                rowIdPath: index
            }
        })
    }, [tableData]);

    const initSelectedItems = useMemo(() => {
        const selectedItems = [];
        _.each(newTableData, (item) => {
            if (item.selected) {
                selectedItems.push(item);
            }
        })
        return selectedItems;
    }, [newTableData]);

    const initSelectedRows = useMemo(() => {
        return _.isEmpty(initSelectedItems) ? [] : initSelectedItems.map((item) => item.rowIdPath);
    }, [initSelectedItems]);

    const [selection, updateSelection] = useState(initSelectedItems);
    const [selectedRows, updateSelectedRows] = useState(initSelectedRows);

    const onCheckboxSelectionChange = useCallback((rows) => {
        const selectedItems = newTableData.filter((item) => rows.includes(item.rowIdPath));
        updateSelection(selectedItems);
        updateSelectedRows(rows);
    }, [newTableData]);

    const updateRisks = async () => {
        const res = await applyRisks(selection);
        const pcDisplayMessage = _.get(res, 'errorsAndWarnings.pcDisplayMessage_Ext');
        if (_.isEmpty(pcDisplayMessage)) {
            updateRisksErrorMessage([]);
            updateShowErrors(false);
            handleClose();
        } else {
            updateRisksErrorMessage(pcDisplayMessage.warnings);
            updateShowErrors(true);
        }
    }

    const overrideProps = {
        risksTable: {
            data: newTableData,
            onSelectionChange: onCheckboxSelectionChange,
            selectedRows: selectedRows,
        },
        description: {
            renderCell: (item) => { return renderDescriptionCell(item, false) }
        },
        risksErrorMessage: {
            message: risksErrorMessage,
            visible: showErrors
        }
    }

    return (
        <ModalNext isOpen={isOpen} onRequestClose={handleClose} className='lg'>
            <ModalHeader title={translator(messages.selectRisksTitle)} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    type="text"
                    onClick={handleClose}
                >
                    {translator(messages.cancel)}
                </Button>
                <Button
                    onClick={updateRisks}
                    disabled={_.isEmpty(selectedRows)}
                >
                    {translator(messages.apply)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

CPGroupedRisksPopup.propTypes = {
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    onReject: PropTypes.func,
    applyRisks: PropTypes.func,
    tableData: PropTypes.array,
    renderDescriptionCell: PropTypes.func
};

CPGroupedRisksPopup.defaultProps = {
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    applyRisks: _.noop,
    tableData: [],
    renderDescriptionCell: _.noop
};

export default CPGroupedRisksPopup;
