import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Button } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ComponentRenderHelper as renderHelper, useProductsData } from 'wni-portals-util-react';
import metadata from './SuccessNotification.metadata.json5';
import styles from './SuccessNotification.module.scss';

const ActionMessageButton = (props) => {
    const translator = useTranslator();
    const {
        id, value, onClick, type, className, isLoading
    } = props;
    return (
        <Button id={id} className={className} type={type} disabled={!!isLoading} onClick={onClick}>
            {renderHelper.getButtonContent(translator(value), isLoading, id)}
        </Button>
    );
};
ActionMessageButton.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    isLoading: PropTypes.string
};
ActionMessageButton.defaultProps = {
    isLoading: false
};

const SuccessNotificationContent = (props) => {
    const { notificationContent } = props;
    return notificationContent;
};

SuccessNotificationContent.propTypes = {
    notificationContent: PropTypes.element,
};

SuccessNotificationContent.defaultProps = {
    notificationContent: null
};

const SuccessNotification = (props) => {
    const {
        onContinueTransaction,
        onCopySubmission,
        onCopyToHOSubmission,
        onCopyToDPSubmission,
        onWithdrawTransaction,
        onSubmitTransaction,
        onEditTransaction,
        onRescindTransaction,
        onViewTransaction,
        onScheduleCancel,
        onCancelNow,
        notificationContent,
        transactionVisibleActions,
        isCopying,
        isEditing,
        isQuoting,
        isDeleting,
        isSubmitting,
        isRescinding,
        type,
        baseState
    } = props;
    const { getProductEnabled, getProductVisibleForState } = useProductsData();

    // notificationContent.infoMessages
    if (_.isEmpty(notificationContent.infoMessages)) {
        return null;
    }

    const overrides = {
        copyToHOSubmission: {
            visible: !!_.get(transactionVisibleActions, 'isCopyToHOSubmission') && getProductEnabled('HOPHomeowners'),
            isLoading: isCopying,
            className: styles.noCapitalize
        },
        copyToDPSubmission: {
            visible: !!_.get(transactionVisibleActions, 'isCopyToDPSubmission') && getProductVisibleForState('DwellingProperty', baseState),
            isLoading: isCopying,
            className: styles.noCapitalize
        },
        copySubmission: {
            visible: _.get(transactionVisibleActions, 'isCopySubmission') || false,
            isLoading: isCopying
        },
        continueTransaction: {
            visible: _.get(transactionVisibleActions, 'isContinueTransaction') || false,
            isLoading: isQuoting
        },
        withdrawTransaction: {
            visible: _.get(transactionVisibleActions, 'isWithdrawTransaction') || false,
            isLoading: isDeleting
        },
        submitTransaction: {
            visible: _.get(transactionVisibleActions, 'isSubmitTransaction') || false,
            isLoading: isSubmitting
        },
        cancelDropdown: {
            visible: _.get(transactionVisibleActions, 'isDropdownCancellation') || false,
            isLoading: isSubmitting
        },
        editTransaction: {
            visible: _.get(transactionVisibleActions, 'isEditTransaction') || false,
            isLoading: isEditing
        },
        rescindTransaction: {
            visible: _.get(transactionVisibleActions, 'isRescindTransaction') || false,
            isLoading: isRescinding
        },
        viewTransaction: {
            visible: _.get(transactionVisibleActions, 'isViewTransaction') || false
        },
        successContainerId: {
            className: cx(styles.gwAlert, {
                [styles.gwAlertInfo]: (type === 'info'),
                [styles.gwAlertWarning]: (type === 'warning')
            }),
            columns: (!_.get(transactionVisibleActions, 'continueSubmission')
                && !_.get(transactionVisibleActions, 'withdrawnSubmission')) ? ['1fr'] : ['3fr', '2fr']
        },
        infoIcon: {
            icon: (type === 'info') ? 'gw-info' : 'gw-warning'
        },
        alertActionsInfo: {
            visible: !_.get(transactionVisibleActions, 'isBoundTransaction')
        },
        successNotificationMessages: {
            visible: !notificationContent.infoMessagesContent
        },
        successNotificationContent: {
            visible: notificationContent.infoMessagesContent,
            notificationContent: notificationContent.infoMessagesContent,
        },
        draftPolicyChangeContentInfo: {
            visible: _.isPlainObject(notificationContent.infoMessages)
        },
        draftPolicyChangeInfoHeader: {
            content: _.get(notificationContent, 'infoMessages.infoMessageTitle')
        },
        draftPolicyChangeContinueContent: {
            content: _.get(notificationContent, 'infoMessages.infoMessageContinueDescription')
        },
        draftPolicyChangeWithdrawContent: {
            content: _.get(notificationContent, 'infoMessages.infoMessageWithdrawDescription')
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleCopySubmission: onCopySubmission,
            handleSubmitTransaction: onSubmitTransaction,
            handleEditTransaction: onEditTransaction,
            handleRescindTransaction: onRescindTransaction,
            handleContinueTransaction: onContinueTransaction,
            handleWithdrawTransaction: onWithdrawTransaction,
            handleViewTransaction: onViewTransaction,
            handleScheduleCancel: onScheduleCancel,
            handleCancelNow: onCancelNow,
            handleCopyToHOSubmission: onCopyToHOSubmission,
            handleCopyToDPSubmission: onCopyToDPSubmission

            
        },
        resolveComponentMap: {
            actionmessagecomponent: ActionMessageButton,
            successNotificationContent: SuccessNotificationContent,
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={notificationContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

SuccessNotification.propTypes = {
    notificationContent: PropTypes.shape({
        infoMessageTitle: PropTypes.string,
        infoMessages: PropTypes.shape({
            infoMessageTitle: PropTypes.string,
            infoMessageDescription: PropTypes.string
        }),
        infoMessagesContent: PropTypes.any
    }).isRequired,
    onContinueTransaction: PropTypes.func.isRequired,
    onCopySubmission: PropTypes.func,
    onCopyToHOSubmission: PropTypes.func,
    onCopyToDPSubmission: PropTypes.func,
    isCopying: PropTypes.bool,
    isEditing: PropTypes.bool,
    isQuoting: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isRescinding: PropTypes.bool,
    onWithdrawTransaction: PropTypes.func.isRequired,
    onSubmitTransaction: PropTypes.func,
    onEditTransaction: PropTypes.func,
    onRescindTransaction: PropTypes.func,
    onViewTransaction: PropTypes.func,
    onScheduleCancel: PropTypes.func,
    onCancelNow: PropTypes.func,
    transactionVisibleActions: PropTypes.shape({
        isCopySubmission: PropTypes.bool,
        isContinueTransaction: PropTypes.bool.isRequired,
        isWithdrawTransaction: PropTypes.bool.isRequired,
        isSubmitTransaction: PropTypes.bool,
        isEditTransaction: PropTypes.bool,
        isRescindTransaction: PropTypes.bool,
        isViewTransaction: PropTypes.bool,
        isBoundTransaction: PropTypes.bool,
        isDropdownCancellation: PropTypes.bool
    }).isRequired,
    type: PropTypes.string
};
SuccessNotification.defaultProps = {
    type: 'info',
    onCopySubmission: undefined,
    onCopyToHOSubmission: undefined,
    onCopyToDPSubmission: undefined,
    isCopying: true,
    isEditing: false,
    isQuoting: false,
    isDeleting: false,
    isSubmitting: false,
    isRescinding: false,
    onSubmitTransaction: undefined,
    onEditTransaction: undefined,
    onRescindTransaction: undefined,
    onViewTransaction: undefined,
    onScheduleCancel: undefined,
    onCancelNow: undefined
};
export default SuccessNotification;
