import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { RadioColumn, Table, TableColumn } from '@jutro/datatable';
import {
    CurrencyField, IconButton, Loader, withModalContext
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { PaymentSchedule, withWniModal } from 'wni-components-platform-react';
import { WizardConstants } from 'wni-portals-config-js';
import styles from 'gw-components-platform-react/PaymentList/PaymentList.module.scss';
import messages from 'gw-components-platform-react/PaymentList/PaymentList.messages';
import messages2 from './PaymentList.messages';
import { PaymentUtil } from 'wni-portals-util-js';

class PaymentListWithoutModalContext extends Component {
    /**
     * @memberof gw-components-platform-react.PaymentList
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {array} propTypes.value - object containing selectedPaymentPlan and the list of plans
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {bool} propTypes.path - path in the data object to retrieve the value
     */

    static propTypes = {
        value: PropTypes.shape({
            paymentPlans: PropTypes.arrayOf(PropTypes.shape({})),
            selectedPaymentPlan: PropTypes.string,
            paymentPlans_Ext: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        onValueChange: PropTypes.func.isRequired,
        path: PropTypes.string,
        isTitleVisible: PropTypes.bool,
        authHeader: PropTypes.string,
        quoteID: PropTypes.string,
        sessionUUID: PropTypes.string,
        updateWizardPageData: PropTypes.func,
        wizardPageData: PropTypes.shape({}),
        WniLoadSaveService: PropTypes.shape({}),
        disabled: PropTypes.bool,
        updateShowLoader: PropTypes.func
    };

    static contextType = TranslatorContext


    static defaultProps = {
        value: {},
        path: undefined,
        isTitleVisible: true,
        authHeader: '',
        quoteID: '',
        sessionUUID: '',
        updateWizardPageData: _.noop,
        wizardPageData: {},
        WniLoadSaveService: {},
        disabled: false,
        updateShowLoader: _.noop
    };

    state = {
        showAllPlan: false
    }


    generateDataForRadioButton = (row) => {
        return PaymentUtil.forMatterPaymentPlan(row);
        // /*
        //     "Full Pay",
        //     "Semiannual",
        //     "Quarterly PL - 12 months",
        //     "Quarterly PL - 6 months"
        //     "Monthly PL - 12 months",
        //     "Monthly PL - 6 months",
        //     "EFTCONV6",
        //     "EFTCONV12"
        // */
        // let { name } = row;
        // const NamesChange = {
        //     'PL - 12 months': '',
        //     'PL - 6 months': '',
        //     'Full Pay': 'Pay in Full'
        // };
        // _.forEach(NamesChange, (value, key) => {
        //     if (name.includes(key)) {
        //         name = name.substring(0, row.name.indexOf(key));
        //         name += value;
        //     }
        // });
        // return {
        //     name: name,
        //     code: row.billingId,
        // };
    }

    generateDownPayment = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`downpayment_${rowIndex}`}
                value={row.downPayment_Ext}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyStyle}
            />
        );
    }

    generateSchedule = (row) => {
        const handleRowItem = async (
            e, rowItem, quoteID, sessionUUID,
            authHeader, wizardPageData, updateWizardPageData,
            WniLoadSaveService, component, updateShowLoader
        ) => {
            e.preventDefault();
            updateShowLoader(true);
            if (_.isEmpty(_.get(rowItem, 'treeNode_Ext'))) {
                const paymentPlanDTO = await WniLoadSaveService
                    .retrievePaymentSchedulePlans(
                        quoteID, sessionUUID, rowItem.billingId, authHeader
                    );
                _.set(rowItem, 'treeNode_Ext', paymentPlanDTO.treeNode_Ext);
                const paymentPlans = wizardPageData[WizardConstants.paymentPlans];
                const plan = _.find(paymentPlans, (elt) => {
                    return elt.billingId === rowItem.billingId;
                });
                // stash for previous
                if (!_.isEmpty(plan)) {
                    plan.treeNode_Ext = paymentPlanDTO.treeNode_Ext;
                    updateWizardPageData({ [WizardConstants.paymentPlans]: paymentPlans });
                }
            }
            const { name } = this.generateDataForRadioButton(row);
            const componentProps = {
                title: `Payment Schedule ${name}`,
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: messages2.dialogOk,
                paymentDetailVM: rowItem
            };
            updateShowLoader(false);
            return this.props.showModal(<PaymentSchedule {...componentProps} />).then(() => {
                _.noop();
            }).catch(() => {
                _.noop();
            });
        };
        const {
            quoteID, sessionUUID, authHeader,
            wizardPageData, updateWizardPageData,
            WniLoadSaveService, disabled, updateShowLoader,
            value
        } = this.props;
        const component = this;
        const { selectedPaymentPlan } = value;
        return (
            <div>
                <IconButton
                    disabled={disabled || (selectedPaymentPlan !== _.get(row, 'billingId'))}
                    onClick={(e) => handleRowItem(
                        e, row, quoteID, sessionUUID,
                        authHeader, wizardPageData,
                        updateWizardPageData, WniLoadSaveService,
                        component, updateShowLoader
                    )}
                    role="menuitem"
                    icon="gw-search"
                />
            </div>
        );
    }

    generateInstallmentPayment = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`installment_${rowIndex}`}
                value={row.installment_Ext}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyStyle}
            />
        );
    }

    generateFeePayment = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`billingfee_${rowIndex}`}
                value={row.feePerInstallment_Ext}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyStyle}
            />
        );
    }

    handleValueChange = (param) => {
        const { onValueChange, path, value } = this.props;
        if (onValueChange) {
            const { paymentPlans } = value;
            const selectPlan = _.find(paymentPlans, (plan) => plan.billingId === param);
            return onValueChange(selectPlan, `${path}.selectedPaymentPlan`);
        }
        return null;
    }

    render() {
        const {
            value, isTitleVisible, onValueChange, path, disabled
        } = this.props;
        const { selectedPaymentPlan, paymentPlans_Ext: paymentPlansExt } = value;
        if (!paymentPlansExt) {
            return (<div />);
        }
        const { termType } = _.get(this.props, 'submissionVM.value.baseData');
        let filterPaymentPlans = paymentPlansExt
            .filter((plan) => plan.name !== 'EFTCONV6' && plan.name !== 'EFTCONV12')
            .filter((plan) => !plan.name.includes('CL'))
            .filter((plan) => !plan.name.includes('AG'));
        if (termType === 'HalfYear') {
            filterPaymentPlans = filterPaymentPlans.filter(
                (plan) => (plan.name.includes('6')
                 || plan.name === 'Full Pay'
                 || plan.name === 'Semiannual')
            );
        } else if (termType === 'Annual') {
            filterPaymentPlans = filterPaymentPlans.filter(
                (plan) => (plan.name.includes('12')
                 || plan.name === 'Full Pay'
                 || plan.name === 'Semiannual')
            );
        }

        const { showAllPlan, showLoader } = this.state;
        if (!showAllPlan && onValueChange) {
            this.setState({
                showAllPlan: true,
            });
            // onValueChange(paymentPlans[0], `${path}.selectedPaymentPlan`);
        }

        return showLoader ? (<Loader showLoader />) : (
            <div className="table-wrapper mb-20">
                <Table
                    title={isTitleVisible && messages.paymentPlans}
                    data={filterPaymentPlans}
                    columnsProportion={[
                        2,
                        1,
                        1,
                        1,
                        1
                    ]}
                    placeholder={messages.emptyPaymentPlans}
                >
                    <RadioColumn
                        id="plan"
                        textAlign="left"
                        header={messages.name}
                        option={this.generateDataForRadioButton}
                        cellClassName={styles.paymentListTableCell}
                        value={selectedPaymentPlan}
                        onValueChange={this.handleValueChange}
                        disabled={disabled}
                    />
                    <TableColumn
                        id="schedule"
                        textAlign="left"
                        header={messages2.schedule}
                        cellClassName={styles.paymentListTableCell}
                        cell={this.generateSchedule}
                    />
                    <TableColumn
                        id="downPayment"
                        textAlign="left"
                        header={messages.downPayment}
                        cellClassName={styles.paymentListTableCell}
                        cell={this.generateDownPayment}
                    />
                    <TableColumn
                        id="installment"
                        textAlign="left"
                        header={messages.installment}
                        cellClassName={styles.paymentListTableCell}
                        cell={this.generateInstallmentPayment}
                    />
                    <TableColumn
                        id="fee"
                        textAlign="left"
                        header={messages2.feePerInstallment}
                        cellClassName={styles.paymentListTableCell}
                        cell={this.generateFeePayment}
                    />
                </Table>
            </div>
        );
    }
}

export default withModalContext(withWniModal(PaymentListWithoutModalContext));
