import { Accordion, AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import { useWniModal } from 'wni-components-platform-react';
import React, { useCallback, useEffect, useState } from 'react';
import { CPLocationService } from 'wni-capability-quoteandbind-cp';
import AccordionCardTitle from '../../../CommercialProperty/components/CPClauseAccordionCardTitle/CPClauseAccordionCardTitle';
import CPClausesComponentVM from '../../../CommercialProperty/components/CPClausesComponentVM';
import CPSingleClauseComponentVM from '../../../CommercialProperty/components/CPSingleClauseComponentVM';
import CoverageContext from '../../../CommercialProperty/context/CPCoverageContext';
import CPCoverageUtil from '../../../CommercialProperty/util/CPCoverageUtil';
import messages from './LocationClauses.messages';
import CPSearchAndAddCoveragesPopup from '../../../../components/CPSearchAndAddCoveragesPopup/CPSearchAndAddCoveragesPopup';

const LocationClausesValidationID = 'LocationClauses';

const LocationClauses = (props) => {
    const modalApi = useWniModal();
    const {
        readOnly,
        showErrors,
        locationVM,
        submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        onValidate: onLocationItemValidate,
        locationClauses,
        setLocationClauses,
    } = props;

    const { publicID: locationPublicID } = locationVM.value;
    
    const [isEditing, setIsEditing] = useState(false);
    const [loadingClauses, setLoadingClauses] = useState();
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { jobID, sessionUUID } = submissionVM.value;

    const translator = useTranslator();

    const {
        onValidate: onStandardCoverageValidate,
        isComponentValid: isStandardCoverageValid,
    } = useValidation('CPLocationStandardCoverage');

    const {
        onValidate: onAdditionalCoverageValidate,
        isComponentValid: isAdditionalCoverageValid,
    } = useValidation('CPLocationAdditionalCoverage');

    const {
        onValidate: onExclusionAndConditionValidate,
        isComponentValid: isExclusionAndConditionValid,
    } = useValidation('CPLocationExclusionsAndConditions');

    const isLocationClauseValid =
        isStandardCoverageValid &&
        isAdditionalCoverageValid &&
        isExclusionAndConditionValid;

    useEffect(() => {
        if (onLocationItemValidate) {
            onLocationItemValidate(isLocationClauseValid, LocationClausesValidationID);
        }
        return () => {
            if (onLocationItemValidate) {
                onLocationItemValidate(true, LocationClausesValidationID);
            }
        };
    }, [isLocationClauseValid, onLocationItemValidate]);

    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            locationPublicID
        };
        return modalApi.showModal(
            <CPSearchAndAddCoveragesPopup {...componentProps} />
        );
    }, [authHeader, jobID, locationPublicID, modalApi, sessionUUID]);

    const onClickAddSearchCoverage = useCallback(async (covType) => {
        const data = await showCoveragesModal(covType)
        setLocationClauses(data)
    },[showCoveragesModal])


    if (!locationClauses) {
        return null;
    }

    const { standardCoverage, additionalCoverage, exclusion, condition } =
        locationClauses;

    const changeClauses = (value, changedPath, clauses, clausesPath) => {
        const newClauses = CPCoverageUtil.setClauseValue(
            clauses,
            clausesPath,
            value,
            changedPath
        );
        const newlocationClauses = _.clone(locationClauses);
        _.set(newlocationClauses, clausesPath, newClauses);
        setLocationClauses(newlocationClauses);
        setIsEditing(false);
        return newlocationClauses;
    };

    const changeStandardCoverage = (value, changedPath) => {
        return changeClauses(
            value,
            changedPath,
            standardCoverage,
            'standardCoverage'
        );
    };

    const changeAdditionalCoverage = (value, changedPath) => {
        return changeClauses(
            value,
            changedPath,
            additionalCoverage,
            'additionalCoverage'
        );
    };

    const changeExclusions = (value, changedPath) => {
        return changeClauses(value, changedPath, exclusion, 'exclusion');
    };

    const changeConditions = (value, changedPath) => {
        return changeClauses(value, changedPath, condition, 'condition');
    };

    const updateVMToServer = async (
        newlocationClauses,
        setLoadingClausesFunc,
        setEditingFunc
    ) => {
        const locationClausesToUpdate =
            CPCoverageUtil.generateUpdatedLocationClausesDTO(
                newlocationClauses
            );
        const updatedLocationClausesPublicIDs =
            CPCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedLocationClausesDTO(
                locationClausesToUpdate
            );
        setLoadingMask(true);
        setLoadingClausesFunc(updatedLocationClausesPublicIDs);
        const locationClausesFromServer =
            await CPLocationService.updateLocationClauses(
                jobID,
                sessionUUID,
                locationPublicID,
                locationClausesToUpdate,
                authHeader
            );
        setLoadingClausesFunc(false);
        setLoadingMask(false);
        setEditingFunc(false);
        setLocationClauses(locationClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM);
    };

    const updateNewStateClausesToServer = async (newLineClauses) => {
        updateVMToServer(newLineClauses, setLoadingClauses, setIsEditing);
    };

    const changeStandardCoverageAndSync = (value, changedPath) => {
        const newStateClauses = changeStandardCoverage(value, changedPath);

        updateNewStateClausesToServer(newStateClauses);
    };

    const changeAdditionalCoveragesAndSync = (value, changedPath) => {
        const newStateClauses = changeAdditionalCoverage(value, changedPath);

        updateNewStateClausesToServer(newStateClauses);
    };

    const changeExclusionAndSync = (value, changedPath) => {
        const newStateClauses = changeExclusions(value, changedPath);

        updateNewStateClausesToServer(newStateClauses);
    };

    const changeConditionAndSync = (value, changedPath) => {
        const newStateClauses = changeConditions(value, changedPath);

        updateNewStateClausesToServer(newStateClauses);
    };

    const onSyncCoverages = () => {
        updateNewStateClausesToServer(locationClauses);
    };

    const commonClausesProps = {
        splitByClauseCategory: false,
        sortBySelected: true,
        onSyncCoverages: onSyncCoverages,
        showAmount: false,
        loadingClause: loadingClauses,
        showErrors: showErrors,
        componentMapOverrides: {
            CPSingleClauseComponentVM: CPSingleClauseComponentVM,
        },
        setIsEditing: setIsEditing,
        isEditing: isEditing,
        isEditable: !readOnly,
        hideCheckBoxForRequired: true,
    };

    return (
        <CoverageContext.Provider
            value={{
                wizardData: submissionVM,
                updateWizardData: updateWizardDataWhileSetPeriodStatus,
                updateVMToServer: updateVMToServer,
            }}
        >
            <Accordion
                id="locationClausesAccordion"
                className="accordion-wizardTitle"
                closeOthers={false}
                showFrame={false}
            >
                <AccordionCard
                    id="LocationCoverages"
                    chevron
                    className="coveragesAccordion"
                    cardBodyClassName="accordionCardBody"
                    cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                    title={translator(messages.LocationCoverages)}
                    errorState={showErrors && !isStandardCoverageValid}
                >
                    <div className="formCoveragesContainer">
                        <CPClausesComponentVM
                            {...commonClausesProps}
                            onChangeSubmissionAndSync={
                                changeStandardCoverageAndSync
                            }
                            onChangeClause={changeStandardCoverage}
                            onValidate={onStandardCoverageValidate}
                            path="standardCoverage"
                            value={standardCoverage}
                        />
                    </div>
                </AccordionCard>
                <AccordionCard
                    id="AdditionalCoverages"
                    chevron
                    className="coveragesAccordion"
                    cardBodyClassName="accordionCardBody"
                    cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                    title={
                        readOnly ? (
                            translator(messages.AdditionalCoverages)
                        ) : (
                            <AccordionCardTitle
                                title={translator(messages.AdditionalCoverages)}
                                actionText={messages.AddCoverages}
                                onClick={() => {onClickAddSearchCoverage('additionalCoverages')}}
                            />
                        )
                    }
                    errorState={showErrors && !isAdditionalCoverageValid}
                >
                    <div className="formCoveragesContainer">
                        <CPClausesComponentVM
                            {...commonClausesProps}
                            onChangeSubmissionAndSync={
                                changeAdditionalCoveragesAndSync
                            }
                            onChangeClause={changeAdditionalCoverage}
                            onValidate={onAdditionalCoverageValidate}
                            path="additionalCoverage"
                            value={additionalCoverage}
                        />
                    </div>
                </AccordionCard>
                <AccordionCard
                    id="exclusionAndCondition"
                    chevron
                    className="coveragesAccordion"
                    cardBodyClassName="accordionCardBody"
                    cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                    title={
                        readOnly ? (
                            translator(messages.ExclusionsAndConditions)
                        ) : (
                            <AccordionCardTitle
                                title={translator(
                                    messages.ExclusionsAndConditions
                                )}
                                actionText={messages.AddExclusionsAndConditions}
                                onClick={() => {onClickAddSearchCoverage('exclusionsAndConditions')}}
                            />
                        )
                    }
                    errorState={showErrors && !isExclusionAndConditionValid}
                >
                    <div className="formCoveragesContainer">
                        <CPClausesComponentVM
                            {...commonClausesProps}
                            onChangeSubmissionAndSync={changeExclusionAndSync}
                            onChangeClause={changeExclusions}
                            onValidate={onExclusionAndConditionValidate}
                            path="exclusion"
                            value={exclusion}
                        />
                        <CPClausesComponentVM
                            {...commonClausesProps}
                            onChangeSubmissionAndSync={changeConditionAndSync}
                            onChangeClause={changeConditions}
                            onValidate={onExclusionAndConditionValidate}
                            path="condition"
                            value={condition}
                        />
                    </div>
                </AccordionCard>
            </Accordion>
        </CoverageContext.Provider>
    );
};

export default LocationClauses;
