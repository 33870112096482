import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ValidationIssuesComponent, QuoteUWIssuesPendingList } from 'wni-components-platform-react';
import { DocumentCheckedComponent } from 'wni-capability-gateway-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import {
    WniDateUtil,
    DocumentsUtil,
    ErrorsAndWarningsUtil,
    OOSUtil,
    UWIssueUtil,
} from 'wni-portals-util-js';
// import { WniSubmissionService } from 'wni-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniPAPolicyChangeService, WniCustomEndorsementService } from 'wni-capability-policychange';

import metadata from './PAPolicySummaryChangePage.metadata.json5';
import messages from './PAPolicySummaryChangePage.messages';


const TYPE_MOBILE = 'mobile';
const TYPE_HOME = 'home';
const TYPE_WORK = 'work';

function PAPolicySummaryChangePage(props) {
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        wizardData: submissionVM,
        updateWizardData,
        // UWCompanyInfo,
        history,
        wizardErrorsAndWarnings_DEPRECATED: wizardErrorsAndWarnings
    } = props;
    const localeService = ServiceManager.getService('locale-service');
    const { authHeader } = useAuthentication();
    const [policyChangeData, updatePolicyChangeData] = useState([]);
    const [initialized, updateInitialized] = useState(false);
    const [validationIssuesWarning, updateValidationIssuesWarning] = useState([]);

    const {
        jobID,
        errorsAndWarnings_Ext: errorsAndWarnings,
    } = submissionVM.value;

    useEffect(() => {
        const getAvailableDocumentsData = (async () => {
            const documents = await WniCustomEndorsementService
                .getDocumentsForPolicyChangeGroupByType([jobID], authHeader);
            const policyChangeDocs = DocumentsUtil.getDocumentData(documents,
                DocumentsUtil.POLICY_CHANGE);
            return { policyChangeDocs };
        });
        if (!initialized) {
            getAvailableDocumentsData().then((res) => {
                const { policyChangeDocs } = res;
                updatePolicyChangeData(policyChangeDocs);
            });
            updateInitialized(true);
        }
    }, [authHeader, initialized, jobID]);

    const policyPeriodStartDate = _.get(submissionVM, 'value.baseData.periodStartDate');

    const policyPeriodEndDate = _.get(submissionVM, 'value.baseData.periodEndDate');

    const policyPeriod = (
        <div>
            {`${WniDateUtil.formatDateWithPattern(policyPeriodStartDate)} `}
            {` - ${WniDateUtil.formatDateWithPattern(policyPeriodEndDate)} `}
        </div>
    );

    const effectiveDate = WniDateUtil
        .formatDateWithPattern(_.get(submissionVM, 'value.baseData.effectiveDate'));

    const getPhoneNumber = useCallback(() => {
        const primaryPhoneType = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.primaryPhoneType');
        let phoneNumber = '';
        switch (primaryPhoneType) {
            case TYPE_MOBILE:
                phoneNumber = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.cellNumber');
                break;
            case TYPE_HOME:
                phoneNumber = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.homeNumber');
                break;
            case TYPE_WORK:
                phoneNumber = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.workNumber');
                break;
            default: break;
        }
        return phoneNumber;
    }, [submissionVM]);

    const checkBind = useCallback((bindResult) => {
        const bindValidationIssues = _.get(
            bindResult,
            'errorsAndWarnings_Ext.validationIssues'
        ) || {};
        const bindIssues = _.get(bindValidationIssues, 'issues') || [];
        const bindFieldIssues = _.get(bindValidationIssues, 'fieldIssues') || [];
        if (!_.isEmpty(bindIssues) || !_.isEmpty(bindFieldIssues)) {
            const allIssues = bindIssues.concat(bindFieldIssues);
            if (
                !_.isEmpty(
                    allIssues.find((issue) => issue.type === 'error')
                )
            ) {
                // set errors
                const errors = allIssues.map((issue) => {
                    return {
                        type: issue.type,
                        reason: issue.reason,
                    };
                });
                updateValidationIssuesWarning(errors);
                return false;
            }
        }
        return true;
    }, []);

    const onNext = useCallback(
        async () => {
            // submissionVM.value = await WniPAPolicyChangeService.issue(submissionVM.jobID.value, authHeader);
            // return submissionVM;

            // no errorsAndWarnings then call bind
            const bindResult = await WniPAPolicyChangeService
                .issue(submissionVM.jobID.value, true, authHeader);
            if (!bindResult || !checkBind(bindResult)) {
                return false;
            }
            submissionVM.value = bindResult;
            return submissionVM;
        },
        [authHeader, checkBind, submissionVM]
    );

    const renderCellContent = useCallback((data, index, tableProps) => {
        const { path } = tableProps;
        if (path === 'licenseState') {
            return translator(
                {
                    id: `typekey.State.${data.licenseState}`,
                    defaultMessage: data.licenseState
                }
            );
        }
        return _.get(data, path);
    },
    [translator]);

    // ==============================================
    const infoUWIssues = UWIssueUtil.getPendingInfoIssues(errorsAndWarnings);
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssuesWarning.concat(
                OOSUtil.getOOSSliceDatesWarning(
                    OOSUtil.getOOSSliceDatesString(
                        _.get(submissionVM.value, 'oossliceDates_Ext', []),
                        _.get(submissionVM.value, 'baseData.effectiveDate')
                    ),
                    translator
                )
            ).concat(wizardErrorsAndWarnings),
            visible: validationIssuesWarning.length > 0
            || wizardErrorsAndWarnings.length > 0
            || OOSUtil.getOOSSliceDatesString(
                _.get(submissionVM.value, 'oossliceDates_Ext', []),
                _.get(submissionVM.value, 'baseData.effectiveDate')
            ).length > 0,
            issuesContainerId: 'validationIssuesComponentId',
            scrollToIssues: true,
        },
        phoneNumber: {
            countryCode: localeService.getDefaultPhoneCountry(),
            value: getPhoneNumber()
        },
        vehicleList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value')
        },
        driverList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value')
        },
        changeEffectiveDate: {
            value: effectiveDate
        },
        policyPeriod: {
            value: policyPeriod
        },
        documentcheckedList: {
            history: history,
            submission: submissionVM,
            renderData: [
                {
                    data: policyChangeData,
                },
                {
                    data: [],
                }
            ],
        },
        uwIssuePendingList: {
            visible: !_.isEmpty(infoUWIssues),
            uwIssues: infoUWIssues,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onRenderCell: renderCellContent
        },
        resolveComponentMap: {
            documentcheckedcomponent: DocumentCheckedComponent,
            validationissuescomponent: ValidationIssuesComponent,
            uwissuependinglist: QuoteUWIssuesPendingList,
        }
    };

    return (
        <WizardPage
            nextLabel={messages.submitChange}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                // onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PAPolicySummaryChangePage.propTypes = wizardProps;
export default PAPolicySummaryChangePage;
