import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
// import { useTranslator, IntlContext } from '@jutro/locale'; 
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
// import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { InputField } from '@jutro/components';
import { AQPolicyHolderUWQuestionComponent } from 'wni-capability-gateway-react';
import { WizardConstants } from 'wni-portals-config-js';
import { WniPNIUtil } from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';

import AQWizardPage from '../../template/AQWizardPage';
import AQWizardPageTempalte from '../../template/AQWizardPageTemplate';
import styles from './AQPolicyHolderPage.module.scss';
import metadata from './AQPolicyHolderPage.metadata.json5';

function AQPolicyHolderReadOnlyPage(props) {
    const {
        wizardData: accountQuoteDataVM,
        wizardPageData: {
            [WizardConstants.lastExitWizardPage]: submissionWizardExitPage
        },
        currentStepIndex
    } = props;

    // const history = useHistory();
    // const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        accountNumber,
        producerCodes,
        accountHolder
    } = accountQuoteDataVM.value;

    const [uwQuestions, setUWQuestions] = useState({
        anyCoverageDeclined: null,
        anyCoverageDeclinedExplainVisible: false,
        anyCoverageDeclinedExplainValue: null,
        anyApplicant: null,
        anyApplicantExplainVisible: false,
        anyApplicantExplainValue: null,
        transferPart: null
    });

    const onInit = async () => {
    };

    useEffect(() => {
        onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getAccountHolderDisplayName = useCallback(() => {
        const primaryAddress = WniPNIUtil.getPrimaryAddressDisplayName(
            _.get(accountQuoteDataVM, 'accountHolder.primaryAddress')
        );

        return (
            <div className='font-weight-normal'>
                <div className="mb-10">{accountNumber}</div>
                <div>{accountHolder.displayName}</div>
                <div className="HQPrimaryAddress">{primaryAddress}</div>
            </div>
        );
    }, [accountNumber, accountQuoteDataVM]);

    const AllAgencyContentReadOnly = () => {
        if (!producerCodes) {
            return (<InputField
                className="readOnlyFieldContainer"
                id="agencyCode"
                label="Agency"
                labelPosition="left"
                readOnly
                value="-"
            />)
        }
        return <>{
            producerCodes.map((producerCode) => (
                <li key={producerCode.code}>
                    <InputField
                        className="readOnlyFieldContainer"
                        id={producerCode.code}
                        label="Agency"
                        labelPosition="left"
                        readOnly
                        value={`${producerCode.code} - ${producerCode.description}(${producerCode.city_Ext} - ${producerCode.state_Ext})`}
                    />
                </li>
            ))
        }</>
        ;
    };
    // Waiting for isExistingAccount data from previous page

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        // residentHouseholdTable: {
        //     data: showResidentHouseholdTable()
        // },
        dynamicInlineNotificationContainer: {
            validationIssues: [],
            visible: false,
            scrollToIssues: true,
        },
        accountHolder: {
            value: getAccountHolderDisplayName()
        },
        accountHolderEditIcon: {
            visible: false,
        },
        createAccountMsg: {
            visible: false
        },
        // residentHouseholdSection: {
        //     visible: false
        // },
        additionalInfoSection: {
            visible: false
        },
        additionalInfoContent: {
            visible: false,
            uwQuestions,
            setUWQuestions,
        },
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            policyholderuwquestion: AQPolicyHolderUWQuestionComponent,
            agencyServiceinfo: AllAgencyContentReadOnly,
            validationissuescomponent: ValidationIssuesComponent
        }
    };


    //---------------------
    return (
        <AQWizardPage
            // showNext={false}
            // onNext={onNext}
            // onCancel={onCancel}
            showPrevious={false}
            showCancel={false}
            showNext={false}
            skipWhen={() => submissionWizardExitPage > currentStepIndex}
            template={AQWizardPageTempalte}
            cancelLabel="Cancel"
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                // onModelChange={updateWizardData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={}
            />
        </AQWizardPage>
    );
}

AQPolicyHolderReadOnlyPage.propTypes = AQWizardPage.propTypes;
export default AQPolicyHolderReadOnlyPage;
