import React from 'react';
import _ from 'lodash';
import {
    CurrencyField, Button
} from '@jutro/components';
import {
    ErrorsAndWarningsUtil,
    SxsUtil,
} from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import {
    QuoteUnderwritingIssuesList,
    EligibilityIssuesLinkComponent
} from 'wni-components-platform-react';
import { QuoteSideBySidePeriodMessages as messages } from 'wni-platform-translations';
import AccountClearanceLinkComponent from './QuoteAccountClearance/AccountClearanceLinkComponent';
import RecommendCoveragesComponent from './RecommendCoverages/RecommendCoveragesComponent';
import ReferToUnderwriterComponent from '../components/ReferToUnderwriterComponent/ReferToUnderwriterComponent';
// import messages from './SideBySidePeriodsComponent.messages';

const INELIGIBLE_UW_ISSUE_CODES = ['PAIneligibleSelect_Ext', 'PAEligibleSelectOnly_Ext', 'PAIneligibleSelectSignature_Ext', 'PABothKnockout_Ext', 'PAEligibleSelectOnly_Ext'];
const DISABLE_BTN_INELIGIBLE_UW_ISSUE_CODES = [
    'PABothKnockout_Ext',
    'PAEligibleSelectOnly_Ext',
    'PAEffectiveDateAfterPendingRate_Ext',
    'HOPEffectiveDateAfterPendingRate_Ext',
    'RTEffectiveDateGreaterThan35DaysInTheFuture_Ext',
    'WALEffectiveDateGreaterThan35DaysInTheFuture_Ext',
    'UMBEffectiveDateGreaterThan35DaysInTheFuture_Ext'
];

const getFormattedCurrency = (premiumAmount, className) => {
    return (
        <CurrencyField
            id="currency"
            className={className}
            value={premiumAmount}
            readOnly
            hideLabel
            showOptional={false}
        />
    );
};

const getButtonsVisibility = (sxsPeriodDto) => {
    const {
        data: {
            periodStatus,
            quoteFlow_Ext: quoteFlow,
        },
        // isEditable = true,
        underwritingIssues
    } = sxsPeriodDto;
    const hasBlockingUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(
        { underwritingIssues: underwritingIssues }
    );
    const isDraftPeriod = periodStatus === 'Draft';
    const isFinishOrSubmitFlow = quoteFlow === 'finishquote' || quoteFlow === 'submitquote';
    const isReadyForIssue = SxsUtil.isQuotedOrApproved(periodStatus) && isFinishOrSubmitFlow;
    // if (!isEditable) {
    //     return {
    //         showContinueWithQuoteBtn: false,
    //         showRunFinalValidationBtn: false,
    //         showReferToUWBtn: false,
    //         showContinueToIssueBtn: false,
    //         showGetUpdatedRateBtn: false,
    //     };
    // }
    const showContinueWithQuoteBtn = quoteFlow === 'firstquote' && periodStatus === 'Rated';
    const showRunFinalValidationBtn = isFinishOrSubmitFlow && periodStatus === 'Rated';
    const showReferToUWBtn = hasBlockingUWIssue && !(periodStatus === 'Draft' || showContinueWithQuoteBtn || showRunFinalValidationBtn);
    const showContinueToIssueBtn = !hasBlockingUWIssue && isReadyForIssue;
    const showGetUpdatedRateBtn = isDraftPeriod;
    return {
        showContinueWithQuoteBtn,
        showRunFinalValidationBtn,
        showReferToUWBtn,
        showContinueToIssueBtn,
        showGetUpdatedRateBtn,
    };
};

/**
 * Table Rows are defined in SideBySidePeriodsComponent.static.js
 * @param {object} props
 * @returns {object}
 */
function SideBySidePeriodsUtil(props) {
    const {
        translator,
        isLoading,
        isReadyForRemoval,
        onRemoveSideBySidePair
    } = props;
    const renderCellContent = (tableRow, index, propParams) => {
        const {
            rowId
        } = tableRow;
        // remove Quote Version
        if (rowId === 'removeSxsPairBtn') {
            const {
                publicID
            } = propParams;
            return isReadyForRemoval && (
                <Button
                    id={`removeSxsPairBtn${publicID}`}
                    className="grap"
                    type="outlined"
                    onClick={() => onRemoveSideBySidePair(publicID)}
                >
                    {renderHelper.getButtonContent(translator(messages.deleteSideBySidePairBtn), isLoading, `removeSxsPairBtn${publicID}`)}
                </Button>
            );
        }

        const {
            callbackMap: {
                goToIncidentReports,
                onAccountClearance,
                postAccountMerge,
                goToDriverCoveragesPage,
                onFinishQuote,
                onSubmitQuote,
                onReferToUnderwriter,
                onContinueToIssue,
                onRateAll: onGetUpdatedRate
            },
            data: {
                columnIndex,
                publicID,
                periodStatus,
                policyType_Ext: policyType,
                branchName,
                nonFullPayAmount_Ext: monthlyPayTotal,
                fullPayAmount_Ext: fullPayTotal,
                quoteFlow_Ext: quoteFlow,
                coverageIssues,
            },
            // isEditable = true, // Consider to remove this prop for SideBySidePeriodsComponentV2
            currentAccountNumber,
            showAccountClearanceLink,
            isMonthlyPayment,
            underwritingIssues,
            eligibilityIssues,
            isClueCompare,
            isMvrCompare,
            isMvrClueServiceCalled,
        } = propParams.sxsPeriod;

        // ref: PAQuoteUIHelper.getColumnData()
        const vehicleCoverages = _.get(propParams.lobOffering.data, 'coverages.vehicleCoverages', []);
        const vehicleFixedIds = vehicleCoverages.map((vehCov) => vehCov.fixedId);

        const {
            showReferToUWBtn,
            showContinueToIssueBtn,
            showContinueWithQuoteBtn,
            showRunFinalValidationBtn,
            showGetUpdatedRateBtn,
        } = getButtonsVisibility(propParams.sxsPeriod);

        const hasBlockingUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(
            { underwritingIssues: underwritingIssues }
        );
        const hasIneligibleUwIssue = ErrorsAndWarningsUtil.hasUnApprovedIneligibleUWIssue(
            underwritingIssues, INELIGIBLE_UW_ISSUE_CODES
        );
        const disableBtnWithIneligibleUwIssue = ErrorsAndWarningsUtil.hasUnApprovedIneligibleUWIssue(
            underwritingIssues, DISABLE_BTN_INELIGIBLE_UW_ISSUE_CODES
        );

        // for UWI 0102 only disable buttons for signature
        const ineligibleUWIssueValidate = () => {
            const hasPAIneligibleSelectSignature = ErrorsAndWarningsUtil.hasUnApprovedIneligibleUWIssue(
                underwritingIssues, ['PAIneligibleSelectSignature_Ext']
            );
            if (hasPAIneligibleSelectSignature) {
                if (policyType === 'Signature') {
                    return false;
                }
                return true;
            }
            return true;
        }

        // const triggerActivity = _.filter(underwritingIssues, { issueTypeCode_Ext: 'ProtalHasRiskMatch' }).length > 0
        //                         || _.filter(underwritingIssues, { issueTypeCode_Ext: 'ProtalHasRiskIssuePolicy' }).length > 0;
        const triggerActivity = underwritingIssues && underwritingIssues.some((issue) => {
            const issueCode = issue.issueTypeCode_Ext;
            return issueCode === 'ProtalHasRiskMatch' || issueCode === 'ProtalHasRiskIssuePolicy';
        });
        const isDraftPeriod = periodStatus === 'Draft';

        const isCompareViolationDiff = !isClueCompare && isMvrCompare;
        const isComparePriorLossDiff = isClueCompare && !isMvrCompare;
        const isCompareAllDiff = isClueCompare && isMvrCompare;

        const hasMvrOrClueData = isClueCompare || isMvrCompare;
        const showClearMvrClueLink = periodStatus === 'Quoted' && isMvrClueServiceCalled && !hasMvrOrClueData;

        const isFinishOrSubmitFlow = quoteFlow === 'finishquote' || quoteFlow === 'submitquote';
        const policyTypeWithSuffix = `${policyType} Auto`;

        let renderContent;
        switch (rowId) {
            case 'periodStatus':
                renderContent = (
                    <div className="grap align-right">
                        {periodStatus}
                    </div>
                );
                break;
            case 'policyType':
                renderContent = (
                    <div className="font-Primary-bold-16 grap">
                        {policyTypeWithSuffix}
                    </div>
                );
                break;
            case 'branchName':
                renderContent = isReadyForRemoval && (
                    <div className="grap">
                        {branchName}
                    </div>
                );
                break;
            case 'withThePenaltyFreePromise':
                renderContent = policyType === 'Signature' && (
                    <div className="grap">
                        {translator(messages.withThePenaltyFreePromise)}
                    </div>
                );
                break;
            case 'payTotal':
                renderContent = !isDraftPeriod && (
                    <div className="grap">
                        {getFormattedCurrency(isMonthlyPayment ? monthlyPayTotal : fullPayTotal, 'font-Primary-bold-36')}
                    </div>
                );
                break;
            case 'premiumFullDiscount':
                renderContent = (
                    <div className="font-secondary-light-16 grap">
                        {isMonthlyPayment
                            ? translator(messages.withoutPayFullDiscount)
                            : translator(messages.withPayFullDiscount)}
                    </div>
                );
                break;
            case 'underwritingIssues':
                renderContent = (
                    <div className="grap">
                        <QuoteUnderwritingIssuesList
                            id="quoteUWIssues"
                            className="grap"
                            headerObject={messages.IssuesRequiringReferral}
                            underwritingIssues={underwritingIssues}
                        />
                    </div>
                );
                break;
            case 'eligibilityIssuesLink':
                renderContent = (!_.isEmpty(eligibilityIssues) && hasIneligibleUwIssue) ? (
                    <div className="grap">
                        <EligibilityIssuesLinkComponent
                            id="eligibilityIssuesLink"
                            policyType={policyTypeWithSuffix}
                            eligibilityIssues={eligibilityIssues}
                            errorMode={hasBlockingUWIssue}
                        />
                    </div>
                ) : null;
                break;
            case 'accountClearanceLink':
                renderContent = showAccountClearanceLink && (showReferToUWBtn || showContinueToIssueBtn) ? (
                    <div className="grap">
                        <AccountClearanceLinkComponent
                            id="accountClearanceLink"
                            onAccountClearance={async () => onAccountClearance(publicID)}
                            postAccountMerge={postAccountMerge}
                            currentAccountNumber={currentAccountNumber}
                        />
                    </div>
                ) : null;
                break;
            case 'recommendedCoverages':
                renderContent = !_.isEmpty(coverageIssues) ? (
                    <div className="grap">
                        <RecommendCoveragesComponent
                            columnIndex={columnIndex}
                            coverageIssues={coverageIssues}
                            goToDriverCoveragesPage={goToDriverCoveragesPage}
                            orderedVehicleFixedIds={vehicleFixedIds}
                        />
                    </div>
                ) : null;
                break;
            case 'submitAndDeleteActions':
                renderContent = (
                    <div className="grap">
                        {showContinueWithQuoteBtn && (
                            <Button
                                id={`continueWithQuoteBtn${publicID}`}
                                type="outlined"
                                visible={showContinueWithQuoteBtn}
                                onClick={async () => onFinishQuote(publicID)}
                            >
                                {renderHelper.getButtonContent(translator(messages.continueWithQuote), isLoading, `continueWithQuoteBtn${publicID}`)}
                            </Button>
                        )}
                        {showRunFinalValidationBtn && (
                            <Button
                                id={`runFinalValidationBtn${publicID}`}
                                type="outlined"
                                visible={showRunFinalValidationBtn}
                                onClick={async () => onSubmitQuote(publicID)}
                            >
                                {renderHelper.getButtonContent(translator(messages.runFinalValidation), isLoading, `runFinalValidationBtn${publicID}`)}
                            </Button>
                        )}
                        {showReferToUWBtn && (
                            <ReferToUnderwriterComponent
                                // id="referToUWBtn"
                                referToUnderwriterID={`referToUWBtn${publicID}`}
                                visible={showReferToUWBtn}
                                onReferToUnderwriter={async (noteForUW) => onReferToUnderwriter(noteForUW, publicID, triggerActivity)}
                                isServiceCallInProgress={isLoading}
                                referToUWButtonEnabled={!disableBtnWithIneligibleUwIssue && ineligibleUWIssueValidate()}
                                noteProcessor={(note) => {
                                    const userNote = note || '';
                                    return `${userNote} \n\n ${policyType} ${branchName} referred`;
                                }}
                            />
                        )}
                        {showContinueToIssueBtn && (
                            <Button
                                id={`continueToIssueBtn${publicID}`}
                                type="outlined"
                                visible={showContinueToIssueBtn}
                                onClick={async () => onContinueToIssue(publicID)}
                            >
                                {renderHelper.getButtonContent(translator(messages.continueToIssue), isLoading, `continueToIssueBtn${publicID}`)}
                            </Button>
                        )}
                        {showGetUpdatedRateBtn && (
                            <Button
                                id={`getUpdatedRateBtn${publicID}`}
                                type="outlined"
                                visible={showGetUpdatedRateBtn}
                                onClick={async () => onGetUpdatedRate(publicID)}
                            >
                                {renderHelper.getButtonContent(translator(messages.getUpdatedRate), isLoading, `getUpdatedRateBtn${publicID}`)}
                            </Button>
                        )}
                    </div>
                );
                break;
            case 'compareReviewViolationLink':
                renderContent = (isFinishOrSubmitFlow && isCompareViolationDiff) ? (
                    <Button
                        id="compareReviewViolationLink"
                        className="btn-link grap"
                        onClick={goToIncidentReports}
                    >
                        {translator(messages.reviewViolation)}
                    </Button>
                ) : null;
                break;
            case 'compareReviewLossLink':
                renderContent = (isFinishOrSubmitFlow && isComparePriorLossDiff) ? (
                    <Button
                        id="compareReviewLossLink"
                        className="btn-link grap"
                        onClick={goToIncidentReports}
                    >
                        {translator(messages.reviewLoss)}
                    </Button>
                ) : null;
                break;
            case 'compareReviewAllLink':
                renderContent = (isFinishOrSubmitFlow && isCompareAllDiff) ? (
                    <Button
                        id="compareReviewAllLink"
                        className="btn-link grap"
                        onClick={goToIncidentReports}
                    >
                        {translator(messages.reviewViolationLoss)}
                    </Button>
                ) : null;
                break;
            case 'clearMvrClueLink':
                renderContent = (isFinishOrSubmitFlow && showClearMvrClueLink) ? (
                    <Button
                        id="compareReviewAllLink"
                        className="btn-link grap"
                        onClick={goToIncidentReports}
                    >
                        {translator(messages.clearMvrAndClue)}
                    </Button>
                ) : null;
                break;
            default:
                renderContent = null;
                break;
        }
        return renderContent;
    };

    const getTableColumnContent = (columnDataParam) => {
        if (!columnDataParam) {
            return null;
        }
        return columnDataParam.map((colData, index) => {
            return {
                id: `sxsVersionColumn${index}`,
                type: 'element',
                component: 'tablecolumn',
                componentProps: {
                    textAlign: 'center',
                    columnProportion: 1,
                    renderCell: renderCellContent,
                    ...colData
                }
            };
        });
    };

    return {
        getTableColumnContent
    };
}

export default SideBySidePeriodsUtil;
