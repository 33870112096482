import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button, CheckboxField } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { DocumentsUtil, ServiceErrorUtil } from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { QuoteUWIssuesPendingList, QuoteUnderwritingIssuesList, UnderwritingIssuesSimpleList, useWniModal } from 'wni-components-platform-react';
import { WniMultiDocumentRetrievalService, WniDocumentRetrievalService } from 'wni-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import BaseDocumentSendViaEmailPopup from '../BaseDocumentSendViaEmailPopup/BaseDocumentSendViaEmailPopup';
import metadata from './BaseDocumentCheckedComponent.metadata.json5';
import styles from './BaseDocumentCheckedComponent.module.scss';
import messages from './BaseDocumentCheckedComponent.messages';

function BaseDocumentCheckedComponent(props) {
    const modalApi = useWniModal();
    const {
        renderData,
        history: deprecatedHistory, // this prop is needed no more
        wizardTitleClass, submission, showSendViaEmail, showQuoteDocTitleAction,
        useJobNumber, forceUI, forceOnDocSelectedAllFn, forceOverrideProps,
        forceGetCheckBoxFn, forcehandleValueChangeFn, forceDocumentChecked,
        forceHasDocSelected, forceDocuments, forceOnDocPrintAllFn,
        showCheckboxIcon, forceDocumentColumnName, useAccountNumber,
        accountNumber
    } = props;

    const history = useHistory();
    const { authHeader } = useAuthentication();

    //
    const documentData = renderData.map(() => {
        const docSelected = [];
        return docSelected;
    });
    const [documentChecked, updateDocumentChecked] = useState(forceDocumentChecked || documentData);
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const successCallback = () => {
        setLoadingMask(false);
    };
    const errorCallback = () => {
        setLoadingMask(false);
        modalApi.showConfirm({
            title: 'Error',
            message: ServiceErrorUtil.prependWithFriendMessage(),
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: messages.ok,
        }).then(() => {
            _.noop();
        }).catch(() => {
            _.noop();
        });
    };

    const printAllErrorCallBack = (exception) => {
        setLoadingMask(false);
        // 408 - timeout
        if (_.get(exception, 'status') === 408) {
            alert(DocumentsUtil.NO_DOCUID_NOTIFICATION);
        } else {
            modalApi.showConfirm({
                title: 'Error',
                message: ServiceErrorUtil.prependWithFriendMessage(),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: messages.ok,
            }).then(() => {
                _.noop();
            }).catch(() => {
                _.noop();
            });
        }
    };

    const getRenderDocFn = (item) => {
        const tryDownloadDocument = async () => {
            if (_.includes(item.publicID, 'bc')) {
                const targetProxiedServiceUrl = 'wniBCDocumentRetrieval';
                await DocumentsUtil.tryDownloadDocument(
                    item, authHeader, history, WniDocumentRetrievalService,
                    successCallback, errorCallback, targetProxiedServiceUrl
                );
            } else {
                setLoadingMask(true);
                await DocumentsUtil.tryDownloadDocument(
                    item, authHeader, history, WniDocumentRetrievalService,
                    successCallback, errorCallback
                );
            }
        };
        const publicID = _.get(item, 'publicID');
        if (_.isEmpty(publicID)) {
            return (<div />);
        }
        if (publicID.includes('ignore')) {
            return (
                <div
                    className={`${styles.tableContent}`}
                    id={`Link${item.publicID}`}
                >
                    {item[forceDocumentColumnName]}
                </div>
            );
        }
        return (
            <Button
                className="wni-button-link align-left"
                onClick={tryDownloadDocument}
                id={`Link${item.publicID}`}
            >
                {item[forceDocumentColumnName]}
            </Button>
        );
    };
    const docFormChange = (value, index) => {
        const updatedValues = [...documentChecked];
        updatedValues[index] = value;
        updateDocumentChecked(updatedValues);
    };
    const onDocSelectedAllFn = () => {
        if (_.isFunction(forceOnDocSelectedAllFn)) {
            const updatedValues = forceOnDocSelectedAllFn();
            updateDocumentChecked(updatedValues);
        } else {
            const updatedValues = [...documentChecked];
            renderData.map((item, index) => {
                const docSelectedAll = item.data.map((v) => {
                    const valuesList = v.publicID;
                    return valuesList;
                });
                updatedValues[index] = docSelectedAll;
                return true;
            });
            updateDocumentChecked(updatedValues);
        }
    };

    const onDocPrintAllFn = async () => {
        if (_.isFunction(forceOnDocPrintAllFn)) {
            forceOnDocPrintAllFn(renderData, documentChecked,
                setLoadingMask, DocumentsUtil, authHeader, history,
                WniDocumentRetrievalService, successCallback, errorCallback,
                WniMultiDocumentRetrievalService, printAllErrorCallBack)
        } else {
            // left part
            const datasLeft = renderData[0].data || [];
            const docsLeft = datasLeft.filter((data) => {
                return documentChecked[0].find((item) => item === data.publicID);
            });
            const docsToCombine = [];
            if (!_.isEmpty(docsLeft)) {
                _.forEach(docsLeft, (doc) => {
                    docsToCombine.push(doc);
                });
            }
            // right part
            const datasRight = renderData[1].data || [];
            const docsRight = datasRight.filter((data) => {
                return documentChecked[1].find((item) => item === data.publicID && !item.includes('ignore'));
            });
            if (!_.isEmpty(docsRight)) {
                _.forEach(docsRight, (doc) => {
                    docsToCombine.push(doc);
                });
            }
            // length === 1 and docUID exist call OOTB download
            if (docsToCombine.length === 1) {
                // only one document
                const doc = docsToCombine[0];
                setLoadingMask(true);
                await DocumentsUtil.tryDownloadDocument(
                    doc, authHeader, history, WniDocumentRetrievalService,
                    successCallback, errorCallback
                );
            } else {
                setLoadingMask(true);
                await DocumentsUtil.printAllDocuments(
                    docsToCombine, authHeader, WniMultiDocumentRetrievalService,
                    successCallback, printAllErrorCallBack
                );
            }
        }
    };

    const updateResultArray = (array, value) => {
        let rs = array;
        if (array.includes(value)) {
            // reset array list
            rs = [];
            _.forEach(array, (item) => {
                if (item !== value) {
                    rs.push(item);
                }
            });
        } else {
            // add select
            rs.push(value);
        }
        return rs;
    };

    const handleValueChange = (value, publicID) => {
        if (_.isFunction(forcehandleValueChangeFn)) {
            const updatedValues = forcehandleValueChangeFn(documentChecked, value, publicID);
            const values = _.cloneDeep(updatedValues);
            updateDocumentChecked(values);
        } else {
            const leftMatch = renderData[0].data.find((item) => item.publicID === publicID);
            if (leftMatch) {
                docFormChange(updateResultArray(documentChecked[0], publicID), 0);
            } else {
                docFormChange(updateResultArray(documentChecked[1], publicID), 1);
            }
        }
    };

    const getCheckBoxFn = (item) => {
        const publicID = _.get(item, 'publicID');
        if (_.isEmpty(publicID) || publicID.includes('ignore') || !showCheckboxIcon) {
            return (<div />);
        }
        let leftMatch = null;
        let rightMatch = null;
        if (forceGetCheckBoxFn) {
            const checkBox = forceGetCheckBoxFn(documentChecked, item);
            leftMatch = _.get(checkBox, 'leftMatch');
            rightMatch = _.get(checkBox, 'rightMatch');
        } else {
            leftMatch = documentChecked[0].find((checked) => checked === item.publicID);
            rightMatch = documentChecked[1].find((checked) => checked === item.publicID);
        }
        const value = !!(leftMatch || rightMatch);
        return (
            <CheckboxField
                className={styles.checkboxStyles}
                path={`${item.publicID}`}
                onValueChange={handleValueChange}
                id={`CheckBox${item.publicID}`}
                value={value}
                layout="full-width"
            >
                {value || null}
            </CheckboxField>
        );
    };

    const onEmailSendFn = useCallback(() => {
        let updatedValues = [];
        _.each(renderData, (data, idx) => {
            const docs = _.get(data, 'data');
            if (idx === 0 && !_.isEmpty(docs)) {
                updatedValues = updatedValues.concat(docs);
            } else if (idx === 1 && !_.isEmpty(docs)) {
                const validDocs = _.filter(docs, (doc) => !_.get(doc, 'publicID', '').includes('gnore'));
                updatedValues = updatedValues.concat(validDocs);
            }
        });

        const componentProps = {
            isOpen: true,
            submission,
            documents: forceDocuments || updatedValues,
            useJobNumber,
            useAccountNumber,
            accountNumber
        };
        modalApi.showModal(<BaseDocumentSendViaEmailPopup {...componentProps} />)
            .then((res) => {
                if (res) {
                    modalApi.showAlert({
                        title: messages.success,
                        message: messages.successContent,
                        status: 'info',
                        icon: 'gw-info-outline',
                        confirmButtonText: messages.close
                    }).then(() => {
                        _.noop();
                    }, _.noop);
                } else {
                    modalApi.showAlert({
                        title: messages.notSend,
                        message: messages.notSendContent,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: messages.close
                    }).then(() => {
                        _.noop();
                    }, _.noop);
                }
            }).catch(() => {
                _.noop();
            });
    }, [renderData, submission]);

    const generateOverrides = useCallback(() => {
        const overrides = renderData.map((item, index) => {
            return {
                [`docFormTable${index}`]: {
                    data: item.data,
                    onSelectionChange: (value) => docFormChange(value, index),
                    selectedRows: documentChecked[index]
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [renderData, documentChecked]);

    const hasDocSelected = forceHasDocSelected
        ? forceHasDocSelected(documentChecked) : _.some(documentChecked, (arr) => {
            return _.get(arr, 'length') > 0;
        });

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        quoteDocHeader: {
            className: `title ${wizardTitleClass}`
        },
        docTableContainer: {
            data: renderData
        },
        sendViaEmail: {
            visible: showSendViaEmail
        },
        quoteDocSelected: {
            disabled: !hasDocSelected
        },
        quoteDocTitleAction: {
            visible: showQuoteDocTitleAction
        },
        ...generateOverrides()
    };

    Object.assign(overrideProps, forceOverrideProps);

    const resolvers = {
        resolveCallbackMap: {
            getRenderDocFn,
            onDocSelectedAllFn,
            getCheckBoxFn,
            onEmailSendFn,
            onDocPrintAllFn
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            uwissuependinglist: QuoteUWIssuesPendingList,
            quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
            underwritingissuessimplelist: UnderwritingIssuesSimpleList
        }
    };

    const uiProps = _.isEmpty(forceUI) ? metadata.pageContent : forceUI;

    return (
        <ViewModelForm
            uiProps={uiProps}
            model={documentChecked}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

BaseDocumentCheckedComponent.propTypes = {
    renderData: PropTypes.arrayOf.isRequired,
    wizardTitleClass: PropTypes.string,
    showSendViaEmail: PropTypes.bool,
    history: PropTypes.shape({}),
    submission: PropTypes.shape({}),
    useJobNumber: PropTypes.bool,
    forceOverrideProps: PropTypes.shape({}),
    forceUI: PropTypes.shape({}),
    forceOnDocSelectedAllFn: PropTypes.func,
    forceGetCheckBoxFn: PropTypes.func,
    forcehandleValueChangeFn: PropTypes.func,
    forceDocumentChecked: PropTypes.arrayOf(PropTypes.shape({})),
    forceHasDocSelected: PropTypes.bool,
    forceDocuments: PropTypes.arrayOf(PropTypes.shape({})),
    forceOnDocPrintAllFn: PropTypes.func,
    showQuoteDocTitleAction: PropTypes.bool,
    showCheckboxIcon: PropTypes.bool,
    forceDocumentColumnName: PropTypes.string
};
BaseDocumentCheckedComponent.defaultProps = {
    wizardTitleClass: '',
    showSendViaEmail: true,
    history: {},
    submission: {},
    useJobNumber: false,
    forceOverrideProps: {},
    forceUI: {},
    forceOnDocSelectedAllFn: null,
    forceGetCheckBoxFn: null,
    forcehandleValueChangeFn: null,
    forceDocumentChecked: null,
    forceHasDocSelected: false,
    forceDocuments: null,
    forceOnDocPrintAllFn: null,
    showQuoteDocTitleAction: true,
    showCheckboxIcon: true,
    forceDocumentColumnName: 'displayName'
};
export default BaseDocumentCheckedComponent;
