import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
    Tooltip, Button, Icon, IconButton
} from '@jutro/components';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';

import LocationSummary from '../summary/LocationSummary/LocationSummary';
import BuildingSummary from '../summary/BuildingSummary/BuildingSummary';
import CoverageSummary from '../summary/CoverageSummary/CoverageSummary';

import EditLocationSummary from '../summary/EditLocationSummary/EditLocationSummary';
import EditBuildingSummary from '../summary/EditBuildingSummary/EditBuildingSummary';
import EditCoveragesSummary from '../summary/EditCoveragesSummary/EditCoveragesSummary';

import metadata from './ViewBuildingInfo.metadata.json5';
import styles from './ViewBuildingInfo.module.scss';
import messages from '../../PECPWizard.messages';

function TooltipWithContent({ onRemoveBuildingClicked }) {
    const translator = useTranslator();

    return (
        <Tooltip
            id="tooltipDeleteBuilding"
            placement="bottom"
            trigger="mouseenter"
            content={translator(messages.deleteBuildingTooltip)}
        >
            <IconButton
                aria-haspopup="true"
                visible
                icon="mi-delete"
                onClick={onRemoveBuildingClicked}
            />
        </Tooltip>
    );
}

TooltipWithContent.propTypes = {
    onRemoveBuildingClicked: PropTypes.func.isRequired
};

const ViewBuildingInfo = (props) => {
    const {
        isLocation,
        title,
        submission,
        updateWizardData,
        buildingInfo,
        viewBuildingDetails,
        backToBuildingsPage,
        deleteBuilding,
        editBuilding,
        addBuildingToSelectedLocation
    } = props;
    const translator = useTranslator();
    const [buildingsData, updateBuildingsData] = useState([]);
    const [buildingsCount, updateBuildingsCount] = useState(0);
    const [showEditLocation, setEditLocationVisible] = useState(false);
    const [showEditBuilding, setEditBuildingVisible] = useState(false);
    const [showEditCoverages, setEditCoveragesVisible] = useState(false);
    const [collapseAllSections, setCollapseAllSections] = useState(false);
    const [isEditComplete, setIsEditComplete] = useState(false);
    const [toggleVisibility, setToggleVisibility] = useState({
        visible: false,
        message: ''
    });
    const getLocationBuilding = useCallback(() => {
        const locationList = _.get(
            submission.value,
            'lobData.commercialProperty.coverables.locations'
        );
        let buildingImpact = {};
        const locationImpact = locationList.find((location) => {
            buildingImpact = location.buildings.find((building) => {
                return building.publicID === (buildingInfo.buildingId || buildingInfo.publicID);
            });
            return buildingImpact !== undefined;
        });

        return {
            selectedLocation: locationImpact,
            selectedBuilding: buildingImpact
        };
    }, [buildingInfo, submission]);

    const removeNotification = useCallback(() => {
        if (collapseAllSections) {
            setToggleVisibility({
                visible: false,
                message: ''
            });
        }
    }, [collapseAllSections]);

    const onEditLocationClicked = useCallback(() => {
        removeNotification();
        setEditLocationVisible(true);
        setEditBuildingVisible(false);
        setEditCoveragesVisible(false);
    }, [removeNotification]);

    const onEditBuildingClicked = useCallback(() => {
        removeNotification();
        setEditBuildingVisible(true);
        setEditCoveragesVisible(false);
        setEditLocationVisible(false);
    }, [removeNotification]);

    const onEditCoveragesClicked = useCallback(() => {
        removeNotification();
        setEditCoveragesVisible(true);
        setEditLocationVisible(false);
        setEditBuildingVisible(false);
    }, [removeNotification]);

    useEffect(() => {
        const { selectedLocation } = getLocationBuilding();
        const buildings = _.get(selectedLocation, 'buildings', []);
        const data = buildings.map((building) => {
            return {
                buildingDescription: building.name,
                buildingClassCode: building.classCode.code,
                building: building
            };
        });
        updateBuildingsData(data);
        updateBuildingsCount(buildings.length);

        switch (editBuilding) {
            case 'location':
                onEditLocationClicked();
                break;
            case 'building':
                onEditBuildingClicked();
                break;
            case 'coverages':
                onEditCoveragesClicked();
                break;
            default:
                break;
        }
    }, [
        updateBuildingsData,
        updateBuildingsCount,
        editBuilding,
        onEditLocationClicked,
        onEditBuildingClicked,
        onEditCoveragesClicked,
        getLocationBuilding
    ]);

    const renderBuildingDescription = useCallback(
        (item, index, { path: property }) => {
            return (
                <Button
                    type="text"
                    className={styles.gwBuildingAndLocationNameLink}
                    onClick={() => viewBuildingDetails(item.building, 'building')}
                >
                    {item[property]}
                </Button>
            );
        },
        [viewBuildingDetails]
    );

    const removeBuilding = useCallback(() => {
        const { selectedBuilding } = getLocationBuilding();
        deleteBuilding(selectedBuilding.locationId, selectedBuilding.publicID);
    }, [deleteBuilding, getLocationBuilding]);

    const getBuildingsTableTitle = useCallback(() => {
        return (
            <>
                <Icon
                    icon="mi-business"
                    className={styles.gwBuildingsIcon}
                />
                {translator(messages.buildingsAtThisLocation, { buildingsCount: buildingsCount })}
            </>
        );
    }, [translator, buildingsCount]);

    const completeEditLocationAndBuilding = useCallback(
        (value, isCompleteEdit, isCancelled, summary) => {
            if (isCompleteEdit) {
                if (!isLocation) {
                    setCollapseAllSections(value);
                }
                setIsEditComplete(!isEditComplete);
                setEditLocationVisible(!value);
                setEditBuildingVisible(!value);
                setEditCoveragesVisible(!value);
            }
            setToggleVisibility({
                visible: !isCancelled,
                message: summary
            });
        },
        [isEditComplete, isLocation]
    );

    const addBuildingButtonClicked = useCallback(() => {
        const { selectedLocation } = getLocationBuilding();
        addBuildingToSelectedLocation(selectedLocation);
    }, [getLocationBuilding, addBuildingToSelectedLocation]);

    const { selectedLocation, selectedBuilding } = getLocationBuilding();

    const overrideProps = {
        propertyHeaderTitle: {
            content: title
        },
        removeBuildingContainer: {
            visible: !isLocation
        },
        locationSummary: {
            submission: selectedLocation,
            summaryIcon: 'mi-place',
            openByDefault: isLocation && !collapseAllSections,
            showIconOnly: isLocation,
            onEditClicked: onEditLocationClicked
        },
        buildingSummary: {
            visible: !isLocation && !showEditBuilding,
            submission: selectedBuilding,
            summaryIcon: 'mi-business',
            onEditClicked: onEditBuildingClicked
        },
        coverageSummary: {
            visible: !isLocation && !showEditCoverages,
            coverageDetails: selectedBuilding,
            summaryIcon: 'mi-list_alt',
            openByDefault: !isLocation && !collapseAllSections && !showEditBuilding,
            onEditClicked: onEditCoveragesClicked,
            showLimit: true
        },
        buildingsTableContainer: {
            data: buildingsData
        },
        buildingsTableWrapper: {
            visible: isLocation
        },
        buildingsCount: {
            value: buildingsCount
        },
        buildingTableTitle: {
            content: getBuildingsTableTitle()
        },
        viewBuildingInfoContainer: {
            visible: !showEditLocation
        },
        editLocationInfoContainer: {
            visible: showEditLocation && !showEditBuilding && !showEditCoverages,
            submissionVM: submission,
            updateWizardData: updateWizardData,
            locationToEdit: selectedLocation,
            completeEdit: (value, isCompleteEdit, isCancelled = false) => {
                return completeEditLocationAndBuilding(
                    value,
                    isCompleteEdit,
                    isCancelled,
                    'Location'
                );
            }
        },
        editBuildingInfoContainer: {
            visible: showEditBuilding && !showEditCoverages && !showEditLocation,
            submissionVM: submission,
            selectedLocation: selectedLocation,
            buildingToEdit: selectedBuilding,
            updateWizardData: updateWizardData,
            completeEdit: (value, isCompleteEdit, isCancelled = false) => {
                return completeEditLocationAndBuilding(
                    value,
                    isCompleteEdit,
                    isCancelled,
                    'Building'
                );
            }
        },
        editCoveragesInfoContainer: {
            visible: showEditCoverages && !showEditLocation && !showEditBuilding,
            submissionVM: submission,
            updateWizardData: updateWizardData,
            selectedLocation: selectedLocation,
            selectedBuilding: selectedBuilding,
            completeEdit: (value, isCompleteEdit, isCancelled = false) => {
                return completeEditLocationAndBuilding(
                    value,
                    isCompleteEdit,
                    isCancelled,
                    'Coverage'
                );
            }
        },
        editMessageChange: {
            visible: toggleVisibility.visible,
            messageProps: {
                info: `${toggleVisibility.message} ${translator(messages.changesSaved)}`
            }
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            tooltipwithcontent: TooltipWithContent,
            cplocationcomponent: LocationSummary,
            cpbuildingcomponent: BuildingSummary,
            cpcoveragecomponent: CoverageSummary,
            editlocationsummary: EditLocationSummary,
            editbuildingsummary: EditBuildingSummary,
            editcoveragessummary: EditCoveragesSummary
        },
        resolveCallbackMap: {
            backToBuilding: backToBuildingsPage,
            removeBuilding: removeBuilding,
            renderBuildingDescription: renderBuildingDescription,
            addBuildingButtonClicked: addBuildingButtonClicked,
            removeNotification: removeNotification
        }
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};

ViewBuildingInfo.propTypes = {
    isLocation: PropTypes.bool,
    title: PropTypes.string.isRequired,
    submission: PropTypes.shape({
        value: PropTypes.shape({})
    }),
    updateWizardData: PropTypes.func.isRequired,
    buildingInfo: PropTypes.shape({
        buildingId: PropTypes.string,
        publicID: PropTypes.string
    }),
    viewBuildingDetails: PropTypes.func.isRequired,
    backToBuildingsPage: PropTypes.func.isRequired,
    deleteBuilding: PropTypes.func.isRequired,
    editBuilding: PropTypes.string.isRequired,
    addBuildingToSelectedLocation: PropTypes.func.isRequired
};

ViewBuildingInfo.defaultProps = {
    isLocation: false,
    submission: {},
    buildingInfo: {}
};

export default ViewBuildingInfo;
