import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniPolicyService {
    static getPolicyLobCoverages(policyNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('wnipolicycoverages'),
            'getPolicyLobCoverages',
            [policyNumber], additionalHeaders);
    }

    static getCoverableDisplayNameMap(policyNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('wnipolicycoverages'),
            'getCoverableDisplayNameMap',
            [policyNumber], additionalHeaders);
    }

    static getAccountContacts(policyNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('wnipolicycoverages'),
            'getAccountContacts',
            [policyNumber], additionalHeaders);
    }

}
