import React from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import messages from '../../CAVehiclesPage.messages';
import { isOrigionalCostNewValid } from '../../Util/CAVehicle.util';

const CAVehicleDetailsFieldMap = (props) => {
    const {
        vm,
        dataPath,
        onValueChange,
        onValidate,
        onBlur,
        onSearch,
        showErrors,
        isReadOnly,
        filterSizeClassMapsInChange = []
    } = props;
    
    const fieldModels = _.get(vm.value, dataPath, []);

    /**
     * for policy change story（POI-2577） Size Class - Similar rule to vehicle type - 
     * cannot add a heavy, extra heavy, heavy trailer or extra heavy trailer unless there is an existing vehicle with that size class already on the policy pre-change
     * @param {Array} options 
     * @returns 
     */
    const filterAvailableValues = (fieldItem = {}) => {
        const { rangeValueOptions, rangeValue } = fieldItem;
        const newFilterSizeClassMapsInChange = filterSizeClassMapsInChange.filter((item) => item !== rangeValue);
        const options = rangeValueOptions.filter((item) => !_.includes(newFilterSizeClassMapsInChange, item));
        return options;
    }

    const handleValueChange = (newValue, index, fieldModel) => {
        onValueChange(newValue, `${dataPath}[${index}]`, fieldModel)
    };

    const handleBlur = (value, fieldItem) => {
        onBlur(value, fieldItem)
    };

    const registerAdditionalValidation = () => {
        return isOrigionalCostNewValid(vm.value);
    }
    const metadata = fieldModels.map((fieldItem, index) => {
        const fieldMessage = messages[`${fieldItem.propertyName}Message`];
        
        const showAdditionalErrors = fieldMessage && !registerAdditionalValidation();

        let additionalProps = {};

        if(fieldItem.propertyName === 'SizeClass'){
            additionalProps = {
                availableValues: filterAvailableValues(fieldItem)
            }
        };

        return {
            id: `${fieldItem.propertyName}Field`,
            type: 'field',
            component: 'WniFieldSetModel',
            componentProps: {
                className: 'fieldLayout_1_1',
                showRequired: true,
                showErrors: showAdditionalErrors || showErrors,
                readOnly: isReadOnly || fieldItem.readonly,
                onValueChange: (value, fieldModel) => handleValueChange(value, index, fieldModel),
                onBlur: (value) => handleBlur(value, fieldItem),
                fieldItem,
                onValidate,
                onSearch,
                additionalValidationFn: showAdditionalErrors && registerAdditionalValidation,
                additionalValidationMessages: showAdditionalErrors && [fieldMessage],
                ...additionalProps
            }
        };
    });

    const overrides = {};
    const resolvers = {
        resolveComponentMap: {
        },
    };

    return (
        <MetadataContent
            uiProps={metadata}
            overrideProps={overrides}
            {...resolvers}
        />
    );
};

export default CAVehicleDetailsFieldMap;
