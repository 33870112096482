import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import { useWniBaseModal } from 'wni-common-base-components';
import metadata from './UIPlatform.metadata.json5';
import Modal from './WniModal/Modal';
import staticData from './Config/config';


function UIPlatform(props) {
    const modalApi = useWniBaseModal();
    const [checkboxValue, updateCheckboxValue] = useState(false);
    const [checkboxGroupValue, updateCheckboxGroupValue] = useState([]);

    const openModal = () => {
        modalApi.showModal(<Modal id="test" data={11} />).then((res) => {
            console.log(`get modal confirm data: ${res}`);
        }).catch((e) => _.noop)
    };

    useEffect(() => {
        console.log(checkboxGroupValue)
    }, [checkboxGroupValue]);

    const overrideProps = {
        modalButton: {
            onClick: openModal
        },
        imageCheckboxField: {
            value: checkboxValue,
            onValueChange: (val) => updateCheckboxValue(val)
        },
       
        imageCheckboxGroup: {
            availableValues: staticData.ImageCheckboxGroupOptions,
            value: checkboxGroupValue,
            onValueChange: updateCheckboxGroupValue
        },
        inputWithAction_1: {
            actionFn: () => { console.log('onClick: click the input icon action')}
        },
        inputWithAction_2: {
            actionFn: () => { console.log('onClick: click the input icon action')}
        }
    };
    const resolvers = {
       
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default UIPlatform;
