import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import {
    Chevron,
    Button,
    Link,
} from '@jutro/components';
import { useHistory } from 'react-router-dom';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FieldLinkComponent } from 'gw-components-platform-react';
import { WniDateUtil } from 'wni-portals-util-js';
import { JobService } from 'gw-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import styles from './PaySuccessChangePage.module.scss';
import metadata from './PaySuccessChangePage.metadata.json5';
import policychangeMessages from './PaySuccessChangePage.messages';

const ACCORDION_CARDS = [
    'driverAccordion',
    'vehicleAccordion',
    'coverageAccordion',
    'contactAccordion'
];

const TYPE_MOBILE = 'mobile';
const TYPE_HOME = 'home';
const TYPE_WORK = 'work';

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
function PaySuccessChangePage(props) {
    const modalApi = useWniModal();
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const [isPrinting, setPrintingState] = useState(false);
    const { interactionModel } = useDependencies('interactionModel');
    const { wizardData: submissionVM } = props;
    const history = useHistory();
    const [handlePreemptionJobIDList, setHandlePreemptionJobIDList] = useState(_.get(submissionVM, 'value.handlePreemptionJobIDList_Ext', []));
    const { authHeader } = useAuthentication();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (isPrinting) {
            // The accordion component requires a new rendering cycle to be trigger,
            // this allows us to wait until that has completed
            const timeoutHandle = setTimeout(() => {
                window.print();
                // Timeout added to delay resetting the print state after the print is called,
                // the print state was being reset before the print preview loaded on actual device
                setTimeout(setPrintingState(false), 1000);
            }, 500);
            return () => clearTimeout(timeoutHandle);
        }
        return _.noop;
    }, [isPrinting]);

    const accountNumber = _.get(submissionVM, 'value.baseData.accountNumber');

    const policyNumber = _.get(submissionVM, 'value.policyNumber');

    const transactionNumber = _.get(submissionVM, 'value.jobID');

    const accountName = _.get(submissionVM, 'value.baseData.accountName');

    const policyPeriodStartDate = _.get(submissionVM, 'value.baseData.periodStartDate');

    const policyPeriodEndDate = _.get(submissionVM, 'value.baseData.periodEndDate');

    const policyPeriod = (
        <div>
            {`${WniDateUtil.formatDateWithPattern(policyPeriodStartDate)} - ${WniDateUtil.formatDateWithPattern(policyPeriodEndDate)}`}
        </div>
    );

    let transactionCost = _.get(submissionVM, 'value.transactionCost');
    if (!transactionCost) {
        transactionCost = { amount: 0.00, currency: 'usd' };
    }

    let totalCost = _.get(submissionVM, 'value.totalCost');
    if (!totalCost) {
        totalCost = { amount: 0.00, currency: 'usd' };
    }

    const effectiveDate = WniDateUtil
        .formatDateWithPattern(_.get(submissionVM, 'value.baseData.effectiveDate'));

    const getPhoneNumber = useCallback(() => {
        const primaryPhoneType = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.primaryPhoneType');
        let phoneNumber = '';
        switch (primaryPhoneType) {
            case TYPE_MOBILE:
                phoneNumber = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.cellNumber');
                break;
            case TYPE_HOME:
                phoneNumber = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.homeNumber');
                break;
            case TYPE_WORK:
                phoneNumber = _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.workNumber');
                break;
            default: break;
        }
        return phoneNumber;
    }, [submissionVM]);

    const handlePrint = useCallback(() => {
        setPrintingState(true);
    }, []);

    const handleDone = useCallback(() => {
        history.push(`/Change/${transactionNumber}/summary`);
    }, [history, transactionNumber]);

    const generateLinkOverrides = () => {
        const accountType = _.get(submissionVM, 'baseData.accountHolder.contactType_Ext');
        return {
            accountNumberLink: interactionModel.getURLObj(accountType, 'accountSummary', accountNumber),
            policyNumberLink: interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber)
        };
    };

    const onWithdrawTransaction = (jobNumber) => {
        modalApi.showConfirm({
            title: translator(policychangeMessages.deleteQuote),
            message: jobNumber ? translator(policychangeMessages.sureDeletePolicyChange) : `${translator(policychangeMessages.sureDeletePolicyChange)} ${jobNumber}?`,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: translator(commonMessages.ok)
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            JobService.withdrawJobByJobNumber(jobNumber, authHeader).then(
                () => {
                    const newHandlePreemptionJobIDList = handlePreemptionJobIDList
                        .filter((jobID) => jobID !== jobNumber);
                    setHandlePreemptionJobIDList(newHandlePreemptionJobIDList);
                },
                () => {
                    modalApi.showAlert({
                        title: gatewayMessages.modalError,
                        message: {
                            id: 'custom.gateway.directives.JobSummary.Failed to withdraw submission',
                            defaultMessage: 'Failed to withdraw submission'
                        },
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
            );
        }, _.noop);
    };

    const generateHandlePreemption = () => {
        return handlePreemptionJobIDList.length > 0 ? (
            <>
                {handlePreemptionJobIDList.map((jobID) => (
                    <div key={jobID}>
                        <span>{translator(policychangeMessages.handlePreemptionMessagePart1)}</span>
                        <Link to={`/change/${jobID}/summary`}>{jobID}</Link>
                        <span>{translator(policychangeMessages.handlePreemptionMessagePart2)}</span>
                        <Button className="btn-link" onClick={() => onWithdrawTransaction(jobID)}>
                            {translator(policychangeMessages.handlePreemptionMessageDeleteJobLink)}
                        </Button>
                        <span>
                            {translator(
                                policychangeMessages.handlePreemptionMessagePart3,
                                { jobID: jobID }
                            )}
                        </span>
                    </div>
                ))}
            </>
        ) : null;
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        ...generateLinkOverrides,
        handlePreemptionMessages: {
            content: generateHandlePreemption()
        },
        transactionNumberLink: {
            to: `/Change/${transactionNumber}/summary`
        },
        driverAccordion: {
            title: policychangeMessages.driverDetails
        },
        contactAccordion: {
            title: policychangeMessages.contactInformationDetails
        },
        accordion: {
            closeOthers: !isPrinting,
            accordionStates: isPrinting ? ACCORDION_CARDS : undefined,
            defaultOpenedId: 'contactAccordion'
        },
        customerSuccessPagePolicyMessage: {
            content: translator(policychangeMessages.changeSuccess)
        },
        // primaryInsuredName: {
        //     value: accountName
        // },
        policyPeriod: {
            value: policyPeriod
        },
        changeEffectiveDate: {
            value: effectiveDate
        },
        policyTotalCost: {
            value: totalCost
        },
        changeInCost: {
            value: transactionCost
        },
        phone: {
            value: getPhoneNumber()
        },
        ...generateLinkOverrides(),
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint,
            onDone: handleDone
        },
        resolveComponentMap: {
            fieldlinkcomponent: FieldLinkComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PaySuccessChangePage.propTypes = wizardProps;
export default PaySuccessChangePage;
