import { start } from '@jutro/app';
import {
    BrowserRouter
} from 'react-router-dom';
import {
    loadGA, subscribe, JUTRO_TOPICS, DefaultDictionaryProvider,
    DefaultWhitelistProvider
} from '@jutro/events';

import { LocaleService } from 'gw-portals-i18n-react';
import { ConfigUtil } from 'wni-portals-util-js';
import 'regenerator-runtime';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import App from './app/App';

const { trackingConfig = {}, authentication = {} } = appConfig;
const ga = loadGA(trackingConfig.gaTrackingId);

const whitelist = DefaultWhitelistProvider.create(trackingConfig.whitelistConfig);
const translate = DefaultDictionaryProvider.create(trackingConfig.dictionaryConfig);
subscribe(
    JUTRO_TOPICS,
    (event, topic) => {
        const transformedEvent = translate(whitelist(event));
        ga('send', {
            hitType: 'event',
            eventCategory: 'Props',
            eventAction: topic,
            eventLabel: JSON.stringify(transformedEvent),
        });
        if (topic === JUTRO_TOPICS.ROUTE_CHANGED) {
            ga('send', {
                hitType: 'pageview',
                page: transformedEvent.page
            });
        }
    }
);
let cloudAuthConfig = {};
if (authentication.authServer === 'okta') {
    cloudAuthConfig = authentication.servers.okta;
}


const getBrandedOkta = () => {
    const brandOverrides = ConfigUtil.getBrandedOktaConfig();
 
    return { ...authentication.servers.okta, ...brandOverrides };
 };
 

const gettOktaConfigs = () => {
    const ootbOkta = authentication.servers.okta;
    const brandedOkta = getBrandedOkta();

    return {...ootbOkta, ...brandedOkta};
};

const wniOktaConfigs = gettOktaConfigs();

// ====================================================
start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLocaleChange: true,
    config: [wniOktaConfigs],
    routerBasename: '',
    onInit: () => {
        LocaleService.register();
    },
    Router: BrowserRouter,
    appName: {
        id: 'digital.appName',
        defaultMessage: 'AgentsOnline'
    },
    appDescription: 'ProducerEngage' //, // ToDo
    //modalProvider: 'default', // ToDo
});
