import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/uiconfig';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import SpreadsheetProcessorResult from '../SpreadsheetProcessorResult/SpreadsheetProcessorResult';
import metadata from './SpreadsheetProcessor.metadata.json5';
import styles from './SpreadsheetProcessor.module.scss';
import messages from './SpreadsheetProcessor.messages';

const SpreadsheetProcessor = (props) => {
    const modalApi = useModal();
    const {
        submissionVM, onManualEntry, onCallbackResponse, enableManualEntry
    } = props;
    const { DocumentService } = useDependencies('DocumentService');
    const { SpreadsheetService } = useDependencies('SpreadsheetService');
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const jobID = submissionVM.quoteID ? submissionVM.quoteID.value : submissionVM.jobID.value;
    const [showLoader, setShowLoader] = useState(false);
    const [templateDownloaded, settemplateDownloaded] = useState(false);
    const [uploadDataStatus, setUploadDataStatus] = useState(null);
    const [loaderMessage, setLoaderMessage] = useState(translator(messages.downloading));

    const displayErrorModal = useCallback(() => {
        settemplateDownloaded(false);
        modalApi.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, []);

    const onResultCallback = useCallback(() => {
        settemplateDownloaded(false);
        setUploadDataStatus(null);
    }, []);

    const downloadTemplate = useCallback(async () => {
        await SpreadsheetService
            .downloadSpreadsheetTemplate(jobID, authHeader, displayErrorModal);
        setShowLoader(false);
        settemplateDownloaded(true);
    }, [SpreadsheetService, authHeader, displayErrorModal, jobID]);

    const handleDownloadTemplate = useCallback(() => {
        setShowLoader(true);
        downloadTemplate();
    }, [downloadTemplate]);

    const handleUploadTemplate = useCallback(() => {
        settemplateDownloaded(true);
    }, []);

    const onManualEntryClicked = useCallback(() => {
        onManualEntry();
    }, [onManualEntry]);

    const generateUploadToken = useCallback(async () => {
        const uploadTokenID = await DocumentService.generateUploadToken(authHeader);
        return uploadTokenID;
    }, [DocumentService, authHeader]);

    const getFileUploadStatus = useCallback((data) => {
        const isUndefinedOrZero = (value) => (value === undefined || value === 0);

        const isFailedUnchanged = (value) => {
            return (
                value.rowsRead > 0
                && isUndefinedOrZero(value.rowsWithErrors)
                && isUndefinedOrZero(value.rowsNew)
                && isUndefinedOrZero(value.rowsEdited)
                && isUndefinedOrZero(value.rowsRemoved)
            );
        };

        if (data.fatalErrors) {
            return { status: 'fail-processing', type: 'error' };
        }
        if (isUndefinedOrZero(data.rowsRead)) {
            return { status: 'fail-empty', type: 'error' };
        }
        if (isFailedUnchanged(data)) {
            return { status: 'fail-unchanged', type: 'error' };
        }
        if (data.rowsWithErrors === data.rowsRead) {
            return { status: 'fail-full', type: 'error' };
        }
        if (data.rowsWithErrors > 0) {
            return { status: 'partial', type: 'warning' };
        }
        return { status: 'success', type: 'info' };
    }, []);

    const processData = useCallback(
        (data) => {
            if (!_.isEmpty(data.lobData.newlyAddedBuildings)) {
                if (submissionVM.value.newlyAddedBuildingList) {
                    submissionVM.value.newlyAddedBuildingList = submissionVM
                        .value.newlyAddedBuildingList.concat(
                            data.lobData.newlyAddedBuildings
                        );
                } else {
                    submissionVM.value.newlyAddedBuildingList = data.lobData.newlyAddedBuildings;
                    submissionVM.value.updatedLocations = data.lobData.locations;
                }
            } else if (!_.isEmpty(data.lobData.locations)) {
                submissionVM.value.updatedLocations = data.lobData.locations;
            } else if (!_.isEmpty(data.lobData.newlyAddedCoveredEmployees)) {
                if (submissionVM.value.newlyAddedCoveredEmployeesList) {
                    submissionVM.value.newlyAddedCoveredEmployeesList = submissionVM
                        .value.newlyAddedCoveredEmployeesList.concat(
                            data.lobData.newlyAddedCoveredEmployees
                        );
                } else {
                    submissionVM.value.newlyAddedCoveredEmployeesList = data
                        .lobData.newlyAddedCoveredEmployees;
                    submissionVM.value.newlyAddedCoveredEmployeesList = data.lobData.locations;
                }
            } else {
                submissionVM.value.newlyAddedLocations = data.lobData.locations;
            }
        },
        [submissionVM.value.newlyAddedBuildingList,
            submissionVM.value.newlyAddedCoveredEmployeesList,
            submissionVM.value.newlyAddedLocations, submissionVM.value.updatedLocations]
    );

    const onFileUpload = useCallback(
        async (file) => {
            setShowLoader(true);
            setLoaderMessage(translator(messages.importingSpreadsheet));
            try {
                const documentMetaDataTemplate = {
                    docUID: '001',
                    documentType: 'other',
                    securityType: 'unrestricted',
                    status: 'approved',
                    jobNumber: jobID,
                    name: file.name,
                    mimeType: file.type,
                    sessionID: await generateUploadToken()
                };
                const data = await SpreadsheetService.uploadDocument(
                    file,
                    documentMetaDataTemplate,
                    authHeader
                );
                processData(data);
                const uploadStatus = getFileUploadStatus(data);
                setUploadDataStatus({
                    status: uploadStatus.status,
                    resultData: data,
                    type: uploadStatus.type
                });
                onCallbackResponse(submissionVM, uploadStatus.status);
            } catch {
                setUploadDataStatus({
                    status: 'fail-processing',
                    resultData: null,
                    type: 'error'
                });
            }
            setShowLoader(false);
        },
        [
            translator,
            jobID,
            generateUploadToken,
            SpreadsheetService,
            authHeader,
            processData,
            getFileUploadStatus,
            onCallbackResponse,
            submissionVM
        ]
    );

    const getFaqLink = useCallback(() => {
        return (
            <div className={styles.faqLinkContainer}>
                <FormattedMessage
                    {...messages.BuildingExcelLink}
                    values={{
                        excel: <strong>{translator(messages.ExcelSpreadsheets)}</strong>,
                        faqlink: (
                            <div>
                                <Link to="/faq" target="_blank" className={styles.faqLinkStyle}>
                                    {translator(messages.NeedMoreInfo)}
                                </Link>
                            </div>
                        )
                    }}
                />
            </div>
        );
    }, [translator]);

    const overrideProps = {
        processInlineLoader: {
            loaded: !showLoader,
            loadingMessage: loaderMessage
        },
        spreadsheetDownloadContainer: {
            visible: !templateDownloaded && !showLoader
        },
        spreadsheetUploadContainer: {
            visible: templateDownloaded && !showLoader
        },
        uploadSpreadSheet: {
            onUpload: onFileUpload
        },
        spreadsheetResultProcessor: {
            uploadedStatus: uploadDataStatus,
            onCallback: onResultCallback,
            productCode: _.get(submissionVM.value, 'baseData.productCode')
        },
        spreadsheetProcessor: {
            visible: _.isNil(uploadDataStatus)
        },
        manualInformationLinkContainer: {
            visible: enableManualEntry
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleDownload: handleDownloadTemplate,
            handleUpload: handleUploadTemplate,
            onManualEntryClicked: onManualEntryClicked
        },
        resolveComponentMap: {
            faqlink: getFaqLink,
            spreadsheetprocessorresult: SpreadsheetProcessorResult
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};

SpreadsheetProcessor.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    enableManualEntry: PropTypes.bool,
    onManualEntry: PropTypes.func,
    onCallbackResponse: PropTypes.func
};

SpreadsheetProcessor.defaulProps = {
    enableManualEntry: true
};

export default SpreadsheetProcessor;
