import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Button } from '@jutro/components'
import { Flex } from '@jutro/layout'
import { useValidation } from '@xengage/gw-portals-validation-react';
import {React, useState, useCallback, useContext, useEffect} from 'react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CPRisksService, CPRetrieveRiskItemSummaryService } from 'wni-capability-quoteandbind-cp';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {ValidationIssuesComponent } from 'wni-components-platform-react';
import { ValidationIssueUtil } from 'wni-portals-util-js';
import metadata from '../Common/CommonRiskItemDetails.metadata.json5';
import DetailsFieldMap from '../Common/DetailsFieldMap'
import BusinessIncomeClauses from '../BusinessIncomeClauses';
import riskItemMessages from '../RiskItemComponent/RiskItemComponent.messages'



const BusinessIncome = (props) => {

    const translator = useTranslator();

    const {
        onValidate,
        isComponentValid,
    } = useValidation('BusinessIncome');

    const { authHeader } = useAuthentication();
    const [businessIncomeClauses, setBusinessIncomeClauses] = useState()

    const [showErrors, updateShowErrors] = useState(false)
    const [validationIssues, updateValidationIssues] = useState([]);
    const {
        riskItem,
        jobID,
        sessionUUID,
        submissionVM,
        updateSubmissionVM,
        isReadOnly,
        handleCloseRisk,
    } = props

    const {
        locationPublicID,
        buildingPublicID,
        publicID: businessIncomePublicID,
        locationDescription,
        buildingDescription,
    } = riskItem

    const serviceProps = {
        jobID,
        sessionUUID,
        authHeader
    }
    const viewModelService = useContext(ViewModelServiceContext);
    const dtoName = 'wni.edge.capabilities.quote.lob.commercialproperty.dto.CPBusinessIncomeDTO';
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [currentRowVM, setCurrentRowVM] = useState({});

    const getBusinessIncomeFunc = useCallback(async () => {

        const res = await CPRisksService.getBusinessIncomeDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, authHeader);
        return res
    }, [authHeader, buildingPublicID, businessIncomePublicID, jobID, locationPublicID, sessionUUID])

    const updateBusinessIncomeDetailsFunc = useCallback(async (dto) => {
        const [needsRefresh, notNeedRefresh] = _.partition(dto.businessIncomeDisplayables?.filter(elt => elt.updated), 'needsRefresh');
        const orderedDisplayables = notNeedRefresh.concat(needsRefresh);
        Object.assign(dto, {'changedBusinessIncomeDisplayables': orderedDisplayables});
        const res = await CPRisksService.updateBusinessIncomeDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, dto, authHeader);
        return res
    }, [authHeader, buildingPublicID, businessIncomePublicID, jobID, locationPublicID, sessionUUID])

    const getBusinessIncomeClausesFunc = useCallback(async () => {
        const res = await CPRisksService.getBusinessIncomeClauses(jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, authHeader)
        return res
    }, [authHeader, buildingPublicID, businessIncomePublicID, jobID, locationPublicID, sessionUUID])

    const updateBusinessIncomeClausesFunc = async (dto) => {
        const res = await CPRisksService.updateBusinessIncomeClauses(jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, dto, authHeader)
        return res
    }

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true)
        const res = await serviceCallFunc()
        setLoadingMask(false)
        return res
    }

    const initFieldData = useCallback(async () => {
        const [
            businessIncomeDetailsDto, 
            newBusinessIncomeClauses
        ] = await withLoadingMask(() => Promise.all([
            getBusinessIncomeFunc(),
            getBusinessIncomeClausesFunc()
        ]))
        
        const res = viewModelService.create(businessIncomeDetailsDto, 'pc', dtoName);
        setBusinessIncomeClauses(newBusinessIncomeClauses)
        setCurrentRowVM(res)
        
    // !!! Never add setLoadingMask into dependency, which always different after render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService])


    const updateService = async(serviceData) => {
        const {
            businessIncome: updatedDetailsDto,
            businessIncomeClauses: newBusinessIncomeClauses
        } = await withLoadingMask(() => updateBusinessIncomeDetailsFunc(serviceData));
        setBusinessIncomeClauses(newBusinessIncomeClauses);
        const displayables = updatedDetailsDto?.businessIncomeDisplayables;
        const clonedDisplayables = _.set(_.clone(updatedDetailsDto), 'businessIncomeDisplayables', displayables);
        const res = viewModelService.create(clonedDisplayables, 'pc', dtoName);
        setCurrentRowVM(res);
        return res
    };


    const writeValue = (fieldItem, path, fieldModel = {}) => {
        const newVm = viewModelService.clone(currentRowVM);
        _.set(newVm.value, path, fieldItem);
        setCurrentRowVM(newVm);
        _.set(currentRowVM.value, path, fieldItem);
        if(fieldModel.triggerFunc === 'onValueChange' && fieldItem.needsRefresh) {
            updateService(currentRowVM.value);
        }
    }

    const onBlur = (value, fieldItem) => {
        updateService(currentRowVM.value, value, fieldItem)
    };


    useEffect(() => {
        initFieldData()
    }, [initFieldData])

    const handleClose = async () => {
        if (isReadOnly) {
            handleCloseRisk((oldTreeRows) => oldTreeRows)
            return
        }
        if (!isComponentValid) {
            updateShowErrors(true)
            return
        }
        const newVm = viewModelService.clone(currentRowVM);
        const allUpdatedDisplayables = _.get(newVm, 'value.businessIncomeDisplayables', [])?.filter(elt => elt.updated);
        if (!_.isEmpty(allUpdatedDisplayables)) {
            updateService(newVm.value);
        }
        const buildingTree = await withLoadingMask(() => CPRetrieveRiskItemSummaryService.retrieveBusinessIncomeSummary(
            jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, authHeader
        ))
        const newSpecClsBI = buildingTree.businessIncomeRows?.find(elt => elt.publicID === businessIncomePublicID);
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(newSpecClsBI.errorsAndWarnings);
        updateValidationIssues(newValidationIssues);
        
        const generateNewTiskTreeRowFunc = (oldRiskTreeRows) => {
            const newRiskTreeRows = _.clone(oldRiskTreeRows)
            const locationIndex = oldRiskTreeRows.findIndex(location => location.publicID === locationPublicID)
            const location = oldRiskTreeRows[locationIndex]
            const buildings = location.buildingTreeRows
            const buildingIndex = buildings.findIndex(building => building.publicID === buildingPublicID)

            _.set(
                newRiskTreeRows, 
                `[${locationIndex}].buildingTreeRows[${buildingIndex}]`, 
                buildingTree
            )

            return newRiskTreeRows
        }
        if (!_.isEmpty(newValidationIssues)) {
            updateShowErrors(true)
            return
        }
        handleCloseRisk((oldTreeRows) => generateNewTiskTreeRowFunc(oldTreeRows))
    }
    
    const overrideProps = {
        fieldSetModel: {
            vm: currentRowVM,
            dataPath: 'businessIncomeDisplayables',
            // submissionVM,
            onValueChange: writeValue,
            // onSearch,
            onValidate,
            onBlur,
            showErrors,
            isReadOnly,
            // filterSizeClassMapsInChange
        },
        riskItemDetailsCard: {
            title: 'Business Income Details'
        },
        locationInput: {
            visible: true,
            value: locationDescription
        },
        buildingInput: {
            visible: true,
            value: buildingDescription
        }
    }
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            fieldsetmap: DetailsFieldMap
        }
    };
    return (
        <>
            <ValidationIssuesComponent validationIssues={validationIssues}/>
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                // onValueChange={onValueChange}
                onValidationChange={onValidate}
                // resolveValue={readValue}
                // classNameMap={resolvers.resolveClassNameMap}
                // callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            <BusinessIncomeClauses
                riskItem={riskItem}
                serviceProps={serviceProps}
                submissionVM={submissionVM}
                updateSubmissionVM={updateSubmissionVM}
                updateClauseFunc={updateBusinessIncomeClausesFunc}
                riskClauses={businessIncomeClauses}
                setRiskClauses={setBusinessIncomeClauses}
                showErrors={showErrors}
                isEditable={!isReadOnly}
                onValidate={onValidate}
            />
            <Flex justifyContent="right">
                <Button onClick={handleClose}>
                    {translator(riskItemMessages.Close)}
                </Button>
            </Flex>
        </>
    );
}

export default BusinessIncome