import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
    CurrencyField,
    Button,
    TooltipIcon,
    DropdownMenuButton,
    DropdownMenuLink,
} from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { AccountService } from 'gw-capability-gateway-policy';
import { JobService } from 'gw-capability-gateway';
import { Link as LinkComponent } from 'gw-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
// import { useValidation } from '@xengage/gw-portals-validation-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import { DocumentsUtil, ServiceErrorUtil, WniDateUtil, WniProductsUtil } from 'wni-portals-util-js';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import accountSummaryConstants from '../../AccountSummaryConstants';
import styles from './AQASPrendingTransactionsPanel.module.scss';
import metadata from './AQASPrendingTransactionsPanel.metadata.json5';
import messages from './AQASPrendingTransactionsPanel.messages';

const { 
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
    CPP_PRODUCT_CODE
} = WniProductsUtil;

function AQASPrendingTransactionsPanel(props) {
    const modalApi = useWniModal();
    const {
        accountNumber,
        prendingTransactions,
        refreshPrendingTransactions,
        selectedJobs,
        setSelectedJobs,
        selectedVersionMap,
        updateSelectedVersionMap,
        getSelectedVersionPublicID,
        getSelectedVersion,
        prendingTransactionsSelectedProductCode,
        PTFilterSelectedTransactionType,
        handleProductFilterChange,
        handleTransactionTypeChange,
    } = props;


    const translator = useTranslator();

    const {
        authHeader,
    } = useAuthentication();
    const history = useHistory();
    const {
        interactionModel,
        loadingMask: { setLoadingMask }
    } = useDependencies(['interactionModel', 'loadingMask']);

    const { lobQuoteURL } = appConfig;

    const filteredPrendingTransactions = prendingTransactions
        .filter((job) => {
            if (prendingTransactionsSelectedProductCode
                    === accountSummaryConstants.ProductFilterOptionAllCode) {
                return true;
            }
            return _.get(job, 'product.productCode') === prendingTransactionsSelectedProductCode;
        })
        .filter((job) => {
            if (PTFilterSelectedTransactionType
                === accountSummaryConstants.TransactionFilterOptionAllCode) {
                return true;
            }
            return _.get(job, 'type') === PTFilterSelectedTransactionType;
        });

    const displayedPrendingTransactions = filteredPrendingTransactions.sort((job1, job2) => {
        if (_.get(job1, 'type') === 'Submission' && _.get(job2, 'type') === 'Submission') {
            return 0;
        }
        if (_.get(job1, 'type') === 'Submission') {
            return -1;
        }
        if (_.get(job2, 'type') === 'Submission') {
            return 1;
        }
        return 0;
    });

    const cleanSelectedVersionInfoForJob = (jobNumber) => {
        const newSelectedVersionMap = _.cloneDeep(selectedVersionMap);
        _.unset(newSelectedVersionMap, jobNumber);
        updateSelectedVersionMap(selectedVersionMap);
    };

    const getProductFilterAvailableValue = () => {
        const options = [{
            code: accountSummaryConstants.ProductFilterOptionAllCode,
            name: translator(messages.productFilterAllProductsItemName),
        }];
        if (_.isNil(prendingTransactions)) {
            return options;
        }
        prendingTransactions.forEach((prendingTransaction) => {
            const productCode = _.get(prendingTransaction, 'product.productCode');
            if (!options.some((option) => option.code === productCode)) {
                options.push({
                    code: productCode,
                    name: _.get(prendingTransaction, 'product.productName')
                });
            }
        });
        return options;
    };

    const getDisplayTypeName = (type) => {
        switch (type) {
            case 'Submission':
                return 'New Submission';
            default:
                return type;
        }
    };

    const getTransactionFilterAvailableValue = () => {
        const options = [{
            code: accountSummaryConstants.TransactionFilterOptionAllCode,
            name: translator(messages.productFilterAllTransactionsItemName),
        }];
        if (_.isNil(prendingTransactions)) {
            return options;
        }
        prendingTransactions.forEach((prendingTransaction) => {
            const transactionType = _.get(prendingTransaction, 'type');
            if (!options.some((option) => option.code === transactionType)) {
                options.push({
                    code: transactionType,
                    name: getDisplayTypeName(transactionType)
                });
            }
        });
        return options;
    };

    const isJobTypeSubmission = (job) => {
        if (_.get(job, 'type') === 'Submission') {
            return true;
        }
        return false;
    };

    const renderStatusHeader = () => {
        const renderTootipContent = (
            <div>
                <div>{translator(messages.prendingTransationsColumnHeaderStatusTootipDraft)}</div>
                <div>{translator(messages.prendingTransationsColumnHeaderStatusTootipRated)}</div>
                <div>{translator(messages.prendingTransationsColumnHeaderStatusTootipQuoted)}</div>
                <div>
                    {translator(messages.prendingTransationsColumnHeaderStatusTootipApproved)}
                </div>
            </div>
        );
        return (
            <Flex justifyContent="left" gap="none" alignContent="middle" className="wni-tooltip">
                <span className="wni-tooltip-label">{translator(messages.prendingTransationsColumnHeaderStatus)}</span>
                <TooltipIcon
                    id="wniTooltipContent"
                    className="wni-tooltip-icon"
                    text={renderTootipContent}
                />
            </Flex>
        );
    };

    const getProductCell = (rowData) => {
        return _.get(rowData, 'product.productName', '-');
    };

    const getEffectiveDateCell = (rowData, index, property) => {
        const effectiveDate = _.get(rowData, _.get(property, 'id'));
        return effectiveDate ? WniDateUtil.formatDateWithPattern(effectiveDate) : '-';
    };

    const getTotalPremiumCell = (rowData) => {
        const accountHolderSubType = _.get(rowData, 'accountHolder.subtype')
        if (accountHolderSubType === 'Company' && _.get(rowData, 'locked')) {
            return '-'
        }
        const selectedVersion = getSelectedVersion(rowData);
        const totalCost = _.get(selectedVersion, 'totalCost');
        return totalCost ? (
            <CurrencyField
                id="currency"
                value={totalCost}
                readOnly
                hideLabel
                showOptional={false}
            />
        ) : '-';
    };

    const getStatusCell = (rowData) => {
        const selectedVersion = getSelectedVersion(rowData);
        const status = _.get(selectedVersion, 'status');
        return status;
    };

    const getQuoteSummaryCell = (rowData) => {
        const summaryDocument = _.get(rowData, 'summaryDocument');
        if (!_.isNil(summaryDocument)) {
            const successCallback = () => {
                setLoadingMask(false);
            };
            const errorCallback = () => {
                setLoadingMask(false);
                modalApi.showConfirm({
                    title: 'Error',
                    message: ServiceErrorUtil.prependWithFriendMessage(),
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: messages.ok,
                }).then(_.noop).catch(_.noop);
            };
            const onPrintClick = async () => {
                setLoadingMask(true);
                await DocumentsUtil.tryDownloadDocument(
                    summaryDocument, authHeader, history, WniDocumentRetrievalService,
                    successCallback, errorCallback
                );
            };
            return (
                <Button className="btn-link" type="text" onClick={() => onPrintClick()}>
                    Print
                </Button>
            );
        }
        return '-';
    };

    const getCell = (items, index, property) => {
        return items[property.id] ? items[property.id] : '-';
    };

    const showOpenJobInXCenter = (type) => {
        return (
            type === 'Submission'
            || type === 'PolicyChange'
            || type === 'Cancellation'
            || type === 'Renewal'
        );
    };

    const getTransactionTypeIsViewableInXCenter = (rowData) => {
        const status = _.get(rowData, 'status');
        return status ? status.toLowerCase() !== 'reinstatement'
            && status.toLowerCase() !== 'rewrite' : false;
    };

    const getJobTypeCell = (rowData) => {
        const type = _.get(rowData, 'type');
        const displayTypeName = getDisplayTypeName(type);
        const jobNumber = _.get(rowData, 'jobNumber');
        const transactionTypeIsViewableInXCenter = getTransactionTypeIsViewableInXCenter(rowData);
        const canUserView = _.get(rowData, 'canUserView');
        const quoteFlow = _.get(rowData, 'quoteFlow_Ext');
        if (canUserView && quoteFlow === 'draft') {
            if (type === 'Submission') {
                const productCode = _.get(rowData, 'product.productCode');
                const postalCode = _.get(
                    rowData,
                    'accountHolder.primaryAddress.postalCode'
                );
                const onGotoWizardLinkClick = () => {
                    if (!_.isNil(lobQuoteURL[productCode])) {
                        const nextLocation = {
                            isReadOnly: false,
                            quoteentry: {
                                postalCode: postalCode,
                                quoteID: jobNumber,
                                // producerCode_Ext: submission.producerCode_Ext
                            },
                        };
                        history.push(lobQuoteURL[productCode], nextLocation);
                    } else {
                        JobUtil.openJobInXCenter(jobNumber);
                    }
                };
                return (
                    <Button className="btn-link" type="text" onClick={() => onGotoWizardLinkClick()}>
                        {displayTypeName}
                    </Button>
                );
            }
            const policyNumber = _.get(rowData, 'policyNumber');
            const url = interactionModel.getURLObj(null, 'policySummary', accountNumber, policyNumber);
            return (
                <LinkComponent
                    {...url}
                    className={styles.removeLinkStyle}
                >
                    {displayTypeName}
                </LinkComponent>
            );
        }
        if (canUserView && transactionTypeIsViewableInXCenter) {
            if (!showOpenJobInXCenter(type)) {
                return (
                    <LinkComponent href="/" onClick={() => JobUtil.openJobInXCenter(jobNumber)}>
                        {displayTypeName}
                    </LinkComponent>
                );
            }
            return (
                <LinkComponent to={JobUtil.getJobDetailURLByJobType(type, jobNumber)}>
                    {displayTypeName}
                </LinkComponent>
            );
        }
        return null;
    };

    const getViewPolicyCell = useCallback((rowData) => {
        const type = _.get(rowData, 'type');
        if (type === 'Submission') {
            const canUserView = _.get(rowData, 'canUserView');
            const transactionTypeIsViewableInXCenter = getTransactionTypeIsViewableInXCenter(
                rowData
            );
            const jobNumber = _.get(rowData, 'jobNumber');
            if (canUserView && transactionTypeIsViewableInXCenter) {
                if (!showOpenJobInXCenter(type)) {
                    return (
                        <LinkComponent href="/" onClick={() => JobUtil.openJobInXCenter(jobNumber)}>
                            {jobNumber}
                        </LinkComponent>
                    );
                }
                return (
                    <LinkComponent to={JobUtil.getJobDetailURLByJobType(type, jobNumber)}>
                        {jobNumber}
                    </LinkComponent>
                );
            }
        }
        const policyNumber = _.get(rowData, 'policyNumber');
        if (!policyNumber || !accountNumber) {
            return '-';
        }
        const url = interactionModel.getURLObj(null, 'policySummary', accountNumber, policyNumber);
        return (
            <LinkComponent
                {...url}
                className={styles.removeLinkStyle}
            >
                {policyNumber}
            </LinkComponent>
        );
    }, [accountNumber, interactionModel]);

    const getVersionCell = (rowData) => {
        const jobNumber = _.get(rowData, 'jobNumber');
        const selectedVersionPublicID = getSelectedVersionPublicID(rowData);
        const versions = _.get(rowData, 'versions', []);
        const generateVersionDisplayName = (version) => {
            const isSelectedVersion = version.publicID === selectedVersionPublicID;

            let versionDisplayName = `${version.policyType} ${version.branchName} - ${version.status}`;
            const productCode = _.get(rowData, 'product.productCode');
            switch (productCode) {
                case HOP_PRODUCT_CODE: {
                    versionDisplayName = version.hocoverageForm ?
                        `${version.policyType} ${version.hocoverageForm.toUpperCase()} ${version.branchName} - ${version.status}`
                        : `${version.policyType} ${version.branchName} - ${version.status}`
                    break;
                }
                case WAL_PRODUCT_CODE:
                case RT_PRODUCT_CODE:
                case DP_PRODUCT_CODE:
                case PU_PRODUCT_CODE:
                case CA_PRODUCT_CODE:
                case WCM_PRODUCT_CODE:
                case GL_PRODUCT_CODE:
                case CP_PRODUCT_CODE:
                case CPP_PRODUCT_CODE:{
                    versionDisplayName = `${version.branchName} - ${version.status}`;
                    break;
                }
                default: {
                    versionDisplayName = `${version.policyType} ${version.branchName} - ${version.status}`;
                }
            }
            return `${isSelectedVersion ? '* ' : ''}${versionDisplayName}`;
        };
        if (versions.length === 1) {
            const selectedVersion = versions.find((version) => version.publicID === selectedVersionPublicID)
            return generateVersionDisplayName(selectedVersion)
        }
        
        const onVersionChange = (newsSelectedVersionPublicID) => {
            if (newsSelectedVersionPublicID === selectedVersionPublicID) {
                return;
            }
            const newSelectedVersionMap = _.cloneDeep(selectedVersionMap);
            _.set(newSelectedVersionMap, jobNumber, newsSelectedVersionPublicID);
            updateSelectedVersionMap(newSelectedVersionMap);
        };
        return (
            <div>
                <DropdownMenuButton
                    icon="gw-expand-more"
                    id="dropdownMenuButton"
                    className="dropDownMenuIconbtn btnAction"
                    menuClassName="dropDownMenuList"
                    alignRight
                >
                    {versions.map((version) => (
                        <DropdownMenuLink
                            key={version.publicID}
                            onClick={() => onVersionChange(version.publicID)}
                        >
                            {generateVersionDisplayName(version)}
                        </DropdownMenuLink>
                    )) }
                </DropdownMenuButton>
            </div>
        );
    };

    const onWithdrawButtonClick = (rowData) => {
        const jobNumber = _.get(rowData, 'jobNumber');
        const type = _.get(rowData, 'type');
        const displayTypeName = getDisplayTypeName(type);
        modalApi.showConfirm({
            title: messages.withdrawJob,
            message: translator(messages.sureWithDrawJob,
                {
                    jobType: displayTypeName,
                    quoteNumber: jobNumber
                }),
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            JobService.withdrawJobByJobNumber(
                jobNumber,
                authHeader
            ).then(
                () => {
                    // handle withdraw success
                    cleanSelectedVersionInfoForJob(jobNumber);
                    refreshPrendingTransactions();
                },
                () => {
                    // handle withdraw failed
                    modalApi.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedWithdrawSubmission,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    });
                }
            );
        }).catch(_.noop);
    };
    const productFilterAvailableValue = getProductFilterAvailableValue();
    const transationFilterAvailableValue = getTransactionFilterAvailableValue();

    const pendingTransactionsTableData = displayedPrendingTransactions.map((item) => {
        return {
            ...item,
            unselectable: !isJobTypeSubmission(item)
        }
    })

    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
        },
        productFilter: {
            availableValues: productFilterAvailableValue,
            value: prendingTransactionsSelectedProductCode,
            onValueChange: handleProductFilterChange,
        },
        transactionFilter: {
            availableValues: transationFilterAvailableValue,
            value: PTFilterSelectedTransactionType,
            onValueChange: handleTransactionTypeChange,
        },
        openTransactionDataTable: {
            data: pendingTransactionsTableData,
            onSelectionChange: (rows) => setSelectedJobs(rows),
            selectedRows: selectedJobs
        },
        deleteColumnAction: {
            onClick: onWithdrawButtonClick
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            renderStatusHeader: renderStatusHeader,
            getProductCell: getProductCell,
            getEffectiveDateCell: getEffectiveDateCell,
            // getExpirationDateCell: getExpirationDateCell,
            getTotalPremiumCell: getTotalPremiumCell,
            getStatusCell: getStatusCell,
            getQuoteSummaryCell: getQuoteSummaryCell,
            getViewPolicyCell: getViewPolicyCell,
            getJobTypeCell: getJobTypeCell,
            // getViewTransactionCell: getViewTransactionCell,
            getVersionCell: getVersionCell,
            getCell: getCell,
        },
        resolveComponentMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            // onModelChange={updateFormData}
            // onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onValidationChange={onValidate}
        />
    );
}

AQASPrendingTransactionsPanel.propTypes = {
    prendingTransactions: PropTypes.arrayOf(PropTypes.shape({})),
    refreshPrendingTransactions: PropTypes.func.isRequired,
    selectedJobs: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedJobs: PropTypes.func.isRequired,
    accountNumber: PropTypes.string.isRequired,
    selectedVersionMap: PropTypes.shape({}).isRequired,
    updateSelectedVersionMap: PropTypes.string.isRequired,
    getSelectedVersionPublicID: PropTypes.func.isRequired,
    getSelectedVersion: PropTypes.func.isRequired,
    prendingTransactionsSelectedProductCode: PropTypes.string.isRequired,
    handleProductFilterChange: PropTypes.func.isRequired,
    PTFilterSelectedTransactionType: PropTypes.string.isRequired,
    handleTransactionTypeChange: PropTypes.func.isRequired,
};

AQASPrendingTransactionsPanel.defaultProps = {
    prendingTransactions: [],
};

export default AQASPrendingTransactionsPanel;
