import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { WniClausesUtil } from 'wni-portals-util-js';
// import { R1Config } from 'wni-portals-config-js';
import BaseSingleClauseComponentVM from './BaseSingleClauseComponentVM';
import messages from './BaseClausesComponentVM.messages';

/**
 * Component hierarchy diagram:
 * BaseClausesComponentVM ->  BaseSingleClauseComponentVM -> BaseClauseComponent
 * @param {object} props
 * @returns {object}
 */
function BaseClausesComponentVM(props) {
    const {
        value: clauses,
        path,
        labelPosition,
        loadingClauseMessage,
        sortBySelected,
        splitByClauseCategory,
        isEditable,
        // ==============================
        getClausesCategoryMetadata,
        showAmount,
        showErrors,
        componentMapOverrides,
    } = props;

    const translator = useTranslator();

    function defaultGetClausesCategoryMetadata(clausesCategory) {
        const retval = clausesCategory.map((clause) => {
            const index = clauses.findIndex((element) => element.publicID === clause.publicID);
            const clausePath = `[${index}]`;

            const changedValuePath = WniClausesUtil.getChangedValuePath(path, clausePath);

            return {
                id: `clause_${clause.publicID}_[${index}]`,
                type: 'field',
                component: 'BaseSingleClauseComponentVM',
                componentProps: {
                    ...props,
                    labelPosition,
                    loadingClauseMessage: translator(loadingClauseMessage),
                    value: clause,
                    path: changedValuePath,
                    containerClassName: 'clauseContainer',
                    showErrors: showErrors,
                    showAmount: showAmount,
                }
            };
        });
        return retval;
    }

    const toMetadata = _.isFunction(getClausesCategoryMetadata)
        ? getClausesCategoryMetadata
        : defaultGetClausesCategoryMetadata;


    const defaultGenerateClauseGroup = (clauseCategory, index) => {
        const clausesMetadata = toMetadata(clauseCategory);

        const clauseCategoryName = (splitByClauseCategory) && {
            componentProps: { className: 'clause_category_name' },
            id: index ? `clause_category_title_[${index}]` : 'clause_category',
            type: 'element',
            component: 'h3',
            // If splitByClauseCategory is false, categoryDisplayName should be supplied
            content: clauseCategory[0].coverageCategoryDisplayName
        };
        return {
            id: `clause_category_container_[${index}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clauseCategoryContainer' },
            content: clauseCategoryName
                ? [clauseCategoryName, ...clausesMetadata] : clausesMetadata
        };
    };

    const {
        generateClauseGroup = defaultGenerateClauseGroup
    } = props

    const generateReadOnlyMetadata = () => {
        const clausesToRender = clauses
            .filter((clause) => clause.selected);

        if (_.isEmpty(clausesToRender)) {
            return [{
                id: 'no_clause',
                type: 'element',
                component: 'h4',
                content: messages.noCoverage,
                componentProps: {
                    className: 'gw-no-coverages'
                }
            }];
        }
        return toMetadata(clausesToRender);
    };

    const generateEditableMetadata = () => {
        if (splitByClauseCategory) {
            // coverageCategoryCode is used in all types of clauses
            const clauseCategory = _.groupBy(clauses, 'coverageCategoryCode');
            const clauseCategoryMetadata = Object.values(clauseCategory)
                .map(generateClauseGroup);

            return {
                id: 'clause_container',
                type: 'container',
                component: 'div',
                content: clauseCategoryMetadata
            };
        }
        return generateClauseGroup(clauses);
    };

    const generateMetadata = () => {
        if (!isEditable) {
            return {
                id: 'clause_container',
                type: 'container',
                component: 'div',
                content: generateReadOnlyMetadata()
            };
        }
        return generateEditableMetadata();
    };

    // ========================================
    const overrides = {

    };
    const resolvers = {
        resolveClassNameMap: {},
        resolveCallbackMap: {},
        resolveComponentMap: {
            BaseSingleClauseComponentVM: BaseSingleClauseComponentVM,
            ...componentMapOverrides
        },
    };
    return <MetadataContent uiProps={generateMetadata()} overrideProps={overrides} {...resolvers} />;
}

/**
 * @memberof gw-components-platform-react.ClausesComponentVM
 * @prop {Object} propTypes - the props that are passed to this component
 * @prop {string} propTypes.path - path to value in the view modal
 * @prop {array} propTypes.value - is the clause is seleceted
 * @prop {bool} propTypes.splitByClauseCategory - if clauses should be split by coverageCategory
 * @prop {string} propTypes.categoryDisplayName - title if splitByClauseCategory is false
 * @prop {string} propTypes.isEditable - if the clauses should not be editable
 * @prop {string} propTypes.sortBySelected - if the selected clauses should be on top
 * @prop {bool} propTypes.showAmount
 */

BaseClausesComponentVM.propTypes = {
    ...BaseSingleClauseComponentVM.propTypes,
    path: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    splitByClauseCategory: PropTypes.bool,
    categoryDisplayName: PropTypes.string,
    labelPosition: PropTypes.string,
    loadingClause: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string, PropTypes.bool]),
    loadingClauseMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    sortBySelected: PropTypes.bool,
    //
    /**
     * (Optional): Function: (clausesCategory) => meatdataConfigJSON
     * Process ClausesCategory before generating MetaData;
     *
     */
    getClausesCategoryMetadata: PropTypes.func,
    isEditable: PropTypes.bool,
    showAmount: PropTypes.bool,
};

BaseClausesComponentVM.defaultProps = {
    splitByClauseCategory: false,
    categoryDisplayName: undefined,
    labelPosition: 'left',
    isEditable: true,
    loadingClauseMessage: {
        id: 'policycommon.directives.coverages.Syncing coverage dependencies…',
        defaultMessage: 'Syncing coverage dependencies…'
    },
    //
    getClausesCategoryMetadata: undefined,
    sortBySelected: false,
    showAmount: true,
};

export default BaseClausesComponentVM;
