import React, { useEffect } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import { RTTooltipUtil as tooltips } from 'wni-portals-tooltip';
import messages from 'gw-components-platform-react/ScheduleItemsComponent/ScheduleItemTypes/SimpleScheduleItem/SimpleScheduleItem.messages';
import {
    valueTypeNameMap,
    valueTypeMap,
    valueTypeNameDatatypeMap,
    valueTypeDatatypeMap,
} from '../../RTScheduleItemsUtil';


export function getComponentDataType(propertyInfo) {
    const {
        valueTypeName_Ext: valueTypeName,
        isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
    } = propertyInfo;

    const valueType = isCovTermPropertyInfo ? valueTypeName : _.get(propertyInfo, 'valueType')

    if (valueType === 'INTEGER' && _.has(propertyInfo, 'currency')) {
        return 'currency';
    }

    const dataTypeMap = isCovTermPropertyInfo ? valueTypeNameDatatypeMap : valueTypeDatatypeMap;

    return _.get(dataTypeMap, valueType);
}

function formatAvailableValues(availableValues) {
    return _.sortBy(availableValues, 'priority')
        .map((availableValue) => ({
            code: availableValue.code,
            name: availableValue.name
        }));
}

function hasErrors(scheduleItem, value, path, modalData) {
    const isFirstItem = _.isEmpty(scheduleItem.scheduleItems);
    const isValueUnique = scheduleItem.scheduleItems.filter((item) => {
        return item.itemNumber !== modalData.itemNumber;
    })
        .every((item) => {
            const itemValue = _.get(item, path);
            return itemValue !== value;
        });

    return !isValueUnique && !isFirstItem;
}

function getValidationMessage(isUnique, scheduleItem, value, path, modalData, translator) {
    return (isUnique && hasErrors(scheduleItem, value, path, modalData)
        ? [translator(messages.thisFieldMustBeUnique)] : undefined)
        || (value === 0.00 ? [translator(messages.requiredField)] : undefined);
}

export default function HOSimpleScheduleItem({
    info,
    translator,
    modalData,
    scheduleItem,
    onValueChange,
    onBlur,
    onValidate,
    parentOverrides,
    isEditable = true,
    showErrors = false,
    optionFilter,
}) {
    const {
        id,
        valueType,
        valueTypeName_Ext: valueTypeName,
        required,
        label,
        availableTypeListValues = [],
        currency,
        isUnique,
        isCovTermPropertyInfo_Ext: isCovTermPropertyInfo,
    } = info;
    const valuePath = isCovTermPropertyInfo ? valueTypeNameMap[valueTypeName] : valueTypeMap[valueType]
    const path = `itemData.${id}.${valuePath}`;
    const dataType = getComponentDataType(info);
    const value = _.get(modalData, path);

    const optionFilterForCurrentField = _.get(optionFilter, id)

    const allOptions = _.get(modalData, `itemData.${id}.options_Ext`, [])
    const optionValues = _.isNil(optionFilterForCurrentField) ? allOptions
        : optionFilterForCurrentField(modalData, allOptions);
    const editable = _.get(modalData, `itemData.${id}.editable_Ext`, false) && isEditable;

    let availableValues = [];
    if (valuePath === 'typeCodeValue') {
        availableValues = formatAvailableValues(optionValues)
    }
    if (valuePath === 'booleanValue') {
        availableValues = [
            {
                "code": "true",
                "name": "Yes"
            },
            {
                "code": "false",
                "name": "No"
            }
        ]
    }
    let dateFormater = undefined;
    if (valuePath === "dateValue") {
        dateFormater = "vshort";
    }
    const updateOnBlur = [
        'bigDecimal',
        'stringValue',
    ].includes(valuePath);
    const valueTypeMetaData = isCovTermPropertyInfo 
        ? (valueTypeNameDatatypeMap[valueTypeName] || {})
        : (valueTypeDatatypeMap[valueType] || {})

    const {
        component: metaDataComponent,
        dataType: metaDataDataType
    } = valueTypeMetaData
    const metadata = {
        content: [{
            id: id,
            type: 'field',
            component: metaDataComponent,
            datatype: dataType,
            componentProps: {
                dataType: metaDataDataType,
                required: required,
                showRequired: required,
                availableValues: availableValues,
                currency: currency,
                path: path,
                readOnly: !editable,
                label: label,
                value: value,
                validationMessages: getValidationMessage(
                    isUnique, scheduleItem, value, path, modalData, translator
                ),
                onValueChange: onValueChange,
                onBlur: updateOnBlur ? onBlur : _.noop,
                tooltip: {
                    text: tooltips[id]
                },
                showErrors,
                showFractions: false,
                format: dateFormater,
                ...parentOverrides['@field']
            }
        }]
    };

    // const isFieldValid = validateContentFromMetadata(metadata);
    // useEffect(() => {
    //     if (onValidate) {
    //         onValidate(isFieldValid, id);
    //     }
    // }, [onValidate, id, isFieldValid]);

    return <MetadataContent uiProps={metadata} />;
}
