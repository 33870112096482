import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalFooter,
    ModalBody
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { FieldLinkComponent } from 'gw-components-platform-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { WniSubmissionService } from 'wni-capability-gateway';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { AttachmentUtil, DocumentsUtil } from 'wni-portals-util-js';
import { useWniModal } from 'wni-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import DocumentsComponent from '../components/DocumentsComponent/DocumentsComponent';
import metadata from './DocumentsPage.metadata.json5';
import messages from './DocumentsPage.messages';

function DocumentsPage(props) {
    const modalApi = useWniModal();
    const {
        title,
        size,
        isOpen,
        onReject,
        submissionVM,
        // authHeader,
        history,
        fromAccountLanding: { quoteDetailsData: { jobNumber } },
        useAuthenticationData,
        useDependenciesData,
        viewModelService,
        breakpoint,
        isInPopup,
        isCL,
        productCode
    } = props;

    const { domainCompany , loadingMask: { setLoadingMask }} = useDependenciesData || useDependencies(['domainCompany', 'loadingMask']);

    const translator = useTranslator();
    const { authHeader } = useAuthenticationData || useAuthentication();

    const [documentsData, updateDocumentsData] = useState([]);

    const handleError = useCallback((messageTitle, message) => {
        modalApi.showAlert({
            title: translator(messageTitle),
            message: translator(message),
            icon: 'gw-error-outline',
            status: 'error',
            confirmButtonText: translator(commonMessages.ok)
        }).then(() => {
            _.noop()
        }, _.noop);
    }, [translator]);

    const handleWarning = useCallback((messageTitle, message) => {
        modalApi.showAlert({
            title: translator(messageTitle),
            message: translator(message),
            icon: 'gw-error-outline',
            status: 'warning',
            confirmButtonText: translator(commonMessages.ok),
        }).then(() => {
            _.noop()
        }, _.noop);
    }, [translator]);

    const getDocuments = async () => {
        try {
            setLoadingMask(true);
            const attachments = await WniSubmissionService
                .getPortalAttachmentsForSubmission([jobNumber, isCL], authHeader);
            updateDocumentsData(attachments);
            setLoadingMask(false);
        } catch (e) {
            handleError(
                messages.modalError,
                messages.errorLoadingDocument
            );
        }
    };

    const claimsDocUploadToken = useCallback((async () => {
        try {
            const uploadTokenID = await GatewayDocumentService
                .generateUploadToken(authHeader);
            return uploadTokenID;
        } catch (e) {
            return handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    }), [authHeader, handleError]);

    const repopupDocumentsPage = useCallback(() => {
        modalApi.showModal(
            <DocumentsPage {...props} />
        ).catch(() => {
            _.noop();
        });
    }, [props]);

    const uploadDocument = useCallback((async (file, vm) => {
        const documentMetaDataTemplate = {
            docUID: '001',
            documentType: _.get(vm, 'value.documentType'),
            description: _.get(vm, 'value.description'),
            securityType: 'unrestricted',
            status: 'approved',
            jobNumber: jobNumber,
            name: file.name,
            mimeType: file.type,
            sessionID: await claimsDocUploadToken()
        };
        if (isCL) {
            // // Agency and Internal
            // _.set(documentMetaDataTemplate, 'securityType', 'agency_Ext');
            // Final
            _.set(documentMetaDataTemplate, 'status', 'final');
        }
        try {
            const documentUploadData = await GatewayDocumentService
                .uploadDocument(file, documentMetaDataTemplate,
                    authHeader);
            if (!_.isEmpty(documentUploadData)) {
                getDocuments();
                // repopupDocumentsPage();
            }
        } catch (error) {
            // BR.PL.0254
            if (error
                && _.get(error, 'baseError.message') === 'ErrorCode: -32603-INTERNAL_ERROR ErrorMessage: Error Code: DMS_PC_GW_SE_400, Call Document Management Service Failed,  Please contact with System Administrator.'
                && _.get(error, 'baseError.code') === -32603
            ) {
                const message = _.get(domainCompany, 'code') === 'UIC' ? messages.uploadFailedMessageUIC : messages.uploadFailedMessage;
                handleWarning(
                    messages.warningUploadTitle,
                    message
                );
            } else {
                handleError(
                    commonMessages.errorUploadTitle,
                    commonMessages.uploadFailedMessage
                );
            }
        }
    }), [authHeader, claimsDocUploadToken,
        domainCompany,
        handleError, handleWarning,
        jobNumber, onReject, repopupDocumentsPage]);

    const deleteDocument = useCallback((async (publicID) => {
        try {
            setLoadingMask(true);
            const isDeleteItem = await GatewayDocumentService
                .removeDocument(publicID, authHeader);
            if (isDeleteItem) {
                getDocuments();
            }
            setLoadingMask(false);
        } catch (documentDeletionError) {
            handleError(
                messages.documentDeletionFailed,
                messages.documentCannotDeleted
            );
        }
    }), [authHeader, handleError]);

    const downloadDocument = useCallback((async (publicID, sessionID) => {
        const serviceUrl = getProxiedServiceUrl('downloadDocument');
        const templateDownloadEndpointURL = serviceUrl.concat(`/${publicID}?token=${sessionID}`);

        const errorCallback = () => {
            history.push('/documenterror');
        };

        DocumentDownloadService.getDocument(
            templateDownloadEndpointURL,
            authHeader,
            errorCallback
        );
    }), [authHeader, history]);

    useEffect(() => {
        getDocuments();
        // if (!initialized) {
        //     updateInitialized(true);
           
        // }
    }, []);

    const overrideProps = {
        '@field': {},
        quoteDocuments: {
            initialDocumentsData: documentsData,
            uploadDocument,
            deleteDocument,
            downloadDocument,
            noDataMessage: translator(messages.noCancellationDocuments),
            onReject,
            submissionVM,
            repopupDocumentsPage,
            viewModelService,
            authHeader,
            breakpoint,
            showFilterBar: !isCL,
            isInPopup: isInPopup,
            // documentNameColumnHeader: translator(messages.attachmentColumnName),
            showActionColumn: !isCL,
            getRelatedFieldFn: isCL ? AttachmentUtil.getRelatedFieldFn : _.noop,
            getDateModifiedFn: isCL ? AttachmentUtil.getDateModifiedFn : _.noop,
            productCode
        }
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            fieldlinkcomponent: FieldLinkComponent,
            documentscomponent: DocumentsComponent
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} documentPopup`}>
            <ModalBody autoFocus={false} id="documentPopup">
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">Close</Button>
            </ModalFooter>
        </ModalNext>
    );
}

DocumentsPage.propTypes = {
    title: PropTypes.string,
    size: PropTypes.string,
    underwriterVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ).isRequired,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    submissionVM: PropTypes.shape({}),
    authHeader: PropTypes.shape({}),
    history: PropTypes.func,
    fromAccountLanding: PropTypes.shape({
        quoteDetailsData: PropTypes.shape({
            jobNumber: PropTypes.string
        })
    }),
    isCL: PropTypes.bool,
    isInPopup: PropTypes.bool
};

DocumentsPage.defaultProps = {
    title: '',
    size: '',
    isOpen: false,
    onReject: _.noop,
    submissionVM: {},
    authHeader: {},
    fromAccountLanding: {
        quoteDetailsData: {
            jobNumber: ''
        }
    },
    history: _.noop,
    isCL: false,
    isInPopup: false,
};

export default DocumentsPage;
