import React, {
    useCallback,
    useState,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Loader,
} from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { 
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import CoverageUtil from '../../util/PUCoverageUtil';
import CoveragesConfigContext from '../../context/PUCoveragesConfigContext';
import coveragesConfig from '../../PUCoverages.config'
import messages from './PUTermsPopup.messages'
import SingleClauseContext from '../../context/PUSingleClauseContext';
import PUTermComponent from '../PUSingleClauseComponentVM/PUTermComponent';


function PUTermsPopup(props) {
    const {
        size,
        isOpen,
        closePopup,
        isEditable,
        // onReject,
        onResolve,
        // clauseVMPath,
        submissionVM,
        clauseCode,
        clauseVMPath,
        coverages,
        changeSubmission,
        changeSubmissionAndSync,
        onSyncCoverages,
        loadingClausesCodes = [],
    } = props;


    const { authHeader } = useAuthentication();
    const translator = useTranslator();

    const coverage = coverages.find((coverageDTO) => _.get(coverageDTO, 'code_Ext') === clauseCode) || {}

    const {
        terms = [],
        publicID,
        selected,
        name,
    } = coverage

    const {
        isComponentValid,
        onValidate,
        // registerComponentValidation,
    } = useValidation(`TermsPopup_${clauseCode}`);

    const [isEditing, setIsEditing] = useState(false);
    
    const [showErrors, setShowErrors] = useState(false);

    const {
        limitCoverageConfig,
        covTermAvailable,
    } = coveragesConfig;

    const curCovTermAvailableConfig = _.get(covTermAvailable, clauseCode, {})

    const isLoading = loadingClausesCodes && loadingClausesCodes.includes(clauseCode)

    const handleSave = async () => {
        if (!isComponentValid && isEditable) {
            setShowErrors(true)
            return
        }
        closePopup()
    }

    const actionButtonDisabled = isEditing || isLoading;

    

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={name} />
            <ModalBody id="termsPopupModal">
                <CoveragesConfigContext.Provider value={coveragesConfig}>
                    <SingleClauseContext.Provider value={{
                        idPrefix: 'CoverageInTermsPopup',
                        clauseCode: clauseCode,
                        clausePublicID: publicID,
                        clausePath: clauseVMPath,
                        isEditable: isEditable,
                        onChangeClause: changeSubmission,
                        onSyncCoverages: onSyncCoverages,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onValidate,
                        setIsEditing,
                        showErrors,
                    }}>
                        {isLoading ? <Loader loaded={!isLoading} /> : (
                            <>
                                {terms
                                    .filter((term) => {
                                        const termCode = _.get(term, 'code_Ext')
                                        const curTermAvailableFunc = _.get(curCovTermAvailableConfig, termCode)
                                        if (_.isFunction(curTermAvailableFunc)) {
                                            const isCurTermAvailable = curTermAvailableFunc()
                                            if (!isCurTermAvailable) {
                                                return false
                                            }
                                        }
                                        // filter all limit terms in edit mode
                                        if (!isEditable) {
                                            return true
                                        }
                                        return _.get(term, 'code_Ext') !== _.get(limitCoverageConfig, clauseCode)
                                    })
                                    .map((term) => {
                                        const termIndex = terms
                                            .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === _.get(term, 'code_Ext'))
                                        return <PUTermComponent
                                            key={_.get(term, 'code_Ext')}
                                            term={term}
                                            termIndex = {termIndex}
                                        />
                                    })}
                            </>
                        )}
                    </SingleClauseContext.Provider>
                </CoveragesConfigContext.Provider>
            </ModalBody>
            <ModalFooter>
                <Button disabled={actionButtonDisabled} onClick={() => handleSave()} type="filled">{translator(messages.save)}</Button>
            </ModalFooter>
        </ModalNext>
    );
}
PUTermsPopup.propTypes = {
    size: PropTypes.string
};
PUTermsPopup.defaultProps = {
    size: 'md',
};

export default PUTermsPopup;