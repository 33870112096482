import _ from 'lodash';
import { WizardStepConstants, WizardConstants } from 'wni-portals-config-js';
import WniProductsUtil from '../AccountAndProduct/WniProductsUtil';

const INVALID_PAGE_INDEX = -1;

/**
 * 
 * @author Fengjuan.Guo@wnins.com
 * @param {*} steps 
 * @param {*} sectionTitleStep 
 * @returns 
 */
function flattenWizarSteps (steps, sectionTitleStep) {
    const newSteps = _.cloneDeep(steps);
    return newSteps.reduce((allSteps, currentStep) => {
        if(currentStep.pages && _.isArray(currentStep.pages)) {
            const currentStepTitle = _.omit(currentStep, 'pages');
            return [...allSteps, ...flattenWizarSteps(currentStep.pages, currentStepTitle)]
        };

        if(sectionTitleStep) {
            _.set(currentStep, WizardConstants.parentTitle, sectionTitleStep.title);
            _.set(currentStep, WizardConstants.parentId, sectionTitleStep.id);
        };

        // stepVisible might have already been set in initialSteps
        if (_.isUndefined(currentStep[WizardConstants.stepVisible])) {
            _.set(currentStep, WizardConstants.stepVisible, true); // to be used by filterVisibleSteps() below
        }
        
        return [...allSteps, currentStep]
    }, []);
}

/**
 * 
 * @author Fengjuan.Guo@wnins.com
 * @param {*} steps 
 * @returns 
 */
function filterVisibleSteps(steps) {
    if (_.isEmpty(steps)) {
        return [];
    }
    const visibleSteps = steps.filter((step) => step[WizardConstants.stepVisible]);
    return visibleSteps;
}


/**
 * Get pageIndex from current pageInfo provided
 * @param {string | number} pageInfo the pageIndex or pageId
 * @param {array} wizardSteps
 * @returns {number}
 */
function getWizardPageIndex(pageInfo, wizardSteps = []) {
    // if (Number.isInteger(pageInfo)) {    //not supported by IE
    if (_.isNumber(pageInfo)) {
        return pageInfo;
    }
    let pageIndex = INVALID_PAGE_INDEX;
    if (pageInfo) { // pageId
        pageIndex = wizardSteps.findIndex((step) => step.id === pageInfo);
    }
    return pageIndex;
}


/**
 * Get QuotePageIndex, which is described by th "isQuotePgae" property in pa-wizard-config.json
 * EXAMPLE in the wizard config file:
 * {
 *   "id": "PAQuotePage",
 *   "title": { "id":"quoteandbind.wizard.step.Quote", "defaultMessage": "Quote" },
 *   "path": "/quote",
 *   "component": "PAQuotePage",
 *   "stepProps": {
 *        "template": "WizardPageTemplateWithTitle"
 *   },
 *   "isQuotePage": true
 * }
 * @param {array} wizardSteps
 * @returns {number} page index of the quote page
 */
function getQuotePageIndex(wizardSteps) {
    const flattenSteps = flattenWizarSteps(wizardSteps);
    const visibleSteps = filterVisibleSteps(flattenSteps);
    const retval = visibleSteps.findIndex((step) => step[WizardStepConstants.isQuotePage]);
    return retval;
}


/**
 * Checks whether the Wizard should stay on current page
 * @param {Object} param0 wizard data info
 * @returns {boolean}
 */
function shouldStayOnCurrentPage({
    periodStatus,
    currentStepIndex,
    lastExitWizardPageIndex,
    quotePageIndex,
}) {
    if (periodStatus !== 'Draft') {
        return false;
    }
    const furthestPageIndex = Math.min(lastExitWizardPageIndex, quotePageIndex - 1);
    return currentStepIndex >= furthestPageIndex;
}

function getPolicyLinePatterns(lobData) {
    return _.get(lobData, 'commercialPackage.coverables.lineSelection.policyLinePatterns', []);
};

function skipQualificationPageForCPP(policyLinePatterns = []) {
    const selectedLinePatternIds = policyLinePatterns.filter((item) => item.checked).map((v) => v.publicId);
    return _.isEmpty(_.intersection(['GL7Line'], selectedLinePatternIds));
};

function skipLinesForCPP(lobData) {
    const selectedLines = getPolicyLinePatterns(lobData)?.filter((item) => item.checked).map((v) => v.publicId);
    const shouldHideLines = WniProductsUtil.policyLinePatternForCPP?.filter((item) => !_.includes(selectedLines, item.policyLinePattern));
    const shouldHideParentIds = shouldHideLines.map((item) => `CPP_${_.upperFirst(item.lobName)}`);
    return shouldHideParentIds;
};



export default {
    getWizardPageIndex,
    getQuotePageIndex,
    shouldStayOnCurrentPage,
    getPolicyLinePatterns,
    skipQualificationPageForCPP,
    skipLinesForCPP,
    //
    flattenWizarSteps,
    filterVisibleSteps,
};
