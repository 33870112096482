import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Button } from '@jutro/components'
import { Flex } from '@jutro/layout'
import { useValidation } from '@xengage/gw-portals-validation-react';
import {React, useState, useCallback, useContext, useEffect} from 'react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CPRisksService, CPRetrieveRiskItemSummaryService } from 'wni-capability-quoteandbind-cp';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal, ValidationIssuesComponent } from 'wni-components-platform-react';
import { ValidationIssueUtil } from 'wni-portals-util-js';
import metadata from '../Common/CommonRiskItemDetails.metadata.json5';
import DetailsFieldMap from '../Common/DetailsFieldMap'
import OccupancyClauses from '../OccupancyClauses';
import riskItemMessages from '../RiskItemComponent/RiskItemComponent.messages';
import SearchClassCodeDescriptionPopup from '../Common/SearchClassCodeDescriptionPopup';


const Occupancy = (props) => {
    const modalApi = useWniModal();
    const translator = useTranslator();

    const {
        onValidate,
        isComponentValid,
    } = useValidation('Occupancy');

    const { authHeader } = useAuthentication();
    const [occupancyClauses, setOccupancyClauses] = useState()

    const [showErrors, updateShowErrors] = useState(false)
    const [validationIssues, updateValidationIssues] = useState([]);
    const {
        riskItem,
        jobID,
        sessionUUID,
        submissionVM,
        updateSubmissionVM,
        isReadOnly,
        handleCloseRisk,
    } = props

    const {
        locationPublicID,
        buildingPublicID,
        publicID: occupancyPublicID, 
        locationDescription,
        buildingDescription,
    } = riskItem

    const serviceProps = {
        jobID,
        sessionUUID,
        authHeader
    }
    const viewModelService = useContext(ViewModelServiceContext);
    const dtoName = 'wni.edge.capabilities.quote.lob.commercialproperty.dto.CPOccupancyDTO';
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [currentRowVM, setCurrentRowVM] = useState({})

    const fieldModels = _.get(currentRowVM?.value, 'occupancyDisplayables', []);
    const showSearchModal = useCallback((tableData, isClassDescriptionSearch) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            tableData,
            isClassDescriptionSearch
        };
        return modalApi.showModal(
            <SearchClassCodeDescriptionPopup {...componentProps} />
        );
    }, [modalApi]);
    const getOccupancyDetailsFunc = useCallback(async () => {
        const res = await CPRisksService.getOccupancyDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, true, authHeader);
        return res
    }, [authHeader, buildingPublicID, jobID, locationPublicID, occupancyPublicID, sessionUUID])

    const updateOccupancyDetailsFunc = useCallback(async (dto) => {
        const [needsRefresh, notNeedRefresh] = _.partition(dto.occupancyDisplayables?.filter(elt => elt.updated), 'needsRefresh');
        const orderedDisplayables = notNeedRefresh.concat(needsRefresh);
        Object.assign(dto, {'changedOccupancyDisplayables': orderedDisplayables});
        const res = await CPRisksService.updateOccupancyDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, dto, authHeader);
        return res
    }, [authHeader, buildingPublicID, jobID, locationPublicID, occupancyPublicID, sessionUUID])

    const getOccupancyClausesFunc = useCallback(async () => {
        const res = await CPRisksService.getOccupancyClassClauses(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, authHeader)
        return res
    }, [jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, authHeader])

    const updateOccupancyClausesFunc = async (dto) => {
        const res = await CPRisksService.updateOccupancyClassClauses(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, dto, authHeader)
        return res
    }

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true)
        const res = await serviceCallFunc()
        setLoadingMask(false)
        return res
    }


    const initFieldData = useCallback(async () => {
        // const buidlingDetailsDto = await getOccupancyDetailsFunc()
        // const newOccupancyClauses = await getLocationClausesFunc()
        const [
            occupancyDetailsDto, 
            newOccupancyClauses
        ] = await withLoadingMask(() => Promise.all([
            getOccupancyDetailsFunc(),
            getOccupancyClausesFunc()
        ]))
        
        const res = viewModelService.create(occupancyDetailsDto, 'pc', dtoName);
        setOccupancyClauses(newOccupancyClauses)
        setCurrentRowVM(res)
        
    // !!! Never add setLoadingMask into dependency, which always different after render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService])


    const updateService = async(serviceData) => {
        const {
            occupancy: updatedDetailsDto,
            occupancyClauses: newOccupancyClauses
        } = await withLoadingMask(() => updateOccupancyDetailsFunc(serviceData));
        setOccupancyClauses(newOccupancyClauses);
        const displayables = updatedDetailsDto?.occupancyDisplayables;
        const clonedDisplayables = _.set(_.clone(updatedDetailsDto), 'occupancyDisplayables', displayables);
        const res = viewModelService.create(clonedDisplayables, 'pc', dtoName);
        setCurrentRowVM(res);
        return res
    };

    const writeValue = (fieldItem, path, fieldModel = {}) => {
        const newVm = viewModelService.clone(currentRowVM);
        _.set(newVm.value, path, fieldItem);
        setCurrentRowVM(newVm);
        _.set(currentRowVM.value, path, fieldItem);
        if(fieldModel.triggerFunc === 'onValueChange' && fieldItem.needsRefresh) {
            updateService(currentRowVM.value)
        }
    }

    const onBlur = (value, fieldItem) => {
        updateService(currentRowVM.value, value, fieldItem)
    };

    const writeSearchedDetailsValue = async(fieldItem, seletedRow) => {
        const { propertyName } = fieldItem;
        setLoadingMask(true)
        const updatedOccupancyDtos = await CPRetrieveRiskItemSummaryService.getUpdatedOccupancyDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, propertyName, seletedRow, authHeader);
        const res = viewModelService.create(updatedOccupancyDtos, 'pc', dtoName);
        setCurrentRowVM(res);
        setLoadingMask(false);
    }

    const handleSearch = async(fieldItem = {}) => {
        const { propertyName } = fieldItem;   
        const tableData = await  withLoadingMask(() => CPRetrieveRiskItemSummaryService.fetchOccupancyClassCodeTable(jobID, sessionUUID, locationPublicID, buildingPublicID,occupancyPublicID, propertyName, authHeader));
        const isClassDescriptionSearch = propertyName === "ClassDesc";
        showSearchModal(tableData, isClassDescriptionSearch)
            .then((seletedRow) => {
                const ClassCodeDisplayable = fieldModels.find(elt => elt.propertyName === propertyName);
                writeSearchedDetailsValue(ClassCodeDisplayable, seletedRow);
            }).catch(() => {
                _.noop();
            })
    };

    useEffect(() => {
        initFieldData()
    }, [initFieldData])

    const handleClose = async () => {
        if (isReadOnly) {
            handleCloseRisk((oldTreeRows) => oldTreeRows)
            return
        }
        if (!isComponentValid) {
            updateShowErrors(true)
            return
        }
        const newVm = viewModelService.clone(currentRowVM);
        const allUpdatedDisplayables = _.get(newVm, 'value.occupancyDisplayables', [])?.filter(elt => elt.updated);
        if (!_.isEmpty(allUpdatedDisplayables)) {
            updateService(newVm.value);
        }
        const occupancySummary = await withLoadingMask(() => CPRetrieveRiskItemSummaryService.retrieveOccupancyClassSummary(
            jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, authHeader
        ))
        const {coverableColumnBasicInfo, errorsAndWarnings} = occupancySummary;
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);
        const generateNewTiskTreeRowFunc = (oldRiskTreeRows) => {
            const newRiskTreeRows = _.clone(oldRiskTreeRows)
            const locationIndex = oldRiskTreeRows.findIndex(location => location.publicID === locationPublicID)
            const location = oldRiskTreeRows[locationIndex]
            const buildings = location.buildingTreeRows
            const buildingIndex = buildings.findIndex(building => building.publicID === buildingPublicID)
            const building = buildings[buildingIndex]
            const occupancies = building.occupancyTreeRows
            const occupancyIndex = occupancies.findIndex(occupancy => occupancy.publicID === occupancyPublicID)
            
            _.set(
                newRiskTreeRows, 
                `[${locationIndex}].buildingTreeRows[${buildingIndex}].occupancyTreeRows[${occupancyIndex}].coverableColumnBasicInfo`, 
                coverableColumnBasicInfo
            )

            return newRiskTreeRows
        }
        if (!_.isEmpty(newValidationIssues)) {
            updateShowErrors(true)
            return
        }
        handleCloseRisk((oldTreeRows) => generateNewTiskTreeRowFunc(oldTreeRows))
    }
    
    const overrideProps = {
        fieldSetModel: {
            vm: currentRowVM,
            dataPath: 'occupancyDisplayables',
            // submissionVM,
            onValueChange: writeValue,
            onSearch: handleSearch,
            onValidate,
            onBlur,
            showErrors,
            isReadOnly,
            // filterSizeClassMapsInChange
        },
        riskItemDetailsCard: {
            title: 'Occupancy Details'
        },
        locationInput: {
            visible: true,
            value: locationDescription
        },
        buildingInput: {
            visible: true,
            value: buildingDescription
        }
    }
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            fieldsetmap: DetailsFieldMap
        }
    };
    return (
        <>
            <ValidationIssuesComponent validationIssues={validationIssues}/>
            <ViewModelForm
                uiProps={metadata.componentContent}
                // model={vehicleVM}
                overrideProps={overrideProps}
                // onValueChange={onValueChange}
                onValidationChange={onValidate}
                // resolveValue={readValue}
                // classNameMap={resolvers.resolveClassNameMap}
                // callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            <OccupancyClauses
                riskItem={riskItem}
                serviceProps={serviceProps}
                submissionVM={submissionVM}
                updateSubmissionVM={updateSubmissionVM}
                updateClauseFunc={updateOccupancyClausesFunc}
                riskClauses={occupancyClauses}
                setRiskClauses={setOccupancyClauses}
                showErrors={showErrors}
                isEditable={!isReadOnly}
                onValidate={onValidate}
            />
            <Flex justifyContent="right">
                <Button onClick={handleClose}>
                    {translator(riskItemMessages.Close)}
                </Button>
            </Flex>
        </>
    );
}

export default Occupancy