import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './ExportDriverModal.metadata.json5';

function ExportDriverModal(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        download,
        isOpen,
        onResolve,
        onReject,
        title,
        useDependenciesData
    } = props;

    const { loadingMask: { setLoadingMask } } = useDependenciesData;

    const handleExport= async() => {
        setLoadingMask(true);
        await download(false, 'Data');
        setLoadingMask(false);
        onResolve()
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalHeader title={title} />
            <ModalBody id="exportDriver">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter
                contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: 'middle',
                        justifyContent: 'between',
                    },
                }}
            >
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleExport}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

ExportDriverModal.propTypes = {
};
ExportDriverModal.defaultProps = {
    size: 'md'
};
export default ExportDriverModal;
