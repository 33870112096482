import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import metadata from './TerritoryPopup.metadata.json5';

const STATE_TYPEKEY = 'typekey.Jurisdiction';

const pageConfig = {
    page: 0,
    pageSize: 5
};

function TerritoryPopup(props) {
    const {
        size,
        title,
        model,
        data,
        baseState,
        searchTerritoryCode = _.noop,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        viewModelService
    } = props;

    const defaultSearchData = {
        state: baseState
    };

    const availableStates = _.get(model, 'address_Ext.state.aspects.availableValues', []);
    const allData = _.cloneDeep(data);
    const translator = useTranslator();
    const [vm, updateVM] = useState({});
    const [searchData, updateSearchData] = useState(defaultSearchData);
    const [tableData, updateTableData] = useState(allData);
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);

    const setVM = (value, path) => {
        const newVM = viewModelService.clone(model);
        _.set(newVM, path, value);
        updateVM(newVM);
    };

    useEffect(() => {
        setVM({}, 'value');
    }, []);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'territoryTable');
    };

    useEffect(() => {
        highlightRowFn(vm.value)
    }, [vm]);

    const handleSave = () => {
        if(vm.value) {
            onResolve(vm.value);
        }
    };

    const handleSearch = async() => {
        setVM({}, 'value');
        const requestData = _.omitBy(searchData, (val) => !val)
        const res = await searchTerritoryCode(requestData);
        updateTableData(res || []);
        updateTablePageConfig(pageConfig);
    };

    const handleReset = () => {
        updateSearchData(defaultSearchData);
        setVM({}, 'value');
        updateTableData(allData);
        updateTablePageConfig(pageConfig);
    };

    const handleSelect = (item) => {
        const newItem = {
            ...item,
            state: defaultSearchData.state
        };
        updateSearchData(newItem); // update search bar
        setVM(newItem, 'value');// update industryCode model
    };

    const renderSelectCell = (item, index, porperty) => {
        const buttonDom = <Button onClick={() => handleSelect(item, index, porperty)} >Select</Button>
        return WniTableRowUtil.renderCell(item.publicID, buttonDom)
    };

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(vm.value);
        return DatatableUtil[sortType](a, b);
    };
  

    const writeValue = (value, path) => {
        const newSearchData = {
            ...searchData,
            [path]: value
        };
        updateSearchData(newSearchData);
    };
    //---------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
        },
        territoryState: {
            availableValues: availableStates,
            value: searchData.state ? translator({id: `${STATE_TYPEKEY}.${searchData.state}`}) : null
        },
        territoryTable: {
            data: tableData,
            config: tablePageConfig,
            onConfigChange: (obj) => updateTablePageConfig(obj),
            pageSizeOptions: [5, 10, 15, 20]
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            handleSearch,
            handleReset,
            renderSelectCell,
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber')
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="territoryCode">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={searchData}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} disabled={!vm.value || _.isEmpty(vm.value)} >{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

export default TerritoryPopup;
