import { AccordionCard } from '@jutro/components';
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import CoverageContext from '../../../GeneralLiability/context/GLCoverageContext';
import GLCoverageUtil from '../../../GeneralLiability/util/GLCoverageUtil';
import GLClausesComponentVM from '../../../GeneralLiability/components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../../../GeneralLiability/components/GLSingleClauseComponentVM/GLSingleClauseComponentVM';

const LocationExclusionAndConditionPerSublineClauses = (props) => {
    const {
        locationPublicID,
        locationSublineClauses,
        setLocationSublineClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: multiSublinesOnValidate,

        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        publicID: locationSublinePublicID,
        exclusion,
        condition,
        sublineTypeDisplayName 
    } = locationSublineClauses;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const validationID = `LocationExclusionAndConditionPerSublineClauses_${locationSublinePublicID}`

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (multiSublinesOnValidate) {
            multiSublinesOnValidate(isComponentValid, validationID);
        }
        return () => {
            if (multiSublinesOnValidate) {
                multiSublinesOnValidate(true, validationID);
            }
        }
    }, [isComponentValid, multiSublinesOnValidate, validationID])


    const [loadingClauses, setLoadingClauses] = useState(false);

    const changeClauses = useCallback(
        (value, changedPath, clauses, clausesPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(
                clauses,
                clausesPath,
                value,
                changedPath
            );
            const newLocationClauses = _.clone(locationSublineClauses);
            _.set(newLocationClauses, clausesPath, newClauses);
            setLocationSublineClauses(newLocationClauses);
            setIsEditing(false);
            return newLocationClauses;
        },
        [locationSublineClauses, setIsEditing, setLocationSublineClauses]
    );

    const changeExclusions = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, exclusion, 'exclusion');
        },
        [changeClauses, exclusion]
    );

    const changeConditions = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, condition, 'condition');
        },
        [changeClauses, condition]
    );

    const updateVMToServer = useCallback(async (newLocationSublineClauses, setLoadingClausesFunc, setEditingFunc) => {
        const locationSubLineClausesToUpdate = GLCoverageUtil.generateUpdatedLocationSublineClausesDTO(newLocationSublineClauses);
        const updatedLocationSublineClausesPublicIDs = GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedLocationSublineClausesDTO(locationSubLineClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedLocationSublineClausesPublicIDs)
        const response = await GLCoverageService.updateLocationPerSublineClauses(
            jobID,
            sessionUUID,
            locationPublicID,
            locationSublinePublicID,
            locationSubLineClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setLocationSublineClauses(response);
    }, [setLoadingMask, jobID, sessionUUID, locationPublicID, locationSublinePublicID, authHeader, setLocationSublineClauses]);

    const updateNewSubLineClausesToServer = useCallback(async (newSublineClauses) => {
        updateVMToServer(newSublineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);

    const changeExclusionsAndSync = useCallback(
        (value, changedPath) => {
            const newLocationClauses = changeExclusions(value, changedPath);

            updateNewSubLineClausesToServer(newLocationClauses);
        },
        [changeExclusions, updateNewSubLineClausesToServer]
    );

    const changeConditionsAndSync = useCallback(
        (value, changedPath) => {
            const newLocationClauses = changeConditions(value, changedPath);

            updateNewSubLineClausesToServer(newLocationClauses);
        },
        [changeConditions, updateNewSubLineClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewSubLineClausesToServer(locationSublineClauses);
        },
        [locationSublineClauses, updateNewSubLineClausesToServer]
    )

    return (
        <AccordionCard
            id={`${locationSublinePublicID}_ExclusionAndCondition`}
            chevron
            className="coveragesAccordion"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={sublineTypeDisplayName}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardData,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeExclusionsAndSync}
                        onChangeClause={changeExclusions}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="exclusion"
                        value={exclusion}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                    <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeConditionsAndSync}
                        onChangeClause={changeConditions}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="condition"
                        value={condition}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default LocationExclusionAndConditionPerSublineClauses;
