
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import metadata from './AQPolicyHolderUWQuestionComponent.metadata.json5';
import styles from './AQPolicyHolderUWQuestionComponent.module.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function AQPolicyHolderUWQuestionComponent(props) {
    const {
        id,
        onValidate,
        model: dtoVM,
        onValueChange,
        showErrors,
        uwQuestions: {
            isIncludeHoLine,
            anyCoverageDeclined,
            anyCoverageDeclinedExplainVisible,
            anyCoverageDeclinedExplainValue,
            anyApplicant,
            anyApplicantExplainVisible,
            anyApplicantExplainValue,
            hasBeenIndictedIn5Years,
            hasBeenIndictedIn5YearsExplainVisible,
            hasBeenIndictedIn5YearsExplainValue,
            haveMaintainedContinuousCov,
            haveMaintainedContinuousCovExplainValue,
            transferPart
        },
        uwQuestions,
        setUWQuestions
    } = props;

    const{
        isComponentValid,
        onValidate: setComponentValidation,
    } = useValidation(id);
    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    // const [saveUWQuestion, updateSaveUWQuestion] = useState();


    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [ id, onValidate, isComponentValid]);

    const writeValue = useCallback((value, path) => {
        const newSaveUWQuestion = _.clone(uwQuestions);
        _.set(newSaveUWQuestion, path, value);
        setUWQuestions(newSaveUWQuestion);
    }, [uwQuestions, setUWQuestions]);


    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        anyCoverageDeclinedExplain: {
            visible: (anyCoverageDeclined || anyCoverageDeclinedExplainVisible)
        },
        anyApplicantExplain: {
            visible: (anyApplicant || anyApplicantExplainVisible)
        },
        hasBeenIndictedIn5Years: {
            visible: isIncludeHoLine
        },
        haveMaintainedContinuousCov: {
            visible: true
        },
        explainForHasLapseInContinuousCov: {
            visible: haveMaintainedContinuousCov === false
        },
        hasBeenIndictedIn5YearsExplain: {
            visible: ( hasBeenIndictedIn5Years || hasBeenIndictedIn5YearsExplainVisible) && isIncludeHoLine
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    // const readValue = (id, path) => {
    //     return readViewModelValue(
    //         metadata.componentContent,
    //         dtoVM,
    //         id,
    //         path,
    //         overrideProps
    //     );
    // };
    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={uwQuestions}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            // resolveValue={readValue}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
}

AQPolicyHolderUWQuestionComponent.propTypes = {
    uwQuestions: PropTypes.shape({
        anyCoverageDeclined: PropTypes.string,
        anyCoverageDeclinedExplainVisible: PropTypes.string,
        anyCoverageDeclinedExplainValue: PropTypes.string,
        anyApplicant: PropTypes.string,
        anyApplicantExplainVisible: PropTypes.string,
        anyApplicantExplainValue: PropTypes.string,
        transferPart: PropTypes.string
    }).isRequired,
    setUWQuestions: PropTypes.func.isRequired,
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
};

AQPolicyHolderUWQuestionComponent.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
};

export default AQPolicyHolderUWQuestionComponent;
