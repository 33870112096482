import React, {
    useState,
    useContext
} from "react";
import _ from 'lodash';
import { DisplayColumn } from '@jutro/datatable'
import {
    Button,
} from '@jutro/components';
import { Flex } from '@jutro/layout'
import { useTranslator } from '@jutro/locale';
import { WniDataTable, WniCheckboxField } from 'wni-common-base-components';
import WCScheduleItemReadonlyCell from "../../../WCScheduleTable/WCScheduleItemReadonlyCell";
import SingleClauseContext from "../../../../context/WCSingleClauseContext";
import messages from './WCComplexSchedulePopupTable.messages'

const WCComplexSchedulePopupTable = (props) => {

    const {
        schedule,
        openedScheduleItemNumber,
        updateSubmissionVMToServer,
        setOpenedScheduleItemNumber,
        isLoading,
        isEditing,
    } = props

    const {
        propertyInfos = [],
        scheduleItems = [],
        deserializationClass,
    } = schedule

    const translator = useTranslator();

    const {
        clauseCode: code,
        clausePath: clauseVMPath,
        onChangeClause,
        isEditable,
    } = useContext(SingleClauseContext)

    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] = useState([])

    const scheduleNumberColumn = <DisplayColumn
        key='ScheduleNumber'
        header= 'Schedule Number'
        sortable = {false}
        textAlign = 'left'
        path = 'itemNumber'
        renderCell = {(scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            if (!isEditable) {
                return itemNumber
            }
            return <WniCheckboxField
                value = {selectedScheduleItemNumbers.includes(itemNumber)}
                label = {itemNumber}
                showInlineLabel
                onValueChange = {(checked) => {
                    let newSelectedScheduleItemNumbers
                    if (checked) {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.concat([itemNumber])
                    } else {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.filter(num => num !== itemNumber)
                    }
                    setSelectedScheduleItemNumbers(newSelectedScheduleItemNumbers)
                }}
            />
        }}
    />

    const propertyInfoColumns = propertyInfos
        .filter((propertyInfo) => {
            const {
                isCovTermPropertyInfo_Ext: isCovTermPropertyInfo
            } = propertyInfo;
            return !isCovTermPropertyInfo
        })
        .map((propertyInfo) => {
            const {
                id,
                label,
            } = propertyInfo
            
            return <DisplayColumn
                key = {id}
                header = {label}
                renderCell = {(scheduleItem) => {
                    return <WCScheduleItemReadonlyCell
                        propertyInfo={propertyInfo}
                        scheduleItem={scheduleItem}
                    />
                }}
                sortable = {false}
            />
        })
    
    const actionColumn = <DisplayColumn
        renderCell = {(scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            return <Button
            className="wni-button-link"
            disabled={isEditing || openedScheduleItemNumber}
            onClick={() => {
                setOpenedScheduleItemNumber(itemNumber)
            }}
        >
            {translator(messages.viewOrEdit)}
        </Button>
        }}
        sortable = {false}
    />

    const sortedScheduleItems = scheduleItems.toSorted((scheduleItemA, scheduleItemB) => {
        const getScheduleNumber = (scheduleItem) => {
            return _.get(scheduleItem, 'itemNumber')
        }
        return getScheduleNumber(scheduleItemA) - getScheduleNumber(scheduleItemB)
    })

    const delSchedule = async () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            return !selectedScheduleItemNumbers.includes(itemNumber)
        })
        if (selectedScheduleItemNumbers.includes(openedScheduleItemNumber)) {
            setOpenedScheduleItemNumber(null)
        }
        setSelectedScheduleItemNumbers([])
        
        const updatedSumissionVMInPE = onChangeClause(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
        await updateSubmissionVMToServer(updatedSumissionVMInPE)
    }

    const addSchedule = async () => {
        const newScheduleItems = scheduleItems.concat([{
            '@deserialization-class': deserializationClass,
            itemData: {
            }
        }])
        const updatedSumissionVMInPE = onChangeClause(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
        await updateSubmissionVMToServer(updatedSumissionVMInPE)
    }

    const isLoadingOrEditing = isLoading || isEditing

    return <>
        <h5>{translator(messages.scheduleTableTitle)}</h5>
        {isEditable && <Flex
            gap = "small"
            justifyContent = "right"
            className = "mb-10"
        >
            <Button
                className = "wni-button-danger"
                type = "filled"
                disabled = {isLoadingOrEditing || selectedScheduleItemNumbers.length === 0}
                onClick = {delSchedule}
            >
                {translator(messages.scheduleDel)}
            </Button>
            <Button
                icon = "gw-add"
                onClick = {addSchedule}
                disabled = {isLoadingOrEditing || !_.isNil(openedScheduleItemNumber)}
            >
                {translator(messages.scheduleAdd)}
            </Button>
        </Flex>}
        <div className='table-wrapper mb-10'>
            <WniDataTable
                id = {`clause_schedule_table_${code}`}
                data={sortedScheduleItems}
                showSearch={false}
            >
                {[scheduleNumberColumn, ...propertyInfoColumns, actionColumn]}
            </WniDataTable>
        </div>
    </>
}

export default WCComplexSchedulePopupTable