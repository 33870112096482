import { setComponentMapOverrides } from '@jutro/uiconfig';

import { PaymentDetailsPage } from 'gw-capability-quoteandbind-common-react';

import CPPolicyDetailsPage from './pages/CPPolicyDetails/CPPolicyDetailsPage';
import CPBuildingsAndLocationsPage from './pages/BuildingsAndLocations/CPBuildingsAndLocationsPage';
import CPQuotePage from './pages/CPQuote/CPQuotePage';
import CPPolicyInformationPage from './pages/CPPolicyInformation/CPPolicyInformationPage';
import CPSuccessPage from './pages/CPSuccess/CPSuccessPage';

setComponentMapOverrides(
    {
        CPPolicyDetailsPage: { component: 'CPPolicyDetailsPage' },
        CPBuildingsAndLocationsPage: { component: 'CPBuildingsAndLocationsPage' },
        CPQuotePage: { component: 'CPQuotePage' },
        CPPolicyInformationPage: { component: 'CPPolicyInformationPage' },
        CPPaymentDetailsPage: { component: 'CPPaymentDetailsPage' },
        CPSuccessPage: { component: 'CPSuccessPage' },
    },
    {
        CPPolicyDetailsPage,
        CPBuildingsAndLocationsPage,
        CPQuotePage,
        CPPolicyInformationPage,
        CPPaymentDetailsPage: PaymentDetailsPage,
        CPSuccessPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PECPWizard } from './PECPWizard';
export { default as BuildingLocationPageTemplate } from './components/BuildingWizard/AddBuildingWizard/templates/BuildingsAndLocationsTemplate';
export { default as BuildingsListPage } from './pages/BuildingsAndLocations/BuildingsList/BuildingsListPage';
export { default as PECPBuildingsWizard } from './components/BuildingWizard/AddBuildingWizard/PECPBuildingsWizard';
export { default as OverallSummary } from './components/summary/OverallSummary/OverallSummary';
