import { setComponentMapOverrides } from '@jutro/uiconfig';

import CADummyPage from './pages/Dummy/CADummyPage';
import CADummyReadOnlyPage from './pages/Dummy/CADummyPage.readonly';

import CACoveragesPage from './pages/Coverages/CACoveragesPage';
import CACoveragesReadOnlyPage from './pages/Coverages/CACoveragesPage.readonly';

import CADriversPage from './pages/Drivers/CADriversPage';
import CADriversReadOnlyPage from './pages/Drivers/CADriversPage.readonly';

import CAQuotePage  from './pages/Quote/CAQuotePage';
import CAQuoteReadOnlyPage from './pages/Quote/CAQuotePage.readonly';

import CAVehiclesPage from './pages/Vehicles/CAVehiclesPage';
import CAVehiclesReadOnlyPage from './pages/Vehicles/CAVehiclesPage.readonly';

import CALocationsPage from './pages/Locations/CALocationsPage';
import CALocationsReadOnlyPage from './pages/Locations/CALocationsPage.readonly';

import CAModifiersPage from './pages/Modifiers/CAModifiersPage';
import CAModifiersReadOnlyPage from './pages/Modifiers/CAModifiersPage.readonly';

import CAPolicyDetailsPage from './pages/PolicyDetails/CAPolicyDetailsPage';
import CAPolicyDetailsReadOnlyPage from './pages/PolicyDetails/CAPolicyDetailsPage.readonly';

import CAQualificationPage from './pages/Qualification/CAQualificationPage';
import CAQualificationReadOnlyPage from './pages/Qualification/CAQualificationPage.readonly';

import CAStateSpecificInformationPage from './pages/StateSpecificInformation/CAStateSpecificInformationPage';
import CAStateSpecificInformationReadOnlyPage from './pages/StateSpecificInformation/CAStateSpecificInformationPage.readonly';

import CACoveredVehicleSymbolsPage from './pages/CoveredVehicleSymbols/CACoveredVehicleSymbolsPage';
import CACoveredVehicleSymbolsReadOnlyPage from './pages/CoveredVehicleSymbols/CACoveredVehicleSymbolsPage.readonly';

import CAUnderwritingQuestionsPage from './pages/UnderwritingQuestions/CAUnderwritingQuestionsPage';
import CAUnderwritingQuestionsReadOnlyPage from './pages/UnderwritingQuestions/CAUnderwritingQuestionsPage.readonly';

import CARiskAnalysisPage from './pages/RiskAnalysis/CARiskAnalysisPage';
import CARiskAnalysisReadOnlyPage from './pages/RiskAnalysis/CARiskAnalysisPage.readonly';

setComponentMapOverrides(
    {
        CADummyPage: {component: 'CADummyPage'},
        CADummyReadOnlyPage: { component: 'CADummyReadOnlyPage' },

        CACoveragesPage: {component: 'CACoveragesPage'},
        CACoveragesReadOnlyPage: { component: 'CACoveragesReadOnlyPage' },

        CADriversPage: {component: 'CADriversPage'},
        CADriversReadOnlyPage: { component: 'CADriversReadOnlyPage' },

        //
        CAQuotePage: {component: 'CAQuotePage'},
        CAQuoteReadOnlyPage: {component: 'CAQuoteReadOnlyPage'},

        CAVehiclesPage: {component: 'CAVehiclesPage'},
        CAVehiclesReadOnlyPage: {component: 'CAVehiclesReadOnlyPage'},

        CALocationsPage: {component: 'CALocationsPage'},
        CALocationsReadOnlyPage: {component: 'CALocationsReadOnlyPage'},

        CAModifiersPage: {component: 'CAModifiersPage'},
        CAModifiersReadOnlyPage: {component: 'CAModifiersReadOnlyPage'},

        CAPolicyDetailsPage: {component: 'CAPolicyDetailsPage'},
        CAPolicyDetailsReadOnlyPage: {component: 'CAPolicyDetailsReadOnlyPage'},

        CAQualificationPage: {component: 'CAQualificationPage'},
        CAQualificationReadOnlyPage: {component: 'CAQualificationReadOnlyPage'},

        CAStateSpecificInformationPage: {component: 'CAStateSpecificInformationPage'},
        CAStateSpecificInformationReadOnlyPage: {component: 'CAStateSpecificInformationReadOnlyPage'},

        CACoveredVehicleSymbolsPage: {component: 'CACoveredVehicleSymbolsPage'},
        CACoveredVehicleSymbolsReadOnlyPage: {component: 'CACoveredVehicleSymbolsReadOnlyPage'},

        CAUnderwritingQuestionsPage: {component: 'CAUnderwritingQuestionsPage'},
        CAUnderwritingQuestionsReadOnlyPage: {component: 'CAUnderwritingQuestionsReadOnlyPage'},

        CARiskAnalysisPage: {component: 'CARiskAnalysisPage'},
        CARiskAnalysisReadOnlyPage: {component: 'CARiskAnalysisReadOnlyPage'}
    },
    {
        CADummyPage,
        CADummyReadOnlyPage,
        // quote page

        CACoveragesPage,
        CACoveragesReadOnlyPage,

        CADriversPage,
        CADriversReadOnlyPage,

        //
        CAQuotePage,
        CAQuoteReadOnlyPage,
        // vehicle page
        CAVehiclesPage,
        CAVehiclesReadOnlyPage,

        //
        CALocationsPage,
        CALocationsReadOnlyPage,

        CAModifiersPage,
        CAModifiersReadOnlyPage,

        CAPolicyDetailsPage,
        CAPolicyDetailsReadOnlyPage,

        CAQualificationPage,
        CAQualificationReadOnlyPage,

        CAStateSpecificInformationPage,
        CAStateSpecificInformationReadOnlyPage,

        CACoveredVehicleSymbolsPage,
        CACoveredVehicleSymbolsReadOnlyPage,

        CAUnderwritingQuestionsPage,
        CAUnderwritingQuestionsReadOnlyPage,

        CARiskAnalysisPage,
        CARiskAnalysisReadOnlyPage
        
    }
);


export {
    CADummyPage,
    CADummyReadOnlyPage,

    CACoveragesPage,
    CACoveragesReadOnlyPage,

    // vehicle page
    CAVehiclesPage,
    CAVehiclesReadOnlyPage,

    CADriversPage,
    CADriversReadOnlyPage,
    //
    CALocationsPage,
    CALocationsReadOnlyPage,

    CAModifiersPage,
    CAModifiersReadOnlyPage,

    CAPolicyDetailsPage,
    CAPolicyDetailsReadOnlyPage,

    CAQualificationPage,
    CAQualificationReadOnlyPage,

    CAStateSpecificInformationPage,
    CAStateSpecificInformationReadOnlyPage,

    CACoveredVehicleSymbolsPage,
    CACoveredVehicleSymbolsReadOnlyPage,

    CAUnderwritingQuestionsPage,
    CAUnderwritingQuestionsReadOnlyPage,

    CARiskAnalysisPage,
    CARiskAnalysisReadOnlyPage
};

// eslint-disable-next-line import/prefer-default-export
export { default as CASubmissionWizard } from './CASubmissionWizard';
export { default as CAWizardPage } from './templates/CAWizardPage';

export { default as CAQuoteIssueRenderHelper } from './pages/Quote/util/CAQuoteIssueRenderHelper';
export { default as CAQuotePageConstants } from './pages/Quote/config/CAQuotePage.config';
