import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';

function ScheduleItemRemovePopvoer(props) {
    const {
        isOpen,
        title,
        message,
        confirmButtonText,
        icon,
        onResolve,
        onReject,
    } = props;

    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader
                title={title}
                status="warning"
                icon={icon}
                onClose={() => onReject()}
            />
            <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => onResolve(true)}>
                    {confirmButtonText}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

ScheduleItemRemovePopvoer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirmButtonText: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
};

export default ScheduleItemRemovePopvoer;
