import React, {
    useCallback,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
// import { Loader } from '@jutro/components';
import { InputField, Accordion, AccordionCard} from '@jutro/components';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
} from 'wni-portals-util-js';
import CPModifiersConfig from './CPModifiersPage.config';
import WizardPage from '../../templates/CPWizardPage';
import messages from './CPModifiersPage.messages';

function CPModifiersReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const {
        lobData: {
            commercialProperty: {
                modifiers_Ext : modifiers
            }
        },
    } = submissionVM.value;

    const {
        agentStateTotalRangeMap
    } = CPModifiersConfig

    const translator = useTranslator();
    const allStateTotalCreditDebit = modifiers.map(modifier => {
        return {state: modifier.jurisdiction, overallCreditDebit: modifier.rateFactors.reduce((acc, currentFactor) => acc + currentFactor.creditDebit, 0)}
    });
    const stateOverallMinMax = modifiers.map(modifier => {
        return {state: modifier.jurisdiction, overallMin: modifier.overallMinimum, overallMax: modifier.overallMaximum}
    });
    const [stateTotalCreditDebit, updateStateTotalCreditDebit] = useState(allStateTotalCreditDebit);
    const [validationIssues, updateValidationIssues] = useState();
    const [isModifiersUpdated, setIsModifiersUpdated] = useState(false);
    const { authHeader, authUserData } = useAuthentication();

    const states = modifiers.map(modifier => modifier.jurisdiction);
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const modifierAK = modifiers.find(modifier => modifier.jurisdiction === 'Alaska');
    const getRateFactorID = (rateFactor) => _.get(rateFactor, 'patternCode');

    const overallMaxAK = useMemo(() => {
        if (!_.isNil(modifierAK)) {
            return isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.maximum`) : modifierAK.overallMaximum;
        }
        return undefined;
    }, [agentStateTotalRangeMap, isExternalUser, modifierAK])

    const stateReadOnlyMap = useMemo(()=> {
        return states.map((currentState) => {
            const currentOverallCreditDebit = parseFloat(stateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit).toFixed(2);
            const currentTotalMinimum = stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
            const currentTotalMaximum = stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
            let isReadOnly = false;
            if (isExternalUser) {
                // UWs will have a larger credit / debit range than an agent would (UWs can do +/- 35% in AK whereas Agents can only do +/-15%) If an UW enters a modifier sum outside of the agent allowed amount, the screen should become un-editable for agents if he opened this submission
                if(!_.isNil(modifierAK)) {
                    isReadOnly = currentOverallCreditDebit > _.get(agentStateTotalRangeMap, `${modifierAK.overallMaximum}.maximum`) 
                    || currentOverallCreditDebit < _.get(agentStateTotalRangeMap, `${modifierAK.overallMinimum}.minimum`)
                } else {
                    isReadOnly =  currentOverallCreditDebit > currentTotalMaximum || currentOverallCreditDebit < currentTotalMinimum;
                }
            }
            return {state: currentState, isCurrentStateReadOnly: isReadOnly}
        })
    }, [])

    const overallMinAK = useMemo(() => {
        if (!_.isNil(modifierAK)) {
            return isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.minimum`) : modifierAK.overallMinimum;
        }
        return undefined;
    }, [agentStateTotalRangeMap, isExternalUser, modifierAK])

    const JustificationInputField = ({item, state, isReadOnly}) => {
        const [justification, setJustification] = useState(_.get(item, 'justification'));
        return <InputField
            onValueChange={(value) => setJustification(value)}
            readOnly={isReadOnly}
            value={justification}
        />
    }

    const CreditDebitInputField = ({item, state, isUnEditable, isReadOnly}) => {
        const shownCreditDebit = _.isNaN(_.get(item, 'creditDebit')) ? null : `${parseFloat(_.get(item, 'creditDebit') * 100).toFixed(1)}%`;
        const [creditDebit, setCreditDebit] = useState(shownCreditDebit);
        return <InputField
            onValueChange={(value) => {setCreditDebit(value)}}
            value={creditDebit}
            readOnly={isUnEditable || isReadOnly}
            showErrors
        />
    }

    const onPageNext = useCallback(async () => {
        return submissionVM;

    }, [submissionVM]);

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            pageLevelValidationIssues={validationIssues}
            onNext={onPageNext}
            alwaysCallOnNext
        >
            <h3>Modifiers</h3>
            <hr/>
            {
                modifiers.map(modifier => {
                    const currentState = modifier.jurisdiction;
                    const currentOverallCreditDebit = stateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
                    const currentTotalMinimum = stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
                    const currentTotalMaximum = stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
                    const isReadOnly = true;
                    const newRateFactors = modifier.rateFactors.concat({category: 'Overall'});
                    return <div>
                                <h4>Modifier: Schedule Rating Modification</h4>
                                <hr/>
                                <DataTable
                                    columnsConfigurable={false}
                                    data={newRateFactors}
                                    id="basic"
                                    showPagination={false}
                                    showSearch={false}
                                    tableLabel="Modifiers"
                                    >
                                    <DisplayColumn
                                        header="Category"
                                        id="category"
                                        path="category"
                                        textAlign="left"
                                        sortable={false}
                                    />
                                    <DisplayColumn
                                        header="Maximum Credit"
                                        id="minimum"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ?
                                            (overallMinAK && `${parseInt(overallMinAK * 100, 10)}%`) || `${parseInt(currentTotalMinimum * 100, 10)}%` 
                                            : `${parseInt(newRateFactors[index].minimum * 100, 10)}%`}}
                                        textAlign="left"
                                        sortable={false}
                                    />
                                    <DisplayColumn
                                        header="Maximum Debit"
                                        id="maximum"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ?
                                            (overallMaxAK && `${parseInt(overallMaxAK * 100, 10)}%`)  || `${parseInt(currentTotalMaximum * 100, 10)}%` 
                                            : `${parseInt(newRateFactors[index].maximum * 100, 10)}%`}}
                                        textAlign="left"
                                        sortable={false}
                                    />
                                    <DisplayColumn
                                        header="Credit(-)/Debit(+)"
                                        id="Liability"
                                        renderCell={(item, index) => {
                                            const isUnEditable = item.category === messages.unEditableCategory.defaultMessage && currentState === 'Alaska';
                                            return index ===  modifier.rateFactors.length ? `${parseFloat(currentOverallCreditDebit * 100).toFixed(1)}%` : <CreditDebitInputField item={item} state={currentState} isUnEditable={isUnEditable} isReadOnly={isReadOnly}/>}} 
                                        textAlign="left"
                                        sortable={false}
                                    />
                                    <DisplayColumn
                                        header="Justification"
                                        id="justification"
                                        renderCell= {(item, index) => {
                                            return index ===  modifier.rateFactors.length ? '' : <JustificationInputField item={item} state={currentState} isReadOnly={isReadOnly}/>}}
                                        textAlign="left"
                                        sortable={false}
                                    />
                                </DataTable>
                            </div>
                })
            }
        </WizardPage>
    );
}

CPModifiersReadOnlyPage.propTypes = WizardPage.propTypes;
CPModifiersReadOnlyPage.defaultProps = WizardPage.defaultProps;
export default CPModifiersReadOnlyPage;