import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalCoveragesTitle: {
        id: "quoteandbind.gl.wizard.step.state specific information page.Additional Coverages",
        defaultMessage: "Additional Coverages"
    },
    addAdditionalCoveragesActionText: {
        "id": "quoteandbind.gl.wizard.step.state specific information page.Additional Coverages.Add Coverages",
        "defaultMessage": "Add Coverages"
    },
    additionalCoveragesHelpTextTermDesc: {
        "id": "quoteandbind.gl.wizard.step.state specific information page.Additional Coverages.Help Text.Term desc",
        "defaultMessage": 'line level attorney fee limit'
    },
    additionalCoveragesHelpText: {
        "id": "quoteandbind.gl.wizard.step.state specific information page.Additional Coverages.Help Text",
        "defaultMessage": ' is incldued in your base policy. The below Attorney Fee limit is in excess of your base policy limit.'
    }
});