import { Button, CheckboxField } from '@jutro/components';
import { DataTable,DisplayColumn } from '@jutro/datatable';
import { Grid,useBreakpoint } from '@jutro/layout';
import { IntlContext,useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React,{ useContext,useEffect,useState } from 'react';
import { WniDateUtil } from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';

const EMPTY_ARRAY = [];

const multiType = 'multi';
const renderDataTableFn = (FieldComponent) => {
    const Components = (props) => {
        const {
            data = EMPTY_ARRAY,
            className,
            rowIdPath,
            children = EMPTY_ARRAY,
            selectionType = 'none',
            onSelectionChange = _.noop,
            selectedRows,
            showSelectedAll = true
        } = props;
        const { breakpoint } = useBreakpoint({});
        const translator = useTranslator();
        const intl = useContext(IntlContext);
        const phoneUtil = PhoneUtil();
        const localeService = ServiceManager.getService('locale-service');

        const [selectionData, updateSelectionData] = useState(selectedRows || []);
        const [selectableData, updateSelectableData] = useState([]);
        const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

        useEffect(() => {
            const newData = _.cloneDeep(data);
            const initNewData = newData.filter((item) => !item.unselectable);
            updateSelectableData(initNewData);
        }, [data]);
        useEffect(() => {
            if(selectedRows) {
                updateSelectionData(selectedRows);
                onSelectionChange(selectedRows);
            }
        }, [onSelectionChange, selectedRows]);

        const defaultRenderCell = (item, index, property, renderCell) => {
            const { path, dataType, typeKey } = property;

            switch(dataType) {
                case 'currency':
                    const { amount, currency } = item[path] || {};
                    if(!amount && Number(amount) !== 0) {
                        return '-'
                    }
                    return intl.formatNumber(amount,
                        {
                            style: 'currency',
                            currency: currency,
                            currencyDisplay: 'symbol'
                        }
                    );
                case 'date':
                    return item[path] ? WniDateUtil.formatDateWithPattern(item[path]) : '-';
                case 'typelist':
                    if(path && _.isNil(item[path])) {
                        return '-';
                    }
                    return typeKey ? translator({id: `${typeKey}.${item[path]}`}) : item[path];
                case 'tel':
                    return item[path] ? phoneUtil.prettyPrint(item[path], localeService.getDefaultCountryCode()) : '-';
                default:
                    return renderCell(item, index, property);
            }
        };

        // ----------- selectionType is 'multi' started -----------
        const initRowId = (item, index) => {
            return rowIdPath ? item[rowIdPath] : index;
        };

        const onCheckboxForSingle = (isChecked, index) => {
            let newselectionData;
            if (isChecked) {
                newselectionData = selectionData.filter((item) => item !== index);
            } else {
                newselectionData = selectionData.concat([index]);
            }
            const selectedDataIds = data.map((item, i) => initRowId(item, i));
            const selectedData = _.intersection(selectedDataIds, newselectionData);
            updateSelectionData(selectedData);
            onSelectionChange(selectedData);
        };

        const onCheckboxForAll = (isChecked) => {
            let newselectionData;
            if(isChecked) {
                newselectionData = [];
            } else {
                newselectionData = selectableData.map((item, i) => initRowId(item, i));
            }
            updateSelectionData(newselectionData);
            onSelectionChange(newselectionData);
        };
    
        const renderSelectCell = (row, index) => {
            let checkboxProps = {};
            let showCheckboxField = true;
            // if row has value, it should be row renderCell, otherwise, it's table renderHeader
            if (row) { // row checkbox
                const isChecked = selectionData.some((item) => item === index);
                checkboxProps = {
                    value: isChecked,
                    onValueChange: () => onCheckboxForSingle(isChecked, index)
                }
                showCheckboxField = !row.unselectable
            } else { // all checkbox
                const allSelected = _.isEmpty(selectionData) ? false : selectableData.every((item, i) => selectionData
                    .some((rowId) => rowId === initRowId(item, i)));

                checkboxProps = {
                    value: allSelected,
                    onValueChange: () => onCheckboxForAll(allSelected),
                    disabled: selectableData.length === 0
                }
                showCheckboxField = showSelectedAll
            }
            if (!showCheckboxField) {
                return null
            };
            const dom = (
                <Button className="btn-text" onClick={(e) => e.stopPropagation()}>
                    <CheckboxField
                        id={index ? `checkbox${index}` : 'checkboxAll'}
                        className="d-inline-block"
                        {...checkboxProps}
                    />
                </Button>
            );
            return row ? WniTableRowUtil.renderCell(row.rowIdPath, dom) : dom;
        };
        
        const checkboxColumn = React.createElement(DisplayColumn, {
            width: 36,
            textAlign: 'left',
            renderHeader: renderSelectCell,
            renderCell: renderSelectCell,
            sortable: false,
            headerClassName: 'without-resizer'
        });
        // ----------- selectionType is 'multi' end -----------

        // if device is phone or phonewide, the UI should display as renderPhoneCell
        const renderPhoneCell = (item, index, property, renderCell) => {
            const { header } = property;
            return (
                <Grid columns={['1fr', '1fr']}>
                    <div className="font-uppercase font-NeutralUltraDark-bold-16">
                        {header && translator(header)}
                    </div>
                    <div className='align-right'>{renderCell(item, index, property)}</div>
                </Grid>
            );
        };

        const renderHeader = (header) => {
            return (
                <>
                    {header}
                    <span className='font-NeutralUltraDark-light-14'> (Optional)</span>
                </>
            );
        };

        const renderChildren = ()=> {
            let newChildren = _.isArray(children) ? [...children] : [children];
            if (selectionType === multiType) { // selectionType is 'multi', add checkbox into children
                newChildren = [checkboxColumn, ...children];
            }

            return newChildren.map((child) => {
                if (!child) {return;}
                const { props: itemChild } = child;
                const { renderCell = defaultRenderCell, showOptional } = itemChild;

                const renderColumnCell = (item, index, property) => defaultRenderCell(item, index, property, renderCell);

                let otherProps = {};
                if(showOptional) {
                    otherProps = {
                        renderHeader
                    }
                    
                }
                return {
                    ...child,
                    props: {
                        ...itemChild,
                        ...otherProps,
                        renderCell: !isPhone ? renderColumnCell : (item, index, property) => renderPhoneCell( item, index, property, renderColumnCell)
                    },
                };
            });
        };

        const componentProps = {
            ...props,
            selectionType: selectionType === multiType ? 'none' : selectionType,
            children: renderChildren()
        };
        return (
            <div className={`table-wrapper ${isPhone ? 'withPhone' : ''} ${className}`}>
                <FieldComponent {...componentProps} />
            </div>
        );
    };
    Components.propTypes = {
        labelPosition: PropTypes.string,
        className: PropTypes.string,
    };
    Components.defaultProps = {};
    return Components;
};

//  action
const WniDataTable = renderDataTableFn(DataTable)
export default WniDataTable;