import { Accordion, AccordionCard } from '@jutro/components';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from './GLMultiSublinesClausesDetailsAndStandardCoverages.messages';
import GLSingleSublineDetailsAndStandardCoveragesAccordionCard from './GLSingleSublineDetailsAndStandardCoveragesAccordionCard';

const GLMultiSublinesClausesStandardCoverages = (props) => {
    const {
        allSublinesClauses,
        setAllSubLinesClauses,
        submissionVM,
        updateWizardData,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        setUnmannedAircraftAvailable,
        loadingClauses,
        setLoadingClauses,
        visible = true,
        peReferredUWIssue
    } = props;

    const translator = useTranslator();

    const validationID = 'MultiSublinesStandardCoverages'

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate])

    if (!visible) {
        return null
    }

    return (
        <AccordionCard
            id="glSublineClausesStandardCoverages"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={translator(messages.standardCoveragesTitle)}
            errorState={showErrors && !isComponentValid}
        >
            <Accordion closeOthers={false} showFrame={false}>
                {allSublinesClauses.map((sublineClauses, index) => {
                    const setSublineClauses = (newSublineClauses) => {
                        const newAllSublinesClauses =
                            _.clone(allSublinesClauses);
                        newAllSublinesClauses[index] = newSublineClauses;
                        setAllSubLinesClauses(newAllSublinesClauses);
                    };
                    return (
                        <GLSingleSublineDetailsAndStandardCoveragesAccordionCard
                            sublineClauses={sublineClauses}
                            setSublineClauses={setSublineClauses}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            isEditable={isEditable}
                            peReferredUWIssue={peReferredUWIssue}
                            showErrors={showErrors}
                            wizardData={submissionVM}
                            updateWizardData={updateWizardData}
                            onValidate={onValidate}
                            setUnmannedAircraftAvailable={setUnmannedAircraftAvailable}
                            loadingClauses={loadingClauses}
                            setLoadingClauses={setLoadingClauses}
                        />
                    );
                })}
            </Accordion>
        </AccordionCard>
    );
};

export default GLMultiSublinesClausesStandardCoverages;
