import React from 'react';
import _ from 'lodash';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import {
    ErrorsAndWarningsUtil,
    ValidationIssueUtil,
    QuoteCoveragesUtil,
    QuoteUtil
} from 'wni-portals-util-js';
import { Button } from '@jutro/components';
import { DomRenderUtil } from 'wni-portals-util-react';

const {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = DomRenderUtil; 

// import customMessages from '../WALQuotePage.messages';


// const ISSUE_TYPE_TO_TITLE_MAP = {
//     info: customMessages.reviewWarningMessages,
//     warning: customMessages.reviewWarningMessages,
//     error: customMessages.submitTheApplication,
// };

// /**
//  * Get errors and warnings title
//  * @param {function} translator
//  * @param {function} issueToTitleFn (Optional)
//  * @returns {function} string the title for specific issue type
//  */
// function getQuotePageErrorsAndWarningsTitle(translator, issueToTitleFn) {
//     const issueToTitleGetter = (issueType) => {
//         let typeTitle;
//         if (_.isFunction(issueToTitleFn)) {
//             typeTitle = issueToTitleFn(issueType);
//         }
//         if (!typeTitle) {
//             typeTitle = ISSUE_TYPE_TO_TITLE_MAP[issueType];
//         }
//         return typeTitle || customMessages.reviewWarningMessages;
//     };

//     return (type, issues) => {
//         const title = issueToTitleGetter(type);

//         // return translator(title, {item: issues.length > 1 ? 'items': 'item'});
//         return translator(title);
//     };
// }

// function getQuotePageErrorsAndWarningsFooter({
//     wizardSteps, wizardStepToFieldMapping,
//     wizardJumpTo, resolveButtonLabel,
//     isServiceCallInProgress,
// }) {
//     return (type, issues) => {
//         if (type === 'error') {
//             //
//             const originalIssues = ValidationIssueUtil.flattenIssueChildren(issues);
//             const jumpFnToEarliestPage = ErrorsAndWarningsUtil.getJumpFnToEarliestPageWithIssue(
//                 originalIssues, wizardSteps, wizardStepToFieldMapping, wizardJumpTo
//             );
//             if (!jumpFnToEarliestPage) {
//                 return null;
//             }
//             // className: ph-10
//             return (
//                 <div className="d-flex flex-direction-row-reverse mt-10">
//                     <Button
//                         id="issueJumpBtn"
//                         onClick={jumpFnToEarliestPage}
//                         disabled={isServiceCallInProgress}
//                     >
//                         {resolveButtonLabel}
//                     </Button>
//                 </div>
//             );
//         }
//         return null;
//     };
// }

// function getQuotePageIssueRenderFn(issue, optConfigs) {
//     const {
//         issueKey,
//         listKey,
//         issueJumpFnMap,
//         defaultIssueRenderFn,
//         getIssueKeyFn,
//         getListKeyFn,
//         getSortKeyForIssueWithSameType,
//     } = optConfigs;
//     const { type, reason, children = [] } = issue;
//     if (type === 'error') {
//         return DomRenderUtil.issueRenderFn(issue, optConfigs);
//     }
//     // else for "info" and "warning"
//     let retval = null;
//     if (_.isEmpty(children)) {
//         retval = defaultIssueRenderFn(issue, {
//             issueKey,
//             listKey,
//             issueJumpFnMap,
//         });
//     } else {
//         let sortedChildren = children;
//         if (getSortKeyForIssueWithSameType) {
//             sortedChildren = _.sortBy(sortedChildren, getSortKeyForIssueWithSameType);
//         }
//         retval = (
//             <>
//                 <span>{reason}</span>
//                 <ul>
//                     {
//                         sortedChildren.map((item) => {
//                             const originalItem = { ...item, reason: item.originalReason };
//                             const currentIssueKey = getIssueKeyFn(originalItem);
//                             const currentListKey = getListKeyFn(item);

//                             const listContent = defaultIssueRenderFn(item, {
//                                 issueKey: currentIssueKey,
//                                 listKey: currentListKey,
//                                 issueJumpFnMap,
//                             });

//                             return (
//                                 <li key={currentListKey}>
//                                     {listContent}
//                                 </li>
//                             );
//                         })
//                     }
//                 </ul>
//             </>
//         );
//     }
//     return retval;
// }


export default {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
};