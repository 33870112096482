import { setComponentMapOverrides } from '@jutro/uiconfig';

import PUPolicyChangeStartPage from './pages/Start/PUPolicyChangeStartPage';

import PUPaySuccessChangePage from './pages/PaySuccess/PUPaySuccessChangePage';

import PUHouseholdInfoChangePage from './pages/HouseholdInfo/PUHouseholdInfoChangePage';
import PUHouseholdInfoChangeReadOnlyPage from './pages/HouseholdInfo/PUHouseholdInfoChangePage.readonly';

import PUUnderlyingChangePage from './pages/Underlying/PUUnderlyingChangePage';
import PUUnderlyingChangeReadOnlyPage from './pages/Underlying/PUUnderlyingChangePage.readonly';

import PUCoveragesChangePage from './pages/Coverages/PUCoveragesChangePage';
import PUCoveragesChangeReadOnlyPage from './pages/Coverages/PUCoveragesChangePage.readonly';

import PULossHistoryChangePage from './pages/LossHistory/PULossHistoryChangePage';
import PULossHistoryChangeReadOnlyPage from './pages/LossHistory/PULossHistoryChangePage.readonly';


// WIZARD PAGE IMPORT ANCHOR
import PUQuoteChangePage from './pages/Quote/PUQuoteChangePage';
import PUQuoteChangeReadOnlyPage from './pages/Quote/PUQuoteChangePage.readonly';

setComponentMapOverrides(
    {
        PUPolicyChangeStartPage: {component: 'PUPolicyChangeStartPage'},
        PUPaySuccessChangePage: { component: 'PUPaySuccessChangePage' },

        PUHouseholdInfoChangePage: { component: 'PUHouseholdInfoChangePage'},
        PUHouseholdInfoChangeReadOnlyPage: { component: 'PUHouseholdInfoChangeReadOnlyPage'},

        PUUnderlyingChangePage: { component: 'PUUnderlyingChangePage'},
        PUUnderlyingChangeReadOnlyPage: { component: 'PUUnderlyingChangeReadOnlyPage'},

        PUCoveragesChangePage: { component: 'PUCoveragesChangePage'},
        PUCoveragesChangeReadOnlyPage: { component: 'PUCoveragesChangeReadOnlyPage'},

        PULossHistoryChangePage: { component: 'PULossHistoryChangePage'},
        PULossHistoryChangeReadOnlyPage: { component: 'PULossHistoryChangeReadOnlyPage'},


// WIZARD PAGE COMPONENT NAME ANCHOR
        PUQuoteChangePage: { component: 'PUQuoteChangePage' },
        PUQuoteChangeReadOnlyPage: { component: 'PUQuoteChangeReadOnlyPage' },
    },
    {
        PUPolicyChangeStartPage,
        PUPaySuccessChangePage,

        PUHouseholdInfoChangePage,
        PUHouseholdInfoChangeReadOnlyPage,

        PUUnderlyingChangePage,
        PUUnderlyingChangeReadOnlyPage,

        PUCoveragesChangePage,
        PUCoveragesChangeReadOnlyPage,

        PULossHistoryChangePage,
        PULossHistoryChangeReadOnlyPage,


// WIZARD PAGE COMPONENT MAP ANCHOR
        PUQuoteChangePage,
        PUQuoteChangeReadOnlyPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PUPolicyChangeWizard } from './PUPolicyChangeWizard';