
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WNIAddressLookupService } from 'wni-capability-address';
import LookupAddressComponent from '../AddressFromService/LookupAddressComponent';
import LookupZipcodeComponent from '../AddressFromService/LookupZipcodeComponent';
import metadata from './AddressInputComponent.metadata.json5';
import messages from './AddressInputComponent.messages';
import styles from './AddressInputComponent.module.scss';

const defaultHideField = {
    country: false,
    addressType: false,
    addressLine1: false,
    addressLine2: false,
    addressLine3: true,
    pobox: false,
    postalCode: false,
    county: false,
    city: false,
    state: false,
    description: true
};
const defaultUnReuqired = {
    country: false,
    addressType: false,
    addressLine1: false,
    addressLine2: true,
    addressLine3: true,
    pobox: true,
    postalCode: false,
    county: false,
    city: false,
    state: false,
    description: true
};
const defaultUnAutoFilled = {
    addressLine1: false,
    postalCode: false
};
const AddressInputComponent = (props) => {
    const {
        model,
        dataPath,
        onAddressChange,
        onBlur = _.noop,
        hideFieldType,
        unRequiredField,
        showErrors,
        readOnly,
        hideRequiredforQuote,
        unAutoFilled,
        availableStates,
        onValidate,
        defaultCountryRequired,
        useAuthenticationData,
        useDependenciesData,
        viewModelService,
        isCLine,
        className
    } = props;

    const hideFieldTypesMap = { ...defaultHideField, ...hideFieldType };
    const unRequiredFieldsMap = { ...defaultUnReuqired, ...unRequiredField };
    const unAutoFilledsMap = { ...defaultUnAutoFilled, ...unAutoFilled };
    const addressDtoVM = _.get(model, dataPath);
    const defaultMask = 'PO Box ##########';
    const [poboxMask, setPoboxMask] = useState('');
    const [poboxInValid, updatePoboxInValid] = useState(false);
    const [showLoader, updateShowLoader] = useState(true);
    const [unitValues, updateUnitValues] = useState([]);
    const [unitValue, updateUnitValue] = useState('');
    const [countryValue, updateCountryValue] = useState(_.get(model.value, `${dataPath}.country`));
    const getRegValue = (value) => {
        if (!value) {
            return '';
        }
        return _.toLower(value).split(/po box |#/)[1];
    };

    const getInputMaskUpdate = (reg) => {
        updatePoboxInValid(true);
        setPoboxMask(reg);
    };

    useEffect(() => {
        if (defaultCountryRequired && !readOnly) {
            const defaultAddress = {...addressDtoVM.value, country: 'US'}
            onAddressChange(defaultAddress, dataPath);
        }

        const defaultPobox = _.get(addressDtoVM.value, 'pobox_Ext');
        const changeMask = getRegValue(defaultPobox);
        if (changeMask) {
            onAddressChange(`PO Box ${changeMask}`, `${dataPath}.pobox_Ext`);
            getInputMaskUpdate(`PO Box ${changeMask.replace(/[0-9]/g, '#')}`);
        }
    }, []);

    const translator = useTranslator();

    const writeValue = (value, path) => {
        onAddressChange(value, `${dataPath}.${path}`);
    };

    const poboxChangeFn = (value, path) => {
        if (value === null && poboxInValid) {
            return false;
        }
        const poboxValue = getRegValue(value);
        updatePoboxInValid(false);

        if (poboxValue) {
            const address = {
                ...addressDtoVM.value, addressLine1: '', pobox_Ext: `PO Box ${poboxValue}`
            };
            onAddressChange(address, dataPath);
            return false;
        }
        onAddressChange('', `${dataPath}.${path}`);
        return true;
    };
    const getCountyLabel = () => {
        const stateVal = _.get(addressDtoVM.value, 'state');
        if (stateVal === 'AK') {
            return translator(messages.boroughs);
        }
        return translator(messages.county);
    };

    function resetCityCountyState(addressDTOValue) {
        _.unset(addressDTOValue, 'city');
        _.unset(addressDTOValue, 'county');
        _.unset(addressDTOValue, 'state');
        _.unset(addressDTOValue, 'countySelection_Ext');
        _.unset(addressDTOValue, 'citySelection_Ext');
    }

    const onSelectionFn = async (item) => {
        resetCityCountyState(addressDtoVM.value);
        
        const address = {
            ...addressDtoVM.value,
            ...item,
            addressLine2: '',
        };
        onAddressChange(address, dataPath);
        updateUnitValues(item.unitNumbers_Ext);
        // choose addressline1, clear the value of unit and addressline2
        updateUnitValue('');
    };

    const lookupZipcode = (item) => {
        resetCityCountyState(addressDtoVM.value);
        const address = {
            ...addressDtoVM.value, ...item
        };
        onAddressChange(address, dataPath);
    };
    const getPlaceholder = (path) => {
        if (hideRequiredforQuote) {
            return '';
        }
        return !unRequiredFieldsMap[path] ? '-- Required for Quote --' : '';
    };

    const getCountryValues = useCallback(() => {
        const availableCountries = _.get(addressDtoVM, 'country.aspects.availableValues');
        let filteredCountries = availableCountries;
        if (isCLine) {
            filteredCountries = availableCountries.filter(country => country.code === 'US' || country.code === 'CA');
        }
        const finalAvailableCountries = filteredCountries.map((country) => {
            return {
                code: country.code,
                name: translator({ id: country.name})
            }
        })
        return finalAvailableCountries
    }, [addressDtoVM, isCLine, translator])
    const generateUnitValues = () => {
        const availableValues = unitValues ? unitValues.map((item) => {
            return {
                code: item,
                name: item
            };
        }) : [];
        return availableValues;
    };

    const countyAndCityOption = (type) => {
        let countyAndCityValues = [];
        if (type === 'county' && !_.isNil(_.get(addressDtoVM.value, 'countySelection_Ext'))) {
            const countyValues = _.get(
                addressDtoVM.value,
                'countySelection_Ext'
            );
            countyAndCityValues = countyValues.map((county) => {
                return {
                    code: county,
                    name: county,
                };
            });
        }
        if (type === 'city' && !_.isNil(_.get(addressDtoVM.value, 'citySelection_Ext'))) {
            const cityValues = _.get(
                addressDtoVM.value,
                'citySelection_Ext'
            );
            countyAndCityValues = cityValues.map((county) => {
                return {
                    code: county,
                    name: county,
                };
            });
        }
        return countyAndCityValues;
    };

    // countyAndCityOption

    const countryValueChangeFn = (val) => {
        updateCountryValue(val);
        onAddressChange(val,  `${dataPath}.country`);
    }

    const unitValueChange = (val) => {
        updateUnitValue(val);
        onAddressChange(val, `${dataPath}.addressLine2`);
    };

    const getAddressLine1Visible = () => {
        const { addressLine1, pobox } = hideFieldTypesMap;
        if (addressLine1) {
            return false;
        }
        return pobox || !_.get(addressDtoVM.value, 'pobox_Ext');
    };

    const getAvailableStates = () => {
        const retval = {};
        if (availableStates) {
            retval.availableValues = availableStates;
        }
        return retval;
    };
    //-------------------------------------------
    const overrideProps = {
        '@all': {
            readOnly
        },
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            readOnly,
            className,
            onBlur
        },
        country: {
            visible: !hideFieldTypesMap.country,
            required: !unRequiredFieldsMap.country,
            placeholder: getPlaceholder('country'),
            onValueChange: countryValueChangeFn,
            availableValues: !hideFieldTypesMap.country? getCountryValues(): undefined
        },
        pobox: {
            mask: poboxMask,
            visible: !hideFieldTypesMap.pobox,
            required: !unRequiredFieldsMap.pobox,
            onValueChange: poboxChangeFn,
            onFocus: () => {
                getInputMaskUpdate(defaultMask);
            },
            onBlur: () => {
                updatePoboxInValid(true);
                const poboxNum = getRegValue(_.get(addressDtoVM.value, 'pobox_Ext'));
                if (!poboxNum) {
                    getInputMaskUpdate('');
                    return false;
                }
                getInputMaskUpdate(`PO Box ${poboxNum.replace(/[0-9]/g, '#')}`);
                return true;
            }
        },
        addressType: {
            visible: !hideFieldTypesMap.addressType,
            required: !unRequiredFieldsMap.addressType,
            placeholder: getPlaceholder('addressType')
        },
        lookupaddress: {
            visible: getAddressLine1Visible(),
            required: !unRequiredFieldsMap.addressLine1,
            placeholder: getPlaceholder('addressLine1'),
            path: 'addressLine1',
            value: addressDtoVM.value ? _.get(addressDtoVM.value, 'addressLine1') : _.get(addressDtoVM, 'addressLine1'),
            onSelection: onSelectionFn,
            writeValue,
            showErrors,
            autoFilled: !unAutoFilledsMap.addressLine1,
            useAuthenticationData,
            useDependenciesData,
            viewModelService
        },
        addressLine2: {
            visible: !hideFieldTypesMap.addressLine2,
            required: !unRequiredFieldsMap.addressLine2,
            placeholder: getPlaceholder('addressLine2')
        },
        addressLine3: {
            visible: !hideFieldTypesMap.addressLine3,
            required: !unRequiredFieldsMap.addressLine3,
            placeholder: getPlaceholder('addressLine3')
        },
        unit: {
            availableValues: generateUnitValues(),
            visible: !!((unitValues && unitValues.length > 0)),
            placeholder: '',
            onValueChange: unitValueChange,
            value: unitValue
        },
        postalCode: {
            visible: !hideFieldTypesMap.postalCode,
            required: !unRequiredFieldsMap.postalCode,
            placeholder: getPlaceholder('postalCode'),
            onSelection: lookupZipcode,
            value:  addressDtoVM.value ? _.get(addressDtoVM.value, 'postalCode') :_.get(addressDtoVM, 'postalCode'),
            writeValue,
            showErrors,
            updateShowLoader,
            autoFilled: !unAutoFilledsMap.postalCode,
            useAuthenticationData,
            useDependenciesData,
            viewModelService,
            label: messages[`${countryValue}PostalCode`] || messages.PostalCode,
            chosenCountry: countryValue
        },
        county: {
            visible: !hideFieldTypesMap.county && _.isNil(_.get(addressDtoVM.value, 'countySelection_Ext')) && !(isCLine && countryValue === 'CA'),
            required: !unRequiredFieldsMap.county,
            placeholder: getPlaceholder('county'),
            label: getCountyLabel(),
        },
        multiCounty: {
            visible: !hideFieldTypesMap.county && !_.isNil(_.get(addressDtoVM.value, 'countySelection_Ext')),
            availableValues: countyAndCityOption('county'),
            required: !unRequiredFieldsMap.county,
            placeholder: getPlaceholder('county'),
            label: getCountyLabel(),
        },
        city: {
            visible: !hideFieldTypesMap.city && _.isNil(_.get(addressDtoVM.value, 'citySelection_Ext')),
            required: !unRequiredFieldsMap.city,
            placeholder: getPlaceholder('city'),
            label: messages[`${countryValue}City`] || messages.City
        },
        multiCity: {
            visible: !hideFieldTypesMap.city && !_.isNil(_.get(addressDtoVM.value, 'citySelection_Ext')),
            required: !unRequiredFieldsMap.city,
            availableValues: countyAndCityOption('city'),
            placeholder: getPlaceholder('city')
        },
        description: {
            visible: !hideFieldTypesMap.description,
            required: !unRequiredFieldsMap.description,
            placeholder: getPlaceholder('description')
        },
        state: {
            visible: !hideFieldTypesMap.state,
            required: !unRequiredFieldsMap.state,
            placeholder: getPlaceholder('state'),
            label: messages[`${countryValue}State`] || messages.State,
            ...getAvailableStates()
        },
        autoFilledContainer: {
            visible: showLoader
        },
        serviceLoader: {
            loaded: showLoader
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            addressDtoVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            lookupaddress: LookupAddressComponent,
            lookupzipcode: LookupZipcodeComponent,
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={addressDtoVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
};

AddressInputComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    dataPath: PropTypes.string.isRequired,
    onAddressChange: PropTypes.func,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    hideFieldType: PropTypes.shape({
        country: PropTypes.bool,
        addressType: PropTypes.bool,
        addressLine1: PropTypes.bool,
        addressLine2: PropTypes.bool,
        pobox: PropTypes.bool,
        postalCode: PropTypes.bool,
        county: PropTypes.bool,
        city: PropTypes.bool,
        state: PropTypes.bool
    }),
    unRequiredField: PropTypes.shape({
        country: PropTypes.bool,
        addressType: PropTypes.bool,
        addressLine1: PropTypes.bool,
        addressLine2: PropTypes.bool,
        pobox: PropTypes.bool,
        postalCode: PropTypes.bool,
        county: PropTypes.bool,
        city: PropTypes.bool,
        state: PropTypes.bool
    }),
    readOnly: PropTypes.bool,
    hideRequiredforQuote: PropTypes.bool,
    unAutoFilled: PropTypes.shape({
        addressLine1: PropTypes.bool,
        postalCode: PropTypes.bool
    }),
    availableStates: PropTypes.arrayOf(PropTypes.shape({})),
    defaultCountryRequired: PropTypes.bool,
    isCLine: PropTypes.bool
};

AddressInputComponent.defaultProps = {
    model: {},
    onAddressChange: undefined,
    showErrors: false,
    readOnly: false,
    onValidate: _.noop,
    hideFieldType: {},
    unRequiredField: {},
    hideRequiredforQuote: false,
    unAutoFilled: {},
    availableStates: undefined,
    defaultCountryRequired: true,
    isCLine: false
};

export default AddressInputComponent;
