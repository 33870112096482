import React, { useState } from 'react'
import _ from 'lodash'
import { Accordion, AccordionCard } from '@jutro/components'
import PoliciesClausesArray from '../PoliciesSummaryClausesArray'

const CA7VehicleCoverablePrefix = 'CA7Vehicle_'

const CA7CommAutoCoverage = (props) => {

    const {
        caCoverages,
        coverableDisplayNameMap,
    } = props

    const [accordionStates, setAccordionStates] = useState([
        'coverages',
        'additionalCoverages',
        'exclusionsAndConditions',
        'additionalInsured'
    ])

    const {
        lineCoverages = [],
        lineAdditionalCoverages = [],
        lineExclusions = [],
        lineConditions = [],
        lineAdditionalInsureds = [],
        stateSpecificCoverages = [],
        vehiclesCoverages = [],
    } = caCoverages

    const commonAccordionCardProps = {
        chevron: true,
        className: 'accordion',
        cardBodyClassName: 'accordionCardBody',
        cardHeadingClassName: 'accordionCardHeader',
    }

    return <Accordion
        accordionStates={accordionStates}
        onUpdateAccordionStates= {(ids) => setAccordionStates(ids)}
        closeOthers={false}
    >
        <AccordionCard
            id='coverages'
            title = 'Coverages'
            {...commonAccordionCardProps}
        >
            <PoliciesClausesArray clauses={lineCoverages} />
            <Accordion>
                {stateSpecificCoverages.map((stateSpecificCoveragesDTO) => {
                    const {
                        state,
                        stateStandardCoverages,
                        hiredAutoStandardCoverages,
                        nonOwnedAutoStandardCoverages,
                    } = stateSpecificCoveragesDTO
                    return <AccordionCard
                        key={`${state}_coverages`}
                        id={`${state}_coverages`}
                        title = {state}
                        {...commonAccordionCardProps}
                    >
                        <PoliciesClausesArray clauses={stateStandardCoverages} />
                        <PoliciesClausesArray clauses={hiredAutoStandardCoverages} />
                        <PoliciesClausesArray clauses={nonOwnedAutoStandardCoverages} />
                    </AccordionCard>
                })}
                {vehiclesCoverages.map((vehicleCoveragesDTO) => {
                    const {
                        owningCoverablePublicID,
                        primaryCoverages
                    } = vehicleCoveragesDTO
                    return <AccordionCard
                        key={`${owningCoverablePublicID}_primarycoverages`}
                        id={`${owningCoverablePublicID}_primarycoverages`}
                        title = {_.get(coverableDisplayNameMap, `${CA7VehicleCoverablePrefix}${owningCoverablePublicID}`)}
                        {...commonAccordionCardProps}
                    >
                        <PoliciesClausesArray clauses={primaryCoverages} />
                    </AccordionCard>
                })}
            </Accordion>
        </AccordionCard>
        <AccordionCard
            id='additionalCoverages'
            title = 'Additional Coverages'
            {...commonAccordionCardProps}
        >
            <PoliciesClausesArray clauses={lineAdditionalCoverages} />
            <Accordion>
                {stateSpecificCoverages.map((stateSpecificCoveragesDTO) => {
                    const {
                        state,
                        stateAdditionalCoverages,
                        hiredAutoAdditionalCoverages,
                        nonOwnedAutoAdditionalCoverages,
                    } = stateSpecificCoveragesDTO
                    return <AccordionCard
                        key={`${state}_additionalCoverages`}
                        id={`${state}_additionalCoverages`}
                        title = {state}
                        {...commonAccordionCardProps}
                    >
                        <PoliciesClausesArray clauses={stateAdditionalCoverages} />
                        <PoliciesClausesArray clauses={hiredAutoAdditionalCoverages} />
                        <PoliciesClausesArray clauses={nonOwnedAutoAdditionalCoverages} />
                    </AccordionCard>
                })}
                {vehiclesCoverages.map((vehicleCoveragesDTO) => {
                    const {
                        owningCoverablePublicID,
                        additionalCoverages
                    } = vehicleCoveragesDTO
                    return <AccordionCard
                        key={`${owningCoverablePublicID}_additionalCoverages`}
                        id={`${owningCoverablePublicID}_additionalCoverages`}
                        title = {_.get(coverableDisplayNameMap, `${CA7VehicleCoverablePrefix}${owningCoverablePublicID}`)}
                        {...commonAccordionCardProps}
                    >
                        <PoliciesClausesArray clauses={additionalCoverages} />
                    </AccordionCard>
                })}
            </Accordion>
        </AccordionCard>
        <AccordionCard
            id='exclusionsAndConditions'
            title = 'Exclusions and Conditions'
            {...commonAccordionCardProps}
        >
            <PoliciesClausesArray clauses={lineExclusions} />
            <PoliciesClausesArray clauses={lineConditions} />
            <Accordion>
                {stateSpecificCoverages.map((stateSpecificCoveragesDTO) => {
                    const {
                        state,
                        stateExclusions,
                        stateConditions,
                        hiredAutoExclusions,
                        hiredAutoConditions,
                        nonOwnedAutoExclusions,
                        nonOwnedAutoConditions,
                    } = stateSpecificCoveragesDTO
                    return <AccordionCard
                        key={`${state}_exclusionsAndConditions`}
                        id={`${state}_exclusionsAndConditions`}
                        title = {state}
                        {...commonAccordionCardProps}
                    >
                        <PoliciesClausesArray clauses={stateExclusions} />
                        <PoliciesClausesArray clauses={stateConditions} />
                        <PoliciesClausesArray clauses={hiredAutoExclusions} />
                        <PoliciesClausesArray clauses={hiredAutoConditions} />
                        <PoliciesClausesArray clauses={nonOwnedAutoExclusions} />
                        <PoliciesClausesArray clauses={nonOwnedAutoConditions} />
                    </AccordionCard>
                })}
                {vehiclesCoverages.map((vehicleCoveragesDTO) => {
                    const {
                        owningCoverablePublicID,
                        exclusions,
                        conditions
                    } = vehicleCoveragesDTO
                    return <AccordionCard
                        key={`${owningCoverablePublicID}_exclusionsAndConditions`}
                        id={`${owningCoverablePublicID}_exclusionsAndConditions`}
                        title = {_.get(coverableDisplayNameMap, `${CA7VehicleCoverablePrefix}${owningCoverablePublicID}`)}
                        {...commonAccordionCardProps}
                    >
                        <PoliciesClausesArray clauses={exclusions} />
                        <PoliciesClausesArray clauses={conditions} />
                    </AccordionCard>
                })}
            </Accordion>
        </AccordionCard>
        <AccordionCard
            id='additionalInsured'
            title = 'Additional Insured'
            {...commonAccordionCardProps}
        >
            <PoliciesClausesArray clauses={lineAdditionalInsureds} />
        </AccordionCard>
        <AccordionCard
            id='additionalInterest'
            title = 'Additional Interest'
            {...commonAccordionCardProps}
        >
            <Accordion>
                {vehiclesCoverages.map((vehicleCoveragesDTO) => {
                    const {
                        owningCoverablePublicID,
                        additionalInterestCoverages
                    } = vehicleCoveragesDTO
                    return <AccordionCard
                        key={`${owningCoverablePublicID}_additionalInterest`}
                        id={`${owningCoverablePublicID}_additionalInterest`}
                        title = {_.get(coverableDisplayNameMap, `${CA7VehicleCoverablePrefix}${owningCoverablePublicID}`)}
                        {...commonAccordionCardProps}
                    >
                        <PoliciesClausesArray clauses={additionalInterestCoverages} />
                    </AccordionCard>
                })}
            </Accordion>
        </AccordionCard>
    </Accordion>
}

export default CA7CommAutoCoverage