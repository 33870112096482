
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { useTranslator, IntlContext } from '@jutro/locale';
import { Link } from '@jutro/components';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { QuoteCoveragesUtil, WniClausesUtil, WindowUtil } from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';

import { BaseRecommendCoveragesComponent } from 'wni-capability-gateway-react';


function WALRecommendCoveragesComponent(props) {

    const {
        goToCoveragesPage,
    } = props;

    const getCovJumpFn = useCallback((idPrefix, coveragePublicID) => {
        // See SingleClauseComponentVM.generateMetadata()
        return () => {
            goToCoveragesPage();
        };
    }, [goToCoveragesPage]);


    return (
        <BaseRecommendCoveragesComponent
            {...props}
            getCovJumpFn={getCovJumpFn}
        />
    );
}

WALRecommendCoveragesComponent.defaultProps = {
    goToCoveragesPage: PropTypes.func.isRequired,
    //
    ...BaseRecommendCoveragesComponent.defaultProps,
}

WALRecommendCoveragesComponent.defaultProps = {
    ...BaseRecommendCoveragesComponent.defaultProps,
}

export default WALRecommendCoveragesComponent;
