import React from 'react'
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Loader,
} from '@jutro/components';

const PAVehicleCoveragesPopup = (props) => {
    const {
        title,
        size,
        isOpen,
        onResolve,
    } = props;

    return <ModalNext
        isOpen={isOpen}
        className={size}
    >
        <ModalHeader title={title} />
        <ModalBody id="paVehicleCoveragesPopup">
            <div>123</div>
        </ModalBody>
        <ModalFooter contentLayout={{
            component: 'flex',
            componentProps: {
                alignItems: "middle",
                justifyContent: "between",
                wrap: 'reverse',
                gap: 'medium'
            }
        }}>
            <Button
                onClick={() => onResolve()}
                className="wni-button-link"
            >Exit</Button>
        </ModalFooter>
    </ModalNext>
}

PAVehicleCoveragesPopup.defaultProps = {
    size: 'md'
}

export default PAVehicleCoveragesPopup
