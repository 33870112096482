import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
// import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { CPPPolicyDetailsChangeService } from 'wni-capability-policychange-cpp';
import {
    ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
    WniPNIUtil,
    // WniProductsUtil,
    WniDateUtil,
    WizardPageJumpUtil,
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    MailingAddressInputPopupComponent
} from 'wni-capability-gateway-react';
import moment from 'moment';
import { WizardConstants } from 'wni-portals-config-js';
import { useTranslator } from '@jutro/locale';
import {CPPWizardPage as WizardPage} from 'wni-capability-quoteandbind-cpp-react';
import { DropdownMenuLink, DropdownMenuHeader } from '@jutro/components';
import metadata from './CPPPolicyDetailsChangePage.metadata.json5';


function CPPPolicyDetailsChangePage(props) {
    const translator = useTranslator();
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        wizardSnapshot,
        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        //
        // clearVisitedStepsFromQuotePage,
        markFollowingWizardStepsUnvisited,
        isReadOnly = false,
    } = props;

    const {
        [WizardConstants.landingPage]: landingPage,
        // [WizardConstants.policyChangeSource]: policyChangeSource,
        [WizardConstants.policyChangeNewVersionAdded]: newVersionAdded,
    } = wizardPageData;

    const {
        jobID: quoteID, 
        sessionUUID,
        // policyInfoSource_Ext: policyInfoSource,
        baseData,
        changeData: {
            maximumEffectiveDate
        }
    } = submissionVM.value;

    const {
        
        // baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        // quoteFlow_Ext: quoteFlow,
        policyAddressCandidates_Ext: policyAddressCandidates
        // productCode
    } = baseData;

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CPPPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    // const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    
    const writeValue = useCallback((value, path) => {
        const newVM = viewModelService.clone(submissionVM);
        _.set(newVM, path, value);
        updateSubmissionVM(newVM);
    }, [viewModelService, submissionVM, updateSubmissionVM]);


    const showMailingAddressPopupModal = useCallback(() => {
            const componentProps = {
                title: 'Add New Address',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                viewModelService,
                showEmployeeFlagToggle: false,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                onValueChange: writeValue,
            };
            return modalApi.showModal(
                <MailingAddressInputPopupComponent {...componentProps} />
            );
        }, [viewModelService, authHeader, sessionUUID, quoteID, writeValue, modalApi]
    );

    const openAddMailingAddressPopup = useCallback(() => {
        showMailingAddressPopupModal()
            .then((newAddress) => {
                const newSubmissionVM = _.clone(submissionVM);
                const originPolicyAddress = _.clone(_.get(submissionVM, 'baseData.policyAddress'))
                const newCandidates = policyAddressCandidates.concat(originPolicyAddress.value)
                _.set(newSubmissionVM.value, 'baseData.policyAddress', newAddress.value);
                _.set(newSubmissionVM.value, 'baseData.policyAddressCandidates_Ext', newCandidates)

                updateSubmissionVM(newSubmissionVM);
            }).catch(() => _.noop());
    },[showMailingAddressPopupModal, submissionVM, updateSubmissionVM, policyAddressCandidates]);


    const onExistingAddressClick = (elt) => {
        const newVM = viewModelService.clone(submissionVM)
        _.set(newVM, 'baseData.policyAddress', elt)
        updateSubmissionVM(newVM)
    }

    const getMailingAddressDataAndDisplay = useCallback(() => {

        const policyAddress= _.get(submissionVM.value, 'baseData.policyAddress.displayName')
        return (
                <div>{policyAddress}</div>
        );
    }, [submissionVM.value]);



    const getAvailableValues = (path) => {
        const options = _.get(submissionVM.baseData.accountHolder,  `${path}.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        })
    };

    const initialEffectiveDate = _.get(wizardSnapshot.value, 'baseData.effectiveDate_Ext')
    const getOOSPeriods = useCallback(() => {
        const oOSSliceDates = _.get(submissionVM.value, 'changeData.oossliceDates_Ext');
            let oOSStartDate = _.get(wizardSnapshot.value, 'baseData.periodStartDate')
            let oOSEndDate = _.get(wizardSnapshot.value, 'baseData.periodEndDate')
            
            const oOSSliceDatesInMoment = oOSSliceDates.map((res) => {
                return moment(res)
            })
            const sortOOSSliceDatesInMoment = oOSSliceDatesInMoment.sort((a, b) => a.diff(b))
            const oOSEndDateIndex = sortOOSSliceDatesInMoment.findIndex((res) => res.isAfter(moment(initialEffectiveDate)))
            if (oOSEndDateIndex !==-1 ) { 
                oOSEndDate = sortOOSSliceDatesInMoment[oOSEndDateIndex] 
                if (oOSEndDateIndex > 0 ) { 
                oOSStartDate = sortOOSSliceDatesInMoment[oOSEndDateIndex-1]
                }
            }
            if (oOSEndDateIndex ===-1 && (sortOOSSliceDatesInMoment.length>0 )) {
                oOSStartDate = sortOOSSliceDatesInMoment[sortOOSSliceDatesInMoment.length-1]
            }
            return {oOSStartDate, oOSEndDate}
    }, [initialEffectiveDate, submissionVM.value, wizardSnapshot.value])

    
    const effectiveMinDate = useCallback(() => {
        const {oOSStartDate} = getOOSPeriods()
        return WniDateUtil.getDateObj(oOSStartDate)
    }, [getOOSPeriods])

    const effectiveMaxDate = useCallback(() => {
        const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        const maxDate =  ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
                maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
        return maxDate
    }, [submissionVM, maximumEffectiveDate])

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = effectiveMinDate();
        const maxDate = effectiveMaxDate();
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();
        const maxDif = moment(maxDate).valueOf() - moment(baseEffectiveDate).valueOf();
        
        return ((minDif >= 0) && (maxDif >= 0))
    }, [effectiveMaxDate, submissionVM]);
    
    
    const CPPPolicyDetailsValidation = useCallback(() => {
        return WniPNIUtil.isPNIValid(primaryNamedInsured) && isEffectiveDateValid()

    }, [isEffectiveDateValid, primaryNamedInsured]);
    
    // =======================================================================
    const pageOnInit = useCallback(() => {
        if (landingPage) {
            // gray out all following pages when first enter
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, 'changeData.description', '');
            updateSubmissionVM(newSubmissionVM);
            updateWizardPageData({ [WizardConstants.landingPage]: undefined });
            if (landingPage !== 'CPPPolicyDetailsPage') {
                const jumpToLandingPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, landingPage);
                jumpToLandingPage();
            }
        } else if (newVersionAdded) {
            updateWizardPageData({ [WizardConstants.policyChangeNewVersionAdded]: undefined });
            // clearVisitedStepsFromQuotePage();
            markFollowingWizardStepsUnvisited();
            //
            setLoadingMask(false);
        }
    }, []);
    // =======================================================================

    useEffect(() => {
        pageOnInit();
    }, []);


    useEffect(() => {
        registerComponentValidation(CPPPolicyDetailsValidation);
    }, [registerComponentValidation, CPPPolicyDetailsValidation, primaryNamedInsured]);


    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },[updateShowErrors, invalidFields]
    );


    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const quoteBaseData = _.get(submissionVM.value, 'baseData')
        
        const res = await CPPPolicyDetailsChangeService.saveCPPDetailsChangeData(
            quoteID, 
            sessionUUID,
            quoteBaseData,
            authHeader);
        setLoadingMask(false);
        
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }

        submissionVM.value = res;
        updateSubmissionVM(submissionVM);

        return submissionVM;
    }, [setLoadingMask, submissionVM, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);

    const generateMailingAddress = useCallback(() => {
        const retval = policyAddressCandidates?.map((address) => (
            <DropdownMenuLink
                key={address.publicID}
                // disabled={!product.available}
                onClick={() => onExistingAddressClick(address)}
            >
                {address.displayName}
            </DropdownMenuLink>
        ));
        return <>
            <DropdownMenuLink
                key='new'
                onClick = {openAddMailingAddressPopup}
            >Add New Address 
            </DropdownMenuLink>
            <DropdownMenuHeader title='Existing Address' />
            {retval}
            </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAddMailingAddressPopup, policyAddressCandidates, submissionVM])
    
    const addressMenuContent = generateMailingAddress()

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true,
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                // onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true,
                    licensedAgentReadOnly: true,
                    servicingAgentReadOnly: true
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate
            },
            mailingAddress: {
                value: getMailingAddressDataAndDisplay(),
            },
            mailingAddressEdit: {
                content: addressMenuContent,
                visible: !isReadOnly
            },
            organizationTypeMultiSelect: {
                availableValues: getAvailableValues('organizationTypesOption_Ext')
            },
            effectiveDate: {
                minDate: effectiveMinDate(),
                maxDate: effectiveMaxDate(),
                showErrors,
                disabled: isReadOnly
            },
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressMenuContent, getMailingAddressDataAndDisplay, isReadOnly, onValidate, submissionVM.baseData]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CPPPolicyDetailsChangePage.propTypes = {
    ...WizardPage.propTypes,
    policyDetailsService: PropTypes.object,
    savePolicyDetailsData: PropTypes.func,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
};

CPPPolicyDetailsChangePage.defaultProps = {
    ...WizardPage.defaultProps,
    // policyDetailsService: CPPPolicyDetailsService,
    // savePolicyDetailsData: CPPPolicyDetailsService.savePolicyDetailsData,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
}
export default CPPPolicyDetailsChangePage;