import React, {
    useState
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { InputField } from '@jutro/components';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import WizardPage from '../../templates/GLWizardPage';
import GLModifiersConfig from './GLModifiersPage.config';
import messages from './GLModifiersPage.messages';

function GLModifiersReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
    } = props;

    const translator = useTranslator();
    const {
        lobData: {
            generalLiability: {
                modifiers_Ext : modifiers
            }
        },
    } = submissionVM.value;
    const {
        agentStateTotalRangeMap
    } = GLModifiersConfig;
    const { authUserData } = useAuthentication();
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    // currently, only focus on AK, this story will be revisited, then follow up other states
    const modifierAK = modifiers.find(modifier => modifier.jurisdiction === 'Alaska');
    const overallMinAK = isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.minimum`) : modifierAK.overallMinimum;
    const overallMaxAK = isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.maximum`) : modifierAK.overallMaximum;
    
    const allStateTotalCreditDebit = modifiers.map(modifier => {
        return {state: modifier.jurisdiction, overallCreditDebit: modifier.rateFactors.reduce((acc, currentFactor) => acc + currentFactor.creditDebit, 0)}
    });
    const stateOverallMinMax = modifiers.map(modifier => {
        return {state: modifier.jurisdiction, overallMin: modifier.overallMinimum, overallMax: modifier.overallMaximum}
    })

    const defaultValidationInfo = _.isNil(modifierAK) ? [] : [{
            type: 'info',
            reason: translator(messages.modifierRangeInfo, {min:`${Math.round(overallMinAK * 100)}%` , max: `${Math.round(overallMaxAK * 100)}%`})
    }]

    const CreditDebitInputField = ({item}) => {
        const shownCreditDebit = _.isNaN(_.get(item, 'creditDebit')) ? null : `${parseFloat(_.get(item, 'creditDebit') * 100).toFixed(1)}%`;
        const [creditDebit, setCreditDebit] = useState(shownCreditDebit);
        const errorCondition = (parseFloat(creditDebit)/100 > item.maximum) || (parseFloat(creditDebit)/100 < item.minimum) || (_.isNaN(parseFloat(creditDebit)/100));
        return <InputField
            onValueChange={(value) => {setCreditDebit(value)}}
            value={creditDebit}
            readOnly
            showErrors
            validationMessages={errorCondition ? [messages.invalidRateFactorError] : null}
        />
    }

    const JustificationInputField = ({item}) => {
        const [justification, setJustification] = useState(_.get(item, 'justification'));
        return <InputField
            onValueChange={(value) => setJustification(value)}
            readOnly
            value={justification}
        />
    }

    const onPageNext = ( () => {
        return submissionVM;
    })

    return (
        <WizardPage
            onNext={onPageNext}
            alwaysCallOnNext
        >
            <ValidationIssuesComponent validationIssues={defaultValidationInfo}/>
            <h2>Modifiers</h2>
            <hr/>
            {
                modifiers.map(modifier => {
                    const currentState = modifier.jurisdiction;
                    const currentOverallCreditDebit = allStateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
                    const currentTotalMinimum = stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
                    const currentTotalMaximum = stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
                    const newRateFactors = modifier.rateFactors.concat({category: 'Overall'})
                    return <div>
                                <h4>Modifier: Schedule Rating Modification</h4>
                                <hr/>
                                <DataTable
                                    columnsConfigurable={false}
                                    data={newRateFactors}
                                    id="basic"
                                    showPagination={false}
                                    showSearch={false}
                                    tableLabel="Modifiers"
                                    >
                                    <DisplayColumn
                                        header="Category"
                                        id="category"
                                        path="category"
                                        textAlign="left"
                                    />
                                    <DisplayColumn
                                        header="Maximum Credit"
                                        id="minimum"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ?  
                                            `${parseFloat(overallMinAK * 100).toFixed(1)}%` || `${parseFloat(currentTotalMinimum * 100).toFixed(1)}%` 
                                            : `${parseFloat(newRateFactors[index].minimum * 100).toFixed(1)}%`}}
                                        textAlign="left"
                                    />
                                    <DisplayColumn
                                        header="Maximum Debit"
                                        id="maximum"
                                        renderCell={(item, index) => {
                                            return index ===  modifier.rateFactors.length ? 
                                            `${parseFloat(overallMaxAK * 100).toFixed(1)}%`  || `${parseFloat(currentTotalMaximum * 100).toFixed(1)}%` 
                                            : `${parseFloat(newRateFactors[index].maximum * 100).toFixed(1)}%`}}
                                        textAlign="left"
                                    />
                                    <DisplayColumn
                                        header="Credit(-)/Debit(+)"
                                        id="Liability"
                                        renderCell={(item, index) => { 
                                            return index ===  modifier.rateFactors.length ? `${parseFloat(currentOverallCreditDebit * 100).toFixed(1)}%` : <CreditDebitInputField item={item}/>}} 
                                        textAlign="left"
                                    />
                                    <DisplayColumn
                                        header="Justification"
                                        id="justification"
                                        renderCell= {(item, index) => {
                                            return index ===  modifier.rateFactors.length ? '' : <JustificationInputField item={item}/>}}
                                        textAlign="left"
                                    />
                                </DataTable>
                            </div>
                })
            }
        </WizardPage>
    );
}

GLModifiersReadOnlyPage.propTypes = WizardPage.propTypes;
export default GLModifiersReadOnlyPage;