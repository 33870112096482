import { defineMessages } from 'react-intl';

export default defineMessages({
    setupPaymentConfirmMessage: {
        id: 'billing.view.setup-billing-and-pay-confirmation.Payment will automatically be taken on the due date.',
        defaultMessage: 'Payment will automatically be taken on the due date.'
    },
    setupPaymentConfirmDue: {
        id: 'amp.bc3.directives.templates.billing-and-summary.Next draft date',
        defaultMessage: 'Next draft date:'
    },
    setupPaymentConfirmForm: {
        id: 'billing.view.setup-billing-and-pay-confirmation.Please retain an Approval form signed by the Bank Account holder',
        defaultMessage: 'Please retain an Approval form signed by the Bank Account holder.'
    },
    autoPayRemoved: {
        id: 'billing.view.remove-billing-and-pay-message.Auto Payment setup removed',
        defaultMessage: 'Auto Payment setup removed'
    },
    ok: {
        id: 'billing.view.remove-autopay-message.OK',
        defaultMessage: 'OK'
    },
    paymentSetupTitle: {
        id: 'billing.view.pay and issue title',
        defaultMessage: 'Payment Setup'
    },
    paymentSetupCancel: {
        id: 'billing.view.Cancel',
        defaultMessage: 'Cancel'
    },
    paymentSetupPayAndIssueButton: {
        id: 'billing.view.pay&issue button',
        defaultMessage: 'Pay & Issue'
    },
    paymentSetupPayNow: {
        id: 'billing.view.Pay Now',
        defaultMessage: 'Pay Now',
    },
    paymentHelpText: {
        id: 'billing.view.Payment Help Text',
        defaultMessage: '*Please note, it may take up to 10 minutes for the most recent payments to be reflected in the balances shown above.'
    }
});
