import React, {
    Fragment,
    useContext,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { WizardStepUtil } from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { BaseWizardPage } from 'wni-portals-wizard-react';
import { WizardContext } from './WizardContext';

/**
 * Sample Input:
 * [
 *   {
 *     "id": "Below required fields for issuance are missing:LANCE FOYNEN - \"Any current financial responsibility filing?\"",
 *     "title": "Below required fields for issuance are missing:LANCE FOYNEN - \"Any current financial responsibility filing?\"",
 *     "description": "Below required fields for issuance are missing:LANCE FOYNEN - \"Any current financial responsibility filing?\"",
 *     "type": "GENERIC_VALIDATION",
 *     "level": "LEVEL_WARN",
 *     "validationStep": "PADrivers",
 *     "isHidden": false,
 *     "relatedEntity": {
 *       "type": "entity.PolicyDriver",
 *       "publicID": "pc:6046",
 *       "fixedId": 6046,
 *       "displayName": "LANCE FOYNEN",
 *       "dtoName": "edge.capabilities.policyjob.lob.personalauto.coverables.dto.DriverDTO"
 *     }
 *   }
 * ]
 * @param {array} errorsForStep
 * @returns {array}
 */
function filterErrorLevelIssues(errorsForStep = []) {
    const retval = errorsForStep.filter((error) => error.level === ErrorLevel.LEVEL_ERROR);
    return retval;
}

function WizardPage(props) {
    const wizardContext = useContext(WizardContext);
    const {
        isSkipping,
        stopSkipping,
        goNext: wizardGoNext,
        wizardData,
        currentStepIndex,
        wizardPageData,
        steps: wizardSteps,
    } = wizardContext;

    const {
        skipWhen,
    } = props;

    const quotePageIndex = WizardStepUtil.getQuotePageIndex(wizardSteps);
    const lastExitWizardPageIndex = _.get(wizardPageData, WizardConstants.lastExitWizardPage, 0);
    const { baseData: { periodStatus } } = wizardData.value;

    useEffect(() => {
        if (isSkipping) {
            const shouldStayOnCurrentPage = WizardStepUtil.shouldStayOnCurrentPage({
                periodStatus, currentStepIndex, lastExitWizardPageIndex, quotePageIndex,
            });
            if (shouldStayOnCurrentPage) {
                stopSkipping();
                return;
            }
            Promise.resolve(skipWhen(wizardData))
                .then((shouldSkip) => {
                    if (shouldSkip) {
                        wizardGoNext();
                    } else {
                        stopSkipping();
                    }
                });
        }
    // });
    }, [currentStepIndex, isSkipping]);

    return (
        <BaseWizardPage {...props} />
    );
}

WizardPage.propTypes = BaseWizardPage.propTypes;

WizardPage.defaultProps = BaseWizardPage.defaultProps;
export default WizardPage;
