import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { GLQualificationService } from 'wni-capability-quoteandbind-gl';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/CPPWizardPage';

import metadata from './CPPQualificationPage.metadata.json5';
import messages from './CPPQualificationPage.messages';

const glLobDataPath = 'lobData.generalLiability';
const imLobDataPath = 'lobData.inlandmarine';
function CPPQualificationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        cppQualificationService,
        readOnly
    } = props;

    // const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CPPQualificationPage');

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    const glQuestionsetAnswers = _.get(submissionVM, `${glLobDataPath}.preQualQuestionSets.value[0].answers`) || {};
    const imQuestionsetAnswers = _.get(submissionVM, `${imLobDataPath}.preQualQuestionSets.value[0].answers`) || {};

    const glDisplayQuestions = [
        'GL7DoOrHave',
        'GL7Operations',
        'GL7Products',
        'GL7Subcontractors'
    ];

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const IsValidField = (value) => {
        if (value === undefined || value === null || value === '') {
            return false;
        }
        return true;
    }

    const IsValidFields = useCallback(() => {
        if (_.isEmpty(glQuestionsetAnswers)) {
            return true;
        }
        const invalidFields = _.filter(glDisplayQuestions, (code) => {
            return IsValidField(glQuestionsetAnswers[code]) === false
        })
        if (invalidFields.length <= 0) {
            return true;
        }
        return false;
    }, []);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const filterGLQuestionSets = useCallback((metadataContent) => {
        return _.indexOf(glDisplayQuestions, metadataContent.id) >= 0;
    }, []);

    const getGLQuestionMapper = useCallback(() => {
        return (metaDataContent) => {
            _.set(metaDataContent, 'componentProps.showRequired', true);
            _.set(metaDataContent, 'componentProps.required', true);
            if (!IsValidField(_.get(glQuestionsetAnswers, metaDataContent.componentProps.path))) {
                _.set(metaDataContent, 'componentProps.validationMessages', [translator(messages.CPPValidationMessages)]);
            } else {
                _.set(metaDataContent, 'componentProps.validationMessages', []);
            }
            return metaDataContent;
        }
    }, [glQuestionsetAnswers]);

    const getGLValidationIssues = useCallback((issues) => {
        const CPPUnfavorablyAnswerWarning = [{
            type: 'warning',
            reason: translator(messages.CPPUnfavorablyAnswerWarning)
        }]
        if (!_.isEmpty(glQuestionsetAnswers)) {
            if (
                glQuestionsetAnswers.GL7Subcontractors === 'true' || glQuestionsetAnswers.GL7Subcontractors === true
                || glQuestionsetAnswers.GL7DoOrHave === 'true' || glQuestionsetAnswers.GL7DoOrHave === true
                || glQuestionsetAnswers.GL7Operations === 'true' || glQuestionsetAnswers.GL7Operations === true
                || glQuestionsetAnswers.GL7Products === 'true' || glQuestionsetAnswers.GL7Products === true
            ) {
                return _.concat(issues, CPPUnfavorablyAnswerWarning);
            }
        }
        return issues;
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left'
            },
            glQuestionSetsContainer: {
                contentFilter: filterGLQuestionSets,
                contentMapper: getGLQuestionMapper(),
                hideSubTitle: false,
                subTitle: translator(messages.GeneralLiabilityTitle),
                visible: !_.isEmpty(glQuestionsetAnswers),
                isReadOnly: readOnly
            }
        };
    }, [glQuestionsetAnswers]);

    const onPageNext = useCallback(async () => {
        if (!isComponentValid || !IsValidFields()) {
            handleValidation();
            return false;
        }
        if (_.isEmpty(glQuestionsetAnswers)) {
            return submissionVM;
        }
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, 'lobData.generalLiability.preQualQuestionSets.value')
        };
        const res = await cppQualificationService.updateQualification(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isEmpty(res)) {
            _.set(newSubmissionVM, 'lobData.generalLiability.preQualQuestionSets.value', res.preQualQuestionSets);
        }
        updateServiceCallInProgress(false);
        updateWizardData(newSubmissionVM);

        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        const resValidationIssues = getGLValidationIssues(newValidationIssues);
        updateValidationIssues(resValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(resValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(resValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, cppQualificationService, jobID, sessionUUID, submissionVM, displayWarnings, isComponentValid]);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.cloneDeep(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            alwaysCallOnNext={!readOnly && !IsValidFields()}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

CPPQualificationPage.propTypes = WizardPage.propTypes;
CPPQualificationPage.defaultProps = WizardPage.defaultProps;

CPPQualificationPage.propTypes = {
    ...wizardProps,
    cppQualificationService: PropTypes.object
};

CPPQualificationPage.defaultProps = {
    cppQualificationService: GLQualificationService
};
export default CPPQualificationPage;