import { setComponentMapOverrides } from '@jutro/uiconfig';

import CustomWizardPageFooterTemplate from './components/CustomFooter/CustomWizardPageFooterTemplate';

setComponentMapOverrides({
    CustomWizardPageFooterTemplate: { component: 'CustomWizardPageFooterTemplate' },
}, {
    CustomWizardPageFooterTemplate,
});


// ===============================
export { default as BaseDigitalWizardPageTemplate } from './templates/BaseDigitalWizardPageTemplate';
export { default as BaseDigitalWizardPageWithTitleTemplate } from './templates/WithTitle/BaseDigitalWizardPageWithTitleTemplate';
export { default as BaseMobileWizardSteps } from './components/MobileWizardSteps/BaseMobileWizardSteps';

export { default as BaseWizardPageHeader } from './components/WizardPageHeader/BaseWizardPageHeader';
export { default as BaseWizardPageSideHeader} from './components/WizardPageHeader/BaseWizardPageHeader.sideHeader';
