import React from 'react';

import { CPPolicyDetailsPage } from 'wni-capability-quoteandbind-cp-react';

function CPPolicyDetailsChangePage(props) {

    return (
        <CPPolicyDetailsPage {...props} />
    );

}

CPPolicyDetailsChangePage.propTypes = CPPolicyDetailsPage.propTypes;
CPPolicyDetailsChangePage.defaultProps = CPPolicyDetailsPage.defaultProps;

export default CPPolicyDetailsChangePage;
