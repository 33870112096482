import { setComponentMapOverrides } from '@jutro/uiconfig';

import RTDummyPage from './pages/Dummy/RTDummyPage';
import RTDummyReadOnlyPage from './pages/Dummy/RTDummyPage.readonly';

import RTPolicyDetailsPage from './pages/PolicyDetails/RTPolicyDetailsPage';
import RTPolicyDetailsReadOnlyPage from './pages/PolicyDetails/RTPolicyDetailsPage.readonly';

import RTOperatorsPage from './pages/Operators/RTOperatorsPage';
import RTOperatorsReadOnlyPage from './pages/Operators/RTOperatorsPage.readonly';

import RTVehiclesPage from './pages/Vehicles/RTVehiclesPage';
import RTVehiclesReadOnlyPage from './pages/Vehicles/RTVehiclesPage.readonly';

import RTCoveragesPage from './pages/Coverages/RTCoveragesPage';
import RTCoveragesReadOnlyPage from './pages/Coverages/RTCoveragesPage.readonly';

import RTIncidentsAndReportsPage from './pages/IncidentsAndReports/RTIncidentsAndReportsPage';
import RTIncidentsAndReportsReadOnlyPage from './pages/IncidentsAndReports/RTIncidentsAndReportsPage.readonly';

import RTQuotePage from './pages/Quote/RTQuotePage';
import RTQuoteReadOnlyPage from './pages/Quote/RTQuotePage.readonly';

setComponentMapOverrides(
    {
        RTDummyPage: {component: 'RTDummyPage'},
        RTDummyReadOnlyPage: { component: 'RTDummyReadOnlyPage' },

        RTPolicyDetailsPage: {component: 'RTPolicyDetailsPage'},
        RTPolicyDetailsReadOnlyPage: { component: 'RTPolicyDetailsReadOnlyPage' },

        RTOperatorsPage: { component: 'RTOperatorsPage' },
        RTOperatorsReadOnlyPage: { component: 'RTOperatorsReadOnlyPage' },

        RTVehiclesPage: { component: "RTVehiclesPage" },
        RTVehiclesReadOnlyPage: { component: "RTVehiclesReadOnlyPage"},

        RTCoveragesPage: { component: "RTCoveragesPage" },
        RTCoveragesReadOnlyPage: { component: "RTCoveragesReadOnlyPage" },

        RTIncidentsAndReportsPage: { component: "RTIncidentsAndReportsPage" },
        RTIncidentsAndReportsReadOnlyPage: { component: "RTIncidentsAndReportsReadOnlyPage" },

        RTQuotePage: { component: 'RTQuotePage' },
        RTQuoteReadOnlyPage: { component: 'RTQuoteReadOnlyPage' },
    },
    {
        RTDummyPage,
        RTDummyReadOnlyPage,

        RTPolicyDetailsPage,
        RTPolicyDetailsReadOnlyPage,

        RTOperatorsPage,
        RTOperatorsReadOnlyPage,
      
        RTVehiclesPage,
        RTVehiclesReadOnlyPage,

        RTCoveragesPage,
        RTCoveragesReadOnlyPage,

        RTIncidentsAndReportsPage,
        RTIncidentsAndReportsReadOnlyPage,

        RTQuotePage,
        RTQuoteReadOnlyPage,
    }
);


export {
    RTDummyPage,
    RTDummyReadOnlyPage,

    RTPolicyDetailsPage,
    RTPolicyDetailsReadOnlyPage,

    RTOperatorsPage,
    RTOperatorsReadOnlyPage,

    RTVehiclesPage,
    RTVehiclesReadOnlyPage,

    RTCoveragesPage,
    RTCoveragesReadOnlyPage,
    
    RTIncidentsAndReportsPage,
    RTIncidentsAndReportsReadOnlyPage,
};

// eslint-disable-next-line import/prefer-default-export
export { default as RTSubmissionWizard } from './RTSubmissionWizard';
export { default as RTWizardPage } from './templates/RTWizardPage';

export { default as RTQuoteIssueRenderHelper } from './pages/Quote/util/RTQuoteIssueRenderHelper';
