import { MetadataContent } from '@jutro/uiconfig';
import _ from 'lodash';
import React from 'react';
import { useTranslator } from '@jutro/locale';
import messages from '../../CPBlanketsPage.messages';

const CPBlanketFieldMap = (props) => {
    const translator = useTranslator();
    const {
        vm,
        dataPath,
        onValueChange,
        onValidate,
        showErrors,
        readOnly,
        onBlur
    } = props;

    const fieldModels = _.get(vm.value, dataPath, []);

    const handleValueChange = (newValue, index, fieldModel) => {
        onValueChange(newValue, `${dataPath}[${index}]`, fieldModel);
    };

    const handleBlur = (value, fieldItem) => {
        if (fieldItem.inputSetMode === 'IntegerRange' && fieldItem.integerValue > 99999999 && fieldItem.integerValue < 0) {
            return;
        }
        onBlur(value, fieldItem);
    };

    const metadata = fieldModels.map((fieldItem, index) => {
        let additionalProps = {};

        if(fieldItem.label === 'Blanket Applied to'){
            additionalProps = {
                tooltip: translator(messages.blanketAppliedtpTooltip)
            }
        };
        if(fieldItem.label === 'Blanket Type' && vm.value.blanketType === 'Blanket Coverage'){
            additionalProps = {
                tooltip: translator(messages.blanketTypeTooltip)
            }
            if (fieldItem.fieldValue === 'Blanket Coverage') {
                _.set(fieldItem, 'fieldValue', 'Standard Blanket')
            }
        };
        switch(fieldItem.label) {
            case '':
                return;
            default:
        return {
            id: `${fieldItem.label}Field`,
            type: 'field',
            component: 'WniFieldSetModel',
            componentProps: {
                showRequired: true,
                showErrors: showErrors,
                readOnly: readOnly || fieldItem.readonly,
                onValueChange: (value, fieldModel) => handleValueChange(value, index, fieldModel),
                onBlur: (value) => handleBlur(value, fieldItem),
                fieldItem,
                onValidate,
                ...additionalProps
            },
        };
        }
    });

    const overrides = {};
    const resolvers = {
        resolveComponentMap: {},
    };

    return (
        <MetadataContent
            uiProps={metadata}
            overrideProps={overrides}
            {...resolvers}
        />
    );
};

export default CPBlanketFieldMap;
