import React from 'react';
import _ from 'lodash';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import {
    ErrorsAndWarningsUtil,
    ValidationIssueUtil,
    QuoteCoveragesUtil,
    QuoteUtil
} from 'wni-portals-util-js';
import { Button } from '@jutro/components';
import { ValidationIssueRenderUtil } from 'wni-portals-util-react';
// import messages from 'gw-capability-quoteandbind-pa-react/pages/Quote/QuotePage.messages';
import customMessages from '../PAQuotePage.messages';

const ISSUE_TYPE_TO_TITLE_MAP = {
    info: customMessages.reviewWarningMessages,
    warning: customMessages.reviewWarningMessages,
    error: customMessages.submitTheApplication,
};

const { structureClauseTableData } = QuoteCoveragesUtil;

function generateClauseData(columnData, coveragePath,
    // completeCoveragePathGetter,
    vehicleFixedId = undefined) {
    // const covPathGetter = completeCoveragePathGetter || (() => `coverages.${coveragePath}`);

    const retval = columnData.map(({ lobOffering, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;
        // const completeCoveragePath = covPathGetter(lobOffering);

        if (!_.isNil(vehicleFixedId)) {
            const vehicleCoverages = _.get(lobOffering, 'data.coverages.vehicleCoverages');
            const vehicleCoverageIndex = vehicleCoverages.findIndex((vc) => vc.fixedId === vehicleFixedId);
            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleCoverageIndex}].coverages`;
        }
        // if (completeCoveragePathGetter && _.isFunction(completeCoveragePathGetter)) {
        //     completeCoveragePath = completeCoveragePathGetter(lobOffering);
        // }

        return {
            code: code,
            path: `${lobOffering.path}.${completeCoveragePath}`,
            clauses: _.get(lobOffering.data, completeCoveragePath.replace(/\.children/, '')),
            pairClauses: _.get(lobOffering.pairData, completeCoveragePath.replace(/\.children/, '')),
        };
    });
    return retval;
}

function structureCustomQuote(submissionVM, affectedQuote, clauses) {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    };
}

function getLineCoveragesUniqueID(submissionVM, periodToCovIssuessMap = {}) {
    const offerings = _.get(submissionVM, 'lobData.personalAuto.offerings.value');
    // const lineCoverages = _.uniqBy(offerings.flatMap((offering) => (
    //     offering.coverages.lineCoverages.map(structureClauseTableData)
    // )), 'publicID');
    // const allLineCovs = offerings.flatMap((offering) => (offering.coverages.lineCoverages))
    //     .filter(QuoteCoveragesUtil.filterCovOnQuotePage);
    const allLineCovs = offerings.flatMap((offering) => {
        const {
            publicID_Ext: periodPublicID,
            coverages: {
                lineCoverages,
                // driverCoverages_Ext: driverCoverages,
            },
        } = offering;
        const periodCovIssues = periodToCovIssuessMap[periodPublicID];

        const retval = lineCoverages.filter((lineCov) => QuoteCoveragesUtil.filterCovOnQuotePage(lineCov,
            periodCovIssues));
        return retval;
    });
    const lineCoveragesLiabGroup = _.uniqBy((
        allLineCovs.filter(QuoteCoveragesUtil.belongsToLiabilityTable)
            .map(structureClauseTableData)
    ), 'publicID');
    const lineCoveragesOtherGroup = _.uniqBy((
        allLineCovs.filter((cov) => !QuoteCoveragesUtil.belongsToLiabilityTable(cov))
            .map(structureClauseTableData)
    ), 'publicID');
    // const vehicleCoverages = _.uniqBy(offerings.flatMap((offering) => (
    //     offering.coverages.vehicleCoverages.flatMap(({ coverages }) => (
    //         coverages.filter(QuoteCoveragesUtil.filterCovOnQuotePage).map(structureClauseTableData)
    //     ))
    // )), 'publicID');
    return {
        // lineCoverages,
        lineCoveragesLiabGroup,
        lineCoveragesOtherGroup,
        // vehicleCoverages,
    };
}

/**
 * Get a map of vehicleCoverages with form of:
 *  {
 *     vehicle.fixedId: [ [publicID: clause_public_ID}]]
 *  }
 * @param {object} submissionVM
 * @param {object} periodToCovIssuessMap (Optional)
 * @param {array} vehicleCoverageSets (Optional)
 * @returns {object}
 */
function getVehicleCoveragesUniqueID(submissionVM, periodToCovIssuessMap = {},
    vehicleCoverageSets = []) {
    const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
    const vehicleFixedIdList = vehicles.map((vehicle) => vehicle.fixedId);

    const offerings = _.get(submissionVM, 'lobData.personalAuto.offerings.value');

    const vehicleCoveragesMap = {};
    vehicleFixedIdList.forEach((vehicleFixedId) => {
        const vehicleCoveragesArray = QuoteCoveragesUtil.getStructuredVehicleCoverages(offerings,
            vehicleFixedId, (vehCov, periodPublicID) => {
                const periodCovIssues = periodToCovIssuessMap[periodPublicID];
                return QuoteCoveragesUtil.filterCovOnQuotePage(vehCov, periodCovIssues, vehicleFixedId);
            });
        vehicleCoveragesMap[vehicleFixedId] = vehicleCoveragesArray;
    });

    // ====for Custom Eqiupment Coverages BEGINS========================
    const vehicleFixedIds = QuoteCoveragesUtil.getVehicleFixedIdsFromCoverageSets(vehicleCoverageSets);
    if (!_.isEmpty(vehicleFixedIds)) {
        vehicleFixedIds.forEach((vehicleFixedId) => {
            const vehCovArray = vehicleCoveragesMap[vehicleFixedId];
            const customEquipCov = vehCovArray.find((vehCov) => {
                return vehCov.codeIdentifier === 'PACustomEquipCov_Ext';
            });
            // Custom Equipment does not exist in current table row, which means
            // we need to either extract it from existing vehicleCov list, or
            // populate with a dummy one
            if (!customEquipCov) {
                const unselectedCustomEquipCovs = QuoteCoveragesUtil.getStructuredVehicleCoverages(
                    offerings, vehicleFixedId, (vehCov) => vehCov.code_Ext === 'PACustomEquipCov_Ext'
                );
                if (_.isEmpty(unselectedCustomEquipCovs)) {
                    // If there is not existing CustomEquipment Covrage, then populate a dummy one
                    vehCovArray.push({
                        // publicID: undefined,
                        publicID: 'PACustomEquipCov_Ext', // zikheonbti42t37080emncndvhb
                        codeIdentifier: 'PACustomEquipCov_Ext',
                    });
                } else {
                    // Otherwise populate with existing unchecked CustomEquipmentCoverage
                    const [ceCov] = unselectedCustomEquipCovs;
                    vehCovArray.push(ceCov);
                }
            }
        });
    }
    // ====for Custom Eqiupment Coverages ENDS========================
    return vehicleCoveragesMap;
}

function filterLobOffering(lobOffering, sxsCoverages) {
    if (_.isEmpty(sxsCoverages)) {
        return lobOffering;
    }
    const {
        coverages: {
            lineCoverages,
            vehicleCoverages
        },
        ...extraProps
    } = lobOffering;
    const {
        lineCoverages: sxsLineCoverages,
        vehicleCoverages: sxsVehicleCoverages,
    } = sxsCoverages;

    // const sxsLinCovPublicIDArray = sxsLineCoverages.map((lineCov) => lineCov.publicID);
    // const sxsVehCovPublicIDArrayMap = sxsVehicleCoverages.map(({ publicID, fixedId, coverages }) => {
    //     return {
    //         publicID,
    //         fixedId,
    //         coverages: coverages.map((cov) => cov.publicID),
    //     };
    // });

    const newLineCoverages = lineCoverages.filter(({ publicID }) => {
        return sxsLineCoverages.find((lineCov) => lineCov.publicID === publicID);
    });
    const newVehicleCoverages = vehicleCoverages.map(({ publicID, fixedId, coverages }) => {
        const sxsVeh = sxsVehicleCoverages.find((sxsVehCov) => sxsVehCov.fixedId === fixedId);
        let filteredVehCovs = null;

        const sxsVehCovs = _.get(sxsVeh, 'coverages');
        if (!_.isEmpty(sxsVehCovs)) {
            filteredVehCovs = coverages.filter(({ publicID: covPublicID }) => {
                return sxsVehCovs.find(((scov) => scov.publicID === covPublicID));
            });
        } else {
            filteredVehCovs = [];
        }
        return {
            publicID,
            fixedId,
            coverages: filteredVehCovs
        };
    });
    return {
        coverages: {
            lineCoverages: newLineCoverages,
            vehicleCoverages: newVehicleCoverages,
        },
        ...extraProps
    };
}

// ============================================================
function generateColumnData({
    submissionVM, sxsDataDTO,
    paymentMethod,
    // installmentPlansMap = {},
    filterLobOfferings = false,
    ...extraColumnData
}) {
    if (!sxsDataDTO) {
        return [];
    }

    const lobOfferingPath = 'lobData.personalAuto.offerings';
    const sxsPeriodsPath = 'personalAuto.periods';
    const sxsCoveragesPath = 'personalAuto.coverages';

    const lobOfferings = _.get(submissionVM.value, `${lobOfferingPath}`);
    const sxsPeriods = _.get(sxsDataDTO, `${sxsPeriodsPath}`);
    const sxsCoverages = _.get(sxsDataDTO, `${sxsCoveragesPath}`);
    const sxsVehicleCovSets = _.get(sxsDataDTO, 'personalAuto.vehicleSets');

    if (lobOfferings.length !== sxsPeriods.length) {
        return [];
    }


    const errorsAndWarnings = _.get(sxsDataDTO, 'errorsAndWarnings', {});

    // Basically, SideBySidePeriod Info such as PolicyType, Premium, etc. are put into 'quote',
    // along with line & vehicle coverages data
    // const periodStatus = _.get(submissionVM, 'baseData.periodStatus.value');
    const columnData = lobOfferings.map((lobOffering, lobOfferingIndex) => {
        const sxsPeriodIndex = sxsPeriods.findIndex(
            (sp) => sp.publicID === lobOffering.publicID_Ext
        );
        const sxsPeriod = sxsPeriods[sxsPeriodIndex];

        const {
            publicID: sxsPeriodPublicID,
            columnIndex,
            pairPeriodPublicId_Ext: sxsPairPeriodPublicID,
        } = sxsPeriod;
        const sxsPeriodUWIssues = ErrorsAndWarningsUtil.filterUWIssuesBasedOnPeriod(
            _.get(errorsAndWarnings, 'underwritingIssues'), sxsPeriodPublicID
        );
        const sxsPeirodEligibilityIssues = ErrorsAndWarningsUtil.filterEligibilityIssuesBasedOnPeriod(
            _.get(errorsAndWarnings, 'eligibilityIssues_Ext.personalAuto'), sxsPeriodPublicID
        );
        const vehicleCoverageSets = QuoteCoveragesUtil.filterVehicleCoverageSets(sxsVehicleCovSets, columnIndex);

        const sxsLobOffering = filterLobOfferings ? filterLobOffering(lobOffering, sxsCoverages) : lobOffering;
        const sxsPairLobOffering = QuoteUtil.getLobOfferingByPeriodPublicID(lobOfferings, sxsPairPeriodPublicID);

        // const installmentPlans = installmentPlansMap[sxsPeriod.publicID];

        return {
            name: sxsPeriod.branchName,
            code: sxsPeriod.columnIndex,
            sxsPeriod: {
                path: `${sxsPeriodsPath}.children.${sxsPeriodIndex}`,
                data: sxsPeriod,
                underwritingIssues: sxsPeriodUWIssues,
                eligibilityIssues: sxsPeirodEligibilityIssues,
                vehicleCoverageSets,
                paymentMethod,
                // installmentPlans,
                ...extraColumnData,
            },
            lobOffering: {
                path: `${lobOfferingPath}.children.${lobOfferingIndex}`,
                // data: lobOffering,
                data: sxsLobOffering,
                pairData: sxsPairLobOffering,
                index: lobOfferingIndex,
            }
        };
    });
    return _.sortBy(columnData, ['code']);
}

// Line Coverages and Vehicle Coverages Info
function generateTableData(submissionVM, columnData, translator, sxsDataDTO = undefined) {
    const periodToCovIssuesMap = QuoteCoveragesUtil.getPeriodToCovIssuesMap(sxsDataDTO, 'personalAuto');
    const sxsVehicleCovSets = _.get(sxsDataDTO, 'personalAuto.vehicleSets');
    // line coverages
    const lineCoveragesUniqueIDMap = getLineCoveragesUniqueID(submissionVM, periodToCovIssuesMap);

    // const lineCoverages = {
    //     header: translator(messages.generalCoverages),
    //     data: coveragesUniqueIDMap.lineCoverages,
    //     tableContent: generateClauseData(columnData, 'lineCoverages')
    // };

    const lineCoveragesLiabGroup = {
        header: translator(customMessages.liabilityLimits),
        data: lineCoveragesUniqueIDMap.lineCoveragesLiabGroup,
        tableContent: generateClauseData(columnData, 'lineCoverages')
    };

    const lineCoveragesOtherGroup = {
        header: translator(customMessages.otherCoverages),
        data: lineCoveragesUniqueIDMap.lineCoveragesOtherGroup,
        tableContent: generateClauseData(columnData, 'lineCoverages')
    };

    // vehicle coverages
    const vehicles = _.get(submissionVM.value, 'lobData.personalAuto.coverables.vehicles');
    const vehicleCoveragesIDMap = getVehicleCoveragesUniqueID(submissionVM, periodToCovIssuesMap, sxsVehicleCovSets);
    const vehicleCoverages = vehicles.map((vehicle) => ({
        header: `${translator(customMessages.vehicleSpecificCoverage)}${QuoteUtil.getVehicleDescTitle(vehicle, ' - ')}`,
        // data: coveragesUniqueIDMap.vehicleCoverages,
        data: vehicleCoveragesIDMap[vehicle.fixedId],
        tableContent: generateClauseData(columnData, 'vehicleCoverages', vehicle.fixedId),
        // (lobOffering) => {
        //     const vehicleCovs = _.get(lobOffering, 'data.coverages.vehicleCoverages');
        //     const vehicleCovIdx = vehicleCovs.findIndex((vc) => vc.fixedId === vehicle.fixedId);
        //     const retval = `coverages.vehicleCoverages.children[${vehicleCovIdx}].coverages`;
        //     return retval;
        // })
        vehicleFixedId: vehicle.fixedId,
    }));

    return [
        // lineCoverages,
        lineCoveragesLiabGroup,
        ...vehicleCoverages,
        lineCoveragesOtherGroup,
    ];
}

function getCustomQuote(vm, lobPath, quotePath, lobName, filterChangedClauses = false) {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(lobOffering.coverages, lobName, null);
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
}

// /**
//  * Get errors and warnings title
//  * @param {function} translator
//  * @param {function} issueToTitleFn (Optional)
//  * @returns {function} string the title for specific issue type
//  */
// function getQuotePageErrorsAndWarningsTitle(translator, issueToTitleFn) {
//     const issueToTitleGetter = (issueType) => {
//         let typeTitle;
//         if (_.isFunction(issueToTitleFn)) {
//             typeTitle = issueToTitleFn(issueType);
//         }
//         if (!typeTitle) {
//             typeTitle = ISSUE_TYPE_TO_TITLE_MAP[issueType];
//         }
//         return typeTitle || customMessages.reviewWarningMessages;
//     };

//     return (type, issues) => {
//         const title = issueToTitleGetter(type);

//         // return translator(title, {item: issues.length > 1 ? 'items': 'item'});
//         return translator(title);
//     };
// }

// function getQuotePageErrorsAndWarningsFooter({
//     wizardSteps, wizardStepToFieldMapping,
//     wizardJumpTo, resolveButtonLabel,
//     isServiceCallInProgress,
// }) {
//     return (type, issues) => {
//         if (type === 'error') {
//             //
//             const originalIssues = ValidationIssueUtil.flattenIssueChildren(issues);
//             const jumpFnToEarliestPage = ErrorsAndWarningsUtil.getJumpFnToEarliestPageWithIssue(
//                 originalIssues, wizardSteps, wizardStepToFieldMapping, wizardJumpTo
//             );
//             if (!jumpFnToEarliestPage) {
//                 return null;
//             }
//             // className: ph-10
//             return (
//                 <div className="d-flex flex-direction-row-reverse mt-10">
//                     <Button
//                         id="issueJumpBtn"
//                         onClick={jumpFnToEarliestPage}
//                         disabled={isServiceCallInProgress}
//                     >
//                         {resolveButtonLabel}
//                     </Button>
//                 </div>
//             );
//         }
//         return null;
//     };
// }

export default {
    generateColumnData,
    getCustomQuote,
    generateTableData,
    //
    getQuotePageErrorsAndWarningsTitle: ValidationIssueRenderUtil.getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter: ValidationIssueRenderUtil.getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn: ValidationIssueRenderUtil.getQuotePageIssueRenderFn,
};
