import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { Loader } from '@jutro/components';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import {
    IncidentsUtil,
    ErrorsAndWarningsUtil,
    ServiceErrorUtil,
    IssuanceValidationUtil,
    PolicyChangeQuoteUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';
import { ModalDialogHelper } from 'wni-portals-util-react';
import { ValidationIssuesComponent, QuoteUnderwritingIssuesList, PAPolicyChangeQuoteComponent, useWniModal } from 'wni-components-platform-react';
import { PAQuoteUIHelper } from 'gw-capability-quoteandbind-pa-react';
import { SuccessNotification } from 'gw-components-platform-react';
import { WniSxsChangeService } from 'wni-capability-policychange';
import { PolicyDiffService } from 'gw-capability-policyjob';
import {
    SideBySidePeriodsComponent,
    ReferToUnderwriterComponent,
    PolicyChangeSideBySidePeriodInfo,
    ChangeSummaryComponent,
    DocumentCheckedComponent,
} from 'wni-capability-gateway-react';
import { messages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PolicyChangeCommonMessages as currentMessages } from 'wni-platform-translations';
import styles from './PAQuoteChangePage.module.scss';
import metadata from './PAQuoteChangePage.metadata.json5';

const {
    generateColumnData,
    generateTableData,
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = PAQuoteUIHelper;

function PAQuoteChangeReadOnlyPage(props) {
    const {
        history,
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        wizardStepToFieldMapping,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        wizardPageData,
        updateWizardPageData,
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const modalApi = useWniModal();
    const { isLoggedIn, authHeader } = useAuthentication();
    const [allDrivers, updateAllDrivers] = useState();
    const [allVehicles, updateAllVehicles] = useState();
    const [sxsDataDTO, updatesxsDataDTO] = useState(undefined);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [isShowMulti, updateShowMulti] = useState(false);
    const [hasBlockingIssue, updateHasBlockingIssue] = useState(false);
    const [policyDiffData, updatePolicyDiffData] = useState([]);

    const {
        jobID,
        // totalCost: {
        //     amount: totalCostAmount,
        // } = {},
        // transactionCost: {
        //     amount: transactionCostAmount,
        // } = {},
        baseData: {
            effectiveDate,
            periodStartDate,
            periodEndDate,
        },
        lobData: {
            personalAuto: {
                coverables: {
                    vehicles = [],
                }
            }
        },
        previousTotalCost,
        transactionCost,
        totalCost,
        oossliceDates_Ext: oossliceDates,
        status: jobStatus,
    } = submissionVM.value;
    const modalHelper = ModalDialogHelper(modalApi);

    const updateSideBySideData = async (clearUWIssues = false) => {
        updateServiceCallInProgress(true);
        let sideBySideData = wizardPageData[WizardConstants.sideBySideData];
        const updatedWizardPageData = {};

        if (!sideBySideData) {
            sideBySideData = await WniSxsChangeService.retrieveSideBySideData(jobID, authHeader);
            updatedWizardPageData[WizardConstants.sideBySideData] = updatedWizardPageData;
        }
        const newErrorsAndWarnings = _.get(sideBySideData, 'errorsAndWarnings', {});
        if (clearUWIssues) {
            _.unset(newErrorsAndWarnings, 'underwritingIssues');
        }
        updateErrorsAndWarnings(newErrorsAndWarnings);
        if (sideBySideData.personalAuto) {
            updatesxsDataDTO(sideBySideData);
        }
        if (!_.isEmpty(updatedWizardPageData)) {
            updateWizardPageData(updatedWizardPageData);
        }
        updateServiceCallInProgress(false);
    };

    const updatePolicyDifferences = async () => {
        if (!_.isEmpty(policyDiffData)) {
            return;
        }
        let cachedPolicyDiffData = wizardPageData[WizardConstants.policyDiffData];
        if (_.isNil(cachedPolicyDiffData)) {
            cachedPolicyDiffData = await PolicyDiffService.getPolicyDiffWithPrevious(jobID,
                authHeader);
            updateWizardPageData({ [WizardConstants.policyDiffData]: cachedPolicyDiffData });
        }
        if (cachedPolicyDiffData) {
            updatePolicyDiffData(cachedPolicyDiffData);
        }
    };

    const withServiceInProgressUpdated = async (cb) => {
        updateServiceCallInProgress(true);
        try {
            await cb();
        } catch (e) {
            const errorMessage = ServiceErrorUtil.getErrorMessage(e);
            modalHelper.alert({ errorMessage });
        }
        updateServiceCallInProgress(false);
    };

    useEffect(() => {
        // to be updated
        withServiceInProgressUpdated(async () => {
            updatePolicyDifferences();
        });
    }, []);

    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        const setDriverNames = () => {
            const drivers = _.get(
                submissionVM.value,
                'lobData.personalAuto.coverables.drivers',
                []
            );
            const driverNames = drivers.map((driver) => driver.displayName);
            updateAllDrivers(driverNames.join());
        };

        const setVehicleNames = () => {
            const vehicleNames = vehicles.map((vehicle) => vehicle.displayName);
            updateAllVehicles(vehicleNames.join());
        };

        if (isLoggedIn) {
            setDriverNames();
            setVehicleNames();
        }
    }, [isLoggedIn, submissionVM, vehicles]);

    const showMultiVersions = async () => {
        updateShowMulti(true);
        updateSideBySideData(true);
    };

    const closeMultiVersions = () => {
        updateShowMulti(false);
    };

    const generateOverrides = useCallback(() => {
        const effectiveDateFormat = WniDateUtil.formatDateWithPattern(effectiveDate);
        const startPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodStartDate);
        const endPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodEndDate);

        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        const columnData = PAQuoteUIHelper.generateColumnData({
            submissionVM,
            sxsDataDTO,
            isClueCompare,
            isMvrCompare,
            callbackMap: {
                // onFinishQuote,
                // onSubmitQuote,
                // onReferToUnderwriter,
                // onContinueToIssue: async () => onContinueToIssue(onNext),
                // goToIncidentReports
            }
        });
        const tableData = PAQuoteUIHelper.generateTableData(submissionVM, columnData, translator);

        const newErrorsAndWarnings = _.get(submissionVM, 'errorsAndWarnings_Ext.value');
        const validationIssues = ErrorsAndWarningsUtil.getValidationIssues(newErrorsAndWarnings);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        const hasValidationError = ErrorsAndWarningsUtil.hasValidationError(newErrorsAndWarnings);

        const underwritingIssues = _.get(newErrorsAndWarnings, 'underwritingIssues', []);
        const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(newErrorsAndWarnings);

        updateHasBlockingIssue(hasBlockUWIssue || hasValidationError);

        const changeUtil = PolicyChangeQuoteUtil({
            intl, translator, totalCost, transactionCost, currentMessages
        });
        const premiumDiffMsg = changeUtil.getPremiumDiffMessage();
        const premiumDiffTitle = changeUtil.getPremiumDiffTitle();
        return {
            '@field': {
                labelPosition: 'left'
            },
            errorsAndWarningsSection: {
                validationIssues: IssuanceValidationUtil.getIssuesMap(validationIssues),
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator),
                issueRenderFn: getQuotePageIssueRenderFn,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
            },
            drivers: {
                value: allDrivers
            },
            vehicles: {
                value: allVehicles
            },
            // effectiveDateId: {
            //     value: effectiveDateFormat
            // },
            // peroidDateRangeId: {
            //     value: `${startPeriodDateFormat} ~ ${endPeriodDateFormat}`
            // },
            // =====================
            policyChangeSummary: {
                validationIssues: premiumDiffMsg,
                typeTitleFormatter: premiumDiffTitle,
                previousTotalCost,
                transactionCost,
                totalCost,
                effectiveDateFormat,
                periodDateRange: `${startPeriodDateFormat} ~ ${endPeriodDateFormat}`,
                // transactionCostAmount,
                showPremiumChange: jobStatus === 'Quoted' || jobStatus === 'Bound',
                scrollToIssues: true,
            },
            multiVersionBtn: {
                visible: false,
            },
            PAHeadId: {
                visible: !isShowMulti
            },
            policyChangeDetailId: {
                visible: !isShowMulti
            },
            paPolicyDiff: {
                policyDiffData,
                jobNumber: jobID,
                vehicles,
            },
            // ============================
            documentcheckedList: {
                visible: false, // hide the doc section in readonly mode, for now.
            },
            // ============================
            multiVersionsId: {
                visible: isShowMulti
            },
            addNewBtn: {
                visible: false,
            },
            sxsPeriodsInfoSection: {
                columnData,
                isServiceCallInProgress,
                sxsPeriodInfo: PolicyChangeSideBySidePeriodInfo,
                showActionButtons: false,
            },
            quoteTable: {
                columnData,
                tableData,
                // isEditable: false
            },
            quoteUWIssues: {
                visible: false,
                // underwritingIssues,
                // headerObject: currentMessages.IssuesRequiringReferral,
            },
            referToUWBtn: {
                visible: false,
            },
        };
    },
    [
        submissionVM,
        isServiceCallInProgress,
        errorsAndWarnings, sxsDataDTO,
        translator,
        isShowMulti,
        policyDiffData,
    ]);

    const renderQuotePage = useCallback(({ onNext }) => {
        const overrideProps = generateOverrides(onNext);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onStaleQuoteBranchCode: _.noop,
                onChangeSubmissionAndSync: _.noop,
                // onChangeSubmission: updateWizardData,
                onSyncCoverages: _.noop,
                onShowMultiVersions: showMultiVersions,
                onBackToDetail: closeMultiVersions,
                // onCreateNewVersion: createNewVersion,
                onPrint: handlePrint
            },
            resolveComponentMap: {
                sxsquoteperiods: SideBySidePeriodsComponent,
                validationissuescomponent: ValidationIssuesComponent,
                quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
                notificationcomponent: SuccessNotification,
                policychangequotecomponent: PAPolicyChangeQuoteComponent,
                refertouw: ReferToUnderwriterComponent,
                changesummary: ChangeSummaryComponent,
                documentcheckedcomponent: DocumentCheckedComponent,
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }, [generateOverrides, submissionVM]); // sxsDataDTO

    // ==============================================
    const isInitializing = isServiceCallInProgress;
    return (
        <WizardPage
            showNext={false}
            cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
        >
            {isInitializing ? <Loader /> : renderQuotePage}
        </WizardPage>
    );
}

PAQuoteChangeReadOnlyPage.propTypes = wizardProps;
export default PAQuoteChangeReadOnlyPage;
