
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import {
    DropdownMenuHeader,
    DropdownMenuLink,
} from '@jutro/components';
import { WizardContext, wizardStepProps } from '@xengage/gw-portals-wizard-react';

import { PolicyChangeCommonMessages as messages } from 'wni-platform-translations';
import { BaseAddNewChangeVersionComponent } from 'wni-components-platform-react';

import { CPMultiQuoteChangeService } from 'wni-capability-policychange-cp';
// import messages from './CPAddNewChangeVersionComponent.messages';

// const POLICY_TYPE_CORE = 'CORE';

/**
 * CP Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function CPAddNewChangeVersionComponent(props) {
    
    const translator = useTranslator();

    const {
        sxsDataDTO: cpSxsDataDTO = {},
        ...restProps
    } = props;


    const {
        canAddNewVersion,
        periods = [{}],
    } = cpSxsDataDTO;


    const menuItems = [
        {  
            menuName: translator(messages.createNewVersion),
            createService: CPMultiQuoteChangeService.createDuplicateQuote,
            menuVisible: canAddNewVersion,
        },
    ];

    return <BaseAddNewChangeVersionComponent
        menuItems={menuItems}
        {...restProps}
    />;
}

CPAddNewChangeVersionComponent.propTypes = {
    // model: PropTypes.shape(
    //     {
    //         value: PropTypes.shape({})
    //     }
    // ),
    // onValueChange: PropTypes.func,
    // showErrors: PropTypes.bool,
    sxsDataDTO: PropTypes.shape({}).isRequired,
    /**
     * onCreateNewVersion() will be called with the JsonRpc Method as parameter
     */
    onCreateNewVersion: PropTypes.func.isRequired,
    // ====================================
    /**
     * A list of AccountJobPeriodDTO instances
     */
    accountJobPeriodDTOList: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Callback to switch to other branch
     */
    onSwitchToBranch: PropTypes.func,
};

CPAddNewChangeVersionComponent.defaultProps = {
    accountJobPeriodDTOList: [],
    onSwitchToBranch: _.noop,
};

export default CPAddNewChangeVersionComponent;