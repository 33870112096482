import React, {
    useState, useContext, useEffect, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FormattedMessage, useTranslator } from '@jutro/locale';
import { Button, useModal } from '@jutro/components';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { CPCoverablesService } from 'gw-capability-quoteandbind-cp';
import metadata from './EditLocationSummary.metadata.json5';
import styles from './EditLocationSummary.module.scss';
import messages from './EditLocationSummary.messages';
import message from '../../../PECPWizard.messages';

const LOCATION_PATH = 'lobData.commercialProperty.coverables.locations';

const EditLocationSummary = (props) => {
    const modalApi = useModal();
    const {
        locationToEdit, completeEdit, submissionVM, updateWizardData
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [locationSubmission, updateLocationSubmission] = useState({});


    useEffect(() => {
        const submission = viewModelService.create(
            locationToEdit,
            'pc',
            'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.LocationDTO'
        );
        updateLocationSubmission(submission);
    }, [locationToEdit, viewModelService]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(locationSubmission);
            _.set(newSubmissionVM, path, value);
            updateLocationSubmission(newSubmissionVM);
        },
        [locationSubmission, viewModelService]
    );

    const showCancelModal = useCallback(() => {
        return modalApi.showConfirm({
            title: message.editLocationCancelChangesTitle,
            message: message.editLocationCancelChangesMessage,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: MediaKeyMessageEvent.editLocationCancelChangesConfirmationYes,
            cancelButtonText: message.editLocationCancelChangesConfirmationNo
        }).catch(_.noop);
    }, []);

    const goToLocationsSummary = useCallback(() => {
        const isComplete = true;
        const toCollapseBuildingDetails = true;
        const isCancelled = true;

        if (!_.isEqual(locationToEdit, locationSubmission.value)) {
            showCancelModal().result.then(() => {
                completeEdit(isComplete, toCollapseBuildingDetails, isCancelled);
            });
        } else {
            completeEdit(isComplete, toCollapseBuildingDetails, isCancelled);
        }
    }, [completeEdit, locationSubmission, locationToEdit, showCancelModal]);

    const onSaveClicked = useCallback(() => {
        const quoteId = _.get(submissionVM.value, 'quoteID') || _.get(submissionVM.value, 'jobID');
        const sessionId = _.get(submissionVM.value, 'sessionUUID');

        CPCoverablesService.updateCPLocation(
            quoteId,
            locationSubmission.value,
            sessionId,
            authHeader
        ).then((response) => {
            const locations = _.get(submissionVM.value, LOCATION_PATH);
            const locationIndex = _.findIndex(locations, (loc) => {
                return loc.publicID === response.publicID;
            });
            locations.splice(locationIndex, 1, response);
            updateWizardData(submissionVM);
            completeEdit(true, true);
        });
    }, [submissionVM, locationSubmission, updateWizardData, completeEdit, authHeader]);

    const renderAlertTitle = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.editLocationAlertTitle}
                values={{
                    buildings: (
                        <strong>
                            {translator(messages.buildingsCount, {
                                numberOfBuildings: locationToEdit.buildings.length
                            })}
                        </strong>
                    )
                }}
            />
        );
    }, [translator, locationToEdit]);

    const viewBuildingFormatMessage = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.clickToViewBuildings}
                values={{
                    link: (
                        <Button
                            className={styles.cpViewBuilding}
                            type="text"
                            onClick={goToLocationsSummary}
                        >
                            {translator(messages.viewBuildings)}
                        </Button>
                    )
                }}
            />
        );
    }, [translator, goToLocationsSummary]);

    const renderAlertListItems = useCallback(() => {
        return (
            <>
                <li>{viewBuildingFormatMessage()}</li>
                <li>{translator(messages.selectTheBuilding)}</li>
                <li>{translator(messages.expandLocation)}</li>
            </>
        );
    }, [translator, viewBuildingFormatMessage]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        editLocationAlertContainer: {
            visible: locationToEdit.buildings.length > 1
        },
        editLocationAlertMessage: {
            content: translator(messages.editLocationAlertMessage)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onCancelClicked: goToLocationsSummary,
            onSaveClicked: onSaveClicked
        },
        resolveComponentMap: {
            renderalerttitle: renderAlertTitle,
            renderalertlistitems: renderAlertListItems,
            viewbuildingformatmessage: viewBuildingFormatMessage
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={locationSubmission}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

EditLocationSummary.propTypes = {
    locationToEdit: PropTypes.shape({
        buildings: PropTypes.shape({
            length: PropTypes.shape({})
        })
    }).isRequired,
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    completeEdit: PropTypes.func.isRequired
};

export default EditLocationSummary;
