import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
WniCurrency,
WniDate,
WniDropdownSelect,
WniInputMask,
WniInputNumber,
WniInputText,
WniLookupField,
WniMultiSelect,
WniTextArea,
WniTypeaheadMultiSelect
} from '../FieldWithTooltip/FieldWithTooltip';

const ValidationError = (props = {}) => {
    const { showErrors, readOnly, validationMessages, className, layout } = props;
    if (!showErrors || !readOnly) {
        return null;
    }
    if(!(validationMessages && validationMessages.length > 0)) {
        return null;
    }

    return (
        <Flex gap='small' className={`${className} ${layout} fieldValidationError`}>
            <div className='fieldLabel' />
            <div className='fieldContent font-error-light-15'>
                {validationMessages.map((item) => (
                    <div>{item}</div>
                ))}
            </div>
        </Flex>
    );
    
};

const Action = (props) => {
    const { 
        children,
        actionFn = _.noop,
        actionVisible = true,
        actionConfig = {}
    } = props;
    const translator = useTranslator();

    const { type = 'text' } = actionConfig

    if(children) {
        return (
            <div className="fieldAction">{children}</div>
        );
    };
    if (!actionVisible) {
        return null;
    }
    return (
        <div className="fieldAction">
            <Button {...actionConfig} type={type} onClick={actionFn}>{translator(actionConfig.text)}</Button>
        </div>
    );
};

const ActionField = (FieldComponent) => {
    const components = (props) => {
        const {
            containerClassName
        } = props;
        const newComponentProps = _.omit(props, ['children', 'actionConfig', 'actionFn', 'actionVisible']);
        return (
            <div className={`fieldWithActionContainer ${containerClassName || ''}`}>
                <FieldComponent {...newComponentProps} />
                <Action {...props} />
                <ValidationError {...props} />
            </div>
        );
    };
    components.propTypes = {
        actionConfig: PropTypes.shape({}),
        children: PropTypes.arrayOf(PropTypes.element),
    };
    components.defaultProps = {
        children: null
    };
    return components;
};

export const WniInputTextWithAction = ActionField(WniInputText);
export const WniInputMaskWithAction = ActionField(WniInputMask);
export const WniInputNumberWithAction = ActionField(WniInputNumber);
export const WniDateWithAction = ActionField(WniDate);
export const WniCurrencyWithAction = ActionField(WniCurrency);
export const WniDropdownWithAction = ActionField(WniDropdownSelect);
export const WniTextAreaWithAction = ActionField(WniTextArea);
export const WniTypeaheadMultiSelectWithAction = ActionField(WniTypeaheadMultiSelect);
export const WniMultiSelectWithAction = ActionField(WniMultiSelect);
export const WniLookupFieldWithAction = ActionField(WniLookupField);