import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import _ from 'lodash';
import {
    useTranslator
} from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PolicyService, UserService } from 'gw-capability-gateway';
import { WniPolicyCoveragesService, WniPolicyService } from 'wni-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniJobUtil, WniDateUtil, WniProductsUtil } from 'wni-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { Link as LinkComponent } from 'gw-components-platform-react';
import {
    PoliciesSummaryDetail,
    PoliciesHomeDetails,
    PoliciesConstructionDetails,
    PoliciesProtectionDetails,
    PoliciesSummaryCoverages,
} from 'wni-capability-gateway-react';
import { useBusinessData } from 'wni-portals-util-react';
import CoveragesIterableComponent from 'gw-capability-gateway-react/Components/CoveragesIterableComponent/CoveragesIterableComponent';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import ChangePolicy from 'gw-capability-gateway-react/Policies/ChangePolicy/ChangePolicy';
import PolicyVehiclesDriversInfo from './pa/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo';
import Cancellation from '../Cancellation/PolicyCancellation';
import metadata from './PoliciesSummary.metadata.json5';
import styles from './PoliciesSummary.module.scss';

function getCell(items, index, property) {
    let retval = items[property.id];
    if (['policyDisplayStatus', 'status'].includes(property.id)) {
        retval = items[property.id] === 'Withdrawn' ? 'Deleted' : items[property.id];
    }
    if (['totalPremium'].includes(property.id)) {
        retval = items.status === 'Bound' ? items[property.id] : '-';
    }
    return retval;
}

function getAuditDetailsTableCell(items, index, property) {
    let retval = items[property.id];
    if(property.id.includes('Date') && items[property.id]) {
        retval = WniDateUtil.formatDateWithPattern(items[property.id])
    }
    if (['totalCost'].includes(property.id)) {
        retval = items[property.id] ? items[property.id] : '-';
    }
    return retval;
}

function transactionTypeIsViewableInXCenter(transaction) {
    return transaction.type.toLowerCase() !== 'reinstatement';
}

function canUserOpenJobInXCenter(transaction) {
    return transaction.canUserView && transactionTypeIsViewableInXCenter(transaction);
}

const {
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE
} = WniProductsUtil;
function PoliciesSummary(props) {
    const {
        fromAccountLanding: {
            policyDetailsData: {
                policyResponse = {},
            }
        },
        history,
        location: {
            state: locationState,
        },
    } = props;

    const {
        product: {
            productCode,
            productName
        } = {},
        policyNumber,
        account: {
            accountNumber
        } = {}
    } = policyResponse;
    const { authHeader } = useAuthentication();
    const { enableCommercialLine, getFeatureCodeValue } = useBusinessData();
    const { 
        interactionModel
    } = useDependencies(['interactionModel']);
    const { isAutoStartCancel } = locationState || '';

    const [policyData, updatePolicyData] = useState('');
    const [lobCoverages, setLobCoverages] = useState();
    const [coverableDisplayNameMap, setCoverableDisplayNameMap] = useState();
    const [accountContacts, setAccountContacts] = useState();
    const [isLobCoveragesLoading, setIsLobCoveragesLoading] = useState(false);
    const [disableCancelLink, updateDisableCancelLink] = useState(locationState ? locationState.disableCancelLink : true);
    const [disableChangePolicyLink, updateDisableChangePolicyLink] = useState(true);
    const [displayBtnGroup, updateDisplayBtnGroup] = useState(locationState ? locationState.displayBtnGroup : true);
    const [policyTransactionData, updatePolicyTransactionData] = useState([]);
    const [auditDetailsData, updateAuditDetailsData] = useState([]);
    const [policyTransactionStatusValues, updatePolicyTransactionStatusValues] = useState([]);
    const [selectedTransactionStatus, updateSelectedTransactionStatus] = useState('all');
    const [searchTransactionKeyword, updateSearchTransactionKeyword] = useState(undefined);
    const [canAdvanceCancellation, updateCanAdvanceCancellation] = useState(false);

    const [transactionResponse, updateTransactionResponse] = useState(undefined);

    const enablePOI53227GoLiveFeatureForPE = getFeatureCodeValue('EnablePOI53227GoLiveFeatureForPE');

    const getSearchFilterValues = (transactionData, keyword) => {
        const lowerCaseFilterValue = keyword.toLocaleLowerCase();
        return _.filter(transactionData, (res) => {
            return Object.keys(res).some((key) => typeof (res[key]) === 'string' && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue));
        });
    };

    const getMappedTransactionData = (policyTransactionResponse, filter) => {
        const selectedStatus = _.get(filter, 'selectedTransactionStatus');
        const searchKeyword = _.get(filter, 'searchTransactionKeyword');
        const transactionData = _.cloneDeep(policyTransactionResponse);

        let newPolicyTransactionData = transactionData.map((policy) => {
            return {
                ...policy,
                totalPremium: policy.totalPremium,
                policyEffectiveDate: WniDateUtil.formatDateWithPattern(policy.policyEffectiveDate)
            };
        }).sort((a, b) => {
            return new Date(b.policyEffectiveDate).getTime()
                - new Date(a.policyEffectiveDate).getTime();
        });
        if (selectedStatus && selectedStatus !== 'all') {
            newPolicyTransactionData = _.filter(newPolicyTransactionData, (transaction) => {
                return transaction.status === selectedStatus;
            });
        }
        if (searchKeyword) {
            newPolicyTransactionData = getSearchFilterValues(
                newPolicyTransactionData, searchKeyword
            );
        }
        updatePolicyTransactionData(newPolicyTransactionData);
    };


    const hasAdvanceCancellationPermission = async () => {
        const permissionDTO = {
            permission: 'advancecancellation'
        };
        const advCancellationPermission = await UserService.hasUserSystemPermission(permissionDTO, authHeader);
        updateCanAdvanceCancellation(advCancellationPermission);
    };

    const getPolicyTransactionStatus = () => {
        return [{
            code: 'all',
            name: gatewayMessages.all
        }, {
            code: 'Draft',
            name: {
                id: 'typekey.PolicyPeriodStatus.Draft',
                defaultMessage: 'Draft'
            }
        },
        {
            code: 'Bound',
            name: {
                id: 'typekey.PolicyPeriodStatus.Bound',
                defaultMessage: 'Bound'
            }
        },
        {
            code: 'Quoted',
            name: {
                id: 'typekey.PolicyPeriodStatus.Quoted',
                defaultMessage: 'Quoted'
            }
        },
        {
            code: 'Withdrawn',
            name: {
                id: 'typekey.PolicyPeriodStatus.Deleted',
                defaultMessage: 'Deleted'
            }
        }];
    };

    const getPolicyTransactions = async () => {
        const newTransactionResponse = await WniPolicyService.getPortalDisplayedPolicyTransactions(
            policyNumber, authHeader
        );
        getMappedTransactionData(newTransactionResponse);
        const newPolicyTransactionStatusValues = getPolicyTransactionStatus();
        updateTransactionResponse(newTransactionResponse);
        updatePolicyTransactionStatusValues(newPolicyTransactionStatusValues);
        updateSelectedTransactionStatus(newPolicyTransactionStatusValues[0].code);
    };

    const getAuditDetails = async () => {
        const auditDetailsResponse = await WniPolicyService.getAuditDetailsForPolicy(
            policyNumber, authHeader
        );
        const newAuditDetailsData = auditDetailsResponse.map((audit) => {
            return {
                ...audit,
                totalCost: audit.totalCost
            }
        })
        updateAuditDetailsData(newAuditDetailsData)
    };


    const setPolicyResponseToDetails = () => {
        const newPolicyData = _.cloneDeep(policyResponse);
        updatePolicyData(newPolicyData);
    };

    const getPolicyLobCoverages = async () => {
        const shouldCallLossPayees = productCode === HOP_PRODUCT_CODE;
        setIsLobCoveragesLoading(true);
        if (shouldCallLossPayees) {
            const [lobCoverageRes, coverableDisplayNameMapRes, accountContactsRes] = await Promise.all([
                WniPolicyCoveragesService.getPolicyLobCoverages(policyNumber, authHeader),
                WniPolicyCoveragesService.getCoverableDisplayNameMap(policyNumber, authHeader),
                WniPolicyCoveragesService.getAccountContacts(policyNumber, authHeader),
            ]);
            setLobCoverages(lobCoverageRes);
            setCoverableDisplayNameMap(coverableDisplayNameMapRes);
            setAccountContacts(accountContactsRes);
        } else {
            const [lobCoverageRes, coverableDisplayNameMapRes] = await Promise.all([
                WniPolicyCoveragesService.getPolicyLobCoverages(policyNumber, authHeader),
                WniPolicyCoveragesService.getCoverableDisplayNameMap(policyNumber, authHeader)
            ])
            setLobCoverages(lobCoverageRes);
            setCoverableDisplayNameMap(coverableDisplayNameMapRes);
        }
        setIsLobCoveragesLoading(false);
    }
    
    const handleCancelButtonClick = () => {
        updateDisableCancelLink(false);
        updateDisplayBtnGroup(false);
    };

    useEffect(() => {
        hasAdvanceCancellationPermission();
        setPolicyResponseToDetails();
        getPolicyTransactions();
        getPolicyLobCoverages();
        getAuditDetails();
        if (isAutoStartCancel) {
            handleCancelButtonClick();
        }
    }, []);

    const showOpenJobInXCenter = (item) => {
        switch (item) {
            case 'Submission':
            case 'PolicyChange':
            case 'Cancellation':
            case 'Renewal':
            case 'Rewrite':
                return true;
            default:
                return false;
        }
    };

    const getJobNumberLink = (item, index, property) => {
        if (canUserOpenJobInXCenter(item)) {
            if (!showOpenJobInXCenter(item.type)) {
                return <LinkComponent href="/" onClick={() => WniJobUtil.openJobInXCenter(item.jobNumber)}>{item[property.id]}</LinkComponent>;
            }
            return (
                <LinkComponent to={WniJobUtil.getJobDetailURLByJobType(item.type, item.jobNumber)}>
                    {item[property.id]}
                </LinkComponent>
            );
        }
        return (
            <span>{item.jobNumber}</span>
        );
    };


    const onChangePolicy = async (policyChangeSource) => {
        const source = _.isString(policyChangeSource) ? policyChangeSource : '';
        const { lobEndorsementURL, endorsementProducts } = appConfig;
       
        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode],
                { policyNumber, policyChangeSource: source });
        } else {
            updateDisableChangePolicyLink(false);
        }
    };

    const getJobNumber = async (policyNum) => {
        const renewalVM = await PolicyService.createNewPolicyRenewalTransaction(
            [policyNum], authHeader
        );
        return _.get(renewalVM, 'jobNumber');
    };

    const onRenewalPolicy = async () => {
        const { lobRenewalURL } = appConfig;
        const lobCode = _.get(policyResponse, 'latestPeriod.policyLines[0].lineOfBusinessCode');
        const jobNumber = await getJobNumber(policyNumber);
        if (!_.isEmpty(lobRenewalURL[lobCode])) {
            history.push(lobRenewalURL[lobCode], { jobNumber });
        } else {
            WniJobUtil.openJobInXCenter(jobNumber);
        }
    };

    const onDoNotCancel = () => {
        updateDisableCancelLink(true);
        updateDisplayBtnGroup(true);
        interactionModel.goToPage(null, history, 'policySummary', accountNumber, policyNumber);
    };

    const onDoNotChangePolicy = () => {
        updateDisableChangePolicyLink(true);
    };

    const handleFilterValueChange = (value) => {
        updateSelectedTransactionStatus(value);
        getMappedTransactionData(
            transactionResponse, { selectedTransactionStatus: value, searchTransactionKeyword }
        );
    };

    const handleSearchValueChange = (value) => {
        updateSearchTransactionKeyword(value);
        getMappedTransactionData(
            transactionResponse, { searchTransactionKeyword: value, selectedTransactionStatus }
        );
    };

    const shouldDisplayCoveragesField = () => {
        return (WniProductsUtil.isCLProduct(productCode)
            || (enableCommercialLine && WniProductsUtil.isDPOrHOProduct(productCode)))
            && disableCancelLink;
    };

    const generateOverrides = () => {
        const overrides = policyData.coverables.map((coverable, i) => {
            return {
                [`namesGrid${i}`]: {
                    visible: !_.isEmpty(coverable.coverages)
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    const homeDetailsVisible = () => {
        return disableCancelLink && WniProductsUtil.isDPOrHOProduct(productCode) && enableCommercialLine
    }

    const getHomeDetailsModel = () => {
        switch(productCode){
            case HOP_PRODUCT_CODE:
                return _.get(policyResponse, 'lobs.homeOwners', {});
            default:
                return _.get(policyResponse, `lobs.${_.lowerFirst(productCode)}`, {});
        }
    };

    const render = () => {
        if (_.isEmpty(policyData)) {
            return null;
        }

        const isCLProduct = WniProductsUtil.isCLProduct(productCode);

        const overrides = {
            summaryPolicyTransactionsTableGrid: {
                data: policyTransactionData
            },
            summaryAuditDetailsTable: {
                visible: isCLProduct,
                data: auditDetailsData
            },
            summaryDetail: {
                model: policyResponse,
                visible: disableCancelLink && (isCLProduct || enableCommercialLine)
            },
            cancellationContacts: {
                visible: !disableCancelLink && disableChangePolicyLink,
                history,
                authHeader,
                policyNumber: policyNumber,
                policyData: policyResponse,
                onChangePolicy
            },
            changePolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink || (isCLProduct && !enablePOI53227GoLiveFeatureForPE),
                visible: !policyData.latestPeriod.canceled && policyData.latestPeriod.canChange
            },
            cancelPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink || (isCLProduct && !enablePOI53227GoLiveFeatureForPE),
                visible: !policyData.latestPeriod.canceled
                    && policyData.latestPeriod.canCancel && canAdvanceCancellation
            },
            policyVehicleInfo: {
                visible: (policyData.product.productCode === PA_PRODUCT_CODE),
                value: policyData.lobs.personalAuto
            },
            policyHouseOwnersInfo: {
                visible: productCode === HO_PRODUCT_CODE || productCode === HOP_PRODUCT_CODE,
                value: policyData.lobs.homeOwners
            },
            policyTransactionStatus: {
                availableValues: policyTransactionStatusValues,
                value: selectedTransactionStatus
            },
            searchFilter: {
                value: searchTransactionKeyword
            },
            producerOfRecordValueId: {
                value: `${_.get(policyResponse, 'latestPeriod.producerCodeOfRecordOrg')}(${_.get(policyResponse, 'latestPeriod.producerCodeOfRecord')})`
            },
            producerOfServiceValueId: {
                value: `${_.get(policyResponse, 'latestPeriod.producerCodeOfServiceOrg')}(${_.get(policyResponse, 'latestPeriod.producerCodeOfService')})`
            },
            policyChangeButtonGroup: {
                visible: displayBtnGroup
            },
            policyTransactionContainer: {
                visible: disableCancelLink
            },
            homeDetails: {
                visible: homeDetailsVisible()
            },
            policiesHomeDetails: {
                model: getHomeDetailsModel(productCode)
            },
            constructionDetails: {
                visible: homeDetailsVisible()
            },
            policiesConstructionDetails: {
                model: getHomeDetailsModel(productCode)
            },
            protectionDetails: {
                visible: homeDetailsVisible()
            },
            policiesProtectionDetails: {
                model: getHomeDetailsModel(productCode)
            },
            // coverages: {
            //     visible: shouldDisplayCoveragesField()
            // },
            auditDetails: {
                visible: disableCancelLink && isCLProduct
            },
            policiesSummaryCoverages: 
            {
                visible: shouldDisplayCoveragesField(),
                isLobCoveragesLoading: isLobCoveragesLoading,
                lobCoverages: lobCoverages,
                accountContacts,
                coverableDisplayNameMap: coverableDisplayNameMap,
            },
            descriptionColumnTitle: {
                visible: homeDetailsVisible() || productCode === PA_PRODUCT_CODE
            },
            ...generateOverrides()
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: getCell,
                getAuditDetailsTableCell: getAuditDetailsTableCell,
                getJobNumberLink: getJobNumberLink,
                onChangePolicy: onChangePolicy,
                onRenewalPolicy: onRenewalPolicy,
                handleCancelButtonClick: handleCancelButtonClick,
                onDoNotCancel: onDoNotCancel,
                handleFilterValueChange: handleFilterValueChange,
                handleSearchValueChange: handleSearchValueChange,
                onDoNotChangePolicy: onDoNotChangePolicy
            },
            resolveComponentMap: {
                cancelcomponent: Cancellation,
                policiesSummaryDetail: PoliciesSummaryDetail,
                policiesHomeDetails: PoliciesHomeDetails,
                policiesConstructionDetails: PoliciesConstructionDetails,
                policiesProtectionDetails: PoliciesProtectionDetails,
                PolicyVehiclesDriversInfo: PolicyVehiclesDriversInfo,
                changepolicycomponent: ChangePolicy,
                coveragesiterablecomponent: CoveragesIterableComponent,
                PoliciesSummaryCoverages: PoliciesSummaryCoverages
            },
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, policyData, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyData}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        );
    };

    return render();
}

PoliciesSummary.propTypes = {
    fromAccountLanding: PropTypes.shape({
        policyDetailsData: PropTypes.shape({
            policyResponse: PropTypes.shape({
                policyNumber: PropTypes.string
            })
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            disableCancelLink: PropTypes.bool,
            disableChangePolicyLink: PropTypes.bool,
            displayBtnGroup: PropTypes.bool,
        }),
    }).isRequired,
};

export const SummaryComponent = PoliciesSummary;


// export default withAuthenticationContext(Summary);
export default PoliciesSummary;
