
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';

import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';

import {
    WniPlatformMessages,
    QuoteCommonMessages,
} from 'wni-platform-translations';
import { useWniModal } from 'wni-components-platform-react';
import { ReferToUnderwriterComponent } from 'wni-capability-gateway-react';


import messages from './VirtualInspectionNotificationComponent.messages';
import metadata from './VirtualInspectionNotificationComponent.metadata.json5';
import styles from './VirtualInspectionNotificationComponent.module.scss';

/**
 * A new version of Virtual Inspection Report that also creates note.
 * 
 * @param {object} props
 * @returns {object} React Component
 */
function VirtualInspectionNotificationComponent(props) {
    const modalApi = useWniModal();
    const {
        onVirtualInspection,
        onCancelVirtualInspection: onCancelVirtualInspectionImpl,
        isServiceCallInProgress,
    } = props;

    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    // const referToUWBtnLebel = translator(messages.okBtnLabel);
    const referToUWBtnLebel = translator(QuoteCommonMessages.referToUnderwriter);


    useEffect(() => {
    }, []);

    const onCancelVirtualInspection = useCallback(async () => {
        const result = await modalApi.showConfirm({
            title: WniPlatformMessages.wantToSaveAndExit,
            message: messages.confirmCancelMsg,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: WniPlatformMessages.saveAndExit,
            cancelButtonText: WniPlatformMessages.Cancel
        }).catch(_.noop); // no-op for 'close' thrown as exception
        // Possible outcome from ModalDialog: confirm, cancel, or close
        // Note that "close" will be thrown out and handled by .catch(),
        // hence the result is undefined in this scenario.
        if (result !== PortalConstants.MODAL_DIALOG_RESULT_CONFIRM) {
            return;
        }

        // ref: CommonTransactionWizard::onLeaveWizard()
        onCancelVirtualInspectionImpl();
        
    }, []);


    const onReferToUnderwriter = useCallback(async (noteForUW) => {
        await onVirtualInspection(noteForUW)
    }, []);

    const getButtonRenderFunc = useCallback((type, issue) => {
        return (
            <div className="d-flex flex-direction-row-reverse justify-content-center mt-10">
                
                {/* <Button
                    className="ml-10"
                    type="outlined"
                    id="cancelVirtualInspectionReportBtn"
                    onClick={onCancelVirtualInspection}
                    disabled={isServiceCallInProgress}
                >
                    {translator(WniPlatformMessages.No)}
                </Button> */}
                {/* <Button
                    id="callVirtualInspectionReportBtn"
                    onClick={onVirtualInspection}
                    disabled={isServiceCallInProgress}
                >
                    {translator(messages.okBtnLabel)}
                </Button> */}
                <ReferToUnderwriterComponent
                    onReferToUnderwriter={onReferToUnderwriter}
                    referToUWBtnLabel={referToUWBtnLebel}
                />
            </div>
        );
    }, [onVirtualInspection, isServiceCallInProgress]);



    //
    const displayContent = [
        {
            type: 'warning',
            reason: translator(messages.furtherReviewRequired),
        }
    ];


    // return (
    //     <ValidationIssuesComponent
    //         issuesContainerId='virtualInspectionNotificationContainer'
    //         validationIssues={displayContent}
    //         typeFooterFormatter={getButtonRenderFunc}
    //         specificBgClass= "gw-issues-nobg without-icon"
    //     />
    // );

    return (
        <div>
            {/* <ValidationIssuesComponent
                issuesContainerId='virtualInspectionNotificationContainer'
                validationIssues={displayContent}
                // typeFooterFormatter={getButtonRenderFunc}
                specificBgClass= "gw-issues-nobg without-icon"
            /> */}
            <ReferToUnderwriterComponent
                onReferToUnderwriter={onReferToUnderwriter}
                referToUWBtnLabel={referToUWBtnLebel}
            />
        </div>
    );
    //---------
    // return MetadataContent(metadata.componentContent, override, resolvers);
    // return (
    //     <ViewModelForm
    //         uiProps={metadata.componentContent}
    //         model={dtoVM}
    //         overrideProps={overrideProps}
    //         classNameMap={resolvers.resolveClassNameMap}
    //         callbackMap={resolvers.resolveCallbackMap}
    //         componentMap={resolvers.resolveComponentMap}
    //         // onModelChange={updateModelValue}
    //         resolveValue={readValue}
    //         onValueChange={writeValue}
    //         showErrors={showErrors}
    //     />
    // );
}

VirtualInspectionNotificationComponent.propTypes = {
    onVirtualInspection: PropTypes.func,
    onCancelVirtualInspection: PropTypes.func,
    //
    isServiceCallInProgress: PropTypes.bool,
};

VirtualInspectionNotificationComponent.defaultProps = {
    onVirtualInspection: _.noop,
    onCancelVirtualInspection: _.noop,
    //
    isServiceCallInProgress: false,
};

export default VirtualInspectionNotificationComponent;
