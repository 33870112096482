import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MetadataContent } from '@jutro/uiconfig';
import { Icon, IconButton } from '@jutro/components';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { withTracking } from '@xengage/gw-portals-tracking-react';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { WizardConstants } from 'wni-portals-config-js';
import { WizardErrorContext } from 'wni-portals-wizard-react';
import {
    AsteriskIsRequiredNotification,
    ConsolidatedValidationIssuesComponent,
} from 'wni-components-platform-react';

import metadata from './WizardPageErrorComponent.metadata.json5';
// import singleErrorStyles from './WizardSingleErrorComponent.module.scss';

function WizardPageErrorComponent(props) {

    const wizardContext = useContext(WizardContext);
    
    const wizardErrorContext = useContext(WizardErrorContext);

    if (!wizardErrorContext) {
        return null;
    }
    
    const {
        wizardPageData,
        currentStep: { id: currentPageId},
    } = wizardContext;

    const {
        //
        showRequiredInfo: showRequiredInfoForFasterQuote,
        showEntityNameInIssues: showEntityNameInPageLevelIssues,
        getRequiredInfoMessage,
        // Page Level
        getWizardPageIssues,
        getWizardPageStickyIssues,
        // Wizard Level
        wizardIssues: wizardLevelValidationIssues,
    } = wizardErrorContext;

    const pageLevelRequiredInfoMessage = getRequiredInfoMessage(currentPageId);
    const pageLevelValidationIssues = getWizardPageIssues(currentPageId);
    const pageLevelStickyValidationIssues = getWizardPageStickyIssues(currentPageId);

    const consolidatedIssuesToDisplay = [
        ...wizardLevelValidationIssues,
        ...pageLevelStickyValidationIssues,
        ...pageLevelValidationIssues,
    ];

    const override = {
        '@all': {

        },
        '@field': {

        },
        '@element': {

        },
        requiredForIssuanceMsg: {
            visible: showRequiredInfoForFasterQuote,
            notificationMessage: pageLevelRequiredInfoMessage,
        },
        pageErrorsAndWarnings: {
            validationIssues: consolidatedIssuesToDisplay,
            showEntityNameInPageLevelIssues,
            issueDistinguisher: true
        },
    };

    const resolvers = {
        resolveClassNameMap: {},
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            asteriskisrequired: AsteriskIsRequiredNotification,
            validationissues: ConsolidatedValidationIssuesComponent,
        },
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={override}
            {...resolvers} />
    );

}

WizardPageErrorComponent.propTypes = {
    // acknowledgeError: PropTypes.func.isRequired,
    // issuesList: PropTypes.shape([])
};
WizardPageErrorComponent.defaultProps = {
    // issuesList: []
};
// export default withTracking(WizardPageErrorComponent);
export default WizardPageErrorComponent;
