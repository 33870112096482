import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { WindowUtil, WniPNIUtil, WniUMBDriverUtil } from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { AddressInputComponent, PersonalAccountContactInputComponent, AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues } from 'wni-capability-gateway-react';
import UMBDriverComponent from '../UMBDriverComponent/UMBDriverComponent'
import metadata from './PUPrimaryNamedInsuredInputPopup.metadata.json5';
import messages from './PUPrimaryNamedInsuredInputPopup.messages';
import styles from './PUPrimaryNamedInsuredInputPopup.module.scss';

function PUPrimaryNamedInsuredInputPopup(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        primaryNamedInsuredVM,
        umbDriverAsPNIVM, 
        umbDriversVM,
        policyNamedInsuredCandidatesVM,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        //
        readOnly: allFieldsReadOnly,
        disablePNISwitch,
        showEmployeeFlagToggle,
        useAuthenticationData
    } = props;
    const modalApi = useWniModal();
    const { authHeader } = useAuthenticationData;
    const translator = useTranslator();
    const [PNIVM, updatePNIVM] = useState(primaryNamedInsuredVM);
    const [driverVM, updateDriverVM] = useState(umbDriverAsPNIVM)
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isAddressFlag, updateAddressFlag] = useState(false);
    // const PNIState = _.get(PNIVM.value, 'primaryAddress.state')
    const {
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('PrimaryNamedInsuredInputPopup');

    // const contactType = _.get(primaryNamedInsuredVM.value, 'contactType_Ext')

    const isAddressValid = useCallback(() => {
        return WniPNIUtil.isNameAndAddressValid(PNIVM)
    }, [PNIVM]);

    const isUMBDriverValid = useCallback(() => {
        return WniUMBDriverUtil.isDriverValid(driverVM.value)
       }, [driverVM])

    const updateLookupValidation = useCallback((validations) => {
        const validationsMap = getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    }, [validationIssues]);

    useEffect(() => {
        registerComponentValidation(isAddressValid);
        registerComponentValidation(isUMBDriverValid)
    }, [registerComponentValidation, isAddressValid, isUMBDriverValid]);

    const writeValue = useCallback((value, path) => {
        if (AddressChangeVerify(path, 'primaryAddress')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateAddressFlag(false);
        }
        const newVM = viewModelService.clone(PNIVM);
        _.set(newVM, path, value);
        updatePNIVM(newVM);
    }, [PNIVM, viewModelService, updateLookupValidation]);

    const onUMBDriverChange = useCallback((value, path) => {
        const newVM = viewModelService.clone(driverVM);
        _.set(newVM, path, value);
        updateDriverVM(newVM);
    }, [driverVM, viewModelService])

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const handleSave = useCallback(
        async () => {
            if (!isComponentValid) {
                handleValidation();
                return false;
            }
            const uiUtil = AddressVerifiedUtil({
                authHeader,
                addressVM: _.get(PNIVM, 'primaryAddress'),
                addressPath: 'primaryAddress',
                updateValidations: updateLookupValidation,
                writeValue,
                isAddressFlag,
                updateAddressFlag: updateAddressFlag,
                doublePopup: true,
                //
                modalApi,
            });
            const verifiedObj = await uiUtil.onVerified();
            if (!verifiedObj.isVerified) {
                return false;
            }
            _.set(PNIVM.value, 'primaryAddress', verifiedObj.addressValue);
            updatePNIVM(PNIVM);
            _.set(driverVM.value, 'primaryPhoneType', _.get(PNIVM.value, 'primaryPhoneType'));
            _.set(driverVM.value, 'cellNumber', _.get(PNIVM.value, 'cellNumber'));
            _.set(driverVM.value, 'homeNumber', _.get(PNIVM.value, 'homeNumber'));
            _.set(driverVM.value, 'workNumber', _.get(PNIVM.value, 'workNumber'));
            _.set(driverVM.value, 'emailAddress1', _.get(PNIVM.value, 'emailAddress1'));
            _.set(driverVM.value, 'occupation_Ext', _.get(PNIVM.value, 'occupation_Ext'));
            _.set(driverVM.value, 'occupationExplain_Ext', _.get(PNIVM.value, 'occupationExplain_Ext'));
            _.set(driverVM.value, 'primaryAddress', _.get(PNIVM.value, 'primaryAddress'));
            const PUPrimaryNamedInsuredcomponentVM = {
                PNIVM, 
                driverVM
            }
            onResolve(PUPrimaryNamedInsuredcomponentVM);
        },
        [isComponentValid, authHeader, PNIVM, updateLookupValidation, writeValue, isAddressFlag, modalApi, onResolve, driverVM, handleValidation]
    );

    const getPolicyNamedInsuredCandidatesAvailableItems = useCallback(() => {
        const policyNamedInsuredCandidates = _.get(policyNamedInsuredCandidatesVM, 'value', []);
        return policyNamedInsuredCandidates.map((policyNamedInsuredCandidate) => {
            return {
                code: policyNamedInsuredCandidate.publicID,
                name: policyNamedInsuredCandidate.publicID === _.get(PNIVM.value, 'publicID')
                    ? `${_.get(PNIVM.value, 'firstName')} ${_.get(PNIVM.value, 'lastName')}`
                    : `${policyNamedInsuredCandidate.firstName} ${policyNamedInsuredCandidate.lastName}`,

            };
        });
    }, [policyNamedInsuredCandidatesVM, PNIVM]);

    const handlePNIChange = useCallback((value) => {
        if (value === _.get(PNIVM.value, 'publicID', null)) {

            return;
        }
        const newNamedInuseredVMIndex = _.get(policyNamedInsuredCandidatesVM, 'value', []).findIndex(
            (policyNamedInsuredCandidate) => policyNamedInsuredCandidate.publicID === value
        );
        const newNamedInuseredVM = policyNamedInsuredCandidatesVM
            .getElement(newNamedInuseredVMIndex);
        updatePNIVM(newNamedInuseredVM);
        // find mapping contact in umbDriverDTO,
        // if not create new
        const driverSameAsPNIVM = umbDriversVM.children.find((umbDriverVM) => 
            _.get(umbDriverVM.value, 'mappingContactPublicID') === _.get(newNamedInuseredVM.value, 'publicID')
        )
        // const driverSameAsPNI = umbDriversVM[driverSameAsPNIIndex]
        if (driverSameAsPNIVM) {
            updateDriverVM(driverSameAsPNIVM)
        } else {
            const newSelectedDriverVM = viewModelService.create(
                {},
                'pc',
                'wni.edge.capabilities.quote.lob.personalumbrella.dto.UMBDriverDTO'
            );
            updateDriverVM(newSelectedDriverVM)
        }
        
    }, [PNIVM, policyNamedInsuredCandidatesVM, umbDriversVM, viewModelService]);


    const overrideProps = {
        // '@all': {
        // },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            showErrors,
            readOnly: allFieldsReadOnly,
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        fullNameDropdownSelect: {
            availableValues: getPolicyNamedInsuredCandidatesAvailableItems(),
            value: _.get(PNIVM.value, 'publicID'),
            // readOnly: disablePNISwitch,
            disabled: disablePNISwitch,
            visible: true
        },
        pniInfoContainer: {
            model: PNIVM,
            onValueChange: writeValue,
            showErrors,
            disablePNISwitch,
            showEmployeeFlagToggle,
            visibleFields: {
                showDateOfBirth: false,
            }
        },
        contactDetailsCard: {
            title: translator(messages.contactDetails)
        },
        primaryAddressCard: {
            title: translator(messages.primaryAddress)
        },
        primaryAddressContainer: {
            model: PNIVM,
            dataPath: 'primaryAddress',
            onAddressChange: writeValue,
            hideFieldType: {
                country: true,
                addressType: true
            },
            onValidate,
            useAuthenticationData
        },
        umbDriverContainer: {
            model: driverVM,
            // dataPath: 'primaryAddress',
            onValueChange: onUMBDriverChange,
            // hideFieldType: {
            //     country: true,
            //     addressType: true
            // },
            onValidate,
            useAuthenticationData
        },
    };

    const resolvers = {
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent,
            validationissuescomponent: ValidationIssuesComponent,
            accountcontactdetailscomponent: PersonalAccountContactInputComponent,
            umbdrivercomponent: UMBDriverComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFullNameChange: handlePNIChange,
        }

    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title || translator(messages.title)} />
            <ModalBody id="primaryNamedInsuredModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={PNIVM}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined" className={styles.cancelBtnMargin}>
                    {cancelBtnLabel || translator(messages.cancel)}
                </Button>
                <Button onClick={handleSave}>
                    {actionBtnLabel || translator(messages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PUPrimaryNamedInsuredInputPopup.propTypes = {
    primaryNamedInsuredVM: PropTypes.shape({}).isRequired,
    policyNamedInsuredCandidatesVM: PropTypes.shape({
        getElement: PropTypes.func.isRequired,
    }).isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    //
    readOnly: PropTypes.bool,
    disablePNISwitch: PropTypes.bool,
    showEmployeeFlagToggle: PropTypes.bool,
};

PUPrimaryNamedInsuredInputPopup.defaultProps = {
    actionBtnLabel: undefined,
    cancelBtnLabel: undefined,
    size: 'md',
    //
    readOnly: false,
    disablePNISwitch: false,
    showEmployeeFlagToggle: true,
};

export default withAuthenticationContext(PUPrimaryNamedInsuredInputPopup);
