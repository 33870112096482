
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { WniAccountService } from 'wni-capability-gateway';
import { WniQuoteVehicleService } from 'wni-capability-quote';
import { HOAddIntService } from 'wni-capability-quoteandbind-ho';
import { DPAddIntService } from 'wni-capability-quoteandbind-dp';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import BaseAdditionalInterestLineDetailComponent from '../BaseAddILineDetailComponent/BaseAdditionalInterestLineDetailComponent';
import messages from './BaseAdditionalInterestDetailComponent.messages'
import metadata from './BaseAdditionalInterestDetailComponent.metadata.json5';
import styles from './BaseAdditionalInterestDetailComponent.module.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function BaseAdditionalInterestDetailComponent(props) {
    const {
        model: additionalInterestDTOVM,
        emptyVM,
        onValueChange,
        showErrors,
        sessionUUID,
        jobID,
        handleSaveAdditionalInterestFn,
        handleCancelFn,
        accountContacts,
        jobType,
        accountNumber,
        viewEditType,
        workflowType,
        handleAdditionalInterestTypeValueChangeFn,
        handleCustomActionFooterFn,
        showLoanNumber,
        isReadOnly,
        productCode,
        cpProductParams, // only for Commercial Property product
        showAdditionalInterestInsuredType,
        showAdditionalInterestPleaseExplain,
        showAdditionalInterestSpecificInterest,
        showAdditionalInterestNameOfSchool,
        handleAdditionalInterestInsuredTypeValueChangeFn
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const writeValue = (value, path) => {
        onValueChange(value, path);
    };

    const getAdditionalInterestInsuredTypeOptions = useCallback(async () => {
        let options;
        try {
            switch (jobType) {
                case 'Submission':
                case 'PolicyChange':
                    if (productCode === 'dwellingProperty') {
                        // DP
                        options = await DPAddIntService.getAdditionalInterestInsuredType(
                            jobID, sessionUUID, authHeader
                        );
                    }
                    break;
                default: break;
            }
        } catch (e) {
            options = [];
        }
        return options;
    }, [authHeader, jobID, jobType, productCode, sessionUUID]);

    const getAdditionalInterestTypeOptions = useCallback(async ({
        publicID, isPerson, isCompany, isBank, isTrust
    }) => {
        let options;
        try {
            switch (jobType) {
                case 'Submission':
                case 'PolicyChange':
                    if (productCode === 'dwellingProperty') {
                        // DP
                        options = await DPAddIntService.getAdditionalInterestType(
                            jobID, sessionUUID, publicID, isPerson,
                            isCompany, isBank, isTrust, "DwellingProperty", authHeader
                        );
                    } else {
                        // original HO
                        options = await HOAddIntService.getAdditionalInterestType(
                            jobID, sessionUUID, publicID, isPerson,
                            isCompany, isBank, isTrust, authHeader
                        );
                    }
                    
                    // add FIRSTMORTGAGEE to options only when the value is it and currently isBank
                    if (_.get(additionalInterestDTOVM, 'value.type') === 'FIRSTMORTGAGEE'
                        && isBank
                        && !options.includes(_.get(additionalInterestDTOVM, 'value.type'))
                    ) {
                        options.push(_.get(additionalInterestDTOVM, 'value.type'));
                    }
                    break;
                default: break;
            }
        } catch (e) {
            options = [];
        }
        return options;
    }, [additionalInterestDTOVM, authHeader, jobID, jobType, productCode, sessionUUID]);

    const getProductAdditionalInterestTypeOptions = useCallback((inputParams) => {
        if (productCode === 'commercialProperty') {
            const {
                selectedAdditionalInterest
            } = inputParams;
            return cpProductParams.getAdditionalInterestTypeOptions(selectedAdditionalInterest);
        }
        const {
            publicID, isPerson, isCompany, isBank, isTrust
        } = inputParams;
        return getAdditionalInterestTypeOptions({
            publicID, 
            isPerson, 
            isCompany, 
            isBank, 
            isTrust
        });
    }, [getAdditionalInterestTypeOptions, cpProductParams, productCode]);

    const saveAdditionalInterestFn = useCallback(async (getAdditionalInterestVM) => {
        await handleSaveAdditionalInterestFn(getAdditionalInterestVM);
    }, []);

    const cancelAddAdditionalInterestFn = useCallback(() => {
        handleCancelFn();
    }, []);

    const additionalInterestInsuredTypeValueChangeFn = useCallback((
        newValue,
        innerViewModelService,
        getAdditionalInterestVM,
        updateAdditionalInterestVM,
        innerWriteValue
    ) => {
        handleAdditionalInterestInsuredTypeValueChangeFn(
            newValue,
            innerViewModelService,
            getAdditionalInterestVM,
            updateAdditionalInterestVM,
            innerWriteValue
        );
    }, [handleAdditionalInterestInsuredTypeValueChangeFn]);

    const additionalInterestTypeValueChangeFn = useCallback((
        newValue,
        innerViewModelService,
        getAdditionalInterestVM,
        updateAdditionalInterestVM,
        innerWriteValue
    ) => {
        handleAdditionalInterestTypeValueChangeFn(
            newValue,
            innerViewModelService,
            getAdditionalInterestVM,
            updateAdditionalInterestVM,
            innerWriteValue
        );
    }, [handleAdditionalInterestTypeValueChangeFn]);

    const customActionFooterFn = useCallback((
        companyType, showBankDetail, readOnly,
        showBankSearch, newSearchBtnLabel, onReject, cancelBtnLabel,
        handleSave, actionBtnLabel, selectRow, handleSelectBank,
        getAdditionalInterestVM, isComponentValid, handleValidation
    ) => {
        return handleCustomActionFooterFn(companyType, showBankDetail, readOnly,
            showBankSearch, newSearchBtnLabel, onReject, cancelBtnLabel,
            handleSave, actionBtnLabel, selectRow, handleSelectBank,
            getAdditionalInterestVM, isComponentValid, handleValidation);
    }, []);

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        accordionSecion: {
            className: styles.marginTop,
            accordionStates: [
                'additionalInterestDetailsAccordionContainer'
            ],
            defaultOpenedId: 'additionalInterestDetailsAccordionContainer',
        },
        additionalInterestDetails: {
            title: 'title',
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            newSearchBtnLabel: 'New Search',
            additionalInterestVM: additionalInterestDTOVM,
            emptyVM: emptyVM,
            viewModelService: viewModelService,
            accountNumber: accountNumber,
            accountContacts: accountContacts,
            accountHolderBirthday: null,
            authHeader,
            readOnly: isReadOnly,
            licenseState: '',
            type: viewEditType,
            getAdditionalInterestTypeOptions: getProductAdditionalInterestTypeOptions,
            isOpen: true,
            showBottomButtons: true,
            saveAdditionalInterestFn,
            cancelAddAdditionalInterestFn,
            workflowType,
            additionalInterestTypeValueChangeFn,
            customActionFooterFn,
            showLoanNumber,
            productCode,
            getAdditionalInterestInsuredTypeOptions,
            showAdditionalInterestInsuredType,
            showAdditionalInterestPleaseExplain,
            showAdditionalInterestSpecificInterest,
            showAdditionalInterestNameOfSchool,
            additionalInterestInsuredTypeValueChangeFn
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            additionalinterestdetailcomponent: BaseAdditionalInterestLineDetailComponent
        },
    };

    //---------
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={additionalInterestDTOVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
    );
}

BaseAdditionalInterestDetailComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    viewEditType: PropTypes.string,
    isReadOnly: PropTypes.bool
};

BaseAdditionalInterestDetailComponent.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
    viewEditType: '',
    isReadOnly: false
};

export default BaseAdditionalInterestDetailComponent;
