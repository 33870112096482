
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Link } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import messages from './ActionListComponent.messages';
import metadata from './ActionListComponent.metadata.json5';
import styles from './ActionListComponent.module.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const ActionListComponent = (props) => {
    const {
        actions,
        title,
        namePrefix,
    } = props;

    const translator = useTranslator();

    const renderedTitle = title.id ? translator(title) : title;
    const renderedPrefix = namePrefix.id ? translator(namePrefix) : namePrefix;

    useEffect(() => {
    }, []);

    //---------
    if (_.isEmpty(actions)) {
        return null;
    }
    return (
        <div className="mt-20">
            <h4 className="font-Primary-bold-18">{translator(renderedTitle)}</h4>
            <ul className="btn-link-lists">
                {
                    actions.map(({ name, action }) => {
                        // const linkTitle = `${renderedPrefix} ${name}`;
                        return (
                            <li key={name} className="listdisc">
                                <Link onClick={action}>
                                    <span>{renderedPrefix}</span>
                                    <strong>{name}</strong>
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

ActionListComponent.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        action: PropTypes.func,
    })).isRequired,
    title: PropTypes.string || PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
    }),
    namePrefix: PropTypes.string || PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
    }),
};

ActionListComponent.defaultProps = {
    title: '',
    namePrefix: '',
};

export default ActionListComponent;
