import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { Link, useModal } from '@jutro/components';
import styles from '../../Claims.module.scss';
import ClaimServicesVendorPopupMetaData from './VendorContactDetailsPopup.metadata.json5';
import metadata from './VendorLinkComponent.metadata.json5';

function VendorLinkComponent(props) {
    const modalApi = useModal();
    const { vendors } = props;

    const getDisplayValues = (item,path) => {
        if (path === "workNumber") {
            const number = _.get(item, path);
            return number ? `(${number.slice(0, 3)})-${number.slice(3, 6)}-${number.slice(6)}(Primary)` : number
        }
        if (path === "faxNumber") {
            const number = _.get(item, path);
            return number ? `(${number.slice(0, 3)})-${number.slice(3, 6)}-${number.slice(6)}` : number
        }
        return _.get(item, path);
    }
    const getServiceVendorPopupModalContent = useCallback((item) => {
        const resolvers = {
            resolveValue: (id, path) => getDisplayValues(item, path),
            resolveClassNameMap: styles
        };
        return (
            <MetadataContent
                uiProps={ClaimServicesVendorPopupMetaData.pageContent}
                overrideProps={{}}
                {...resolvers} />
        );
    }, []);

    const openVendorPopup = useCallback(
        (event, item) => {
            event.preventDefault();
            const displayName = _.get(item, 'contactDTO.displayName', '');
            const renderServiceVendorContent = getServiceVendorPopupModalContent(item.contactDTO);
            modalApi.showAlert({
                title: displayName,
                message: renderServiceVendorContent,
                status: 'info',
                icon: 'gw-error-outline'
            }).catch(_.noop);
        },
        [getServiceVendorPopupModalContent]
    );
    const getLinkComponent = useCallback((item) => {
        return (
            <Link onClick={(event) => openVendorPopup(event, item)} href="/">
                {item.contactDTO.displayName}
            </Link>
        );
    }, [openVendorPopup]);

    const generateOverrides = useMemo(() => {
        const overrides = vendors.map((item, index) => {
            return {
                [`serviceVendorLink${index}`]: {
                    content: getLinkComponent(item)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [vendors, getLinkComponent]);

    const overrides = {
        vendorsIterable: {
            data: vendors
        },
        ...generateOverrides
    };

    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrides} />;
}

VendorLinkComponent.propTypes = {
    vendors: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

VendorLinkComponent.defaultProps = {
    vendors: []
};

export default VendorLinkComponent;
