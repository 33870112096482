import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Loader,
} from '@jutro/components';
import { PUHouseholdInfoService } from 'wni-capability-quoteandbind-pu';
import { WindowUtil, WniPNIUtil, WniUMBDriverUtil } from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { PersonalAccountContactInputComponent, getValidationMap } from 'wni-capability-gateway-react';
import UMBDriverComponent from '../UMBDriverComponent/UMBDriverComponent'
import metadata from './PUEditResidentsInputPopup.metadata.json5';
import messages from './PUEditResidentsInputPopup.messages';
import styles from './PUEditResidentsInputPopup.module.scss';


function PUEditResidentsInputPopup(props) {
    const {
        title,
        size,
        nextBtnLabel,
        saveBtnLabel,
        cancelBtnLabel,
        driverVMList,
        selectedDriverPublicID,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        //
        readOnly: allFieldsReadOnly,
        // disablePNISwitch,
        showEmployeeFlagToggle,
        useAuthenticationData,
        policyAddress,
        pniFirstName,
        pniLastName
    } = props;

    const [list, updateList] = useState(driverVMList)

    const[driverPublicID, setDriverPublicID] = useState(selectedDriverPublicID)
    const dtoVM = list.find((elt) => elt.value.publicID === driverPublicID)

    // const modalApi = useWniModal();
    const { authHeader } = useAuthenticationData;
    const translator = useTranslator();
    // const [currentDriverVM, updateCurrentDriverVM] = useState(driverVM);
    // const [driverVM, updateDriverVM] = useState(umbDriverAsPNIVM)
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const {
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('PUEditResidentsInputPopup');

    const IsValidFields = useCallback(() => {
        const currentDriver = _.find(list, (driverVM) => {
            return driverVM.value.publicID === driverPublicID
        })
        if (currentDriver.aspects.valid && currentDriver.aspects.subtreeValid  && !_.isNil(currentDriver.value.isLicensedDriver) && !_.isNil(currentDriver.value.occupation_Ext)) {
            return true;
        }
        return false;
    }, [list]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, list]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const updateDtoVM = useCallback((newDtoVM) => {
        const newList = _.clone(list)
        const currentList = newList.map((item) => {
            if (item.value.publicID === dtoVM) {
                return newDtoVM
            }
            return item
        })
        updateList(currentList)
    }, [dtoVM, list])

    const handleSaveAndNext = () => {
        const currentIndex =  list.findIndex((elt) => elt.value.publicID === driverPublicID)
        _.set(list[currentIndex], 'primaryAddress', policyAddress)
        let nextIndex = ''
        if (currentIndex < list.length-1) {
            nextIndex = currentIndex+1
        } else {
            // when reach the last line,back to first one
            nextIndex = 0
        }
        const nextCustomPublicID= list[nextIndex].value.publicID
        setDriverPublicID(nextCustomPublicID)
    }

    const writeValue = useCallback((value, path) => {
        const newVM = viewModelService.clone(dtoVM);
        _.set(newVM, `value.${path}`, value);
        updateDtoVM(newVM);
    }, [viewModelService, dtoVM, updateDtoVM]);

    const handleSave = useCallback(() => {
        const listDto = list.map((elt) => elt.value)
        if (!isComponentValid) {
            updateShowErrors(true);
            return false;
        }
        // onResolve(_.get(dtoVM, 'value'));
        onResolve(listDto)
    }, [isComponentValid, list, onResolve]);


    const overrideProps = {
        // '@all': {
        // },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            showErrors,
            readOnly: allFieldsReadOnly,
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        infoContainer: {
            model: dtoVM,
            onValueChange: writeValue,
            showErrors,
            // disablePNISwitch,
            showEmployeeFlagToggle,
            onValidate,
            useAuthenticationData,
            visibleFields: {
                showMiddleName: false,
                showDateOfBirth: true,
                showPrimaryPhoneType: false,
                showPrimaryPhone: false,
                showPrimaryEmail: false,
                showAdditionalNamedInsured: true,
                showRelationshipToInsured: true
            },
            pniFirstName,
            pniLastName
        },
        umbDriverContainer: {
            model: dtoVM,
            // dataPath: 'primaryAddress',
            onValueChange: writeValue,
            // hideFieldType: {
            //     country: true,
            //     addressType: true
            // },
            onValidate,
            useAuthenticationData
            },
    };

    const resolvers = {
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            accountcontactdetailscomponent: PersonalAccountContactInputComponent,
            umbdrivercomponent: UMBDriverComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            // onFullNameChange: handlePNIChange,
        }

    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title || translator(messages.title)} />
            <ModalBody id="primaryNamedInsuredModal">
                <ViewModelForm
                        uiProps={metadata.componentContent}
                        model={dtoVM}
                        onValidationChange={onValidate}
                        onValueChange={writeValue}
                        overrideProps={overrideProps}
                        classNameMap={resolvers.resolveClassNameMap}
                        componentMap={resolvers.resolveComponentMap}
                        callbackMap={resolvers.resolveCallbackMap}
                />
                {/* { isLoading ? <Loader/> : 
                    <ViewModelForm
                        uiProps={metadata.componentContent}
                        model={currentDriverVM}
                        onValidationChange={onValidate}
                        onValueChange={writeValue}
                        overrideProps={overrideProps}
                        classNameMap={resolvers.resolveClassNameMap}
                        componentMap={resolvers.resolveComponentMap}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                } */}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined" className={styles.cancelBtnMargin}>
                    {cancelBtnLabel || translator(messages.cancel)}
                </Button>
                <Button onClick={isComponentValid ? handleSaveAndNext : handleValidation} disabled={list.length <= 1}>
                    {nextBtnLabel || translator(messages.next)}
                </Button>
                <Button onClick={isComponentValid ? handleSave : handleValidation} >
                    {saveBtnLabel || translator(messages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PUEditResidentsInputPopup.propTypes = {
    primaryNamedInsuredVM: PropTypes.shape({}).isRequired,
    policyNamedInsuredCandidatesVM: PropTypes.shape({
        getElement: PropTypes.func.isRequired,
    }).isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    //
    readOnly: PropTypes.bool,
    disablePNISwitch: PropTypes.bool,
    showEmployeeFlagToggle: PropTypes.bool,
};

PUEditResidentsInputPopup.defaultProps = {
    actionBtnLabel: undefined,
    cancelBtnLabel: undefined,
    size: 'md',
    //
    readOnly: false,
    disablePNISwitch: false,
    showEmployeeFlagToggle: true,
};

export default withAuthenticationContext(PUEditResidentsInputPopup);
