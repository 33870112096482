import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { Button, Chevron, Icon } from '@jutro/components';
import { Flex, FlexItem } from '@jutro/layout';
import styles from './LocationHeaderComponent.module.scss';
import messages from './LocationHeaderComponent.messages';

class LocationHeaderComponent extends Component {
    /**
     * @memberof gw-components-platform-react.LocationHeaderComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {Object} propTypes.location - value of the component
     * @prop {func} propTypes.onRemoveLocation - remove a location
     * @prop {string} propTypes.path - path to location
     * @prop {bool} propTypes.isEditable - is it editable
     * @prop {bool} propTypes.isAccordionOpen - is accordion open
     */

    static propTypes = {
        path: PropTypes.string,
        location: PropTypes.shape({
            displayName: PropTypes.string,
            buildings: PropTypes.arrayOf(PropTypes.shape({})),
            isPrimary: PropTypes.bool
        }),
        onRemoveLocation: PropTypes.func,
        isAccordionOpen: PropTypes.bool,
        isEditable: PropTypes.bool,
        locationIndex: PropTypes.number
    };

    static defaultProps = {
        path: undefined,
        location: undefined,
        isAccordionOpen: false,
        isEditable: true,
        onRemoveLocation: undefined,
        locationIndex: 0
    };

    removeLocation = () => {
        const { onRemoveLocation, path } = this.props;
        onRemoveLocation(path);
    };

    render() {
        const {
            location, isAccordionOpen, isEditable, locationIndex
        } = this.props;
        const { displayName, buildings, isPrimary } = location;

        const locationIconClass = classNames(styles.locationIcon, {
            [styles.primaryLocationIcon]: isPrimary
        });

        return (
            <TranslatorContext.Consumer>
                {(translator) => (
                    <Flex
                        gap="medium"
                        id="Flex"
                        justifyContent="left"
                        alignItems="center"
                        className={styles.locationHeaderContainer}
                    >
                        <FlexItem grow={0}>
                            <div className={styles.chevronContainer}>
                                <Chevron isOpen={isAccordionOpen} className={styles.chevron} />
                            </div>
                        </FlexItem>
                        <FlexItem grow={0}>
                            <Icon icon="mi-place" className={locationIconClass} />
                        </FlexItem>
                        <FlexItem grow={0} className={styles.locationName}>
                            <span className={styles.displayName}>{displayName}</span>
                            {isPrimary ? (
                                <span className={styles.primaryAddress}>
                                    {translator(messages.primaryAddress)}
                                </span>
                            ) : null}
                        </FlexItem>
                        <FlexItem
                            grow={2}
                            textAlign="center"
                            className={styles.buildingNumber}
                        >
                            <span>{buildings.length}</span>
                            <Icon icon="mi-business" />
                            {!isPrimary && isAccordionOpen && isEditable ? (
                                <div className={styles.buttonContainer}>
                                    <Button
                                        id={`removeLocation${locationIndex}`}
                                        className="actionIcon"
                                        onClick={this.removeLocation}
                                        icon="mi-delete"
                                        type="text"
                                    />
                                </div>
                            ) : null}
                        </FlexItem>
                    </Flex>
                )}
            </TranslatorContext.Consumer>
        );
    }
}

export default LocationHeaderComponent;
