import React, {
    // useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// import { useWniModal } from 'wni-components-platform-react';
import { GLPolicyDetailsChangeService } from 'wni-capability-policychange-gl';
import {
    ValidationIssueUtil,

    WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';

import moment from 'moment';
import {
    GLWizardPage as WizardPage
} from 'wni-capability-quoteandbind-gl-react';


import metadata from './GLPolicyDetailsChangePage.metadata.json5';


function GLPolicyDetailsChangePage(props) {

    // const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,

        savePolicyDetailsData,

    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
        // policyInfoSource_Ext: policyInfoSource,
        baseData,

    } = submissionVM.value;

    const {
        effectiveDate_Ext: transactionEffectiveDate
        // productCode
    } = baseData;

  
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('GLPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);


    const getMinDate = useCallback(() => {
        return systemDate
    }, [systemDate]);


    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                isReadOnly: true,
                labelPosition: 'left',
                showRequired: true
            },
            effectiveDate: {
                minDate: getMinDate(),
                showErrors
            },
        
            
        }
    }, [getMinDate, showErrors]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: {

        },
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage showPrevious={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

GLPolicyDetailsChangePage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
};

GLPolicyDetailsChangePage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: GLPolicyDetailsChangeService.saveGLPolicyDetailsData,
} 
export default GLPolicyDetailsChangePage;