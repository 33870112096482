import React, { useContext, useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    ViewModelForm,
    ViewModelServiceContext,
    withViewModelService,
} from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { withRouter } from 'react-router-dom';
import { SubmissionService } from 'gw-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WniSubmissionService } from 'wni-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ServiceManager } from '@jutro/services';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import {
    ValidationIssuesComponent,
    useWniModal,
} from 'wni-components-platform-react';
import { useProductsData } from 'wni-portals-util-react';
import { WindowUtil, ErrorsAndWarningsUtil } from 'wni-portals-util-js';
import metadata from './NewSubmissionPage.metadata.json5';
import messages from './NewSubmissionPage.messages';

const xCenter = 'pc';
const SUBMISSION_DTO_PATH =
    'edge.capabilities.gateway.job.submission.dto.NewSubmissionDTO';
function NewSubmissionPage(props) {
    const modalApi = useWniModal();
    const {
        accountHolder: { contactType_Ext: contactType, primaryAddress } = {},
        accountNumber,
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const localeService = ServiceManager.getService('locale-service');
    const { authHeader, authUserData } = useAuthentication();
    const { getProductsMap } = useProductsData();
    const { lobQuoteURL } = appConfig;
    const {
        producerCodes_Ext: producerCodes = [],
        businessData_Ext: { systemDate },
    } = authUserData;
    const {
        domainCompany,
        interactionModel,
        loadingMask: { setLoadingMask },
    } = useDependencies(['domainCompany', 'interactionModel', 'loadingMask']);
    const availableStatesForR1 = domainCompany.availableStates;

    const defaultCountryCode = localeService.getDefaultCountryCode();
    const [submissionVM, updateSubmissionVM] = useState({});
    const [validationIssues, updateValidationIssues] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const { history } = props;
    const {
        onValidate,
        isComponentValid,
        invalidFields
    } = useValidation('NewSubmissionPage');

    const initData = () => {
        setLoadingMask(true);
        const newSubmissionVM = viewModelService.create(
            {
                country: defaultCountryCode,
                accountNumber,
            },
            xCenter,
            SUBMISSION_DTO_PATH
        );
        updateSubmissionVM(newSubmissionVM);
        setLoadingMask(false);
    };

    useEffect(() => {
        if (accountNumber) {
            initData();
        }
    }, [accountNumber]);

    const handleCancel = () => {
        modalApi
            .showConfirm({
                title: messages.cancelQuote,
                message: messages.cancelMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok,
            })
            .then((result) => {
                if (result === 'cancel' || result === 'close') {
                    return _.noop();
                }
                if (accountNumber) {
                    interactionModel.goToPage(null, 
                        history,
                        'accountSummary',
                        accountNumber
                    );
                }
                return true;
            }, _.noop);
    };

    const startSubmission = async () => {
        setLoadingMask(true);
        const productCode = _.get(submissionVM, 'productCode.value');
        const submissionResponse = await SubmissionService.createSubmission(
            submissionVM.value,
            authHeader
        );
        const newValidationIssues = ErrorsAndWarningsUtil.getValidationIssues(
            _.get(submissionResponse, 'errorsAndWarnings_Ext')
        );
        const hasError = newValidationIssues.some((issue) => issue.type === 'error');
        if (hasError) {
            updateValidationIssues(newValidationIssues);
            setLoadingMask(false);
            return;
        };
        if (submissionResponse.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                const nextLocation = {
                    quoteentry: {
                        postalCode: _.get(primaryAddress, 'postalCode'),
                        quoteID: submissionResponse.jobNumber,
                    },
                };
                await WniSubmissionService.addRecentlyViewedSubmission(
                    submissionResponse.jobNumber,
                    authHeader
                );
                history.push(lobQuoteURL[productCode], nextLocation);
            } else {
                JobUtil.openJobInXCenter(submissionResponse.jobNumber);
            }
        }
        setLoadingMask(false);
    };
    const handleValidation = useCallback(() => {
        WindowUtil.scrollToInvalidField(invalidFields);
        updateShowErrors(true);
    }, [updateShowErrors, invalidFields]);

    const renderAvailableValues = () => {
        return producerCodes.map((item) => {
            return {
                ...item,
                name: `${item.code}-${item.description}-${item.city_Ext}, ${item.state_Ext}`,
                code: item.publicID,
            };
        });
    };

    const writeValue = (value, path) => {
        const newVM = viewModelService.clone(submissionVM);
        _.set(newVM, path, value);
        if(path === 'producerCode') {
            _.set(newVM, 'producerCodePublicID_Ext', value)
        }
        updateSubmissionVM(newVM);
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true
        },
        submissionState: {
            availableValues: availableStatesForR1
        },
        submissionDate: {
            minDate: systemDate,
            value: _.get(submissionVM.value, 'effectiveDate'),
            showErrors,
        },
        agencyAndAgentInfo: {
            availableValues: renderAvailableValues(),
        },
        submissionProductCode: {
            availableValues: getProductsMap(contactType),
            disabled: !_.get(submissionVM, 'producerCode.value')
        },
        cancel: {},
        next: {},
    };

    const resolvers = {
        resolveCallbackMap: {
            startSubmission: isComponentValid
                ? startSubmission
                : handleValidation,
            onCancel: handleCancel,
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent
        },
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValidationChange={onValidate}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

NewSubmissionPage.defaultProps = {
    isAnExistingAccount: false,
};
export default withRouter(withViewModelService(NewSubmissionPage));
