import React, { useCallback } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { Link, useModal } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import messages from '../../Claims.messages';
import styles from '../../Claims.module.scss';
import ClaimServicesPrimaryContactPopupMetaData from './ContactDetailsPopup.metadata.json5';


function ContactLinkComponent(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const contactDetails = _.get(props, 'contactDetails');

    const getFormatedPhoneNumber = (number) => {
        if (!_.isNil(number)) {
            return `(${number.slice(0, 3)})-${number.slice(3, 6)}-${number.slice(6)}`
        }
        return '-'
    }
    const getPrimaryMobilePhoneNumber = useCallback((item) => {
        if (item.cellNumber) {
            const formatedCellNumber = getFormatedPhoneNumber(item.cellNumber);
            if (item.primaryPhoneType === 'mobile') {
                return `${formatedCellNumber}(${translator(messages.modalPrimary)})`;
            }
            return formatedCellNumber;
        }
        return null;
    }, [translator]);

    const getPrimaryWorkPhoneNumber = useCallback((item) => {
        if (item.workNumber) {
            const formatedWorkNumber = getFormatedPhoneNumber(item.workNumber);
            if (item.primaryPhoneType === 'work') {
                return `${formatedWorkNumber}(${translator(messages.modalPrimary)})`;
            }
            return formatedWorkNumber;
        }
        return null;
    }, [translator]);

    const getPrimaryHomePhoneNumber = useCallback((item) => {
        if (item.homeNumber) {
            const formatedHomeNumber = getFormatedPhoneNumber(item.homeNumber);
            if (item.primaryPhoneType === 'home') {
                return `${formatedHomeNumber}(${translator(messages.modalPrimary)})`;
            }
            return formatedHomeNumber;
        }
        return null;
    }, [translator]);

    const getPrimaryContactPopupModalContent = useCallback((item) => {
        const isCompanyType = item.contactType === 'Company' || item.contactType === 'CompanyVendor';
        const overrides = {
            primaryContactHomeNumber: {
                value: getPrimaryHomePhoneNumber(item),
                visible: !isCompanyType
            },
            primaryContactWorkNumber: {
                value: getPrimaryWorkPhoneNumber(item),
                visible: !isCompanyType
            },
            primaryContactMobileNumber: {
                value: getPrimaryMobilePhoneNumber(item),
                visible: !isCompanyType
            },
            primaryContactName: {
                visible: isCompanyType
            },
            contactPhoneNumber: {
                value: getPrimaryWorkPhoneNumber(item) ||  getPrimaryMobilePhoneNumber(item),
                visible: isCompanyType
            },
            contactFaxNumber: {
                value: getFormatedPhoneNumber(item.faxNumber),
                visible: isCompanyType
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(
                ClaimServicesPrimaryContactPopupMetaData.pageContent,
                item,
                id,
                path,
                overrides
            );
        };

        const resolvers = {
            resolveValue: readValue,
            resolveClassNameMap: styles
        };
        return (
            <MetadataContent
                uiProps={ClaimServicesPrimaryContactPopupMetaData.pageContent}
                overrideProps={overrides}
                {...resolvers} />
        );
    }, []);

    const openContactPopup = useCallback(
        (event, item) => {
            event.preventDefault();
            const displayName = _.get(item, 'displayName', '');
            const renderServiceVendorContent = getPrimaryContactPopupModalContent(item);
            modalApi.showAlert({
                title: displayName,
                message: renderServiceVendorContent,
                status: 'info',
                icon: 'gw-error-outline'
            }).catch(_.noop);
        },
        [getPrimaryContactPopupModalContent]
    );

    return (
        <Link onClick={(event) => openContactPopup(event, contactDetails)} href="/">
            {contactDetails.displayName}
        </Link>
    );
}

export default ContactLinkComponent;
