import React, {
    useContext,
    useState,
    useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

import {
    CoverageSummaryComponent,
    DisplayableSummaryComponent,
} from 'wni-capability-gateway-react';


import CRJurisdictionsComponent from './CRJurisdictionsComponent';
/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function CRPolicyLineSummaryComponent(props) {

    const {
        policyLineSummary, // CRPollicyLineSummaryDTO;
    } = props;

    if (_.isEmpty(policyLineSummary)) {
        return null;
    }

    //
    const {
        lineLevelCoverages,
        coverages,
        exclusions,
        conditions,
        //
        jurisdictions
    } = policyLineSummary;


    return (
        <div>
            <DisplayableSummaryComponent title="Line-Level Coverages" displayables={lineLevelCoverages} />
            <CoverageSummaryComponent title="Coverages" clauses={coverages} />
            <CoverageSummaryComponent title="Exclusions" clauses={exclusions} />
            <CoverageSummaryComponent title="Conditions" clauses={conditions} />
            <CRJurisdictionsComponent jurisdictions={jurisdictions} />
        </div>
    );
    
}

CRPolicyLineSummaryComponent.propTypes = {
    
};

CRPolicyLineSummaryComponent.defaultProps = {
    
};


export default CRPolicyLineSummaryComponent;