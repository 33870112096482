import _ from 'lodash';
import React, {
    useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@jutro/components';

import { WindowUtil, WniAddressUtil } from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import metadata from './MailingAddressInputPopupComponent.metadata.json5';

import styles from './MailingAddressInputPopupComponent.module.scss';
import AddressInputComponent from '../AddressInputComponent/AddressInputComponent';

function MailingAddressInputPopupComponent(props) {
    const {
        isOpen = true,
        title,
        viewModelService,
        authHeader,
        useAuthenticationData,
        onResolve,
        onReject,
    } = props;
    const translator = useTranslator();

    const dtoVM = viewModelService.create({country: 'US'}, 'pc', 'edge.capabilities.address.dto.AddressDTO');
    const vm = { 
        policyAddress: dtoVM,
     }
    const [addressVM, updateAddressVM] = useState(vm)
    const [showErrors, updateShowErrors] = useState(false);
    const [addressVerifyResponse, updateAddressVerifyResponse] = useState({});
        // verify address 
    const [isAddressFlag, updateIsAddressFlag] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const {
        isComponentValid,
        onValidate,
        // registerComponentValidation,
        invalidFields
    } = useValidation('MailingAddressInputPopupComponent');


    const updateLookupValidation = useCallback((validations) => {
        const validationsMap = WniAddressUtil.getValidationMap(validations, validationIssues);
        updateValidationIssues(validationsMap);
    }, [validationIssues]);


    const writeValue = useCallback((value, path) => {
        if (WniAddressUtil.AddressChangeVerify(path, 'policyAddress')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = WniAddressUtil.getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateIsAddressFlag(false);
        }
        const newVM = _.clone(addressVM);
        _.set(newVM, `${path}.value`, value);
        updateAddressVM(newVM);
    }, [addressVM, updateLookupValidation]);


    const verifyAddress = async() => {
        let retval = addressVerifyResponse;
        if(!isAddressFlag) {
            const verifyAddressResult = await WniAddressUtil.onVerified(addressVM.policyAddress.value, authHeader);
            updateIsAddressFlag(true);
            updateAddressVerifyResponse(verifyAddressResult);
            retval = verifyAddressResult
        }
        
        return retval;
    };


    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );
    
    const handleSave = useCallback(
        async () => {
            if (!isComponentValid) {
                handleValidation();
                return false;
            }
            const verifyAddressData = await verifyAddress();

        const {
            isVerified,
            // addressValue,
            validationIssues: newValidationIssues = []
        } = verifyAddressData;
        
        if(!isVerified && !isAddressFlag) {
            updateValidationIssues(newValidationIssues);
            return false;
        };
        
        updateAddressVM(addressVM);
        onResolve(addressVM?.policyAddress);
        },
        [isComponentValid, isAddressFlag, addressVM, onResolve, handleValidation]
    );


    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            showErrors
        },
        mailingAddressContainer: {
            model: addressVM,
            dataPath: 'policyAddress',
            onAddressChange: writeValue,
            hideFieldType: {
                country: true,
                addressType: true,
                pobox: true
            },
            onValidate,
            useAuthenticationData,
            // availableStates
        },
    
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent,
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            addressVM,
            id,
            path,
            overrideProps
        );
    };

    
    return (
        <ModalNext isOpen={isOpen} className='md'>
            <ModalHeader title={title} />
            <ModalBody id="mailingAddressInputModal">
            <ViewModelForm
            uiProps={metadata.componentContent}
            model={addressVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors={showErrors}
        />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined" className={styles.cancelBtnMargin}>
                    {translator('Cancel')}
                </Button>
                <Button onClick={handleSave}>
                    {translator('Save')}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

MailingAddressInputPopupComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    size: PropTypes.string,
};

MailingAddressInputPopupComponent.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
    size: 'lg',
};

export default MailingAddressInputPopupComponent;
