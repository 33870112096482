import React, { useCallback, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputNumberField,
} from '@jutro/components';
import { CAVehicleService } from 'wni-capability-quoteandbind-ca';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWniModal } from 'wni-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './CopyModal.metadata.json5';
import messages from '../../CAVehiclesPage.messages';

function CopyModal(props) {
    const modalApi = useWniModal();

    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        selectedVehicles,
        renderDropDownCell,
        extendProps: { jobID, sessionUUID, authHeader } = {},
        useDependenciesData,
        intl
    } = props;

    const {
        loadingMask: { setLoadingMask }
    } = useDependenciesData;

    const [tableData, updateTableData] = useState([]);

    useEffect(() => {
        const vehicleData = _.cloneDeep(selectedVehicles);
        updateTableData(vehicleData);
    }, [])

    const copyService = async(serviceData) => {
        setLoadingMask(true);
        const res = await CAVehicleService.copyVehicle(jobID, sessionUUID, serviceData, authHeader);
        setLoadingMask(false);
        onResolve(res)
    }

    const handleCopyVehicle = async() => {
        const serviceData = tableData.map((item) => {
            return {
                rowIdPath: item.rowIdPath,
                numOfCopies: item.numOfCopies || 0
            }
        });
        const isOver20 = serviceData.some((item) => item.numOfCopies >= 20);
        if(isOver20) {
            modalApi.showConfirm({
                title: 'Warning',
                message: messages.overCopyMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.copyVehicles,
                cancelButtonText: commonMessages.cancelModel
            }).then(async(results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    await copyService(serviceData);
                }
            ).catch(()=> _.noop);
            return false;
        }
        await copyService(serviceData);
    };

    const handleValueChange = (value, item) => {
        const numberValue = !_.isNil(value) && value.length !== 0 ? value : 0;
        const newData = _.clone(tableData);
        newData.forEach((v) => {
            if(v.rowIdPath === item.rowIdPath) {
                _.set(v, 'numOfCopies', numberValue)
            }
        })
        updateTableData(newData);
    };

    const renderfieldCell = (item, index) => {
        return (
            <InputNumberField
                value={item.numOfCopies}
                minValue={0}
                onValueChange={(val) => handleValueChange(val, item)}
            />
        );
    };

    const renderVehicleIndex = (item, index, { path }) => {
        return `${item[path]}.`
    };

    const renderOrigionalCell = (item, index, { path }) => {
        return intl.formatNumber(
            item[path],
            {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol'
            }
        );
    }

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        copyVehicleTable: {
            data: tableData,
        },
        numberOfCopiesColumn: {
            
            renderCell: renderfieldCell,
        },
    };
    const resolvers = {
        resolveCallbackMap: {
            renderDropDownCell,
            renderVehicleIndex,
            renderOrigionalCell,
        },
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={`${size} modalHeaderWithBorder`}>
            <ModalHeader title={title} />
            <ModalBody id="copyVehicle">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter
                contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: 'middle',
                        justifyContent: 'between',
                    },
                }}
            >
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleCopyVehicle}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

CopyModal.propTypes = {};
CopyModal.defaultProps = {
    size: 'lg',
};
export default CopyModal;
