
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';



import {
    Button,
    formatDate,
    formatNumber,
    FormattedDate,
    FormattedNumber,
} from '@jutro/components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/datatable';

/**
 * Shows PolicyLineSummaryClauseDTO[] in a DataTable.
 * 
 * Sample Clauses Data:
  * [
 * 	 {
 * 		"description": "Experience Mod",
 * 		"terms": [
 * 			{
 * 				"name": "Factor",
 * 				"chosenTermValue": "1"
 * 			},
 * 			{
 * 				"name": "Rating Effective Date",
 * 				"chosenTermValue": ""
 * 			}
 * 		]
 * 	},
 * 	{
 * 		"description": "Catastrophe (Other Than Certified Acts of Terrorism) Premium Endorsement"
 * 	}
 * ]
 * @param {object} props
 * @returns {object} React Component
 */


function extractTermDTO (termDTOParam, termFormatter) {
    let termDTO = termDTOParam;
    if (_.isFunction(termFormatter)) {
        termDTO = termFormatter(termDTOParam);
    }

    const {
        name,
        chosenTermValue,
    } = termDTO;
    return {
        description: name,
        value: chosenTermValue,
    };
}

function extractClauseDTO(clauseDTO) {
    const {
        description,
        value = '', // might come from DisplayableSummaryComponent
        // terms,
    } = clauseDTO;
    return {
        description,
        value,
        //
        showBold: true,
    };
}

function clauseDTOExtractor(clause, { termFormatter } = {}) {
    const retval = [];
    const {
        terms,
    } = clause;
    
    const covRow = extractClauseDTO(clause);
    retval.push(covRow);

    if (!_.isEmpty(terms)) {
        terms.forEach((term) => {
            const termRow = extractTermDTO(term, termFormatter);
            retval.push(termRow);
        });
    }

    return retval;
}
function CoverageSummaryComponent(props) {
    const {
        clauses,
        //
        // tableRowExtractor = clauseDTOExtractor,
        showClauseInBold,
        title,
        //
        termFormatter,
    } = props;
    
    if (_.isEmpty(clauses)) {
        return null;
    }

    const getTableData = () => {
        const retval = [];

        if (_.isEmpty(clauses)) {
            return retval;
        }
        clauses.forEach((clause) => {
            const rows=  clauseDTOExtractor(clause, { termFormatter }); // || [];
            
            rows.forEach((row) => retval.push(row));
            
        });

        return retval;
    };

    const renderCell = (columnField) => {
        return (rowData, ...params) => {
            // const retval = rowData[columnField];
            // console.log(params);
            const {
                [columnField]: columnData,
                showBold,
            } = rowData;
            if (showClauseInBold && showBold) {
                return <strong>{columnData}</strong>;
            }
            return columnData;
        };
    };

    const onRowClick = (rowData, selectedIndex, ...params) => {
        // console.log(params);
        // return 'derp';
    };

    const renderTitle = () => {
        if(_.isEmpty(title)) {
            return null;
        }
        return <h4>{title}</h4>;
    };



    const tableData = getTableData();
    

    return (
        <div>
            {renderTitle()}
            <DataTable
                id="coveragesSummaryTable"
                data={tableData}
                tableLabel="Coverage Summary"
                onRowClick={onRowClick}
                selectOnRowClick
                showSearch={false}
            >
                <DisplayColumn
                    key="description"
                    value="description"
                    header="Description"
                    renderCell={renderCell('description')}
                />
                <DisplayColumn
                    key="value"
                    value="value"
                    header="Value"
                    renderCell={renderCell('value')}
                />
            </DataTable>
        </div>
    );


}

CoverageSummaryComponent.propTypes = {
    clauses: PropTypes.shape({}).isRequired,
    //
    // tableRowExtractor: PropTypes.func,
    showClauseInBold: PropTypes.bool,
    title: PropTypes.string,
    termFormatter: PropTypes.func,
};

CoverageSummaryComponent.defaultProps = {
    // tableRowExtractor: clauseDTOExtractor,
    showClauseInBold: true,
    title: undefined,
    termFormatter: undefined,
};

export default CoverageSummaryComponent;
