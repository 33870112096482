import React, {
    useState,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Loader,
} from '@jutro/components';
import {
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { 
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
// import { usePrevious } from 'wni-portals-util-react';
import { HOAddIntService } from 'wni-capability-quoteandbind-ho';
import HOCoverageUtil from '../../util/HOCoverageUtil';
import messages from './HOCoverageDetailsPopup.messages';
import styles from './HOCoverageDetailsPopup.module.scss';
import HOSingleClauseComponentVM from '../HOSingleClauseComponentVM/HOSingleClauseComponentVM';

function HOCoverageDetailsPopup(props) {

    const {
        size,
        isOpen,
        onResolve,
        onReject,
        coverage,
        quoteID,
        jobID,
        sessionUUID,
        coverageType,
        lobCoveragesPath,
        submissionVM,
        updateSubmissionVM,
        isEditable,
        hideCovBstructureLimitItem,
        hideCovBstructureRelatedTerms,
        lobName,
        coveragesConfig,
        coveragesService,
        useAuthenticationData,
        useDependenciesData,
        viewModelService
    } = props;

    const {
        baseData: {
            accountContacts_Ext: accountContacts,
        },
        lobData: {
            [lobName]: {
                coverables: {
                    additionalInterests = []
                } = {}
            } = {}
        }
    } = submissionVM.value;

    const translator = useTranslator();
    const [coverageInModal, setCoverageInModal] = useState(coverage)
    const { authHeader } = useAuthenticationData || useAuthentication();

    const [openedRowItemNumber, setOpenedRowItemNumber] = useState();
    const [selectedRowItemIndex, setSelectedRowItemIndex] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isCreateAdditionalInterestPanelVisible, setIsCreateAdditionalInterestPanelVisible] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [popupWarningIssue, setPopupWarningIssue] = useState([]);

    const [needSyncBeforeSave, setNeedSyncBeforeSave] = useState(false);

    const isPopupActionButtonDisable = isEditing || isCreateAdditionalInterestPanelVisible

    const model = {
        coverage: coverageInModal
    };

    const {
        code_Ext: codeExt
    } = coverageInModal

    const isCoverageInvalid = HOCoverageUtil.isCoverageInvalid(coverageInModal, coveragesConfig)

    const isOpenedRowItemInvalid = HOCoverageUtil
        .isOpenedScheduleOrStructureInValid(openedRowItemNumber, coverageInModal, coveragesConfig)

    const saveChangesToPC = useCallback(
        async (coverageToSave) => {
            const clausesToUpdate = HOCoverageUtil.generateUpdatedCoveragesDTO({
                [coverageType]: [coverageToSave]
            }, lobName);
            setIsLoading(true);
            const response = await coveragesService.updateCoverages(
                quoteID || jobID,
                sessionUUID,
                clausesToUpdate,
                authHeader
            )
            setIsLoading(false);
            
            // Sync complete
            setNeedSyncBeforeSave(false);
            return response
        },
        [authHeader, coverageType, jobID, quoteID, sessionUUID, lobName, coveragesService]
    )

    const updateUIByResponse = useCallback(
        (response) => {
            const coverageCode = _.get(coverageInModal, 'code_Ext')
            const hoprivateStructuresBeforeSyncStructureNum= _.get(coverageInModal, 'hoprivateStructures', [])
                .map((hoPrivateStructure) => _.get(hoPrivateStructure, 'structureNum'))
            setIsEditing(false);
            const lobCoverages = _.get(response, lobName);
            const errorsAndWarnings = _.get(response, 'errorsAndWarnings');
            // Only show warnings on field change here
            const newErrorsAndWarnings = ErrorsAndWarningsUtil.getServerIssues(errorsAndWarnings);
            const newWarnings = newErrorsAndWarnings.filter((issue) => _.get(issue, 'type') === 'warning');
            setPopupWarningIssue(newWarnings)
            // Update coverages on socerages screen
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM.value, lobCoveragesPath, lobCoverages);
            updateSubmissionVM(newSubmissionVM);
            // Update coverages on serires popup
            const coveragesAfterSync = _.get(lobCoverages, coverageType);
            const updatedCoverageAfterSync = coveragesAfterSync
                .find((item) => _.get(item, 'code_Ext') === coverageCode);
            const updatedPrivateStructuresAfterSyncStructureNum = _.get(updatedCoverageAfterSync, 'hoprivateStructures', [])
                .map((hoPrivateStructure) => _.get(hoPrivateStructure, 'structureNum'))
            const newAddedPrivateStructureNums = _.difference(
                updatedPrivateStructuresAfterSyncStructureNum, hoprivateStructuresBeforeSyncStructureNum)
            if (_.get(newAddedPrivateStructureNums, 'length', 0) > 0) {
                const firstAddedStructureNum = newAddedPrivateStructureNums[0]
                setOpenedRowItemNumber(firstAddedStructureNum)
            }
            setCoverageInModal(updatedCoverageAfterSync)
            return updatedCoverageAfterSync
        },
        [coverageInModal, coverageType, lobCoveragesPath, submissionVM, updateSubmissionVM, viewModelService, lobName]
    )

    const handleSave = async () => {
        // if a row item is open, close the opened item instead of whole popup
        if (!_.isNil(openedRowItemNumber)) {
            
            // When close opened item, save changes when needed
            if (needSyncBeforeSave) {
                const response = await saveChangesToPC(coverageInModal)
                updateUIByResponse(response)
            }
            // If have valid error in current opened item, stop user to let user fix it
            if (isOpenedRowItemInvalid) {
                setShowErrors(true)
            } else {
                setOpenedRowItemNumber(null)
                setShowErrors(false)
            }
            
            return;
        }
        // All row items are closed, close popup
        if (isCoverageInvalid) {
            setShowErrors(true)
            return;
        }
        onResolve(coverageInModal);
    }

    const handleClose = () => {
        // if a row item is open, close the opened item instead of whole popup
        if (!_.isNil(openedRowItemNumber)) {
            setOpenedRowItemNumber(null)
            return;
        }
        onReject()
    }

    // This function change submission but not sync
    const changeSubmission = useCallback(
        (value, changedPath) => {
            setIsEditing(false)
            // Due to someThing change, need sync to PC later
            setNeedSyncBeforeSave(true)
            const pathToCoverage = changedPath.replace('coverage.', '');
            let updatedCoverageInModal = _.clone(coverageInModal);
            updatedCoverageInModal = HOCoverageUtil.setValueToCoverage(updatedCoverageInModal, value, pathToCoverage);
            setCoverageInModal(updatedCoverageInModal);
            return updatedCoverageInModal
        },
        [coverageInModal]
    );

    // This function change submission and sync
    const changeSubmissionAndUpdateCoverageInModal = useCallback(
        async (value, changedPath) => {
            // changeSubmission(value, changedPath)
            setIsEditing(false)
            const pathToCoverage = changedPath.replace('coverage.', '');
            
            let coverageInModalClone = _.clone(coverageInModal);
            
            coverageInModalClone = HOCoverageUtil.setValueToCoverage(coverageInModalClone, value, pathToCoverage);

            const response = await saveChangesToPC(coverageInModalClone)
            const updatedCoverageAfterSync = updateUIByResponse(response)
            
            return updatedCoverageAfterSync
        },
        [coverageInModal, saveChangesToPC, updateUIByResponse]
    );

    const changeSubmissionAndSync = useCallback(
        async (value, changedPath) => {
            // changeSubmission(value, changedPath)
            changeSubmissionAndUpdateCoverageInModal(value, changedPath)
        },
        [changeSubmissionAndUpdateCoverageInModal]
    );

    const onScheduleChangeWithoutSync = useCallback(
        (value, changedPath, localPathToScheduleItem, changedFields) => {
            let updatedSchedule = value
            _.forEach(changedFields, (changedField) => {
                const frontendScheduleItemSync = _.get(
                    coveragesConfig,
                    `scheduleConfig.frontendScheduleItemSync.${codeExt}.${changedField}`)
                if (!_.isNil(frontendScheduleItemSync)) {
                    updatedSchedule = frontendScheduleItemSync(updatedSchedule, localPathToScheduleItem)
                }

            })
            
            const updatedCoverage = changeSubmission(updatedSchedule, changedPath);
            updatedSchedule = _.get(updatedCoverage, 'schedule')
            return updatedSchedule;
        },
        [changeSubmission, codeExt, coveragesConfig]
    )

    const onScheduleChangeAndSync = useCallback(
        async (value, changedPath) => {
            const updatedCoverageAfterSync = await changeSubmissionAndUpdateCoverageInModal(value, changedPath);
            const updatedScheduleAfterSync = _.get(updatedCoverageAfterSync, 'schedule')
            return updatedScheduleAfterSync;
            
        },
        [changeSubmissionAndUpdateCoverageInModal]
    )

    const onSyncCoverages = useCallback(
        async (value, changedPath) => {
            await changeSubmissionAndUpdateCoverageInModal(value, changedPath);
        },
        [changeSubmissionAndUpdateCoverageInModal]
    )

    const actionBtnLabel = translator(messages.save);

    const showSaveButton = useMemo(() => {
        if (!isEditable) {
            return false
        }
        if (needSyncBeforeSave) {
            return true
        }
        // const coveragesToUpdate = WniClausesUtil.filterUnchangedClauses([coverageInModal])
        // if (_.get(coveragesToUpdate, 'length', 0) > 0) {
        //     return true
        // }
        // If row item is expanded, only check opened item is valid
        if (!_.isNil(openedRowItemNumber)) {
            return isOpenedRowItemInvalid
        }
        if (isCoverageInvalid) {
            return true
        }
        
        return false
    }, [isEditable, needSyncBeforeSave, openedRowItemNumber, isCoverageInvalid, isOpenedRowItemInvalid]);

    // For sub loss payee panel, creating new loss payee related service is here
    const onSaveAdditionalInterestFn =  useCallback( async (getAdditionalInterestDTO) => {
        setIsLoading(true);
        const remainAdditionalInterests = await HOAddIntService.getLatestLossPayee(jobID, sessionUUID, getAdditionalInterestDTO, authHeader);
        setIsLoading(false);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, 'value.lobData.homeowners.coverables.additionalInterests', remainAdditionalInterests);
        updateSubmissionVM(newSubmissionVM);
        return remainAdditionalInterests
    },[jobID, sessionUUID, authHeader, viewModelService, submissionVM, updateSubmissionVM]);

    const onGettingAdditionalInterestTypeOptions = useCallback(({
        publicID, isPerson, isCompany, isBank, isTrust
    }) => {
        return HOAddIntService.getAdditionalInterestType(jobID, sessionUUID, publicID, isPerson, isCompany, isBank, isTrust, authHeader);
    },[jobID, sessionUUID, authHeader]);

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={coverage.name} />
            <ModalBody id="HOSeriesCoverages" autoFocus={false}>
                {isLoading ? <Loader loaded={!isLoading} /> : (
                    <ViewModelServiceContext.Provider value={viewModelService}>
                        <ValidationIssuesComponent 
                            visible={popupWarningIssue.length > 0}
                            validationIssues={popupWarningIssue}
                        />
                        <HOSingleClauseComponentVM
                            value={coverageInModal}
                            path='coverage'
                            isEditable={isEditable}
                            isInDetailsPopup
                            showErrors={showErrors}
                            showScheduleDetailsInPanelBelow
                            openedRowItemNumber={openedRowItemNumber}
                            setOpenedRowItemNumber={setOpenedRowItemNumber}
                            selectedRowItemIndex={selectedRowItemIndex}
                            setSelectedRowItemIndex={setSelectedRowItemIndex}
                            hideCovBstructureLimitItem={hideCovBstructureLimitItem}
                            hideCovBstructureRelatedTerms={hideCovBstructureRelatedTerms}
                            setIsEditing={setIsEditing}
                            labelPosition= "left"
                            loadingClauseMessage= {{
                                "id": "policycommon.directives.coverages.Syncing coverage dependencies…",
                                "defaultMessage": "Syncing coverage dependencies…"
                            }}
                            containerClassName= "clauseContainer"
                            onSyncCoverages= {onSyncCoverages}
                            onChangeSubmissionAndSync= {changeSubmissionAndSync}
                            // This function change submission without sync
                            onChangeClause= {changeSubmission}
                            onScheduleChangeWithSync= {onScheduleChangeAndSync}
                            onScheduleChangeWithoutSync = {onScheduleChangeWithoutSync}
                            isOnlyShowTermsAndScheduleItems
                            setNeedSyncBeforeSave={setNeedSyncBeforeSave}
                            coveragesConfig={coveragesConfig}
                            additionalInterests={additionalInterests}
                            // for create loss payee part
                            onSaveAdditionalInterestFn={onSaveAdditionalInterestFn}
                            onGettingAdditionalInterestTypeOptions={onGettingAdditionalInterestTypeOptions}
                            accountContacts={accountContacts}
                            isCreateAdditionalInterestPanelVisible={isCreateAdditionalInterestPanelVisible}
                            setIsCreateAdditionalInterestPanelVisible={setIsCreateAdditionalInterestPanelVisible}
                            
                        />
                    </ViewModelServiceContext.Provider>
                    
                )}
                
            </ModalBody>
            <ModalFooter>
                {showSaveButton ? (
                    <>
                        <Button
                            onClick={handleClose}
                            type="outlined"
                            className={styles.cancelBtnMargin}
                            disabled={isPopupActionButtonDisable}
                        >
                            {translator(messages.cancel)}
                        </Button>
                        <Button onClick={handleSave} disabled={isPopupActionButtonDisable}>
                            {actionBtnLabel}
                        </Button>
                    </>
                ) : (
                    <Button disabled={isPopupActionButtonDisable} onClick={handleClose}>
                        {translator(messages.close)}
                    </Button>
                )}
                
            </ModalFooter>
        </ModalNext>
    );
}

HOCoverageDetailsPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    coverage: PropTypes.shape({}).isRequired,
    isEditable: PropTypes.bool,
};

HOCoverageDetailsPopup.defaultProps = {
    actionBtnLabel: undefined,
    cancelBtnLabel: undefined,
    size: 'lg',
    isOpen: true,
    onReject: _.noop,
    onResolve: _.noop,
    isEditable: true,
};

export default HOCoverageDetailsPopup;
