
import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardConstants, PortalConstants } from 'wni-portals-config-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { GLRiskAnalysisPage } from 'wni-capability-quoteandbind-gl-react';
import { CPRiskAnalysisService, CPMultiQuoteService } from 'wni-capability-quoteandbind-cp';
// import messages from './CPRiskAnalysisPage.messages';

function CPRiskAnalysisPage(props) {

    const {
        wizardData: submissionVM,
        riskAnalysisService,
        //
        updateWizardPageData,
        //
        onPreQuotePageNext: onPreQuotePageNextParam,
    } = props;

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    
    const {
        jobID,
        sessionUUID,
    } = submissionVM.value;

    // =================================================================
    const onCPPreQuotePageNext = useCallback(async () =>{
        const {
            baseData: {
                periodStatus,
            },
        } = submissionVM.value;

        if (periodStatus === PortalConstants.QUOTE_STATUS_DRAFT) {
            updateWizardPageData({ [WizardConstants.sideBySideData]: undefined });
            setLoadingMask(true);
            submissionVM.value = await CPMultiQuoteService.processQuote(
            // submissionVM.value = await processQuote(
                jobID, sessionUUID, authHeader
            );
            setLoadingMask(false);
        }
        return submissionVM
    }, [authHeader, submissionVM, updateWizardPageData]);
    // =================================================================


    const CPOverrideProps = {
    }

    const onPreQuotePageNext = onPreQuotePageNextParam || onCPPreQuotePageNext;
    return (
        <GLRiskAnalysisPage
            {...props}
            glRiskAnalysisService = {riskAnalysisService}
            // customMessages = {messages}
            CPOverrideProps = {CPOverrideProps}
            lobName = 'commercialProperty'
            onPreQuotePageNext = {onPreQuotePageNext}
        />
    );

}

CPRiskAnalysisPage.propTypes = {
    ...wizardProps,
    riskAnalysisService: PropTypes.object
};

CPRiskAnalysisPage.defaultProps = {
    riskAnalysisService: CPRiskAnalysisService
};

export default CPRiskAnalysisPage;
