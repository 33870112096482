import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import questionSetsMetadata from 'question-sets-metadata';
import { R1Config } from 'wni-portals-config-js';
import { DomainRulesMessages } from 'wni-platform-translations';


const {
    UWQuestionConfig
} = R1Config;

/**
 * QuestionSet filter for PAPolicyDetails
 *
 * See QuestionSetsParser.contentFilter
 * @param {String} policyState
 * @returns {Function}
 */
function getQSFilterForPolicyDetails(policyState) {
    return (metadataContent) => {
        if (policyState !== 'AK') {
            return metadataContent.id !== 'hasExistingHOAK_Ext'
            && metadataContent.id !== 'hasFamilyMemberWithSeparateAutoAK_Ext';
        }
        return metadataContent.id !== 'hasExistingHO_Ext'
            && metadataContent.id !== 'hasFamilyMemberWithSeparateAuto_Ext';
    };
}

/**
 * QuestionSet filter for HoHouseholdInfo
 *
 * See QuestionSetsParser.contentFilter
 * @param {Boolean} isFeb19EntityChangeVerExt
 * @param {Object} policyInfoQuestions
 * @returns {Function}
 */
function getQSFilterForHoHouseholdInfo(isFeb19EntityChangeVerExt, isApr16EntityChangeVerExt, policyInfoQuestions) {
    const {
        answers: {
            // eslint-disable-next-line camelcase
            HasLapseInContinuousCovChoice_Ext,
            HaveMaintainedContinuousCov_Ext
        }
    } = policyInfoQuestions
    return (metadataContent) => {
        const metadataContentId = metadataContent.id
        switch (metadataContentId) {
            case 'policyNumber_Ext':
                return false
            case 'HasLapseInContinuousCov_Ext':
                return !isFeb19EntityChangeVerExt
            case 'HasLapseInContinuousCovChoice_Ext':
                return isFeb19EntityChangeVerExt && !isApr16EntityChangeVerExt
            case 'HaveMaintainedContinuousCov_Ext':
                return isApr16EntityChangeVerExt
            case 'explainForHasLapseInContinuousCov_Ext':
                return ([
                    'HasLapseInContinuousCovChoiceNo_Ext',
                    'HasLapseInContinuousCovChoiceUnknown_Ext'
                ].includes(HasLapseInContinuousCovChoice_Ext) && isFeb19EntityChangeVerExt) || (HaveMaintainedContinuousCov_Ext === 'false' && isApr16EntityChangeVerExt)
            default:
                return true
        }
    };
}


/**
 * QuestionSet Mapper for HoHouseholdInfo
 *
 * See QuestionSetsParser.contentMapper
 * @param {String} policyState
 * @returns {Function}
 */
function getQSMapperForHoHousehold(policyState, hasAnyDeclinedHomeOwnerCov,hasForeclosure,hasBeenIndictedIn5Years) {
    return (metaDataContent) => {
        if (DomainRulesMessages[`${metaDataContent.id}_${policyState}`]) {
            _.set(metaDataContent, 'componentProps.label', DomainRulesMessages[`${metaDataContent.id}_${policyState}`])
        }
        if(metaDataContent.id.startsWith('has')){
            _.set(metaDataContent, 'componentProps.className', 'wniKnockoutToolTip')
        }
        if(metaDataContent.id.startsWith('hasAnyDeclinedHomeOwnerCov') && hasAnyDeclinedHomeOwnerCov === "true"){
            _.set(metaDataContent,'componentProps.tooltip',true)
            _.set(metaDataContent, 'componentProps.tooltip.showOnInit', hasAnyDeclinedHomeOwnerCov)
            _.set(metaDataContent, 'componentProps.tooltip.text', getHOPUWQuestionWarningMessage(metaDataContent.id))
            _.set(metaDataContent, 'componentProps.tooltip.title', 'Ineligible')
            _.set(metaDataContent, 'componentProps.tooltip.icon', 'gw-error')
            
        }
        if(metaDataContent.id.startsWith('hasForeclosure') && hasForeclosure === "true"){
            _.set(metaDataContent, 'componentProps.tooltip.tooltip',true)
            _.set(metaDataContent, 'componentProps.tooltip.showOnInit', hasForeclosure)
            _.set(metaDataContent, 'componentProps.tooltip.text', getHOPUWQuestionWarningMessage(metaDataContent.id))
            _.set(metaDataContent, 'componentProps.tooltip.title', 'Ineligible')
            _.set(metaDataContent, 'componentProps.tooltip.icon', 'gw-error')

             }
        if(metaDataContent.id.startsWith('hasBeenIndictedIn5Years') && hasBeenIndictedIn5Years === "true"){
            _.set(metaDataContent,'componentProps.tooltip',true)
            _.set(metaDataContent, 'componentProps.tooltip.showOnInit', hasBeenIndictedIn5Years)
            _.set(metaDataContent, 'componentProps.tooltip.text', getHOPUWQuestionWarningMessage(metaDataContent.id))
            _.set(metaDataContent, 'componentProps.tooltip.title', 'Ineligible')
            _.set(metaDataContent, 'componentProps.tooltip.icon', 'gw-error')
        }

        return metaDataContent;
    };
}

/**
 * Get question name show in warning message
 * @param {*} questionSet
 * @param {*} code
 * @param {*} translator
 * @returns {String}
 */
function getQuestionNameInWarningMessage(questionSet, code, translator) {
    switch (code) {
        case 'policyInfoDoYou_Ext':
        case 'policyInfoDoYouAK_Ext':
            return 'Do you own or rent a home?';
        default:
            return translator({
                id: questionSet.find((question) => question.code === code).displayKey
            });
    }
}

function getHOPUWQuestionWarningMessage(code) {
    switch(code) {
        case 'hasAnyDeclinedHomeOwnerCov_Ext': 
            return 'Insured cannot have any declined, cancelled, or non-renewed coverage in the last three years.';
        case 'hasForeclosure_Ext':
            return 'Insured cannot have had a foreclosure, repossession, bankruptcy, judgement, or lien in the last five years.';
        case 'hasBeenIndictedIn5Years_Ext':
            return 'Insured cannot have been convicted of any degree of fraud, bribery, arson or any other arson related crime in the last five years.';
        default:
            return ''
    }
}

function mapPreQualQuestions(metaDataContent) {
    if (metaDataContent.id === 'policyInfoDoYou_Ext' || metaDataContent.id === 'policyInfoDoYouAK_Ext') {
        _.set(metaDataContent, 'componentProps.label', {
            defaultMessage: 'Do you own or rent a home?',
            id: 'questionkey.Question_PAPolicyInfoUWQuestions.Do you own or rent a home',
        });
    } else if (metaDataContent.id === 'anyCoverageDeclined_Ext') {
        // POI-15098: Change question label
        _.set(metaDataContent, 'componentProps.label', {
            defaultMessage: 'Has any driver or household resident had automobile insurance coverage declined, cancelled, or non-renewed during the last three years?',
            id: 'questionkey.Question_PAPolicyInfoUWQuestions.anyCoverageDeclined_Ext',
        });
    }
    return metaDataContent;
}

/**
 * QuestionSet Mapper for PAPolicyDetails
 *
 * See QuestionSetsParser.contentMapper
 * @param {String} policyState
 * @returns {Function}
 */
function getQSMapperForPASubmissionPolicyDetails() {
    return (metaDataContent) => {
        const res = mapPreQualQuestions(metaDataContent);
        return res;
    };
}

/**
 * QuestionSet Mapper for PolicyChange
 *
 * See QuestionSetsParser.contentMapper
 * @param {String} policyState
 * @returns {Function}
 */
function getQSMapperForPAPolicyChangePolicyDetails() {
    return (metaDataContent) => {
        const res = mapPreQualQuestions(metaDataContent);
        if (metaDataContent.id === 'isPartOfBookroll_Ext') {
            res.componentProps.readOnly = true;
            // _.set(metaDataContent, 'componentProps.readOnly', true);
        }
        return res;
    };
}

function getQSWarningQuestionsByAnswersAndState(answers, policyState) {
    const questionsNeedWarning = [];
    Object.keys(answers)
        .filter((key) => {
            if (UWQuestionConfig.explainBoxUWQuestionCode.includes(key)) {
                return false;
            }
            if (policyState !== 'AK') {
                return key !== 'hasExistingHOAK_Ext'
                && key !== 'hasFamilyMemberWithSeparateAutoAK_Ext'
                && key !== 'policyInfoDoYouAK_Ext'
                && key !== 'separateAutoPolicyNumberAK_Ext'
                && key !== 'mulDiscountPolicyNumber_Ext';
            }
            if (policyState === 'AK') {
                return key !== 'mulDiscountPolicyNumberAK_Ext';
            }
            return key !== 'hasExistingHO_Ext'
                && key !== 'hasFamilyMemberWithSeparateAuto_Ext'
                && key !== 'policyInfoDoYou_Ext'
                && key !== 'separateAutoPolicyNumber_Ext';
        }).forEach((key) => {
            if (answers[key]) {
                return;
            }
            switch (key) {
                case 'policyInfoDoYou_Ext':
                    if (answers.hasExistingHO_Ext === 'true') {
                        questionsNeedWarning.push(key);
                    }
                    break;
                case 'policyInfoDoYouAK_Ext':
                    if (answers.hasExistingHOAK_Ext === 'true') {
                        questionsNeedWarning.push(key);
                    }
                    break;
                case 'separateAutoPolicyNumber_Ext':
                    if (answers.hasFamilyMemberWithSeparateAuto_Ext === 'true') {
                        questionsNeedWarning.push(key);
                    }
                    break;
                case 'separateAutoPolicyNumberAK_Ext':
                    if (answers.hasFamilyMemberWithSeparateAutoAK_Ext === 'true') {
                        questionsNeedWarning.push(key);
                    }
                    break;
                default:
                    questionsNeedWarning.push(key);
                    break;
            }
        });
    return questionsNeedWarning;
}

export default {
    getQSFilterForPolicyDetails,
    getQSMapperForPASubmissionPolicyDetails,
    getQSMapperForPAPolicyChangePolicyDetails,
    getQSWarningQuestionsByAnswersAndState,
    getQuestionNameInWarningMessage,
    getHOPUWQuestionWarningMessage,
    getQSFilterForHoHouseholdInfo,
    getQSMapperForHoHousehold
    // isPAPreQualQuestionValid,
    // getPAInvalidAnswerKeys,
};
