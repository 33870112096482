import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalBody,
    Loader} from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';

import SolidFuelFormComponent from '../SolidFuelFormCompnent/SolidFuelFormCompnent';
import messages from '../../HOResidencePage.messages';
import metadata from './SolidFuelPopupCompnent.metadata.json5';
import ResidenceUtil from '../../util/ResidenceUtil';

function SolidFuelPopupCompnent(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        isReadOnly,
        solidFuelBurningDevicesVM,
        editSolidFuel,
        removeSolidFuels,
        solidFuelTypeOptions,
        viewModelService,
        translator,
        linePath
    } = props;

    const [showErrors, updateShowErrors] = useState(false);
    const [currentSolidFuelVM, updateCurrentSolidFuelVM] = useState();
    const [selection, updateSelection] = useState([]);
    const [loadingTable, updateLoadingTable] = useState(false);
    const [solidFuelsStateVM, updateSolidFuelsStateVM] = useState(solidFuelBurningDevicesVM);
    const [originSolidFuelVM, updateOriginSolidFuelVM] = useState(currentSolidFuelVM);

    const renderViewEditDom = () => {
        return (
            <div className="activeRow btn-link">
                {isReadOnly ? translator(messages.viewLabel) : translator(messages.viewAndEditLabel)}
            </div>
        );
    };

    const removeSelectedSolidFuels = async() => {
        const solidFuels = _.get(solidFuelsStateVM, 'value') || [];
        const selectedSolidFuels = _.filter(solidFuels, (solidFuel, index) => {
            return _.includes(selection, index) && solidFuel.publicID;
        });
        updateLoadingTable(true);
        const res = await removeSolidFuels(selectedSolidFuels);
        const solidFuelsPath = `lobData.${linePath}.coverables.dwelling_Ext.construction.solidFuelBurningDevices_Ext`;
        solidFuelsStateVM.value = _.get(res, solidFuelsPath);
        updateSolidFuelsStateVM(solidFuelsStateVM);
        updateCurrentSolidFuelVM(null);
        updateSelection([]);
        updateLoadingTable(false);
    };

    const addSolidFuel = () => {
        const {
            _dtoName,
            _xCenter,
        } = solidFuelsStateVM;
        const newVM = viewModelService.create({}, _xCenter, _dtoName);
        updateCurrentSolidFuelVM(newVM);

        // const newSolidFuelsVM = viewModelService.clone(solidFuelsStateVM);
        const exsitSolidFuels = _.get(solidFuelsStateVM, 'value') || [];
        const solidFuels = [...exsitSolidFuels, newVM.value];
        _.set(solidFuelsStateVM, 'value', solidFuels);
        updateSolidFuelsStateVM(solidFuelsStateVM);
    };

    const viewOrEditSolidFuel = useCallback((solidFuel) => {
        _.each(solidFuelsStateVM.children, (solidFuelVM) => {
            if (solidFuelVM.value.publicID === solidFuel.publicID) {
                updateOriginSolidFuelVM(viewModelService.clone(solidFuelVM));
                updateCurrentSolidFuelVM(solidFuelVM);
            }
        });
    }, [solidFuelsStateVM]);

    const isAddSolidFuelDisabled = useCallback(() => {
        const solidFuels = _.get(solidFuelsStateVM, 'value');
        const newlySolidFuels = _.filter(solidFuels, (solidFuel) => {
            return !solidFuel.publicID
        })
        if (_.isEmpty(newlySolidFuels)) {
            return false;
        }
        return true;
    }, [solidFuelsStateVM])

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                showOptional: false,
                labelPosition: 'left',
                showRequired: true,
                showErrors: showErrors
            },
            removeSolidFuels: {
                onClick: removeSelectedSolidFuels,
                visible: !isReadOnly,
                disabled: _.isEmpty(selection)
            },
            addSolidFuel: {
                visible: !isReadOnly,
                onClick: addSolidFuel,
                disabled: isAddSolidFuelDisabled()
            },
            solidFuelsTable: {
                data: _.get(solidFuelsStateVM, 'value') || [],
                onSelectionChange: (rows) => updateSelection(rows)
            },
            type: {
                cell: (item) => ResidenceUtil.onSolidFuelTypeCell(item, translator)
            },
            stoveLocated: {
                cell: (item) => ResidenceUtil.onStoveLocatedCell(item, translator)
            },
            isSolidFuelDeviceABarrel: {
                cell: (item) => {
                    if (item.isSolidFuelDeviceABarrel === true) {
                        return 'Yes';
                    } if (item.isSolidFuelDeviceABarrel === false) {
                        return 'No';
                    }
                    return '';
                }
            },
            viewOrEdit: {
                onClick: viewOrEditSolidFuel,
                label: renderViewEditDom(),
            },
            solidFuelForm: {
                solidFuelVM: currentSolidFuelVM,
                solidFuelsVM: solidFuelsStateVM,
                updateSolidFuelVM: updateCurrentSolidFuelVM,
                visible: !_.isEmpty(currentSolidFuelVM),
                editSolidFuel,
                updateSolidFuelsVM: updateSolidFuelsStateVM,
                originSolidFuelVM,
                solidFuelTypeOptions,
                viewModelService,
                linePath,
                isReadOnly
            }
        }
    }, [currentSolidFuelVM, solidFuelsStateVM, solidFuelBurningDevicesVM, selection]);

    const overrideProps = generateOverrides();

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            solidFuelsStateVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            SolidFuelFormComponent: SolidFuelFormComponent
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <Button className="modal-close" icon="gw-close" onClick={onResolve} />
            <ModalBody id="animalDatailsPage">
                { loadingTable ? <Loader loaded={!loadingTable} /> : <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={solidFuelsStateVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
                }
            </ModalBody>
        </ModalNext>
    );
}

SolidFuelPopupCompnent.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    isReadOnly: PropTypes.bool,
    solidFuelBurningDevicesVM: PropTypes.shape({}),
    editSolidFuel: PropTypes.func,
    removeSolidFuels: PropTypes.func,
    solidFuelTypeOptions: PropTypes.array,
    linePath: PropTypes.string,
};
SolidFuelPopupCompnent.defaultProps = {
    searchValue: '',
    size: 'md',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    isReadOnly: false,
    solidFuelBurningDevicesVM: null,
    editSolidFuel: _.noop,
    removeSolidFuels: _.noop,
    solidFuelTypeOptions: []
};

export default SolidFuelPopupCompnent;

