import React, {
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { NewPersonComponent } from 'wni-capability-gateway-react';
import { PaymentUtil, WniProductsUtil } from 'wni-portals-util-js';
import { useProductsData } from 'wni-portals-util-react';
import { WniLoadSaveService, WniPAQuoteService } from 'wni-capability-quoteandbind';
import { CAPaymentService } from 'wni-capability-quoteandbind-ca';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { BaseAddPaymentMethodPopup } from 'wni-capability-common-react';
import { useWniModal } from 'wni-components-platform-react';
import AQPaymentPageUtil from '../../utils/AQPaymentPageUtil';
import styles from './EditQuotePopup.module.scss';
import metadata from './EditQuotePopup.metadata.json5';
import messages from './EditQuotePopup.messages';

function EditQuotePopup(props) {
    const modalApi = useWniModal();
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        warningMessage,
        rowData,
        rePopupEditQuoteRow,
        fetchedAccountTableData,
        quoteTableData,
        editedData,
        getProductName,
        useAuthenticationData,
        useDependenciesData,
        viewModelService,
        breakpoint,
        showCancelBtn,
        producerOfRecordName
    } = props;

    const { loadingMask: { setLoadingMask } } = useDependenciesData || useDependencies(['loadingMask']);

    const { authHeader } = useAuthenticationData || useAuthentication();
    const translator = useTranslator();
    const [model, updateModel] = useState(_.isEmpty(editedData) ? _.cloneDeep(rowData) : _.cloneDeep(editedData));
    const [hasSelectAutoPay, updateHasSelectAutoPay] = useState(false);
    // const viewModelService = useContext(ViewModelServiceContext);
    const [primaryPayerAvailableValues, updatePrimaryPayerAvailableValues] = useState([]);
    const [primaryPayer, updatePrimaryPayer] = useState('');
    const [selectedItem, updateSelectedItem] = useState({});
    const [paymentMethodsOptions, updatePaymentMethodsOptions] = useState([]);
    const [paymentMethodValue, updatePaymentMethodValue] = useState('');
    const [paymentPlanOptions, updatePaymentPlanOptions] = useState([]);
    const [applyToOtherQuotesAvailableValues, updateapplyToOtherQuotesAvailableValues] = useState([]);
    const [applyToOtherQuotesValue, updateApplyToOtherQuotesValue] = useState(_.get(model, 'applyToOtherQuotes', []));
    const [clBillingMethodsOptions, updateCLBillingMethodsOptions] = useState([]);
    const [clBillingMethodValue, updateCLBillingMethodValue] = useState('');
    const [clPaymentMehodEditable, updateCLPaymentMehodEditable] = useState(true);
    const [clDueDayOfMonthEditable, updateCLDueDayOfMonthEditable] = useState(true);
    const [clSelectedPaymentPlan, updateCLSelectedPaymentPlan] = useState('');
    const [clDueDayOfMonth, updateCLDueDayOfMonth] = useState('');
    const [clPrimaryPayerEditable, updateCLPrimaryPayerEditable] = useState(true);
    const [clSelectedBillingMethodPAYG, updateCLSelectedBillingMethodPAYG] = useState(false);
    const [clSelectedBillingMethodAB, updateCLSelectedBillingMethodAB] = useState(false);
    const [agencyBillPrimaryPayerAvailableValues, updateAgencyBillPrimaryPayerAvailableValues] = useState([]);
    const [agencyBillPrimaryPayer, updateAgencyBillPrimaryPayer] = useState('');
    
    
    
    const {
        quoteNumber: quoteID,
        sessionUUID,
        accountNumber
    } = rowData;

    const isCLProduct = WniProductsUtil.isCLProduct(_.get(model, 'product.productCode'));

    const checkPersonExist = useCallback((person, primaryPayerOptions) => {
        const personExist = _.find(primaryPayerOptions, (options) => {
            return JSON.stringify(person) === _.get(options, 'code');
        });
        if (person && !personExist) {
            primaryPayerOptions.push({
                code: JSON.stringify(person),
                name: _.get(person, 'displayName')
            });
        }
        return primaryPayerOptions;
    }, []);

    const resetPAYGPrimaryPayerOptions = useCallback((newPerson, initPerson) => {
        // set primaryPayerOptions
        let primaryPayerOptions = [];
        // initPerson
        primaryPayerOptions = checkPersonExist(initPerson, primaryPayerOptions)

        // manually add New Person
        primaryPayerOptions = checkPersonExist(newPerson, primaryPayerOptions)

        primaryPayerOptions.push({
            code: 'Add New Person',
            name: 'Add New Person'
        });
        updatePrimaryPayerAvailableValues(primaryPayerOptions);
    }, [checkPersonExist]);

    const resetPrimaryPayerOptions = useCallback((newPerson, initPerson) => {
        // set primaryPayerOptions
        let primaryPayerOptions = [];
        const nonBillingContacts = _.get(model, 'bindData.nonBillingContacts_Ext');
        _.each(nonBillingContacts , (contact) => {
            _.set(contact, 'phoneRequired_Ext', false);
        })
        primaryPayerOptions = PaymentUtil.updatePrimaryPayerOptions(
            nonBillingContacts, primaryPayerOptions
        );
        const billingContacts = _.get(model, 'bindData.billingContacts_Ext');
        _.each(billingContacts , (contact) => {
            _.set(contact, 'phoneRequired_Ext', false);
        })
        primaryPayerOptions = PaymentUtil.updatePrimaryPayerOptions(
            billingContacts, primaryPayerOptions
        );
        // initPerson
        primaryPayerOptions = checkPersonExist(initPerson, primaryPayerOptions)

        // manually add New Person
        primaryPayerOptions = checkPersonExist(newPerson, primaryPayerOptions)
        primaryPayerOptions.push({
            code: 'Add New Person',
            name: 'Add New Person'
        });
        updatePrimaryPayerAvailableValues(primaryPayerOptions);
    }, [checkPersonExist, model]);

    const isPremiumMeetPAYG = useCallback((premium) => {
        return premium >= 15000;
    }, []);


    const checkPAYGOptionAvailable = useCallback((isClSelectedBillingMethodPAYG) => {
        // POI-51016 only show Pay As You Go option when premium is greater than or equals to 15000
        // and user has select pay as you go questions answer as yes
        // or current is selected Pay As You Go
        const premium = _.get(model, 'premium.totalCost_Ext.amount', 0);
        const hasTakenPayAsYouGoOffering = _.get(model, 'hasTakenPayAsYouGoOffering');
        const showPAYGOption = (isPremiumMeetPAYG(premium) && hasTakenPayAsYouGoOffering) || isClSelectedBillingMethodPAYG;
        return showPAYGOption;
    }, [isPremiumMeetPAYG, model]);

    const updateCLBillingMethodForPAYG = useCallback((isClSelectedBillingMethodPAYG) => {
        const clBillingMethods = _.get(model, 'billingMethodOptions');
        const showPAYGOption = checkPAYGOptionAvailable(isClSelectedBillingMethodPAYG);
        const updatedCLBillingMethodOptions = PaymentUtil.generatePaymentBillingMethods(clBillingMethods, showPAYGOption);
        updateCLBillingMethodsOptions(updatedCLBillingMethodOptions);
    }, [checkPAYGOptionAvailable, model]);

    const updateCLPrimaryPayerOptionsAndValue = useCallback((path) => {
        const currentPayer = _.get(model, 'primaryPayer');
        const displayName = _.get(currentPayer, path, null);
        if (displayName) {
            _.set(currentPayer, 'displayName', displayName);
        }
        resetPrimaryPayerOptions(null, currentPayer);
        updatePrimaryPayer(JSON.stringify(currentPayer));
    }, [model, resetPrimaryPayerOptions]);

    const enableDirectBillFieldsEditable = useCallback(() => {
        updateCLPaymentMehodEditable(true);
        updateCLDueDayOfMonthEditable(true);
        updateCLPrimaryPayerEditable(true);
        updateCLSelectedBillingMethodPAYG(false);
        updateCLSelectedBillingMethodAB(false);

        updateCLBillingMethodForPAYG(false);
        updateCLPrimaryPayerOptionsAndValue('originalDisplayName');
    }, [updateCLBillingMethodForPAYG, updateCLPrimaryPayerOptionsAndValue]);

    const updateCLAgencyBillRelatedChanges = useCallback(() => {
        updatePaymentMethodValue('Check');
        updateCLPaymentMehodEditable(false);
        updateCLDueDayOfMonthEditable(false);
        updateCLPrimaryPayerEditable(false);
        _.set(model, 'isAutoPay', false);
        _.set(model, 'payUsing', 'Check');
        updateCLSelectedBillingMethodPAYG(false);
        // CL Selected Billing Method
        updateCLSelectedBillingMethodAB(true); 
        // handle agency bill Primary Payer display logic
        updateAgencyBillPrimaryPayerAvailableValues([{code: 'producerOfRecordName', name: producerOfRecordName}]);
        // update primary payer displayName to Producer
        updateAgencyBillPrimaryPayer('producerOfRecordName');

        updateCLBillingMethodForPAYG(false);
    }, [model, producerOfRecordName, updateCLBillingMethodForPAYG]);

    const updateCLPAYGPrimaryPayerOptionsAndValue = useCallback(() => {
        // reset Primary Payer to account holder
        const accountHolder = _.get(model, 'accountHolder');
        if (accountHolder) {
            resetPAYGPrimaryPayerOptions(null, accountHolder);
            updatePrimaryPayer(JSON.stringify(accountHolder));
        }
    }, [model, resetPAYGPrimaryPayerOptions]);

    const updateCLPayAsYouGoRelatedChanges = useCallback(() => {
        updatePaymentMethodValue('Check');
        updateCLPaymentMehodEditable(false);
        updateCLDueDayOfMonthEditable(false);
        updateCLPrimaryPayerEditable(true);
        _.set(model, 'isAutoPay', false);
        _.set(model, 'payUsing', 'Check');
        updateCLSelectedBillingMethodPAYG(true);
        updateCLSelectedBillingMethodAB(false); 
        updateCLBillingMethodForPAYG(true);
        updateCLPAYGPrimaryPayerOptionsAndValue();
    }, [model, updateCLBillingMethodForPAYG, updateCLPAYGPrimaryPayerOptionsAndValue]);

    useEffect(() => {
        // loading data options from PC
        async function retrieveQuotePaymentDetailsData() {
            const rs = isCLProduct ? 
                await CAPaymentService
                    .retrieveCLQuotePaymentDetailsData(quoteID, sessionUUID, authHeader)
                :await WniLoadSaveService
                    .retrieveQuotePaymentDetailsData(quoteID, authHeader);
            return rs;
        }
        setLoadingMask(true);
        
        retrieveQuotePaymentDetailsData().then((res) => {
            // update ApplyToOtherQuotesOption when this is CL
            let clSelectedBillingMethod = null;
            let rowDataPrimaryNameInsured = null;
            if (isCLProduct) {
                // CL Billing Method
                const clBillingMethods = _.get(res, 'bindData.billingMethodRange_Ext');
                _.set(model, 'billingMethodOptions', clBillingMethods);
                // CL Selected Billing Method
                clSelectedBillingMethod = _.get(res, 'bindData.selectedBillingMethod_Ext');
                updateCLBillingMethodValue(clSelectedBillingMethod);

                // CL Primary Name Insured
                rowDataPrimaryNameInsured = _.get(res, 'baseData.accountHolder');
                _.set(rowDataPrimaryNameInsured, 'phoneRequired_Ext', false);
                _.set(model, 'accountHolder', _.get(res, 'baseData.accountHolder'));
                // CL AGENCY BILL Special disable logic
                switch (clSelectedBillingMethod) {
                    case 'AGENCY_BILL':
                        updateCLAgencyBillRelatedChanges();
                        break;
                    case 'PAY_AS_YOU_GO':
                        updateCLPayAsYouGoRelatedChanges();
                        break;
                    case 'DIRECT_BILL':
                        enableDirectBillFieldsEditable();
                        break;
                    default: break;
                }

                // CL Due day of Month
                const clDuesDayOfMonth = _.get(res, 'bindData.dueDayOfTheMonth_Ext');
                updateCLDueDayOfMonth(clDuesDayOfMonth);
                
                // selectedPaymentPlan
                const newSelectedPaymentPlan = _.get(res, 'bindData.selectedPaymentPlan');
                updateCLSelectedPaymentPlan(newSelectedPaymentPlan);

                // apply to other quotes
                updateCLApplyToOtherQuotesOptions(clSelectedBillingMethod);
            }
            

            // paymentMethods
            const payInstrucments = _.get(res, 'bindData.payInstrucments_Ext');
            const newPaymentMethods = PaymentUtil.initPaymentMethods(
                payInstrucments, viewModelService
            );
            updatePaymentMethodsOptions(newPaymentMethods);
            _.set(model, 'bindData.payInstrucments_Ext', payInstrucments);

            // paymentPlans
            const newPaymentPlans = _.get(res, 'bindData.paymentPlans_Ext');
            const newPaymentPlanOptions = _.map(newPaymentPlans, (row) => {
                return PaymentUtil.forMatterPaymentPlan(row);
            });
            updatePaymentPlanOptions(newPaymentPlanOptions);

            const billingContacts = _.get(res, 'bindData.billingContacts_Ext');
            const nonBillingContacts = _.get(res, 'bindData.nonBillingContacts_Ext');

            _.set(model, 'bindData.billingContacts_Ext', billingContacts);
            _.set(model, 'bindData.nonBillingContacts_Ext', nonBillingContacts);

            // set up init primaryPayer_Ext
            let rowDataInitPrimaryPayer = _.get(rowData, 'primaryPayer');
            if (rowDataInitPrimaryPayer) {
                // set phoneRequired_Ext flag to false to avoid dto error
                _.set(rowDataInitPrimaryPayer, 'phoneRequired_Ext', false);
            }


            // set default dueDate to invoiceStreamDay when invoiceStreamCode exists
            const invoiceStreamCode = _.get(rowData, 'invoiceStreamCode', '');
            if (invoiceStreamCode && !isCLProduct) {
                _.set(rowData, 'primaryPayer', _.get(rowData, 'invoiceStreamPrimaryPayer'));
                // set dueDayOfTheMonth value with invoiceStreamDay
                _.set(model, 'dueDayOfTheMonth', _.get(rowData, 'invoiceStreamDay', ''));
            }

            // set up default primaryPayer_Ext
            let defaultPrimaryPayer = _.get(model, 'primaryPayer');
            if (defaultPrimaryPayer) {
                // set phoneRequired_Ext flag to false to avoid dto error
                _.set(model, 'primaryPayer.phoneRequired_Ext', false);
                const newAddedPrimaryPayer = _.get(model, 'newAddedPrimaryPayer');
                if (newAddedPrimaryPayer) {
                    // set phoneRequired_Ext flag to false to avoid dto error
                    _.set(model, 'newAddedPrimaryPayer.phoneRequired_Ext', false);
                    const firstName = _.get(newAddedPrimaryPayer, 'firstName');
                    const lastName = _.get(newAddedPrimaryPayer, 'lastName');
                    const inputUserName = `${firstName} ${lastName}`;
                    const newAddedContact = _.find(billingContacts, (payer) => {
                        return inputUserName === _.get(payer, 'displayName');
                    });
                    if (newAddedContact) {
                        _.set(newAddedContact, 'phoneRequired_Ext', false);
                        updatePrimaryPayer(JSON.stringify(newAddedContact));
                    } else {
                        // add a duplicate primarypayer and not exist in billingContacts
                        _.set(model, 'primaryPayer', newAddedPrimaryPayer);
                        _.set(model, 'primaryPayer.phoneRequired_Ext', false);
                        defaultPrimaryPayer = _.get(model, 'primaryPayer');
                        rowDataInitPrimaryPayer = defaultPrimaryPayer;
                        updatePrimaryPayer(JSON.stringify(defaultPrimaryPayer));
                    }
                } else {
                    updatePrimaryPayer(JSON.stringify(defaultPrimaryPayer));
                }
                if (isCLProduct && clSelectedBillingMethod === 'PAY_AS_YOU_GO') {
                    // for billing method is Pay As You Go
                    const defaultPAYGPayer = newAddedPrimaryPayer || defaultPrimaryPayer
                    resetPAYGPrimaryPayerOptions(defaultPAYGPayer, rowDataPrimaryNameInsured);
                } else {
                    resetPrimaryPayerOptions(defaultPrimaryPayer, rowDataInitPrimaryPayer);
                }
            } else {
                defaultPrimaryPayer = '-';
                if (isCLProduct && clSelectedBillingMethod === 'PAY_AS_YOU_GO') {
                    // for billing method is Pay As You Go
                    resetPAYGPrimaryPayerOptions(null, rowDataPrimaryNameInsured);
                } else {
                    resetPrimaryPayerOptions(null, rowDataInitPrimaryPayer);
                }
            }

            const isAutoPay = _.get(model, 'isAutoPay');
            if (isAutoPay) {
                const payUsing = _.get(model, 'payUsing');
                updatePaymentMethodValue(payUsing);
            } else {
                updatePaymentMethodValue('Check');
            }

            if (!isCLProduct) {
                // update applyToOtherQuotesAvailableValues
                const nonMortgageBills = _.filter(quoteTableData, (data) => {
                    // exclude HO mortgage bill quote
                    const isMortgageBill = _.get(data, 'mortgageBill');
                    return !isMortgageBill && _.get(data, 'quoteNumber') !== quoteID;
                });
                const newapplyToOtherQuotesAvailableValues = _.map(nonMortgageBills, (option) => {
                    const productCode = _.get(option, 'product.productCode');
                    return {
                        code: JSON.stringify({ quoteID: _.get(option, 'quoteNumber'), sessionUUID: _.get(option, 'sessionUUID') }),
                        name: `${getProductName(productCode, translator)}-${_.get(option, 'quoteNumber')}`
                    };
                });
                updateapplyToOtherQuotesAvailableValues(newapplyToOtherQuotesAvailableValues || []);
            }

            const item = {
                title: title,
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: showCancelBtn,
                actionBtnLabel: messages.dialogOk,
                cancelBtnLabel: messages.dialogCancel,
                authHeader,
                rowData: rowData,
                warningMessage: translator(messages.editPaymentDetailsWarning),
                rePopupEditQuoteRow: rePopupEditQuoteRow,
                fetchedAccountTableData: fetchedAccountTableData,
                quoteTableData: quoteTableData,
                editedData: model,
                setLoadingMask,
                getProductName,
                viewModelService,
                breakpoint,
                producerOfRecordName
            };
            updateSelectedItem(item);
        }).finally(() => {
            setLoadingMask(false);
        });
    }, []);

    const innerNewPersonPopup = useCallback(() => {
        const vmObject = {
            value: {
                quoteID: quoteID,
                sessionUUID: sessionUUID,
                baseData: {
                    accountNumber: accountNumber
                }
            }
        };
        const modalProps = {
            title: translator(messages.newPersonTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            authHeader,
            submissionVM: vmObject,
            primaryPayerAvailableValues,
            requireProductsContainer: false,
            useAuthenticationData,
            useDependenciesData,
            viewModelService,
            clSelectedBillingMethodPAYG
        };

        return Promise.resolve(onReject()).then(() => {
            let newAddPrimaryPayer = null;
            modalApi.showModal(<NewPersonComponent {...modalProps} />)
                .then((res) => {
                    newAddPrimaryPayer = res;
                    return res;
                }).catch(() => {
                    return false;
                }).finally(() => {
                    // keep the edtied data
                    _.set(model, 'newAddedPrimaryPayer', newAddPrimaryPayer);
                    _.set(selectedItem, 'editedData', model);
                    // update option and value
                    // _.set(selectedItem, 'rowData.newAddedPrimaryPayer', newAddPrimaryPayer);
                    rePopupEditQuoteRow(selectedItem);
                    _.noop();
                });
        });
    }, [quoteID, sessionUUID, accountNumber, translator, authHeader, primaryPayerAvailableValues, useAuthenticationData,
        useDependenciesData, viewModelService, clSelectedBillingMethodPAYG, onReject, modalApi, model, selectedItem, rePopupEditQuoteRow]);

    const onNewPersonClick = useCallback(() => {
        return innerNewPersonPopup();
    }, [innerNewPersonPopup]);

    const primaryPayerChange = useCallback((value) => {
        if (value === 'Add New Person') {
            onNewPersonClick();
        } else {
            updatePrimaryPayer(value);
            const contact = JSON.parse(value);
            // set phoneRequired_Ext flag to false to avoid dto error
            _.set(contact, 'phoneRequired_Ext', false);
            _.set(model, 'primaryPayer', contact);
            _.set(model, 'newAddedPrimaryPayer', false);
        }
    }, [model, onNewPersonClick]);


    const writeValue = useCallback((value, path) => {
        const newModel = _.cloneDeep(model);
        _.set(newModel, `${path}`, value);
        updateModel(newModel);
    }, [model]);

    const getDueDayOfMonthOptions = useCallback(() => {
        const options = [...Array(31).keys()].map((i) => ({
            code: i + 1,
            name: i + 1,
        }));
        return options;
    }, []);

    const showAddpaymentMethodPopup = useCallback((getPaymentDetailVM) => {
        const componentProps = {
            title: translator(messages.addPaymentMethodTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            paymentDetailVM: getPaymentDetailVM,
            viewModelService: viewModelService,
            breakpoint
        };
        return modalApi.showModal(<BaseAddPaymentMethodPopup {...componentProps} />);
    }, [breakpoint, modalApi, translator, viewModelService]);

    const popupNewBankAccount = useCallback(() => {
        // popup a window to enter
        const paymentDetails = {
            paymentMethod: 'autopay_Ext',
            bankAccountData: {
                bankAccountType: 'checking'
            }
        };
        const popupOneTimePaymentMethodVM = viewModelService.create(paymentDetails, 'pc', 'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO');
        return Promise.resolve(onReject()).then(() => {
            let code = _.get(model, 'payUsing', '');
            showAddpaymentMethodPopup(popupOneTimePaymentMethodVM).then(async (updatedVM) => {
                // check payment exist in payment methods
                const result = PaymentUtil.checkPaymentMethodExist(
                    updatedVM, paymentMethodsOptions
                );
                if (_.isEmpty(result)) {
                    // get new payInstrument publicID
                    const publicID = await PaymentUtil.savePayInstrucment(updatedVM,
                        setLoadingMask, WniPAQuoteService.savePayInstrucmentService,
                        quoteID, sessionUUID, authHeader);
                    _.set(updatedVM, 'value.bankAccountData.publicID', publicID);
                    code = publicID;
                    return true;
                }
                code = _.get(result, 'value.bankAccountData.publicID');
                // duplicate
                return false;
            }).catch(() => {
                _.noop();
                return false;
            }).finally(() => {
                // update options and value
                _.set(selectedItem, 'rowData.payUsing', code);
                rePopupEditQuoteRow(selectedItem);
                _.noop();
            });
        });
    }, [authHeader, onReject, paymentMethodsOptions,
        quoteID, rePopupEditQuoteRow, selectedItem,
        sessionUUID, setLoadingMask, showAddpaymentMethodPopup,
        viewModelService, model]);

    const paymentPlanChange = useCallback((value) => {
        updateCLSelectedPaymentPlan(value);
        writeValue(value, 'selectedPaymentPlan');
    }, [writeValue]);

    const clDueDayOfMonthChange = useCallback((value) => {
        updateCLDueDayOfMonth(value);
        writeValue(value, 'dueDayOfTheMonth');
    }, [writeValue]);

    const updatePaymentMethod = useCallback((value) => {
        // addNewBankAccount
        if (value === 'Add New AutoPay Account') {
            // popup new Bank account
            AQPaymentPageUtil.popupNewBankAccount(viewModelService, onReject, model,
                showAddpaymentMethodPopup, PaymentUtil, paymentMethodsOptions,
                setLoadingMask, WniPAQuoteService, quoteID, sessionUUID,
                authHeader, rePopupEditQuoteRow, selectedItem);
        } else if (value === 'Check') {
            updateHasSelectAutoPay(false);
        } else {
            // override the due date
            // use the bankAccount info to find from the accountData
            // sort the finding results
            // use the invoicing number biggerest dueday
            const existings = _.filter(fetchedAccountTableData, (data) => {
                return _.get(data, 'payUsing') === value;
            });
            if (_.isEmpty(existings)) {
                updateHasSelectAutoPay(false);
            } else {
                updateHasSelectAutoPay(true);
                const sorted = _.sortBy(existings, 'invoiceStreamDescription');
                const biggestInvoiceStreamNumber = sorted[sorted.length - 1];
                const invoiceStreamDueDay = _.get(biggestInvoiceStreamNumber, 'invoiceStreamDay');
                _.set(model, 'dueDayOfTheMonth', invoiceStreamDueDay);
                _.set(model, 'invoiceStreamDay', invoiceStreamDueDay);
                const payUsingName = _.get(biggestInvoiceStreamNumber, 'payUsingName');
                _.set(model, 'payUsingName', payUsingName);
            }
            // set isAutoPay to true for current edit model
            _.set(model, 'isAutoPay', true);
        }
        writeValue(value, 'payUsing');
        updatePaymentMethodValue(value);
    }, [authHeader, fetchedAccountTableData, model, onReject,
        paymentMethodsOptions, quoteID, rePopupEditQuoteRow,
        selectedItem, sessionUUID, setLoadingMask,
        showAddpaymentMethodPopup, viewModelService, writeValue]);

    const parseApplyToOtherQuotes = useCallback(() => {
        // parse applyToOtherQuotes from string to object
        const applyToOtherQuotes = _.get(model, 'applyToOtherQuotes');
        const applyToOtherQuotesObjects = [];
        if (!_.isEmpty(applyToOtherQuotes)) {
            _.each(applyToOtherQuotes, (str) => {
                applyToOtherQuotesObjects.push(JSON.parse(str));
            });
            _.set(model, 'applyToOtherQuotes', applyToOtherQuotesObjects);
        }
    }, [model]);

    const handleSave = useCallback(async () => {
        parseApplyToOtherQuotes();
        // select the PrimaryPayer value
        const currentIsAgency = _.isEqual(primaryPayer, 'producerOfRecordName');
        if (primaryPayer && !currentIsAgency) {
            primaryPayerChange(primaryPayer);
        }
        _.set(model, 'isCL', isCLProduct);
        return onResolve(model);
    }, [model, onResolve, parseApplyToOtherQuotes, primaryPayer, primaryPayerChange, isCLProduct]);

    const handleApplyToOthersChange = useCallback((value) => {
        updateApplyToOtherQuotesValue(value);
        writeValue(value, 'applyToOtherQuotes');
    }, [writeValue])

    const updateCLApplyToOtherQuotesOptions = useCallback((value) => {
        // update applyToOtherQuotesAvailableValues
        const nonMortgageBills = _.filter(quoteTableData, (data) => {
            // exclude HO mortgage bill quote
            const isMortgageBill = _.get(data, 'mortgageBill');
            return !isMortgageBill && _.get(data, 'quoteNumber') !== quoteID;
        });
        const premium = _.get(model, 'premium.totalCost_Ext.amount', 0);
        const hasTakenPayAsYouGoOffering = _.get(model, 'hasTakenPayAsYouGoOffering');
        let options = nonMortgageBills;
        if (value === 'PAY_AS_YOU_GO') {
            options = _.filter(nonMortgageBills, (option) => {
                const optionPremium = _.get(option, 'premium.totalCost_Ext.amount', 0);
                const optionHasTakenPayAsYouGoOffering = _.get(option, 'hasTakenPayAsYouGoOffering');
                const isSameLevelPremium = isPremiumMeetPAYG(premium) === isPremiumMeetPAYG(optionPremium);
                const isSameHasTakenPayAsYouGoOffering = hasTakenPayAsYouGoOffering === optionHasTakenPayAsYouGoOffering;
                if (isSameLevelPremium && isSameHasTakenPayAsYouGoOffering) {
                    const billingMethodOptions = _.get(option, 'billingMethodOptions');
                    return _.includes(billingMethodOptions, 'PAY_AS_YOU_GO');
                }
            });
        }
        const newapplyToOtherQuotesAvailableValues = _.map(options, (option) => {
            const productCode = _.get(option, 'product.productCode');
            return {
                code: JSON.stringify({ quoteID: _.get(option, 'quoteNumber'), sessionUUID: _.get(option, 'sessionUUID') }),
                name: `${getProductName(productCode, translator)}-${_.get(option, 'quoteNumber')}`
            };
        });
        updateapplyToOtherQuotesAvailableValues(newapplyToOtherQuotesAvailableValues || []);
    }, [getProductName, isPremiumMeetPAYG, model, quoteID, quoteTableData, translator]);

    const updateCLBillingMethod = useCallback(async (value) => {
        if (value === _.get(model, 'selectedBillingMethod')) {
            return;
        } 
        if (value === 'AGENCY_BILL') {
            updateCLAgencyBillRelatedChanges();
        } else if (value === "PAY_AS_YOU_GO"){
            updateCLPayAsYouGoRelatedChanges();
        } else {
            enableDirectBillFieldsEditable();
        }
        updateCLBillingMethodValue(value);
        // writeValue(value, 'selectedBillingMethod');
        _.set(model, 'selectedBillingMethod', value);
        // _.unset(model, 'selectedPaymentPlan');
        // hide the cancel button
        _.set(selectedItem, 'showCancelBtn', false);
        try {
            setLoadingMask(true);
            const res = await CAPaymentService.updateBillingMethodAndRetrievePaymentData(quoteID, sessionUUID, value, authHeader);
            // paymentPlans
            const newPaymentPlans = _.get(res, 'bindData.paymentPlans_Ext');
            const newPaymentPlanOptions = _.map(newPaymentPlans, (row) => {
                return PaymentUtil.forMatterPaymentPlan(row);
            });
            updatePaymentPlanOptions(newPaymentPlanOptions);
            // selectedPaymentPlan
            const newSelectedPaymentPlan = _.get(res, 'bindData.selectedPaymentPlan');
            updateCLSelectedPaymentPlan(newSelectedPaymentPlan);
            _.set(model, 'selectedPaymentPlan', newSelectedPaymentPlan);
            // dueDate
            const newDueDayOfMonth = _.get(res, 'bindData.dueDayOfTheMonth_Ext');
            updateCLDueDayOfMonth(newDueDayOfMonth);
            _.set(model, 'dueDayOfTheMonth', newDueDayOfMonth);
            // paymentMethod options
            const payInstrucments = _.get(res, 'bindData.payInstrucments_Ext');
            const newPaymentMethods = PaymentUtil.initPaymentMethods(
                payInstrucments, viewModelService
            );
            updatePaymentMethodsOptions(newPaymentMethods);
            // _.set(model, 'bindData.payInstrucments_Ext', payInstrucments);
            // update ApplyToOtherQuotesOption when this is CL
            if (isCLProduct) {
                updateCLApplyToOtherQuotesOptions(value);
            }
            // update value
            const newModel = _.cloneDeep(model);
            updateModel(newModel);
        } catch (ex) {
            updatePaymentPlanOptions([]);
        } finally {
            setLoadingMask(false);
        }
    }, [authHeader, enableDirectBillFieldsEditable, isCLProduct, model, quoteID, selectedItem, sessionUUID, setLoadingMask, updateCLAgencyBillRelatedChanges, updateCLApplyToOtherQuotesOptions, updateCLPayAsYouGoRelatedChanges, viewModelService]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
        notificationSection: {
            className: styles.marginBottom
        },
        warningMessage: {
            visible: hasSelectAutoPay,
            content: warningMessage
        },
        paymentMethod: {
            label: translator(messages.paymentMethod),
            availableValues: AQPaymentPageUtil.getPaymentDetailsData(paymentMethodsOptions),
            onValueChange: updatePaymentMethod,
            value: paymentMethodValue
        },
        dueDayOfMonth: {
            path: 'dueDayOfTheMonth',
            label: translator(messages.dueDayOfMonth),
            availableValues: getDueDayOfMonthOptions()
        },
        paymentPlan: {
            path: 'selectedPaymentPlan',
            label: translator(messages.paymentPlan),
            availableValues: paymentPlanOptions
        },
        primaryPayer: {
            label: translator(messages.primaryPayer),
            availableValues: primaryPayerAvailableValues,
            value: primaryPayer,
            onValueChange: primaryPayerChange
        },
        applyToOtherQuotes: {
            id: 'applyToOtherQuotes',
            path: 'applyToOtherQuotes',
            label: translator(messages.applyToOtherQuotes),
            availableValues: applyToOtherQuotesAvailableValues,
            value: applyToOtherQuotesValue,
            onValueChange: handleApplyToOthersChange
        },
        clInputSection: {
            visible: isCLProduct
        },
        inputSection: {
            visible: !isCLProduct
        },
        clBillingMethod: {
            label: translator(messages.billingMethod),
            availableValues: clBillingMethodsOptions,
            onValueChange: updateCLBillingMethod,
            value: clBillingMethodValue
        },
        clPaymentMethod: {
            label: translator(messages.paymentMethod),
            availableValues: AQPaymentPageUtil.getPaymentDetailsData(paymentMethodsOptions),
            onValueChange: updatePaymentMethod,
            value: paymentMethodValue,
            disabled: !clPaymentMehodEditable
        },
        clDueDayOfMonth: {
            path: 'dueDayOfTheMonth',
            label: translator(messages.dueDayOfMonth),
            availableValues: getDueDayOfMonthOptions(),
            disabled: !clDueDayOfMonthEditable,
            value: clDueDayOfMonth,
            onValueChange: clDueDayOfMonthChange
        },
        clPaymentPlan: {
            path: 'selectedPaymentPlan',
            label: translator(messages.paymentPlan),
            availableValues: paymentPlanOptions,
            value: clSelectedPaymentPlan,
            onValueChange: paymentPlanChange
        },
        clPrimaryPayer: {
            label: translator(messages.primaryPayer),
            availableValues: clSelectedBillingMethodAB ? agencyBillPrimaryPayerAvailableValues : primaryPayerAvailableValues,
            disabled: !clPrimaryPayerEditable,
            value: clSelectedBillingMethodAB ? agencyBillPrimaryPayer : primaryPayer,
            onValueChange: primaryPayerChange
        },
        clApplyToOtherQuotes: {
            id: 'applyToOtherQuotes',
            path: 'applyToOtherQuotes',
            label: translator(messages.applyToOtherQuotes),
            availableValues: applyToOtherQuotesAvailableValues,
            value: applyToOtherQuotesValue,
            onValueChange: handleApplyToOthersChange
        }, 
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
        },
    };

    const cancelBtnClass = _.get(selectedItem, 'showCancelBtn', true) ? '' : styles.hidden;

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="paymentDetailPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={model}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined" className={cancelBtnClass}>{cancelBtnLabel}</Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

EditQuotePopup.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    warningMessage: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
        quoteNumber: PropTypes.string,
        sessionUUID: PropTypes.string,
        accountNumber: PropTypes.string
    }).isRequired,
    rePopupEditQuoteRow: PropTypes.func,
    fetchedAccountTableData: PropTypes.arrayOf(PropTypes.shape({})),
    quoteTableData: PropTypes.arrayOf(PropTypes.shape({})),
    showCancelBtn: PropTypes.bool
}
EditQuotePopup.defaultProps = {
    size: 'md',
    rePopupEditQuoteRow: _.noop,
    fetchedAccountTableData: [],
    quoteTableData: [],
    showCancelBtn: false
};
export default EditQuotePopup;
