import { defineMessages } from 'react-intl';

export default defineMessages({
    linkUnderwriter: {
        id: 'wizard.template.link.underwriter',
        defaultMessage: 'My Underwriter'
    },
    linkAttachment: {
        id: 'wizard.template.link.attachment',
        defaultMessage: 'Attachment'
    },
    linkUploadAttachment: {
        id: 'wizard.template.link.Upload Attachments',
        defaultMessage: 'Upload Attachments'
    },
    copySubmission: {
        id: 'wizard.template.link.CopySubmission',
        defaultMessage: 'Copy Submission'
    },
    copyNewQuote: {
        id: 'wizard.template.link.Copy to New Quote',
        defaultMessage: 'Copy to New Quote'
    },
    createHomeOwnerQuote: {
        id: 'wizard.template.link.CreateHomeOwnerQuote',
        defaultMessage: 'Create a Home Quote'
    },
    createDwellingPropertyQuote: {
        id: 'wizard.template.link.CreateDwellingPropertyQuote',
        defaultMessage: 'Create a Dwelling Property Quote'
    },
    viewCoverageForms: {
        id: 'wizard.template.link.ViewCoverageForms',
        defaultMessage: 'View Coverage Forms'
    },
    startNewQuote: {
        id: 'wizard.template.link.startNewQuote',
        defaultMessage: 'Start New Quote'
    }
});
