import React, {
    Fragment,
    useContext,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useErrors, ErrorBoundary } from '@xengage/gw-portals-error-react';
import { WizardUtil } from 'wni-portals-util-js';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { messages as customMessages } from 'wni-platform-translations';
import { WizardContext, WizardPage } from '@xengage/gw-portals-wizard-react';
import { WizardConstants } from 'wni-portals-config-js';
import { WizardErrorContext, ErrorHandlingWizardPage } from 'wni-portals-wizard-react';
// import { BaseWizardPage } from 'wni-portals-wizard-react';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

/**
 * Solution Proposals for handling onNext errors:
 * 1) Each page calls WniCommonQuoteService.getErrorsAndWarnigns() on its own onNext,
 *      and manullay update wizardPageData[wizardPageErrorsAndWarnings]
 * 2) Set "checkErrorsAndWarningsOnPageNext" on IMWizardPage to let it handle the task automatically;'
 *      Note that similar feature could be introduced for page initialization, e.g. checkErrorsAndWarningsOnPageInit
 * 3) 
 * 
 * @param {object} props 
 * @returns {function}
 */
function IMWizardPage(props) {
    return (
        <ErrorHandlingWizardPage {...props} />
    );
};

IMWizardPage.propTypes =  ErrorHandlingWizardPage.propTypes;
IMWizardPage.defaultProps = ErrorHandlingWizardPage.defaultProps;
export default IMWizardPage;