import { MetadataContent } from '@jutro/uiconfig';
import _, { includes } from 'lodash';
import React from 'react';

const UnmannedAircraftFieldMap = (props) => {
    const {
        vm,
        dataPath,
        invalidFields,
        onValueChange,
        onValidate,
        onBlur,
        onSearch,
        showErrors,
        readOnly
    } = props;

    const fieldModels = _.get(vm.value, dataPath, []);
    const handleValueChange = (newValue, index, fieldModel) => {
        onValueChange(newValue, `${dataPath}[${index}]`, fieldModel);
    };

    const handleBlur = (value, fieldItem) => {
        onBlur(value, fieldItem);
    };

    const registerAdditionalValidation = () => {
        return _.isEmpty(invalidFields);
    }

    const metadata = fieldModels.map((fieldItem, index) => {
        const invalidField = _.find(invalidFields, (item) => item.propertyName === fieldItem.propertyName);
        const fieldMessage = _.get(invalidField, 'validationMessage', null);
        const showAdditionalErrors = fieldMessage && !registerAdditionalValidation();
        const newFieldMessage = fieldMessage ? _.replace(fieldMessage, `${fieldItem.label} : `, '') : fieldMessage
        return {
            id: `${fieldItem.propertyName}Field`,
            type: 'field',
            component: 'WniFieldSetModel',
            componentProps: {
                showRequired: true,
                showErrors: showAdditionalErrors || showErrors,
                readOnly: readOnly || fieldItem.readonly,
                onValueChange: (value, fieldModel) => handleValueChange(value, index, fieldModel),
                onBlur: (value) => handleBlur(value, fieldItem),
                fieldItem,
                onValidate,
                onSearch,
                additionalValidationFn: showAdditionalErrors && registerAdditionalValidation,
                additionalValidationMessages: showAdditionalErrors && [newFieldMessage]
            },
        };
    });

    const overrides = {};
    const resolvers = {
        resolveComponentMap: {},
    };

    return (
        <MetadataContent
            uiProps={metadata}
            overrideProps={overrides}
            {...resolvers}
        />
    );
};

export default UnmannedAircraftFieldMap;
