import { setComponentMapOverrides } from '@jutro/uiconfig';

import { PaymentDetailsPage, QualificationPage } from 'gw-capability-quoteandbind-common-react';

import BOPYourInfoPage from './pages/YourInfo/YourInfoPage';
import BOPGeneralCoveragesPage from './pages/GeneralCoverages/GeneralCoveragesPage';
import BOPAdditionalCoveragesPage from './pages/AdditionalCoverages/AdditionalCoveragesPage';
import BOPLocationsAndBuildingsPage from './pages/LocationsAndBuildings/LocationsAndBuildingsPage';
import BOPQuotePage from './pages/Quote/QuotePage';
import BOPAdditionalInformation from './pages/AdditionalInformation/AdditionalInformationPage';
import BOPPolicyInformationPage from './pages/PolicyInformation/PolicyInformationPage';
import BOPSuccessPage from './pages/Success/SuccessPage';

setComponentMapOverrides(
    {
        BOPYourInfoPage: { component: 'BOPYourInfoPage' },
        BOPQualificationPage: { component: 'BOPQualificationPage' },
        BOPGeneralCoveragesPage: { component: 'BOPGeneralCoveragesPage' },
        BOPAdditionalCoveragesPage: { component: 'BOPAdditionalCoveragesPage' },
        BOPLocationsAndBuildingsPage: { component: 'BOPLocationsAndBuildingsPage' },
        BOPQuotePage: { component: 'BOPQuotePage' },
        BOPAdditionalInformation: { component: 'BOPAdditionalInformation' },
        BOPPolicyInformationPage: { component: 'BOPPolicyInformationPage' },
        BOPPaymentDetailsPage: { component: 'BOPPaymentDetailsPage' },
        BOPSuccessPage: { component: 'BOPSuccessPage' }
    },
    {
        BOPYourInfoPage,
        BOPQualificationPage: QualificationPage,
        BOPGeneralCoveragesPage,
        BOPAdditionalCoveragesPage,
        BOPLocationsAndBuildingsPage,
        BOPQuotePage,
        BOPAdditionalInformation,
        BOPPolicyInformationPage,
        BOPPaymentDetailsPage: PaymentDetailsPage,
        BOPSuccessPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as BOPWizard } from './BOPWizard';
