import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _, { remove } from 'lodash';
import {
    Button,
    ModalNext,
    ModalBody,
    Loader,
    TooltipIcon,
    Tooltip} from '@jutro/components';
import { Flex } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import classNames from 'classnames';

import AnimalFormComponent from '../AnimalFormComponent/AnimalFormComponent';
import messages from '../../HOResidencePage.messages';
import metadata from './AnimalsPopupComponent.metadata.json5';
import styles from '../../HOResidencePage.module.scss'
import AnimalsUtil from '../../util/AnimalsUtil';

function AnimalsPopupComponent(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        baseState,
        animalsVM,
        isReadOnly,
        editAnimal,
        removeAnimals,
        isSubmissionType,
        viewModelService,
        translator,
        linePath
    } = props;

    const [showErrors, updateShowErrors] = useState(false);
    const [currentAnimalVM, updateCurrentAnimalVM] = useState();
    const [selection, updateSelection] = useState([]);
    const [loadingAnimalsTable, updateLoadingAnimalsTable] = useState(false);
    const [animalsStateVM, updateAnimalsStateVM] = useState(animalsVM);
    const [originAnimalVM, updateOriginAnimalVM] = useState(currentAnimalVM);
    const [originAnimalsVM, updateOriginAnimalsVM] = useState(animalsVM);
    const ineligibleWarningStyle = classNames('wniKnockoutToolTip');

    const {animals: animalsList} = animalsVM.value || {};
   
    const addAnimals = () => {
        let newVM;
        if (linePath === 'dwellingProperty') {
            newVM = viewModelService.create(
                {},
                'pc',
                'wni.edge.capabilities.policyjob.lob.dwellingproperty.coverables.dto.DPAnimalDTO'
            );
        } else {
            newVM = viewModelService.create(
                {},
                'pc',
                'wni.edge.capabilities.policyjob.lob.homeowners.coverables.dto.AnimalDTO'
            );
        }
        updateCurrentAnimalVM(newVM);

        const newAnimalsVM = viewModelService.clone(animalsStateVM);
        const exsitAnimals = _.get(animalsStateVM, 'value.animals') || [];
        const animals = [...exsitAnimals, newVM.value];
        _.set(newAnimalsVM, 'animals', animals);
        updateAnimalsStateVM(newAnimalsVM);
    };

    const removeSelectedAnimals = async() => {
        const animals = _.get(animalsStateVM, 'value.animals') || [];
        const selectedAnimals = _.filter(animals, (animal, index) => {
            return _.includes(selection, index) && animal.publicID;
        });
        updateLoadingAnimalsTable(true);
        const res = await removeAnimals(selectedAnimals);
        const animalsPath = `lobData.${linePath}.coverables.dwelling_Ext.dwellingAnimals`;
        animalsStateVM.value = _.get(res, animalsPath);
        updateAnimalsStateVM(animalsStateVM);
        updateCurrentAnimalVM(null);
        updateSelection([]);
        updateLoadingAnimalsTable(false);
    };

    const viewOrEditAnimal = (animal) => {
        const dwellingAnimalsVM = _.get(animalsStateVM, 'animals.children') || [];
        _.each(dwellingAnimalsVM, (animalVM) => {
            if (animalVM.value.publicID === animal.publicID) {
                updateOriginAnimalVM(viewModelService.clone(animalVM));
                updateCurrentAnimalVM(animalVM);
            }
        });
    };

    const renderViewEditDom = () => {
        return (
            <div className="activeRow btn-link">
                {isReadOnly ? translator(messages.viewLabel) : translator(messages.viewAndEditLabel)}
            </div>
        );
    };

    const isAddAnimalDisabled = useCallback(() => {
        const animals = _.get(animalsStateVM, 'value.animals');
        const newlyAnimal = _.filter(animals, (animal) => {
            return !animal.publicID
        })
        if (_.isEmpty(newlyAnimal)) {
            return false;
        }
        return true;
    }, [animalsStateVM]);
    
    const getAnimalTitle = () => {
        const dogs = animalsList?.filter(animal => animal.animalType === 'Dog')
        const showDogCountKnockout = linePath === 'homeowners' && !_.isNil(dogs) && dogs.length > 3
        return (
            <Flex justifyContent="left" gap="none" alignContent="middle" className={`wniKnockoutToolTip ${styles.animalToolTip}`}>
            <span>Animal</span>
            {showDogCountKnockout ? <TooltipIcon
                id="wniTooltipContent"
                title="Ineligible"
                icon="gw-error"
                className={styles.animalToolTipIcon}
                showOnInit={showDogCountKnockout}
                text={translator(messages.dogCountIneligible)}
            /> : null
            }
            
        </Flex>
        );
    };

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                showOptional: false,
                labelPosition: 'left',
                showRequired: true,
                showErrors: showErrors
            },
            animalsTable: {
                data: _.get(animalsStateVM, 'value.animals') || [],
                onSelectionChange: (rows) => updateSelection(rows),
            },
            type: {
                cell: (item) => AnimalsUtil.onTypeCell(item, translator)
            },
            breed: {
                cell: (item) => AnimalsUtil.onBreedTypeCell(item, translator)
            },
            animalBiteHistory: {
                cell: (item) => AnimalsUtil.onBiteHistoryCell(item)
            },
            describe: {
                cell: (item) => AnimalsUtil.onDescribeCell(item)
            },
            excluded: {
                cell: (item) => AnimalsUtil.onExcludedCell(item)
            },
            addAnimal: {
                onClick: addAnimals,
                visible: !isReadOnly,
                disabled: isAddAnimalDisabled()
            },
            removeAnimals: {
                onClick: removeSelectedAnimals,
                visible: !isReadOnly,
                disabled: _.isEmpty(selection)
            },
            viewOrEdit: {
                onClick: viewOrEditAnimal,
                label: renderViewEditDom(),
            },
            animalForm: {
                model: animalsStateVM,
                baseState,
                updateAnimalsVM: updateAnimalsStateVM,
                currentAnimalVM,
                updateCurrentAnimalVM,
                isReadOnly,
                visible: !_.isEmpty(currentAnimalVM),
                editAnimal,
                originAnimalVM,
                viewModelService,
                isSubmissionType,
                linePath
            }
        }
    }, [addAnimals, animalsStateVM, currentAnimalVM, editAnimal, isReadOnly, showErrors, translator, viewOrEditAnimal]);

    const overrideProps = generateOverrides();

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            animalsStateVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            AnimalFormComponent: AnimalFormComponent,
            animalTitleComponent: getAnimalTitle,
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <Button className="modal-close" icon="gw-close" onClick={onReject} />
            <ModalBody id="animalDatailsPage">
                { loadingAnimalsTable ? <Loader loaded={!loadingAnimalsTable} /> : <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={animalsStateVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
                }
            </ModalBody>
        </ModalNext>
    );
}

AnimalsPopupComponent.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    baseState: PropTypes.string,
    animalsVM: PropTypes.shape({}),
    isReadOnly: PropTypes.bool,
    editAnimal: PropTypes.func,
    isSubmissionType: PropTypes.bool,
    removeAnimals: PropTypes.func
};
AnimalsPopupComponent.defaultProps = {
    searchValue: '',
    size: 'md',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    baseState: '',
    animalsVM: null,
    isReadOnly: false,
    editAnimal: _.noop,
    removeAnimals: _.noop
};

export default AnimalsPopupComponent;

