
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator, IntlContext } from '@jutro/locale';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    sectionVisible,
    isLossSettlementValuationMethodVisible,
    //
    isOutboardWatercraft,
    getValuationMethodOptions,
    //
    syncCoverageAmountValueAndMsg,
    syncPhysicalDamageCovRequired,
} from '../../util/Watercraft.util';


import metadata from './WALLossSettlementValuationMethodComponent.metadata.json5';
import styles from './WALLossSettlementValuationMethodComponent.module.scss';

const DTO_FULL_NAME = 'wni.edge.capabilities.quote.lob.watercraft.dto.WALWatercraftDTO';

// ref: WatercraftComponent.metadata.json5
// const DETAIL_BASE_PATHS = [
//     'watercraftDetail',
//     'motorDetail',
//     'trailerDetail',
// ];
/**
 * ref:
 * 1, WatercraftComponent
 * 2, WatercraftDetails
 * 3, MotorDetails
 * 4, TrailerDetails
 * 5, 
 * 6, 
 * @param {object} props
 * @returns {object} React Component
 */
function WALLossSettlementValuationMethodComponent(props) {
    const {
        // modelVM: walWatercraftDTOVM,
        model: walWatercraftDTO,
        periodEffectiveDate,
        // onValueChange,
        onUpdateLossSettlementValuationMethod,
        // showErrors,
        isReadOnly,
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const walWatercraftDTOVM = viewModelService.create(walWatercraftDTO, 'pc', DTO_FULL_NAME);
    const {
        watercraftDetail: watercraftDetailDTOVM,
        motorDetail: motorDetailDTOVM,
        trailerDetail: trailerDetailDTOVM,
    } = walWatercraftDTOVM;
    const {
        itemToBeInsured_Ext: itemToBeInsured,
        walCategoryFlowType: walFlowType,
        //
        watercraftDetail: watercraftDetailDTO,
        motorDetail: motorDetailDTO,
        trailerDetail: trailerDetailDTO,
    } = walWatercraftDTOVM.value;
    // from WatercraftComponent
    const sectionData = sectionVisible(itemToBeInsured, walFlowType) || {};
    const { 
        sectionConfig: {
            watercraftDetail: watercraftDetailSectionVisible,
            motorDetail: motorDetailSectionVisible,
            trailerDetail: trailerDetailSectionVisible,
        } = {},
    } = sectionData;

    const isOutboard = isOutboardWatercraft(walWatercraftDTOVM.value);
    

    // useEffect(() => {
    // }, []);

    const syncWatercraftDetailDTOCoverageAmount = (walWatercraftDetailDTO) => { 
        const {
            msrp,
            currentMarketValue,
            coverageAmount,
            //
            lossSettlementValuationMethod,
        } = walWatercraftDetailDTO;
        if(lossSettlementValuationMethod === 'replacement_cost') {
            _.set(walWatercraftDetailDTO, 'coverageAmount', msrp);
        } else if (lossSettlementValuationMethod === 'agreed_value') {
            _.set(walWatercraftDetailDTO, 'coverageAmount', currentMarketValue);
        }
    };

    const syncExtraDetailDTOCoverageAmount = (walWatercraftDetailDTO) => { 
        const {
            msrp,
            currentMarketValue,
            coverageAmount,
            lossSettlementValuationMethod,
        } = walWatercraftDetailDTO;
        const isCoverageAmountExist = coverageAmount && coverageAmount.amount;

        
        if(lossSettlementValuationMethod === 'replacement_cost' && !isCoverageAmountExist) {
            _.set(walWatercraftDetailDTO, 'coverageAmount', msrp);
        }
        if(lossSettlementValuationMethod === 'agreed_value' && !isCoverageAmountExist) {
            _.set(walWatercraftDetailDTO, 'coverageAmount', currentMarketValue);
        }
    };


    const writeValue = async (value, path) => {
        // onValueChange(value, path);
        // _.clone()
        const updatedWatercraftDTO = walWatercraftDTOVM.value;
        _.set(updatedWatercraftDTO, path, value);
        //
        const {
            watercraftDetail: watercraftDetailDTOParam,
            motorDetail: motorDetailDTOParam,
            trailerDetail: trailerDetailDTOParam,
        } = updatedWatercraftDTO;
        //
        if (path === 'watercraftDetail.lossSettlementValuationMethod') {
            // ref: WatercraftDetails.jsx
            //
            syncWatercraftDetailDTOCoverageAmount(watercraftDetailDTOParam);

            // ref: MotorDetails.jsx
            // if (isOutboard) {
            //     _.set(motorDetailDTOParam, 'lossSettlementValuationMethod', value);
            //     syncExtraDetailDTOCoverageAmount(motorDetailDTOParam);
            // }

            // ref: TrailerDetals.jsx
            // if(itemToBeInsured === 'watercraft_motor_trailer') {
            //     _.set(trailerDetailDTOParam, 'lossSettlementValuationMethod', value);
            //     syncExtraDetailDTOCoverageAmount(trailerDetailDTOParam);
            // }
        } else if (path === 'motorDetail.lossSettlementValuationMethod') {
            // ref: MotorDetails.jsx
            //
            syncExtraDetailDTOCoverageAmount(motorDetailDTOParam);
        } else if (path === 'trailerDetail.lossSettlementValuationMethod') {
            // ref: TrailerDetails.jsx
            syncExtraDetailDTOCoverageAmount(trailerDetailDTOParam);
        }

        //
        syncCoverageAmountValueAndMsg(updatedWatercraftDTO);
        syncPhysicalDamageCovRequired(updatedWatercraftDTO);
        await onUpdateLossSettlementValuationMethod(updatedWatercraftDTO);
    };

    // ======================================
    const isWatercraftDetailVisible = isLossSettlementValuationMethodVisible(watercraftDetailDTO);
    const isMotorDetailVisible = isLossSettlementValuationMethodVisible(motorDetailDTO);
    const isTrailerDetailVisible = isLossSettlementValuationMethodVisible(trailerDetailDTO);

    const watercraftDetailValuationMethodOptions = watercraftDetailDTOVM? getValuationMethodOptions(watercraftDetailDTOVM, periodEffectiveDate, translator) : [];
    const motorDetailValuationMethodOptions = motorDetailDTOVM ? getValuationMethodOptions(motorDetailDTOVM, periodEffectiveDate, translator, isOutboard) : [];

    const allPartsInsured = itemToBeInsured === 'watercraft_motor_trailer';
    const trailerDetailVlauationMethodOptions = trailerDetailDTOVM ? getValuationMethodOptions(trailerDetailDTOVM, periodEffectiveDate, translator, allPartsInsured) : [];

    // =====================
    const isWatercraftVisible = watercraftDetailSectionVisible && isWatercraftDetailVisible;

    const isMotorVisible = motorDetailSectionVisible && isMotorDetailVisible;
    const isMotorDisabled = isOutboard && _.get(motorDetailDTO, 'lossSettlementValuationMethod');

    const isTrailerVisible = trailerDetailSectionVisible && isTrailerDetailVisible;
    const isTrailerDisabled = !!_.get(watercraftDetailDTO, 'lossSettlementValuationMethod');
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly,
        },
        // from WatercraftDetails.jsx
        watercraftlossSettlementValuationMethod: {
            visible: isWatercraftVisible, // watercraftDetailSectionVisible && isWatercraftDetailVisible,
            availableValues: watercraftDetailValuationMethodOptions,
            required: true
        },
        // from MotorDetails.jsx
        motorlossSettlementValuationMethod: {
            visible: !isWatercraftVisible && !isMotorDisabled && isMotorVisible,
            availableValues: motorDetailValuationMethodOptions,
            disabled: isMotorDisabled,
            required: true,
        },
        // from TrailerDetails.jsx
        trailerlossSettlementValuationMethod: {
            visible: (!isWatercraftVisible || isMotorDisabled || !isMotorVisible) && !isTrailerDisabled && isTrailerVisible,
            availableValues: trailerDetailVlauationMethodOptions, // getAvailableForLossMethod('lossSettlementValuationMethod', itemToBeInsured === 'watercraft_motor_trailer'),
            disabled: isTrailerDisabled,
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            walWatercraftDTOVM,
            id,
            path,
            overrideProps
        );
    };
    //---------
    // return MetadataContent(metadata.componentContent, override, resolvers);
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={walWatercraftDTOVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={writeValue}
            showErrors
        />
    );
}

WALLossSettlementValuationMethodComponent.propTypes = {
    model: PropTypes.shape({}).isRequired,
    periodEffectiveDate: PropTypes.string.isRequired,
    //
    onValueChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
};

WALLossSettlementValuationMethodComponent.defaultProps = {
    // model: {},
    onValueChange: _.noop,
    // showErrors: false,
    isReadOnly: false,
};

export default WALLossSettlementValuationMethodComponent;
