import _ from 'lodash';
import { WniDateUtil } from 'wni-portals-util-js';

const currentViews = ['Quotes', 'Renewal', 'Change', 'Cancellation', 'Rewrite'];
const policiesViews = ['Policies', 'RecentlyViewed', 'RecentlyIssued', 'Delinquent']
const advancedDefaultView = policiesViews[0];
const quickDefaultView = policiesViews[1];

const jobsCreatedInLastXDays = 120;

const parseCase = (id) => {
    return _.upperFirst(id);
};

const generateAccountData = (data = []) => {
    const {
        openSubmissions = [],
        openPolicyChanges = [],
        openCancellations = [],
        boundRewrites_Ext: boundRewrites = [],
        openRenewals = [],
    } = data[0] || {};
    return {
        openQuotes: openSubmissions,
        openChange: openPolicyChanges,
        openCancellation: openCancellations,
        openRewrite: boundRewrites,
        openRenewal: openRenewals,
    };
};

const generateOpenJobData = (jobData = [], getProductName = _.noop) => {
    const genNewData = jobData.map((item) => {
        return {
            ...item,
            productCode: item.product?.productCode,
            productName: getProductName(item.product?.productCode),
            premium: item.totalCost_Ext,
            jobStatus: item.status,
            accountType: item.accountHolder_Ext?.contactType_Ext,
            account: item.accountNumber,
            created: WniDateUtil.formatDateWithPattern(item.createTime),
            prefilledQuote: item.isPrefilled_Ext ? 'Yes' : 'No',
        };
    });
    return genNewData;
};

const generatePolicyData = (policyData = [], getProductName = _.noop) => {
    const genNewData = policyData.map((item) => {
        return {
            productCode: item.product?.productCode,
            productName: getProductName(item.product?.productCode),
            displayStatus: item.displayStatus,
            accountNumber: item.accountNumber,
            accountType: item.accountHolder?.contactType_Ext,
            policyAccountNumber: item.accountNumber,
            policyNumber: item.policyNumber,
            accountHolderName: item.accountHolderName,
            primaryInsuredName: item.primaryInsuredName,
            premium: item.totalCost_Ext,
            effectiveDate: WniDateUtil.formatDateWithPattern(item.effective),
            expirationDate: WniDateUtil.formatDateWithPattern(item.expiration),
            producerCodeOfService: item.producerCodeOfService,
            primaryDBA: item.primaryDBA_Ext
        };
    });
    return genNewData;
};

const isCurrentUserLicensedOrServicingAgent = (job) => {
    const {
        currentUserAgent_Ext: { isLicensedAgent, isServicingAgent } = {},
    } = job;
    const isLicensedOrSericingAgent = isLicensedAgent || isServicingAgent;
    return isLicensedOrSericingAgent;
};

const filterJobByProducerCode = (job, selectedProducerCode) => {
    const isLicensedOrSericingAgent = isCurrentUserLicensedOrServicingAgent(job);
    return (job && ((job.producerCodeOfRecord
    && job.producerCodeOfRecord === selectedProducerCode)
    || (job.producerCodeOfService && job.producerCodeOfService === selectedProducerCode)
    || selectedProducerCode === 'all'
    || (selectedProducerCode === 'myWork' && job.createdByMe)))
    || (selectedProducerCode === 'myTransactions' && isLicensedOrSericingAgent)
};

export {
    jobsCreatedInLastXDays,
    advancedDefaultView,
    quickDefaultView,
    policiesViews,
    currentViews,
    parseCase,
    generateAccountData,
    generateOpenJobData,
    generatePolicyData,
    filterJobByProducerCode
};
