import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalNext,
} from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import messages from './WCPAYGPopup.messages';
import metadata from './WCPAYGPopup.metadata.json5';
import styles from './WCPAYGPopup.module.scss';

function WCPAYGPopup(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        useAuthenticationData,
        useDependenciesData,
        translator,
        submissionVM,
        viewModelService,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependenciesData || useDependencies(['loadingMask']);

    const { authHeader } = useAuthenticationData || useAuthentication();
    // use this modal to call the validation for email
    const currentEmailAddress =
        _.get(
            submissionVM,
            'value.baseData.primaryNamedInsured_Ext.emailAddress1',
            ''
        ) ||
        _.get(
            submissionVM,
            'value.baseData.primaryNamedInsured_Ext.emailAddress2_Ext',
            ''
        );
    const createEmptyVM = useCallback(() => {
        return viewModelService.create(
            { senderEmailAddress: currentEmailAddress },
            'pc',
            'wni.edge.capabilities.quote.mailing.dto.WniEmailDTO'
        );
    }, [currentEmailAddress, viewModelService]);
    const [model, updateModel] = useState(createEmptyVM());

    const writeValue = useCallback(
        (value, path) => {
            const newVM = viewModelService.clone(model);
            _.set(newVM, `value.${path}`, value);
            updateModel(newVM);
        },
        [model, viewModelService]
    );

    const handleSave = useCallback(() => {
        if (_.get(model, 'senderEmailAddress.aspects.valid')) {
            onResolve(_.get(model, 'value.senderEmailAddress'));
        }
    }, [model, onResolve]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left'
        },
        warningMessage: {
            content: translator(messages.content),
        },
        contentSection: {
            className: `mb-20 ${styles.font16}`,
        },
        inputSection: {
            className: styles.marginBottom,
        },
        emailAddress: {
            path: 'senderEmailAddress',
            placeholder: '--email address--',
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} icon="gw-warning" status="warning" />
            <ModalBody id="payAsYouGoPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={model}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

WCPAYGPopup.propTypes = {
    title: PropTypes.string,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
};
WCPAYGPopup.defaultProps = {
    size: 'md',
    title: messages.paygTitle,
    iconClassType: false,
    showCloseBtn: false,
    showCancelBtn: true,
    actionBtnLabel: messages.referToUnderwriter,
    cancelBtnLabel: messages.dialogCancel,
};
export default WCPAYGPopup;
