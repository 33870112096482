
const FIRST_CHANGE_WIZARD_PAGE = 'GLPolicyInfoChangePage';

function getChangePageJumpList({ policyChangeSource, getPageJumpAction }) {
    let retval = [];
    if (policyChangeSource === 'cancellation') {
        retval = [{
            name: 'Mailing Address',
            action: getPageJumpAction('GLPolicyDetailsPage'),
        }];
    } else {
        retval = [
            // {
            //     name: 'GL Page',
            //     action: getPageJumpAction('GLDummyPage'),
            // },
            // {
            //     name: 'Qualification Questions',
            //     action: getPageJumpAction('GLQualificationPage'),
            // },
            {
                name: 'Policy Details',
                action: getPageJumpAction('GLPolicyDetailsPage'),
            },
            // {
            //     name: 'Subline Selection',
            //     action: getPageJumpAction('GLSublineSelectionPage'),
            // },
            {
                name: 'Coverages',
                action: getPageJumpAction('GLGeneralLiabilityPage'),
            },
            {
                name: 'State Specific Information',
                action: getPageJumpAction('GLStateSpecificInformationPage'),
            },{
                name: 'Location',
                action: getPageJumpAction('GLlocationsPage'),
            },
            {
                name: 'Exposures',
                action: getPageJumpAction('GLExposuresPage'),
            },
            // {
            //     name: 'Unmanned Aircraft',
            //     action: getPageJumpAction('GLUnmannedAircraftPage'),
            // },
            // {
            //     name: 'Modifiers',
            //     action: getPageJumpAction('GLModifiersPage'),
            // },
            // {
            //     name: 'Underwriting Questions',
            //     action: getPageJumpAction('GLUnderwritingQuestionsPage'),
            // },
            // {
            //     name: 'Risk Analysis',
            //     action: getPageJumpAction('GLRiskAnalysisPage'),
            // },
            // {
            //     name: '',
            //     action: getPageJumpAction(''),
            // },
        ];
        // return  [{
        //     name: 'Policy Details',
        //     action: getPageJumpAction('WALPolicyDetailsPage'),
        // }, {
        //     name: 'Watercrafts',
        //     action: getPageJumpAction('WALWatercraftPage'),
        // }, {
        //     name: 'Operators',
        //     action: getPageJumpAction('WALOperatorPage'),
        // }, {
        //     name: 'Coverages',
        //     action: getPageJumpAction('WALCoveragesPage'),
        // }, {
        //     name: 'Loss History and Prior Carrier',
        //     action: getPageJumpAction('WALIncidentsAndReportsPage'),
        // }];
    }
    return retval;
}

export {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
};