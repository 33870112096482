import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Link } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import SummaryHeading from '../SummaryHeader/SummaryHeader';
import styles from './CoverageSummary.module.scss';
import metadata from './CoverageSummary.metadata.json5';
import messages from '../../../PECPWizard.messages';

class CoverageSummary extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        coverageDetails: PropTypes.shape({}).isRequired,
        summaryIcon: PropTypes.string,
        openByDefault: PropTypes.bool,
        onEditClicked: PropTypes.func.isRequired,
        showLimit: PropTypes.bool
    };

    static defaultProps = {
        summaryIcon: 'mi-check',
        openByDefault: false,
        showLimit: false
    };

    state = {
        submissionVM: {},
        coverageDetails: {}
    };

    componentDidMount() {
        const { viewModelService, coverageDetails } = this.props;
        const submission = viewModelService.create(
            coverageDetails,
            'pc',
            'edge.capabilities.policyjob.lob.commercialproperty.coverages.dto.BuildingCoverageDTO'
        );

        this.setState({
            submissionVM: submission,
            coverageDetails
        });
    }

    getCoverageName = () => {
        const { showLimit } = this.props;
        const {
            coverageDetails: { coverages }
        } = this.state;
        const [firstCoverage] = coverages;
        const limit = showLimit ? 2 : 0;
        return firstCoverage.terms.length
            ? `${firstCoverage.terms[limit].name}: ${firstCoverage.terms[limit].chosenTermValue}`
            : '';
    };

    renderAccordionHeader = (type) => {
        const { summaryIcon } = this.props;
        const displayName = this.getCoverageName();
        const translator = this.context;

        return (isOpen) => (
            <SummaryHeading
                isOpen={isOpen}
                type={type}
                displayName={displayName}
                title={translator(messages.coveragesSummary)}
                icon={summaryIcon}
            />
        );
    };

    onEditCoveragesClicked = () => {
        const { onEditClicked } = this.props;
        if (onEditClicked) {
            onEditClicked();
        }
    };

    render() {
        const { submissionVM } = this.state;
        const { openByDefault } = this.props;

        if (_.isEmpty(_.get(submissionVM.value, 'coverages'))) {
            return null;
        }

        const overrideProps = {
            coverageSummaryCard: {
                renderHeader: this.renderAccordionHeader('coverages')
            },
            coverageSummaryAccordion: {
                accordionStates: openByDefault ? ['coverageSummaryCard'] : []
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: { link: Link },
            resolveCallbackMap: {
                onEditCoveragesClicked: this.onEditCoveragesClicked
            }
        };

        return (
            <ViewModelForm
                model={submissionVM}
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export default withViewModelService(CoverageSummary);
