import { defineMessages } from 'react-intl';

export default defineMessages({
    viewQuote: {
        id: 'overwrite.gateway.directives.RewriteSummary.ViewQuoteReadOnly',
        defaultMessage: 'View Quote'
    },
    backTOPolicyDetails: {
        id: 'overwrite.gateway.directives.RewriteSummary.Back To Policy Details',
        defaultMessage: 'Back to Policy Details'
    },
    WesternNational: {
        id: 'overwrite.quoteandbind.pa.views.RewriteSummary.Submit to Western National',
        defaultMessage: 'Submit to { domainName }',
    },
    agency: {
        id: 'overwrite.gateway.directives.RewriteSummary.Agency',
        defaultMessage: 'Agency'
    },
    licensedAgent: {
        id: 'overwrite.gateway.directives.RewriteSummary.Licensed Agent',
        defaultMessage: 'Licensed Agent'
    },
    servicingAgent: {
        id: 'overwrite.gateway.directives.RewriteSummary.Servicing Agent',
        defaultMessage: 'Servicing Agent'
    },
    copySubmission: {
        id: 'overwrite.gateway.directives.RewriteSummary.copy submission',
        defaultMessage: 'Copy Submission'
    },
    copiedMessage: {
        id: 'overwrite.gateway.directives.RewriteSummary.The application has been copied to ',
        defaultMessage: 'The application has been copied to '
    },
    noOpenUnderwritingSubmission: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.There are no underwriting issues associated with this.Submission',
        defaultMessage: 'There are no open underwriting issues associated with this quote.'
    },
    noOpenUnderwritingPolicyChange: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.There are no underwriting issues associated with this.PolicyChange',
        defaultMessage: 'There are no open underwriting issues associated with this policy change.'
    },
    noOpenUnderwritingRenewal: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.There are no underwriting issues associated with this.Renewal',
        defaultMessage: 'There are no open underwriting issues associated with this renewal.'
    },
    approvedForThisCancellation: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.All underwriting issues have been approved for this Cancellation',
        defaultMessage: 'All underwriting issues have been approved for this cancellation.'
    },
    approvedForThisSubmission: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.All underwriting issues have been approved for this Submission',
        defaultMessage: 'All underwriting issues have been approved for this quote.'
    },
    approvedForThisRenewal: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.All underwriting issues have been approved for this Renewal',
        defaultMessage: 'All underwriting issues have been approved for this renewal.'
    },
    approvedForThisPolicyChange: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.All underwriting issues have been approved for this PolicyChange',
        defaultMessage: 'All underwriting issues have been approved for this policy change.'
    },
    withdrawOrContinuePolicyChange: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.You may either withdraw or continue to the PolicyChange wizard',
        defaultMessage: 'You may either withdraw or continue to the policy change wizard.'
    },
    withdrawOrContinueRenewal: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.You may either withdraw or continue to the Renewal wizard',
        defaultMessage: 'You may either withdraw or continue to the renewal wizard.'
    },
    withdrawOrContinueSubmission: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.You may either withdraw or continue to the Submission wizard',
        defaultMessage: 'You may either withdraw or continue to the quote wizard.'
    },
    withdrawOrContinueCancellation: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.You may either withdraw or continue to the Cancellation wizard',
        defaultMessage: 'You may either withdraw or continue to the cancellation wizard.'
    },
    withdrawThePolicyChange: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.You may withdraw the PolicyChange',
        defaultMessage: 'You may withdraw the policy change'
    },
    withdrawTheRenewal: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.You may withdraw the Renewal',
        defaultMessage: 'You may withdraw the renewal'
    },
    withdrawTheQuote: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.You may withdraw the Submission',
        defaultMessage: 'You may withdraw the quote'
    },
    withdrawTheCancellation: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.You may withdraw the Cancellation',
        defaultMessage: 'You may withdraw the cancellation'
    },
    sureWithDrawQuote: {
        id: 'overwrite.gateway.views.job-uwissues.RewriteSummary.Are you sure you want to withdraw quote:',
        defaultMessage: 'Are you sure you want to withdraw quote: {quoteNumber}? All changes will be lost.'
    },
    thePolicyIsRewritten: {
        id: 'overwrite.gateway.views.RewriteSummary.the policy is rewritten',
        defaultMessage: 'The policy is rewritten.'
    }
});
