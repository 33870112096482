
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';



import {
    Button,
    formatDate,
    formatNumber,
    FormattedDate,
    FormattedNumber,
} from '@jutro/components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/datatable';

import CoverageSummaryComponent from '../CoverageSummary/CoverageSummaryComponent';

/**
 * Shows PolicyLineSummaryClauseDTO[] in a DataTable.
 * 
 * Sample Displayables Data:
 * [
 * 	{
 * 		"label": "Each Accident Limit",
 * 		"fieldValue": "500,000"
 * 	},
 * 	{
 * 		"label": "Disease Each Employee Limit",
 * 		"fieldValue": "500,000"
 * 	}
 * ]
 * @param {object} props
 * @returns {object} React Component
 */


function extractDisplayableDTO(displayableDTO) {
    const {
        label,
        fieldValue
    } = displayableDTO;
    return {
        description: label,
        value: fieldValue,
    };
}
function DisplayableSummaryComponent(props) {
    const {
        displayables,
        //
        title,
    } = props;
    
    if (_.isEmpty(displayables)) {
        return null;
    }

    const clauses = displayables.map(extractDisplayableDTO);
    
    return (
        <CoverageSummaryComponent
            title={title}
            clauses={clauses}
            showClauseInBold={false}
            // tableRowExtractor={extractDisplayableDTO}
        />
    );

}

DisplayableSummaryComponent.propTypes = {
    displayables: PropTypes.shape({}),
};

DisplayableSummaryComponent.defaultProps = {
};

export default DisplayableSummaryComponent;
