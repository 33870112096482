import _ from 'lodash';
import policiesMessages from '../../Policies/Policies.messages';
import messages from './PolicyLanding.messages';

const DEFAULT_DISPLAY_QUOTE_STATUS = ['Draft', 'Quoted', 'Rated', 'Approved'];

/**
 * 
 * @param {object} jobSummaryDTO JobSummaryDTO or PolicySummaryDTO
 * @returns {boolean}
 */
export function isCurrentUserLicensedOrServicingAgent(jobSummaryDTO) {
    const {
        currentUserAgent_Ext: {
            isLicensedAgent,
            isServicingAgent,
        }
    } = jobSummaryDTO;
    const isLicensedOrSericingAgent = isLicensedAgent || isServicingAgent;
    return isLicensedOrSericingAgent;
}

export default class LandingUtil {
    constructor() {
        this.allJobs = [];

        this.policyCountInformation = {
            Policies: 0,
            Quotes: 0,
            Renewal: 0,
            Change: 0,
            Cancellation: 0,
            Rewrite: 0,
        };

        this.billingStatusFilter = {
            displayKey: policiesMessages.billingStatus,
            displayValue: '',
            options: [{
                code: 'inGoodStanding',
                displayKey: policiesMessages.inGoodStanding,
                displayValue: '',
                selected: true
            }, {
                code: 'delinquent',
                displayKey: policiesMessages.delinquent,
                displayValue: '',
                selected: true
            }]
        };
        this.policyStatusFilter = {
            displayKey: policiesMessages.status,
            displayValue: '',
            options: []
        };

        this.renewalStatusFilter = {
            displayKey: policiesMessages.status,
            displayValue: '',
            options: []
        };

        this.quoteStatusFilter = {
            displayKey: policiesMessages.status,
            displayValue: '',
            options: []
        };

        this.policyChangeStatusFilter = {
            displayKey: policiesMessages.status,
            displayValue: '',
            options: []
        };

        this.cancellationStatusFilter = {
            displayKey: policiesMessages.status,
            displayValue: '',
            options: []
        };

        this.rewriteStatusFilter = {
            displayKey: policiesMessages.status,
            displayValue: '',
            options: []
        };

        this.policyFilter = {
            displayKey: policiesMessages.policies,
            displayValue: 'Policies',
            filters: [
                this.policyStatusFilter
            ]
        };
        this.policyLinesFilter = {
            displayKey: messages.policylandingPolicyLine,
            displayValue: '',
            options: []
        };
        this.customLobFilter = {
            lobFilter: this.policyLinesFilter,
            filters: this.policyFilter
        };
        this.quoteFilter = {
            displayKey: policiesMessages.policyLandingQuotes,
            displayValue: 'Quotes',
            filters: [
                this.quoteStatusFilter
            ]
        };

        this.renewalFilter = {
            displayKey: policiesMessages.policyLandingRenewal,
            displayValue: 'Renewal',
            filters: [
                this.renewalStatusFilter
            ]
        };

        this.policyChangeFilter = {
            displayKey: policiesMessages.policyLandingChange,
            displayValue: 'Change',
            filters: [
                this.policyChangeStatusFilter
            ]
        };

        this.cancellationFilter = {
            displayKey: policiesMessages.policyLandingCancellation,
            displayValue: 'Cancellation',
            filters: [
                this.cancellationStatusFilter
            ]
        };

        this.rewriteFilter = {
            displayKey: policiesMessages.policyLandingRewrite,
            displayValue: 'Rewrite',
            filters: [
                this.rewriteStatusFilter
            ]
        };

        this.customFilters = [
            this.policyFilter,
            this.quoteFilter,
            this.renewalFilter,
            this.policyChangeFilter,
            this.cancellationFilter,
            this.rewriteFilter
        ];
    }

createPolicyStatusFilter = (policy) => {
    if (_.filter(this.policyStatusFilter.options, { code: policy.displayStatus }).length === 0) {
        const newFilter = {
            code: policy.displayStatus,
            displayKey: policy.displayStatus,
            displayValue: policy.displayStatus,
            selected: true
        };
        this.policyStatusFilter.options.push(newFilter);
    }
};

createPolicyLineFilter = (policyLine) => {
    const lineOfBusinessCode = _.get(policyLine, 'lineOfBusinessCode');
    if (lineOfBusinessCode && _.filter(this.policyLinesFilter.options, { code: lineOfBusinessCode }).length === 0) {
        const newFilter = {
            code: policyLine.lineOfBusinessCode,
            displayKey: policyLine.lineOfBusinessName,
            displayValue: policyLine.lineOfBusinessName,
            selected: false
        };
        this.policyLinesFilter.options.push(newFilter);
    }
};

getCountInformation = (type) => {
    return this.policyCountInformation[type];
}

getPolicySummaries = (summaries, selectedQuickFilter) => {
    const policies = [];
    if (summaries && summaries.length > 0) {
        summaries.forEach((policySummary) => {
            if (policySummary) {
                const policy = {
                    account: {
                        accountNumber: policySummary.accountNumber
                    },
                    accountHolderName: policySummary.accountHolderName,
                    policyNumber: policySummary.policyNumber,
                    latestPeriod: {
                        policyLines: policySummary.policyLines
                    },
                    policyLines: policySummary.policyLines,
                    producerCodeOfRecord: policySummary.producerCodeOfRecord,
                    producerCodeOfService: policySummary.producerCodeOfService,
                    primaryInsuredName: policySummary.primaryInsuredName,
                    effective: policySummary.effective,
                    expiration: policySummary.expiration,
                    totalPremium: policySummary.premium,
                    displayStatus: policySummary.displayStatus,
                    delinquent: policySummary.delinquent,
                    canUserView: policySummary.canUserView,
                    createdByMe: policySummary.createdByMe,
                    product: policySummary.product
                };

                policies.push(policy);
                policy.policyLines.forEach((policyLine) => {
                    this.createPolicyLineFilter(policyLine);
                });
                this.createPolicyStatusFilter(policy);
            }
        });
    }
    this.customFilterForPolicy(selectedQuickFilter, summaries);
    return [this.customFilters, this.customLobFilter];
};

customFilterForPolicy = (selectedQuickFilter, policiesDetails) => {
    if (selectedQuickFilter === 'delinquentTile') {
        const filterIndex = _.findIndex(this.billingStatusFilter.options, { code: 'inGoodStanding' });
        _.set(this.billingStatusFilter.options[filterIndex], 'selected', false);
    }
    this.policyCountInformation.Policies = policiesDetails.length;
}

populateQuotesJobFilterOptions = (filter, jobs) => {
    /**
     * When user access this screen, only below 3 status will be selected
     * if there other status, they will be unselected.
        - Draft
        - Quoted
        - Rated
        - Approved // Portal Only
     */
    jobs.forEach((job) => {
        if (
            // job.status !== 'New' &&
            !_.find(filter.options, {
                displayValue: job.status
            })) {
            let selected = false;
            if (DEFAULT_DISPLAY_QUOTE_STATUS.includes(job.status)) {
                selected = true;
            } else {
                selected = false;
            }
            const newFilter = {
                code: job.status,
                displayKey: job.status,
                displayValue: job.status,
                selected: selected
            };
            filter.options.push(newFilter);
        }
    });
}

populateJobFilterOptions = (filter, jobs) => {
    jobs.forEach((job) => {
        if (
            !_.find(filter.options, {
                displayValue: job.status
            })) {
            const newFilter = {
                code: job.status,
                displayKey: job.status,
                displayValue: job.status,
                selected: true
            };
            filter.options.push(newFilter);
        }
    });
};

initJobs = (accounts) => {
    this.allJobs = [];
    if (accounts && accounts.length > 0) {
        accounts.forEach((account) => {
            this.allJobs = this.allJobs.concat(
                account.openSubmissions,
                account.openRenewals,
                account.openPolicyChanges,
                account.openCancellations,
                account.boundRewrites_Ext,
            );

            this.policyCountInformation.Quotes += account.openSubmissions.length;
            this.policyCountInformation.Renewal += account.openRenewals.length;
            this.policyCountInformation.Change += account.openPolicyChanges.length + 0;
            this.policyCountInformation.Cancellation += account.openCancellations.length;
            this.policyCountInformation.Rewrite += account.boundRewrites_Ext.length;

            this.populateQuotesJobFilterOptions(this.quoteStatusFilter, account.openSubmissions);
            this.populateJobFilterOptions(this.renewalStatusFilter, account.openRenewals);
            this.populateJobFilterOptions(this.policyChangeStatusFilter, account.openPolicyChanges);
            this.populateJobFilterOptions(this.cancellationStatusFilter, account.openCancellations);
            this.populateJobFilterOptions(this.rewriteStatusFilter, account.boundRewrites_Ext);
        });
    }
    this.allJobs.flatMap((job) => job.policyLines).forEach((policyLines) => this.createPolicyLineFilter(policyLines));
};

checkFilterHasOptionsSelected = (filter) => {
    return filter.options ? (filter.options.some((option) => option.selected)) : true;
};

filterByOptionCode = (subFilter, codeValue) => {
    return subFilter.options.some(({ selected, code }) => selected && code === codeValue);
};

filterByLineOfBusiness = (item) => {
    if (this.checkFilterHasOptionsSelected(this.customLobFilter.lobFilter)) {
        return this.filterByOptionCode(this.customLobFilter.lobFilter,
            item.policyLines[0].lineOfBusinessCode);
    }
    return item;
};

filterPolicy = (policy) => {
    let shouldFilterPolicy = true;
    const policyLineFilter = this.customLobFilter.filters;
    if (policyLineFilter && this.checkFilterHasOptionsSelected(policyLineFilter)) {
        policyLineFilter.filters.forEach((filter) => {
            const isOptionsSelected = _.some(filter.options, {
                selected: true
            });
            if (shouldFilterPolicy) {
                if (isOptionsSelected && filter === this.billingStatusFilter) {
                    shouldFilterPolicy = ((policy.delinquent && (_.some(filter.options, { code: 'delinquent', selected: true })))
                        || (!policy.delinquent && (_.some(filter.options, { code: 'inGoodStanding', selected: true }))));
                } if (isOptionsSelected && filter === this.policyStatusFilter) {
                    shouldFilterPolicy = _.some(
                        filter.options, { code: policy.displayStatus, selected: true }
                    );
                }
                return true;
            }
            return shouldFilterPolicy;
        });
    }
    return shouldFilterPolicy;
};

filterPolicyByProducerCode = (policy, selectedProducerCode) => {
    const isLicensedOrSericingAgent = isCurrentUserLicensedOrServicingAgent(policy);
    return (policy && ((policy.producerCodeOfRecord
    && policy.producerCodeOfRecord === selectedProducerCode.code)
    || (policy.producerCodeOfService && policy.producerCodeOfService === selectedProducerCode.code)
    || selectedProducerCode.code === 'all'
    || (selectedProducerCode.code === 'myWork' && policy.createdByMe))
    || (selectedProducerCode.code === 'myTransactions' && isLicensedOrSericingAgent)
    );
};

filterByProducerCode = (policies, selectedProducerCode) => {
    return _.filter(policies, (item) => {
        // if (selectedProducerCode !== 'all') {
        //     return item.producerCodeOfService === selectedProducerCode;
        // }
        // return true;
        let retval = item.producerCodeOfService === selectedProducerCode;
        if (selectedProducerCode === 'all') {
            retval = true;
        } else if (selectedProducerCode === 'myTransactions') {
            const isLicensedOrSericingAgent = isCurrentUserLicensedOrServicingAgent(item);
            retval = isLicensedOrSericingAgent;
        }
        return retval;
    });
}

filterAllPolicies = (policies, selectedProducerCode) => {
    let allPolicies = [];
    if (selectedProducerCode) {
        allPolicies = this.filterByProducerCode(policies, selectedProducerCode);
        if (allPolicies) {
            this.policyChangeStatusFilter.policies = allPolicies.length;
        }
    }

    let filteredPolicies = allPolicies.filter((policy) => this.filterByLineOfBusiness(policy));
    filteredPolicies = filteredPolicies.filter((policy) => this.filterPolicy(policy) && policy);
    if (filteredPolicies) {
        this.policyCountInformation.Policies = filteredPolicies.length;
    }
    return filteredPolicies;
};

setNumberOfOpenJobs = (jobs) => {
    if (jobs && jobs.length > 0) {
        this.policyCountInformation.Quotes = _.filter(jobs, {
            type: 'Submission'
        }).length;
        this.policyCountInformation.Renewal = _.filter(jobs, {
            type: 'Renewal'
        }).length;
        this.policyCountInformation.Change = _.filter(jobs, {
            type: 'PolicyChange'
        }).length;
        this.policyCountInformation.Cancellation = _.filter(jobs, {
            type: 'Cancellation'
        }).length;
        this.policyCountInformation.Rewrite = _.filter(jobs, {
            type: 'Rewrite'
        }).length;
    }
};

filterJobByLineOfBusiness = (item) => {
    return !this.checkFilterHasOptionsSelected(this.customLobFilter.lobFilter)
    || this.filterByOptionCode(this.customLobFilter.lobFilter, item.policyLines[0]
        .lineOfBusinessCode);
};

filterByOptionDisplayValue = (subFilter, displayValue) => {
    const selectedOptions = _.filter(subFilter.options, {
        selected: true
    });

    if (selectedOptions.length === 0) {
        return false;
    }
    return (_.filter(selectedOptions, {
        displayValue
    }).length > 0);
};

filterJob = (job, filter) => {
    if (!this.customLobFilter.filters) {
        return true;
    } if (filter === this.quoteFilter.displayValue) {
        return job.type.toLowerCase() === 'submission' && (!this.checkFilterHasOptionsSelected(this.quoteStatusFilter) || this.filterByOptionDisplayValue(this.quoteStatusFilter, job.status));
    } if (filter === this.renewalFilter.displayValue) {
        return job.type.toLowerCase() === 'renewal' && (!this.checkFilterHasOptionsSelected(this.renewalStatusFilter) || this.filterByOptionDisplayValue(this.renewalStatusFilter, job.status));
    } if (filter === this.policyChangeFilter.displayValue) {
        return job.type.toLowerCase() === 'policychange' && (!this.checkFilterHasOptionsSelected(this.policyChangeStatusFilter) || this.filterByOptionDisplayValue(this.policyChangeStatusFilter, job.status));
    } if (filter === this.cancellationFilter.displayValue) {
        return job.type.toLowerCase() === 'cancellation' && (!this.checkFilterHasOptionsSelected(this.cancellationStatusFilter) || this.filterByOptionDisplayValue(this.cancellationStatusFilter, job.status));
    } if (filter === this.rewriteFilter.displayValue) {
        return job.type.toLowerCase() === 'rewrite' && (!this.checkFilterHasOptionsSelected(this.rewriteStatusFilter) || this.filterByOptionDisplayValue(this.rewriteStatusFilter, job.status));
    }
    return false;
};

filterJobs = (category, selectedProducerCode) => {
    let filteredJobs = this.allJobs;
    if (selectedProducerCode) {
        filteredJobs = this.filterByProducerCode(filteredJobs, selectedProducerCode);
    }
    this.setNumberOfOpenJobs(filteredJobs);

    filteredJobs = filteredJobs.filter((job) => this.filterJobByLineOfBusiness(job));
    filteredJobs = filteredJobs.filter((job) => this.filterJob(job, category) && job);

    if (filteredJobs && this.customLobFilter) {
        if (category === this.quoteFilter.displayValue) {
            this.policyCountInformation.Quotes = filteredJobs.length;
        } else if (category === this.renewalFilter.displayValue) {
            this.policyCountInformation.Renewal = filteredJobs.length;
        } else if (category === this.policyChangeFilter.displayValue) {
            this.policyCountInformation.Change = filteredJobs.length;
        } else if (category === this.cancellationFilter.displayValue) {
            this.policyCountInformation.Cancellation = filteredJobs.length;
        } else if (category === this.rewriteFilter.displayValue) {
            this.policyCountInformation.Rewrite = filteredJobs.length;
        }
    }
    return filteredJobs;
};

callFiltersForCurrentFilterOptions = (selectedProducerCode, category, policies) => {
    if (category === this.policyFilter.displayValue) {
        return this.filterAllPolicies(policies, selectedProducerCode);
    }
    return this.filterJobs(category, selectedProducerCode);
};
}
