import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniSxsQuoteService, WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { WniSubmissionService } from 'wni-capability-gateway';
import {
    IncidentsUtil,
    ErrorsAndWarningsUtil,
    PaymentUtil,
    DocumentsUtil,
    QuoteUtil,
    IssuanceValidationUtil,
} from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';
// import { SideBySidePeriodsComponent } from 'wni-capability-gateway-react';
import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import { QuoteCommonMessages as messages } from 'wni-platform-translations';

import WizardPage from '../../templates/HOWizardPage';

import { generateConfigFromLobName } from './config/HOQuoteConstants';

// import HOQuoteUIHelper from './util/HOQuoteUIHelper';
import HOQuoteUIHelperGenerator from './util/HOQuoteUIHelperGenerator';
import HOQuoteIssueRenderHelper from './util/HOQuoteIssueRenderHelper';
import HOQuoteClauseTableUIHelper from './util/HOQuoteClauseTableUIHelper';

// const SXS_LOB_NAME = 'homeowners'; // 'perosnalAuto';


import SideBySidePeriodsComponent from './components/HoSideBySidePeriodsComponent';
import HOAddNewVersionComponent from './components/AddNewVersion/HOAddNewVersionComponent';

import styles from './HOQuotePage.module.scss';
import metadata from './HOQuotePage.metadata.json5';
// import messages from './HOQuotePage.messages';

const LOB_NAME = 'homeowners';

const {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = HOQuoteIssueRenderHelper;

// const {
//     PAYMENT_PLAN_TYPES,
// } = QuoteUtil;

function HOQuoteReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        wizardPageData,
        updateWizardPageData,
        steps: wizardSteps,
        wizardStepToFieldMapping,
        // history,
        //
        lobDataProductKey,
    } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);

    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const [sxsDataDTO, updatesxsDataDTO] = useState(undefined);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);

    const [paymentViewType, updatePaymentViewType] = useState(QuoteUtil.PAYMENT_PLAN_TYPES.monthly);

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    const {
        jobID: quoteID, sessionUUID,
        baseData: {
            periodStatus,
            displayStatus_Ext: displayStatus,
        },   
        lobData: {
            [lobDataProductKey]: {
                householdInfoDTO_Ext: {
                    coveragesForm,
                },
            },
        },
    } = submissionVM.value;

    const quotePageConstants = generateConfigFromLobName(lobDataProductKey);

    const {
        LOB_NAME: SXS_LOB_NAME,
        BRANCH_NAME_PATH,
    } = quotePageConstants;

    const updateSideBySideData = async (clearUWIssues = false) => {
        updateServiceCallInProgress(true);
        let {
            [WizardConstants.sideBySideData]: sideBySideData,
            [WizardConstants.isUwLocked]: isUwLocked
        } = wizardPageData;
        const updatedWizardPageData = {};
        if (!sideBySideData) {
            sideBySideData = await WniSxsQuoteService.retrieveSxsData(quoteID, sessionUUID, authHeader);
            updatedWizardPageData[WizardConstants.sideBySideData] = sideBySideData;
        }
        const newErrorsAndWarnings = _.get(sideBySideData, 'errorsAndWarnings', {});
        if (isExternalUser && clearUWIssues) {
            _.unset(newErrorsAndWarnings, 'underwritingIssues');
        }
        updateErrorsAndWarnings(newErrorsAndWarnings);
        if (sideBySideData[SXS_LOB_NAME]) {
            updatesxsDataDTO(sideBySideData);
        }

        if (_.isNil(isUwLocked)) {
            isUwLocked = await WniCommonQuoteService.isUwLocked(quoteID, sessionUUID, authHeader);
            updatedWizardPageData[WizardConstants.isUwLocked] = isUwLocked;
        }
        if (isUwLocked) {
            updateExtraBlockingSubmitIssues([{ type: 'info', reason: translator(messages.referralSubmitted) }]);
        }

        if (!_.isEmpty(updatedWizardPageData)) {
            updateWizardPageData(updatedWizardPageData);
        }
        updateServiceCallInProgress(false);
    };

    useEffect(() => {
        updateSideBySideData(true);

        //
        const cachedViewType = wizardPageData[WizardConstants.paymentViewType];
        if (cachedViewType) {
            updatePaymentViewType(cachedViewType);
        } else {
            updateWizardPageData({ [WizardConstants.paymentViewType]: paymentViewType });
        }
    }, []);

    const handleDownload = useCallback(() => {
        // do nothing
        return false;
    }, []);

    const generateOverrides = useCallback((onNext) => {
        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        const HOQuoteUIHelper = HOQuoteUIHelperGenerator(quotePageConstants);

        const columnData = HOQuoteUIHelper.generateColumnData({
            submissionVM,
            sxsDataDTO,
            isEditable: false,
            isClueCompare,
            isMvrCompare,
            isServiceCallInProgress,
            isMonthlyPayment: PaymentUtil.isMonthlyPaymentPlan(paymentViewType),
            coveragesForm,
            callbackMap: {
                onFinishQuote: _.noop,
                onSubmitQuote: _.noop,
                onReferToUnderwriter: _.noop,
                onContinueToIssue: _.noop,
                goToIncidentReports: _.noop,
            }
        });
        const tableData = HOQuoteUIHelper.generateTableData(submissionVM, columnData, translator, sxsDataDTO);
        const validationIssues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings);
        const filteredValidationIssues = validationIssues.filter(ErrorsAndWarningsUtil.isIssueOnOrBeforeQuotPage);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            referredToUWMsgSection: {
                validationIssues: extraBlockingSubmitIssues,
            },
            errorsAndWarningsSection: {
                validationIssues: IssuanceValidationUtil.getIssuesMap(filteredValidationIssues),
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator),
                scrollToIssues: true,
                issueRenderFn: getQuotePageIssueRenderFn,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
            },
            quoteStatus: {
                content: displayStatus,
                visible: false,
            },
            addNewVersionBtn: {
                visible: false,
            },
            downloadAction: {
                disabled: true
            },
            sxsPeriodsInfoSection: {
                quoteID,
                columnData,
                isReadOnly: true,
            },
            // paymentViewToggle: {
            //     value: paymentViewType,
            //     disabled: periodStatus === 'Draft' || isServiceCallInProgress,
            //     onValueChange: (value, path) => {
            //         updatePaymentViewType(value);
            //         updateWizardPageData({ [WizardConstants.paymentViewType]: value });
            //     },
            //     visible: !_.isEmpty(columnData),
            // },
            // markAsNotTakenBtn: {
            //     visible: false,
            // },
            noSxsDataMessage: {
                visible: false,
            },
            branchNameEdit: {
                visible: false,
            },
            deleteQuoteVersionButton: {
                visible: false,
            },
            quoteTable: {
                columnData,
                tableData,
                isEditable: false,
                visible: !_.isEmpty(columnData),
                //
                clauseTableRenderHelper: HOQuoteClauseTableUIHelper,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, submissionVM, isServiceCallInProgress, errorsAndWarnings, sxsDataDTO,
        translator, extraBlockingSubmitIssues, paymentViewType, sxsDataDTO]);

    const renderQuotePage = useCallback(({ onNext }) => {
        const overrideProps = generateOverrides(onNext);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                // TODO: set clause operations to _.noop for now
                onStaleQuoteBranchCode: _.noop,
                onChangeSubmissionAndSync: _.noop,
                onChangeSubmission: _.noop,
                onSyncCoverages: _.noop
            },
            resolveComponentMap: {
                validationissuescomponent: ValidationIssuesComponent,
                errorsandwarningsdisplaycomponent: ErrorsAndWarningsDisplayComponent,
                sxsquoteperiods: SideBySidePeriodsComponent,
                addnewversioncomponent: HOAddNewVersionComponent,
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        );
    }, [generateOverrides, submissionVM]); // sxsDataDTO

    // ============================
    const isInitializing = isServiceCallInProgress && !sxsDataDTO;
    // const showNext = periodStatus === 'Bound';
    const showNext = false;
    return (
        <WizardPage showNext={showNext}>
            {isInitializing ? <Loader /> : renderQuotePage}
        </WizardPage>
    );
}

HOQuoteReadOnlyPage.propTypes = {
    ...wizardProps,
    /**
     * (Optional) the product name key in lobData, e.g. homeowners, dwellingProperty, etc.
     */
    lobDataProductKey: PropTypes.string,
};

HOQuoteReadOnlyPage.defaultProps = {
    ...WizardPage.defaultProps,
    // quotePageConstants: undefined,
    lobDataProductKey: LOB_NAME,
};

export default HOQuoteReadOnlyPage;
