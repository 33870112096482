import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-quoteandbind-bop-react';

import PRBOPPolicyDetailsPage from './pages/BOPPolicyDetails/BOPPolicyDetailsPage';
import PRQuotePage from './pages/Quote/QuotePage';

setComponentMapOverrides(
    {
        PRBOPPolicyDetailsPage: { component: 'PRBOPPolicyDetailsPage' },
        PRQuotePage: { component: 'PRQuotePage' }
    },
    {
        PRBOPPolicyDetailsPage,
        PRQuotePage
    }
);
