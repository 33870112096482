import { Accordion, AccordionCard } from '@jutro/components';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from './LocationAdditionalInsuredMultiSublineClauses.messages';
import LocationAdditionalInsuredPerSublineClauses from './LocationAdditionalInsuredPerSublineClauses';
import AccordionCardTitle from '../../../GeneralLiability/components/GLClauseAccordionCardTitle/GLClauseAccordionCardTitle';

const LocationAdditionalInsuredMultiSublineClauses = (props) => {
    const {
        locationPublicID,
        locationClauses,
        updateLocationClauses,
        submissionVM,
        updateWizardData,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        onClickAddSearchCoverage
    } = props;

    const translator = useTranslator();
    const covType = 'additionalInsureds';
    const validationID = 'LocationAdditionalInsuredMultiSublineClauses'


    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate])

    if(!locationPublicID) {
        return null;
    }

    return (
        <AccordionCard
            id="locationMultiSublineAdditionalInsuredClausesCard"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={isEditable ?
                <AccordionCardTitle
                    title={messages.AdditionalInsuredTitle}
                    actionText={messages.AdditionalInsuredActionText}
                    onClick={() => {onClickAddSearchCoverage(covType)}}
                /> : translator(messages.AdditionalInsuredTitle)}
            errorState={showErrors && !isComponentValid}
        >
            <Accordion closeOthers={false} showFrame={false}>
                {locationClauses.locationSublinesClauses.map((sublineClauses, index) => {
                    const setLocationSublineClauses = (newLocationSublineClauses) => {
                        const newLocationClauses = _.clone(locationClauses);
                            newLocationClauses.locationSublinesClauses[index] = newLocationSublineClauses;
                        updateLocationClauses(newLocationClauses);
                    };
                    return (
                        <LocationAdditionalInsuredPerSublineClauses
                            locationPublicID={locationPublicID}
                            locationSublineClauses={sublineClauses}
                            setLocationSublineClauses={setLocationSublineClauses}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            isEditable={isEditable}
                            showErrors={showErrors}
                            wizardData={submissionVM}
                            updateWizardData={updateWizardData}
                            onValidate={onValidate}
                        />
                    );
                })}
            </Accordion>
        </AccordionCard>
    );
};

export default LocationAdditionalInsuredMultiSublineClauses;
