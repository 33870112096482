import { DropdownMenuButton,DropdownMenuLink, CurrencyField } from '@jutro/components';
import { IntlContext,useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useBusinessData } from 'wni-portals-util-react';
import appConfig from 'app-config';
import { FieldLinkComponent } from 'gw-components-platform-react';
import _ from 'lodash';
import React,{ useContext,useEffect,useState } from 'react';
import { WniPolicyService } from 'wni-capability-gateway';
import { useWniModal } from 'wni-components-platform-react';
import { WniAccountsUtil, WniProductsUtil } from 'wni-portals-util-js';
import messages from '../Policies.messages';
import { policiesViews } from '../Policies.util';
import metadata from './PolicyPageTable.metadata.json5';

const {
    lobEndorsementURL
} = appConfig;

const pageConfig = {
    page: 0,
    pageSize: 25
};

const PolicyPageTable = (props) => {
    const {
        currentView,
        policyData,
        jobData,
        search: {
            visible: searchVisible,
            searchValue,
            onSearchFn = _.noop
        } = {},
        history
    } = props;
    const modalApi = useWniModal();
    const translator = useTranslator();
    const {
        authHeader,
        authUserData: {
            isExternalUser_Ext: isExternalUser,
            producerCodes_Ext: userAgencies,
            permissionMap_Ext: {
                createcancellation: hasCreateCancellationPermission,
                createpolchange: hasCreatePolicyChangePermission
            },
            businessData_Ext: {
                enableCommercialLine
            } = {}
        } = {}
    } = useAuthentication();
    const {
        interactionModel
    } = useDependencies(['interactionModel']);
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);

    const { getFeatureCodeValue } = useBusinessData();
    const enablePOI53227GoLiveFeatureForPE = getFeatureCodeValue('EnablePOI53227GoLiveFeatureForPE');

    useEffect(() => {
        updateTablePageConfig(pageConfig)
    }, [currentView, policyData, jobData]);
    const getPolicyAccess = (agencyOfServiceCode) => {
        let result = false;
        if (!isExternalUser) {
            result = true;
        } else {
            _.forEach(userAgencies, (agency) => {
                const { code } = agency;
                if (agencyOfServiceCode === code) {
                    result = true;
                    return false;
                }
                return true;
            });
        }
        return result;
    };

    const showWarningAccordingPolicyAccess = () => {
        modalApi.showAlert({
                title: 'Warning',
                status: 'warning',
                icon: 'gw-error-outline',
                message: messages.warningMsg,
            })
    };
    const onClickPolicyLink = async (policyNumber) => {
        await WniPolicyService.addRecentlyViewedPolicy( policyNumber, authHeader);
    };

    const blockChangeAlert = (isPreemptiveTransaction) => {
        // trigger the block popup when this shouldBlockChange and it is Policy Change and it is and CL product
        return modalApi.showAlert({
            title: messages.blockChangeTitle,
            message: isPreemptiveTransaction ? messages.preemptiveTransaction : messages.oosTransaction,
            status: 'info',
            icon: 'gw-error-outline',
        }).catch(_.noop)
    }

    const getLink = (item, index, { id: property, path}) => {
        let fieldProps = {};
        
        const access = getPolicyAccess(item.producerCodeOfService);
        const {
            oosTransaction_Ext: isOosTransaction,
            preemptiveTransaction_Ext: isPreemptiveTransaction,
            type: jobType,
            productCode,
            // product: {
            //     productCode,
            // } = {}
        } = item || {};
        const shouldBlockChange = isPreemptiveTransaction || isOosTransaction;

        switch (property) {
            case 'policyNumber':
                // const policyUrl = interactionModel.getURLObj(item.accountType, 'policySummary', item.accountNumber, item[property]);
                /**
                 * POI-46831: Clicking on the Account link should bring the user to the account screens for BOTH CL and PL policies
                 */
                // const policyUrl = {
                //     to: `/policies/${item[property]}/summary`
                // }
                let policyUrl = {};
                if(!enableCommercialLine) { // classical is Only PL line
                    policyUrl = interactionModel.getURLObj(item.accountType, 'policySummary', item.accountNumber, item[property]);
                } else {
                    policyUrl = {
                        to: `/policies/${item[property]}/summary`
                    };
                }
                const policyUrlProps = access ? policyUrl : {};
                if(shouldBlockChange && jobType === 'PolicyChange' &&  WniProductsUtil.isCLProduct(productCode)) {
                    fieldProps = {
                        onClick: () => blockChangeAlert(isPreemptiveTransaction)
                    }
                } else {
                    fieldProps = {
                        title: translator(messages.aosTooltipMessage),
                        onClick: access ? () => onClickPolicyLink(item[property]) : showWarningAccordingPolicyAccess,
                        ...policyUrlProps,
                    };
                };
                break;
            case 'policyAccountNumber':
                /** if accountType == 'person': online env need to jump to AO env, and local still jump to PE url 
                 *  if accountType == 'company': only need to jump PE only URL
                */
                // const policyAccountUrl = interactionModel.getURLObj(item.accountType, 'accountSummary', item[property]);
                /**
                 * POI-46831: Clicking on the Account link should bring the user to the account screens for BOTH CL and PL policies
                 */

                let policyAccountUrl = {}
                if(!enableCommercialLine) { // POI-52277: Pre Go-Live ticket to ensure CL and dashboard screens are not accessible in PE until 8/18 date
                    policyAccountUrl = interactionModel.getURLObj(item.accountType, 'accountSummary', item[property]);
                } else {
                    policyAccountUrl = {
                        to: `/accounts/${item[property]}/summary`
                    }
                }

                const policyAccountUrlProps = access ? policyAccountUrl : {};
                fieldProps = {
                    title: translator(messages.aosTooltipMessage),
                    onClick: access ? _.noop : showWarningAccordingPolicyAccess,
                    ...policyAccountUrlProps,
                };
                break;
            case 'jobNumber':
                // const {
                //     oosTransaction_Ext: isOosTransaction,
                //     preemptiveTransaction_Ext: isPreemptiveTransaction,
                //     type: jobType,
                //     product: {
                //         productCode,
                //     } = {}
                // } = item || {};               
                if(shouldBlockChange && jobType === 'PolicyChange' && WniProductsUtil.isCLProduct(item?.product?.productCode || productCode)) {
                    fieldProps = {
                        onClick: () => blockChangeAlert(isPreemptiveTransaction)
                    }
                } else {
                    fieldProps = {
                        to: `/${currentView}/${item[property]}/summary`
                    };
                }
                
                break;
            case 'accountNumber':
                /** if accountType == 'person': online env need to jump to AO env, and local still jump to PE url 
                 *  if accountType == 'company': only need to jump PE only URL
                */
                // const accountUrl = interactionModel.getURLObj(item.accountType, 'accountSummary', item[property]);
                /**
                 * POI-46831: Clicking on the Account link should bring the user to the account screens for BOTH CL and PL policies
                 */
                let accountUrl = {};
                if(!enableCommercialLine) {  // POI-52277: Pre Go-Live ticket to ensure CL and dashboard screens are not accessible in PE until 8/18 date
                    accountUrl = interactionModel.getURLObj(item.accountType, 'accountSummary', item[property]);
                } else {
                    accountUrl = {
                        to: `/accounts/${item[property]}/summary`
                    }
                }
                fieldProps = {
                    title: item[property],
                    ...accountUrl
                };
                break;
            default:
                break;
        }
        return (
            <FieldLinkComponent
                id={`${property}${item[property]}`}
                value={item[path]}
                {...fieldProps}
            />
        );
    };

    const getPremiumCell = (jobSummary) => {
        const {
            jobStatus,
            premium,
            type,
            accountType,
            isEditLocked_Ext: isEditLocked
        } = jobSummary
        const isCL = WniAccountsUtil.isCLAccount(accountType);
       
        if(type === 'Renewal' && isEditLocked && isCL) { // POI-50645: For Policy Renewals the premium value should be hidden in the event of a transaction lock for ONLY CL
            return '-'
        }

        if (jobStatus === 'Quoted' || jobStatus === 'Approved' || jobStatus === 'Bound' || jobStatus === 'Rated') {
            return <CurrencyField contentContainerClassName="font-normal" readOnly value={premium} />
        }
        return '-'
    }

    const renderDBACell = (item, index, { path }) => {
        return item[path] || item.accountHolderName
    };

    const onChangePolicyClick = (item) => {
        const { policyNumber: policyNumberParam, productCode } = item;
        history.push(lobEndorsementURL[productCode], {
            policyNumber: policyNumberParam,
        });
    };

    const onCancelPolicyClick = (item) => {
        const { policyNumber: policyNumberParam } = item;
        const historyState = {
            disableCancelLink: false,
            disableChangePolicyLink: true,
            displayBtnGroup: false
        };
        history.push(`/policies/${policyNumberParam}/summary`, historyState);
    };
    const renderPolicyAction = (item) => {
        const isCL = WniAccountsUtil.isCLAccount(item.accountType);
        if (isCL && !enablePOI53227GoLiveFeatureForPE) {
            return '-';
        }

        const actionAvailable = hasCreateCancellationPermission || hasCreatePolicyChangePermission;
        const access = getPolicyAccess(item.producerCodeOfService);
        if (actionAvailable) {
            return (
                <div>
                    <DropdownMenuButton
                        icon="gw-expand-more"
                        id="dropdownMenuButton"
                        className="dropDownMenuIconbtn"
                        menuClassName="dropDownMenuList"
                        alignRight
                    >
                        {hasCreatePolicyChangePermission && (
                            <DropdownMenuLink
                                onClick={access ? () => onChangePolicyClick(item) : showWarningAccordingPolicyAccess}
                            >
                                {translator(messages.changePolicy)}
                            </DropdownMenuLink>
                        )}
                        {hasCreateCancellationPermission && (
                            <DropdownMenuLink
                                onClick={access ? () => onCancelPolicyClick(item) : showWarningAccordingPolicyAccess}
                            >
                                {translator(messages.cancelPolicy)}
                            </DropdownMenuLink>
                        )}
                    </DropdownMenuButton>
                </div>
            );
        }
    };
    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };
    //-------------------------------------------
    const overrideProps = {
        policyFilterBar: {
            onValueChange: (value) => onSearchFn(value, 'policy'),
            value: searchValue,
            visible: !!searchVisible
        },
        jobFilterBar: {
            onValueChange: (value) => onSearchFn(value, 'job'),
            value: searchValue,
            visible: !!searchVisible
        },
        recentlyViewedPoliciesSection: {
            visible: policiesViews.includes(currentView),
        },
        openQuotesSection: {
            visible: !policiesViews.includes(currentView),
        },
        recentlyViewedPoliciesTable: {
            showSearch: !!searchVisible,
            data: policyData,
            showPagination: policyData.length > 25,
            config: tablePageConfig,
            onConfigChange,
            pageSizeOptions: [25, 50]
        },
        openQuotesTable: {
            showSearch: !!searchVisible,
            data: jobData,
            showPagination: jobData.length > 25,
            config: tablePageConfig,
            onConfigChange,
            pageSizeOptions: [25, 50]
        },
        perfilledQuoteTitle: {
            headerClassName: currentView === 'Quotes' ? '' : 'hide',
            columnClassName: currentView === 'Quotes' ? '' : 'hide'
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            {},
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
            getLink,
            getPremiumCell,
            renderDBACell,
            renderPolicyAction,
            sortString: DatatableUtil.sortString,
            sortNumber: DatatableUtil.sortNumber,
            sortCurrency: DatatableUtil.sortCurrency,
            sortDate: DatatableUtil.sortDate,
        },
        resolveComponentMap: {
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
        />
    );
};

export default PolicyPageTable;
