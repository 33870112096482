import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';

import messages from './CopyVehicleCoverageModal.messages';
import metadata from './CopyVehicleCoverageModal.metadata.json5';
import style from './CopyVehicleCoverageModal.module.scss';

function CopyVehicleCoverageModal(props) {
    const {
        isOpen,
        size,
        onResolve,
        triggerCopyCoverageVehiclePublicID,
        submissionVM,
        updateSubmissionVMOnCopyCoverage,
        hasCompVehicleCoverage,
    } = props;

    const {
        lobData: {
            personalAuto: {
                coverables: {
                    vehicles
                }
            }
        }
    } = submissionVM.value;
    const breakpoint = useContext(BreakpointTrackerContext);

    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const translator = useTranslator();

    const [copyFromPublicID, setCopyFromPublicID] = useState(triggerCopyCoverageVehiclePublicID);
    const [copyToPublicIDs, setCopyToPublicIDs] = useState([]);

    useEffect(() => {
        setCopyToPublicIDs([]);
        setCopyFromPublicID(hasCompVehicleCoverage(
            triggerCopyCoverageVehiclePublicID
        ) ? triggerCopyCoverageVehiclePublicID : null);
    }, [triggerCopyCoverageVehiclePublicID, hasCompVehicleCoverage, isOpen]);


    const getVehicleDescription = (value) => {
        if (value.vehicleType_Ext === 'noVehicleNamedNonOwner_Ext') {
            return 'Named Non-Owner';
        }
        return `${value.year} ${value.make} ${value.model} (${value.vin.slice(value.vin.length - 4)})`;
    };

    const copyFromAvailableValues = vehicles
        .filter((vehicle) => hasCompVehicleCoverage(vehicle.publicID))
        .map((vehicle) => {
            return {
                code: vehicle.publicID,
                name: getVehicleDescription(vehicle),
            };
        });

    const copyToAvailableValues = copyFromPublicID ? vehicles
        .filter((vehicle) => vehicle.publicID !== copyFromPublicID)
        .map((vehicle) => {
            return {
                code: vehicle.publicID,
                name: getVehicleDescription(vehicle),
            };
        }) : [];

    const isComponentValid = copyFromPublicID && !_.isEmpty(copyToPublicIDs);
    const onCopyFromValueChange = (value) => {
        setCopyFromPublicID(value);
        setCopyToPublicIDs([]);
    };

    const onCopyToValueChange = (value) => {
        setCopyToPublicIDs(value);
    };

    const handleCopyCoverages = useCallback(
        async () => {
            setLoadingMask(true);
            await updateSubmissionVMOnCopyCoverage(copyFromPublicID, copyToPublicIDs);
            setLoadingMask(false);
            onResolve();
        },
        [
            onResolve,
            copyFromPublicID,
            copyToPublicIDs,
            setLoadingMask,
            updateSubmissionVMOnCopyCoverage,
        ],
    );
    const onCopyAllValueChange = (ids) => {
        if (_.includes(ids, 'selectAll')) {
            const selectIds = copyToAvailableValues.map((item) => item.code);
            setCopyToPublicIDs(selectIds);
        } else {
            setCopyToPublicIDs([]);
        }
    };

    const copyToAvailableValueCodes = copyToAvailableValues
        .map((copyToavailableValue) => copyToavailableValue.code);

    const selectAll = _.xor(copyToPublicIDs, copyToAvailableValueCodes).length === 0
        ? ['selectAll'] : [];

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        copyFromDropDownSelect: {
            availableValues: copyFromAvailableValues,
            value: copyFromPublicID,
        },
        copyFormSelectAll: {
            onValueChange: onCopyAllValueChange,
            value: selectAll,
            visible: copyToAvailableValues.length > 1
        },
        copyToCheckboxGroup: {
            availableValues: copyToAvailableValues,
            value: copyToPublicIDs,
            label: copyToAvailableValues.length === 1 ? translator(messages.copyToLabel) : '  '
        }
    };

    const resolvers = {
        resolveClassNameMap: style,
        resolveCallbackMap: {
            onCopyFromValueChange,
            onCopyToValueChange,
        },
        resolveComponentMap: {
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            className={size}
        >
            <ModalHeader
                title={translator(messages.title)}
                onClose={() => onResolve(triggerCopyCoverageVehiclePublicID)}
            />
            <ModalBody id="vehicleCoverageModal">
                <MetadataContent
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    {...resolvers} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => onResolve(triggerCopyCoverageVehiclePublicID)} type="outlined">
                    {translator(messages.cancel)}
                </Button>
                <Button onClick={handleCopyCoverages} disabled={!isComponentValid}>
                    {translator(messages.confirm)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

CopyVehicleCoverageModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    size: PropTypes.string,
    onResolve: PropTypes.func.isRequired,
    triggerCopyCoverageVehiclePublicID: PropTypes.string,
    submissionVM: PropTypes.shape(
        {
            value: PropTypes.shape({
                lobData: PropTypes.shape({
                    personalAuto: PropTypes.shape({
                        coverables: PropTypes.shape({})
                    }),
                })
            })
        }
    ),
    updateSubmissionVMOnCopyCoverage: PropTypes.func.isRequired,
    hasCompVehicleCoverage: PropTypes.func.isRequired,
};

CopyVehicleCoverageModal.defaultProps = {
    submissionVM: {},
    size: 'md',
    triggerCopyCoverageVehiclePublicID: null
};

export default CopyVehicleCoverageModal;
