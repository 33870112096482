import { CheckboxField } from '@jutro/components';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import React from 'react';

const WniCheckboxGroup = (props) => {
    const { availableValues = [], value = [], onValueChange = _.noop } = props;
    const handleCheckboxField = (val, code) => {
        if (val) {
            const checkedValues = value.concat([code]);
            onValueChange(checkedValues);
        } else {
            const checkedValues = value.filter((v) => v !== code);
            onValueChange(checkedValues);
        }
    };

    return (
        <Flex alignContent="top" gap="none" direction='column'>
            {availableValues.map((item) => {
                const isChecked = value.some((v) => v === item.code);
                return (
                    <CheckboxField
                        key={item.code}
                        label={item.name}
                        icon={item.icon}
                        disabled={item.isDisabled}
                        value={isChecked}
                        showInlineLabel
                        onValueChange={(val) =>
                            handleCheckboxField(val, item.code)
                        }
                    />
                );
            })}
        </Flex>
    );
};

export default WniCheckboxGroup;
