import React, {
    useEffect,
    useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { PolicyService, UserService } from 'gw-capability-gateway';
import { WniPolicyService } from 'wni-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {  useProductsData, useBusinessData } from 'wni-portals-util-react';
import { WniProductsUtil } from 'wni-portals-util-js';
import { Loader } from '@jutro/components';
import { CustomizedHeaderComponent } from 'gw-components-platform-react';
import cx from 'classnames';
// import styles from 'gw-capability-gateway-react/Policies/Policies.module.scss';
import messages from 'gw-capability-gateway-react/Policies/Policies.messages';
import { FormattedHeaderComponent } from '../../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import metadata from './PolicyDetails.metadata.json5';
import CommonRoutes from '../../Common/CommonRouting';
import pageRouting from '../../Common/Policies-config.json5';
import styles from './PolicyDetails.module.scss';

function PolicyDetails(props) {
    const {
        match: {
            url: matchURL,
        },
        location,
    } = props;
    const { authHeader, isAuthenticated } = useAuthentication();
    const [policyResponse, updatePolicyResponse] = useState({});
    const [policyBillingData, updatePolicyBillingData] = useState({});
    const [currentView, updateCurrentView] = useState(undefined);
    const [commissionTurnedOn, updateCommissionTurnedOn] = useState(false);
    const { loadingMask: { setLoadingMask } } = useDependencies(['loadingMask']);
    const { getProductName } = useProductsData();
    const { enableCommercialLine } = useBusinessData();
    const getAccountHolderNameandNumber = (accountData, type) => {
        if (_.isEmpty(accountData)) {
            return null;
        }
        if (type === 'status') {
            return _.get(accountData, "latestPeriod.displayStatus");
        }
        const productName = getProductName(_.get(accountData, "product.productCode"));
        return `${productName} (${accountData.policyNumber})`;
    };

    const billingFeatureTurnedOn = (routes) => {
        const { gatewayBilling } = appConfig;
        if (gatewayBilling) {
            const billingRoute = {
                path: '/billing',
                component: 'Policies/Billing/PoliciesBilling'
            };
            routes.push(billingRoute);
        }
    };

    const isClaimsTurnedOn = () => {
        const { fnolLobs } = appConfig;
        return !_.isEmpty(fnolLobs);
    };

    const claimFeatureTurnedOn = (routes) => {
        if (isClaimsTurnedOn()) {
            const claimRoute = {
                path: '/claims',
                component: 'Policies/Claims/PolicyClaims'
            };
            routes.push(claimRoute);
        }
    };

    const hasAccessForCommision = (routes) => {
        if (commissionTurnedOn) {
            const commissionRoute = {
                path: '/commission',
                component: 'Policies/Commission/PoliciesCommission'
            };
            routes.push(commissionRoute);
        }
    };
    const getRoutesWithFeature = () => {
        const routes = [...pageRouting];
        billingFeatureTurnedOn(routes);
        hasAccessForCommision(routes);
        claimFeatureTurnedOn(routes);
        return routes;
    };

    const updateCountInfo = (type) => {
        const currentCount = _.get(policyResponse, `numberOf${type}`);

        const newResponse = _.clone(policyResponse);
        _.set(newResponse, `numberOf${type}`, currentCount + 1);
        updatePolicyResponse(newResponse);
    };

    const updateCountInfoDriectly = (type, value) => {
        const newResponse = _.clone(policyResponse);
        _.set(newResponse, `numberOf${type}`, value);
        updatePolicyResponse(newResponse);
    };

    const updateDeleteDocumentCountInfo = (type) => {
        const currentCount = _.get(policyResponse, `numberOf${type}`);

        const newResponse = _.clone(policyResponse);
        _.set(newResponse, `numberOf${type}`, currentCount - 1);
        updatePolicyResponse(newResponse);
    };

    const getCommissionTurnedOn = async (authHeaderParam) => {
        const permissionDTO = {
            permission: 'viewcommissions'
        };
        const newCommissionTurnedOn = await UserService.hasUserSystemPermission(permissionDTO,
            authHeaderParam);
        updateCommissionTurnedOn(newCommissionTurnedOn);
    };

    const getPolicyDetails = async () => {
        setLoadingMask(true);
        const { gatewayBilling } = appConfig;
        const {
            match: { params: { policyNumber } },
            location: { state: redirectPath, pathname }
        } = props;

        if (gatewayBilling) {
            const billingResponseData = await PolicyService.getPolicyBillingSummaryInfo(
                policyNumber, authHeader
            );
            // const billingData = billingResponseData.policyPeriodBillingSummaries;
            // const newBillingIcon = _.get(billingData, '[0].isDelinquent') ? 'exclamation-triangle' : 'gw-credit-card';
            updatePolicyBillingData(billingResponseData);
            // updateBillingIcon(newBillingIcon);
        }

        if (isAuthenticated) {
            getCommissionTurnedOn(authHeader);
            const getPolicyResponse = await PolicyService.getPolicy(policyNumber, authHeader);
            const routePath = pathname && (pathname.trim().split('/') || []);
            const selectedPageFromPath = routePath[routePath.length - 1];
            const activeTile = selectedPageFromPath || redirectPath || 'summary';

            updateCurrentView(activeTile);
            updatePolicyResponse(getPolicyResponse);
        }
        await WniPolicyService.addRecentlyViewedPolicy(policyNumber, authHeader);
        setLoadingMask(false);
    };

    useEffect(() => {
        getPolicyDetails();
    }, []);

    const handleTilesOnClick = (id) => {
        updateCurrentView(id);
    };

    const render = () => {
        const { gatewayBilling } = appConfig;
        // if (isLoadingMask) {
        //     return null;
        // }

        if (_.isEmpty(policyResponse)) {
            return null;
        }

        const productCode = _.get(policyResponse, 'product.productCode');
        const isCLProduct = WniProductsUtil.isCLProduct(productCode);
        const overrides = {
            [currentView]: {
                active: true
            },
            activities: {
                visible: false
            },
            notes: {
                visible: false
            },
            accountPageTileContainer: {
                visible: enableCommercialLine
            },
            commission: {
                active: (currentView === 'commission'),
                visible: commissionTurnedOn
            },
            billing: {
                active: (currentView === 'billing'),
                visible: gatewayBilling
            },
            claims: {
                active: (currentView === 'claims'),
                visible: isClaimsTurnedOn()
            },
            policyProductName: {
                title: getAccountHolderNameandNumber(policyResponse, '')
            },
            policyTypeItem: {
                content: _.get(policyResponse, 'latestPeriod.policyType_Ext')
            },
            policyStatus: {
                message: getAccountHolderNameandNumber(policyResponse, 'status')
            },
            accountHeader: {
                urlParams: ['accountSummary', _.get(policyResponse, "account.accountNumber")],
                displayName: _.get(policyResponse, "account.accountHolder.displayName"),
                message: messages.accountNavigation
            }
        };

        const resolvers = {
            resolveComponentMap: {
                Loader: Loader,
                customizedheadercomponent: CustomizedHeaderComponent,
                accountheadercomponent: FormattedHeaderComponent
            },
            resolveCallbackMap: {
                handleTilesOnClick: handleTilesOnClick
            },
            resolveClassNameMap: styles
        };
        const policyDetailsData = {
            policyResponse: policyResponse,
            getUpdateCountInfo: updateCountInfo,
            getDocumentDeleteCountInfo: updateDeleteDocumentCountInfo,
            updateCountInfoDriectly: updateCountInfoDriectly
        };
        return (
            <div className={cx(styles.policy)}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={policyResponse}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
                <CommonRoutes
                    steps={getRoutesWithFeature()}
                    basePath={matchURL}
                    policyDetailsData={policyDetailsData}
                    billingData={policyBillingData}
                />
            </div>
        );
    };

    return render();
}

PolicyDetails.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        params: PropTypes.shape({
            policyNumber: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.string,
        pathname: PropTypes.string
    }).isRequired,
};

// export default withAuthenticationContext(PolicyDetails);
export default withRouter(PolicyDetails);
