import { setComponentMapOverrides } from '@jutro/uiconfig';

//
import HOHouseholdInfoPage from './pages/HouseholdInfo/HOHouseholdInfoPage';
import HOHouseholdInfoReadOnlyPage from './pages/HouseholdInfo/HOHouseholdInfoPage.readonly';

import HOResidencePage from './pages/HOResidence/HOResidencePage';
import HOResidenceReadOnlyPage from './pages/HOResidence/HOResidencePage.readonly';

import HOCoveragesPage from './pages/HOCoverages/HOCoveragesPage';
import HOCoveragesReadOnlyPage from './pages/HOCoverages/HOCoveragesPage.readonly';

import HODPCoveragesBasePage from './pages/HOCoverages/HODPCoveragesBasePage';
import HODPCoveragesBaseReadOnlyPage from './pages/HOCoverages/HODPCoveragesBasePage.readonly';

import HOLossHistoryAndPriorCarrierPage from './pages/LossHistoryAndPriorCarrier/HOLossHistoryAndPriorCarrierPage';
import HOLossHistoryAndPriorCarrierReadOnlyPage from './pages/LossHistoryAndPriorCarrier/HOLossHistoryAndPriorCarrierPage.readonly';

import HOAdditionalInterestsPage from './pages/AdditionalInterests/HOAdditionalInterestsPage';
import HOAdditionalInterestsReadOnlyPage from './pages/AdditionalInterests/HOAdditionalInterestsPage.readonly';

//
import HOQuotePage from './pages/Quote/HOQuotePage';
import HOQuoteReadOnlyPage from './pages/Quote/HOQuotePage.readonly';

setComponentMapOverrides(
    {
        HOHouseholdInfoPage: {component: 'HOHouseholdInfoPage'},
        HOHouseholdInfoReadOnlyPage: { component: 'HOHouseholdInfoReadOnlyPage' },
        
        HOResidencePage: { component: 'HOResidencePage' },
        HOResidenceReadOnlyPage: { component: 'HOResidenceReadOnlyPage' },
        
        HOCoveragesPage: { component: 'HOCoveragesPage' },
        HOCoveragesReadOnlyPage: { component: 'HOCoveragesReadOnlyPage' },

        HOLossHistoryAndPriorCarrierPage: { component: 'HOLossHistoryAndPriorCarrierPage'},
        HOLossHistoryAndPriorCarrierReadOnlyPage: {component: 'HOLossHistoryAndPriorCarrierReadOnlyPage'},

        HOAdditionalInterestsPage: { component: 'HOAdditionalInterestsPage' },
        HOAdditionalInterestsReadOnlyPage: { component: 'HOAdditionalInterestsReadOnlyPage' },
        
        // To avoid name conflict with gw-cadpability-quoteadnbind-ho-react/index.js
        // HOQuotePage: { component: 'HOQuotePage' },
        WniHOQuotePage: { component: 'WniHOQuotePage' },
        HOQuoteReadOnlyPage: { component: 'HOQuoteReadOnlyPage' },
    },
    {
        HOHouseholdInfoPage,
        HOHouseholdInfoReadOnlyPage,

        HOResidencePage,
        HOResidenceReadOnlyPage,

        HOCoveragesPage,
        HOCoveragesReadOnlyPage,

        HOLossHistoryAndPriorCarrierPage,
        HOLossHistoryAndPriorCarrierReadOnlyPage,

        HOAdditionalInterestsPage,
        HOAdditionalInterestsReadOnlyPage,

        // HOQuotePage,
        WniHOQuotePage: HOQuotePage,
        HOQuoteReadOnlyPage,
    }
);


export {
    HOHouseholdInfoPage,
    HOHouseholdInfoReadOnlyPage,

    HOResidencePage,
    HOResidenceReadOnlyPage,

    HOCoveragesPage,
    HOCoveragesReadOnlyPage,

    HODPCoveragesBasePage,
    HODPCoveragesBaseReadOnlyPage,

    HOLossHistoryAndPriorCarrierPage,
    HOLossHistoryAndPriorCarrierReadOnlyPage,

    HOAdditionalInterestsPage,
    HOAdditionalInterestsReadOnlyPage,

    HOQuotePage,
    HOQuoteReadOnlyPage,
};

// eslint-disable-next-line import/prefer-default-export
export { default as HOSubmissionWizard } from './HOSubmissionWizard';
// export { default as HOJobWizard } from './templates/HOJobWizard';
export { default as HOWizardPage } from './templates/HOWizardPage';

export { default as HouseholdResidentsPopup } from './pages/HouseholdInfo/component/HouseholdResidentsPopup/HouseholdResidentsPopup';
export { default as HouseholdLocationPopupComponent } from './pages/HouseholdInfo/component/HouseholdLocationPopup/HouseholdLocationPopupComponent';
export { default as HOAddNewVersionComponent } from './pages/Quote/components/AddNewVersion/HOAddNewVersionComponent';
export { default as HOWizardQuestionnaireComponent } from './components/HOWizardQuestionnaireComponent';
export { default as HOCoverageUtil } from './pages/HOCoverages/util/HOCoverageUtil';
