import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
} from '@jutro/components';
import metadata from './TeamTabComponent.metadata.json5';

function TeamTab(props) {
    const {
        title,
        size,
        underwriterVM,
        isOpen,
        onReject
    } = props;

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} onClose={onReject} />
            <ModalBody id="teamTabPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={underwriterVM}
                />
            </ModalBody>
        </ModalNext>
    );
}

TeamTab.propTypes = {
    title: PropTypes.string,
    size: PropTypes.string,
    underwriterVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ).isRequired,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func
};

TeamTab.defaultProps = {
    title: '',
    size: '',
    isOpen: false,
    onReject: _.noop
};

export default TeamTab;
