import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Loader,
} from '@jutro/components';
import { PUHouseholdInfoService } from 'wni-capability-quoteandbind-pu';
import { WindowUtil, WniPNIUtil, WniUMBDriverUtil } from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { PersonalAccountContactInputComponent, getValidationMap } from 'wni-capability-gateway-react';
import UMBDriverComponent from '../UMBDriverComponent/UMBDriverComponent'
import metadata from './PUAddResidentsInputPopup.metadata.json5';
import messages from './PUAddResidentsInputPopup.messages';
import styles from './PUAddResidentsInputPopup.module.scss';


function PUAddResidentsInputPopup(props) {
    const {
        quoteID, 
        sessionUUID,
        title,
        size,
        addBtnLabel,
        saveBtnLabel,
        cancelBtnLabel,
        // driverVM,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        //
        readOnly: allFieldsReadOnly,
        // disablePNISwitch,
        showEmployeeFlagToggle,
        useAuthenticationData,
        policyAddress
    } = props;

    const driverVM = viewModelService.create(
        {}, 'pc', 'wni.edge.capabilities.quote.lob.personalumbrella.dto.UMBDriverDTO'
    );
    _.set(driverVM, 'publicID', 'new');
    const modalApi = useWniModal();
    const { authHeader } = useAuthenticationData;
    const translator = useTranslator();
    const [currentDriverVM, updateCurrentDriverVM] = useState(driverVM);
    // const [driverVM, updateDriverVM] = useState(umbDriverAsPNIVM)
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const {
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('PUAddResidentsInputPopup');

    const IsValidFields = useCallback(() => {
        if (currentDriverVM.aspects.valid && currentDriverVM.aspects.subtreeValid && !_.isNil(currentDriverVM.value.isLicensedDriver) && _.isNil(currentDriverVM.value.occupancy_Ext)) {
            return true;
        }
        return false;
    }, [currentDriverVM]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, currentDriverVM]);

    const writeValue = useCallback((value, path) => {
        const newVM = viewModelService.clone(currentDriverVM);
        _.set(newVM, path, value);
        updateCurrentDriverVM(newVM);
    }, [viewModelService, currentDriverVM]);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const handleSave = useCallback( 
        async() => {
            if (!isComponentValid) { 
                handleValidation();
                return false;
            }

            // const isVerified = await uiUtil.onVerified();
            // const isVerified = true
            // if (!isVerified) {
            //     return false;
            // }
            _.set(currentDriverVM, 'primaryAddress', policyAddress)
            setIsLoading(true);
            
            const res = await PUHouseholdInfoService.createResidents(quoteID, sessionUUID, currentDriverVM.value, authHeader);
            setIsLoading(false);
            // const PUAddResidentsComponentVM = { 
            //     allDriversVM
            // }
            onResolve(res);
        },
        [isComponentValid, currentDriverVM, policyAddress, quoteID, sessionUUID, authHeader, onResolve, handleValidation]
    );

    const handleSaveAndAdd = useCallback(
        async() => {
            if (!isComponentValid) {
                handleValidation();
                return false;
            }
            _.set(currentDriverVM, 'primaryAddress', policyAddress)
            // const isVerified = await uiUtil.onVerified();
            // const isVerified = true
            // if (!isVerified) {
            //     return false;
            // }
             setIsLoading(true);

            const res = await PUHouseholdInfoService.createResidents(quoteID, sessionUUID, currentDriverVM.value, authHeader);
            updateCurrentDriverVM(driverVM)
            updateShowErrors(false);
            setIsLoading(false)
        },
        [isComponentValid, currentDriverVM, policyAddress, quoteID, sessionUUID, authHeader, driverVM, handleValidation]
    );



    const overrideProps = {
        // '@all': {
        // },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            showErrors,
            readOnly: allFieldsReadOnly,
        },
        dynamicInlineNotificationContainer: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        infoContainer: {
            model: currentDriverVM,
            onValueChange: writeValue,
            showErrors,
            // disablePNISwitch,
            showEmployeeFlagToggle,
            visibleFields: {
                showMiddleName: false,
                showDateOfBirth: true,
                showPrimaryPhoneType: false,
                showPrimaryPhone: false,
                showPrimaryEmail: false,
                showAdditionalNamedInsured: true,
                showRelationshipToInsured: true
    
            }
        },
        umbDriverContainer: {
            model: currentDriverVM,
            // dataPath: 'primaryAddress',
            onValueChange: writeValue,
            // hideFieldType: {
            //     country: true,
            //     addressType: true
            // },
            onValidate,
            useAuthenticationData
        },
    };

    const resolvers = {
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            accountcontactdetailscomponent: PersonalAccountContactInputComponent,
            umbdrivercomponent: UMBDriverComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            // onFullNameChange: handlePNIChange,
        }

    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title || translator(messages.title)} />
            <ModalBody id="primaryNamedInsuredModal">
                {/* <ViewModelForm
                        uiProps={metadata.componentContent}
                        model={currentDriverVM}
                        onValidationChange={onValidate}
                        onValueChange={writeValue}
                        overrideProps={overrideProps}
                        classNameMap={resolvers.resolveClassNameMap}
                        componentMap={resolvers.resolveComponentMap}
                        callbackMap={resolvers.resolveCallbackMap}
                /> */}
                { isLoading ? <Loader/> : 
                    <ViewModelForm
                        uiProps={metadata.componentContent}
                        model={currentDriverVM}
                        onValidationChange={onValidate}
                        onValueChange={writeValue}
                        overrideProps={overrideProps}
                        classNameMap={resolvers.resolveClassNameMap}
                        componentMap={resolvers.resolveComponentMap}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined" className={styles.cancelBtnMargin}>
                    {cancelBtnLabel || translator(messages.cancel)}
                </Button>
                <Button onClick={handleSaveAndAdd} >
                    {addBtnLabel || translator(messages.add)}
                </Button>
                <Button onClick={handleSave} >
                    {saveBtnLabel || translator(messages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PUAddResidentsInputPopup.propTypes = {
    primaryNamedInsuredVM: PropTypes.shape({}).isRequired,
    policyNamedInsuredCandidatesVM: PropTypes.shape({
        getElement: PropTypes.func.isRequired,
    }).isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    actionBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    //
    readOnly: PropTypes.bool,
    disablePNISwitch: PropTypes.bool,
    showEmployeeFlagToggle: PropTypes.bool,
};

PUAddResidentsInputPopup.defaultProps = {
    actionBtnLabel: undefined,
    cancelBtnLabel: undefined,
    size: 'md',
    //
    readOnly: false,
    disablePNISwitch: false,
    showEmployeeFlagToggle: true,
};

export default withAuthenticationContext(PUAddResidentsInputPopup);
