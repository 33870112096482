
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { PortalConstants } from 'wni-portals-config-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { WCCoveragesService, WCModifierService } from 'wni-capability-quoteandbind-wc';
import { ButtonLink } from '@jutro/router';
import { Flex } from '@jutro/layout';
import CoveredEmployees from '../CoveredEmployees/CoveredEmployees';
import WCStateStandardPartOneCoverages from '../WCStateStandardPartOneCoverages/WCStateStandardPartOneCoverages';
import WCStateStandardPartTwoCoverages from '../WCStateStandardPartTwoCoverages/WCStateStandardPartTwoCoverages';
import WCStateAdditionalCoverages from '../WCStateAdditionalCoverages/WCStateAdditionalCoverages';
import ModifiersPopup from '../ModifiersPopup/ModifiersPopup';
import metadata from './StateSpecificComponent.metadata.json5';
import styles from '../../WCStateSpecificInformationPage.module.scss';
import WCStateDetails from './WCStateDetails/WCStateDetails';
import WCModifiersConfig from '../../config/WCModifiersPage.config';
import WCStateRatingPeriodsCoverages from '../WCStateRatingPeriodsCoverages/WCStateRatingPeriodsCoverages'

function StateSpecificComponent(props) {
    const {
        submissionVM,
        updateWizardData,
        stateSpecificVM,
        onValidate,
        onValueChange,
        syncWizardData,
        updateWizardSnapshot,
        updateSubmissionVMForResponse,
        resetWizardDataToSnapshot,
        generateValidationIssues,
        showErrors,
        updateShowErrors,
        messages,
        isReadOnly,
        isPolicyChange,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        }
    } = props;


    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
        },
        lobData: {
            workersComp: {
                offerings,
                modifiers_Ext : modifiers
            }
        },
    } = submissionVM.value;

    const {
        agentStateTotalRangeMap
    } = WCModifiersConfig;
    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authUserData } = useAuthentication();
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const [validationIssues, updateValidationIssues] = useState([]);
    const [loadingClauses, setLoadingClauses] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const selectedState = _.get(stateSpecificVM.value, 'jurisdiction');
    const modifierAK = modifiers.find(modifier => modifier.jurisdiction === 'AK');
    // for agent limit should be +-25%, for UW, limit should follow PC. +-40%
    const overallMinAK = useMemo(() => {
        if (selectedState === 'AK') {
            return isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.minimum`) : modifierAK.overallMinimum;
        }
        return undefined;    
    }, [agentStateTotalRangeMap, isExternalUser, modifierAK, selectedState])
    const overallMaxAK = useMemo(() => {
        if (selectedState === 'AK') {
            return isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.maximum`) : modifierAK.overallMaximum;
        }
        return undefined;
    }, [agentStateTotalRangeMap, isExternalUser, modifierAK, selectedState])

    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData)
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const stateModifierIndex = modifiers.findIndex(modifier => modifier.jurisdiction === selectedState);
    const saveModifier = useCallback(async(rateFactors) => {
        setLoadingMask(true);
        const newRateFactors = await WCModifierService.updateScheduleRateModifiers(jobID, sessionUUID, rateFactors, selectedState, authHeader);
        setLoadingMask(false);
        _.set(submissionVM, `value.lobData.workersComp.modifiers_Ext[${stateModifierIndex}].rateFactors`, newRateFactors);
        updateWizardData(submissionVM);
    },[authHeader, jobID, selectedState, sessionUUID, setLoadingMask, stateModifierIndex, submissionVM, updateWizardData])

    const lobName = 'workersComp';

    const stateCoveragesIndex = _.get(selectedVersion, 'coverages.stateSpecificCoverages', [])
        .findIndex((stateSpecificCoverages) => stateSpecificCoverages.state === selectedState);

    const commonCoverageComponentProps = {
        selectedVersion,
        selectedVersionIndex,
        submissionVM,
        updateWizardData: updateWizardDataToDraft,
        onValidate,
        showErrors,
        updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService: WCCoveragesService,
        loadingClauses,
        setLoadingClauses,
        stateCoveragesIndex
    }

    const rateFactors = _.get(submissionVM, `value.lobData.workersComp.modifiers_Ext[${stateModifierIndex}].rateFactors`);
    const overallMinimum = _.get(submissionVM, `value.lobData.workersComp.modifiers_Ext[${stateModifierIndex}].overallMinimum`);
    const overallMaximum = _.get(submissionVM, `value.lobData.workersComp.modifiers_Ext[${stateModifierIndex}].overallMaximum`);
    
    const getTotalCreditDebit = useCallback(()=>{
        const totalCreditDebit = rateFactors.reduce((total, item) =>item.creditDebit + total, 0);
        return `${parseFloat(totalCreditDebit * 100).toFixed(2)}%`;
    },[rateFactors])
    
    const getModifierLinkDisablilty = useCallback(()=>{
        let disabled = false;
        // link should be uneditable if UW approved over what agent can access
        if (isExternalUser) {
            const totalCreditDebit = rateFactors.reduce((total, item) =>item.creditDebit + total, 0);
            if (selectedState === 'AK') {
                disabled = totalCreditDebit < overallMinAK || totalCreditDebit > overallMaxAK;
            } else {
                disabled = totalCreditDebit < overallMinimum || totalCreditDebit > overallMaximum;
            }
        }
        return disabled;
    },[overallMaximum, overallMinimum, rateFactors])

    const showModifierModal = useCallback(() => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            rateFactors,
            overallMinimum,
            overallMaximum,
            overallMinAK,
            overallMaxAK,
            selectedState,
            stateModifierIndex,
            actionBtnLabel: messages.splitPopupOK,
            cancelBtnLabel: messages.Exit,
            translator,
            isReadOnly
        };
        return modalApi.showModal(
            <ModifiersPopup {...componentProps} />
        );
    }, [messages.Exit, messages.splitPopupOK, modalApi, overallMaximum, overallMinimum, rateFactors, stateModifierIndex, translator]);

    const onClickFactor = useCallback(() => {
        showModifierModal().then(
            (data) => {
                if (!_.isEqual(data, modifiers[stateModifierIndex].rateFactors)) {
                    saveModifier(data)
                 } 
            }
        ).catch(_.noop);
    },[modifiers, saveModifier, showModifierModal, stateModifierIndex])

    const renderFactorBtn = () => {
        return <Flex alignItems="middle" justifyContent="left" gap='large'>{getTotalCreditDebit()}<ButtonLink type="outlined" disabled={getModifierLinkDisablilty()} onClick={onClickFactor} className={styles.modifyBtn}>Modify</ButtonLink></Flex>
    }
    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            isEditable: !isReadOnly
        },
        splitPeriodToggle: {

        },
        splitDate: {
            visible: true
        },
        scheduleRateFactor: {
            label: messages.scheduleRatingFactor,
            value: renderFactorBtn()
        },
        splitType: {
            visible: true
        },
        stateSpecificDetailAndCoverages: {
            visible: !isPolicyChange
        },
        stateAdditionalCoverageAccordion: {
            visible: !isPolicyChange
        },
        wcStateDetails: {
            ...commonCoverageComponentProps
        },
        wcStateRatingPeriodsCoverages: {
            ...commonCoverageComponentProps
        },
        stateStandardPart1Section: {
            ...commonCoverageComponentProps
        },
        stateStandardPart2Section: {
            ...commonCoverageComponentProps
        },
        stateAdditionalSection: {
            ...commonCoverageComponentProps
        },
        coveredEmployeesSection: {
            submissionVM,
            stateSpecificVM,
            syncWizardData,
            updateWizardData,
            updateWizardSnapshot,
            updateSubmissionVMForResponse,
            resetWizardDataToSnapshot,
            generateValidationIssues,
            messages,
            isReadOnly,
            isPolicyChange,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            onValidate,
            showErrors,
            updateShowErrors
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onClickFactor: onClickFactor
        },
        resolveComponentMap: {
            WCStateDetails: WCStateDetails,
            WCStateRatingPeriodsCoverages: WCStateRatingPeriodsCoverages,
            WCStateStandardPartOneCoverages: WCStateStandardPartOneCoverages,
            WCStateStandardPartTwoCoverages: WCStateStandardPartTwoCoverages,
            WCStateAdditionalCoverages: WCStateAdditionalCoverages,
            coverdemployees: CoveredEmployees,
        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent,
            stateSpecificVM,
            fieldId,
            fieldPath,
            overrideProps
        );
    };
    //---------
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={stateSpecificVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={onValueChange}
            showErrors={showErrors}
        />
    );
}

StateSpecificComponent.propTypes = {

};
StateSpecificComponent.defaultProps = {

};

export default StateSpecificComponent;
