import React, {
    useContext, useState, useEffect
} from 'react';
import _ from 'lodash';
import {
    Loader
} from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { useTranslator } from '@jutro/locale';
import { WniDateUtil } from 'wni-portals-util-js';
import generateSubComponents from './RTScheduleItemTypes/RTScheduleItemTypes';
import RTCoverageUtil from '../../util/RTCoverageUtil';

function RTScheduleItemDetails(props) {
    
    const {
        openedRowItemNumber,
        scheduleItem,
        selectedScheduleItem,
        labelPosition,
        syncScheduleChange,
        showScheduleDetailsInPanelBelow,
        path,
        visibleFieldIdsOverride,
        isEditable = true,
        showErrors = false,
        optionFilter,
    } = props;
    
    const localeService = ServiceManager.getService('locale-service');
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const [isLoading, setIsLoading] = useState(false)
    const [openedScheduleItemData, setOpenedScheduleItemData] = useState({});
    const scheduleItemIndex = _.get(scheduleItem, 'scheduleItems', [])
        .findIndex((item) => _.get(item, 'itemNumber') === openedRowItemNumber)
    // const selectedScheduleItem = _.get(scheduleItem, `scheduleItems[${scheduleItemIndex}]`, {})
    useEffect(() => {
        // const selectedScheduleItem = _.get(scheduleItem, `scheduleItems[${scheduleItemIndex}]`, {})
        setOpenedScheduleItemData(selectedScheduleItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedScheduleItem])
    
    
    const orderedPropertyInfo = _.sortBy(scheduleItem.propertyInfos, 'order');
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const modalOverrides = {
        '@field': {
            labelPosition,
        }
    };
    const handleChange = async (value, scheduleValuePath) => {
        let clonedData = _.cloneDeep(openedScheduleItemData);
        const newPath = ViewModelUtil.getNonVMPath(scheduleValuePath);
        // This is the last part of path, such as dateValue stringValue
        const relativePath = RTCoverageUtil.getScheduleItemDataRelativePath(newPath);
        const itemDataBasePath = RTCoverageUtil.getScheduleItemDataBasePath(newPath);
        _.set(clonedData, `${itemDataBasePath}.updated_Ext`, true)
        if (_.get(value, 'code')) {
            _.set(clonedData, newPath, _.get(value, 'code'));
        } else if (relativePath === 'dateValue' && _.isObject(value)) {
            _.set(clonedData, newPath, WniDateUtil.formatDateWithPattern(value, 'YYYY-MM-DD'));
        } else{
            _.set(clonedData, newPath, value);
        }
        const isFieldChangedSyncOnBlur = [
            'stringValue',
            'bigDecimal'
        ].some((fieldName) => newPath.endsWith(fieldName));
        if (syncScheduleChange && !isFieldChangedSyncOnBlur) {
            setIsLoading(true)
            clonedData = await syncScheduleChange(clonedData, scheduleItemIndex);
            setIsLoading(false)
        }
        if (!isFieldChangedSyncOnBlur && showScheduleDetailsInPanelBelow) {
            const clonedSchedule = _.cloneDeep(scheduleItem);
            const localPathToSchedule = `scheduleItems[${scheduleItemIndex}]`;
            _.set(clonedSchedule, localPathToSchedule, clonedData);
            // setScheduleItem(clonedSchedule)
        }
        setOpenedScheduleItemData(clonedData);
    }

    const handleBlur = async () => {
        // Do nothing when no value in openedScheduleItemData updated
        const itemData = _.get(openedScheduleItemData, 'itemData', {})
        const isAnyItemUpdatedNotSync = Object.keys(itemData).some((key) => {
            const item = _.get(itemData, key);
            return _.get(item, 'updated_Ext');
        })
        if (!isAnyItemUpdatedNotSync) {
            return;
        }
        let clonedData = _.cloneDeep(openedScheduleItemData);
        if (syncScheduleChange) {
            setIsLoading(true)
            clonedData = await syncScheduleChange(clonedData, scheduleItemIndex);
            setIsLoading(false)
        }
        if (showScheduleDetailsInPanelBelow) {
            const clonedSchedule = _.cloneDeep(scheduleItem);
            const localPathToSchedule = `scheduleItems[${scheduleItemIndex}]`;
            _.set(clonedSchedule, localPathToSchedule, clonedData);
            // setScheduleItem(clonedSchedule)
            

        }
        setOpenedScheduleItemData(clonedData);
    };

    if (!openedRowItemNumber || !showScheduleDetailsInPanelBelow) {
        return null
    }
    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }
    const propertyInfosOfDetailsShoudDisplay = orderedPropertyInfo
        .filter((info) => {
            if (info.id === 'ItemNO') {
                return false
            }
            if (visibleFieldIdsOverride && !visibleFieldIdsOverride.includes(info.id)) {
                return false
            }
            const isVisible = _.get(openedScheduleItemData, `itemData.${info.id}.visible_Ext`);
            if (!isVisible) {
                return false
            }
            return true
        })
    return (
        <div className="mt-10">
            {
                propertyInfosOfDetailsShoudDisplay
                    .flatMap((info) => {
                        return generateSubComponents({
                            info,
                            modalData: openedScheduleItemData,
                            viewModelService,
                            translator,
                            defaultCountryCode,
                            scheduleItem,
                            onValueChange: handleChange,
                            onBlur: handleBlur,
                            parentOverrides: modalOverrides,
                            isNew: false,
                            isEditable,
                            showErrors,
                            optionFilter,
                        })
                    })
            }
        </div>
    )
}

export default RTScheduleItemDetails;
