import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    ViewModelForm,
} from '@xengage/gw-portals-viewmodel-react';
import {
    useHistory
} from 'react-router-dom';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { SubmissionService } from 'gw-capability-gateway';
import { ServiceManager } from '@jutro/services';
import { FieldLinkComponent } from 'gw-components-platform-react';
import { WizardConstants } from 'wni-portals-config-js';
import { WniSubmissionService, WniAccountQuoteService } from 'wni-capability-gateway';
import {
    QuoteAdditionalProductComponent
} from 'wni-capability-gateway-react';
import { useWniModal } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
import {  useProductsData } from 'wni-portals-util-react';
import { WizardPageJumpUtil, WniProductsUtil } from 'wni-portals-util-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import AQWizardPageTempalte from '../../template/AQWizardPageTemplate';
import IssueApprovedQuotesPopup from './components/IssueApprovedQuotesPopup/IssueApprovedQuotesPopup';
import AQASPrendingTransactionsPanel from './components/AQASPrendingTransactionsPanel/AQASPrendingTransactionsPanel';
import AQASActivePoliciesPanel from './components/AQASActivePoliciesPanel/AQASActivePolicesPanel';
import accountSummaryConstants from './AccountSummaryConstants';
import AQWizardPage from '../../template/AQWizardPage';
import styles from './AQAccountSummaryPage.module.scss';
import metadata from './AQAccountSummaryPage.metadata.json5';

function AQAccountSummaryPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: accountQuoteDataVM,
        // updateWizardData: updateSubmissionVM,
        wizardPageData: {
            [WizardConstants.externalAgencyValue]: selectedAgency,
            [WizardConstants.prendingTransactions]: prendingTransactions,
            [WizardConstants.lastExitWizardPage]: submissionWizardExitPage
        },
        currentStepIndex,
        updateWizardPageData,
        goNext,
    } = props;
    const { getAvailableEffectiveDate, getAvailableProducts } = useProductsData();

    const history = useHistory();
    const translator = useTranslator();
    // Another Design should be raised for this issue
    // e.g. Come up with a MenuContainer instead of using existing Wizard
    // useEffect(() => {
    //     updateFurthestPageVisited('AQAccountSummaryPage');
    // }, [])

    const {
        accountNumber,
        accountHolder: {
            contactType_Ext: accountType,
            displayName: acccountHolderDisplayName,
            primaryAddress: {
                state
            },
        },
        policySummaries,
        availableProducerCodesForCurrentUser,
        systemDate,
        producerCodes,// get selected agency
        accountHolder: {
            productAvailable_Ext: productAvailableForAccount,
            primaryAddress: {
                postalCode,
            }
        },

    } = accountQuoteDataVM.value;

    const activePolicies = policySummaries.filter((policy) => {
        const displayStatus = _.get(policy, 'displayStatus');
        return displayStatus === 'In Force' || displayStatus === 'Scheduled';
    }).map((item) => {
        const { product: { productCode }} = item;
        _.set(item, 'product.productName', WniProductsUtil.getProductName(productCode));
        return item;
    });

    const {
        authHeader,
    } = useAuthentication();
    const { lobQuoteURL } = appConfig;
    const {
        interactionModel,
        loadingMask: { setLoadingMask },
        domainCompany
    } = useDependencies(['interactionModel', 'loadingMask', 'domainCompany']);
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();


    const [selectedJobs, setSelectedJobs] = useState([]);
    const [selectedAgencyCode, updateSelectedAgencyCode] = useState(null);
    const [selectedAgencyPublicID, updateSelectedAgencyPublicID] = useState(null);
    useEffect(() => {
        if (!_.isNil(producerCodes)) {
            // external user NB
            updateSelectedAgencyCode(producerCodes[0].code);
            updateSelectedAgencyPublicID(producerCodes[0].publicID);
        } else if (!_.isEmpty(availableProducerCodesForCurrentUser)){
            // internal user person type account create NB
            updateSelectedAgencyCode(availableProducerCodesForCurrentUser[0].code);
            updateSelectedAgencyPublicID(availableProducerCodesForCurrentUser[0].publicID);
        }
    }, [producerCodes, availableProducerCodesForCurrentUser]);
    const [
        selectedVersionMap,
        updateSelectedVersionMap
    ] = useState({});
    const [
        prendingTransactionsSelectedProductCode,
        updatePrendingTransactionsSelectedProductCode
    ] = useState(
        accountSummaryConstants.ProductFilterOptionAllCode
    );
    const [
        PTFilterSelectedTransactionType,
        updatePTFilterSelectedTransactionType
    ] = useState(
        accountSummaryConstants.TransactionFilterOptionAllCode
    );
    const productCodeToCopyWayMap = { Watercraft : 'PAToWAL', RoadTrail : 'PAToRT', PersonalUmbrella : 'PAToPU'};
    const refreshPrendingTransactions = useCallback(async () => {
        setLoadingMask(true);
        
        let res = await WniAccountQuoteService.getAQPrendingTransactionsForAccount(
            accountNumber,
            authHeader
        );
        if (!_.isArray(res)) {
            res = [];
        }

        setLoadingMask(false);
        
        const prendingTransactionsData = res.map((item) => {
            const { product: { productCode }} = item;
            _.set(item, 'product.productName', WniProductsUtil.getProductName(productCode, translator));
            return item;
        });
        updateWizardPageData({
            [WizardConstants.prendingTransactions]: prendingTransactionsData
        });
    }, [
        accountNumber,
        authHeader,
        updateWizardPageData
    ]);

    const initOpensJobs = async () => {
        if (_.isNil(prendingTransactions)) {
            await refreshPrendingTransactions();
        }
    };

    useEffect(() => {
        initOpensJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPolicyAccountNumberLink = useCallback(() => {
        const url = interactionModel.getURLObj(null, 'accountSummary', accountNumber);
        return (
            <FieldLinkComponent
                id="accountNumberLink"
                className={styles.removeLinkStyle}
                value={acccountHolderDisplayName.toUpperCase()}
                {...url}
            />
        );
    }, [acccountHolderDisplayName, accountNumber, interactionModel]);

    const startSubmission = useCallback(
        async (productCode, countryCode) => {
            setLoadingMask(true);
            let jobNumber = null;
            if (Object.keys(productCodeToCopyWayMap).includes(productCode)) {
                jobNumber = await WniSubmissionService.getLastPAPolicyOrSubmissionJobNumber(accountNumber, authHeader);
            }
            let SubmissionResponse = null;
            if (jobNumber != null && !_.isEmpty(jobNumber)) {
                const way = productCodeToCopyWayMap[productCode];
                SubmissionResponse = await WniSubmissionService.copySubmissionByWay(
                    jobNumber,
                    way,
                    authHeader
                );
            } else {
                const effectiveDate = getAvailableEffectiveDate(productCode, state) || systemDate;
                SubmissionResponse = await SubmissionService
                    .createSubmission({
                        accountNumber: accountNumber,
                        country: countryCode,
                        effectiveDate: effectiveDate,
                        producerCode: selectedAgencyCode,
                        producerCodePublicID_Ext: selectedAgencyPublicID,
                        productCode: productCode,
                        state: state,
                    }, authHeader);
            }
            setLoadingMask(false);
            const newSubmissionResponse = SubmissionResponse;
            const jobID = newSubmissionResponse.jobNumber;
            if (!_.isNil(jobID)) {
                if (!_.isNil(lobQuoteURL[productCode])) {
                    await WniSubmissionService.addRecentlyViewedSubmission(newSubmissionResponse.jobNumber, authHeader);
                    WizardPageJumpUtil.goToNewQuoteWizard(history, {
                        productCode,
                        postalCode,
                        jobID
                    });
                }
                await refreshPrendingTransactions();
            }
        },
        [
            accountNumber,
            systemDate,
            selectedAgency,
            state,
            authHeader,
            lobQuoteURL,
            refreshPrendingTransactions,
            selectedAgencyCode,
            selectedAgencyPublicID
        ]
    );

    const handleNewSubmission = (product) => {
        const { jumpToAO, code: productCode } = product;
        if (jumpToAO){
            return interactionModel.goToPage(null, history, 'newQuote', accountNumber);
        }
        startSubmission(productCode, defaultCountryCode);
    };
    const onIssueApprovedQuotesClick = async () => {
        const isPUSuggested = await WniAccountQuoteService.isPUSuggested(accountNumber, authHeader);
        const approvedQuotes = prendingTransactions.filter((job) => selectedJobs
            .some((selectedJobNumber) => selectedJobNumber === job.jobNumber));

        const handleNextBtnClick = () => {
            updateWizardPageData({
                [WizardConstants.issuedApprovedQuotes]: selectedJobs
            });
            // goNext();
            let ids = '';
            _.each(selectedJobs, (quoteID) => {
                ids += `${quoteID}_`;
            })
            ids = ids.substring(0, ids.length - 1);
            history.push('/aqpayment', {
                jobNumber: `${ids}`,
                payStatus: 'init'
            });
        };
        const componentProps = {
            size: 'lg',
            activePolicies: activePolicies,
            approvedQuotes: approvedQuotes,
            onNextBtnClick: handleNextBtnClick,
            isPUSuggested: isPUSuggested,
            handleNewSubmission,
            productsMap: getAvailableProducts(accountType, state),
            domainCompany,
            accountType,
        };
        modalApi.showModal(<IssueApprovedQuotesPopup {...componentProps} />);
    };

    const getSelectedVersionPublicID = (job) => {
        const jobNumber = _.get(job, 'jobNumber');
        const selectedVerionsPublicIDInSelectedVerionMap = _.get(selectedVersionMap, jobNumber);
        const defaultSelectedVersionPublicID = _.get(job, 'selectedVersion');
        return selectedVerionsPublicIDInSelectedVerionMap
            || defaultSelectedVersionPublicID;
    };

    const getSelectedVersion = (job) => {
        const versions = _.get(job, 'versions', []);
        const selectedVersionPublicID = getSelectedVersionPublicID(job);
        const selectedVersion = versions
            .find((version) => version.publicID === selectedVersionPublicID);
        return selectedVersion;
    };

    const getSelectedVersionStatus = (job) => {
        const selectedVersion = getSelectedVersion(job);
        const status = _.get(selectedVersion, 'status');
        return status;
    };

    const isIssueApprovedQuotesButtonAvailable = !_.isArray(prendingTransactions)
        ? false : prendingTransactions.some(
            (job) => selectedJobs
                .some((jobNumber) => jobNumber === job.jobNumber) && getSelectedVersionStatus(job) === 'Approved'
        ) && !prendingTransactions.some(
            (job) => selectedJobs
                .some((jobNumber) => jobNumber === job.jobNumber) && getSelectedVersionStatus(job) !== 'Approved'
        );

    const handlePTProductFilterChange = (newProductCode) => {
        if (newProductCode !== accountSummaryConstants.ProductFilterOptionAllCode) {
            const filteredSelectedJobs = selectedJobs.filter((jobNumber) => {
                return prendingTransactions.some((job) => job.jobNumber === jobNumber
                    && _.get(job, 'product.productCode') === newProductCode);
            });
            setSelectedJobs(filteredSelectedJobs);
        }
        updatePrendingTransactionsSelectedProductCode(newProductCode);
    };

    const handlePTTransactionFilterChange = (newTransactionType) => {
        if (newTransactionType !== accountSummaryConstants.TransactionFilterOptionAllCode) {
            const filteredSelectedJobs = selectedJobs.filter((jobNumber) => {
                return prendingTransactions.some((job) => job.jobNumber === jobNumber
                    && _.get(job, 'type') === newTransactionType);
            });
            setSelectedJobs(filteredSelectedJobs);
        }
        updatePTFilterSelectedTransactionType(newTransactionType);
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
        },
        pageTitle: {
            content: `Account Summary (${accountNumber})`
        },
        accountNumberTitle: {
            content: getPolicyAccountNumberLink()
        },
        issueApprovedQuotesButton: {
            disabled: !isIssueApprovedQuotesButtonAvailable
        },
        quoteNewLineContainer: {
            // content: generateAvailableProducts()
            productAvailableForAccount,
            startSubmissionFn: startSubmission,
            accountNumber,
            accountType,
            baseState: state
        },
        AQASPrendingTransactionsPanel: {
            refreshPrendingTransactions,
            selectedJobs,
            setSelectedJobs,
            accountNumber,
            prendingTransactions,
            selectedVersionMap,
            updateSelectedVersionMap,
            getSelectedVersionPublicID,
            getSelectedVersion,
            prendingTransactionsSelectedProductCode,
            PTFilterSelectedTransactionType,
            handleProductFilterChange: handlePTProductFilterChange,
            handleTransactionTypeChange: handlePTTransactionFilterChange,
        },
        AQASActivePoliciesPanel: {
            accountNumber,
            activePolicies,
            availableProducerCodesForCurrentUser,
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onIssueApprovedQuotesClick: onIssueApprovedQuotesClick,
        },
        resolveComponentMap: {
            AQASPrendingTransactionsPanel: AQASPrendingTransactionsPanel,
            AQASActivePoliciesPanel: AQASActivePoliciesPanel,
            quoteadditionalproductcomponent : QuoteAdditionalProductComponent,
        }
    };

    return (
        <AQWizardPage
            showNext={false}
            showPrevious={false}
            showCancel={false}
            template={AQWizardPageTempalte}
            skipWhen={() => submissionWizardExitPage > currentStepIndex}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </AQWizardPage>
    );
}

AQAccountSummaryPage.propTypes = AQWizardPage.propTypes;
export default AQAccountSummaryPage;
