import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { SearchAndAddCoveragesPopup } from 'wni-capability-common-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import CoveragesConfigContext from './context/GLCoveragesConfigContext'
import GLCoveragesConfig from './GLCoverages.config';
import WizardPage from '../../templates/GLWizardPage';
import metadata from './GLGeneralLiabilityPage.metadata.json5';
import GLMultiSublinesClausesAdditionalInsured from './GLMultiSublinesClausesAdditionalInsured/GLMultiSublinesClausesAdditionalInsured';
import GLMultiSublinesClausesExclusionsAndConditions from './GLMultiSublinesClausesExclusionsAndConditions/GLMultiSublinesClausesExclusionsAndConditions'
import GLMultiSublinesClausesAdditionalCoverages from './GLMultiSublinesClausesAdditionalCoverages/GLMultiSublinesClausesAdditionalCoverages';
import GLMultiSublinesClausesDetailsAndStandardCoverages from './GLMultiSublinesClausesDetailsAndStandardCoverages/GLMultiSublinesClausesDetailsAndStandardCoverages';



function GLGeneralLiabilityPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        wizardPageData,
        updateWizardData,
        toggleWizardPageDisplay,
        //
        markFollowingWizardStepsUnvisited,
        //
        readonlyForAdditionalCov,
        editableFieldsForPolicyChange,
        hideDetailsAndStandardCoverages,
        readOnlyForExclCondUnlessSpecificFields
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            periodStatus,
        }
    } = submissionVM.value

    const uwIssues = _.get(wizardPageData, 'WPDK_sideBySideData.errorsAndWarnings.underwritingIssues', []);
    const peReferredUWIssue = _.find(uwIssues, (issue) => issue.issueTypeCode_Ext === 'CLIsReferToUWAddedbyPEAgent_Ext');
    
    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');
    const { initialValidation, onValidate, isComponentValid } = useValidation('GLGeneralLiabilityPage');
    const { authHeader } = useAuthentication();
    const [validationIssues, updateValidationIssues] = useState([])
    const [showErrors, setShowErrors] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [allSublinesClauses, setAllSubLinesClauses] = useState()
    const [loadingClauses, setLoadingClauses] = useState(false);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initAllSublinesClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await GLCoverageService.getLineClausesWithSync(jobID, sessionUUID, authHeader) :
            await GLCoverageService.getSublineCoverages(jobID, sessionUUID, authHeader)
        const {
            sublineClauses,
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setAllSubLinesClauses(sublineClauses)

        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    useEffect(() => {
        initAllSublinesClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setAllSubLinesClausesAndClearPages = useCallback((clausesParam) => {
        setAllSubLinesClauses(clausesParam);
        markFollowingWizardStepsUnvisited();
    }, [markFollowingWizardStepsUnvisited]);

    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            isSubline: true,
            editableFieldsForPolicyChange
        };
        return modalApi.showModal(
            <SearchAndAddCoveragesPopup {...componentProps} />
        );
    }, [authHeader, editableFieldsForPolicyChange, jobID, modalApi, sessionUUID, setLoadingMask]);

    const setUnmannedAircraftAvailable = useCallback((unmannedAircraftAvailable) => {
        toggleWizardPageDisplay([
            {[WizardConstants.stepId]: 'GLUnmannedAircraftPage', [WizardConstants.stepVisible]: unmannedAircraftAvailable},
        ]);
        markFollowingWizardStepsUnvisited();
    }, [markFollowingWizardStepsUnvisited, toggleWizardPageDisplay])

    const onClickAddSearchCoverage = useCallback(async (covType) => {
        const data = await showCoveragesModal(covType);
        setAllSubLinesClausesAndClearPages(data.sublineClauses);
        setUnmannedAircraftAvailable(data.isUnmannedAircraftAvailable);
    },[setAllSubLinesClausesAndClearPages, setUnmannedAircraftAvailable, showCoveragesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData);
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await GLCoverageService.onGeneralLiabilityPageNext(jobID, sessionUUID, authHeader)
        const newSubmissionVM = setWizardDataToDraft(submissionVM);
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return newSubmissionVM
    };

    const handleError = () => {
        setShowErrors(true)
        return false
    };

    const mutiSublineClausesProps = {
        allSublinesClauses: allSublinesClauses,
        submissionVM: submissionVM,
        updateWizardData: updateWizardDataToDraft,
        setAllSubLinesClauses: setAllSubLinesClausesAndClearPages,
        isEditing,
        setIsEditing,
        onValidate,
        showErrors: showErrors,
        setUnmannedAircraftAvailable,
        onClickAddSearchCoverage,
        loadingClauses,
        setLoadingClauses,
        peReferredUWIssue
    }

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        GLMultiSublinesClausesDetailsAndStandardCoverages: {
            ...mutiSublineClausesProps,
            visible: !hideDetailsAndStandardCoverages,
        },
        GLMultiSublinesClausesAdditionalCoverages: {
            ...mutiSublineClausesProps,
            isEditable: !readonlyForAdditionalCov
        },
        GLMultiSublinesClausesExclusionsAndConditions: {
            ...mutiSublineClausesProps,
            isEditable: !readOnlyForExclCondUnlessSpecificFields,
            editableFieldsForPolicyChange
        },
        GLMultiSublinesClausesAdditionalInsured: {
            ...mutiSublineClausesProps,
        },
    };

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            GLMultiSublinesClausesDetailsAndStandardCoverages: GLMultiSublinesClausesDetailsAndStandardCoverages,
            GLMultiSublinesClausesAdditionalCoverages: GLMultiSublinesClausesAdditionalCoverages,
            GLMultiSublinesClausesExclusionsAndConditions: GLMultiSublinesClausesExclusionsAndConditions,
            GLMultiSublinesClausesAdditionalInsured: GLMultiSublinesClausesAdditionalInsured,
        },
    };

    const disableNavigate = isEditing || loadingClauses || isLoadingMask

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigate}
            disablePrevious={disableNavigate}
            disableCancel={disableNavigate}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {allSublinesClauses && 
                <CoveragesConfigContext.Provider value={GLCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
                
            }
        </WizardPage>
    );
}

GLGeneralLiabilityPage.propTypes = {
    ...wizardProps,
    readonlyForAdditionalCov: PropTypes.bool,
    editableFieldsForPolicyChange: PropTypes.array,
    hideDetailsAndStandardCoverages: PropTypes.bool,
    readOnlyForExclCondUnlessSpecificFields: PropTypes.bool
};
GLGeneralLiabilityPage.defaultProps = {
    readonlyForAdditionalCov: false,
    editableFieldsForPolicyChange: [],
    hideDetailsAndStandardCoverages: false,
    readOnlyForExclCondUnlessSpecificFields: false
};
export default GLGeneralLiabilityPage;