import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cpblanketsService'), method, data, additionalHeaders);
}

export default class CPBlanketsService {

    /**
     * Invokes CPBlanketsHandler.addBlanket()
     *
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {Object} blanketType
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static addBlanket(data, authHeader = {}) {
        return processSubmission(
            'addBlanket',
            [data.jobID, data.sessionUUID, data.blanketType],
            authHeader);
    }

    /**
     * Invokes CPBlanketsHandler.removeBlankets()
     *
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {Object} publicIDs
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static removeBlankets(data, authHeader = {}) {
        return processSubmission(
            'removeBlankets',
            [data.jobID, data.sessionUUID, data.publicIDs],
            authHeader);
    }

    /**
     * Invokes CPBlanketsHandler.updateBlanket()
     *
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {Object} blanket
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateBlanket(data, authHeader = {}) {
        return processSubmission(
            'updateBlanket',
            [data.jobID, data.sessionUUID, data.blanket],
            authHeader);
    }

    /**
     * Invokes CPBlanketsHandler.getBlanketDetails()
     *
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {String} blanketID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getBlanketDetails(data, authHeader = {}) {
        return processSubmission(
            'getBlanketDetails',
            [data.jobID, data.sessionUUID, data.blanketID],
            authHeader);
    }

    /**
     * Invokes CPBlanketsHandler.onPageNext()
     *
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static onPageNext(data, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [data.jobID, data.sessionUUID],
            authHeader);
    }

    /**
     * Invokes CPBlanketsHandler.applyRisks()
     *
     * @param {string} jobID
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static applyRisks(data, authHeader = {}) {
        return processSubmission(
            'applyRisks',
            [data.jobID, data.sessionUUID, data.blanket, data.selectedRisks],
            authHeader);
    }

}