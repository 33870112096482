import React, { useCallback } from 'react';
import {
    Button,
    DropdownMenuButton,
    DropdownMenuHeader,
    DropdownMenuLink,
} from '@jutro/components';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import messages from './PUScheduleAddItemButton.messages';

const PUScheduleAddItemButton = (props) => {
    const { onScheduleChangeWithSync, path, schedule, setOpenedRowItemNumber } =
        props;

    const {
        isPUChangeScheduleExclusion,
        newAddItemLabel,
        // --- PU AUTO Exclusion props start ---
        isPUAutoExclusion,
        existingAutos,
        // --- PU AUTO Exclusion props end ---
        // --- PU Driver Exclusion props start ---
        isPUDriverExclusion,
        existUMBDrivers,
        // --- PU Driver Exclusion props end ---
        // --- PU Premise Exclusion props start ---
        isPUPremiseExclusion,
        existUMBPremiseExposures,
        // --- PU Premise Exclusion props end ---
    } = schedule;

    const translator = useTranslator();

    const generateNewScheduleItem = useCallback(
        (existingUMBExclusionInfo) => {
            const basicScheduleItem = {
                '@deserialization-class': schedule.deserializationClass,
                itemData: {},
            };
            if (!_.isEmpty(existingUMBExclusionInfo)) {
                return {
                    '@deserialization-class':
                        'wni.edge.capabilities.policyjob.lob.personalumbrella.coverages.schedule.scheduleItem.dto.WniPUChangeExclusionScheduleItemForAddExistingDTO',
                    umbexclusionInfoForAddExisting: existingUMBExclusionInfo,
                };
            }
            return basicScheduleItem;
        },
        [schedule.deserializationClass]
    );

    const addSchedule = useCallback(
        async (existingUMBExclusionInfo) => {
            const clonedSchedule = _.cloneDeep(schedule);
            const newScheduleItem = generateNewScheduleItem(
                existingUMBExclusionInfo
            );
            clonedSchedule.scheduleItems = [
                ...schedule.scheduleItems,
                newScheduleItem,
            ];
            if (onScheduleChangeWithSync) {
                // setLoading(true);
                await onScheduleChangeWithSync(clonedSchedule, path).finally(_.noop);
                setOpenedRowItemNumber(clonedSchedule.scheduleItems.length);
            }
        },
        [
            onScheduleChangeWithSync,
            path,
            schedule,
            setOpenedRowItemNumber,
            generateNewScheduleItem,
        ]
    );

    if (isPUChangeScheduleExclusion) {
        return (
            <DropdownMenuButton
                icon="gw-expand-more"
                id="dropdownMenuButton"
                menuClassName="dropDownMenuList"
                buttonText="Add"
                alignRight
            >
                <DropdownMenuLink onClick={() => addSchedule()}>
                    {newAddItemLabel}
                </DropdownMenuLink>
                {isPUAutoExclusion && !_.isEmpty(existingAutos) && (
                    <DropdownMenuHeader
                        title={translator(messages.existingAuto)}
                    >
                        {existingAutos.map((existingAuto, index) => (
                            <DropdownMenuLink
                                key={index}
                                onClick={() => addSchedule(existingAuto)}
                            >
                                {existingAuto.label}
                            </DropdownMenuLink>
                        ))}
                    </DropdownMenuHeader>
                )}
                {isPUDriverExclusion && !_.isEmpty(existUMBDrivers) && (
                    <DropdownMenuHeader
                        title={translator(messages.existingExcludedDriver)}
                    >
                        {existUMBDrivers.map((existUMBDriver, index) => (
                            <DropdownMenuLink
                                key={index}
                                onClick={() => addSchedule(existUMBDriver)}
                            >
                                {existUMBDriver.label}
                            </DropdownMenuLink>
                        ))}
                    </DropdownMenuHeader>
                )}
                {isPUPremiseExclusion && !_.isEmpty(existUMBPremiseExposures) && (
                    <DropdownMenuHeader
                        title={translator(messages.existingPremise)}
                    >
                        {existUMBPremiseExposures.map(
                            (existUMBPremiseExposure, index) => (
                                <DropdownMenuLink
                                    key={index}
                                    onClick={() => addSchedule(existUMBPremiseExposure)}
                                >
                                    {existUMBPremiseExposure.label}
                                </DropdownMenuLink>
                            )
                        )}
                    </DropdownMenuHeader>
                )}
            </DropdownMenuButton>
        );
    }

    return (
        <Button icon="gw-add" onClick={() => addSchedule()}>
            {translator(messages.scheduleAdd)}
        </Button>
    );
};

export default PUScheduleAddItemButton;
