import _ from 'lodash';

function zipcodeFormat(code) {
    if (!code) {
        return '';
    }
    return code.replace(/(\d{5})(\d{4})/, '$1-$2');
}

function zipCodeFormatForCanada(code) {
    if (!code) {
        return '';
    }
    return code.replace(/^([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][a-zA-Z])([0-9][a-zA-Z][0-9])$/i, '$1 $2');
}

function isZipCodeMatchPattern(country, value) {
    if (country === 'CA') {
        return /[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]/.test(value)
    } 
    return /^[0-9]{5}(-[0-9]{4})?$/.test(value)
}
function dateFormat(value) {
    if (!value) {
        return value;
    }
    return value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
}

function placeholderFormatMap(vm, fieldsMap) {
    const overrides = fieldsMap.map((item) => {
        const { id, path } = item;
        const required = _.get(vm, `${path}.aspects.required`);
        return {
            [id]: {
                placeholder: required ? '-- Required for Quote --' : ''
            }
        };
    });
    return overrides;
}
function placeholderFormat(vm, path, customRequired = false, requiredForIssuance = false) {
    const required = _.get(vm, `${path}.aspects.required`);
    if (customRequired) {
        if (requiredForIssuance) {
            return {
                placeholder: '-- Required for Issuance --'
            };
        }
        return {
            placeholder: '-- Required for Quote --'
        };
    }
    return {
        placeholder: required ? '-- Required for Quote --' : ''
    };
}

function directionStrFormat(value) {
    const directionsMap = ['ne', 'nw', 'sw', 'se', 'n', 's', 'w', 'e'];
    const valueStrMap = value.split(/ /);
    valueStrMap.forEach((v, i) => {
        directionsMap.forEach((d) => {
            if (v.toLowerCase() === d || v.toLowerCase() === `${d},` || v.toLowerCase() === `,${d}`) {
                valueStrMap[i] = v.toUpperCase();
            }
        });
    });
    return valueStrMap.join(' ');
}

// check the valus is changed for onBlur event
const isValueChanged = (valueObject) => {
    if (!valueObject) {
        return true;
    }
    // value not changed and not call the service
    if (_.get(valueObject, 'value') === _.get(valueObject, 'beforeValue')) {
        return false;
    }
    return true;
};

export default {
    zipcodeFormat,
    zipCodeFormatForCanada,
    isZipCodeMatchPattern,
    dateFormat,
    placeholderFormatMap,
    placeholderFormat,
    directionStrFormat,
    isValueChanged
};
