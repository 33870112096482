import { AccordionCard } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import GLClausesComponentVM from '../components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../components/GLSingleClauseComponentVM/GLSingleClauseComponentVM';
import CoverageContext from '../context/GLCoverageContext';
import GLCoverageUtil from '../util/GLCoverageUtil';

const GLSingleSublineExclusionsAndConditionsAccordionCard = (props) => {
    const {
        sublineClauses,
        setSublineClauses,
        isEditing,
        setIsEditing,
        isEditable,
        peReferredUWIssue,
        showErrors,
        onValidate: multiSublinesOnValidate,
        setUnmannedAircraftAvailable,
        loadingClauses,
        setLoadingClauses,
        editableFieldsForPolicyChange,
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { publicID, sublineName, exclusions, conditions } = sublineClauses;

    const { jobID, sessionUUID } = submissionVM.value;

    const validationID = `SingleSublineExclusionsAndConditions_${publicID}`

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (multiSublinesOnValidate) {
            multiSublinesOnValidate(isComponentValid, validationID);
        }
        return () => {
            if (multiSublinesOnValidate) {
                multiSublinesOnValidate(true, validationID);
            }
        }
    }, [isComponentValid, multiSublinesOnValidate, validationID])

    const changeClauses = useCallback(
        (value, changedPath, clauses, clausesPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(
                clauses,
                clausesPath,
                value,
                changedPath
            );
            const newSublineClauses = _.clone(sublineClauses);
            _.set(newSublineClauses, clausesPath, newClauses);
            setSublineClauses(newSublineClauses);
            setIsEditing(false);
            return newSublineClauses;
        },
        [setIsEditing, setSublineClauses, sublineClauses]
    );

    const changeExclusions = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, exclusions, 'exclusions');
        },
        [changeClauses, exclusions]
    );

    const changeConditions = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, conditions, 'conditions');
        },
        [changeClauses, conditions]
    );

    const updateVMToServer = useCallback(
        async (newSublineClauses, setLoadingClausesFunc, setEditingFunc) => {
            const subLineClausesToUpdate =
                GLCoverageUtil.generateUpdatedSingleSublineClausesDTO(
                    newSublineClauses
                );
            const updatedSublineClausesPublicIDs =
                GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedSublineClausesDTO(
                    subLineClausesToUpdate
                );
            setLoadingMask(true)
            setLoadingClausesFunc(updatedSublineClausesPublicIDs);
            const response = await GLCoverageService.updateSingleSublineClauses(
                jobID,
                sessionUUID,
                subLineClausesToUpdate,
                authHeader
            );
            const sublineClausesFromServer = _.get(response, 'gl7SingleSublineClauses')
            const isUnmannedAircraftAvailable = _.get(response , 'isUnmannedAircraftAvailable')
            setLoadingClausesFunc(false)
            setLoadingMask(false)
            setEditingFunc(false)
            setSublineClauses(sublineClausesFromServer);
            setUnmannedAircraftAvailable(isUnmannedAircraftAvailable)
        },
        [setLoadingMask, jobID, sessionUUID, authHeader, setSublineClauses, setUnmannedAircraftAvailable]
    );

    const updateNewSubLineClausesToServer = useCallback(
        async (newSublineClauses) => {
            updateVMToServer(
                newSublineClauses,
                setLoadingClauses,
                setIsEditing
            );
        },
        [updateVMToServer, setLoadingClauses, setIsEditing]
    );

    const changeExclusionsAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeExclusions(value, changedPath);

            updateNewSubLineClausesToServer(newSublineClauses);
        },
        [changeExclusions, updateNewSubLineClausesToServer]
    );

    const changeConditionsAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeConditions(value, changedPath);

            updateNewSubLineClausesToServer(newSublineClauses);
        },
        [changeConditions, updateNewSubLineClausesToServer]
    );

    const onSyncCoverages = useCallback(() => {
        updateNewSubLineClausesToServer(sublineClauses);
    }, [sublineClauses, updateNewSubLineClausesToServer]);

    return (
        <AccordionCard
            id={`${publicID}_ExclusionsAndConditions`}
            chevron
            className="coveragesAccordion"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={sublineName}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardData,
                        updateVMToServer: updateVMToServer,
                    }}
                >
                    {!_.isEmpty(exclusions) && <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeExclusionsAndSync}
                        onChangeClause={changeExclusions}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="exclusions"
                        value={exclusions}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM:
                                GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        peReferredUWIssue={peReferredUWIssue}
                        clauseType="exclusion"
                        hideCheckBoxForRequired
                    />}
                    {!_.isEmpty(conditions) && <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeConditionsAndSync}
                        onChangeClause={changeConditions}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="conditions"
                        value={conditions}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM:
                                GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        peReferredUWIssue={peReferredUWIssue}
                        clauseType="condition"
                        editableFieldsForPolicyChange={editableFieldsForPolicyChange}
                        hideCheckBoxForRequired
                    />}
                </CoverageContext.Provider>
            </div>
        </AccordionCard>
    );
};

export default GLSingleSublineExclusionsAndConditionsAccordionCard;
