import React, { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { AccordionCard } from '@jutro/components';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import messages from './ExposureAdditionalCoveragesAccordionCard.messages'
import GLCoverageUtil from '../../../GeneralLiability/util/GLCoverageUtil'
import CoverageContext from '../../../GeneralLiability/context/GLCoverageContext';
import GLClausesComponentVM from '../../../GeneralLiability/components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../../../GeneralLiability/components/GLSingleClauseComponentVM/GLSingleClauseComponentVM'
import AccordionCardTitle from '../../../GeneralLiability/components/GLClauseAccordionCardTitle/GLClauseAccordionCardTitle';

const ExposureAdditionalCoveragesAccordionCard = (props) => {
    const {
        currentExposureClauses,
        setCurrentExposureClauses,
        wizardData: submissionVM,
        updateWizardData,
        onValidate: onExposurePageValidate,
        showErrors,
        readOnly,
        extendProps,
        setIsEditing,
        isEditing,
        onClickAddSearchCoverage
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        jobID,
        sessionUUID,
    } = extendProps

    const { authHeader } = useAuthentication();

    const covType = 'additionalCoverages';
    const validationID = 'glExposureAdditionalCoverages'
    const translator = useTranslator();

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    useEffect(() => {
        if (onExposurePageValidate) {
            onExposurePageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onExposurePageValidate) {
                onExposurePageValidate(true, validationID)
            }
        }
    }, [isComponentValid, onExposurePageValidate])

    const { additionalCoverage } = currentExposureClauses;

    const [loadingClauses, setLoadingClauses] = useState(false);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(additionalCoverage, 'additionalCoverage', value, changedPath);
            const newExposureClauses = _.clone(currentExposureClauses)
            _.set(newExposureClauses, 'additionalCoverage', newClauses);
            setCurrentExposureClauses(newExposureClauses);
            setIsEditing(false);
            return newExposureClauses;
        },
        [additionalCoverage, currentExposureClauses, setCurrentExposureClauses, setIsEditing]
    );

    const updateVMToServer = useCallback(async (newExposureClauses, setLoadingClausesFunc, setEditingFunc) => {
        const exposureClausesToUpdate = GLCoverageUtil.generateUpdatedExposureClausesDTO(newExposureClauses);
        const updatedExposureClausesPublicIDs = GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedExposureClausesDTO(exposureClausesToUpdate);
        setLoadingMask(true)
        setLoadingClausesFunc(updatedExposureClausesPublicIDs)
        const response = await GLCoverageService.updateExposureClauses(
            jobID,
            sessionUUID,
            exposureClausesToUpdate,
            authHeader
        )
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setCurrentExposureClauses(response);
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setCurrentExposureClauses]);

    const updateNewExposureClausesToServer = useCallback(async (newSublineClauses) => {
        updateVMToServer(newSublineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);



    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeSubmission(value, changedPath);
            
            updateNewExposureClausesToServer(newSublineClauses);
        },
        [changeSubmission, updateNewExposureClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewExposureClausesToServer(currentExposureClauses);
        },
        [currentExposureClauses, updateNewExposureClausesToServer]
    )

    return <AccordionCard
            id="glExposureAdditionalCoveragesAccordionCard"
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={
                !readOnly ?
                <AccordionCardTitle
                    title={messages.additionalCoveragesTitle}
                    actionText={messages.addAdditionalCoveragesActionText}
                    onClick={() => {onClickAddSearchCoverage(covType)}}
                /> : translator(messages.additionalCoveragesTitle)
            }
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardData,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="additionalCoverage"
                        value={additionalCoverage}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={!readOnly}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
}

export default ExposureAdditionalCoveragesAccordionCard;