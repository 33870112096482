import _ from 'lodash';
import React, { useCallback }  from 'react';
import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWniModal } from 'wni-components-platform-react';
import { SearchAndAddCoveragesPopup } from 'wni-capability-common-react';
import messages from '../GLExposuresPage.messages';
import metadata from './ExposureComponent.metadata.json5';
import ExposureDetailsAndCoveragesAccordionCard from './ExposureDetailsAndCoveragesAccordionCard/ExposureDetailsAndCoveragesAccordionCard';
import CoveragesConfigContext from '../../GeneralLiability/context/GLCoveragesConfigContext'
import GLCoveragesConfig from '../../GeneralLiability/GLCoverages.config'
import ExposureAdditionalCoveragesAccordionCard from './ExposureAdditionalCoveragesAccordionCard/ExposureAdditionalCoveragesAccordionCard';
import ExposureExclusionAndConditionAccordionCard from './ExposureExclusionAndConditionAccordionCard/ExposureExclusionAndConditionAccordionCard';
import ExposureAdditionalInsuredAccordionCard from './ExposureAdditionalInsuredAccordionCard/ExposureAdditionalInsuredAccordionCard';


const AccordionCardTitle = (props) => {
    const { title, actionText, onClick } = props;
    const translator = useTranslator();
    return (
        <Flex justifyContent="between">
            <div>{translator(title)}</div>
            <Button icon="gw-add" onClick={onClick}>
                {translator(actionText)}
            </Button>
        </Flex>
    );
};

function ExposureComponent(props) {
    const modalApi = useWniModal();
    const {
        currentRow = {},
        currentExposureClauses,
        setCurrentExposureClauses,
        writeValue,
        syncWizardData,
        syncWizardDataSnapshot,
        wizardData: submissionVM,
        updateWizardData,
        onValidate,
        showErrors,
        readOnly,
        setIsEditing,
        isEditing,
        extendProps
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const exposureID = _.get(currentRow, 'value.publicID');
    const exposureClassDescription = _.get(currentRow, 'value.classDescription');
    const showCoveragesModal = useCallback((covType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            covType,
            exposureID,
            exposureClassDescription
        };
        return modalApi.showModal(
            <SearchAndAddCoveragesPopup {...componentProps} />
        );
    }, [authHeader, exposureID, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchCoverage = useCallback((covType) => {
        showCoveragesModal(covType).then(
            (data) => {setCurrentExposureClauses(data)}
        ).catch(_.noop);
    },[setCurrentExposureClauses, showCoveragesModal])
    

    const newComponentProps = _.omit(props, 'id');

    const commonClausesAccordionCardProps = {
        currentExposureClauses,
        setCurrentExposureClauses,
        onClickAddSearchCoverage,
        ...newComponentProps
    }

    const overrideProps = {
        // '@field': {
        //     ...newComponentProps,
        // },
        exposureDetailsAndCoveragesAccordionCard: {
            ...commonClausesAccordionCardProps
        },
        exposureAdditionalCoveragesAccordionCard: {
            ...commonClausesAccordionCardProps,
        },
        exposureExclusionAndConditionAccordionCard: {
            ...commonClausesAccordionCardProps,
        },
        exposureAdditionalInsuredAccordionCard: {
            ...commonClausesAccordionCardProps,
        }
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
            ExposureDetailsAndCoveragesAccordionCard: ExposureDetailsAndCoveragesAccordionCard,
            ExposureAdditionalCoveragesAccordionCard: ExposureAdditionalCoveragesAccordionCard,
            ExposureExclusionAndConditionAccordionCard: ExposureExclusionAndConditionAccordionCard,
            ExposureAdditionalInsuredAccordionCard: ExposureAdditionalInsuredAccordionCard,
        },
    };
    return (
        <CoveragesConfigContext.Provider value={GLCoveragesConfig}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={currentRow}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
                {...resolvers}
            />
        </CoveragesConfigContext.Provider>
        
    );
}

export default ExposureComponent;
