import { setComponentMapOverrides } from '@jutro/uiconfig';
import { PaymentDetailsPage } from 'gw-capability-quoteandbind-common-react';
import 'gw-capability-quoteandbind-ho-react';
import GWQuoteHOPolicyDetailsPage from './pages/PolicyDetails/PolicyDetailsPage';

setComponentMapOverrides(
    {
        GWQuoteHOPolicyDetailsPage: { component: 'GWQuoteHOPolicyDetailsPage' },
        GWQuoteHOPaymentDetailsPage: { component: 'GWQuoteHOPaymentDetailsPage' },
    },
    {
        GWQuoteHOPolicyDetailsPage,
        GWQuoteHOPaymentDetailsPage: PaymentDetailsPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEHOWizard } from './PEHOWizard';
