
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Link } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import metadata from './AccountEligibity.metadata.json5';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
const AccountEligibity = (props) => {
    const {
        model,
        basePath,
        onValueChange,
        onValidate,
        showErrors,
        disabled
    } = props;
    const VM = model[basePath];
    
    const translator = useTranslator();


   
    const writeValue = (value, path) => {
        onValueChange(value, `${basePath}.${path}`);
    };
    //---------
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            disabled: disabled,
            required: true
        },
       
    };
    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        }
    }
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={VM}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

export default AccountEligibity;
