import { setComponentMapOverrides } from '@jutro/uiconfig';
import { PaymentDetailsPage } from 'gw-capability-quoteandbind-common-react';
import 'gw-capability-quoteandbind-bop-react';
import GWQuoteBOPPolicyDetailsPage from './pages/PolicyDetails/PolicyDetailsPage';

setComponentMapOverrides(
    {
        GWQuoteBOPPolicyDetailsPage: { component: 'GWQuoteBOPPolicyDetailsPage' },
        GWQuoteBOPPaymentDetailsPage: { component: 'GWQuoteBOPPaymentDetailsPage' },
    },
    {
        GWQuoteBOPPolicyDetailsPage,
        GWQuoteBOPPaymentDetailsPage: PaymentDetailsPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEBOPWizard } from './PEBOPWizard';
