import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';

import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { CACoveredVehicleSymbolsService } from 'wni-capability-quoteandbind-ca';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniCheckboxField } from 'wni-common-base-components';

import WizardPage from '../../templates/CAWizardPage';

import metadata from './CACoveredVehicleSymbolsPage.metadata.json5';

function CACoveredVehicleSymbolsPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        caCoveredVehicleSymbolsService
    } = props;

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const coveredVehicleSymbols = _.get(submissionVM, 'value.lobData.commercialAuto.coverables.coveredVehicleSymbols') || {};

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        invalidFields,
        isComponentValid,
    } = useValidation('CACoveredVehicleSymbolsPage');

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [coverageSymbolGroup, updateCoverageSymbolGroup] = useState(coveredVehicleSymbols.coverageSymbolGroup)

    const handleValidation = useCallback(() => {
        //
    }, [invalidFields]);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const onPageNext = useCallback(async () => {
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            dtos: coverageSymbolGroup
        };
        const newSubmissionVM = viewModelService.clone(submissionVM)
        // return newSubmissionVM;
        const res = await caCoveredVehicleSymbolsService.updateCoveredVehicleSymbols(requestData, authHeader);
        _.set(newSubmissionVM, 'lobData.commercialAuto.coverables.coveredVehicleSymbols', res.coveredVehicleSymbols);

        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);

        updateWizardData(newSubmissionVM);
        updateServiceCallInProgress(false);
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, submissionVM]);

    
    const handleCoverageSymbols = (value, row, colum) => {
        const newCoverageSymbolGroup = _.map(coverageSymbolGroup, (coverageSymbol) => {
            if (coverageSymbol.publicID === row.publicID) {
                const symbolIndex = _.findIndex(coverageSymbol.coverageSymbols, (item) => item.code === colum.code)
                _.set(coverageSymbol, `coverageSymbols[${symbolIndex}].isSelected`, value);
            }
            return coverageSymbol;
        })

        updateCoverageSymbolGroup(newCoverageSymbolGroup);
        updateWizardData(submissionVM);
    }

    const renderTableContent = () => {
        return (
            <DataTable
                data={coverageSymbolGroup}
                id="coveredVehicleSymbols"
                expandable={false}
                showSearch={false}
                showPagination={false}
                defaultConfig={{
                    sorted: [
                        {
                            desc: false,
                            id: 'symbolHeader-priority'
                        }
                    ]
                }}
            >
                <DisplayColumn
                    path='priority'
                    header='Priority'
                    id='symbolHeader-priority'
                    textAlign="left"
                    onSort={DatatableUtil.sortNumber}
                    visible={false}
                />
                <DisplayColumn
                    path='displayName'
                    header='Coverage'
                    id='symbolHeader-coverage'
                    textAlign="left"
                    onSort={DatatableUtil.sortString}
                />
                {
                    _.map(coveredVehicleSymbols.coverageSymbolsColumn, (colum) => {
                        return <DisplayColumn
                            renderCell={(row) =>
                                <WniCheckboxField
                                    visible={_.find(row.coverageSymbols, (item) => item.code === colum.code).isAvailable}
                                    value={_.find(row.coverageSymbols, (item) => item.code === colum.code).isSelected}
                                    onValueChange={(value, path) => handleCoverageSymbols(value, row, colum)} />}
                            header={colum.displayName}
                            id={`symbolHeader-${colum.code}`}
                            textAlign="left"
                            onSort={DatatableUtil.sortString}
                        />
                    })
                }
            </DataTable>
        )
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            symbolsTableContainer: {
                content: renderTableContent()
            },
            vehicleGroupReferenceTable: {
                data: coveredVehicleSymbols.vehicleGroupReference
            }
        };
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={handleCoverageSymbols}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}
CACoveredVehicleSymbolsPage.propTypes = {
    ...wizardProps,
    caCoveredVehicleSymbolsService: PropTypes.object
};

CACoveredVehicleSymbolsPage.defaultProps = {
    caCoveredVehicleSymbolsService: CACoveredVehicleSymbolsService
};

export default CACoveredVehicleSymbolsPage;