import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { IMUnderwritingQuestionsService } from 'wni-capability-quoteandbind-im';
import {
    QuoteUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/IMWizardPage';

import metadata from './IMUnderwritingQuestionsPage.metadata.json5';

function IMUnderwritingQuestionsPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        imUnderwritingQuestionsService
    } = props;

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid
    } = useValidation('IMUnderwritingQuestionsPage');

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    // const getQuestionMapper = useCallback(() => {
    //     return (metaDataContent) => {
    //         if (metaDataContent.id === 'GL7ForeignProducts') {
    //             _.set(metaDataContent, 'component', 'WniToggle')
    //             _.set(metaDataContent,'componentProps.tooltip', true);
    //             _.set(metaDataContent, 'componentProps.tooltip.showOnInit', false)
    //             _.set(metaDataContent, 'componentProps.tooltip.text', 'ACORD 815 - International Liability Exposure Supplement')
    //         }
    //         return metaDataContent;
    //     }
    // }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            questionSetsContainer: {
                // contentMapper: getQuestionMapper()
            }
        };
    }, []);

    const onPageNext = useCallback(async () => {
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, 'lobData.inlandMarine.underwritingQuestionSets.value')
        };
        const res = await imUnderwritingQuestionsService.updateUnderwritingQuestions(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isEmpty(res)) {
            _.set(newSubmissionVM, 'lobData.inlandMarine.underwritingQuestionSets.value', res.underwritingQuestionSets);
        }
        updateServiceCallInProgress(false);
        updateWizardData(newSubmissionVM);
        return newSubmissionVM;
    }, [imUnderwritingQuestionsService, submissionVM, updateWizardData]);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.cloneDeep(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

IMUnderwritingQuestionsPage.propTypes = WizardPage.propTypes;
IMUnderwritingQuestionsPage.defaultProps = WizardPage.defaultProps;

IMUnderwritingQuestionsPage.propTypes = {
    ...wizardProps,
    imUnderwritingQuestionsService: PropTypes.object
};

IMUnderwritingQuestionsPage.defaultProps = {
    imUnderwritingQuestionsService: IMUnderwritingQuestionsService
};
export default IMUnderwritingQuestionsPage;