
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';



import {
    Button,
    formatDate,
    formatNumber,
    FormattedDate,
    FormattedNumber,
} from '@jutro/components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/datatable';

import CoverageSummaryComponent from '../CoverageSummary/CoverageSummaryComponent';

/**
 * Shows CoverableSummaryClauseDTO[] in a DataTable.
 * 
 * Sample Displayables Data:
 * [
 * 	{
 * 		"coverages": []
 * 		"coverabelLabel": "Derp",
 * 	},
 * ]
 * @param {object} props
 * @returns {object} React Component
 */


function CoverableSummaryComponent(props) {
    const {
        coverable,
        //
        ...coverageProps
    } = props;
    
    if (_.isEmpty(coverable)) {
        return null;
    }

    const {
        coverages,
        exclusions,
        conditions,
        //
        coveragesLabel,
        exclusionsLabel,
        conditionsLabel,
    } = coverable;


    
    return (
        <div>
            <CoverageSummaryComponent title={coveragesLabel} clauses={coverages} {...coverageProps}/>
            <CoverageSummaryComponent title={exclusionsLabel} clauses={exclusions} {...coverageProps}/>
            <CoverageSummaryComponent title={conditionsLabel} clauses={conditions} {...coverageProps}/>
        </div>
    );

}

CoverableSummaryComponent.propTypes = {
    coverable: PropTypes.shape({}),
};

CoverableSummaryComponent.defaultProps = {
};

export default CoverableSummaryComponent;
