import { Accordion, AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useWniModal } from 'wni-components-platform-react';
import CPSearchAndAddCoveragesPopup from '../../../../components/CPSearchAndAddCoveragesPopup/CPSearchAndAddCoveragesPopup';
import AccordionCardTitle from '../../../CommercialProperty/components/CPClauseAccordionCardTitle';
import CPClausesComponentVM from '../../../CommercialProperty/components/CPClausesComponentVM';
import CPSingleClauseComponentVM from '../../../CommercialProperty/components/CPSingleClauseComponentVM';
import CoverageContext from '../../../CommercialProperty/context/CPCoverageContext';
import CoveragesConfigContext from '../../../CommercialProperty/context/CPCoveragesConfigContext';
import CPCoveragesConfig from '../../../CommercialProperty/CPCoverages.config';
import CPCoverageUtil from '../../../CommercialProperty/util/CPCoverageUtil';
import MortgageeDetailsComponent from '../MortgageeDetailsComponent/MortgageeDetailsComponent';
import messages from './BuildingClauses.messages';

const displayAdditionalInsuredTab = true;

const RiskClausesValidationID = 'BuildingClauses';

const BuildingClauses = (props) => {
    const modalApi = useWniModal();
    const {
        submissionVM,
        updateSubmissionVM,
        updateClauseFunc,
        riskClauses,
        setRiskClauses,
        showErrors,
        isEditable = true,
        onValidate: onRiskItemValidate,
        riskItem,
        serviceProps,
        mortgageeDetails,
        updateMortGageeDetails,
        createOrUpdateAdditionalInterest,
        removeAdditionalInterests,
    } = props;

    const translator = useTranslator();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const {
        onValidate: onStandardCoverageValidate,
        isComponentValid: isStandardCoverageValid,
    } = useValidation('CPRiskStandardCoverage');

    const {
        onValidate: onAdditionalCoverageValidate,
        isComponentValid: isAdditionalCoverageValid,
    } = useValidation('CPRiskAdditionalCoverage');

    const {
        onValidate: onExclusionAndConditionValidate,
        isComponentValid: isExclusionAndConditionValid,
    } = useValidation('CPRiskExclusionsAndConditions');

    const {
        onValidate: onAdditionalInsuredValidate,
        isComponentValid: isAdditionalInsuredValid,
    } = useValidation('CPRiskExclusionsAndConditions');

    const isRiskClausesValid =
        isStandardCoverageValid &&
        isAdditionalCoverageValid &&
        isExclusionAndConditionValid &&
        isAdditionalInsuredValid;

    const showCoveragesModal = useCallback(
        (covType) => {
            const componentProps = {
                showCloseBtn: false,
                showCancelBtn: false,
                serviceProps,
                setLoadingMask,
                covType,
                riskItem,
            };
            return modalApi.showModal(
                <CPSearchAndAddCoveragesPopup {...componentProps} />
            );
        },
        [serviceProps, setLoadingMask, riskItem, modalApi]
    );

    const onClickAddSearchCoverage = useCallback(
        async (covType) => {
            const data = await showCoveragesModal(covType);
            setRiskClauses(data);
        },
        [setRiskClauses, showCoveragesModal]
    );

    const [isEditing, setIsEditing] = useState(false);
    const [loadingClauses, setLoadingClauses] = useState();

    useEffect(() => {
        if (onRiskItemValidate) {
            onRiskItemValidate(isRiskClausesValid, RiskClausesValidationID);
        }
        return () => {
            if (onRiskItemValidate) {
                onRiskItemValidate(true, RiskClausesValidationID);
            }
        };
    }, [isRiskClausesValid, onRiskItemValidate]);

    if (!riskClauses) {
        return null;
    }

    const {
        standardCoverage,
        additionalCoverage,
        exclusion,
        condition,
        additionalInsured,
    } = riskClauses;

    const changeClauses = (value, changedPath, clauses, clausesPath) => {
        const newClauses = CPCoverageUtil.setClauseValue(
            clauses,
            clausesPath,
            value,
            changedPath
        );
        const newRiskClauses = _.clone(riskClauses);
        _.set(newRiskClauses, clausesPath, newClauses);
        setRiskClauses(newRiskClauses);
        setIsEditing(false);
        return newRiskClauses;
    };

    const changeStandardCoverage = (value, changedPath) => {
        return changeClauses(
            value,
            changedPath,
            standardCoverage,
            'standardCoverage'
        );
    };

    const changeAdditionalCoverage = (value, changedPath) => {
        return changeClauses(
            value,
            changedPath,
            additionalCoverage,
            'additionalCoverage'
        );
    };

    const changeExclusions = (value, changedPath) => {
        return changeClauses(value, changedPath, exclusion, 'exclusion');
    };

    const changeConditions = (value, changedPath) => {
        return changeClauses(value, changedPath, condition, 'condition');
    };

    const changeAdditionalInsured = (value, changedPath) => {
        return changeClauses(
            value,
            changedPath,
            additionalInsured,
            'additionalInsured'
        );
    };

    const updateVMToServer = async (
        newRiskClauses,
        setLoadingClausesFunc,
        setEditingFunc
    ) => {
        const riskClausesToUpdate =
            CPCoverageUtil.generateUpdatedRiskItemClausesDTO(newRiskClauses);
        const updatedStateClausesPublicIDs =
            CPCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedRiskItemClausesDTO(
                riskClausesToUpdate
            );
        setLoadingMask(true);
        setLoadingClausesFunc(updatedStateClausesPublicIDs);
        const riskClausesFromServer = await updateClauseFunc(
            riskClausesToUpdate
        );
        setLoadingClausesFunc(false);
        setLoadingMask(false);
        setEditingFunc(false);
        setRiskClauses(riskClausesFromServer);
        updateSubmissionVM(submissionVM);
    };

    const updateNewRiskClausesToServer = async (newRiskClauses) => {
        updateVMToServer(newRiskClauses, setLoadingClauses, setIsEditing);
    };

    const changeStandardCoverageAndSync = (value, changedPath) => {
        const newRiskClauses = changeStandardCoverage(value, changedPath);

        updateNewRiskClausesToServer(newRiskClauses);
    };

    const changeAdditionalCoveragesAndSync = (value, changedPath) => {
        const newRiskClauses = changeAdditionalCoverage(value, changedPath);

        updateNewRiskClausesToServer(newRiskClauses);
    };

    const changeExclusionAndSync = (value, changedPath) => {
        const newRiskClauses = changeExclusions(value, changedPath);

        updateNewRiskClausesToServer(newRiskClauses);
    };

    const changeConditionAndSync = (value, changedPath) => {
        const newRiskClauses = changeConditions(value, changedPath);

        updateNewRiskClausesToServer(newRiskClauses);
    };

    const changeAdditionalInsuredAndSync = (value, changedPath) => {
        const newRiskClauses = changeAdditionalInsured(value, changedPath);

        updateNewRiskClausesToServer(newRiskClauses);
    };

    const onSyncCoverages = () => {
        updateNewRiskClausesToServer(riskClauses);
    };

    const commonClausesProps = {
        splitByClauseCategory: false,
        sortBySelected: true,
        onSyncCoverages: onSyncCoverages,
        showAmount: false,
        loadingClause: loadingClauses,
        showErrors: showErrors,
        componentMapOverrides: {
            CPSingleClauseComponentVM: CPSingleClauseComponentVM,
        },
        setIsEditing: setIsEditing,
        isEditing: isEditing,
        isEditable: isEditable,
        hideCheckBoxForRequired: true,
    };

    return (
        <CoveragesConfigContext.Provider value={CPCoveragesConfig}>
            <CoverageContext.Provider
                value={{
                    wizardData: submissionVM,
                    updateWizardData: updateSubmissionVM,
                    updateVMToServer: updateVMToServer,
                }}
            >
                <Accordion
                    id="clauseInfoAccordion"
                    className="accordion-wizardTitle"
                    closeOthers={false}
                    showFrame={false}
                    defaultOpenedId="DetailsAndCoverage"
                >
                    <AccordionCard
                        id="DetailsAndCoverage"
                        chevron
                        className="coveragesAccordion"
                        cardBodyClassName="accordionCardBody"
                        cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                        title={translator(messages.StandardCoverages)}
                        errorState={showErrors && !isStandardCoverageValid}
                    >
                        <div className="formCoveragesContainer">
                            <CPClausesComponentVM
                                {...commonClausesProps}
                                onChangeSubmissionAndSync={
                                    changeStandardCoverageAndSync
                                }
                                onChangeClause={changeStandardCoverage}
                                onValidate={onStandardCoverageValidate}
                                path="standardCoverage"
                                value={standardCoverage}
                            />
                        </div>
                    </AccordionCard>
                    <AccordionCard
                        id="AdditionalCoverages"
                        chevron
                        className="coveragesAccordion"
                        cardBodyClassName="accordionCardBody"
                        cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                        title={
                            !isEditable ? (
                                translator(messages.AdditionalCoverages)
                            ) : (
                                <AccordionCardTitle
                                    title={translator(
                                        messages.AdditionalCoverages
                                    )}
                                    actionText={messages.AddAdditionalCoverage}
                                    onClick={() => {
                                        onClickAddSearchCoverage(
                                            'additionalCoverages'
                                        );
                                    }}
                                />
                            )
                        }
                        errorState={showErrors && !isAdditionalCoverageValid}
                    >
                        <div className="formCoveragesContainer">
                            <CPClausesComponentVM
                                {...commonClausesProps}
                                onChangeSubmissionAndSync={
                                    changeAdditionalCoveragesAndSync
                                }
                                onChangeClause={changeAdditionalCoverage}
                                onValidate={onAdditionalCoverageValidate}
                                path="additionalCoverage"
                                value={additionalCoverage}
                            />
                        </div>
                        <MortgageeDetailsComponent
                            wizardData={submissionVM}
                            mortgageeDetails={mortgageeDetails}
                            updateMortGageeDetails={updateMortGageeDetails}
                            createOrUpdateAdditionalInterest={createOrUpdateAdditionalInterest}
                            removeAdditionalInterests={removeAdditionalInterests}
                            onValidate={onAdditionalCoverageValidate}
                            showErrors={showErrors}
                            isEditable={isEditable}
                        />
                        {/* <MortgageeDetails
                            mortgageeDetails={mortgageeDetails}
                            updateMortGageeDetails={updateMortGageeDetails}
                            searchAddressBookFunc={searchAddressBookFunc}
                            addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc={addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc}
                            addNewContact={addNewContact}
                            onValidate={onAdditionalCoverageValidate}
                            showErrors={showErrors}
                            isEditable={isEditable}
                        /> */}
                    </AccordionCard>
                    <AccordionCard
                        id="exclusionAndCondition"
                        chevron
                        className="coveragesAccordion"
                        cardBodyClassName="accordionCardBody"
                        cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                        title={
                            !isEditable ? (
                                translator(messages.ExclusionsAndConditions)
                            ) : (
                                <AccordionCardTitle
                                    title={translator(
                                        messages.ExclusionsAndConditions
                                    )}
                                    actionText={
                                        messages.AddExclusionsAndConditions
                                    }
                                    onClick={() => {
                                        onClickAddSearchCoverage(
                                            'exclusionsAndConditions'
                                        );
                                    }}
                                />
                            )
                        }
                        errorState={showErrors && !isExclusionAndConditionValid}
                    >
                        <div className="formCoveragesContainer">
                            <CPClausesComponentVM
                                {...commonClausesProps}
                                onChangeSubmissionAndSync={
                                    changeExclusionAndSync
                                }
                                onChangeClause={changeExclusions}
                                onValidate={onExclusionAndConditionValidate}
                                path="exclusion"
                                value={exclusion}
                            />
                            <CPClausesComponentVM
                                {...commonClausesProps}
                                onChangeSubmissionAndSync={
                                    changeConditionAndSync
                                }
                                onChangeClause={changeConditions}
                                onValidate={onExclusionAndConditionValidate}
                                path="condition"
                                value={condition}
                            />
                        </div>
                    </AccordionCard>
                    {displayAdditionalInsuredTab && (
                        <AccordionCard
                            id="additionalInsured"
                            chevron
                            className="coveragesAccordion"
                            cardBodyClassName="accordionCardBody"
                            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
                            title={
                                !isEditable ? (
                                    translator(messages.AdditionalInsured)
                                ) : (
                                    <AccordionCardTitle
                                        title={translator(
                                            messages.AdditionalInsured
                                        )}
                                        actionText={
                                            messages.AddAdditionalInsured
                                        }
                                        onClick={() => {
                                            onClickAddSearchCoverage(
                                                'additionalInsureds'
                                            );
                                        }}
                                    />
                                )
                            }
                            errorState={showErrors && !isAdditionalInsuredValid}
                        >
                            <div className="formCoveragesContainer">
                                <CPClausesComponentVM
                                    {...commonClausesProps}
                                    onChangeSubmissionAndSync={
                                        changeAdditionalInsuredAndSync
                                    }
                                    onChangeClause={changeAdditionalInsured}
                                    onValidate={onAdditionalInsuredValidate}
                                    path="additionalInsured"
                                    value={additionalInsured}
                                />
                            </div>
                        </AccordionCard>
                    )}
                </Accordion>
            </CoverageContext.Provider>
        </CoveragesConfigContext.Provider>
    );
};

export default BuildingClauses;
