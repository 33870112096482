import { Link } from '@jutro/components';
import _ from 'lodash';
import React from 'react';
import { WniProductsUtil } from 'wni-portals-util-js';

const { 
    CPP_PRODUCT_CODE,
    CP_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    IM_PRODUCT_CODE,
    CR7_PRODUCT_CODE,
    policyLinePatternForCPP,
    getPolicyLinePattern
} = WniProductsUtil;


// ------- FOR GL PRODUCT START ---------
const premiseSubline = 'Premises/Operations and Products/Completed Operations';
const liquorSubline = 'Liquor';
const proWithdrawalSubline = 'Product Withdrawal';
const ownersSubline = 'Owners and Contractors';
const railroadSubline = 'Railroad';

const STANDALONE_SUBLINES = [ownersSubline, railroadSubline];
const ALONGSIDE_PREMISE_SUBLINES = [liquorSubline, proWithdrawalSubline];
const GL_PRODUCT_SELECTION_FOR_GLPRODUCT = [ownersSubline, railroadSubline];
const CPP_INCLUDED_GL_SUBLINES = [
    premiseSubline,
    liquorSubline,
    proWithdrawalSubline,
];
// ------- FOR GL PRODUCT END ---------

// ------- FOR IM PRODUCT START ---------
const IM_MONOLINE_CODE = 'Monoline';
const IM_PACKAGE_CODE = 'Package';
const IM_BOTH_CODE = 'Both';

const IM_INCLUDED_PACKAGE_VALUES = [IM_MONOLINE_CODE, IM_PACKAGE_CODE, IM_BOTH_CODE];


// ------- FOR IM PRODUCT END ---------

const getAvailableValues = (vm, path) => {
    const data = _.get(vm, `productRules.${path}.value`, []);
    return data.map((item) => {
        return {
            name: item,
            code: item,
        };
    });
};

const setSelectPolicyDefaultValue = (vm, path) => {
    const availableData = getAvailableValues(vm, path);
    if(availableData.length === 1) {
        return availableData[0].code;
    }
    return null
}

const getImIncludedPackageValues = () => {
    return IM_INCLUDED_PACKAGE_VALUES.map((item) => {
        return {
            name: item,
            code: item,
        };
    });
};

const isFieldVisible = (vm, path) => {
    const isRequired = _.get(vm, `productRules.${path}.aspects.required`);
    if(!isRequired) {
        _.set(vm, `productRules.${path}.value`, null);
    }
    return isRequired;
};

/**
 * liquor and product withdrawal cannot be standalone (must be with prem/ops, but can be a package)
 * if railroad is selected first, do not allow user to select any other option. if any other option is selected first, do not allow user to select railroad
 * if owners and contractors is selected first, do not allow user to select any other option. if any other options is selected first, do not allow user to select owners and contractors
 * @param {String} path
 * @returns
 */
const getSublinesAvailableValues = (policyHolderVM, path) => {
    const sublineIncluded = _.get(policyHolderVM, 'productRules.sublineIncluded.value', []);
    const availableData = _.get(policyHolderVM, `productRules.${path}.value`, []);

    if (_.isEmpty(sublineIncluded)) {
        return availableData.map((item) => {
            return {
                name: item,
                code: item,
                isDisabled: false,
            };
        });
    }
    // if railroad is selected first, do not allow user to select any other option. if any other option is selected first, do not allow user to select railroad
    // if owners and contractors is selected first, do not allow user to select any other option. if any other options is selected first, do not allow user to select owners and contractors
    const standaloneIncluded = _.intersection(sublineIncluded, STANDALONE_SUBLINES);
    if (!_.isEmpty(standaloneIncluded)) {
        return availableData.map((item) => {
            return {
                name: item,
                code: item,
                isDisabled: !_.includes(sublineIncluded, item),
            };
        });
    }

    // if any other option is selected first, do not allow user to select railroad
    // if any other options is selected first, do not allow user to select owners and contractors
    return availableData.map((item) => {
        return {
            name: item,
            code: item,
            isDisabled: _.includes(STANDALONE_SUBLINES, item),
        };
    });
};

const getProductSelectedAndLinePatterns = (vm) => {
    const {
        selectedProducts = [],
        productRules: {
            sublineIncluded = [],
            imIncludedPackage
        } = {}
    } = vm.value || {};

    const linePatterns = []; // for the cpp policylinepattern

    let newSelectedProducts = selectedProducts;
    if(_.includes(selectedProducts, GL_PRODUCT_CODE)) { // for GL
        const hasCppIncludedSublines = _.intersection(sublineIncluded, CPP_INCLUDED_GL_SUBLINES);
        if(!_.isEmpty(hasCppIncludedSublines) ) {
            newSelectedProducts = newSelectedProducts.filter((item) => item !== GL_PRODUCT_CODE).concat(CPP_PRODUCT_CODE);
            linePatterns.push(getPolicyLinePattern(GL_PRODUCT_CODE));
        };
    };

    if(_.includes(selectedProducts, IM_PRODUCT_CODE)) { // For IM
        switch(imIncludedPackage) {
            case IM_PACKAGE_CODE:
                newSelectedProducts = newSelectedProducts.filter((item) => item !== IM_PRODUCT_CODE).concat(CPP_PRODUCT_CODE);
                linePatterns.push(getPolicyLinePattern(IM_PRODUCT_CODE));
                break;
            case IM_BOTH_CODE:
                newSelectedProducts = newSelectedProducts.concat(CPP_PRODUCT_CODE);
                linePatterns.push(getPolicyLinePattern(IM_PRODUCT_CODE));
                break;
                default:
                    break
        }; 
    };

    if(_.includes(selectedProducts, CP_PRODUCT_CODE)) {
        newSelectedProducts = newSelectedProducts.filter((item) => item !== CP_PRODUCT_CODE).concat(CPP_PRODUCT_CODE);
        linePatterns.push(getPolicyLinePattern(CP_PRODUCT_CODE));
    };
    if(_.includes(selectedProducts, CR7_PRODUCT_CODE)) {
        newSelectedProducts = newSelectedProducts.filter((item) => item !== CR7_PRODUCT_CODE).concat(CPP_PRODUCT_CODE);
        linePatterns.push(getPolicyLinePattern(CR7_PRODUCT_CODE));
    };

    return {
        selectedProducts: _.uniq(newSelectedProducts),
        selectedlinePatterns: _.uniq(linePatterns)
    };
};

const renderImIncludedInfo = (message) => {
    return (
        <div>
            <Link href="###">Click here </Link>
            {message}
        </div>
    );
};

const isProductMessageVisible = (availableProducts) => {
    const linesForCPP = policyLinePatternForCPP.map((item) => item.productCode);
    const availableProductIds = availableProducts.map((item) => item.code);

    return !_.isEmpty(_.intersection(linesForCPP, availableProductIds));
};

export default {
    premiseSubline,
    liquorSubline,
    proWithdrawalSubline,
    ownersSubline,
    railroadSubline,

    ALONGSIDE_PREMISE_SUBLINES,
    STANDALONE_SUBLINES,
    GL_PRODUCT_SELECTION_FOR_GLPRODUCT,
    CPP_INCLUDED_GL_SUBLINES,

    getAvailableValues,
    setSelectPolicyDefaultValue,
    getSublinesAvailableValues,
    isFieldVisible,
    getImIncludedPackageValues,
    getProductSelectedAndLinePatterns,
    renderImIncludedInfo,
    isProductMessageVisible
};
