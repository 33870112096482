import { setComponentMapOverrides } from '@jutro/uiconfig';

import {
    PaymentDetailsPage as PAPaymentDetailsPage,
    PaymentDetailsReadOnlyPage as PAPaymentDetailsReadOnlyPage
} from 'gw-capability-quoteandbind-common-react';


// import PAYourInfoPage from 'gw-capability-quoteandbind-pa-react/pages/YourInfo/YourInfoPage';
// import PAQualificationPage from 'gw-capability-quoteandbind-common-react/pages/Qualification/QualificationPage';
// import PAAdditionalInformation from 'gw-capability-quoteandbind-pa-react/pages/AdditionalInformation/AdditionalInformationPage';
// import PASuccessPage from 'gw-capability-quoteandbind-pa-react/pages/Success/SuccessPage';

// import PADriverCoveragesPage from './pages/DriverCoverages/PADriverCoveragesPage';
// import PADriverCoveragesReadOnlyPage from './pages/DriverCoverages/PADriverCoveragesPage.readonly';

// import PAVehicleCoveragesPage from './pages/VehicleCoverages/PAVehicleCoveragesPage';
// import PAVehicleCoveragesReadOnlyPage from './pages/VehicleCoverages/PAVehicleCoveragesPage.readonly';

import PAPolicyDetailsPage from './pages/PolicyDetails/PAPolicyDetailsPage';
import PAPolicyDetailsReadOnlyPage from './pages/PolicyDetails/PAPolicyDetailsPage.readonly';


import PADriversPage from './pages/Drivers/PADriversPage';
import PADriversReadOnlyPage from './pages/Drivers/PADriversPage.readonly';

import PAVehiclesPage from './pages/Vehicles/PAVehiclesPage';
import PAVehiclesReadOnlyPage from './pages/Vehicles/PAVehiclesPage.readonly';

import PACoveragesPage from './pages/Coverages/PACoveragesPage';
import PACoveragesReadOnlyPage from './pages/Coverages/PACoveragesPage.readonly';

import PADriverAssignmentPage from './pages/DriverAssignment/PADriverAssignmentPage';
import PADriverAssignmentReadOnlyPage from './pages/DriverAssignment/PADriverAssignmentPage.readonly';

import PAIncidentsAndReportsPage from './pages/IncidentsAndReports/PAIncidentsAndReportsPage';
import PAIncidentsAndReportsReadOnlyPage from './pages/IncidentsAndReports/PAIncidentsAndReportsPage.readonly';

import PAQuotePage from './pages/Quote/PAQuotePage';
import PAQuoteReadOnlyPage from './pages/Quote/PAQuotePage.readonly';

import PAPolicySummaryPage from './pages/Summary/PAPolicySummaryPage';
import PAPolicySummaryReadOnlyPage from './pages/Summary/PAPolicySummaryPage.readonly';


setComponentMapOverrides(
    {
        PAPolicyDetailsPage: { component: 'PAPolicyDetailsPage' },
        PAPolicyDetailsReadOnlyPage: { component: 'PAPolicyDetailsReadOnlyPage' },

        PADriversPage: { component: 'PADriversPage' },
        PADriversReadOnlyPage: { component: 'PADriversReadOnlyPage' },

        PAVehiclesPage: { component: 'PAVehiclesPage' },
        PAVehiclesReadOnlyPage: { component: 'PAVehiclesReadOnlyPage' },

        PACoveragesPage: { component: 'PACoveragesPage' },
        PACoveragesReadOnlyPage: { component: 'PACoveragesReadOnlyPage' },

        PADriverAssignmentPage: { component: 'PADriverAssignmentPage' },
        PADriverAssignmentReadOnlyPage: { component: 'PADriverAssignmentReadOnlyPage' },

        PAIncidentsAndReportsPage: { component: 'PAIncidentsAndReportsPage' },
        PAIncidentsAndReportsReadOnlyPage: { component: 'PAIncidentsAndReportsReadOnlyPage' },

        PAQuotePage: { component: 'PAQuotePage' },
        PAQuoteReadOnlyPage: { component: 'PAQuoteReadOnlyPage' },

        PAPolicySummaryPage: { component: 'PAPolicySummaryPage' },
        PAPolicySummaryReadOnlyPage: { component: 'PAPolicySummaryReadOnlyPage' },

        PAPaymentDetailsPage: { component: 'PAPaymentDetailsPage' },
        PAPaymentDetailsReadOnlyPage: { component: 'PAPaymentDetailsReadOnlyPage' },

        // Deprecated Pages: to be removed
        // PAYourInfoPage: { component: 'PAYourInfoPage' },
        // PAQualificationPage: { component: 'PAQualificationPage' },
        // PAAdditionalInformation: { component: 'PAAdditionalInformation' },
        // PASuccessPage: { component: 'PASuccessPage' },

        // PADriverCoveragesPage: { component: 'PADriverCoveragesPage' },
        // PADriverCoveragesReadOnlyPage: { component: 'PADriverCoveragesReadOnlyPage' },

        // PAVehicleCoveragesPage: { component: 'PAVehicleCoveragesPage' },
        // PAVehicleCoveragesReadOnlyPage: { component: 'PAVehicleCoveragesReadOnlyPage' },
    },
    {
        PAPolicyDetailsPage,
        PAPolicyDetailsReadOnlyPage,

        PADriversPage,
        PADriversReadOnlyPage,

        PAVehiclesPage,
        PAVehiclesReadOnlyPage,

        PACoveragesPage,
        PACoveragesReadOnlyPage,

        PADriverAssignmentPage,
        PADriverAssignmentReadOnlyPage,

        PAIncidentsAndReportsPage,
        PAIncidentsAndReportsReadOnlyPage,

        PAQuotePage,
        PAQuoteReadOnlyPage,

        PAPolicySummaryPage,
        PAPolicySummaryReadOnlyPage,

        PAPaymentDetailsPage,
        PAPaymentDetailsReadOnlyPage,

        // Deprecated Pages: To be removed
        // PAYourInfoPage,
        // PAQualificationPage,
        // PAAdditionalInformation,
        // PASuccessPage,

        // PADriverCoveragesPage,
        // PADriverCoveragesReadOnlyPage,

        // PAVehicleCoveragesPage,
        // PAVehicleCoveragesReadOnlyPage,

    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PAWizard } from 'gw-capability-quoteandbind-pa-react/PAWizard';
export { default as PAVehiclesPage } from './pages/Vehicles/PAVehiclesPage';
export { default as PAVehiclesReadOnlyPage } from './pages/Vehicles/PAVehiclesPage.readonly';
export { default as PAVehicleCoveragesPage } from './pages/VehicleCoverages/PAVehicleCoveragesPage';
export { default as PADriversPage } from './pages/Drivers/PADriversPage';
export { default as PADriversReadOnlyPage } from './pages/Drivers/PADriversPage.readonly';
export { default as PADriverCoveragesPage } from './pages/DriverCoverages/PADriverCoveragesPage';
export { default as PADriverCoveragesReadOnlyPage } from './pages/DriverCoverages/PADriverCoveragesPage.readonly';
export { default as PADriverAssignmentPage } from './pages/DriverAssignment/PADriverAssignmentPage';
export { default as PADriverAssignmentReadOnlyPage } from './pages/DriverAssignment/PADriverAssignmentPage.readonly';
export { default as PACoveragesPage } from './pages/Coverages/PACoveragesPage';
export { default as PACoveragesReadOnlyPage } from './pages/Coverages/PACoveragesPage.readonly';
export { default as PAQuoteUIHelper } from './pages/Quote/util/PAQuoteUIHelper';

export { default as PAIncidentsAndReportsMessage } from './pages/IncidentsAndReports/PAIncidentsAndReportsPage.messages';
export { default as PAIncidentsAndReportsStatic } from './pages/IncidentsAndReports/PAIncidentsAndReportsPage.static';
export { default as PAIncidentsAndReportsMetadata } from './pages/IncidentsAndReports/PAIncidentsAndReportsPage.metadata.json5';