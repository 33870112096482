import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, InlineLoader, Tooltip } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';

import {
    // WizardSidebar,
    pageTemplateProps,
    // WizardPageTemplate as DigitalWizardPageTemplate
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate as DigitalWizardPageTemplate } from 'gw-portals-wizard-components-ui'
// import styles from 'gw-portals-wizard-react/Wizard/templates/DigitalWizardPageTemplate.module.scss';

// import styles from './AQWizardPageTemplate.module.scss';

/**
 * See DigitalWizardPageWithTitleTemplate, DigitalWizardPageTemplate
 * @param {object} props
 * @returns {function}
 */
function AQWizardPageTemplate(props) {
    const {
        children,
        ...otherProps
    } = props;

    // const renderWizardHeader = useCallback(
    //     (wizardData) => {
    //         const getSubmissionVM = wizardData.submissionVM || wizardData;
    //         return (
    //             <WizardPageHeader
    //                 wizardSubmission={getSubmissionVM}
    //                 isSideHeader={isSideHeader}
    //                 // warningMessage={warningMessage}
    //             />
    //         );
    //     },
    //     [
    //         isSideHeader,
    //         // warningMessage
    //     ]
    // );

    return (
        <div>
            <DigitalWizardPageTemplate
                // renderContextComponent={renderWizardHeader}
                showContextComponent={false}
                showWizardHeader
                {...otherProps}
            >
                {children}
            </DigitalWizardPageTemplate>
        </div>
    );
}

AQWizardPageTemplate.propTypes = pageTemplateProps;

export default AQWizardPageTemplate;
