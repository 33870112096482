import React, { useState } from 'react';
import _ from 'lodash';
import { Accordion, AccordionCard } from '@jutro/components';
import HOPrimaryCoverages from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/CoveragesSection/HOPrimaryCoverages/HOPrimaryCoverages';
import HOCommonOptionalCoverages from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/CoveragesSection/HOCommonOptionalCoverages/HOCommonOptionalCoverages';
import HOOtherOptionalCoverages from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/CoveragesSection/HOOtherOptionalCoverages/HOOtherOptionalCoverages';
import HOExclusions from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/CoveragesSection/HOExclusions/HOExclusions';
import HOCoveragesConfig from 'wni-capability-quoteandbind-ho-react/pages/HOCoverages/HOCoverages.config';

const HOPHomeownersCoverages = (props) => {

    const {
        coverageSubmissionVM
    } = props

    const [accordionStates, setAccordionStates] = useState([
        'primaryCoverages',
        'commonOptionalCoverags'
    ])

    const commonAccordionCardProps = {
        chevron: true,
        className: 'coveragesAccordion',
        cardBodyClassName: 'accordionCardBody',
        cardHeadingClassName: 'coveragesAccordionHeader',
    }

    const selectedVersion = _.first(_.get(coverageSubmissionVM, 'value.lobData.homeowners.offerings'));
    const selectedVersionIndex = 0;
    const commonCoverageProps = {
        selectedVersion,
        selectedVersionIndex,
        submissionVM: coverageSubmissionVM,
        lobName: 'homeowners',
        coveragesConfig: HOCoveragesConfig,
        labelPosition: 'left',
        isEditable: false
    }
    return <Accordion
        accordionStates={accordionStates}
        onUpdateAccordionStates= {(ids) => setAccordionStates(ids)}
        closeOthers={false}
        className= "accordion-wizardTitle"
    >
        <AccordionCard
            id='primaryCoverages'
            title='Primary Coverages'
            {...commonAccordionCardProps}
        >
            <HOPrimaryCoverages 
                {...commonCoverageProps}
            />
        </AccordionCard>
        <AccordionCard
            id='commonOptionalCoverags'
            title='Common Optional Coverages'
            {...commonAccordionCardProps}
        >
            <HOCommonOptionalCoverages
                {...commonCoverageProps}
            />
        </AccordionCard>
        <AccordionCard
            id='otherOptionalAndExclusionsCondations'
            title = 'Other Optional Coverages & Exclusions'
            {...commonAccordionCardProps}
        >
            <HOOtherOptionalCoverages
                {...commonCoverageProps}
            />
            <HOExclusions
                {...commonCoverageProps}
            />
        </AccordionCard>
    </Accordion>
}

export default HOPHomeownersCoverages