import React from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import { withRouter } from 'react-router-dom';
import ClaimListComponent from '../../components/ClaimListComponent/ClaimListComponent';
import metadata from './ClaimList.metadata.json5';
import styles from './ClaimList.module.scss';

function ClaimList() {
    const overrides = {};
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            claimlistcomponent: ClaimListComponent
        }
    };

    return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
}

export default withRouter(ClaimList);
