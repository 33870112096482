import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
// import { useTranslator, IntlContext } from '@jutro/locale'; 
import { BreakpointTrackerContext } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { AgencyOfServiceComponent, AccountHolderInputPopup, AQPolicyHolderUWQuestionComponent } from 'wni-capability-gateway-react';

import { WizardConstants } from 'wni-portals-config-js';
import { useWniModal, ValidationIssuesComponent } from 'wni-components-platform-react';
import { WniAccountQuoteService, WniAccountService } from 'wni-capability-gateway';
import { WniPNIUtil, WindowUtil, WniProductsUtil, ErrorsAndWarningsUtil } from 'wni-portals-util-js';
import {  useProductsData } from 'wni-portals-util-react';
import { ServiceManager } from '@jutro/services';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import AQWizardPage from '../../template/AQWizardPage';
import AQWizardPageTempalte from '../../template/AQWizardPageTemplate';
import styles from './AQPolicyHolderPage.module.scss';
import metadata from './AQPolicyHolderPage.metadata.json5';

function AQPolicyHolderPage(props) {
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        isComponentValid,
        onValidate,
        invalidFields
    } = useValidation('AQPolicyHolderPage');
    const {
        wizardData: accountQuoteDataVM,
        updateWizardData,
        wizardPageData: {
            [WizardConstants.isNewAccount]: isNewAccount = false,
            [WizardConstants.productSelected]: productSelected = [],
            [WizardConstants.lastExitWizardPage]: submissionWizardExitPage,
            [WizardConstants.externalAgencyOptions]: externalAgencyOptions,
            [WizardConstants.licensedAgentOptions]: licensedAgentOptions,
            [WizardConstants.agentStatus]: agentStatus,
            [WizardConstants.servicingAgentOptions]: servicingAgentOptions,
            [WizardConstants.externalAgencyValue]: wizardExternalAgencyValue,
            [WizardConstants.licensedAgentValue]: wizardLicensedAgentValue,
            [WizardConstants.servicingAgentValue]: wizardServicingAgentValue
        },
        updateWizardPageData,
        currentStepIndex
    } = props;
    const { lobQuoteURL } = appConfig;
    const history = useHistory();
    const {   
        location:{
            state: {
                AOEffectiveDate,
                baseState,
            } = {},     // location status could be null during local test
        }
    } = history;
    // const PAHO = ['PersonalAuto', 'HOPHomeowners'];
    const { getAvailableEffectiveDate } = useProductsData();
    const sortProductSelected = WniProductsUtil.getSortProductSelected(productSelected);
    // const productSelectedIncludePAHO = _.every(PAHO, (paho) => _.includes(productSelected, paho));
    // const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const [showErrors, updateShowErrors] = useState(false);
    const [pageLevelValidationIssues, updatePageLevelValidationIssues] = useState([]);

    const {
        accountNumber,
        producerCodes = [],
        accountHolder: {
            primaryAddress: {
                state: primaryState,
            }
        },
        accountHolder,
        systemDate,
    } = accountQuoteDataVM.value;
    const defaultProducerCode = producerCodes[0] || {};
    const {
        authHeader,
        authUserData
    } = useAuthentication();
    const userPublicID = authUserData && authUserData.publicID;
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const {
        interactionModel,
    } = useDependencies(['interactionModel', 'loadingMask']);
    const [externalAgencyValue, updateExternalAgencyValue] = useState(wizardExternalAgencyValue);
    const [producerCodeDisplayName, updateProducerCodeDisplayName] = useState('')
    const [licensedAgentValue, updateLicensedAgentValue] = useState(wizardLicensedAgentValue);
    const [servicingAgentValue, updateServicingAgentValue] = useState(wizardServicingAgentValue);
    const isIncludeHoLine = sortProductSelected.includes('HOPHomeowners')
    const [uwQuestions, setUWQuestions] = useState({
        isIncludeHoLine,
        anyCoverageDeclined: null,
        anyCoverageDeclinedExplainVisible: false,
        anyCoverageDeclinedExplainValue: null,
        anyApplicant: null,
        anyApplicantExplainVisible: false,
        anyApplicantExplainValue: null,
        hasBeenIndictedIn5Years: null,
        hasBeenIndictedIn5YearsExplainVisible: false,
        hasBeenIndictedIn5YearsExplainValue: null,
        haveMaintainedContinuousCov: null,
        haveMaintainedContinuousCovExplainValue: null,
        transferPart: null
    });
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();
    // const { interactionModel } = useDependencies('interactionModel');
    const NUM_OF_PROUDCTS_TO_SHOW_ADDL_INFO = 2;

    const showAccountHolderModal = useCallback(
        (accountHolderDataVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                accountHolder,
                accountHolderDataVM,
            };
            return modalApi.showModal(
                <AccountHolderInputPopup {...componentProps} />
            );
        }, [accountHolder, viewModelService, authHeader]
    );

    const quoteBaseState =( baseState || primaryState)
    const getAccountHolderDisplayName = useCallback(() => {
        // const firstName = _.get(accountQuoteDataVM.value, 'accountHolder.firstName');
        // const lastName = _.get(accountQuoteDataVM.value, 'accountHolder.lastName');
        // const accountNumber = _.get(accountQuoteDataVM.value, )
        const primaryAddress = WniPNIUtil.getPrimaryAddressDisplayName(
            _.get(accountQuoteDataVM, 'accountHolder.primaryAddress')
        );

        return (
            <div className='font-weight-normal'>
                <div className="mb-10">{accountNumber}</div>
                {/* <div>{`${firstName} ${lastName}`}</div> */}
                <div>{accountHolder.displayName}</div>
                <div className="HQPrimaryAddress">{primaryAddress}</div>
            </div>
        );
    }, [accountNumber, accountQuoteDataVM]);

    const openEditAccountHolderPopup = useCallback(() => {
        const newAccountQuoteDataVM = viewModelService.clone(accountQuoteDataVM);
        showAccountHolderModal(newAccountQuoteDataVM.accountHolder)
            .then((updatedAccountHolder) => {
                _.set(newAccountQuoteDataVM.value, 'accountHolder', updatedAccountHolder);
                updateWizardData(newAccountQuoteDataVM);
            }).catch(() => _.noop());
    }, [accountQuoteDataVM, showAccountHolderModal, updateWizardData]);

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const generateNewSubmissionDTO = useCallback((productSelectedMap) => {
        return productSelectedMap.map((product) => {
            return {
                accountNumber: accountNumber,
                country: defaultCountryCode,
                effectiveDate: getAvailableEffectiveDate(product, quoteBaseState, AOEffectiveDate),
                producerCode: ((typeof(externalAgencyValue) === 'string') ? externalAgencyValue : _.get(externalAgencyValue,'publicID')),
                productCode: product,
                state: quoteBaseState,
            }
        });
    }, [accountNumber, defaultCountryCode, AOEffectiveDate, systemDate, externalAgencyValue, quoteBaseState]);


    const onNext = useCallback(async () => {
        const newSubmissionDTO = generateNewSubmissionDTO(sortProductSelected);
        const newAccountQuoteData = await WniAccountQuoteService
            .saveAccountHolder({
                accountNumber,
                accountHolder,
                newSubmissionDTO,
                selectedProducts: sortProductSelected,
                servicingAgent: servicingAgentValue,
                producerOrLicensedAgent: _.get(licensedAgentValue, 'code') || licensedAgentValue,
                uWQuestionAnswers: uwQuestions
            }, authHeader);
            if(_.isEmpty(newAccountQuoteData)){
                updatePageLevelValidationIssues(ErrorsAndWarningsUtil.getValidationIssues({
                    "validationIssues": {
                        "level": "readyforissue"
                    },
                    "pcDisplayMessage_Ext": {
                        "errors": [
                            "This agency code has been terminated; no action can be completed. Please contact your Regional Agency Manager with any questions."
                        ],
                        "warnings": []
                    }
                }))
                return false
            }

        const submissionToOpen = _.get(newAccountQuoteData, 'submissionToOpen')
        const postalCode = _.get(
            newAccountQuoteData,
            'accountHolder.primaryAddress.postalCode'
        );

        history.push(lobQuoteURL[sortProductSelected[0]], {
            isReadOnly: false,
            quoteentry: {
                postalCode: postalCode,
                quoteID: submissionToOpen,
                // producerCode_Ext: submission.producerCode_Ext
            },
        });
       
        accountQuoteDataVM.value = newAccountQuoteData;
        const newWizardPageData = {
            // [WizardConstants.licensedAgentValue]: licensedAgentValue,
            // [WizardConstants.servicingAgentValue]: servicingAgentValue,
            // [WizardConstants.externalAgencyValue]: externalAgencyValue
        };
        const hasNewSubmissionCreated = true;
        if (hasNewSubmissionCreated) {
            _.set(newWizardPageData, 'prendingTransactions', null);
        }
        updateWizardPageData(newWizardPageData);

        return accountQuoteDataVM;
    }, [
        generateNewSubmissionDTO,
        accountNumber,
        accountHolder,
        authHeader,
        accountQuoteDataVM,
        sortProductSelected,
        updateWizardPageData,
        uwQuestions]);

    const onCancel = useCallback(async () => {
        interactionModel.goToPage(null, history, 'accountSummary', accountNumber);
    }, [accountNumber, history, interactionModel]);

    const getExternalAgencyOptions = async () => {
        if (isExternalUser) {
            const agencies = await WniAccountService.getAgencyMatchData('', 6, authHeader).then((agencyResponse) => {
                return agencyResponse.map((selectRow) => {
                    const agencyDisplay = `${selectRow.code} - `
                        + `${selectRow.description ? `${selectRow.description}-` : null} `
                        + `${selectRow.city_Ext ? selectRow.city_Ext : null}, `
                        + `${selectRow.state_Ext ? selectRow.state_Ext : null}`;
                    return {
                        code: selectRow.code,
                        name: agencyDisplay,
                        publicID: selectRow.publicID
                    };
                });
            });
            return agencies;
        }
        return null;
    };

    const updateLicensedAgent = (value) => {
        updateLicensedAgentValue(value);
        updateWizardPageData({ [WizardConstants.licensedAgentValue]: value });
    };

    const updateServicingAgent = (value) => {
        updateServicingAgentValue(value);
        updateWizardPageData({ [WizardConstants.servicingAgentValue]: value });
    };

    const setLicensedAgentDefaultValue = (status, licensedAgents) => {
        if (status === 'Licensed') {
            const licensedDefaultValue = _.filter(licensedAgents, (item) => item.code === userPublicID);
            updateLicensedAgent(licensedDefaultValue[0]);
            return licensedDefaultValue[0];
        }
        return null;
    };

    const getLicensedAgentOptions = async (producerCodePublicID) => {
        const res = await WniAccountService
            .getLicensedAgentData(producerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        setLicensedAgentDefaultValue(agentStatus, options);
        return options;
    };

    const getAgentStatus = () => {
        const producerCodePublicID = defaultProducerCode.publicID;
        if (producerCodePublicID) {
            return WniAccountService.getAgentStatus(producerCodePublicID, userPublicID, authHeader);
        }
        return '';
    };

    const getServicingAgentOptions = async (producerCodePublicID) => {
        const res = await WniAccountService.getServicingAgentData(producerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        /**
         * setServicingAgentDefaultValue
         */
        const servicingDefaultValue = _.find(options, (item) => item.code === userPublicID);
        const servicingDefaultValueCode = _.get(servicingDefaultValue, "code")
        updateServicingAgent(servicingDefaultValueCode);
        return options;
    };

    const updateExternalAgency = async (value) => {
        let producerCodePublicID = ''
        if (isExternalUser) {
            producerCodePublicID = value.code;
        } else if (!isExternalUser) {
            producerCodePublicID = value.publicID
        }
        const servicingRes = await getServicingAgentOptions(producerCodePublicID);
        const licensedAgentsRes = await getLicensedAgentOptions(producerCodePublicID);
        const servicingDefaultValue = _.find(servicingRes, (item) => item.code === userPublicID);
        const servicingDefaultValueCode = _.get(servicingDefaultValue, "code")
        const licensedAgentDefaultValue = setLicensedAgentDefaultValue(agentStatus, licensedAgentsRes);
        const newWizardPageData = {
            [WizardConstants.servicingAgentOptions]: servicingRes,
            [WizardConstants.licensedAgentOptions]: licensedAgentsRes,
            [WizardConstants.externalAgencyValue]: producerCodePublicID,
            [WizardConstants.servicingAgentValue]: servicingDefaultValueCode,
            [WizardConstants.licensedAgentValue]: licensedAgentDefaultValue
        };
        updateWizardPageData(newWizardPageData);
        updateExternalAgencyValue(producerCodePublicID);
        updateProducerCodeDisplayName(_.get(value, 'name'))
    };

    /**
     * When initializing updateWizardPageData,
     * it needs to be integrated in a method, otherwise data coverage will occur
     */
    const getAgentInfo = async () => {
        let agencyRes;
        let servicingRes;
        let agentStatusRes;
        let licensedAgentsRes;
        const newWizardPageData = {};

        if (!agentStatus) {
            agentStatusRes = await getAgentStatus();
            _.set(newWizardPageData, WizardConstants.agentStatus, agentStatusRes);
        }

        if (!externalAgencyOptions) {
            agencyRes = await getExternalAgencyOptions();
            _.set(newWizardPageData, WizardConstants.externalAgencyOptions, agencyRes);
            /*
            * There is a default value when ExternalAgencyOptions has a value and only one value
            * The agent services is loaded only when ExternalAgencyOptions has a default value
            */
            if (agencyRes && agencyRes.length === 1) {
                const producerCodePublicID = _.get(agencyRes[0], 'publicID');
                /**
                 * Determine whether the data exists in the cache to avoid repeated calls to the service
                 */
                if (!servicingAgentOptions) {
                    servicingRes = await getServicingAgentOptions(producerCodePublicID);
                }
                if (!licensedAgentOptions) {
                    licensedAgentsRes = await getLicensedAgentOptions(producerCodePublicID);
                    setLicensedAgentDefaultValue(agentStatusRes, licensedAgentsRes);
                }
                const servicingDefaultValue = _.find(servicingRes || [], (item) => item.code === userPublicID);
                const servicingDefaultValueCode = _.get(servicingDefaultValue, "code")
                const licensedAgentDefaultValue = setLicensedAgentDefaultValue(agentStatusRes, licensedAgentsRes || []);
                _.set(newWizardPageData, WizardConstants.servicingAgentOptions, servicingRes);
                _.set(newWizardPageData, WizardConstants.licensedAgentOptions, licensedAgentsRes);
                _.set(newWizardPageData, WizardConstants.licensedAgentValue, licensedAgentDefaultValue);
                _.set(newWizardPageData, WizardConstants.servicingAgentValue, servicingDefaultValueCode);
            }
            updateWizardPageData(newWizardPageData);
        }
    };

    useEffect(() => {
        getAgentInfo();
    }, []);

    /**
     * setExternalAgencyDefaultValue
     */
    useEffect(() => {
        if (_.isEmpty(externalAgencyValue) && externalAgencyOptions && externalAgencyOptions.length === 1) {
            updateExternalAgencyValue(externalAgencyOptions[0].publicID);
            updateWizardPageData({ [WizardConstants.externalAgencyValue]: externalAgencyOptions[0].publicID });
        }
    }, [externalAgencyOptions, externalAgencyValue]);

    const updateLicensedAgentOptions = (value) => {
        updateWizardPageData({ [WizardConstants.licensedAgentOptions]: value });
    };

    const updateServicingAgentOptions = (value) => {
        updateWizardPageData({ [WizardConstants.servicingAgentOptions]: value });
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        // residentHouseholdTable: {
        //     data: showResidentHouseholdTable()
        // },
        dynamicInlineNotificationContainer: {
            validationIssues: pageLevelValidationIssues,
            visible: pageLevelValidationIssues.length > 0,
            scrollToIssues: true,
        },
        agencyServiceContent: {
            externalAgencyOptions,
            licensedAgentOptions,
            servicingAgentOptions,
            showErrors,
            updateExternalAgency,
            // externalAgencyValue: externalAgencyValue,
            // externalAgencyValue: {
            //     name: producerCodeDisplayName,
            //     publicID: externalAgencyValue
            // },
            externalAgencyName: producerCodeDisplayName,
            externalAgencyPublicID: externalAgencyValue,
            updateLicensedAgent,
            licensedAgentValue: licensedAgentValue,
            servicingAgentValue: servicingAgentValue,
            updateServicingAgent,
            updateLicensedAgentOptions,
            updateServicingAgentOptions,
            onValidate
        },
        accountHolder: {
            value: getAccountHolderDisplayName()
        },
        accountHolderEditIcon: {
            visible: isNewAccount,
            onClick: openEditAccountHolderPopup
        },
        createAccountMsg: {
            visible: isNewAccount
        },
        // residentHouseholdSection: {
        //     visible: false
        //     // !isNewAccount
        // },
        additionalInfoSection: {
            visible: sortProductSelected.length >= NUM_OF_PROUDCTS_TO_SHOW_ADDL_INFO
        },
        additionalInfoContent: {
            uwQuestions,
            setUWQuestions,
            onValidate,
            showErrors
        },
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            policyholderuwquestion: AQPolicyHolderUWQuestionComponent,
            agencyServiceinfo: AgencyOfServiceComponent,
            validationissuescomponent: ValidationIssuesComponent
        }
    };


    //---------------------
    return (
        <AQWizardPage
            // showNext={false}
            onNext={isComponentValid ? onNext : handleValidation}
            alwaysCallOnNext
            onCancel={onCancel}
            showPrevious={false}
            showCancel
            template={AQWizardPageTempalte}
            cancelLabel="Cancel"
            skipWhen={() => submissionWizardExitPage > currentStepIndex}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                // onModelChange={updateWizardData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </AQWizardPage>
    );
}

AQPolicyHolderPage.propTypes = AQWizardPage.propTypes;
export default AQPolicyHolderPage;
