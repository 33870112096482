
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import metadata from './UMBDriverComponent.metadata.json5';
import styles from './UMBDriverComponent.module.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function UMBDriverComponent(props) {
    const {
        model: driverVM,
        onValueChange: onUMBDriverChange,
        showErrors,
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);


    useEffect(() => {
    }, []);


    // const writeValue = (value, path) => {
    //     onValueChange(value, path);
    // };

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            showErrors: showErrors
        },
        licenseState:{
            visible: !!(_.get(driverVM.value, 'isLicensedDriver')),
        },
        driverLicenseNumberInput: {
            visible: !!(_.get(driverVM.value, 'isLicensedDriver')),
        },
        hasLicenseSuspendedExplain: {
            visible: !!(_.get(driverVM.value, 'hasLicenseSuspended')),
            required: !!(_.get(driverVM.value, 'hasLicenseSuspended'))
        },
        isRequiredToSubmitSR22Explain: {
            visible: !!(_.get(driverVM.value, 'isRequiredToSubmitSR22')),
            required: !!(_.get(driverVM.value, 'isRequiredToSubmitSR22'))
        },
        // namedDriverExclusionApplyToggle: {
        //     readOnly: true
        // }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    // const readValue = (id, path) => {
    //     return readViewModelValue(
    //         metadata.componentContent,
    //         dtoVM,
    //         id,
    //         path,
    //         overrideProps
    //     );
    // };
    //---------
    // return (
    //     <MetadataContent
    //             uiProps={metadata.componentContent}
    //             overrideProps={overrides}
    //             {...resolvers} />
    // );
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            // resolveValue={readValue}
            onValueChange={onUMBDriverChange}
            showErrors={showErrors}
        />
    );
}

UMBDriverComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
};

UMBDriverComponent.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
};

export default UMBDriverComponent