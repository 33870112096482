
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { UWIssueUtil } from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import messages from './QuoteUWIssuesPendingList.messages';
import metadata from './QuoteUWIssuesPendingList.metadata.json5';
import styles from './QuoteUWIssuesPendingList.module.scss';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function QuoteUWIssuesPendingList(props) {
    const {
        uwIssues: infoUWIssues,
    } = props;

    const translator = useTranslator();
    const infoUWIssueBaseTitle = translator(messages.requiringPostIssueReview);

    useEffect(() => {
    }, []);


    // const writeValue = (value, path) => {
    //     onValueChange(value, path);
    // };

    //----------------------------------
    const infoUWIssueTitle = UWIssueUtil.formatUWIssueTypeTitle(infoUWIssueBaseTitle, infoUWIssues.length);
    const renderedListContent = renderHelper.getIssuesRenderContent(infoUWIssues);
    const overrideProps = {
        '@all': {
        },
        underwritingIssuesAccordion: {
            visible: !_.isEmpty(infoUWIssues),
        },
        infoUWIssuesCard: {
            title: infoUWIssueTitle.toUpperCase(),
            content: renderedListContent,
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    // const readValue = (id, path) => {
    //     return readViewModelValue(
    //         metadata.componentContent,
    //         dtoVM,
    //         id,
    //         path,
    //         overrideProps
    //     );
    // };
    //---------
    // return (
    //     <ViewModelForm
    //         uiProps={metadata.componentContent}
    //         model={{}}
    //         overrideProps={overrideProps}
    //         classNameMap={resolvers.resolveClassNameMap}
    //         callbackMap={resolvers.resolveCallbackMap}
    //         componentMap={resolvers.resolveComponentMap}
    //         // onModelChange={updateModelValue}
    //         // resolveValue={readValue}
    //         // onValueChange={writeValue}
    //         // showErrors={showErrors}
    //     />
    // );
    if (_.isEmpty(infoUWIssues)) {
        return null;
    }
    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} />;
}

QuoteUWIssuesPendingList.propTypes = {
    uwIssues: PropTypes.arrayOf(PropTypes.shape({})),
};

QuoteUWIssuesPendingList.defaultProps = {
    uwIssues: [],
};

export default QuoteUWIssuesPendingList;
