import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { findContentFromMetadata } from '@jutro/uiconfig';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import styles from './ConstructionPage.module.scss';
import metadata from './ConstructionPage.metadata.json5';
import './ConstructionPage.messages';


const { env } = appConfig;

function generateRoofTypeOverrides() {
    const roofTypeId = 'roofType';
    const originalMetadata = findContentFromMetadata(
        metadata.pageContent,
        ({ id }) => id === roofTypeId
    )[0];
    return {
        [roofTypeId]: {
            imageBasePath: `${env.DEPLOYMENT_URL}/${originalMetadata.componentProps.imageBasePath}`
        }
    };
}

function ConstructionPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const labelPosition = breakpoint === 'phoneWide' || breakpoint === 'phone' ? 'top' : 'left';
    const [isPageInitialized, setPageInitialized] = useState(false);
    const { isComponentValid, initialValidation, onValidate } = useValidation('ConstructionPage');

    useEffect(() => {
        if (_.isEmpty(submissionVM.lobData.homeowners.coverables.construction.value)) {
            // eslint-disable-next-line no-param-reassign
            submissionVM.lobData.homeowners.coverables.construction = {};
            updateWizardData(submissionVM);
        }
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldShow = (path, valueToMatch = true) => {
        let value = '';
        if (valueToMatch === true) {
            value = _.get(submissionVM, path);
        } else {
            value = _.toLower(_.get(submissionVM, path));
        }
        return value === valueToMatch;
    };

    const generateDetailElementOverrides = () => {
        return {
            roofUpgradeYear: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.roofUpgradeExists.value'
                ),
            },
            plumbingUpgradeYear: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.plumbingUpgradeExists.value'
                ),
            },
            heatingUpgradeYear: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.heatingUpgradeExists.value'
                ),
            },
            electricalUpgradeYear: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.wiringUpgradeExists.value'
                ),
            }
        };
    };

    const generateOtherFieldOverrides = () => {
        return {
            specifyRoofType: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.roofType.value.code',
                    'other'
                ),
            },
            specifyConstructionType: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.constructionType.value.code',
                    'other'
                ),
            },
            specifyPlumbing: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.plumbingType.value.code',
                    'other'
                ),
            },
            specifyPrimaryHeating: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.primaryHeatingType.value.code',
                    'other'
                ),
            },
            specifyWiring: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.wiringType.value.code',
                    'other'
                ),
            },
            specifyElectricalSystem: {
                visible: shouldShow(
                    'lobData.homeowners.coverables.construction.electricalType.value.code',
                    'other'
                ),
            }
        };
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition
        },
        ...generateRoofTypeOverrides(),
        ...generateDetailElementOverrides(),
        ...generateOtherFieldOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

ConstructionPage.propTypes = wizardProps;
export default ConstructionPage;
