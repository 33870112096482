import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { WizardConstants } from 'wni-portals-config-js';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { DPWizardPage as WizardPage } from 'wni-capability-quoteandbind-dp-react';
import { useWniModal } from 'wni-components-platform-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext} from '@xengage/gw-portals-viewmodel-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
// import { WniAccountService } from 'wni-capability-gateway';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { 
    QuoteUtil, 
    IssuanceValidationUtil,
   //  WniQuestionSetUtil,
   WniPNIUtil
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
} from 'wni-capability-gateway-react';
import {  IconButton } from '@jutro/components';
import { DPHouseholdInfoService } from 'wni-capability-quoteandbind-dp';
import { HouseholdResidentsPopup } from 'wni-capability-quoteandbind-ho-react';
import styles from './DPHouseholdInfoPage.module.scss';
import metadata from './DPHouseholdInfoPage.metadata.json5';
import messages from './DPHouseholdInfoPage.messages';
import dpQuestionSetsUtil from './utils/DPQuestionSetsUtil';


function DPHouseholdInfoReadOnlyPage(props) {
    const modalApi = useWniModal();

    const {
        authHeader,
    } = useAuthentication();

    const {
        // currentStep: { id: currentPageId},
        //
        wizardData: submissionVM,
        // jumpTo: wizardJumpTo,
        // steps: wizardSteps,
        updateWizardData: updateSubmissionVM,
        // updateWizardPageData,
        wizardPageData,
        //
        // saveDpHouseholdInfo,
        // householdInfoPageOnInit,
        isOpenPNIPopupPageInit,
        checkRequiredForIssuance,
        // setAgentComponentReadOnly,
        // setCoverageFormReadOnly,
        // readOnlyFields: {
        //     // effectiveDate = false,
        //     // walTermTypedropdownselect = false,
        //     // walBaseState = false,
        //     agencyOfRecord = false,
        //     // uwqReadonly = false
        // },
    } = props;


    const viewModelService = useContext(ViewModelServiceContext);
    const [validationIssues, updateValidationIssues] = useState([]);


    const {
        // [WizardConstants.RCTCallback]: RCTCallback,
        // [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
        [WizardConstants.accountCompletedQuotes]: accountCompletedQuotes = []
    } = wizardPageData;

    const {
        jobID: quoteID, 
        sessionUUID,
        baseData,
        lobData: {
            dwellingProperty: {
                householdInfoDTO_Ext: {
                    // isPropertyRentedToOthers,
                    coveragesForm,
                    underNewConstructionType,
                    isNamedInsuredContractor,
                    householdResidents,
                    namedInsuredOccupancy,
                    isHouseholdInfoFirstEnter,
                    dpleaseAgreement_Ext: dpleaseAgreement,
                }
            }
        }
    } = submissionVM.value;

    const {
        baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        productCode
    } = baseData;

    const {
        initialValidation,
        // isComponentValid,
        onValidate,
        registerComponentValidation,
        // invalidFields
    } = useValidation('HOHouseholdInfoPage');

    // can refer to the method of PA line, add the validation into VM, if done, this below func can be remove.
    const isEffectiveDateValid = useCallback(() => {
        const effectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = LocalDateUtil.today();
        const dif = moment(effectiveDate).startOf('day').toDate().getTime() - moment(minDate).startOf('day').toDate().getTime();

        return dif >= 0;
    }, [submissionVM]);


    const isHOHouseholdInfoValid = useCallback(() => {
        return isEffectiveDateValid()
        // return isEffectiveDateValid() && isResidenceLocationValid()
    }, [isEffectiveDateValid])

    useEffect(() => {
        registerComponentValidation(isHOHouseholdInfoValid);
    }, [registerComponentValidation, isHOHouseholdInfoValid]);

    const emptyHOHouseholdContactDTO = viewModelService.create(
        {}, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
    );
    const relationshipToInsuredListsAvailableValues = _.get(emptyHOHouseholdContactDTO, 'relationshipToInsured_Ext.aspects.availableValues', []);

    const isBaseStateInMutualState = useCallback(() => {
        switch (baseState) {
            case 'IA': 
            case 'MN': 
            case 'SD': 
            case 'WI': return true;
            default: return false;
        }
    }, [baseState])


    const translator = useTranslator();

    const { domainCompany: { domainName}, loadingMask: { setLoadingMask } } = useDependencies(['domainCompany', 'loadingMask']);
    // const defaultPaymentViewType = showFullPayDiscount ? PAYMENT_PLAN_TYPES.annually : PAYMENT_PLAN_TYPES.monthly;
    // const [paymentViewType, updatePaymentViewType] = useState(defaultPaymentViewType);
    // const [backendPaymentViewType, updateBackendPaymentViewType] = useState(defaultPaymentViewType);
    const [householdResidentsTableData, updateHouseholdResidentsTableData] = useState([]);
    
    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {          
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress=WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.accountContacts_Ext[0].primaryAddress'))
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress=WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [submissionVM]);
    
    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const getResidenceLocaltionAndDisplay = useCallback(() => {
        const dwellingLocation = _.get(submissionVM, 'value.lobData.dwellingProperty.householdInfoDTO_Ext.dwellingLocation');
        let displayAddress;
        let displaystate;
        if (dwellingLocation) {
            if(_.isNil(_.get(dwellingLocation, 'address.addressLine1'))){
                displayAddress = ''
                displaystate = ''
            }else{
                displayAddress = `${_.get(dwellingLocation, 'address.addressLine1')}, ${_.get(dwellingLocation, 'address.city')}`;
                displaystate = `${_.get(dwellingLocation, 'address.state')}, ${_.get(dwellingLocation, 'address.postalCode')}`;
            }
        } else {
            // TODO 
            // For account without existing policy, default to PNI's address if PNI's address is not PO box. If PNI's address is PO Box, leave it as blank.
            // For account with existing policy (PA), we will define requirement in future user story to copy data from PA. As of now, default to PNI's address if PNI's address is not PO box. If PNI's address is PO Box, leave it as blank.
            displayAddress = ''
            displaystate = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{displayAddress}</div>
                <div>{displaystate || '-'}</div>
            </div>
        );
    }, [submissionVM]);


    const coveragesFormLogic = useCallback(() => {
        switch (coveragesForm) {
            case 'ho3': return true
            case 'ho4': return false
            case 'ho5': return true
            case 'ho6': return false
            default: ''
        }
    },[coveragesForm])

    const baseStateLogic = useCallback(() => {
        switch (baseState) {
            case 'AK': 
            case 'IA': 
            case 'MN': 
            case 'SD': 
            case 'WI': return true;
            default: return false;
        }
    },[baseState])

    const shouldShowResidenceQuestionDropdown = useCallback(() => {
        return coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic])

    const isNamedInsuredContractorEnteredNo = useCallback(() => {
        if (isNamedInsuredContractor === false) {
            return true;
        } 
        return false;
    }, [isNamedInsuredContractor])
    const shouldShowGeneralContractorToggle = useCallback(() => {
        const selection = (underNewConstructionType === 'yeswithnewconstruction' ||
                    underNewConstructionType === 'yeswithstructuralrenovation');
        return selection && coveragesFormLogic() && baseStateLogic()
    }, [baseStateLogic, coveragesFormLogic, underNewConstructionType])

    const shouldShowConstructionToggle = useCallback(() => {
        const selection = (underNewConstructionType === 'yeswithnewconstruction' && isNamedInsuredContractorEnteredNo());
        return selection && coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic, isNamedInsuredContractorEnteredNo, underNewConstructionType])

    const shouldShowEstimatedCompletionDateDropdown = useCallback(() => {
        const selection = (underNewConstructionType ===
            'yeswithstructuralrenovation' ||
        (underNewConstructionType === 'yeswithnewconstruction' && isNamedInsuredContractorEnteredNo()));
        return selection && coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic, isNamedInsuredContractorEnteredNo, underNewConstructionType])

    const questionSetFilter = dpQuestionSetsUtil.getQSFilterForDpHousehold()
    const questionSetMapper = dpQuestionSetsUtil.getQSMapperForDpHousehold();

    const updateExistFinishedPolicyAnswers = () => {
        let isExistFinishedPA = null
        accountCompletedQuotes.forEach((job) => {
            if(job.productCode === 'PersonalAuto' && (job.jobStatus === 'Bound' || job.jobStatus === 'Scheduled')) {
                isExistFinishedPA = true
            }
        });
        const isThereExistingPolicyExt = 'lobData.dwellingProperty.preQualQuestionSets.children[0].answers.value.isThereExistingPolicy_Ext';
        if(isExistFinishedPA && !_.get(submissionVM, isThereExistingPolicyExt)) {
            _.set(submissionVM, isThereExistingPolicyExt, isExistFinishedPA);
        }
    }

    const refreshHouseholdResidentsTable = useCallback((datas) => {
        const pni = _.cloneDeep(primaryNamedInsured);
        _.set(pni, 'relationshipToInsured_Ext', 'PRIMARYNAMEDINSURED');
        const initData = [];
        initData.push(pni);
        _.each(datas, (contact) => {
            if (_.get(contact, 'contactDenormPublicID') !== _.get(pni, 'publicID')) {
                initData.push(contact);
            }
        })
        updateHouseholdResidentsTableData(initData)
    }, [primaryNamedInsured]);

    useEffect(() => {
        // getAgentInfo();
        refreshHouseholdResidentsTable(householdResidents);
        updateExistFinishedPolicyAnswers();
    }, []);

    const handleAddHouseholdResident = async (event, editItem = null, editMode = false) => {      
        // fetch options
        const exsitingContacts = await DPHouseholdInfoService.getUnassignedContacts(quoteID, sessionUUID, authHeader);
        const exsitingContactsOptions = [];
        _.each(exsitingContacts, (contact) => {
            const opt = {
                code: JSON.stringify(contact),
                name: _.get(contact, 'displayName')
            };
            exsitingContactsOptions.push(opt);
        })
        exsitingContactsOptions.push({
            code: 'New',
            name: 'New'
        });
        // create Contact and using PNI address as default
        let initialValue = {
            westernNationalEmployee: false
        };
        if (editItem) {
            initialValue = {
                ...editItem
            }
        }
        const contactVM = viewModelService.create(
            initialValue, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
        );
        let contactVMList = householdResidentsTableData.map((res) => viewModelService.create(
            res, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
        ))

        if(!editItem) {
            const newVM = viewModelService.create(
                {publicID: ''}, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
            )
            contactVMList = contactVMList.concat(newVM)
        }

        const title = editMode ? translator(messages.editAdditionalNamedInsured) : translator(messages.addAdditionalNamedInsured);
        const componentProps = {
            title: title,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            contactVM: contactVM,
            defaultList: contactVMList,
            selectedContactPublicID: editItem ? editItem.publicID : '',
            viewModelService: viewModelService,
            exsitingContactsOptions,
            editMode,
            baseState,
            nextButtonVisible: true,
            saveAndNextMessageLabel: 'Save & Next Person',
            productCode,
            displayFields: {
                additionalNamedInsured: false,
            },
        };
        modalApi.showModal(<HouseholdResidentsPopup {...componentProps} />)
            .then(async (res) => {
                const listToUpdate = res.filter((elt) => _.get(elt, 'relationshipToInsured_Ext') !== 'PRIMARYNAMEDINSURED')
                setLoadingMask(true);
                const rs = await DPHouseholdInfoService.updatePersonContactList(quoteID, sessionUUID, listToUpdate, authHeader);
                // update tableData
                refreshHouseholdResidentsTable(rs);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    };

    const renderRelationshipToInsured = useCallback((item, index, property) => {
        const relationshipToInsuredValue = _.get(item, property.path);
        let value = '';
        if (relationshipToInsuredValue === 'PRIMARYNAMEDINSURED') {
            return 'Primary Named Insured';
        }
        if (!_.isNil(relationshipToInsuredValue)) {
            const interestTypeKey = _.find(relationshipToInsuredListsAvailableValues,
                (ttx) => ttx.code === relationshipToInsuredValue);
            value = translator({ id: _.get(interestTypeKey, 'name') });
        }
        return value;
    }, [relationshipToInsuredListsAvailableValues, translator]);

    const renderAdditionalNamedInsured = useCallback((item, index, property) => {
        const additionalNamedInsuredValue = _.get(item, property.path);
        let value = 'No';
        if (additionalNamedInsuredValue === 'PolicyAddlNamedInsured') {
            value = 'Yes';
        }
        return value;
    }, []);

    
    const editHouseholdRow = (event, item) => {
        return handleAddHouseholdResident(event, item, true);
    };

    const deleteHouseholdRow = useCallback(async (event, item) => {
        modalApi.showConfirm({
            title: messages.deleteAdditionalNamedInsuredTitle,
            message: messages.deleteAdditionalNamedInsuredMessage,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const publicID = _.get(item, 'publicID');
                try {
                    setLoadingMask(true);
                    const rs = await DPHouseholdInfoService.removeContacts(quoteID, sessionUUID, publicID, authHeader);
                    refreshHouseholdResidentsTable(rs);
                } finally {
                    setLoadingMask(false);
                }
                return true;
            }, _.noop
        );
    }, [authHeader, quoteID, refreshHouseholdResidentsTable, sessionUUID, setLoadingMask]);

    const renderHouseholdTableAction = (item, index) => {
        const isPNI = _.get(item, 'relationshipToInsured_Ext') === 'PRIMARYNAMEDINSURED';
        if (isPNI) {
            return '';
        }
        return (
            <>
                <IconButton
                    id={`edit${index}`}
                    icon="gw-edit"
                    iconColor="dark"
                    size="medium"
                    disabled
                    onClick={(e) => editHouseholdRow(e, item)}
                />
                <IconButton
                    id={`delete${index}`}
                    icon="gw-delete"
                    iconColor="dark"
                    size="medium"
                    disabled
                    onClick={(e) => deleteHouseholdRow(e, item)
                    }
                />
            </>
        );
    };

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const overrideProps = {
        '@all': {
            readOnly: true
        },
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            isReadOnly: true,
            labelPosition: 'left',
            showRequired: true,
            // isRequiredForIssuance
        },
        agencyAndProducerInfo: {
            model: submissionVM.baseData,
            onAgencyChange: handleAgencyChange,
            displayFields: {
                agencyOfRecord: false,
                licensedAgent: true,
                servicingAgent: true
            },
            readOnlyFields: {
                agencyOfRecordReadOnly: true
            },
            producerCodePath: 'producerCode_Ext',
            // shouldSetExternalAgencyVal: true,
            // shouldUpdateAgentOptions: !isSkipping,
            onValidate
        },
        primaryNamedInsured: {
            value: getPrimaryNamedInsuredAndDisplay(),
        },
        insuredLivedLocationToggle: {
            visible: namedInsuredOccupancy === 'owneroccupiedprimary_Ext' || namedInsuredOccupancy === 'owneroccupiedseasonal_Ext',
        },
        vacationRentalsType: {
            visible: namedInsuredOccupancy === 'tenantoccupiedprimary_Ext' || namedInsuredOccupancy === 'tenantoccupiedseasonal_Ext'
        },
        dPLeaseAgreement: {
            visible: namedInsuredOccupancy === 'tenantoccupiedprimary_Ext' ||  namedInsuredOccupancy ===  'tenantoccupiedseasonal_Ext'
        },
        dPLeaseAgreementExplain: {
            required: true,
            visible: (dpleaseAgreement === 'lessthansixmonths' || dpleaseAgreement === 'morethanthreetenants') && (namedInsuredOccupancy === 'tenantoccupiedprimary_Ext' ||  namedInsuredOccupancy ===  'tenantoccupiedseasonal_Ext')
        },
        // isAnyPortionOfThePropertyToggle: {
        //     defaultValue: false,
        //     value: isPropertyRentedToOthers,
        // },
        dwellingLocation: {
            value: getResidenceLocaltionAndDisplay(),
        },
        residenceQuestionDropdown: {
            // availableValues: getResidenceQuestionDropdown(),
            visible: shouldShowResidenceQuestionDropdown()
        },
        generalContractorToggle: {
            visible: shouldShowGeneralContractorToggle()
        },
        constructionToggle: {
            visible: shouldShowConstructionToggle()
        },
        estimatedCompletionDateDropdown: {
            visible: shouldShowEstimatedCompletionDateDropdown(),
        },
        householdResidentsTable: {
            data: householdResidentsTableData,
        },
        relationshipToInsuredColumn: {
            path: 'relationshipToInsured_Ext',
            renderCell: renderRelationshipToInsured,
        },
        additionalNamedInsuredColumn: {
            path: 'subType',
            renderCell: renderAdditionalNamedInsured,
        },
        actionColumn: {
            renderCell: renderHouseholdTableAction,
        },
        primaryNamedInsuredEditIcon: {
            visible: false,
        },
        dwellingLocationEditIcon: {
            visible: false,
        },
        effectiveDate: {
            minDate: LocalDateUtil.today(),
            value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
            //  showErrors
        },
        policyStateDropdown: {
            visible: isHouseholdInfoFirstEnter && isBaseStateInMutualState(),
        },
        policyStateInput: {
            visible: !(isHouseholdInfoFirstEnter && isBaseStateInMutualState()),
        },
        totalNumberofHouseholdResidents: {
            required: true,
            minValue: _.get(householdResidentsTableData, 'length', 0)
        },
        addHouseholdResident: {
            disabled: true
        },
        UWQSelectAllAppliesMultiSelect: {
            // availableValues: getMultipleSelectOptions(),
            visible: !!_.get(submissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.applicantExistPolicy_Ext'),
        },
        UWQApplicantExistPolicyToggle: {
            label: translator(messages.isDpThereExistingPolicy, {
                companyName: domainName
            }),
        },
        policyUnderwritingQuestionSets: {
            // isRequiredForIssuance: isRequiredForIssuance,
            contentFilter: questionSetFilter,
            contentMapper: questionSetMapper,
            // onValidate,
            // getInvalidAnswerKeys: questionSetGetInvalidAnswerKeys,
        }
    };
    //---------------------
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            // validationissuescomponent: ValidationIssuesComponent,
            // agencyServiceinfo: AgencyOfServiceComponent,
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            // policyholderuwquestion: AQPolicyHolderUWQuestionComponent,
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            //  onNext={isComponentValid ? onPageNext : handleValidation}
            showPrevious={false}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            //
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                // showErrors={showErrors}
            />
        </WizardPage>
    );
}

DPHouseholdInfoReadOnlyPage.propTypes = {
    ...wizardProps,
    saveHoHouseholdInfo: PropTypes.func,
    // householdInfoPageOnInit: PropTypes.func,
    isOpenPNIPopupPageInit: PropTypes.bool,
    checkRequiredForIssuance: PropTypes.bool,
        /**
     * Controls the visibility of fields on the page.
     *
     * Default to 'false' to see all fields
     */
        readOnlyFields: PropTypes.shape({
            effectiveDate: PropTypes.bool,
            walTermTypedropdownselect: PropTypes.bool,
            walBaseState: PropTypes.bool,
            agencyOfRecord: PropTypes.bool,
            uwqReadonly: PropTypes.bool
        })
};

DPHouseholdInfoReadOnlyPage.defaultProps = {
    saveDpHouseholdInfo: DPHouseholdInfoService.saveDpHouseholdInfoData,
    // householdInfoPageOnInit: undefined,
    isOpenPNIPopupPageInit: false,
    checkRequiredForIssuance: true,
    readOnlyFields: {
        effectiveDate: false,
        walTermTypedropdownselect: false,
        walBaseState: false,
        agencyOfRecord: false,
        uwqReadonly: false
    }
};

export default DPHouseholdInfoReadOnlyPage;
