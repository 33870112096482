import React, {
    Component,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/uiconfig';
import { withValidation, validationPropTypes, useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
// import { usePrevious } from 'wni-portals-util-react';
import { ClauseMetadataUtil } from 'wni-portals-util-js';
import BaseClauseComponent from './BaseClauseComponent';
import messages from './BaseClausesComponentVM.messages';

function isClauseLoading(clause, loadingClause) {
    if (_.isArray(loadingClause)) {
        return loadingClause.includes(clause.publicID)
    }
    if (_.isString(loadingClause)) {
        return loadingClause === clause.publicID;
    }
    if (_.isBoolean(loadingClause)) {
        return loadingClause;
    }
    return false;
}

function getPath(changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    return pathToNormalise;
}

function defaultGetRelativePath(changedValuePath) {
    const [relativePath] = changedValuePath.match(/(selected|terms\.children\[\d+\](\.\w+)+)/);

    return relativePath.replace(/\.children/g, '');
}

/**
 * Component hierarchy diagram:
 * BaseClausesComponentVM ->  BaseSingleClauseComponentVM -> BaseClauseComponent
 * @param {object} props
 * @returns {object}
 */
function BaseSingleClauseComponentVM(props) {
    const {
        //
        value: clause = {},
        path,
        //
        onValidate,
        showErrors,
        //
        idPrefix,
        containerClassName,
        description,
        //
        labelPosition,
        labelTop,
        //
        showAmount,
        showSchedule,
        //
        loadingClause,
        loadingClauseMessage,
        loadingIconType,
        //
        isEditable,
        isDisabled,
        //
        onScheduleChange,
        onChangeSubmissionAndSync,
        onChangeClause,
        onSyncCoverages,
        // ============================
        getSingleClauseMetadata,
        getTermMetaData: getTermMetadataParam,
        getScheduleMetaData: getScheduleMetaDataParam,
        componentMapOverrides,
        callbackMapOverrides,
    } = props;
    const {
        // initialValidation,
        isComponentValid,
        // disregardFieldValidation,
        onValidate: setComponentValidation, // from withValidation()
        registerComponentValidation,
    } = useValidation(clause.publicID);
    const translator = useTranslator();
    // const oldClause = usePrevious(clause);
    const validationMessagesRef = useRef();

    const {
        getRelativePath = defaultGetRelativePath
    } = props;

    function defaultGetTermMetaData(clauseTerms, clausePublicID) {
        return ClauseMetadataUtil.getTermMetadata({
            clauseTerms,
            clausePublicID,
            isEditable,
            path,
            idPrefix,
            isDisabled,
            labelPosition,
            labelTop,
            translator,
            messages
        });
    }

    function defaultGetScheduleMetadata(clauseSchedule, clausePublicID) {
        return ClauseMetadataUtil.getScheduleMetadata({
            clauseSchedule,
            clausePublicID,
            idPrefix,
            path,
            showSchedule,
            labelPosition,
            isEditable,
        });
    }

    const getTermMetaData = getTermMetadataParam || defaultGetTermMetaData;
    const getScheduleMetadata = getScheduleMetaDataParam || defaultGetScheduleMetadata;

    /**
     * Generate clauses sub element metadata from clause
     * @param {object} newClause the clause to get data from
     * @returns {object | null} the metadata to render
     */
    const getClauseMetadata = (newClause) => {
        const terms = !_.isEmpty(newClause.terms)
            ? getTermMetaData(newClause.terms, newClause.publicID) : [];
        const schedules = _.has(newClause, 'schedule')
            ? getScheduleMetadata(newClause.schedule, newClause.publicID) : [];
        const clauseSubElements = _.concat([], terms, schedules);

        return !_.isEmpty(clauseSubElements) ? [{
            id: `${idPrefix}ClausetElementContainer_[${newClause.publicID}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clause_element_container' },
            content: clauseSubElements
        }] : null;
    };

    /**
     * Generate metadata from value provided from props
     * @returns {object} the metadata to render
     */
    const generateMetadata = () => {
        const isTooltipVisible = (
            description || (clause.description !== clause.name && !_.isEmpty(clause.description))
        );

        const coverageTermsCount = _.get(clause, 'terms.length', 0);
        const displayName = isEditable ? clause.name : description || clause.description;

        const isLimitRequired = _.get(clause, 'terms', []).some((term) => term.required);

        // let content = null;
        const clauseComponentProps = {
            id: `${idPrefix}_${clause.publicID}`,
            displayName: displayName,
            showAmount,
            amount: clause.amount,
            path: `${path}.selected`,
            readOnly: clause.required || clause.readOnly_Ext,
            required: clause.required,
            value: clause.selected,
            checked: clause.selected,
            onValueChange: 'onChangeAndSyncClause',
            isEditable: isEditable,
            isDisabled: isDisabled,
            isLoading: isClauseLoading(clause, loadingClause),
            loadingMessage: loadingClauseMessage,
            containerClassName: `${containerClassName} clause-ootbcoverage-container`,
            labelPosition: labelPosition,
            description: isTooltipVisible ? description || clause.description : undefined,
            coverageTermsCount: coverageTermsCount,
            isLimitRequired: isLimitRequired,
            loadingIconType,
            clausePatternCode: clause.code_Ext,
        };

        if (_.isFunction(getSingleClauseMetadata)) {
            return getSingleClauseMetadata({
                clauseComponentProps,
                getClauseMetadata,
                getTermMetaData,
                getScheduleMetadata,
            });
        }

        return {
            content: [{
                id: `${idPrefix}Clause_[${clause.publicID}]`,
                type: 'field',
                component: 'BaseClauseComponent',
                componentProps: clauseComponentProps,
                content: clause.selected ? getClauseMetadata(clause) : null
            }]
        };
    };

    // =============================================
    const singleClauseComponentMetaData = generateMetadata();

    const isMetadataValid = () => {
        // const metadata = generateMetadata();
        // this is wrong
        // const retval = <MetadataForm uiProps={singleClauseComponentMetaData} />;
        
        // return retval;
        return _.isEmpty(validationMessagesRef.current);
    };
    
    useEffect(() => {
        registerComponentValidation(isMetadataValid);
    }, [clause]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, clause.publicID);
        }
    }, [clause, isComponentValid, onValidate]);

    const onValidationChange = (isValid, fullPath, message) => {
        const newMessages = { ...validationMessagesRef.current };
        if (isValid) {
            delete newMessages[fullPath];
        } else {
            newMessages[fullPath] = [message];
        }
        validationMessagesRef.current={...newMessages};
    };

    /**
     * Changes value and calls backend if needed
     * @param {object} value the new value
     * @param {string} changedValuePath the path to change
     * @param {boolean} updateWithLoader flag of show loader to coverages when update schedule
     * @returns {Promise}
     */
    const handleScheduleChange = (value, changedValuePath, updateWithLoader = true) => {
        if (onScheduleChange) {
            return Promise.resolve(onScheduleChange(value, changedValuePath, updateWithLoader));
        }
        return Promise.resolve();
    };

    /**
     * Changes value and calls backend if needed
     * @param {object} value the new value
     * @param {string} changedValuePath the path to change
     * @returns {Promise}
     */
    const handleChangeAndSyncClause = (value, changedValuePath) => {
        if (_.get(clause, getRelativePath(changedValuePath)) !== value) {
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    /**
     * Changes value
     * @param {object} value the new value
     * @param {string} changedValuePath the path to change
     * @returns {Promise}
     */
    const handleChangeClause = (value, changedValuePath) => {
        const beforeValue = _.get(clause, getRelativePath(changedValuePath))
        const newValue = value
        const isCurrency = !_.isNil(_.get(newValue, 'currency'))
        const isValueChange = isCurrency
            ? beforeValue !== _.get(newValue, 'amount')
            : (beforeValue !== newValue)
        if (isValueChange) {
            if (onChangeClause) {
                return Promise.resolve(onChangeClause(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    /**
     * Calls backend if needed
     * @param {object} evt event
     * @param {string} changedValues the path to change
     * @returns {Promise}
     */
    const handleSyncCoverages = (evt, changedValues) => {
        const { beforeValue, value: newValue, model } = changedValues;
        const actualChangedPath = getPath(model);

        const isCurrency = !_.isNil(_.get(newValue, 'currency'))

        const isValueChange = isCurrency
            ? (_.get(beforeValue, 'currency') !== _.get(newValue, 'currency')
                || _.get(beforeValue, 'amount') !== _.get(newValue, 'amount'))
            : (beforeValue !== newValue)

        if (isValueChange) {
            if (onSyncCoverages) {
                return Promise.resolve(onSyncCoverages(newValue, actualChangedPath));
            }
        }
        return Promise.resolve();
    };

    // ============================================
    const render = () => {
        const resolvers = {
            resolveCallbackMap: {
                onValueChange: handleChangeAndSyncClause,
                onChangeAndSyncClause: handleChangeAndSyncClause,
                onChangeClause: handleChangeClause,
                onSyncCoverages: handleSyncCoverages,
                onScheduleChange: handleScheduleChange,
                ...callbackMapOverrides,
            },
            resolveComponentMap: {
                ...componentMapOverrides
            },
        };

        const overrides = {
            '@field': {
                showOptional: false,
                showRequired: false,
                showErrors: showErrors,
                onValidationChange,
            }
        };

        return (
            <MetadataContent
                uiProps={singleClauseComponentMetaData}
                overrideProps={overrides}
                {...resolvers} />
        );
    };

    return render();
}


/**
 * @memberof gw-components-platform-react.SingleClauseComponentVM
 * @prop {Object} propTypes - the props that are passed to this component
 * @prop {string} propTypes.path - path to clause in the view modal
 * @prop {object} propTypes.value - the clause
 * @prop {function} propTypes.onChangeSubmissionAndSync - callback when change is made
 * @prop {function} propTypes.onChangeClause - callback when change is made
 * @prop {function} propTypes.onSyncCoverages - callback to check if clause should call backend
 * @prop {string} propTypes.loadingClause - the clause publicID that is loading
 * @prop {string} propTypes.loadingClauseMessage - loading message to be shown while loading
 * @prop {string} propTypes.isEditable - if the clauses should not be editable
 * @prop {string} propTypes.labelTop - if the clause term label should be on the top
 * @prop {string} propTypes.containerClassName - clause container class
 * @prop {string} propTypes.idPrefix - string to prefix all the ID's
 * @prop {string} propTypes.description - clause description
 * @prop {string} propTypes.onValidate - returns if the values are valid
 * @prop {bool} propTypes.showAmount - determine to show amount next to clause name
 * @prop {string} propTypes.isDisabled - if the clauses term should be disabled
 */

BaseSingleClauseComponentVM.propTypes = {
    path: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    onChangeSubmissionAndSync: PropTypes.func,
    onChangeClause: PropTypes.func,
    onSyncCoverages: PropTypes.func,
    onScheduleChange: PropTypes.func,
    onValidate: PropTypes.func,
    loadingClause: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    loadingClauseMessage: PropTypes.string,
    isEditable: PropTypes.bool,
    labelTop: PropTypes.bool,
    containerClassName: PropTypes.string,
    idPrefix: PropTypes.string,
    labelPosition: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showAmount: PropTypes.bool,
    showSchedule: PropTypes.bool,
    isDisabled: PropTypes.bool,
    loadingIconType: PropTypes.string,
    // ==================
    /**
     * (Optional): Function: (getSingleClauseMetadata) => metadataConfigJSON
     */
    getSingleClauseMetadata: PropTypes.func,
    /**
     * (Optional): Function: (terms, clusePublicID) => termsMetadataConfigJSON
     */
    getTermMetaData: PropTypes.func,
    componentMapOverrides: PropTypes.shape({}),
    // overrideFunctionMap: PropTypes.shape({}),
    // ...validationPropTypes
};

BaseSingleClauseComponentVM.defaultProps = {
    onChangeSubmissionAndSync: undefined,
    onChangeClause: undefined,
    onSyncCoverages: undefined,
    onScheduleChange: undefined,
    loadingClause: undefined,
    loadingClauseMessage: '',
    onValidate: undefined,
    isEditable: true,
    labelTop: false,
    containerClassName: undefined,
    idPrefix: '',
    labelPosition: 'left',
    description: undefined,
    showAmount: true,
    showSchedule: true,
    isDisabled: false,
    loadingIconType: undefined,
    //
    getSingleClauseMetadata: undefined,
    componentMapOverrides: {
        BaseClauseComponent: BaseClauseComponent
    },
    // overrideFunctionMap: {},
    getTermMetaData: undefined,
};


export const BaseSingleClauseVM = BaseSingleClauseComponentVM;
// export default withValidation(SingleClauseComponentVM);
export default BaseSingleClauseComponentVM;
