import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WniDateUtil,
    WindowUtil,
    WniPNIUtil
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
} from 'wni-capability-gateway-react';
import { WizardConstants } from 'wni-portals-config-js';
import  { WALPolicyInfoService } from 'wni-capability-quoteandbind-wal';
import { useWniModal } from 'wni-components-platform-react';
import { useProductsData } from 'wni-portals-util-react';
import WizardPage from '../../templates/WALWizardPage';

import styles from './WALPolicyDetailsPage.module.scss';
import metadata from './WALPolicyDetailsPage.metadata.json5';
import messages from './WALPolicyDetailsPage.messages';
import walQuestionSetsUtil from './utils/WALQuestionSetsUtil'

const availableWALTermTypeCodes = ['Annual'];
function WALPolicyDetailsPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        // updateWizardSnapshot,
        // updateWizardReadOnly,
        wizardSnapshot,
        // history,
        // goNext
        // errorsForStep,
        // stepsWithErrors,
        // underwritingIssues,
        // acknowledgeError,
        // reportErrors,
        // wizardStepToFieldMapping,
        // markFollowingWizardStepsUnvisited,
        // jumpTo: wizardJumpTo,
        // steps: wizardSteps,
        wizardPageData,
        updateWizardPageData,
        maximumEffectiveDate,
        //
        savePolicyInfoData,
        linePath,
        isOpenPNIPopupPageInit,
        readOnlyFields: {
            effectiveDate = false,
            walTermTypedropdownselect = false,
            walBaseState = false,
            agencyOfRecord = false,
            uwqReadonly = false
        },
        setEffectiveDateForChangeTransaction = false,
    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
        baseData: {
            productCode,
            baseState_Ext: baseState,
            quoteFlow_Ext: quoteFlow,
            policyInfoSource_Ext: policyInfoSource,
            primaryNamedInsured_Ext: primaryNamedInsured,
            effectiveDate_Ext: transactionEffectiveDate
        },
        // lobData: {
        //     watercraft: {
        //         policyInfoDTO: {
        //             applicantExistPolicy_Ext: UWQApplicantExistPolicy,
        //         }
        //     }
        // },
        // bindData,
        // quoteData,
    } = submissionVM.value;
    
    const translator = useTranslator();
    const { authHeader, authUserData: { businessData_Ext: { systemDate } = {} } = {}} = useAuthentication();
    const { getProductItem, getProductName, getProductMinDate, getProductStartEffectiveDate } = useProductsData();
    // const userPublicID = authUserData && authUserData.publicID;
    // const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const { domainCompany: { domainName}, loadingMask: { setLoadingMask } } = useDependencies(['domainCompany', 'loadingMask']);
    const viewModelService = useContext(ViewModelServiceContext);
    // const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('WALPolicyDetailsPage');
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    // const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const haveShownPNIPopup = wizardPageData[WizardConstants.haveShownPNIPopup];

    const getMinDate = useCallback(() => {
        if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
            return moment(systemDate).add(-5, 'd').startOf('day').toDate();
        }
        return getProductMinDate(productCode, baseState);
    }, [getProductMinDate, productCode, baseState]);

    const effectiveMinDate = useCallback(() => {
        if (setEffectiveDateForChangeTransaction) {
            return _.get(submissionVM, 'value.baseData.periodStartDate')
        }
        return getMinDate()
    }, [setEffectiveDateForChangeTransaction, getMinDate, submissionVM])

    const effectiveMaxDate = useCallback(() => {
        const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        if (setEffectiveDateForChangeTransaction) {
            const maxDate =  ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
                maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
            return maxDate
        }
        return undefined

    }, [submissionVM, setEffectiveDateForChangeTransaction, maximumEffectiveDate])
    
    const isEffecitiveDateValidForOOS = useCallback(() => {
        // return true when in policy change transaction and in slice
        // always return true when in NB transaction
        // return false when in policy change transaction and oos
        if (setEffectiveDateForChangeTransaction) {
            const oOSSliceDates = _.get(submissionVM.value, 'changeData.oossliceDates_Ext');
            let oOSStartDate = _.get(submissionVM, 'value.baseData.periodStartDate')
            let oOSEndDate = _.get(submissionVM, 'value.baseData.periodEndDate')
            const initialEffectiveDate = _.get(wizardSnapshot.value, 'baseData.effectiveDate_Ext')
            const oOSSliceDatesInMoment = oOSSliceDates.map((res) => {
                return moment(res)
            })
            const sortOOSSliceDatesInMoment = oOSSliceDatesInMoment.sort((a, b) => a.diff(b))
            const oOSEndDateIndex = sortOOSSliceDatesInMoment.findIndex((res) => res.isAfter(moment(initialEffectiveDate)))
            if (oOSEndDateIndex !==-1 ) { 
                oOSEndDate = sortOOSSliceDatesInMoment[oOSEndDateIndex] 
                if (oOSEndDateIndex > 0 ) { 
                oOSStartDate = sortOOSSliceDatesInMoment[oOSEndDateIndex-1]
                }
            }
            if (oOSEndDateIndex ===-1 && (sortOOSSliceDatesInMoment.length>0 )) {
                oOSStartDate = sortOOSSliceDatesInMoment[sortOOSSliceDatesInMoment.length-1]
            }
            const isDateSameOrAfterSliceStart =  moment(transactionEffectiveDate).isSameOrAfter(oOSStartDate)
            const isDateBeforeSliceEnd =  moment(transactionEffectiveDate).isBefore(oOSEndDate)
            return isDateSameOrAfterSliceStart && isDateBeforeSliceEnd
        }
        return true
    }, [setEffectiveDateForChangeTransaction, submissionVM, transactionEffectiveDate, wizardSnapshot.value]);

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = effectiveMinDate();
        const maxDate = effectiveMaxDate()
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();
        const maxDif = (maxDate === undefined ? 1 : moment(maxDate).valueOf() - moment(baseEffectiveDate).valueOf())
        if (setEffectiveDateForChangeTransaction) {
            return isEffecitiveDateValidForOOS()
        }
        return ((minDif >= 0) && (maxDif >= 0))
    }, [submissionVM.value, effectiveMinDate, effectiveMaxDate, setEffectiveDateForChangeTransaction, isEffecitiveDateValidForOOS]);

    const WALPolicyDetailsValidation = useCallback(() => {
        return isEffectiveDateValid()
            && WniPNIUtil.isPNIValid(primaryNamedInsured);
        // && isAgencyAndAgentInfoValid()
    }, [isEffectiveDateValid, primaryNamedInsured]);

    useEffect(() => {
        registerComponentValidation(WALPolicyDetailsValidation);
    }, [registerComponentValidation, primaryNamedInsured, transactionEffectiveDate]);

    const getEffectiveDateFieldOverrideProps = () => {
        const prodDate = getProductStartEffectiveDate(productCode, baseState);
        const currentDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const isProdDateBeforeSysDate = moment(prodDate).valueOf() - moment(systemDate).valueOf() < 0
        let shouldShowNewValidation = false
        if (!isProdDateBeforeSysDate) {
            // current<prod show
            if(moment(currentDate).valueOf() - moment(prodDate).valueOf() < 0) {
                shouldShowNewValidation = true
            }
        }
        const effectiveDateFieldOverrideProps = {
            minDate: effectiveMinDate(),
            maxDate: effectiveMaxDate(),
            value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
            // onValueChange: onStartDateChange,
            showErrors: showErrors || shouldShowNewValidation,
            readOnly: effectiveDate
        };
        if (shouldShowNewValidation) {
            _.set(effectiveDateFieldOverrideProps, 'validationMessages',
                [translator(messages.effectiveDateValidation, {
                    productEffectiveDate: WniDateUtil.formatDateWithPattern(effectiveMinDate(), 'MM/DD/YYYY'),
                })]);
        }
        return  effectiveDateFieldOverrideProps;
    };

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                showEmployeeFlagToggle: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        // }, [viewModelService, authHeader, isCopiedSubmission]
        }, [modalApi]
    );

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                const newAddress = _.get(updatedVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                if (accountHolder.publicID === _.get(updatedVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.value);
                }
                updateSubmissionVM(submissionVM);
            }).catch(() => _.noop());
    }, [showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    useEffect(() => {
        if (isOpenPNIPopupPageInit && !haveShownPNIPopup) {
            openEditPrimaryNamedInsuredPopup();
            updateWizardPageData({ [WizardConstants.haveShownPNIPopup]: true })
        }
    }, [isOpenPNIPopupPageInit, haveShownPNIPopup]);

    const questionSetMapper = walQuestionSetsUtil.getQSMapperForWALPolicyChangePolicyDetails(uwqReadonly)

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.firstName.value');
        const lastName = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.lastName.value');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
            const newUWQApplicantExistPolicy = _.get(submissionVM.value, `lobData.${linePath}.policyInfoDTO.applicantExistPolicy_Ext`)
            if (path === `lobData.${linePath}.policyInfoDTO.applicantExistPolicy_Ext` && newUWQApplicantExistPolicy === false) {
                _.set(newSubmissionVM.value, `lobData.${linePath}.policyInfoDTO.selectAllApplies_Ext`, [])
                updateSubmissionVM(newSubmissionVM);
            }
        },
        [submissionVM, updateSubmissionVM, linePath]
    );


    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const staticInfoMessages = [{
        type: 'requiredInfo',
        reason: translator(messages.policyinfoNotificaion, {
            requiredNotification: <div>{translator(messages.requiredNotification, {
                asteriskIcon: <span className="font-error-bold-16 algin-text-top">*</span>,
            })}</div>
        })
    }];

    useEffect(() => {
        // clear errorsAndWarnings, workaround
        _.set(submissionVM, 'errorsAndWarnings', []);
        // const newValidationIssues = ResidenceUtil.updateBRValidationIssues(submissionVM, validationIssues, translator, linePath);
        updateValidationIssues(staticInfoMessages);
    }, []);

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const preQualQuestionSets = _.get(submissionVM.value, `lobData.${linePath}.preQualQuestionSets`)
        const quoteBaseData = _.get(submissionVM.value, 'baseData')
        const policyInfoData = _.get(submissionVM.value, `lobData.${linePath}.policyInfoDTO`)
        //
        // const res = await WALPolicyInfoService
        //     .saveWALPolicyInfoData(
        const res = await savePolicyInfoData(
            quoteID, 
            sessionUUID,
            preQualQuestionSets,
            quoteBaseData,
            policyInfoData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);
    
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const oldValidationIssues = _.uniqBy(validationIssues, 'reason')
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        const hasNewValidationIssue = ValidationIssueUtil.hasNewValidationIssue(oldValidationIssues, newValidationIssues)

        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && hasNewValidationIssue) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }

        return submissionVM;
    }, [authHeader, quoteID, sessionUUID, setLoadingMask, submissionVM, updateSubmissionVM, displayWarnings]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const renderPrimaryNamedInsuredValue = () => {
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))}</div>
            </div>
        )
    }

    const getMultipleSelectOptions = () => {
        const options =  _.get(submissionVM.value, `lobData.${linePath}.policyInfoDTO.selectAllAppliesOptions_Ext`)
        return options.map((res) => {
            const displayName = getProductName(res);
            if (displayName) {
                return {
                    code: res,
                    name: displayName
                }
            } 
            return {
                code: res,
                name: translator({ id: res })
            } 
        })
    }

    const getAvailableTermType = () => {
        return  availableWALTermTypeCodes.map((code) => {
            return {
                code: code,
                name: translator({
                    id: `typekey.TermType.${code}`,
                    defaultMessage: `typekey.TermType.${code}`
                })
            };
        });
    }

    const productAvailableStates = (code) => {
        const productItem = getProductItem(code);
        const { availableStates = [] } = productItem;
        return availableStates.map((a) => a.state);
    };
    const getPolicyAvailableStates = () => {
        const policyStates = _.get(submissionVM, `baseData.baseState_Ext.aspects.availableValues`);
        const availablePolicyStates = policyStates.filter((item) => productAvailableStates(productCode).includes(item.code));
        return availablePolicyStates.map((state) => {
            return {
                code: state.code,
                name: translator({ id: state.name })
            }
        })
    };

    useEffect(() => {
        const OOSFlag = isEffecitiveDateValidForOOS()
        const OOSValidationMsg = {
            type: 'error',
            reason: translator(messages.outOfSliceDate, {
                effectiveDate: WniDateUtil.formatDateWithPattern(transactionEffectiveDate)
            }),
            flag: 'OOSErrorMsg'
        }
        if (!OOSFlag && setEffectiveDateForChangeTransaction) {
            updateValidationIssues((prev) => {
                const validationFilter = prev.filter((item) => item.flag !=='OOSErrorMsg');
                return [
                    ...validationFilter,
                    OOSValidationMsg
                ]
            })
        }else {
            updateValidationIssues((prev) => {
                return  prev.filter((item) => item.flag !=='OOSErrorMsg');
            }) 
        }
    }, [isEffecitiveDateValidForOOS, setEffectiveDateForChangeTransaction, submissionVM.value, transactionEffectiveDate, translator])


    const generateOverrides = useCallback(() => {
        const overrides = {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            effectiveDate: {
                ...getEffectiveDateFieldOverrideProps()
            },
            // effectiveDate: {
            //     minDate: effectiveMinDate(),
            //     maxDate: effectiveMaxDate(),
            //     value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
            //     showErrors,
            //     readOnly: effectiveDate
            // },
            walTermTypedropdownselect: {
                // availableValues: getAvailableTermType(),
                readOnly: walTermTypedropdownselect
            },
            walBaseState: {
                availableValues: getPolicyAvailableStates(),
                readOnly: walBaseState
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: true,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: agencyOfRecord
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate
            },
            primaryNamedInsuredField: {
                value: renderPrimaryNamedInsuredValue()
            },
            primaryNamedInsuredEditIcon: {
                onClick: openEditPrimaryNamedInsuredPopup,
            },
            policyUnderwritingQuestionSets: {
                contentMapper: questionSetMapper,
                path: `lobData.${linePath}.preQualQuestionSets.children[0].value`,
            },
            UWQSelectAllAppliesMultiSelect: {
                availableValues: getMultipleSelectOptions(),
                visible: _.get(submissionVM.value, `lobData.${linePath}.policyInfoDTO.applicantExistPolicy_Ext`),
                path: `lobData.${linePath}.policyInfoDTO.selectAllApplies_Ext`
            },
            UWQApplicantExistPolicyToggle: {
                label: translator(messages.isTWThereExistingPolicy, {
                    companyName: domainName
                }),
                path: `lobData.${linePath}.policyInfoDTO.applicantExistPolicy_Ext`,
            },
        };
        // set walTermTypedropdownselect.availableValues when walTermTypedropdownselect is not readOnly
        // and unset walTermTypedropdownselect.availableValues when walTermTypedropdownselect is readOnly
        if (!walTermTypedropdownselect) {
            _.set(overrides, 'walTermTypedropdownselect.availableValues', getAvailableTermType());
        } else {
            _.unset(overrides, 'walTermTypedropdownselect.availableValues');
        }
        return overrides;
    }, [submissionVM.value, submissionVM.baseData, showErrors, getAvailableTermType, getPolicyAvailableStates, handleAgencyChange, onValidate, renderPrimaryNamedInsuredValue, openEditPrimaryNamedInsuredPopup]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            questionset: QuestionSetComponent,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            onNext={isComponentValid ? onPageNext : handleValidation}
            showPrevious={false}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

WALPolicyDetailsPage.propTypes = {
    ...WizardPage.propTypes,
    //
    savePolicyInfoData: PropTypes.func,
    linePath: PropTypes.string,
    isOpenPNIPopupPageInit: PropTypes.bool,
    /**
     * Controls the visibility of fields on the page.
     *
     * Default to 'false' to see all fields
     */
    readOnlyFields: PropTypes.shape({
        effectiveDate: PropTypes.bool,
        walTermTypedropdownselect: PropTypes.bool,
        walBaseState: PropTypes.bool,
        agencyOfRecord: PropTypes.bool,
        uwqReadonly: PropTypes.bool
    })
};

WALPolicyDetailsPage.defaultProps = {
    ...WizardPage.defaultProps,
    //
    savePolicyInfoData: WALPolicyInfoService.saveWALPolicyInfoData,
    linePath: 'watercraft',
    isOpenPNIPopupPageInit: false,
    readOnlyFields: {
        effectiveDate: false,
        walTermTypedropdownselect: false,
        walBaseState: false,
        agencyOfRecord: false,
        uwqReadonly: false
    }
    
};
export default WALPolicyDetailsPage;
