import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import {
    DataTable,
    DisplayColumn
} from '@jutro/datatable';
import { BreakpointTrackerContext } from '@jutro/layout';
import { IntlContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateUtil } from 'wni-portals-util-js';
import metadata from './PaymentSchedule.metadata.json5';
import styles from './PaymentSchedule.module.scss';

function PaymentSchedule(props) {
    const {
        title,
        size,
        actionBtnLabel,
        isOpen,
        onResolve,
        paymentDetailVM
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const intl = useContext(IntlContext);

    const [getPaymentDetailVM, updatePaymentDetailVM] = useState(
        paymentDetailVM
    );
    const { isComponentValid, onValidate } = useValidation(
        'PaymentSchedule'
    );

    const writeValue = useCallback(
        (value, path) => {
            const newPaymentDetailVM = _.cloneDeep(getPaymentDetailVM);
            _.set(newPaymentDetailVM, path, value);
            updatePaymentDetailVM(newPaymentDetailVM);
        },
        [getPaymentDetailVM]
    );

    const handleSave = useCallback(() => {
        onResolve(getPaymentDetailVM);
    }, [onResolve, getPaymentDetailVM]);

    const getDate = useCallback((item) => {
        const date = _.get(item, 'data.dueDate');
        return date ? WniDateUtil.formatDateWithPattern(new Date(date)) : '';
    }, []);

    const getAmount = useCallback((item) => {
        let formattedAmount = '-';
        const amount = _.get(item, 'data.amount');
        if (!_.isNil(amount)) {
            const currency = _.get(item, 'data.currency', 'USD');
            formattedAmount = intl.formatNumber(_.get(amount, 'amount'), {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'symbol' // 'code'
            });
        }
        return formattedAmount;
    }, []);

    const renderExpanderContent = (data) => {
        return (
            <DataTable
                id="paymentScheduleChildTable"
                titlePosition="left"
                expandable={false}
                showSearch={false}
                showPagination={false}
                data={data.children}
            >
                <DisplayColumn
                    renderCell={getDate}
                    headerClassName={styles.displayNone}
                    columnProportion={1}
                    sortable={false}
                    id="dueDate"
                    textAlign="left"
                />
                <DisplayColumn
                    renderCell={getDate}
                    headerClassName={styles.displayNone}
                    columnProportion={1}
                    sortable={false}
                    id="billDate"
                    textAlign="left"
                />
                <DisplayColumn
                    renderCell={(row) => row.data.type}
                    headerClassName={styles.displayNone}
                    columnProportion={1}
                    id="payment"
                    textAlign="left"
                />
                <DisplayColumn
                    renderCell={(row) => row.data.chargeCategory}
                    headerClassName={styles.displayNone}
                    columnProportion={1}
                    id="chargeCategory"
                    textAlign="left"
                />
                <DisplayColumn
                    renderCell={getAmount}
                    headerClassName={styles.displayNone}
                    columnProportion={2}
                    id="category"
                    textAlign="right"
                    cellClassName={styles.textRight}
                />
            </DataTable>
        );
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showRequired: true,
        },
        paymentScheduleTable: {
            data: _.get(getPaymentDetailVM, 'treeNode_Ext.children'),
            renderExpanderContent: renderExpanderContent,
        },
        chargesForSubmissionSection: {
            cellClassName: styles.textRight
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getDate,
            getAmount
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} onClose={onResolve} />
            <ModalBody id="paymentSchedulePanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={getPaymentDetailVM}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleSave} disabled={!isComponentValid}>
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PaymentSchedule.propTypes = {
    paymentDetailVM: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
    }).isRequired,
};
PaymentSchedule.defaultProps = {
    size: 'lg',
};
export default PaymentSchedule;
