import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import { Button } from '@jutro/components';
import { useProductsData } from 'wni-portals-util-react';
// import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { FieldLinkComponent } from 'gw-components-platform-react';
import metadata from './ExistingAccountMatchTable.metadata.json5';
import messages from './ExistingAccountMatchPage.messages';

function ExistingAccountMatchTable(props) {
    const {
        data = [],
        onStartNewQuote,
        accountType,
        history,
        /* sidebyside page
        *  isSXS
        * onConfirmMerge
        */
        isSXS = false,
        onConfirmMerge,
        isClassicalWorkflow,
        //
        productsData,
    } = props;
    const translator = useTranslator();
    const { getProductName} = productsData || useProductsData();
    const toAccountSummary = (item) => {
        const { accountNumber, accountHolder: { contactType_Ext: accountTypeItem} = {} } = item;
        if(accountTypeItem === 'person') { // PL
            history.push({
                pathname: `/account-quotes/${accountNumber}`,
                state: {
                    locked: false,
                    submissionWizardExitPage: 1
                }
            });
            return false;
        }

        // CL
        history.push(`/accounts/${accountNumber}/summary`);
    };
    const startNewQuoteLink = (item, index, label, type) => {
        const { accountNumber, isLegacyAccount, accountHolder: { contactType_Ext: accountTypeItem} = {} } = item;
        if(isLegacyAccount) {
            return null;
        }
        return (
            <Button
                id={`button${index}`}
                size="small"
                type={type}
                className={type==='text' && 'wni-button-link'}
                onClick={() => onStartNewQuote(accountNumber, accountTypeItem)}
            >
                {translator(label)}
            </Button>
        );
    };
    const getAccountLink = (item) => {
        return (
            <FieldLinkComponent
                id={`account${item.accountNumber}`}
                value={item.accountNumber}
                onClick={() => toAccountSummary(item)}
            />
        );
    };
    const mergeAccountLink = (item, index) => {
        const value = translator(messages.AddToThisAccount);
        const { accountNumber } = item;
        return (
            <Button
                id={`button${index}`}
                className="pv-5"
                onClick={() => onConfirmMerge(accountNumber)}
            >
                {value}
            </Button>
        );
    };


    const getMatchColumnLink = (item, index, { path }, type) => {
        const matchingMaps = item[path].split('\r\n');
        return (
            <ul>
                {
                    matchingMaps.map((jobNumber) => {
                        return (
                            <li title={jobNumber}>
                                <FieldLinkComponent
                                    id={`${type}${jobNumber}`}
                                    value={jobNumber}
                                    to={`/${type}/${jobNumber}/summary`}
                                />
                            </li>
                        );
                    })
                }
            </ul>
        );
    };

    const renderProductCell = (item) => {
        const {
            matchingQuotes = []
        } = item;
        const matchingProducts = matchingQuotes.map((product) => getProductName(product.productCode));
        const uniqProducts = _.uniq(matchingProducts);
        return uniqProducts.join('\r\n') || '-'
    };

    const resolvers = {
        resolveCallbackMap: {
            getAccountLink
        },
        resolveComponentMap:{
        }
    }
    const override = {
        classicalTableWrapper: {
            visible: isClassicalWorkflow || isSXS
        },
        useAccountColumn: {
            visible: !isSXS,
            renderCell: (item, index) => startNewQuoteLink(item, index, messages.startNewQuote, 'text')
        },
        R2TableWrapper: {
            visible: !isClassicalWorkflow && !isSXS
        },
        startNewQuotecolumn: {
            renderCell: (item, index) => startNewQuoteLink(item, index, messages.addQuoteToThisAccount, 'filled')
        },
        accountTable: {
            data: data,
            // visible: !_.isEmpty(data)
        },
        mergeAccountColumn: {
            visible: isSXS,
            renderCell: mergeAccountLink,
        },
        productColumn: {
            visible: accountType === 'company',
            renderCell: renderProductCell
        },
        statusColumn: {
            visible: accountType === 'company'
        },
        quoteNumberColumnTitle:{
            renderCell: (item, index, property) => getMatchColumnLink(item, index, property, 'quotes')
        },
        policyNumberColumnTitle: {
            renderCell: (item, index, property) => getMatchColumnLink(item, index, property, 'policies')
        }
    }
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={override}
            {...resolvers} />
    );
}

ExistingAccountMatchTable.propTypes = {
    isSXS: PropTypes.string,
    isClassicalWorkflow: PropTypes.bool,
    productsData: PropTypes.shape({}),
};
ExistingAccountMatchTable.defaultPropTypes = {
    isSXS: false,
    isClassicalWorkflow: false,
    productsData: PropTypes.shape({}),
};

export default withRouter(ExistingAccountMatchTable);