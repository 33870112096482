import React, { useState, useEffect, useCallback, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import _, { update } from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useWniModal } from "wni-components-platform-react";
import { WniGatewayBillingService } from 'wni-capability-gateway';
import { DocumentsUtil, WniDateUtil } from 'wni-portals-util-js';
import { Link } from '@jutro/components';
import metadata from './Statements.metadata.json5';
import messages from './Statements.messages';

const StatementsPage = (props) => {
    const { authHeader, authUserData: { producerCodes_Ext = [], canViewStatementScreen_Ext: canViewStatementScreen } = {} } =
        useAuthentication();
    const {
        interactionModel,
        loadingMask: { setLoadingMask },
    } = useDependencies(['interactionModel', 'loadingMask']);

    const { history } = props;

    const intl = useContext(IntlContext);
    const modalApi = useWniModal();

    const translator = useTranslator();
    const [agencyBillStatements, updateAgencyBillStatements] = useState([]);
    const [directBillStatements, updateDirectBillStatements] = useState([]);

    useEffect(() => {
        async function retrieveInitStatementsData() {
            setLoadingMask(true);
            const producerCodes = _.map(producerCodes_Ext, (obj) =>
                _.get(obj, 'code')
            );
            const statementCycles =
                await WniGatewayBillingService.getStatementCycles(
                    producerCodes,
                    authHeader
                );
            setLoadingMask(false);
            const agencyBillStatementsCycles = _.get(
                statementCycles,
                'agencyBillStatements',
                []
            );
            updateAgencyBillStatements(agencyBillStatementsCycles);
            const directBillStatementsCycles = _.get(
                statementCycles,
                'directBillStatements',
                []
            );
            updateDirectBillStatements(directBillStatementsCycles);
            return null;
        }
        if (canViewStatementScreen) {
            retrieveInitStatementsData();
        } else {
            modalApi.showAlert({
                title: messages.noPermissionToView,
                // message: messages.noPermissionToView,
                status: 'info',
                icon: 'gw-info-outline',
                confirmButtonText: messages.backToAllQuotes,
            }).catch(() => {
                _.noop();
            }).finally(() => {
                history.push("/")
            });
        }
    }, []);

    const getPremiumCell = useCallback((item, index, property) => {
        const value = item[_.get(property, 'path')];
        if (_.isEmpty(_.get(value, 'currency'))) {
            return '-'
        }
        return intl.formatNumber(
            _.get(value, 'amount'),
            {
                style: 'currency',
                currency: _.get(value, 'currency'),
                currencyDisplay: 'symbol',
                maximumFractionDigits: 2
            }
        )
    }, [intl]);

    const getCell = useCallback((item, index, property) => {
        const value = _.get(item, _.get(property, 'path'));
        return value;
    }, []);

    const getDate = useCallback((item, index, property) => {
        return WniDateUtil.formatDateWithPattern(
            item[property.path],
            'MM/DD/YYYY'
        );
    }, []);

    const getStatementCell = useCallback((item, index, property) => {
        const value = _.get(item, _.get(property, 'path'));
        const documentId = _.get(item, 'statementDocPublicId');
        if (!_.isEmpty(documentId)) {
            const documentProps = {
                onClick: async () => {
                  try {
                      setLoadingMask(true);
                      await DocumentsUtil.previewDocument(
                        documentId,
                        authHeader,
                        history,
                        'preview',
                        'wniBCDocumentRetrieval'
                      );
                    } finally {
                      setLoadingMask(false);
                    }
                },
            };
            return (
                <Link id={`policy${item.transactionNumber}`} {...documentProps}>
                    {value}
                </Link>
            );
        }
        return value;
    }, []);

    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            autoComplete: false,
        },
        agencyBillStatementsTableTitle: {
            content: translator(messages.agencyBillStatements),
        },
        commissionStatementsTableTitle: {
            content: translator(messages.commissionStatements),
        },
        agencyBillStatementsDataTable: {
            data: agencyBillStatements,
        },
        commissionStatementsDataTable: {
            data: directBillStatements,
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            getCell,
            getDate,
            getStatementCell,
            getPremiumCell
        },
        resolveComponentMap: {},
    };
    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
};

export default withRouter(StatementsPage);
