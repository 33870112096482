import {
    CurrencyField,
    DropdownSelectField,
    InputField,
    InputMaskField,
    InputNumberField,
    LookupField,
    TextAreaField,
    ToggleField,
    TypeaheadMultiSelectField,
    CheckboxField,
    CheckboxGroupField,
    TooltipIcon,
    RadioField
} from '@jutro/components';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '@jutro/layout';
import WniDateField from '../DateField/WniDateField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import MultiSelectNextField from '../MultiSelectField/MultiSelectNextField';

const ToggleDefaultAvailableValues = [
    {
        code: true,
        name: commonMessages.yesModel
    },
    {
        code: false,
        name: commonMessages.noModel
    }
];
const TooltipField = (FieldComponent, componentType) => {
    const components = (props) => {
        const {
            labelPosition,
            className,
            availableValues,
            requiredForIssuance,
            isRequiredForIssuance,
            tooltip,
            ineligibleTooptip
        } = props;

        const classNames = labelPosition === 'left' ? `jut__FieldComponent__left ${className}` : className;
        const componentProps = { ...props };
        _.set(componentProps, 'className', `${classNames} wniTooltipFieldsContainer`);
        _.set(componentProps, 'labelPosition', 'top');

        if (componentType === 'toggle' && _.isEmpty(availableValues)) {
            _.set(componentProps, 'availableValues', ToggleDefaultAvailableValues);
        }


        // for all required for issuance field
        if (requiredForIssuance && isRequiredForIssuance) {
            _.set(componentProps, 'required', isRequiredForIssuance);
            _.set(componentProps, 'showRequired', false);
        }

        const newComponentProps = _.omit(componentProps, [
            'componentType',
            'isRequiredForIssuance',
            'requiredForIssuance'
        ]);

        // for ineligible tooltip
        if(ineligibleTooptip && !_.isEmpty(ineligibleTooptip)) {
            const newClassNames = _.get(newComponentProps, 'className', '');
            if(componentType === 'multi') {
                _.set(newComponentProps, 'multiContainerClassName', 'flex-1')
            } else {
                _.set(newComponentProps, 'className', `${newClassNames} flex-1`)
            }
           
            return (
                <Flex className='wniKnockoutToolTip' gap="none" justifyContent='left'>
                    <FieldComponent {...newComponentProps} />
                    <TooltipIcon {...ineligibleTooptip} className='tooltipIcon'/>
                </Flex>
            );
        };
        
        return <FieldComponent {...newComponentProps} />;
    };

    components.propTypes = {
        labelPosition: PropTypes.string,
        className: PropTypes.string,
        componentType: PropTypes.string,
        availableValues: PropTypes.arrayOf(PropTypes.shape({})),
        onValidate: PropTypes.func,
    };
    components.defaultProps = {
        labelPosition: 'left',
        className: '',
        componentType: '',
        availableValues: [],
    };
    return components;
};

export const WniInputText = TooltipField(InputField);
export const WniInputNumber = TooltipField(InputNumberField);
export const WniInputMask = TooltipField(InputMaskField);
export const WniDropdownSelect = TooltipField(DropdownSelectField);
export const WniCurrency = TooltipField(CurrencyField);
export const WniDate = TooltipField(WniDateField);
export const WniTextArea = TooltipField(TextAreaField);
export const WniTypeaheadMultiSelect = TooltipField(TypeaheadMultiSelectField);
export const WniMultiSelect = TooltipField(MultiSelectField, 'multi');
export const WniMultiSelectNext = TooltipField(MultiSelectNextField, 'multi');
export const WniLookupField = TooltipField(LookupField);
export const WniCheckboxField = TooltipField(CheckboxField);
export const WniCheckboxGroupField = TooltipField(CheckboxGroupField);
export const WniToggle = TooltipField(ToggleField, 'toggle');
export const WniRadio = TooltipField(RadioField, 'toggle');

