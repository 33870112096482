import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import {
    Button
} from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext, getFlattenedUiPropsContent } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './RTVehicleInformation.metadata.json5';
import RTCustomEquipment from '../RTCustomEquipment/RTCustomEquipment';
import { getVehicleOrTrailerLabel, isFieldVisible } from '../util/Vehicle.util';

import messages from '../RTVehiclesPage.messages';

function VehicleInformation(props) {
    const {
        value: vehicleVM,
        onValueChange,
        onValidate,
        showErrors,
        syncWizardData,
        isReadOnly,
        isRequiredForIssuance
    } = props;
    const {
        publicID,
        itemToBeInsured
    } = vehicleVM.value;
    const modalApi = useWniModal();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const getAvailableValues = (path) => {
        const options = _.get(vehicleVM,  `${path}_options.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        })
    };

    const getVisibleField = (fieldPath) => {
        return isFieldVisible(vehicleVM, fieldPath);
    };

    const viewEditEquipment = () => {
        const basePath = 'customEquipment';
        const componentProps = {
            title: messages.customEquipment,
            actionBtnLabel: translator(messages.save),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            viewModelService: viewModelService,
            basePath,
            vm: vehicleVM
        };
        const customEquipmentComponent = modalApi.showModal(<RTCustomEquipment {...componentProps} />);
        customEquipmentComponent.then((res) => {
            _.set(vehicleVM.value, basePath, res);
            syncWizardData(vehicleVM);
        }).catch(() => _.noop)
    };
    const viewOrEditButtonLink = (isVisible) => {
        if(!isVisible || isReadOnly) {
            return null;
        }
        return (
            <Button
                className="wni-button-link ml-10" 
                onClick={viewEditEquipment}
            >
                View/Edit
            </Button>)
    };
    const renderAppliesSecondaryLabel = () => {
        const applies = _.get(vehicleVM, 'value.selectVehicleApplies', []);
        if (applies.includes('loaned_to_others')) {
            return <>
                <div>{translator(messages.selectApply)}</div>
                <div className='font-error'>{translator(messages.selectedVehicleAppliesWarningMsg)}</div>
            </>
        };
        return messages.selectApply;
    }

    const renderLivingQuartersWarning = (path) => {
        const hasLivingQuartersValue = _.get(vehicleVM.value, path);
        if(!hasLivingQuartersValue) {
            return null
        };
        return <span className='font-error'>{translator(messages.hasLivingQuartersWarning)}</span>
    }
    //---------------------
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            layout: 'reversed',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        isThereExistingDamage: {
            label: getVehicleOrTrailerLabel('isThereExistingDamage', itemToBeInsured)
        },
        existingDamageExplain: {
            visible: getVisibleField('existingDamageExplain'),
        },
        hasCustomizedEquip: {
            label: getVehicleOrTrailerLabel('hasCustomizedEquip', itemToBeInsured),
            visible: getVisibleField('hasCustomizedEquip'),
            secondaryLabel: viewOrEditButtonLink(_.get(vehicleVM.value, 'hasCustomizedEquip'))
        },
        hasAntiTheftProtectDevice: {
            visible: getVisibleField('hasAntiTheftProtectDevice')
        },
        isLicensedForRoadUse: {
            visible: getVisibleField('isLicensedForRoadUse')
        },
        whereVehicleStored: {
            visible: getVisibleField('whereVehicleStored')
        },
        vehicleUsedMonths: {
            visible: getVisibleField('vehicleUsedMonths')
        },
        selectVehicleApplies: {
            visible: getVisibleField('selectVehicleApplies'),
            availableValues: getAvailableValues('selectVehicleApplies'),
            secondaryLabel: renderAppliesSecondaryLabel()
        },
        isUsedForBusiness: {
            visible: getVisibleField('isUsedForBusiness')
        },
        isUsedForBusinessExplain: {
            visible: getVisibleField('isUsedForBusinessExplain')
        },
        hasVehicleBeenModified: {
            visible: getVisibleField('hasVehicleBeenModified')
        },
        hasVehBeenModifiedExplain: {
            visible: getVisibleField('hasVehBeenModifiedExplain')
        },
        hasCoOwner: {
            visible: getVisibleField('hasCoOwner')
        },
        coOwnerName: {
            visible: getVisibleField('coOwnerName')
        },
        maxNumberOfOccupants: {
            visible: getVisibleField('maxNumberOfOccupants')
        },
        haveLessThanFourWheels: {
            visible: getVisibleField('haveLessThanFourWheels')
        },
        hasPhysDamageLast12Months: {
            label: getVehicleOrTrailerLabel('hasPhysDamageLast12Months', itemToBeInsured),
            visible: getVisibleField('hasPhysDamageLast12Months')
        },
        hasLivingQuarters: {
            visible: getVisibleField('hasLivingQuarters'),
            secondaryLabel: renderLivingQuartersWarning('hasLivingQuarters')
        },
        // hasHazardWarnLightingSys: {
        //     visible: getVisibleField('hasHazardWarnLightingSys')
        // },
        
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    const newMetadata = metadata.componentContent;
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(newMetadata), [newMetadata]);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrides = _.merge(overrideProps, overridesForIssuance);

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, vehicleVM, fieldId, fieldPath, overrides
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrides}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default VehicleInformation;
