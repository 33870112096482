import { setComponentMapOverrides } from '@jutro/uiconfig';
import HOPAPaymentPage from 'gw-capability-policychange-common-react/pages/Payments/HOPAPaymentPage/PaymentPage';
import CPBOPPaymentPage from 'gw-capability-policychange-common-react/pages/Payments/CPBOPPaymentPage/PaymentPage';

import ConfirmationPage from './pages/Confirmation/ConfirmationPage';

setComponentMapOverrides(
    {
        HOPAPaymentPage: { component: 'HOPAPaymentPage' },
        CPBOPPaymentPage: { component: 'CPBOPPaymentPage' },
        ConfirmationPage: { component: 'ConfirmationPage' }
    },
    {
        HOPAPaymentPage,
        CPBOPPaymentPage,
        ConfirmationPage
    }
);
export { default as messages } from 'gw-capability-policychange-common-react/PolicyChangeCommon.messages';
export { default as PolicyChange } from './models/PolicyChange';
export { default as ConfirmationQuoteDetailsPage } from 'gw-capability-policychange-common-react/pages/ConfirmationQuoteDetails/ConfirmationQuoteDetailsPage';
export { default as CPBOPPaymentPage } from 'gw-capability-policychange-common-react/pages/Payments/CPBOPPaymentPage/PaymentPage';
