import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { PUHouseholdInfoService } from 'wni-capability-quoteandbind-pu';
import {
    ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
    WniPNIUtil,
} from 'wni-portals-util-js';
import {  IconButton } from '@jutro/components';
import {
    AgencyAndProducerInfoComponent,
} from 'wni-capability-gateway-react';
import { useProductsData } from 'wni-portals-util-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';

// import appConfig from 'app-config';
import moment from 'moment';
import WizardPage from '../../templates/PUWizardPage';

import styles from './PUHouseholdInfoPage.module.scss';
import metadata from './PUHouseholdInfoPage.metadata.json5';
import messages from './PUHouseholdInfoPage.messages';
import puQuestionSetsUtil from './utils/PUQuestionSetsUtil'
import PUPrimaryNamedInsuredInputPopup from './components/PUPrimaryNamedInsuredInputPopupComponent/PUPrimaryNamedInsuredInputPopup';
import PUEditResidentsInputPopup from './components/PUEditResidentsPopupComponent/PUEditResidentsInputPopup';
import PUAddResidentsInputPopup from './components/PUAddResidentsPopupComponent/PUAddResidentsInputPopup';

function PUHouseholdInfoPage(props) {

    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        setAgentComponentReadOnly,
        // updateWizardSnapshot,
        // updateWizardReadOnly,
        // wizardSnapshot,
        // //
        // wizardStepToFieldMapping,
        // markFollowingWizardStepsUnvisited,
        savePolicyDetailsData,
        isOpenPNIPopupPageInit
        // jumpTo: wizardJumpTo,
        // steps: wizardSteps,
        //
        // wizardPageData,
        // updateWizardPageData,
        // readOnlyFields: {
        //     // effectiveDate = false,
        //     agencyOfRecord = false,
        // },
    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
        policyInfoSource_Ext: policyInfoSource,
        baseData,
        lobData: {
            personalUmbrella: {
                householdInfo,
                isHouseholdInfoFirstEnter
            }
        },
        // bindData,
        // quoteData,
    } = submissionVM.value;

    const {
        baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        quoteFlow_Ext: quoteFlow,
        effectiveDate_Ext: transactionEffectiveDate,
        policyAddress
        // productCode
    } = baseData;
    const {
        umbdrivers,
        uwqapplicantExistPolicy_Ext: uwqApplicantExistPolicy,
        uwqholdNonCompPosi_Ext: uwqHoldNonCompPosi,
        uwqholdNonCompApplies_Ext: uwqholdNonCompApplies
    } = householdInfo;

    // const history = useHistory();
    const translator = useTranslator();
    // const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [driverResidentsTableData, updateDriverResidentsTableData] = useState(umbdrivers);
    const viewModelService = useContext(ViewModelServiceContext);
    const { getProductName } = useProductsData();
    
    // const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        initialValidation,
        onValidate,
        // invalidFields,
        // isComponentValid,
        registerComponentValidation
    } = useValidation('WCPolicyDetailsPage');

    // const [showErrors, updateShowErrors] = useState(false);
    const { domainCompany: { domainName} } = useDependencies(['domainCompany']);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    // const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    useEffect(() => {
        if (isOpenPNIPopupPageInit) {
            openEditPrimaryNamedInsuredPopup();
        }
    }, [isOpenPNIPopupPageInit]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const questionSetMapper = puQuestionSetsUtil.getQSMapperForPUHouseholdInfo()

    const getMinDate = useCallback(() => {
        if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
            return moment(systemDate).add(-5, 'd').startOf('day').toDate();
        }
        return systemDate
    }, [quoteFlow, policyInfoSource, systemDate]);

    const isBaseStateInMutualState = useCallback(() => {
        switch (baseState) {
            case 'IA': 
            case 'MN': 
            case 'SD': 
            case 'WI': return true;
            default: return false;
        }
    }, [baseState])

    const getBaseStateList = useCallback(() => {
        const allBaseStateList = _.get(submissionVM, 'baseData.baseState_Ext._aspects.availableValues')
        const displaAllBaseStateList = allBaseStateList.map((state) => {
            return {
                code: state.code,
                name: translator({ id: state.name })
            }
        })
        let MutualState = ['WI', 'MN', 'IA', 'SD']
        if (!isBaseStateInMutualState()) {
            MutualState = ['AK'];
        }
        const filteredBaseStateList = _.filter(displaAllBaseStateList, (state) => MutualState.includes(state.code))     
        return filteredBaseStateList
    }, [isBaseStateInMutualState, submissionVM, translator])

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, umbDriverAsPNIVM, umbDriversVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                umbDriverAsPNIVM, 
                umbDriversVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                showEmployeeFlagToggle: false,
                authHeader
            };
            return modalApi.showModal(
                <PUPrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader, modalApi]
    );

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        _.set(primaryNamedInsuredVM.value, 'isNewAccount_Ext', true)
        // _.set(primaryNamedInsuredVM.value, 'accountOrgType_Ext', 'commonownership')
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        const umbDriverAsPNIVM = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.umbdriverAsPNI');
        const umbDriversVM = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.umbdrivers')
        _.set(primaryNamedInsuredVM, 'occupation_Ext', _.get(umbDriverAsPNIVM, 'occupation_Ext', null))
        _.set(primaryNamedInsuredVM, 'occupationExplain_Ext', _.get(umbDriverAsPNIVM, 'occupationExplain_Ext', null))
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, umbDriverAsPNIVM, umbDriversVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                const newAddress = _.get(updatedVM.PNIVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM.PNIVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.PNIVM.value);
                if (accountHolder.publicID === _.get(updatedVM.PNIVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.PNIVM.value);
                }
                const newSubmissionVM = _.clone(submissionVM);
                updateSubmissionVM(newSubmissionVM);
            }).catch(() => _.noop());
    },[showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {          
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.accountContacts_Ext[0].primaryAddress'));
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress=WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [getPrimaryNamedInsuredDisplayName, primaryNamedInsured, submissionVM.value]);

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = getMinDate();
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();

        return (minDif >= 0) 
    }, [submissionVM.value, getMinDate]);
    

    const PUHouseholdInfoValidation = useCallback(() => {
        return isEffectiveDateValid()
            // && WniPNIUtil.isPNIValid(primaryNamedInsured);
        // && isAgencyAndAgentInfoValid()
    }, [isEffectiveDateValid]);

    useEffect(() => {
        registerComponentValidation(PUHouseholdInfoValidation);
    }, [registerComponentValidation, transactionEffectiveDate]);

    // const multipleProductsSelectOptions = 


    const getMultipleSelectOptions = useCallback(() => {
        const options =  _.get(submissionVM.value, 'lobData.personalUmbrella.householdInfo.uwqselectedProductTypeOptions_Ext');
        return options.map((res) => {
            const displayName = getProductName(res);
            if (displayName) {
                return {
                    code: res,
                    name: displayName
                }
            } 
            return {
                code: res,
                name: translator({ id: res })
            } 
        })
    },[getProductName, submissionVM.value, translator]);

    const getHoldNonCompApplies = () => {
        const options = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.uwqholdNonCompAppliesOptions_Ext.aspects.availableValues', []);
        
        return options.map((res) => {
            return {
                code: res.code,
                name: translator({ id: res.name })
            } 
        })
    }

    const editDriverRow = async(event, item) => {
        const driverVMList = _.get(submissionVM, 'lobData.personalUmbrella.householdInfo.umbdrivers.children')
        const componentProps = {
            title: messages.editResidentsTitle,
            nextBtnLabel: 'Save & Next Household Resident',
            saveBtnLabel: 'Save & Continue',
            cancelBtnLabel: 'Cancel',
            // contactVM: contactVM,
            driverVMList,
            selectedDriverPublicID: item.publicID ,
            viewModelService: viewModelService,
            policyAddress
        };
        modalApi.showModal(<PUEditResidentsInputPopup {...componentProps} />)
            .then(async (res) => {
                const newSubmissionVM = _.clone(submissionVM);
                const listToUpdate = res.filter((elt) => _.get(elt, 'relationshipToInsured_Ext') !== 'PRIMARYNAMEDINSURED')
                setLoadingMask(true);
                const rs = await PUHouseholdInfoService.updateResidents(quoteID, sessionUUID, listToUpdate, authHeader);
                // update tableData
                refreshDriverResidentsTable(rs);
                _.set(newSubmissionVM.value, 'lobData.dwellingProperty.householdInfoDTO_Ext.householdResidents', rs);
                updateSubmissionVM(newSubmissionVM);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    };

    
    const deleteDriverRow = useCallback(async (event, item) => {
        modalApi.showConfirm({
            title: messages.deleteResidentsTitle,
            message: messages.deleteResidentsMessage,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const publicID = _.get(item, 'publicID');
                try {
                    setLoadingMask(true);
                    const newSubmissionVM = _.clone(submissionVM);
                    const rs = await PUHouseholdInfoService.removeResidents(quoteID, sessionUUID, publicID, authHeader);
                    refreshDriverResidentsTable(rs);
                    _.set(newSubmissionVM.value, 'lobData.personalUmbrella.householdInfo.umbdrivers', rs);
                    updateSubmissionVM(newSubmissionVM);
                } finally {
                    setLoadingMask(false);
                }
                return true;
            }, _.noop
        );
    }, [modalApi, setLoadingMask, submissionVM, updateSubmissionVM]);
   
    const renderDriverTableAction = (item, index) => {
        return (
            <>
                <IconButton
                    id={`edit${index}`}
                    icon="gw-edit"
                    iconColor="dark"
                    size="medium"
                    onClick={(e) => editDriverRow(e, item)}
                />
                <IconButton
                    id={`delete${index}`}
                    icon="gw-delete"
                    iconColor="dark"
                    size="medium"
                    onClick={(e) => deleteDriverRow(e, item)
                    }
                />
            </>
        );
    };


    const renderCell = (item, index, { path, typeKey }) => {
        return item[path] ? translator({id: `${typeKey}.${item[path]}` }) : '-'
    };

    const licensedDriverRenderCell = (item) => { 
        const val = _.get(item, 'isLicensedDriver');
        if (val === false) {
            return 'No';
        }
        if (val === true) {
            return 'Yes';
        }
        return '-';
    }

    const aniRenderCell = (item) => { 
        const val = _.get(item, 'additionalNamedInsured');
        if (val === false || val === null) {
            return 'No';
        }
        if (val === true) {
            return 'Yes';
        }
        return '-';
    }

    const refreshDriverResidentsTable = (datas) => {
        const initData = [];
        _.each(datas, (contact) => {
                initData.push(contact);
        })
        updateDriverResidentsTableData(initData)
    };

    // const handleValidation = useCallback(
    //     () => {
    //         updateShowErrors(true);
    //         WindowUtil.scrollToInvalidField(invalidFields);
    //         return false;
    //     },[updateShowErrors, invalidFields]
    // );

    // const onPageNext = useCallback(async () => {
    //     setLoadingMask(true);
    //     const puHouseholdInfoData = _.get(submissionVM.value, 'lobData.personalUmbrella.householdInfo')
    //     const questionSetData = _.get(submissionVM.value, 'lobData.personalUmbrella.preQualQuestionSets')
    //     const quoteBaseData = _.get(submissionVM.value, 'baseData')
        
    //     const res = await savePolicyDetailsData(
    //         quoteID, 
    //         sessionUUID,
    //         questionSetData,
    //         quoteBaseData,
    //         puHouseholdInfoData,
    //         authHeader);
    //     setLoadingMask(false);
    //     submissionVM.value = res;
    //     updateSubmissionVM(submissionVM);
        
    //     // Next step: move this and the service call into HOWizardPage as a common feature
    //     const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
    //     const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
    //     updateValidationIssues(newValidationIssues);

    //     const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
    //     const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
    //     if(hasValidationWarning && !displayWarnings) {
    //         updateDisplayWarnings(true);
    //         return false;
    //     }

    //     if (hasValidationError) {
    //         WindowUtil.scrollToWizardErrors();
    //         return false;
    //     }
    //     return submissionVM;
    // }, [setLoadingMask, submissionVM, savePolicyDetailsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);


    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                isReadOnly: true,
                labelPosition: 'left',
                showRequired: true
            },
            effectiveDate: {
                minDate: getMinDate(),
                // showErrors
            },
            policyState: {
                readOnly: !(isHouseholdInfoFirstEnter && isBaseStateInMutualState()),
                availableValues: getBaseStateList()
            },
            agencyAndProducerInfo: {
                isReadOnly: true,
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate
            },
            primaryNamedInsured: {
                value: getPrimaryNamedInsuredAndDisplay(),
            },
            primaryNamedInsuredEditIcon: {
                visible: false,
                // onClick: openEditPrimaryNamedInsuredPopup,
            },
            UWQApplicantExistPolicyToggle: {
                label: translator(messages.uWQApplicantExistPolicyToggle, {
                    companyName: domainName
                }),
            },
            UWQSelectedProductTypeMultiSelect: {
                // availableValues: getMultipleSelectOptions(),
                visible: !!uwqApplicantExistPolicy,
            },
            UWQHoldNonCompApplies: {
                visible: !!uwqHoldNonCompPosi,
                // availableValues: getHoldNonCompApplies(),
            },
            UWQHoldNonCompPosiExplain: {
                required: true,
                visible: !!uwqHoldNonCompPosi && (uwqholdNonCompApplies.includes('other') || uwqholdNonCompApplies.includes('board_member'))
            },
            policyUnderwritingQuestionSets: {
                contentMapper: questionSetMapper,
            },
            addDriverButton: {
                // onClick: handleAddDriverResidents,
                disabled: true
            },
            driverResidentsTable: {
                data: driverResidentsTableData,
            },
            // actionColumn: {
            //     renderCell: renderDriverTableAction,
            // },
        };
    }, [getMinDate, isHouseholdInfoFirstEnter, isBaseStateInMutualState, getBaseStateList, submissionVM.baseData, handleAgencyChange, onValidate, getPrimaryNamedInsuredAndDisplay, openEditPrimaryNamedInsuredPopup, translator, domainName, getMultipleSelectOptions, uwqApplicantExistPolicy, uwqHoldNonCompPosi, getHoldNonCompApplies, uwqholdNonCompApplies, questionSetMapper, driverResidentsTableData, renderDriverTableAction]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderCell: renderCell,
            licensedDriverRenderCell: licensedDriverRenderCell,
            aniRenderCell: aniRenderCell

        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            questionset: QuestionSetComponent,
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            // onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                // showErrors={showErrors}
            />
        </WizardPage>
    );
}

PUHouseholdInfoPage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
};

PUHouseholdInfoPage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: PUHouseholdInfoService.savePUHouseholdInfoData,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
} 
export default PUHouseholdInfoPage;