import React from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';

const CPLocationDetailsFieldMap = (props) => {
    const {
        vm,
        dataPath,
        onValueChange = _.noop,
        onValidate,
        onBlur = _.noop,
        showErrors,
        isReadOnly,
    } = props;
    
    const fieldModels = _.get(vm.value, dataPath, []);


    const handleValueChange = (newValue, index, fieldModel) => {
        onValueChange(newValue, `${dataPath}[${index}]`, fieldModel)
    };

    const handleBlur = (value, fieldItem) => {
        onBlur(value, fieldItem)
    };

    
    const metadata = fieldModels.map((fieldItem, index) => {
        return {
            id: `${fieldItem.propertyName}Field`,
            type: 'field',
            component: 'WniFieldSetModel',
            componentProps: {
                showRequired: true,
                showErrors: showErrors,
                readOnly: isReadOnly || fieldItem.readonly || !fieldItem.editable,
                onValueChange: (value, fieldModel) => handleValueChange(value, index, fieldModel),
                onBlur: (value) => handleBlur(value, fieldItem),
                fieldItem,
                onValidate
            }
        };
    });

    const overrides = {};
    const resolvers = {
        resolveComponentMap: {
        },
    };

    return (
        <MetadataContent
            uiProps={metadata}
            overrideProps={overrides}
            {...resolvers}
        />
    );
};

export default CPLocationDetailsFieldMap;
