import React, { useCallback } from 'react';
import { useTranslator } from '@jutro/locale';
import classNames from 'classnames';
import _ from 'lodash';
import {
    WniCheckboxField
} from 'wni-common-base-components';
import MortgageeAdditionalInterestsComponent from '../MortgageeAdditionalInterestsComponent/MortgageeAdditionalInterestsComponent';
import messages from './MortgageeDetailsComponent.messages';
import styles from './MortgageeDetailsComponent.module.scss';


const MortgageeDetailsComponent = (props) => {
    const translator = useTranslator();

    const {
        wizardData,
        mortgageeDetails,
        updateMortGageeDetails,
        createOrUpdateAdditionalInterest,
        removeAdditionalInterests,
        isEditable,
        onValidate,
        showErrors,
    } = props;

    const {
        mortgageeDetails: mortgageeDetailsSelected,
        additionalInterests = [],
    } = mortgageeDetails;

    const checkboxStyles = classNames({
        [styles.clauseNoPadding]: false,
    });

    const getAdditionalInterestTypeOptions = useCallback((selectedAdditionalInterest) => {
        const options = ['CP7FIRSTMORTGAGEE_Ext', 'CP7SECONDMORTGAGEE_Ext', 'CP7THIRDMORTGAGEE_Ext'];
        let existingTypes = additionalInterests.map((item) => item.type);
        if (!_.isNil(selectedAdditionalInterest.fixedId)) { // edit
            const existingInterests = additionalInterests.filter((interest) => interest.fixedId !== selectedAdditionalInterest.fixedId);
            existingTypes = existingInterests.map((item) => item.type);
        }
        return options.filter((code) => !existingTypes.includes(code));
    }, [additionalInterests]);

    const additionalInterestProps = {
        wizardData,
        lobDataProductKey: 'commercialProperty',
        dtoVMPath: 'wni.edge.capabilities.quote.lob.commercialproperty.dto.CPBuildingAdditionalInterestDTO',
        cpProductParams: {
            additionalInterests,
            createOrUpdateAdditionalInterest,
            removeAdditionalInterests,
            getAdditionalInterestTypeOptions
        },
        onValidate,
        showErrors,
        readOnly: !isEditable
    };

    return (
        <>
            <WniCheckboxField
                id="mortgageeDetails"
                label={translator(messages.MortgageeDetails)}
                value={mortgageeDetailsSelected}
                readOnly={!isEditable}
                onValueChange={() =>
                    updateMortGageeDetails({
                        mortgageeDetails: !mortgageeDetailsSelected,
                    })
                }
                className={`${checkboxStyles} clauseCoverages clauseCheckbox`}
                labelPosition="right"
                showInlineLabel
                layout="full-width"
            />
            {mortgageeDetails.mortgageeDetails && (
                <MortgageeAdditionalInterestsComponent
                    {...additionalInterestProps}
                />
            )}
        </>
    );
};

export default MortgageeDetailsComponent;
