export { default as TeamTabComponent } from './components/TeamTab/TeamTabComponent';
export { default as IncidentPopupComponent } from './components/IncidentPopupComponent/IncidentPopupComponent';
export { default as SimpleIncidentPopupComponent } from './components/SimpleIncidentPopupComponent/SimpleIncidentPopupComponent';
export { default as FormPopupComponent } from './components/FormPopupComponent/FormPopupComponent';
export { default as QuoteUnderwritingIssuesList } from './components/UWIssue/QuoteUnderwritingIssuesList/QuoteUnderwritingIssuesList';
export { default as EligibilityIssuesLinkComponent } from './components/EligibilityIssuesLinkComponent/EligibilityIssuesLinkComponent';

export { default as ValidationIssuesComponent } from './components/ErrorsAndWarnings/ValidationIssuesComponent/ValidationIssuesComponent';
export { default as ConsolidatedValidationIssuesComponent } from './components/ErrorsAndWarnings/ConsolidatedValidationIssues/ConsolidatedValidationIssuesComponent';

export { default as ErrorsAndWarningsDisplayComponent } from './components/ErrorsAndWarnings/ErrorsAndWarningsDisplayComponent/ErrorsAndWarningsDisplayComponent';
export { default as PaymentSchedule } from './components/PaymentSchedule/PaymentSchedule';
export { default as RecommendInputComponent } from './components/RecommendInputComponent/RecommendInputComponent';
export { default as CustomEquipmentSingleClauseComponentVM } from './components/Clauses/CustomEquipmentSingleClauseComponentVM';
export { default as AccidentForgivenessClauseComponent } from './components/Clauses/AccidentForgivenessClauseComponent';
export { default as ActionListComponent } from './components/ActionListComponent/ActionListComponent';
export { default as CheckboxGroupComponent } from './components/CheckboxGroupComponent/CheckboxGroupComponent';
export { default as BaseClauseComponent } from './components/Clauses/Base/BaseClauseComponent';
export { default as BaseClausesComponentVM } from './components/Clauses/Base/BaseClausesComponentVM';
export { default as BaseSingleClauseComponentVM } from './components/Clauses/Base/BaseSingleClauseComponentVM';
export { default as QuoteUWIssuesPendingList } from './components/UWIssue/PendingList/QuoteUWIssuesPendingList';
export { default as BasePolicyChangeQuoteComponent } from './components/PolicyChangeQuoteComponent/BasePolicyChangeQuoteComponent';
export { default as PAPolicyChangeQuoteComponent } from './components/PolicyChangeQuoteComponent/PAPolicyChangeQuoteComponent';
export { default as ProductsField } from './components/ProductsComponent/ProductsField';

export { default as AgencyInformation } from './components/AgencyInfomation/AgencyInformation';

export { default as AsteriskIsRequiredNotification } from './components/AsteriskIsRequiredNotification/AsteriskIsRequiredNotification';
export { default as UnderwritingIssuesSimpleList } from './components/UWIssue/UnderwritingIssuesSimpleList/UnderwritingIssuesSimpleList';

export { default as useFeatureToggle } from './hooks/useFeatureToggle';
export { isNumberValid } from './components/FieldSetMode/FieldSetModel.util'
// ==============================
export { default as BaseAddNewVersionComponent } from './QuotePage/AddNewVersion/BaseAddNewVersionComponent';
export { default as BaseAddNewChangeVersionComponent } from './QuotePage/AddNewChangeVersion/BaseAddNewChangeVersionComponent';
export { default as useWniModal, withWniModal } from './hooks/ModalNext/useWniModal';
// ============================================================================================================================

import { setComponentMapOverrides } from '@jutro/uiconfig';
import WniFieldSetModel from './components/FieldSetMode/FieldSetMode';
import WniUploadField  from './components/UploadFieldComponent/UploadFieldComponent'

setComponentMapOverrides(
    {
       
        WniUploadField: { component: 'WniUploadField' },
        WniFieldSetModel: { component: 'WniFieldSetModel'}
    },
    {
       
        WniUploadField,
        WniFieldSetModel
    }
);
export {
    WniUploadField,
    WniFieldSetModel
}
