import { setComponentMapOverrides } from '@jutro/uiconfig';

import QuoteWizardPageTemplate from 'gw-capability-quoteandbind-common-react/components/QuoteWizardPageTemplate';

export { default as commonMessages } from 'gw-capability-quoteandbind-common-react/quoteandbind.messages';
export { default as commonQuoteStyles } from 'gw-capability-quoteandbind-common-react/quoteandbind-common-styles.module.scss';
export { default as QuoteInfo } from 'gw-capability-quoteandbind-common-react/components/CommonQuoteInfo/CommonQuoteInfo';
export { default as QuickQuoteWizardPageTemplate } from 'gw-capability-quoteandbind-common-react/components/QuickQuoteWizardPageTemplate';
export { default as QualificationPage } from 'gw-capability-quoteandbind-common-react/pages/Qualification/QualificationPage';
export { QuoteInfoComponentContext, withQuoteInfoComponent } from 'gw-capability-quoteandbind-common-react/components/QuoteInfoComponentContext';

export { default as PaymentDetailsPage } from './pages/PaymentDetails/PaymentDetailsPage';
export { default as PaymentDetailsReadOnlyPage } from './pages/PaymentDetails/PaymentDetailsPage.readonly';
export { default as AddPaymentMethodPopup } from './pages/PaymentDetails/AddPaymentMethodPopup';
export { QuoteWizardPageTemplate };

setComponentMapOverrides(
    {
        QuoteWizardPageTemplate: { component: 'QuoteWizardPageTemplate' },
    },
    {
        QuoteWizardPageTemplate,
    }
);
