import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
    WniProductsUtil,
} from 'wni-portals-util-js';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { CPPModifierService, CPPMultiQuoteService } from 'wni-capability-quoteandbind-cpp';
import { BaseModifierComponent } from 'wni-capability-common-react';
import WizardPage from '../../templates/CPPWizardPage';
import CPPModifiersConfig from './CPPModifiersPage.config';
import messages from './CPPModifiersPage.message';

const CPPLINE_LOBNAME = {
    commercialProperty: WniProductsUtil.CP_PRODUCT_CODE,
    generalLiability: WniProductsUtil.GL_PRODUCT_CODE
};

function CPPModifiersPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        isReadOnly,
        //
        updateWizardPageData,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value;

    const {
        agentStateTotalRangeMap
    } = CPPModifiersConfig

    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');

    const [validationIssues, updateValidationIssues] = useState();
    const [isModifiersUpdated, setIsModifiersUpdated] = useState(false);
    const [allModifiers, setAllModifiers] = useState({});
    const [stateReadOnlyMap, setStateReadOnlyMap] = useState({});

    const stateOverallMinMax = (modifiers) => {
        return modifiers.map(modifier => {
            return {state: modifier.jurisdiction, overallMin: modifier.overallMinimum, overallMax: modifier.overallMaximum}
        })
    };

    const allStateTotalCreditDebit = (modifiers) => {
        return modifiers.map(modifier => {
            return {state: modifier.jurisdiction, overallCreditDebit: modifier.rateFactors.reduce((acc, currentFactor) => acc + currentFactor.creditDebit, 0)}
        })
    };

    const overallMaxAK = (modifierAK) => {         
        if (!_.isNil(modifierAK)) {
            return isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.maximum`) : modifierAK.overallMaximum;
        }
        return undefined;
    }
    
    const overallMinAK = (modifierAK) => {
        if (!_.isNil(modifierAK)) {
            return isExternalUser ? _.get(agentStateTotalRangeMap, `${modifierAK.jurisdiction}.minimum`) : modifierAK.overallMinimum;
        }
        return undefined;
    }

    const getStateReadOnlyMap = (modifierItem)=> {
        return modifierItem.states.map((currentState) => {
            const currentOverallCreditDebit = parseFloat(modifierItem.stateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit).toFixed(4);
            const currentTotalMinimum = modifierItem.stateOverallMinMax.find(factor => factor.state === currentState).overallMin;
            const currentTotalMaximum = modifierItem.stateOverallMinMax.find(factor => factor.state === currentState).overallMax;
            let isStateReadOnly = false;
            if (isExternalUser) {
                // UWs will have a larger credit / debit range than an agent would (UWs can do +/- 35% in AK whereas Agents can only do +/-15%) If an UW enters a modifier sum outside of the agent allowed amount, the screen should become un-editable for agents if he opened this submission
                if(!_.isNil(modifierItem.modifierAK)) {
                    isStateReadOnly = currentOverallCreditDebit > _.get(agentStateTotalRangeMap, `${modifierItem.modifierAK.jurisdiction}.maximum`) 
                    || currentOverallCreditDebit < _.get(agentStateTotalRangeMap, `${modifierItem.modifierAK.jurisdiction}.minimum`)
                } else {
                    isStateReadOnly =  currentOverallCreditDebit > currentTotalMaximum || currentOverallCreditDebit < currentTotalMinimum;
                }
            }
            return {state: currentState, isCurrentStateReadOnly: isStateReadOnly}
        })
    }

    const getAllModifiers = (submissionvm, init) => {
        const lobData = _.get(submissionvm.value, 'lobData');
        const modifiersData = [];
        const stateReadOnlyData = [];
        _.forIn(lobData,(value, key) => {
            if(Object.keys(CPPLINE_LOBNAME).includes(key)) {
                const modifiers = _.get(value, 'modifiers_Ext', []);
                const modifierAK = modifiers.find(modifier => modifier.jurisdiction === 'Alaska');
                const allStateTotalCreditDebitMap = allStateTotalCreditDebit(modifiers);
                modifiersData[key] = {
                    modifiers,
                    productName: WniProductsUtil.getProductName(CPPLINE_LOBNAME[key]),
                    states: modifiers.map(modifier => modifier.jurisdiction),
                    modifierAK,
                    allStateTotalCreditDebit: allStateTotalCreditDebitMap,
                    stateOverallMinMax: stateOverallMinMax(modifiers),
                    stateTotalCreditDebit: allStateTotalCreditDebitMap,
                    overallMaxAK: overallMaxAK(modifierAK),
                    overallMinAK: overallMinAK(modifierAK)
                }
                if(init) {
                    stateReadOnlyData[key] = getStateReadOnlyMap(modifiersData[key]);
                    setStateReadOnlyMap(stateReadOnlyData);
                }
            }
        })
        setAllModifiers(modifiersData);
    }

    useEffect(() => {
        getAllModifiers(submissionVM, true);
    }, []);

    const defaultValidationInfo = (modifierItem) => {
        return _.isNil(modifierItem.modifierAK) ? [] : [{
            type: 'info',
            reason: translator(messages.modifierRangeInfo, {max: `${Math.round(modifierItem.overallMaxAK * 100)}%`})
    }];}

    const onModifierChange = (productName, currentModifierIndex, newRateFactors) => {
        _.set(submissionVM.value, `lobData.${productName}.modifiers_Ext[${currentModifierIndex}].rateFactors`, newRateFactors);
        updateWizardData(submissionVM);
        getAllModifiers(submissionVM);
    }

    const isRateFactorsValid = (modifiersItem) => {
        let isValid = true;
        modifiersItem.modifiers.forEach(stateInfo => {
            const {rateFactors} = stateInfo;
            const overallMin = modifiersItem.overallMinAK || stateInfo.overallMinimum;
            const overallMax = modifiersItem.overallMaxAK || stateInfo.overallMaximum;
            const currentState = stateInfo.jurisdiction;
            const currentTotalCreditDebit = modifiersItem.allStateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
            const invalidRateFactors = rateFactors.filter((item) => 
                {
                    const isUnEditable = item.category === messages.unEditableCategory.defaultMessage && currentState === 'Alaska';
                    return(!isUnEditable && item.creditDebit !== 0) && (_.isNil(item.justification) || item.justification === '')
                });
            const invalidCreditDebitInput = rateFactors.filter( (item) => 
                {
                    return (item.creditDebit > item.maximum || item.creditDebit < item.minimum || _.isNaN(item.creditDebit))
                });
            if ((currentTotalCreditDebit < overallMin) || (currentTotalCreditDebit > overallMax) || !_.isEmpty(invalidRateFactors) || !_.isEmpty(invalidCreditDebitInput)) {
                isValid = false;
            } 
        })
        return isValid;
    }

    const getValidationIssues = useCallback((modifiersItem) => {
        let allValidationIssues = [];
        modifiersItem.modifiers.forEach(stateInfo => {
            const overallMin = modifiersItem.overallMinAK || stateInfo.overallMinimum;
            const overallMax = modifiersItem.overallMaxAK || stateInfo.overallMaximum;
            const currentState = stateInfo.jurisdiction;
            const {rateFactors} = stateInfo;
            const currentTotalCreditDebit = modifiersItem.allStateTotalCreditDebit.find(factor => factor.state === currentState).overallCreditDebit;
            const invalidRateFactors = rateFactors.filter((item) => 
                {
                    const isUnEditable = item.category === messages.unEditableCategory.defaultMessage && currentState === 'Alaska';
                    return (!isUnEditable && item.creditDebit !== 0) && (_.isNil(item.justification) || item.justification === '')
                });
            if ((currentTotalCreditDebit < overallMin || currentTotalCreditDebit > overallMax) && currentState === 'Alaska' ) {
                const AKMaximum = isExternalUser ? _.get(agentStateTotalRangeMap, `${modifiersItem.modifierAK.jurisdiction}.maximum`) : modifiersItem.modifierAK.overallMaximum;
                allValidationIssues = [...allValidationIssues, {
                    type: 'error',
                    reason: translator(messages.creditDebitOutOfRange.defaultMessage, {
                        agentStateTotalRangeMaximum: `${AKMaximum * 100}%`
                    })
                }]
            } 
            if (!_.isEmpty(invalidRateFactors)) {
                allValidationIssues = [...allValidationIssues, {
                    type: 'warning',
                    reason: messages.justificationsRequired.defaultMessage
                }]
            }
        })
        return _.uniqBy(allValidationIssues, 'reason');
    }, [agentStateTotalRangeMap, isExternalUser, translator]);

    const onPageNext = useCallback(async () => {

        for (const key in allModifiers) {
            if (Object.hasOwnProperty.call(allModifiers, key)) {
                const modifierItem = allModifiers[key];
                const isReadOnlyModeForUser = _.get(stateReadOnlyMap[key].find(factor => factor.state === 'Alaska'), 'isCurrentStateReadOnly', false);
                if (!isReadOnlyModeForUser && !isRateFactorsValid(modifierItem)) {
                    const allValidationIssues = getValidationIssues(modifierItem);
                    updateValidationIssues(defaultValidationInfo(modifierItem).concat(allValidationIssues));
                    return false;
                }
            }
        }
        
        if (isModifiersUpdated){
            setLoadingMask(true);
            const requestData = {}
            Object.keys(allModifiers).forEach(key => {
                requestData[key] = allModifiers[key].modifiers
            });
            const newModifiers = await CPPModifierService.updateModifiers(jobID, sessionUUID, requestData, authHeader);
            setLoadingMask(false);
            Object.keys(newModifiers).forEach(key => {
                const newModifierItem = newModifiers[key];
                _.set(submissionVM, `value.lobData.${key}.modifiers_Ext`, newModifierItem);
            })
            //
            _.set(submissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
            updateWizardData(submissionVM);
        }

        return submissionVM; 
    }, [allModifiers, authHeader, defaultValidationInfo, getValidationIssues, isModifiersUpdated, jobID, props, sessionUUID, setLoadingMask, stateReadOnlyMap, submissionVM, updateWizardData]);

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            pageLevelValidationIssues={validationIssues}
            onNext={onPageNext}
            alwaysCallOnNext={!isReadOnly}
        >
            <h3>Modifiers</h3>
            <hr/>
            {
                Object.keys(allModifiers).map(key => {
                    const modifierItem = allModifiers[key]
                    if(modifierItem.modifiers.length <= 0) {
                        return null
                    }
                    const modifiersAK = modifierItem.modifiers.find(modifier => modifier.jurisdiction === 'Alaska');
                    return (
                        <>
                            <h4>{modifierItem.productName}</h4>
                            <hr />
                            <BaseModifierComponent 
                                modifiers={modifierItem.modifiers}
                                modifierAK={modifiersAK}
                                setIsModifiersUpdated={setIsModifiersUpdated}
                                onModifierChange={onModifierChange}
                                modifiersConfig={CPPModifiersConfig}
                                messages={messages}
                                overallMaxAK={modifierItem.overallMaxAK}
                                overallMinAK={modifierItem.overallMinAK}
                                allStateTotalCreditDebit={modifierItem.allStateTotalCreditDebit}
                                stateOverallMinMax={modifierItem.stateOverallMinMax}
                                productName={key}
                                isReadOnly={isReadOnly}
                            />
                        </>
                    )
                })
            }
        </WizardPage>
    );
}

CPPModifiersPage.propTypes = WizardPage.propTypes;
CPPModifiersPage.defaultProps = WizardPage.defaultProps;
export default CPPModifiersPage;
