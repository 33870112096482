
import { setComponentMapOverrides } from '@jutro/uiconfig';
import { RenewalConfirmationPage, RenewalPaymentPage } from 'gw-capability-policyrenewal-common-react';
import PRCPPolicyDetailsPage from './pages/PRCPPolicyDetails/PRCPPolicyDetailsPage';
import PRCPQuotePage from './pages/PRCPQuote/PRCPQuotePage';
import 'gw-capability-gateway-quoteandbind-cp-react';
import './CPPolicyRenewal.messages';

setComponentMapOverrides(
    {
        PRCPPolicyDetailsPage: { component: 'PRCPPolicyDetailsPage' },
        PRCPQuotePage: { component: 'PRCPQuotePage' },
        RenewalPaymentPage: { component: 'RenewalPaymentPage' },
        RenewalConfirmationPage: { component: 'RenewalConfirmationPage' }
    },
    {
        PRCPPolicyDetailsPage,
        PRCPQuotePage,
        RenewalPaymentPage,
        RenewalConfirmationPage
    }
);
