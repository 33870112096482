import { setComponentMapOverrides } from '@jutro/uiconfig';

import CPDummyPage from './pages/Dummy/CPDummyPage';
import CPDummyReadOnlyPage from './pages/Dummy/CPDummyPage.readonly';

import CPRiskAnalysisPage from './pages/RiskAnalysis/CPRiskAnalysisPage';
import CPRiskAnalysisReadOnlyPage from './pages/RiskAnalysis/CPRiskAnalysisPage.readonly';

import CPModifiersPage from './pages/Modifiers/CPModifiersPage';
import CPModifiersReadOnlyPage from './pages/Modifiers/CPModifiersPage.readonly';

import CPPolicyDetailsPage from './pages/PolicyDetails/CPPolicyDetailsPage';
import CPPolicyDetailsReadOnlyPage from './pages/PolicyDetails/CPPolicyDetailsPage.readonly';

import CPCommercialPropertyPage from './pages/CommercialProperty/CommercialPropertyPage';
import CPCommercialPropertyReadOnlyPage from './pages/CommercialProperty/CommercialPropertyPage.readonly';

import CPLocationsPage from './pages/Locations/CPLocationsPage';
import CPLocationsReadOnlyPage from './pages/Locations/CPLocationsPage.readonly';

import CPStateSpecificInformationPage from './pages/StateSpecificInformation/CPStateSpecificInformationPage';
import CPStateSpecificInformationReadOnlyPage from './pages/StateSpecificInformation/CPStateSpecificInformationPage.readonly';

import CPBlanketsPage from './pages/Blankets/CPBlanketsPage';
import CPBlanketsReadOnlyPage from './pages/Blankets/CPBlanketsPage.readonly';

import CPRisksPage from './pages/Risks/CPRisksPage';
import CPRisksReadOnlyPage from './pages/Risks/CPRisksPage.readonly';

import CPQuotePage  from './pages/Quote/CPQuotePage';
import CPQuoteReadOnlyPage from './pages/Quote/CPQuotePage.readonly';


setComponentMapOverrides(
    {
        CPDummyPage: {component: 'CPDummyPage'},
        CPDummyReadOnlyPage: { component: 'CPDummyReadOnlyPage' },

        CPPolicyDetailsPage: {component: 'CPPolicyDetailsPage'},
        CPPolicyDetailsReadOnlyPage: { component: 'CPPolicyDetailsReadOnlyPage' },

        CPCommercialPropertyPage: {component: 'CPCommercialPropertyPage'},
        CPCommercialPropertyReadOnlyPage: {component: 'CPCommercialPropertyReadOnlyPage'},

        CPStateSpecificInformationPage: {component: 'CPStateSpecificInformationPage'},
        CPStateSpecificInformationReadOnlyPage: { component: 'CPStateSpecificInformationReadOnlyPage' },

        CPLocationsPage: { component: "CPLocationsPage"},
        CPLocationsReadOnlyPage: { component: "CPLocationsReadOnlyPage"},

        CPRiskAnalysisPage: {component: 'CPRiskAnalysisPage'},
        CPRiskAnalysisReadOnlyPage: { component: 'CPRiskAnalysisReadOnlyPage' },

        CPModifiersPage: {component: 'CPModifiersPage'},
        CPModifiersReadOnlyPage: { component: 'CPModifiersReadOnlyPage' },

        CPBlanketsPage: { component: 'CPBlanketsPage' },
        CPBlanketsReadOnlyPage: { component: 'CPBlanketsReadOnlyPage' },

        CPRisksPage: {component: 'CPRisksPage'},
        CPRisksReadOnlyPage: { component: 'CPRisksReadOnlyPage' },
        //
        CPQuotePage: {component: 'CPQuotePage'},
        CPQuoteReadOnlyPage: {component: 'CPQuoteReadOnlyPage'},

    },
    {
        CPDummyPage,
        CPDummyReadOnlyPage,

        CPPolicyDetailsPage,
        CPPolicyDetailsReadOnlyPage,

        CPCommercialPropertyPage,
        CPCommercialPropertyReadOnlyPage,

        CPStateSpecificInformationPage,
        CPStateSpecificInformationReadOnlyPage,

        CPLocationsPage,
        CPLocationsReadOnlyPage,

        CPRiskAnalysisPage,
        CPRiskAnalysisReadOnlyPage,

        CPModifiersPage,
        CPModifiersReadOnlyPage,

        CPBlanketsPage,
        CPBlanketsReadOnlyPage,

        CPRisksPage,
        CPRisksReadOnlyPage,
        //
        CPQuotePage,
        CPQuoteReadOnlyPage,
    }
);


export {

    CPPolicyDetailsPage,
    CPPolicyDetailsReadOnlyPage,

    CPCommercialPropertyPage,
    CPCommercialPropertyReadOnlyPage,

    CPLocationsPage,
    CPLocationsReadOnlyPage,

    CPStateSpecificInformationPage,
    CPStateSpecificInformationReadOnlyPage,

    CPRiskAnalysisPage,
    CPRiskAnalysisReadOnlyPage,

    CPModifiersPage,
    CPModifiersReadOnlyPage,

    CPBlanketsPage,
    CPBlanketsReadOnlyPage,

    CPRisksPage,
    CPRisksReadOnlyPage,
    
    CPDummyPage,
    CPDummyReadOnlyPage,
    //
};

// eslint-disable-next-line import/prefer-default-export
export { default as CPSubmissionWizard } from './CPSubmissionWizard';
export { default as CPWizardPage } from './templates/CPWizardPage';

export { default as CPQuoteIssueRenderHelper } from './pages/Quote/util/CPQuoteIssueRenderHelper';
export { default as CPQuotePageConstants } from './pages/Quote/config/CPQuotePage.config';

export { default as CPPolicyLineSummaryComponent } from './pages/Quote/components/Summary/CPPolicyLineSummaryComponent';