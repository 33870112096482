import { defineMessages } from 'react-intl';

export default defineMessages({
    CRAnswerYesWarning: {
        id: 'wni.quoteandbind.cr-wizard.cr-qualification.CRAnswerYesWarning',
        defaultMessage: 'Due to your answer to [Underwriting question(s)] this application has been flagged as containing risks that do not meet criteria to quote via portal.You may continue to fill out relevant information,however,an underwriter will need to review the application before a quote can be provided'
    },
    CRValidationMessages: {
        id: 'wni.quoteandbind.cr-wizard.cr-qualification.CRValidationMessages',
        defaultMessage: 'This field is required.'
    }
});