
import React from 'react';
import { PolicyChangeUtil } from 'wni-portals-util-js';
import { GLGeneralLiabilityPage } from 'wni-capability-quoteandbind-gl-react';

function GLGeneralLiabilityChangePage(props) {
    const {
        wizardData: submissionVM
    } = props;
 
    const isDisplayAsSubmission = PolicyChangeUtil.isDisplaySubmissionComponent('GL7Line', submissionVM);
    
    if(isDisplayAsSubmission) {
        return (
            <GLGeneralLiabilityPage {...props} />
        )
    };
    return (
        <GLGeneralLiabilityPage {...props} 
        readonlyForAdditionalCov
        hideDetailsAndStandardCoverages
        readOnlyForExclCondUnlessSpecificFields
        editableFieldsForPolicyChange = {['GL7SchedNoticeOfCancellation_Ext']}
        />
    );

}

GLGeneralLiabilityChangePage.propTypes = GLGeneralLiabilityPage.propTypes;
GLGeneralLiabilityChangePage.defaultProps = GLGeneralLiabilityPage.defaultProps;

export default GLGeneralLiabilityChangePage;