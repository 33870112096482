/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { TranslatorContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ActivitiesService } from 'gw-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { Loader, withModalContext } from '@jutro/components';
import messages from 'gw-capability-gateway-react/NewQuote/NewQuoteAccountSearch.messages';
import 'gw-capability-gateway-react/NewQuote/NewQuote/NewQuotePage.messages';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import { WniAccountService, WniSubmissionService } from 'wni-capability-gateway';
import {
    AccountContactInputComponent, AccountClearanceComponent, AddressChangeVerify, getValidationMap, AddressVerifiedUtil, getVerifyAddressIssues
} from 'wni-capability-gateway-react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { StateUtil, ActivityUtil, WniDateUtil, WindowUtil } from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
// import { BRPLMessages } from 'wni-platform-translations';
import NewSubmissionPage from './NewSubmission/NewSubmissionPage';
import styles from './NewQuotePage.module.scss';
import metadata from './NewQuotePage.metadata.json5';
import currentMessages from './NewQuotePage.messages';

class NewQuotePageWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    state = {
        submissionVM: {}, // instance of NewSubmissionDTO
        producerCode: [],
        isAnExistingAccount: false,
        accountHolderDetails: {},
        accountHolderViewVM: {},
        newAccountCreate: {},
        allOrganisationValue: [],
        showErrors: false,
        isLoading: true,
        subTypeLabel: '',
        contactTypeValue: '',
        dynamicNotifications: [],
        showAccountClearance: false,
        reserVationData: {},
        sysLocalDate: {},
        // policyDetailsContainerIssues: [],
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func,
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                accountSearchCriteria: PropTypes.object,
                externalData: PropTypes.object,
            }),
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                accountNumber: PropTypes.number,
            }),
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string,
        }).isRequired,
        authUserData: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        interactionModel: PropTypes.shape({ goToPage: PropTypes.func }).isRequired,
        domainCompany: PropTypes.shape({
            availableStates: PropTypes.arrayOf(PropTypes.shape({}))
        }).isRequired,
        ...validationPropTypes
    };

    localeService = ServiceManager.getService('locale-service');

    defaultCountryCode = this.localeService.getDefaultCountryCode();
    
    updateAccountHolderVM = (accountHolderViewVM) => {
        this.setState({ accountHolderViewVM });
    };

    getAccountHolderDetails = () => {
        const { accountHolderDetails } = this.state;
        let isAnExistingAccount;
        const accountHolder = {
            contactName: '',
            primaryAddress: {},
        };
        const {
            match: {
                params: { accountNumber },
            },
        } = this.props;
        if (accountNumber) {
            isAnExistingAccount = true;
            this.findAccount();
        } else {
            this.setState({
                isLoading: false,
            });
            const {
                location: { state }
            } = this.props;
            const accountSearchCriteria = (state && state.accountSearchCriteria)
                || { contactType: 'person', country: this.defaultCountryCode };

            isAnExistingAccount = false;
            const criteriaContactType = accountSearchCriteria.contactType;
            accountHolderDetails.contactType = _.upperFirst(
                criteriaContactType
            );
            if (accountHolderDetails.contactType === 'Person') {
                accountHolder.firstName = accountSearchCriteria.firstName;
                accountHolder.lastName = accountSearchCriteria.lastName;
                if (accountSearchCriteria.lastNameKanji) {
                    accountHolder.firstNameKanji = accountSearchCriteria.firstNameKanji;
                    accountHolder.lastNameKanji = accountSearchCriteria.lastNameKanji;
                }
            } else {
                accountHolder.contactName = accountSearchCriteria.contactName;
                if (accountSearchCriteria.contactNameKanji) {
                    accountHolder.contactNameKanji = accountSearchCriteria.contactNameKanji;
                }
            }
            accountHolder.primaryAddress.city = accountSearchCriteria.city;
            accountHolder.primaryAddress.state = accountSearchCriteria.state;
            accountHolder.primaryAddress.postalCode = accountSearchCriteria.postalCode;
            accountHolder.primaryAddress.country = accountSearchCriteria.country;
            if (accountSearchCriteria.cityKanji) {
                accountHolder.primaryAddress.cityKanji = accountSearchCriteria.cityKanji;
            }

            // this.setAccountViewVM({ accountHolder });
            this.setAccountHolderViewVM(accountHolder);

            accountSearchCriteria.contactName = '';
            accountSearchCriteria.lastName = '';
            accountSearchCriteria.firstName = '';
            accountSearchCriteria.city = '';
            accountSearchCriteria.state = '';
            accountSearchCriteria.postalCode = '';
        }
        this.setState({
            accountHolderDetails,
            isAnExistingAccount,
        });
    };

    componentDidMount = async () => {
        let { submissionVM } = this.state;
        const { viewModelService, location: { state }, authHeader } = this.props;
        const model = {
            country: this.defaultCountryCode,
        };
        const sysLocalDate = await WniSubmissionService.getSystemDate(authHeader);
        this.setState({ sysLocalDate: sysLocalDate });
        submissionVM = viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.job.submission.dto.NewSubmissionDTO'
        );

        if (state && !_.isEmpty(state.externalData)) {
            const exData = state.externalData;
            const agency = await this.getAgencyInfo(exData.producerCode);
            if (!_.isNil(agency)) {
                const agencyInfo = agency[0];
                const agencyDisplay = `${agencyInfo.code} - `
                // + `${agencyInfo.orgName_Ext ? `${agencyInfo.orgName_Ext}-` : null} `
                + `${agencyInfo.description ? `${agencyInfo.description}-` : null} `
                + `${agencyInfo.city_Ext ? agencyInfo.city_Ext : null}, `
                + `${agencyInfo.state_Ext ? agencyInfo.state_Ext : null}`;
                _.set(submissionVM, 'producerCodePublicID_Ext', agencyInfo.publicID);
                _.set(submissionVM.value, 'producerDisplay_Ext', agencyDisplay);
            }

            _.set(submissionVM, 'state', exData.stateCode);
            _.set(submissionVM, 'effectiveDate', WniDateUtil.parseDateExternal(exData.effectiveDate));
            _.set(submissionVM, 'producerCode', exData.producerCode);
            _.set(submissionVM, 'productCode', exData.productCode);
        } else {
            _.set(submissionVM, 'effectiveDate', sysLocalDate);
            _.set(submissionVM, 'productCode', '');
        }

        this.setState(
            {
                submissionVM,
            },
            () => {
                this.getAccountHolderDetails();
            }
        );
    };

    getAgencyInfo = async (producerCode) => {
        const { authHeader } = this.props;
        const agencyRes = await WniAccountService.getAgencyMatchData(producerCode, 5, authHeader)
        if (!_.isNil(agencyRes.length) && agencyRes.length > 0) {
            return agencyRes.slice(0, 5);
        }
        return null;
    }

    findAccount = async (accNumber) => {
        const { accountHolderDetails, submissionVM } = this.state;
        const { match: { params: { accountNumber } }, domainCompany } = this.props;
        const accNum = accNumber || accountNumber;
        const { authHeader } = this.props;
        const account = await AccountService.getAccountDetails(accNum, authHeader);

        if (account.accountHolder) {
            accountHolderDetails.contactType = account.accountHolder.subtype;
            this.updateContactType(accountHolderDetails.contactType);

            accountHolderDetails.newAccount = account;
            // _.set(submissionVM, 'state', account.accountHolder.primaryAddress.state);
            StateUtil.tryToUpdateNewQuoteState(submissionVM, account.accountHolder.primaryAddress.state, domainCompany);
            accountHolderDetails.displayAddressArray = account.accountHolder.primaryAddress.displayName.replace(', ', '#@ ').split('#@ ').join('\n');
            _.set(submissionVM, 'accountNumber', account.accountNumber);
        } else {
            this.props.modalContext.showAlert({
                title: gatewayMessages.modelError,
                message: messages.accountTypeErrorMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            });
        }
        this.setState({
            accountHolderDetails,
            submissionVM,
            isLoading: false
        }, () => {
            this.setAccountHolderViewVM(accountHolderDetails.newAccount);
        });
    };

    setAccountHolderViewVM = (accountHolder) => {
        const { viewModelService } = this.props;
        let { accountHolderViewVM } = this.state;
        const { accountHolderDetails } = this.state;
        const viewModel = viewModelService.create(accountHolder, 'pc', 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO', {
            ProducerCodeRequired: false,
            PhoneRequired: true
        });
        _.set(viewModel, 'isAgentPortalContact', true);
        _.set(viewModel, 'phoneRequired_Ext', true);
        if (accountHolderDetails.contactType === 'Person') {
            _.set(viewModel, 'subtype.value', 'Person');
            // BA finding 111: Default address type to home and hide it for Personal Auto
            _.set(viewModel.value, 'primaryAddress.addressType', 'home');
        } else {
            _.set(viewModel, 'subtype.value', 'Company');
        }
        accountHolderViewVM = viewModel;
        this.updateAccountHolderVM(accountHolderViewVM);
    };

    generateAccountObjByAOCode = (selectedProducerCode, selectedPublicId) => {
        const {
            accountHolderDetails, accountHolderViewVM
        } = this.state;
        const newAccount = {};
        newAccount.accountHolder = _.get(accountHolderViewVM, 'value');
        if (accountHolderDetails.contactType === 'Person') {
            newAccount.accountHolder.contactName = `${accountHolderViewVM.firstName.value} ${accountHolderViewVM.lastName.value}`;
            newAccount.accountHolder.primaryAddress.addressType = 'home';
        }

        newAccount.producerCodes = [{
            code: selectedProducerCode,
            publicID: selectedPublicId
        }];
        return newAccount;
    };


    generateAccountObj = () => {
        const {
            accountHolderDetails, accountHolderViewVM,
            producerCode: producerCodeOptions,
        } = this.state;
        const newAccount = {
            accountHolder: {
                primaryAddress: {
                    addressType: 'business'
                }
            }
        };
        newAccount.accountHolder = _.get(accountHolderViewVM, 'value');
        if (accountHolderDetails.contactType === 'Person') {
            newAccount.accountHolder.contactName = `${accountHolderViewVM.firstName.value} ${accountHolderViewVM.lastName.value}`;
            newAccount.accountHolder.primaryAddress.addressType = 'home';
        }

        newAccount.producerCodes = producerCodeOptions.map((producerCode) => {
            return {
                code: producerCode.code,
                publicID: producerCode.publicID
            };
        });
        return newAccount;
    };

    onContinueCreate = () => {
        const { submissionVM } = this.state;
        const selectedAoAgencyCode = _.get(submissionVM.value, 'producerCode');
        const selectedAoPublicId = _.get(submissionVM.value, 'producerCodePublicID_Ext');
        if (!_.isEmpty(selectedAoAgencyCode)) {
            /**
             * Get the producer code from AO path to generate the Account Object
             */
            const selectedAoAccount = this.generateAccountObjByAOCode(selectedAoAgencyCode, selectedAoPublicId);
            this.createNewAccount(selectedAoAccount);
        } else {
            const newAccount = this.generateAccountObj();
            this.createNewAccount(newAccount);
        }
    };

    updateAddressFlag = (flag) => {
        this.setState({ isAddressFlag: flag });
    };

    onCreateNewAccount = async () => {
        const { registerComponentValidation } = this.props;
        await registerComponentValidation(this.validRegisterComponent);
        const { accountHolderViewVM, isAddressFlag } = this.state;
        const { isComponentValid } = this.props;
        if (!isComponentValid) {
            this.handleValidation();
            return false;
        }
        const { authHeader } = this.props;
        const uiUtil = AddressVerifiedUtil({
            authHeader,
            addressVM: _.get(accountHolderViewVM, 'primaryAddress'),
            addressPath: 'primaryAddress',
            isAddressFlag,
            updateAddressFlag: this.updateAddressFlag,
            updateValidations: this.updateLookupValidation,
            writeValue: this.onAccountContactChange,
            //
            modalApi: this.props.modalContext,
        });
        const verifiedObj = await uiUtil.onVerified();

        if (!verifiedObj.isVerified) {
            return false;
        }

        let { showAccountClearance, reserVationData } = this.state;
        const newAccount = this.generateAccountObj();

        reserVationData = await WniAccountService.getReservationAccounts(newAccount, authHeader);
        const matchAccounts = this.filterPolicyWithAgency(reserVationData.matchAccounts);
        const poMatchAccounts = this.filterPolicyWithAgency(reserVationData.poMatchAccounts);
        if (!_.isEmpty(matchAccounts) || !_.isEmpty(poMatchAccounts)) {
            showAccountClearance = true;
            reserVationData.matchAccounts = matchAccounts;
            reserVationData.poMatchAccounts = poMatchAccounts;
            this.setState({ showAccountClearance, reserVationData });
        } else {
            this.createNewAccount(newAccount);
        }
        return true;
    };

    filterPolicyWithAgency = (matchAccounts) => {
        const { producerCode } = this.state;
        const tempList = [];
        matchAccounts.forEach((clearance) => {
            const obj = _.find(producerCode, (item) => {
                if (!_.isEmpty(clearance.producerCodes)) {
                    const hasMatched = _.find(clearance.producerCodes, (matchCode) => {
                        return matchCode === item.code;
                    });
                    if (hasMatched) {
                        return true;
                    }
                }
                return false;
            });
            if (obj) {
                tempList.push(clearance);
            }
        });
        return tempList;
    };

    createNewAccount = async (newAccount) => {
        const { newAccountCreate, submissionVM } = this.state;
        const { authHeader, history, loadingMask: { setLoadingMask }, domainCompany } = this.props;
        let { isAnExistingAccount } = this.state;
        setLoadingMask(true);
        const newAccountResponse = await AccountService.getOrCreateAccount(newAccount, authHeader);
        setLoadingMask(false);
        if (!_.isEmpty(newAccountResponse.accountNumber)) {
            newAccountCreate.newAccount = newAccountResponse;
            newAccountCreate.displayAddressArray = newAccountResponse
                .accountHolder.primaryAddress
                .displayName.replace(', ', '#@ ').split('#@ ').join('\n');
            _.set(submissionVM, 'accountNumber', newAccountResponse.accountNumber);
            // _.set(submissionVM, 'state', newAccountResponse.accountHolder.primaryAddress.state);
            StateUtil.tryToUpdateNewQuoteState(submissionVM, newAccountResponse.accountHolder.primaryAddress.state, domainCompany);

            isAnExistingAccount = true;
        } else {
            this.props.modalContext.showAlert({
                title: messages.accountTypeError,
                message: messages.newAccountCreateErrorMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                window.history.back();
            });
        }
        this.setState({
            showAccountClearance: false,
            newAccountCreate,
            isAnExistingAccount,
            submissionVM
        }, () => {
            return history.push(`/new-quote/${_.get(submissionVM, 'accountNumber.value')}`);
        });
    };

    onStartNewQuote = async (accountNumber) => {
        const { authHeader, history } = this.props;
        const translator = this.context;
        const isAnExistingAccount = true;
        await this.findAccount(accountNumber);
        const activityObj = {
            accountNumber: accountNumber,
            subject: translator(currentMessages.activitySubject),
            description: translator(currentMessages.activityDescription),
            priority: 'urgent',
            mandatory: true,
            activityPattern: {
                code: 'agent_risk_reservation_referral',
                priority: 'urgent'
            }
        };
        const newActivityData = ActivityUtil.createNewActivityData(activityObj);
        await ActivitiesService.createNewActivity(newActivityData, null, authHeader);

        //
        // const { policyDetailsContainerIssues } = this.state;
        // const newPolicyDetailsIssues = [...policyDetailsContainerIssues];
        // if (allQuotesInLastYearDeclined) {
        //     newPolicyDetailsIssues.push({
        //         type: 'warning',
        //         reason: translator(BRPLMessages.BR_PL_0110),
        //     });
        // }
        this.setState({
            isAnExistingAccount,
            showAccountClearance: false,
            // policyDetailsContainerIssues: newPolicyDetailsIssues,
        }, () => {
            return history.push(`/new-quote/${accountNumber}`);
        });
    }

    handleValidation = () => {
        const { invalidFields } = this.props;
        WindowUtil.scrollToInvalidField(invalidFields);
        this.setState({ showErrors: true });
    };

    handleCancel = () => {
        const { history, interactionModel } = this.props;
        this.props.modalContext.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.close
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            interactionModel.goToPage(null, history, 'accountsHomePage');
        }, _.noop);
    };

    updateSubmission = (submissionVM) => {
        this.setState({
            submissionVM,
        });
    };

    updateSubTypeLabel = (newSubTypeLabel) => {
        this.setState({ subTypeLabel: newSubTypeLabel });
    };

    onAccountContactChange = (value, path, customUpdater = undefined) => {
        if (AddressChangeVerify(path, 'primaryAddress')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = getVerifyAddressIssues(false);
            this.updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            this.updateAddressFlag(false);
        }
        const { accountHolderViewVM } = this.state;
        const newAccountHolder = accountHolderViewVM;
        if (!customUpdater) {
            _.set(newAccountHolder, path, value);
            this.updateAccountHolderVM(newAccountHolder);
        } else {
            customUpdater(newAccountHolder);
            this.updateAccountHolderVM(newAccountHolder);
        }
    };

    updateOrgnisationOptions = (allOrganisationValue) => {
        this.setState({ allOrganisationValue });
    };

    updateProducerCodeOptions = (producerCodeOptions) => {
        this.setState({ producerCode: producerCodeOptions });
    };

    updateContactType = (contactTypeValue) => {
        this.setState({ contactTypeValue });
    };

    updateLookupValidation = (validations) => {
        const { dynamicNotifications } = this.state;
        const validationsMap = getValidationMap(validations, dynamicNotifications);
        this.setState({
            dynamicNotifications: validationsMap,
        });
    };

    validRegisterComponent = () => {
        const { accountHolderViewVM } = this.state;
        if (Object.keys(accountHolderViewVM).length === 0) { return false; }
        const {
            firstName,
            lastName,
            dateOfBirth,
            primaryPhoneType,
            homeNumber,
            cellNumber,
            workNumber,
            primaryAddress: {
                addressLine1,
                pobox_Ext: pobox,
                postalCode,
                county,
                city,
                state
            }
        } = accountHolderViewVM.value;
        const vaild = firstName && lastName && dateOfBirth && primaryPhoneType
                        && postalCode && county && city && state && (homeNumber || cellNumber || workNumber)
                        && accountHolderViewVM.aspects.subtreeValid;
        if (pobox) {
            return !!(vaild);
        }
        return !!(vaild && addressLine1);
    };


    render() {
        const {
            accountHolderViewVM, producerCode,
            accountHolderDetails, isAnExistingAccount, submissionVM,
            newAccountCreate, allOrganisationValue, showErrors,
            isLoading, contactTypeValue, dynamicNotifications,
            showAccountClearance, reserVationData, sysLocalDate,
            // policyDetailsContainerIssues,
        } = this.state;
        const {
            authHeader, authUserData,
            setComponentValidation, isHideContactType
        } = this.props;
        const translator = this.context;
        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showOptional: true,
                showErrors: showErrors
            },
            dynamicInlineNotificationContainer: {
                validationIssues: dynamicNotifications,
                visible: dynamicNotifications.length > 0,
                scrollToIssues: true,
            },
            newQuoteDetailsContainer: {
                visible: !isAnExistingAccount && !showAccountClearance,
            },
            // =========================
            policyDetailsContainer: {
                visible: isAnExistingAccount,
            },
            // policyDetailsContainerIssuesField: {
            //     validationIssues: policyDetailsContainerIssues,
            //     visible: policyDetailsContainerIssues.length > 0,
            //     scrollToIssues: true,
            // },
            contactTypeDetails: {
                value: contactTypeValue
            },
            accountContactContainer: {
                model: accountHolderViewVM,
                onAccountContactChange: this.onAccountContactChange,
                updateAddressSubType: this.updateSubTypeLabel,
                onOrganisationOptionsChange: this.updateOrgnisationOptions,
                onProducerCodeOptionsChange: this.updateProducerCodeOptions,
                onContactTypeChange: this.updateContactType,
                showErrors,
                isHideContactType,
                onValidate: setComponentValidation
            },
            addressDetails: {
                value: _.get(newAccountCreate, 'displayAddressArray') ? `${_.get(newAccountCreate, 'newAccount.accountHolder.displayName')
                }\n${_.get(newAccountCreate, 'displayAddressArray')}` : `${_.get(accountHolderDetails, 'newAccount.accountHolder.displayName')
                }\n${_.get(accountHolderDetails, 'displayAddressArray')}`
            },
            accountNumberDetails: {
                value: _.get(submissionVM, 'accountNumber.value'),
            },
            // ===================
            newSubmission: {
                visible: isAnExistingAccount,
                isAnExistingAccount: isAnExistingAccount,
                submissionVM: submissionVM,
                postalCode: _.get(newAccountCreate.newAccount, 'accountHolder.primaryAddress.postalCode')
                  || _.get(accountHolderDetails.newAccount, 'accountHolder.primaryAddress.postalCode'),
                updateSubmissionVM: this.updateSubmission,
                producerCodeValue: authUserData && authUserData.userType === 'producer' ? producerCode : [],
                isAgent: authUserData && authUserData.userType === 'producer',
                allOrganisationValue: allOrganisationValue,
                authHeader: authHeader,
                sysLocalDate
            },
            // ======================
            clearanceContainer: {
                visible: showAccountClearance
            },
            matchedTable: {
                onStartNewQuote: this.onStartNewQuote,
                submissionVM: submissionVM,
                tableData: _.isEmpty(reserVationData) ? [] : reserVationData.matchAccounts,
                visible: !_.isEmpty(reserVationData.matchAccounts)
            },
            potentialTable: {
                onStartNewQuote: this.onStartNewQuote,
                submissionVM: submissionVM,
                tableData: _.isEmpty(reserVationData) ? [] : reserVationData.poMatchAccounts,
            },
            matchedSubTitle: {
                content: !_.isEmpty(reserVationData.matchAccounts) ? translator(currentMessages.matchingTitle) : translator(currentMessages.noMatchingTitle)
            },
            matchedSubMsg: {
                visible: !_.isEmpty(reserVationData.matchAccounts)
            },
            potentialSubMsg: {
                content: !_.isEmpty(reserVationData.matchAccounts) ? translator(currentMessages.potentialTitle) : translator(currentMessages.noMatchingPotentialTitle)
            },
            potentialContainer: {
                visible: !_.isEmpty(reserVationData.poMatchAccounts)
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                validationissuescomponent: ValidationIssuesComponent,
                accountcontactinput: AccountContactInputComponent,
                newsubmission: NewSubmissionPage,
                accountClearanceComponent: AccountClearanceComponent
            },
            resolveCallbackMap: {
                submitCreateAccountForm: this.onCreateNewAccount,
                onCancel: this.handleCancel,
                onContinueCreate: this.onContinueCreate
            },
        };
        if (isLoading) {
            return <Loader loaded={isLoading} />;
        }

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                accountHolderViewVM,
                id,
                path,
                overrideProps
            );
        };
        return (
            <div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountHolderViewVM}
                    onModelChange={this.updateAccountHolderVM}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={setComponentValidation}
                    resolveValue={readValue}
                    showErrors={showErrors}
                />
            </div>
        );
    }
}
const NewQuotePage = withModalContext(NewQuotePageWithoutModalContext);
export const NewQuoteComponent = NewQuotePage;
export default withValidation(
    withRouter(withViewModelService(withAuthenticationContext(withDependencies(['loadingMask', 'interactionModel', 'domainCompany'])(NewQuotePage))))
);
