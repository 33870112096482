import React from 'react'
import RiskComponentType from './RiskItemComponentType'
import Building from '../Building'
import Occupancy from '../Occupancy'
import SpecialClass from '../SpecialClass';
import SpecClsBI from '../SpecClsBI';
import BusinessIncome from '../BusinessIncome'
import PersonalProperty from '../PersonalProperty'


const RiskItemComponent = (props) => {

    const {
        riskItem,
        jobID,
        sessionUUID,
        submissionVM,
        updateSubmissionVM,
        showErrors,
        isReadOnly,
        handleCloseRisk,
    } = props

    const {
        coverableType,
    } = riskItem

    const riskComponentCommonProps = {
        riskItem,
        jobID,
        sessionUUID,
        submissionVM,
        updateSubmissionVM,
        showErrors,
        isReadOnly,
        handleCloseRisk,
    }

    switch (coverableType) {
        case RiskComponentType.Building:
            return <Building
                {...riskComponentCommonProps}
            />
        case RiskComponentType.SpecialClass:
            return <SpecialClass
                {...riskComponentCommonProps}
            />
        case RiskComponentType.SpecialClassBusinessIncome:
            return <SpecClsBI
            {...riskComponentCommonProps}
            />
        case RiskComponentType.Occupancy:
            return <Occupancy
                {...riskComponentCommonProps}
            />
        case RiskComponentType.BusinessIncome:
            return <BusinessIncome
                {...riskComponentCommonProps}
            />
        case RiskComponentType.PersonalProperty:
            return <PersonalProperty
                {...riskComponentCommonProps}
            />
        default:
            return null
    }
}

const RiskItemComponentWrapper = (Component) => {

    const Wrapper = (props) => {

        const {
            riskItem,
            setSelectedRiskItem,
            updateNewRiskTreeRow,
            isReadOnly
        } = props

        if (!riskItem) {
            return null
        }

        const handleCloseRisk = (generateNewTiskTreeRowFunc) => {
            setSelectedRiskItem(undefined)
            if (isReadOnly) {
                return
            }
            updateNewRiskTreeRow((oldTreeRows) => generateNewTiskTreeRowFunc(oldTreeRows))
        }

        return <Component {...props} handleCloseRisk={handleCloseRisk}/>

    }

    return Wrapper
}


export default RiskItemComponentWrapper(RiskItemComponent)