import React, { Component } from 'react';
import { Icon } from '@jutro/components';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { AccountService } from 'gw-capability-gateway';
import { DatatableUtil, LobIconUtil } from '@xengage/gw-portals-util-js';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import metadata from './OpenQuotes.metadata.json5';
import styles from './OpenQuotes.module.scss';
import messages from './OpenQuotes.messages';
import gatewayMessages from '../../gateway.messages';

class AccountOpenQuotes extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        intl: PropTypes.func.isRequired
    };

    static contextType = TranslatorContext;

    state = {
        claimResponse: '',
        claimDataTable: '',
        selectedClaimLOB: '',
        searchFilter: ''
    };

    componentDidMount = () => {
        const translator = this.context;
        const {
            authHeader,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;

        AccountService.findSubmissionSummariesByAccount(accountNumber, authHeader).then(
            (responseData) => {
                this.setState({
                    claimResponse: responseData
                });
                const response = this.getClaimModelResponse(responseData);
                const filter = {
                    type: 'lobFilter',
                    value: translator(messages.openQuotesOpenNotBoundLabel)
                };
                this.getClaimDataTable(response, filter);
                this.setState({
                    claimResponse: response,
                    selectedClaimLOB: translator(messages.openQuotesOpenNotBoundLabel)
                });
            }
        );
    };

    findSubmissionSummariesByAccount = async () => {
        const response = await AccountService.findSubmissionSummariesByAccount();
        return response;
    };

    getClaimModelResponse = (responseData) => {
        const claimsData = responseData;
        return {
            claimsData: claimsData,
            claimLOBOptions: this.getClaimLOBOptions(claimsData)
        };
    };

    isIssuedInLastXDays = (submission, days) => {
        return (
            moment().isSame(submission.closeDate, 'day')
            || moment(submission.closeDate).isAfter(moment().subtract(days, 'days'), 'day')
        );
    };

    getLOBFilterValues = (claimsArrayResult, filter) => {
        const translator = this.context;
        return claimsArrayResult.filter((activitiesInfo) => {
            const isIssuedInLastXDays = 30;
            if (
                filter.value === translator(messages.openQuotesLastDaysLabel)
                && activitiesInfo.policyIssued
                && this.isIssuedInLastXDays(activitiesInfo, isIssuedInLastXDays)
            ) {
                this.setJobStatus(activitiesInfo);
                return true;
            }
            if (filter.value === translator(messages.openQuotesOpenBoundLabel)) {
                return (
                    activitiesInfo.status === 'Bound'
                    && (!activitiesInfo.policyIssued || !activitiesInfo.closeDate)
                );
            }
            if (filter.value === translator(messages.openQuotesOpenNotBoundLabel)) {
                return activitiesInfo.status !== 'Bound' && !activitiesInfo.closeDate;
            }
            return false;
        });
    };

    getSearchFilterValues = (claimsArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();
        return _.filter(claimsArrayResult, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    setJobStatus = (activitiesInfo) => {
        const translator = this.context;
        if (activitiesInfo.policyIssued) {
            _.set(activitiesInfo, 'status', translator(gatewayMessages.issued));
        }
        if (activitiesInfo.status === 'Not-taken') {
            _.set(activitiesInfo, 'status', translator(gatewayMessages.notTaken));
        }
    };

    getClaimDataTable = (claimsData, filter) => {
        const { searchFilter } = this.state;
        let claimsArrayResult = claimsData.claimsData;
        const combineFilter = {};
        switch (filter.type) {
            case 'lobFilter':
                claimsArrayResult = this.getLOBFilterValues(claimsArrayResult, filter);
                if (searchFilter && claimsArrayResult) {
                    combineFilter.value = searchFilter;
                    claimsArrayResult = this.getSearchFilterValues(
                        claimsArrayResult,
                        combineFilter
                    );
                }
                break;
            case 'searchFilter':
                claimsArrayResult = this.getSearchFilterValues(claimsArrayResult, filter);
                if (
                    typeof this.selectedClaimLOB === 'string'
                    && this.selectedClaimLOB !== 'Filters'
                ) {
                    combineFilter.value = this.selectedClaimLOB;
                    claimsArrayResult = this.getLOBFilterValues(claimsArrayResult, combineFilter);
                }
                break;
            case null:
                if (searchFilter) {
                    combineFilter.value = searchFilter;
                    claimsArrayResult = this.getSearchFilterValues(
                        claimsArrayResult,
                        combineFilter
                    );
                }
                break;
            default:
                claimsArrayResult = claimsData.claimsData;
                break;
        }
        claimsArrayResult = claimsArrayResult.map((claimInfo) => {
            const claims = {
                created:claimInfo.createTime,
                product: claimInfo.product.productCode,
                quote: claimInfo.jobNumber,
                premium: claimInfo.totalPremium,
                status: claimInfo.status
            };
            return claims;
        });
        this.setState({
            claimDataTable: claimsArrayResult
        });
    };

    getClaimLOBOptions = (response) => {
        const translator = this.context;
        const claimLOBOptionsArray = [
            translator(messages.openQuotesLastDaysLabel),
            translator(messages.openQuotesOpenNotBoundLabel),
            translator(messages.openQuotesOpenBoundLabel)
        ];
        if (_.isEmpty(response)) {
            return [];
        }
        return claimLOBOptionsArray.map((key) => {
            return {
                code: key,
                name: key
            };
        });
    };

    handleNewQuoteClick = () => {
        const {
            history,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        return history.push(`/new-quote/${accountNumber}`);
    };

    handleFilterChange = (value, id) => {
        const { claimResponse } = this.state;
        const filter = {
            type: null,
            value: null
        };
        if (id === 'lobFilter') {
            if (value !== 'Filters') {
                filter.type = 'lobFilter';
                filter.value = value;
            }
            this.setState(
                {
                    selectedClaimLOB: value
                },
                () => {
                    this.getClaimDataTable(claimResponse, filter);
                }
            );
        }
        if (id === 'searchFilter') {
            filter.type = 'searchFilter';
            filter.value = value;
            this.setState(
                {
                    searchFilter: value
                },
                () => {
                    this.getClaimDataTable(claimResponse, filter);
                }
            );
        }
    };

    getCell = (items, index, property) => {
        const translator = this.context;
        const toolTipMessage = {
            status: translator(messages.openQuoteStatusLabel),
            created: translator(messages.openQuotesCreatedLabel)
        };
        return <span title={toolTipMessage[property.id]}>{items[property.id]}</span>;
    };

    getLink = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.openQuoteLabel);
        return (
            <span title={toolTipMessage}>
                <Link to={`/quotes/${item[property.id]}/summary`}>{item[property.id]}</Link>
            </span>
        );
    };

    getCurrency = (item, index, property) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.premium);
        return (
            <span title={toolTipMessage}>
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[property.id]}
                    readOnly
                    hideLabel
                    showOptional={false}
                    className={styles.currencyColumn}
                />
            </span>
        );
    };

    getMetadataImplementation = () => {
        const resolvers = {
            resolveClassNameMap: styles
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={{}} {...resolvers} />;
    };

    getProductImage = (item) => {
        const icon = LobIconUtil.getFontIcon(item.product);
        return (
            <Icon
                icon={icon}
                title={item.product}
            />
        );
    };

    render() {
        const {
            claimResponse, claimDataTable, selectedClaimLOB, searchFilter
        } = this.state;
        if (_.isEmpty(claimResponse)) {
            return null;
        }
        const overrides = {
            quoteDataTable: {
                data: claimDataTable
            },
            lobFilter: {
                availableValues: claimResponse.claimLOBOptions,
                value: selectedClaimLOB
            },
            searchFilter: {
                value: searchFilter
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getLink: this.getLink,
                getProductImage: this.getProductImage,
                getCurrency: this.getCurrency,
                handleLobValueChange: (value) => this.handleFilterChange(value, 'lobFilter'),
                handleSearchValueChange: (value) => this.handleFilterChange(value, 'searchFilter'),
                handleNewQuoteClick: this.handleNewQuoteClick,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortNumber: DatatableUtil.sortNumber,
                sortCurrency: DatatableUtil.sortCurrency
            }
        };
        const claimPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return <div className={cx(styles.summary)}>{claimPage}</div>;
    }
}

export const AccountOpenQuotesComponent = AccountOpenQuotes;
export default withIntl(withAuthenticationContext(AccountOpenQuotes));
