import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { HOResidenceService, HORCTService } from 'wni-capability-quoteandbind-ho';
import {
    QuoteUtil,
    IssuanceValidationUtil,
    ValidationIssueUtil,
    WindowUtil,
} from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { useWniModal } from 'wni-components-platform-react';
import { Link } from '@jutro/components';
import WizardPage from '../../templates/HOWizardPage';

import ConstructionComponent from './Component/ConstructionComponent/ConstructionComponent';
import ProtectionComponent from './Component/ProtectionComponent/ProtectionComponent';
import LiabilityExposuresComponent from './Component/LiabilityExposuresComponent/LiabilityExposuresComponent';
import OtherInformationComponent from './Component/OtherInformationComponent/OtherInformationComponent';
import BusinessQuestionComponent from './Component/BusinessQuestionComponent/BusinessQuestionComponent';
import QuestionSetsComponent from './Component/QuestionSetsComponent/QuestionSetsComponent';
import RCTCallbackPopupComponent from './Component/RCTCallbackPopupComponent/RCTCallbackPopupComponent';
import DiffPopupComponent from './Component/DiffPopupComponent/DiffPopupComponent';

import ResidenceUtil from './util/ResidenceUtil';
import ResidenceValidationUtil from './util/ResidenceValidationUtil';

import styles from './HOResidencePage.module.scss';
import metadata from './HOResidencePage.metadata.json5';
import messages from './HOResidencePage.messages';

function HOResidencePage(props) {
    const modalApi = useWniModal();
    const {
        currentStep: { id: currentPageId},
        //
        wizardData: submissionVM,
        updateWizardData,
        wizardPageData,
        wizardSnapshot,
        isSkipping,
        //
        hoRctService,
        hoResidenceService,
        checkRequiredForIssuance,
        transactionType,
        isSkipPrefillRCT,
        linePath,
        customMessages,
        constructionFieldReadOnlyFlags,
        componentMapOverride,
        DPOverrideProps,
        DP2Condo,
        isReadonlyFireProtectionClass
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [showErrors, updateShowErrors] = useState(false);
    const { authHeader } = useAuthentication();
    const [isRCTSuccess, updateIsRCTSuccess] = useState(true);
    const [callRCEFlag, updateCallRCEFlag] = useState(false);
    const [RCTSubmission, updateRCTSubmission] = useState();
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const {
        jobID,
        sessionUUID,
        baseData
    } = submissionVM.value;

    const dwellingPath = `lobData.${linePath}.coverables.dwelling_Ext`;
    const dwellingVM = _.get(submissionVM, dwellingPath);
    const {
        prefillRCTCallFlag,
        fireProtectionFlag,
        isFeb19EntityChangeVer
    } = dwellingVM.value;

    // const isFeb19EntityChangeVer = true;

    const launchRCTExpressCallFlag = _.get(submissionVM, `lobData.${linePath}.coverables.dwelling_Ext.launchRCTExpressCallFlag`);

    const constructionPath = `lobData.${linePath}.coverables.dwelling_Ext.construction`;
    const constructionVM = _.get(submissionVM, constructionPath);
    const originalConstructionVM = _.get(wizardSnapshot, constructionPath);
    const solidFuelsPath = `${constructionPath}.solidFuelBurningDevices_Ext`;

    const protectionPath = `lobData.${linePath}.coverables.dwelling_Ext.protection`;
    const protectionVM = _.get(submissionVM, protectionPath);
    const originalProtectionVM = _.get(wizardSnapshot, protectionPath);

    const liabilityExposuresPath = `lobData.${linePath}.coverables.dwelling_Ext.liabilityExposures`;
    const liabilityExposuresVM = _.get(submissionVM, liabilityExposuresPath);
    const originalLiabilityExposuresVM = _.get(wizardSnapshot, liabilityExposuresPath);

    const businessQuestionsPath = `lobData.${linePath}.coverables.dwelling_Ext.businessQuestions`;
    const businessQuestionsVM = _.get(submissionVM, businessQuestionsPath);

    const animalsPath = `lobData.${linePath}.coverables.dwelling_Ext.dwellingAnimals`;
    const dwellingAnimalsVM = _.get(submissionVM, animalsPath)

    const questionSetPath = `lobData.${linePath}.coverables.dwelling_Ext.dwellingQuestionSets`;
    const dwellingQuestionSetsVM = _.get(submissionVM, questionSetPath);

    const isSubmissionType = _.get(submissionVM.value, 'baseData.jobType');

    const foundationType = _.get(constructionVM, 'foundationType.value');
    const basementFinishedPercentage = _.get(constructionVM, 'basementFinishedPercentage_Ext.value');
    const coverageForm = _.get(submissionVM, `value.${constructionPath}.coverageForm_Ext`);
    const translator = useTranslator();
    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuanceR2(baseData, wizardPageData);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);

    const {
        source_Ext: source,
        effectiveDate_Ext: effectiveDate,
        baseState_Ext: baseState,
        isAPISource_Ext: isAPISource,
        policyInfoSource_Ext: policySource,
    } = _.get(submissionVM, 'value.baseData')

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation,
        invalidFields
    } = useValidation('HOResidencePage');

    const isShowRCENote = coverageForm !== 'ho4';
    const requiredInfoMessages = useMemo(() => {
        return translator(messages.residenceRequiredNotification, {
            fasterQuote: <span className="font-Primary-bold-16">{translator(messages.residenceFasterQuote)}</span>,
            asteriskIcon: <span className="font-error-bold-16">*</span>,
            RCENote: isShowRCENote ? <div>{translator(messages.residenceRCENote)}</div> : ''
        })
    }, [])

    useEffect(() => {
        // clear errorsAndWarnings, workaround
        _.set(submissionVM, 'errorsAndWarnings', []);
        const newValidationIssues = ResidenceUtil.updateBRValidationIssues(submissionVM, validationIssues, translator, linePath, messages);
        updateValidationIssues(newValidationIssues);
    }, []);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const updateNoValidationDwelling = useCallback(async () => {
        const dwellingValue = ResidenceUtil.buildDwellingValue(dwellingVM);
        const allDwellingData = {};
        _.assign(allDwellingData, dwellingValue.construction, dwellingValue.liabilityExposures, dwellingValue.protection, dwellingValue.businessQuestions);
        _.forEach(allDwellingData, (value, key) => {
            if (value === '') {
                delete allDwellingData[key];
            }
        });
        _.set(allDwellingData, 'dwellingQuestionSets', dwellingValue.dwellingQuestionSets);
        const requestData = {
            jobID,
            sessionUUID,
            dwelling: allDwellingData
        };
        const res = await hoResidenceService.updateNoValidationDwelling(requestData, authHeader);
        return res;
    }, [dwellingVM]);

    const launchRCTURLFailPopup = useCallback(() => {
        modalApi.showConfirm({
            title: messages.RCTWebsiteCallbackFail,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.ok
        }).then(
            async (res) => {
                if (res === 'confirm') {
                    await launchRCTURL();
                }
            }, _.noop
        );
    }, []);

    const launchRCTURL = useCallback(async () => {
        updateServiceCallInProgress(true);
        await updateNoValidationDwelling();
        const postalCode = _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress.postalCode');
        const productCode = _.get(submissionVM, 'value.baseData.productCode');
        const policyNumber = _.get(submissionVM, 'value.changeData.policyNumber');
        const callbackUrl = `${window.location.origin}/RCT/${transactionType}/${postalCode}/${productCode}/${jobID}/${policyNumber}`;
        const url = await hoRctService.launchRCTExpress(jobID, sessionUUID, callbackUrl, authHeader);
        if (_.isEmpty(url)) {
            updateCallRCEFlag(false);
            launchRCTURLFailPopup();
            if (!prefillRCTCallFlag) {
                updateIsRCTSuccess(false);
            }
        } else {
            updateCallRCEFlag(true);
            updateIsRCTSuccess(true);
            window.location.href = url;
        }
        updateServiceCallInProgress(false);
    }, [prefillRCTCallFlag, callRCEFlag]);

    const showRCTEmptyFieldsPopup = useCallback((dwelling) => {
        const emptyFields = ResidenceUtil.getEmptyRCTFields(dwelling, translator, isFeb19EntityChangeVer, customMessages);
        if (emptyFields.length > 0) {
            const componentProps = {
                model: emptyFields,
                launchRCTURL
            };
            modalApi.showModal(
                <RCTCallbackPopupComponent {...componentProps} />
            ).then(() => {}).catch(() => { _.noop(); })
        }
    }, [launchRCTURL]);

    
    const updateSubmissionVM = useCallback((submission) => {
        submissionVM.value = submission;
        updateWizardData(submissionVM);
        return submissionVM;
    }, [submissionVM]);

    const callRCTPt2 = useCallback(async (prefillRCTID, type) => {
        updateServiceCallInProgress(true);
        let res;
        if (type === 'prefill') {
            res = await hoRctService.prefillRCTByRiskAddressPt2(jobID, sessionUUID, prefillRCTID, authHeader);
        } else if (type === 'assumption') {
            res = await hoRctService.assumptionsRCTCallPt2(jobID, sessionUUID, prefillRCTID, authHeader);
        }
        updateServiceCallInProgress(false);
        const resWithouEmpty = ResidenceUtil.updateSubmissionVMWithoutEmpty(submissionVM.value, res, linePath);
        updateSubmissionVM(resWithouEmpty);
    }, [submissionVM]);

    const showDiffPopup = useCallback((dwelling, type) => {

        // POI-21498 DP don't have this popup
        if (linePath === 'dwellingProperty') {
            return;
        }
        const callBackRCTFlag = _.get(dwelling, 'prefillRCTCallFlag');
        if (!callBackRCTFlag) {
            return;
        }

        const initalDwelling = dwellingVM.value || {};
        const expandedDwelling = {};
        _.assign(expandedDwelling,
            initalDwelling.construction,
            initalDwelling.liabilityExposures,
            initalDwelling.protection,
            initalDwelling.businessQuestions);
        const diffFields = ResidenceUtil.getDiffFields(expandedDwelling, dwelling, translator, isFeb19EntityChangeVer, customMessages);
        if (diffFields.length > 0) {
            const componentProps = {
                model: diffFields,
                launchRCTURL,
                compRaterDwelling: expandedDwelling,
                RCTDwelling: dwelling
            };
            modalApi.showModal(
                <DiffPopupComponent {...componentProps}/>
            ).then(() => {
                callRCTPt2(dwelling.prefillRCTID_Ext, type);
            }).catch(() => { _.noop(); });
        }
    }, [RCTSubmission, dwellingVM, launchRCTURL, updateSubmissionVM]);

    const prefillRCTByRiskAddress = useCallback(async () => {
        // ho4 will not call assumption and RCE
        if (coverageForm === 'ho4') {
            updateIsRCTSuccess(false);
            return;
        }
        if (isSkipping || isSkipPrefillRCT) {
            return;
        }
        if (!prefillRCTCallFlag) {
            updateServiceCallInProgress(true);
            const res = await hoRctService.prefillRCTByRiskAddress(jobID, sessionUUID, authHeader);
            const dwelling = _.get(res, dwellingPath);
            const callBackRCTFlag = _.get(res, `lobData.${linePath}.coverables.dwelling_Ext.prefillRCTCallFlag`);
            if (callBackRCTFlag) {
                updateRCTSubmission(res);
                if (coverageForm !== 'ho6') {
                    showRCTEmptyFieldsPopup(dwelling);
                }
                const resWithouEmpty = ResidenceUtil.updateSubmissionVMWithoutEmpty(submissionVM.value, res, linePath);
                updateSubmissionVM(resWithouEmpty);
            }
            updateServiceCallInProgress(false);
        }
    }, [coverageForm, prefillRCTCallFlag, isSkipping]);

    const callRCTPt1 = useCallback(async (type) => {
        if (coverageForm === 'ho4') {
            updateIsRCTSuccess(false);
            return;
        }
        if (isSkipping || isSkipPrefillRCT) {
            return;
        }
        if (!prefillRCTCallFlag) {
            updateServiceCallInProgress(true);
            let res;
            if (type === 'prefill') {
                res = await hoRctService.prefillRCTByRiskAddressPt1(jobID, sessionUUID, authHeader);
                const callBackRCTFlag = _.get(res, 'prefillRCTCallFlag');
                if (!callBackRCTFlag) {
                    res = await hoRctService.assumptionsRCTCallPt1(jobID, sessionUUID, authHeader);
                }
                showDiffPopup(res, type);
            } else if (type === 'assumption') {
                res = await hoRctService.assumptionsRCTCallPt1(jobID, sessionUUID, authHeader);
                showDiffPopup(res, type);
            }
            updateServiceCallInProgress(false);
        }
    }, [coverageForm, isSkipping, prefillRCTCallFlag]);

    const getBR339WarningMessage = () => {
        const RCELinkProps = {
            onClick: launchRCTURL
        }
        return translator(messages.BR339WarningMessage, {
            ViewEditRCE: <Link {...RCELinkProps}>{translator(messages.ViewEditRCE)}</Link>
        })
    }

    const assumptionsRCTCallService = useCallback(async () => {
        if (coverageForm === 'ho4' || prefillRCTCallFlag || isSkipPrefillRCT) {
            return false;
        }
        updateServiceCallInProgress(true);
        const {
            yearBuilt,
            storiesNumber_Ext: storiesNumber,
            totalLivingArea_Ext: totalLivingArea
        } = _.get(submissionVM, `value.${constructionPath}`);
        const assumptionRequestDTO = {
            yearBuilt,
            storiesNumber,
            totalLivingArea
        };
        const res = await hoRctService.assumptionsRCTCall(jobID, sessionUUID, assumptionRequestDTO, authHeader);
        const callBackRCTFlag = _.get(res, `lobData.${linePath}.coverables.dwelling_Ext.prefillRCTCallFlag`);
        if (callBackRCTFlag) {
            const resWithouEmpty = ResidenceUtil.updateSubmissionVMWithoutEmpty(submissionVM.value, res, linePath);
            updateSubmissionVM(resWithouEmpty);
            const BR343 = [{
                type: 'warning',
                reason: translator(messages.BR343WarningMessage)
            }]
            const newValidationIssues = _.concat(validationIssues, BR343)
            updateValidationIssues(newValidationIssues);
        } else {
            const BR339 = [{
                type: 'warning',
                reason: getBR339WarningMessage()
            }]
            const newValidationIssues = _.concat(validationIssues, BR339)
            updateValidationIssues(newValidationIssues);
        }
        updateServiceCallInProgress(false);
    }, [submissionVM]);

    const assumptionsRCTCall = useCallback(() => {
        if (isAPISource) {
            callRCTPt1('assumption');
        } else {
            assumptionsRCTCallService();
        }
    }, []);

    useEffect(() => {
        if (isAPISource) {
            callRCTPt1('prefill');
        } else {
            prefillRCTByRiskAddress();
        }
    }, [isSkipping]);

    const IsValidFields = useCallback(() => {
        if (dwellingVM.aspects.valid && dwellingVM.aspects.subtreeValid) {
            return true;
        }
        return false;
    }, [dwellingVM]);

    const checkShowRCTReadonlyRequiredError = useCallback(() => {
        let updatedRCTIssueList = false;
        if (!_.isNil(foundationType) && foundationType.code === "FullBasement" && (coverageForm === "ho3" || coverageForm === "ho5")) {
            if (_.isNil(basementFinishedPercentage) && !updatedRCTIssueList) {
                const newRCTReadonlyIssue = ResidenceValidationUtil.getReadonlyRCTRequiredError(translator, customMessages);
                updateValidationIssues(newRCTReadonlyIssue);
                updatedRCTIssueList = true;
            }
        }
    }, [foundationType, coverageForm]);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation, dwellingVM]);

    const handleValidation = useCallback(() => {
        checkShowRCTReadonlyRequiredError();
        updateShowErrors(true);
        return false;
    }, [invalidFields]);

    const updateSubmissionVMByAnimals = useCallback((submission) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        const animalsVal = _.get(submission, animalsPath);
        _.set(newSubmissionVM, `${animalsPath}`, animalsVal);
        submissionVM.value = newSubmissionVM.value;
        updateWizardData(submissionVM);
        return submissionVM;
    }, [submissionVM]);

    const editAnimal = useCallback(async (param) => {
        const dwellingAnimals = param.dwellingAnimals;
        const animal = param.animal;
        _.set(dwellingAnimals, 'animals', [animal]);
        const requestData = {
            jobID,
            sessionUUID,
            dwellingAnimals
        };
        const res = await hoResidenceService.editAnimal(requestData, authHeader);
        updateSubmissionVMByAnimals(res);
        return res;
    }, [submissionVM]);

    const removeAnimals = useCallback(async (animals) => {
        const requestData = {
            jobID,
            sessionUUID,
            animals
        };
        const res = await hoResidenceService.removeAnimals(requestData, authHeader);
        updateSubmissionVMByAnimals(res);
        return res;
    }, []);

    const updateSubmissionVMBySolidFuel = useCallback((submission) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        const solidFuelsVal = _.get(submission, solidFuelsPath);
        _.set(newSubmissionVM, `${solidFuelsPath}`, solidFuelsVal);
        submissionVM.value = newSubmissionVM.value;
        updateWizardData(submissionVM);
        return submissionVM;
    }, [submissionVM]);

    const editSolidFuel = useCallback(async (param) => {
        const requestData = {
            jobID,
            sessionUUID,
            solidFuel: param.solidFuel
        };
        const res = await hoResidenceService.editSolidFuel(requestData, authHeader)
        updateSubmissionVMBySolidFuel(res);
        return res;
    }, [submissionVM]);

    const removeSolidFuels = useCallback(async (solidFuels) => {
        const requestData = {
            jobID,
            sessionUUID,
            solidFuels
        };
        const res = await hoResidenceService.removeSolidFuels(requestData, authHeader);
        updateSubmissionVMBySolidFuel(res);
        return res;
    }, []);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.clone(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM, updateWizardData]);

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                isRequiredForIssuance
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                isRequiredForIssuance
            },
            construction: {
                showErrors,
                model: constructionVM,
                onValidate,
                isReadOnly: false,
                linePath,
                messages: linePath === 'homeowners' ? messages : customMessages,
                isShowHasCompletedMaintenance: linePath === 'homeowners',
                onValueChange: (value, path) => { writeValue(value, `${constructionPath}.${path}`); },
                baseState,
                effectiveDate,
                isRCTSuccess,
                prefillRCTCallFlag,
                assumptionsRCTCall,
                RCTSubmission,
                launchRCTURL,
                coverageForm,
                showBRMessage: () => {
                    updateValidationIssues(
                        ResidenceUtil.updateBRValidationIssues(submissionVM, validationIssues, translator, linePath, customMessages))
                },
                editSolidFuel,
                removeSolidFuels,
                isFeb19EntityChangeVer,
                constructionFieldReadOnlyFlags,
                launchRCTExpressCallFlag,
                originalConstructionVM,
                policySource
            },
            protection: {
                showErrors,
                model: protectionVM,
                onValidate,
                isReadOnly: false,
                onValueChange: (value, path) => { writeValue(value, `${protectionPath}.${path}`); },
                isRCTSuccess,
                RCTSubmission,
                coverageForm,
                updateValidationIssues,
                validationIssues,
                linePath,
                isReadonlyFireProtectionClass: isReadonlyFireProtectionClass || linePath === 'homeowners',
                isShowdIstanceToFire: !fireProtectionFlag || linePath === 'dwellingProperty',
                launchRCTExpressCallFlag,
                originalProtectionVM
            },
            liabilityExposures: {
                showErrors,
                model: liabilityExposuresVM,
                baseState,
                onValidate,
                isReadOnly: false,
                onValueChange: (value, path) => { writeValue(value, `${liabilityExposuresPath}.${path}`); },
                isRCTSuccess,
                RCTSubmission,
                animalsVM: dwellingAnimalsVM,
                editAnimal,
                removeAnimals,
                coverageForm,
                messages: linePath === 'homeowners' ? messages : customMessages,
                linePath,
                isFeb19EntityChangeVer,
                isSubmissionType,
                launchRCTExpressCallFlag,
                originalLiabilityExposuresVM,
                DP2Condo
            },
            otherInformation: {
                showErrors,
                model: constructionVM,
                dwellingQuestionSetsVM,
                baseState,
                onValidate,
                isReadOnly: false,
                onValueChange: (value, path) => { writeValue(value, `${questionSetPath}.${path}`); },
                isRCTSuccess,
                RCTSubmission,
                coverageForm,
                messages: linePath === 'homeowners' ? messages : customMessages,
                DP2Condo,
                linePath
            },
            businessQuestion: {
                showErrors,
                model: businessQuestionsVM,
                onValidate,
                isReadOnly: false,
                onValueChange: (value, path) => { writeValue(value, `${businessQuestionsPath}.${path}`) },
                baseState
            }
        };
    }, [showErrors,
        submissionVM,
        updateWizardData]);

    const overrideProps = _.mergeWith(generateOverrides(), DPOverrideProps, (obj, src) => {
        return {...obj, ...src}
    });

    const defaultComponentMap = {
        ConstructionComponent: ConstructionComponent,
        ProtectionComponent: ProtectionComponent,
        LiabilityExposuresComponent: LiabilityExposuresComponent,
        OtherInformationComponent: OtherInformationComponent,
        BusinessQuestionComponent: BusinessQuestionComponent,
        QuestionSetsComponent: QuestionSetsComponent,
    }
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            ...defaultComponentMap,
            ...componentMapOverride,
        }
    };

    const onPageNext = useCallback(async () => {
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            dwelling: ResidenceUtil.buildDwellingValue(dwellingVM)
        };
        const res = await hoResidenceService.updateDwelling(requestData, authHeader);
        const newSubmissionVM = updateSubmissionVM(res);
        updateServiceCallInProgress(false);

        // // update validation issues
        // const errorsAndWarningsRes = await WniCommonQuoteService.getErrorsAndWarnings(
        //     jobID, sessionUUID, currentStep.id, authHeader
        // );
        // const allValidationIssues = ResidenceValidationUtil
        //     .getAllValidationIssues(submissionVM, errorsAndWarningsRes, translator, messages, isRequiredForIssuance);
        // updateWizardPageIssues(currentPageId, allValidationIssues);

        // error block
        // const errorIssues = _.filter(validationIssues, (issue) => {
        //     return _.endsWith(issue.type, 'error');
        // });
        // if (errorIssues.length > 0) {
        //     updateShowErrors(false);
        //     return false;
        // }
        // if (validationIssues.length > 0) {
        //     if (!showErrors) {
        //         updateShowErrors(true);
        //         return false;
        //     }
        // }

        // Next step: move this and the service call into HOWizardPage as a common feature
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        let resValidationIssues = ResidenceValidationUtil.getValidationIssues(newValidationIssues);
        if(!_.isEmpty(resValidationIssues)) {
            resValidationIssues = resValidationIssues.map(issue => {
                 if(issue.type === 'warning'){
                     issue.reason = issue.reason.replace('Plus ','')
                     return issue;
                 }
                 return issue;   
             })
         };
        updateValidationIssues(resValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        // const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        const hasNewAddValidationIssue = ValidationIssueUtil.hasNewValidationIssue(validationIssues, _.uniqBy(resValidationIssues, 'reason'));
        if (hasValidationError || hasNewAddValidationIssue) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, dwellingVM, hoResidenceService, jobID, sessionUUID, updateSubmissionVM, validationIssues]);

    return (
        <WizardPage
            onNext={isComponentValid ? onPageNext : handleValidation}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            //
            showRequiredInfoForFasterQuote
            pageLevelRequiredInfoMessage={requiredInfoMessages}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={dwellingVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}



HOResidencePage.propTypes = {
    ...wizardProps,
    hoRctService: PropTypes.object,
    hoResidenceService: PropTypes.object,
    checkRequiredForIssuance:PropTypes.bool,
    transactionType: PropTypes.string,
    isSkipPrefillRCT: PropTypes.bool,
    linePath: PropTypes.string,
    isSubmissionType: PropTypes.string,
    customMessages: PropTypes.object,
    constructionFieldReadOnlyFlags: PropTypes.object,
    isReadonlyFireProtectionClass: PropTypes.bool
};

HOResidencePage.defaultProps = {
    hoRctService: HORCTService,
    hoResidenceService: HOResidenceService,
    checkRequiredForIssuance: true,
    transactionType: 'submission',
    isSkipPrefillRCT: false,
    linePath: 'homeowners',
    customMessages: messages,
    constructionFieldReadOnlyFlags: {
        isConstructionOptionReadOnly: true,
    }
};

export default HOResidencePage;
