import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _, { rest } from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
 } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useWniModal } from 'wni-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './AgencyMatchPopupComponent.metadata.json5';
import AllAgencyPopupComponent from './AllAgencyPopupComponent';

function AgencyMatchPopupComponent(props) {
    const modalApi = useWniModal();
    const {
        actionBtnLabel,
        cancelBtnLabel,
        size,
        isOpen,
        onResolve,
        onReject,
        // producerCodePath,
        // authHeader,
        // onAgencyChange,
        // updateAgencyDisplayName,
        // updateSearchValue,
        // searchValue,
        agencySearchName = '',
        agencySearchCode = '',
        updateExternalAgency,
        updateInternalAgencyName,
        useAuthenticationData,
        useDependenciesData,
        viewModelService: vmService
    } = props;

    const { authHeader, authUserData } = useAuthenticationData || useAuthentication();
    const [pageConfig, updatePageConfig] = useState(WniTableRowUtil.defaultPageConfig);
    const [agencyResponseValue, updateAgencyResponseValue] = useState([]);
    const [selectRow, updateSelectRow] = useState({});

    useEffect(() => {
        // WniAccountService.getAgencyMatchData(searchValue, 5, authHeader).then((agencyResponse) => {
        WniAccountService.searchAgency(agencySearchName, agencySearchCode, 5, authHeader).then((agencyResponse) => {
            if (!_.isNil(agencyResponse.length) && agencyResponse.length > 0) {
                _.each(agencyResponse, (item) => {
                    const name = `${item.code} - `
                        + `${item.description ? `${item.description}-` : null} `
                        + `${item.city_Ext ? item.city_Ext : null}, `
                        + `${item.state_Ext ? item.state_Ext : null}`;
                    _.set(item, 'name', name);
                });
                const fiveAgency = agencyResponse.slice(0, 5);
                updateAgencyResponseValue(fiveAgency);
            }
        });
    }, [agencySearchCode, agencySearchName, authHeader]);

    const viewModelService = vmService || useContext(ViewModelServiceContext);

    const handleSave = useCallback(() => {
        if (selectRow.code) {
            onResolve(selectRow);
        }
    }, [selectRow]);

    const showModal = useCallback(
        () => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: 'OK',
                cancelBtnLabel: 'Cancel',
                size: 'lg',
                // authHeader: authHeader,
                // agencyVM,
                // updateAgencyDisplayName,
                // updateSearchValue,
                // searchValue,
                agencySearchName,
                agencySearchCode,
                viewModelService,
                updateExternalAgency,
                updateInternalAgencyName,
                useAuthenticationData,
                useDependenciesData
            };
            return modalApi.showModal(
                <AllAgencyPopupComponent {...componentProps} />
            );
        },
        // eslint-disable-next-line max-len
        [viewModelService, updateExternalAgency]
    );

    const openAgencyMatchPopup = useCallback(() => {
        Promise.resolve(onReject()).then(() => {
            showModal().then((res) => {
                if (res) {
                    updateExternalAgency(res);
                }
            }).catch(() => {
                _.noop();
            });
        });
    }, [onReject, showModal, updateExternalAgency]);

    const hightLight = (activeRow) => {
        const selectedPublicID = _.get(activeRow, 'publicID');
        WniTableRowUtil.setTablePublicIDSelected(selectedPublicID, 'exisitingAgencyMatchTable');
    };
    const onTableConfigPage = useCallback((obj) => {
        updatePageConfig(obj);
    }, []);
    const onSort = useCallback((a, b) => {
        hightLight(selectRow);
        return DatatableUtil.sortString(a, b);
    }, [selectRow]);

    useEffect(() => {
        hightLight(selectRow);
    }, [selectRow, onTableConfigPage, pageConfig]);

    const getSelectedFn = (item) => {
        if (item.code === selectRow.code) {
            updateSelectRow({});
        } else {
            updateSelectRow(item);
        }
        hightLight(item);
    };
    const filterAgencyCodeTable = (val) => {
        return (row) => row.code.indexOf(val) !== -1;
    };

    const filterAgencyNameTable = (val) => {
        return (row) => row.orgName_Ext.indexOf(val) !== -1;
    };

    const overrideProps = {
        exisitingAgencyMatchTable: {
            data: agencyResponseValue,
            onConfigChange: onTableConfigPage,
            config: pageConfig
        },
        agencyCodeColumn: {
            filter: filterAgencyCodeTable
        },
        agencyNamecolumn: {
            filter: filterAgencyNameTable,
            renderCell: (item) => WniTableRowUtil.renderCell(item.publicID, item.description)
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            // agencyVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        // resolveClassNameMap: styles,
        resolveCallbackMap: {
            getSelectedFn: (item, index, e) => getSelectedFn(item, index, e),
            onSort
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title="Agency Search Data" onClose={onReject} />
            <ModalBody id="agencyDatailsPage">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    // model={agencyVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Grid columns={['1fr', '2fr']} justifyItems="between">
                    <Button onClick={openAgencyMatchPopup} className="btn-link pv-0 ml-0" type="text">See All Records</Button>
                    <div className="align-right">
                        <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                        <Button onClick={handleSave} type="filled" disabled={!selectRow.code}>{actionBtnLabel}</Button>
                    </div>
                </Grid>
            </ModalFooter>
        </ModalNext>
    );
}

AgencyMatchPopupComponent.propTypes = {
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    // onAgencyChange: PropTypes.func.isRequired,
    // updateAgencyDisplayName: PropTypes.func,
    // updateSearchValue: PropTypes.func,
    // authHeader: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    // producerCodePath: PropTypes.string.isRequired,
    searchValue: PropTypes.string,
    // updateProducerCodePublicID: PropTypes.func,
    updateExternalAgency: PropTypes.func,
};
AgencyMatchPopupComponent.defaultProps = {
    searchValue: '',
    size: 'md',
    // updateAgencyDisplayName: _.noop,
    // updateSearchValue: _.noop,
    // updateProducerCodePublicID: _.noop,
    updateExternalAgency: undefined
};

export default AgencyMatchPopupComponent;
