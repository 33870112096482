import React, {
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { ConfigUtil } from 'wni-portals-util-js';
import { WniAccountService } from 'wni-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import metadata from './IndustryCodePopup.metadata.json5';
import { DESCRIPTION_SEARCH_TYPE } from './IndustryCodeUtil';

const pageConfig = {
    page: 0,
    pageSize: 5
};

const generateTableData = (data) => {
    return data.map((item, index) => {
        return {
            ...item,
            rowIdPath: `${item.code}_${ConfigUtil.getUuid()}_${index}`
        }
    })
};
function IndustryCodePopup(props) {
    const {
        size,
        model,
        basePath,
        searchType,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        useAuthenticationData,
        viewModelService
    } = props;

    const { 
        authHeader,
        authUserData: {
            businessData_Ext: {
                industryCodes = []
            } = {}
    } } = useAuthenticationData;
    
    const defaultValue = {
        code: searchType !== DESCRIPTION_SEARCH_TYPE ? _.get(model, 'value.industryCode_Ext') : null,
        classification: _.get(model, 'value.industryDescription_Ext')
    }
    const allData = generateTableData(_.cloneDeep(industryCodes));
    const [vm, updateVM] = useState({});
    const [searchData, updateSearchData] = useState(defaultValue);
    const [tableData, updateTableData] = useState(generateTableData(allData));
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);

    const setVM = (value, path) => {
        const newVM = viewModelService.clone(model[basePath]);
        _.set(newVM, path, value);
        updateVM(newVM);
    };

    useEffect(() => {
        setVM({}, 'value');
    }, []);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow, 'rowIdPath') : null;
        // if(!activePublicID) { return false };
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'industryTable');
    };

    useEffect(() => {
        highlightRowFn(vm.value)
    }, [vm]);

    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };
    
    const handleSave= () => {
        if(vm.value) {
            onResolve(vm.value);
        }
    };
    const handleSearch = async() => {
        setVM({}, 'value');
        const res = await WniAccountService.searchIndustry(searchData, authHeader);
        updateTableData(generateTableData(res) || []);
        updateTablePageConfig(pageConfig);        
    };

    const handleReset = () => {
        updateSearchData({});
        setVM({}, 'value');
        updateTableData(allData);
        updateTablePageConfig(pageConfig);
    };

    const handleSelect = (item) => {
        const newItem = _.cloneDeep(item);
        updateSearchData(newItem); // update search bar
        setVM(newItem, 'value');// update industryCode model
    };

    const renderSelectCell = (item, index, porperty) => {
        const buttonDom = <Button onClick={() => handleSelect(item, index, porperty)} >Select</Button>
        return WniTableRowUtil.renderCell(item.rowIdPath, buttonDom)
    };
    const sortColumn = (a, b, sortType) => {
        highlightRowFn(vm.value);
        return DatatableUtil[sortType](a, b);
    };
    const writeValue = (value, path) => {
        const newSearchData = {
            code: searchData.code,
            classification: searchData.classification,
            [path]: value
        };
        // _.set(newSearchData, path, value);
        updateSearchData(newSearchData);
    };
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
        industryCode: {
            visible: searchType !== DESCRIPTION_SEARCH_TYPE
        },
        industryTable: {
            data: tableData,
            config: tablePageConfig,
            onConfigChange,
            pageSizeOptions: [5, 10, 15, 20]
        },
        
        
    };
    const resolvers = {
        resolveCallbackMap: {
            handleSearch,
            handleReset,
            renderSelectCell,
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber')
        },
        resolveComponentMap: {
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="industryCode">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={searchData}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} disabled={!vm.value || _.isEmpty(vm.value)} >{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

IndustryCodePopup.propTypes = {
   
};
IndustryCodePopup.defaultProps = {
    size: 'md'
};
export default IndustryCodePopup;
