import { defineMessages } from 'react-intl';

export default defineMessages({
    viewQuote: {
        id: 'gateway.directives.JobSummary.ViewQuoteReadOnly',
        defaultMessage: 'View Quote'
    },
    backTOPolicyDetails: {
        id: 'custom.gateway.directives.JobSummary.Back To Policy Details',
        defaultMessage: 'Back to Policy Details'
    },
    WesternNational: {
        id: 'quoteandbind.pa.views.paPolicyInfo.Submit to Western National',
        defaultMessage: 'Submit to { domainName }',
    },
    agency: {
        id: 'gateway.directives.JobSummary.Agency',
        defaultMessage: 'Agency'
    },
    licensedAgent: {
        id: 'gateway.directives.JobSummary.Licensed Agent',
        defaultMessage: 'Licensed Agent'
    },
    servicingAgent: {
        id: 'gateway.directives.JobSummary.Account Contact',
        defaultMessage: 'Account Contact'
    },
    copyToHOQuote: {
        id: 'gateway.directives.JobSummary.Copy to Home Quote',
        defaultMessage: 'Copy to Home Quote'
    },
    copyToDPQuote: {
        id: 'gateway.directives.JobSummary.Copy to Dwelling Quote',
        defaultMessage: 'Copy to Dwelling Quote'
    },    
    copySubmission: {
        id: 'gateway.directives.JobSummary.copy submission',
        defaultMessage: 'Copy Submission'
    },
    copiedMessage: {
        id: 'gateway.directives.JobSummary.The application has been copied to ',
        defaultMessage: 'The application has been copied to '
    },
    noOpenUnderwritingSubmission: {
        id: 'gateway.views.job-uwissues.There are no underwriting issues associated with this.SubmissionV2',
        defaultMessage: 'There are no open underwriting issues associated with this quote.'
    },
    noOpenUnderwritingPolicyChange: {
        id: 'gateway.views.job-uwissues.There are no underwriting issues associated with this.PolicyChange_v1',
        defaultMessage: 'There are no open underwriting issues associated with this policy change.'
    },
    noOpenUnderwritingRenewal: {
        id: 'gateway.views.job-uwissues.quotedetails.There are no underwriting issues associated with this.Renewal',
        defaultMessage: 'There are no open underwriting issues associated with this renewal.'
    },
    approvedForThisCancellation: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Cancellation_v1',
        defaultMessage: 'All underwriting issues have been approved for this cancellation.'
    },
    approvedForThisSubmission: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Submission_v1',
        defaultMessage: 'All underwriting issues have been approved for this quote.'
    },
    approvedForThisRenewal: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this Renewal_v1',
        defaultMessage: 'All underwriting issues have been approved for this renewal.'
    },
    approvedForThisPolicyChange: {
        id: 'gateway.views.job-uwissues.All underwriting issues have been approved for this PolicyChange_v1',
        defaultMessage: 'All underwriting issues have been approved for this policy change.'
    },
    withdrawOrContinuePolicyChange: {
        id: 'gateway.views.job-uwissues.quoteDetails.You may either withdraw or continue to the PolicyChange wizard',
        defaultMessage: 'You may either withdraw or continue to the policy change wizard.'
    },
    withdrawOrContinueRenewal: {
        id: 'gateway.views.job-uwissues.quoteDetails.You may either withdraw or continue to the Renewal wizard',
        defaultMessage: 'You may either withdraw or continue to the renewal wizard.'
    },
    withdrawOrContinueSubmission: {
        id: 'gateway.views.job-uwissues.quote.You may either withdraw or continue to the Submission wizard',
        defaultMessage: 'You may either withdraw or continue to the quote wizard.'
    },
    withdrawOrContinueCancellation: {
        id: 'gateway.views.job-uwissues.quoteDetails.You may either withdraw or continue to the Cancellation wizard',
        defaultMessage: 'You may either withdraw or continue to the cancellation wizard.'
    },
    withdrawThePolicyChange: {
        id: 'gateway.views.job-uwissues.quote.You may withdraw the PolicyChange',
        defaultMessage: 'You may withdraw the policy change'
    },
    withdrawTheRenewal: {
        id: 'gateway.views.job-uwissues.quote.You may withdraw the Renewal',
        defaultMessage: 'You may withdraw the renewal'
    },
    withdrawTheQuote: {
        id: 'gateway.views.job-uwissues.quote.You may withdraw the Submission',
        defaultMessage: 'You may withdraw the quote'
    },
    withdrawTheCancellation: {
        id: 'gateway.views.job-uwissues.quote.You may withdraw the Cancellation',
        defaultMessage: 'You may withdraw the cancellation'
    },
    sureWithDrawQuote: {
        id: 'gateway.views.job-uwissues.quote.Are you sure you want to withdraw quote:',
        defaultMessage: 'Are you sure you want to withdraw quote: {quoteNumber}? All changes will be lost.'
    },
    thePolicyIsIssued: {
        id: 'gateway.views.quote-detail.the policy is issued',
        defaultMessage: 'The policy is issued'
    },
    deleteQuote: {
        id: 'gateway.views.quote-detail.Delete Quote',
        defaultMessage: 'Delete Quote'
    }
});
