import React, {
    useCallback,
    useState,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './BaseAddPaymentMethodPopup.metadata.json5';

function BaseAddPaymentMethodPopup(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        paymentDetailVM,
        viewModelService,
        breakpoint
    } = props;
    // const breakpoint = useContext(BreakpointTrackerContext);
    const [getPaymentDetailVM, updatePaymentDetailVM] = useState(paymentDetailVM);
    const { isComponentValid, onValidate } = useValidation('BaseAddPaymentMethodPopup');
    const [showErrors, updateShowErrors] = useState(false);
    const translator = useTranslator();

    const writeValue = useCallback(
        (value, path) => {
            const newPaymentDetailVM = viewModelService.clone(getPaymentDetailVM);
            _.set(newPaymentDetailVM, path, value);
            updatePaymentDetailVM(newPaymentDetailVM);
        },
        [getPaymentDetailVM, viewModelService]
    );

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
        },
        [updateShowErrors]
    );

    const handleSave = useCallback(
        () => {
            if (!isComponentValid) {
                handleValidation();
            } else {
                onResolve(getPaymentDetailVM);
            }
        },
        [isComponentValid, handleValidation, onResolve, getPaymentDetailVM]
    );

    const validateNumber = useCallback((value, path) => {
        let numbericValue = value;
        if (!_.isNil(value)) {
            // seprate number
            numbericValue = value.match(/\d+/g);
            // combine the number to string
            if (!_.isNil(numbericValue)) {
                numbericValue = numbericValue.join('');
                // maxinum lenth is 19
                if (numbericValue.length > 19) {
                    numbericValue = numbericValue.substring(0, 19);
                }
            }
        }
        writeValue(numbericValue, path);
    }, [writeValue]);

    const getAccountTypeOptions = useCallback(() => {
        const availableValues = _.get(getPaymentDetailVM, 'bankAccountData.bankAccountType.aspects.availableValues');
        const options = _.map(availableValues, (option) => {
            return {
                code: option.code,
                name: translator({
                    id: option.name
                })
            };
        });
        return options;
    }, [getPaymentDetailVM, translator]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showRequired: true
        },
        accountNumber: {
            onValueChange: validateNumber
        },
        accountType: {
            availableValues: getAccountTypeOptions()
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="paymentDetailPanel">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={getPaymentDetailVM}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave}>{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

BaseAddPaymentMethodPopup.propTypes = {
    paymentDetailVM: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired
};
BaseAddPaymentMethodPopup.defaultProps = {
    size: 'md'
};
export default BaseAddPaymentMethodPopup;
