import { setComponentMapOverrides } from '@jutro/uiconfig';

import CommonDummyPage from './pages/Dummy/CommonDummyPage';
import CommonDummyReadOnlyPage from './pages/Dummy/CommonDummyPage.readonly';

import DummyQuotePage  from './pages/Quote/DummyQuotePage';
import DummyQuoteReadOnlyPage from './pages/Quote/DummyQuotePage.readonly';


setComponentMapOverrides(
    {
        CommonDummyPage: {component: 'CommonDummyPage'},
        CommonDummyReadOnlyPage: { component: 'CommonDummyReadOnlyPage' },

        //
        DummyQuotePage: {component: 'DummyQuotePage'},
        DummyQuoteReadOnlyPage: {component: 'DummyQuoteReadOnlyPage'},

    },
    {
        CommonDummyPage,
        CommonDummyReadOnlyPage,

        //
        DummyQuotePage,
        DummyQuoteReadOnlyPage,
    }
);


export {
    CommonDummyPage,
    CommonDummyReadOnlyPage,
    //
};

// eslint-disable-next-line import/prefer-default-export
export { default as DummySubmissionWizard } from './DummySubmissionWizard';
export { default as DummyWizardPage } from './templates/DummyWizardPage';

export { default as DummyQuoteIssueRenderHelper } from './pages/Quote/util/DummyQuoteIssueRenderHelper';
export { default as DummyQuotePageConstants } from './pages/Quote/config/DummyQuotePage.config';

