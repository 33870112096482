import React, {
    Component,
    useEffect,
    useState,
    useContext,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {
    CheckboxField, CurrencyValue, Loader,
    DropdownSelectField,
    InlineLoader,
} from '@jutro/components';
import { IntlContext, useTranslator } from '@jutro/locale';
import { WniClausesUtil } from 'wni-portals-util-js';
import styles from './BaseClauseComponent.module.scss';

/**
 * Component hierarchy diagram:
 * BaseClausesComponentVM ->  BaseSingleClauseComponentVM -> BaseClauseComponent
 * @param {object} props
 * @returns {object}
 */
function BaseClauseComponent(props) {
    const {
        onValueChange,
        path,
        showAmount,
        isLoading,
        loadingMessage,
        containerClassName,
        displayName,
        readOnly,
        required,
        value,
        id,
        children,
        checked,
        description,
        labelPosition,
        amount,
        isDisabled,
        isLimitRequired,
        // children,
        expectCoverageTermComponentType,
        coverageTermsCount,
        isEditable,
        loadingIconType,
        clausePatternCode,
        // ====================================================
        getCoverageLabel,
        getCheckboxTooltip,
    } = props;
    const intl = useContext(IntlContext);
    const translator = useTranslator();


    function defaultGetCheckboxTooltip(clausePatternCodeParam) {
        const formattedTooltip = !_.isUndefined(description) ? description : '';

        return formattedTooltip;
    }

    function defaultGetCoverageLabel(labelName, labelAmount) {
        return WniClausesUtil.getLabelNameWithAmount(labelName, labelAmount, intl, showAmount);
    }


    const handleChange = (newValue) => {
        if (onValueChange) {
            onValueChange(newValue, path);
        }
    };

    const renderCheckboxFieldComponent = (renderChildren) => {
        const hasChildren = !_.isNil(children);
        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });

        const formattedTooltip = _.isFunction(getCheckboxTooltip)
            ? getCheckboxTooltip(clausePatternCode)
            : defaultGetCheckboxTooltip(clausePatternCode);
        const coverageLabel = _.isFunction(getCoverageLabel)
            ? getCoverageLabel(displayName, amount)
            : defaultGetCoverageLabel(displayName, amount);
        
        return (
            <CheckboxField
                id={id}
                label={coverageLabel}
                required={isLimitRequired}
                // showRequired={isLimitRequired}
                value={value}
                readOnly={readOnly}
                disabled={isDisabled}
                onValueChange={handleChange}
                className={`${checkboxStyles} clauseCoverages`}
                controlClassName={checkboxControlStyles}
                path={path}
                checked={checked}
                labelPosition={labelPosition}
                showInlineLabel
                tooltip={{ text: formattedTooltip }}
                layout="full-width"
            >
                {renderChildren ? children : null}
            </CheckboxField>
        );
    };

    const renderDisabledCoverageTermDropdownList = () => {
        return (
            <DropdownSelectField
                autoTrim={false}
                dataType="string"
                disabled
                hideLabel={false}
                id="dropdownselect"
                // label={coverageName}
                labelPosition={labelPosition}
                searchable
                readOnly={false}
                required={false}
                schemaRequired={false}
                showErrors={false}
                placeholder=""
                showValidationIcon={false}
                size="medium"
                value="false"
            />
        );
    };

    const renderCheckboxField = () => {
        const renderChildren = value && coverageTermsCount > 0;
        const renderDisabledDropdown = !value && expectCoverageTermComponentType === 'DropDownList';

        if (renderDisabledDropdown) {
            return (
                <div className="clause-coverage-disabled">
                    <div className="clause-coverage-disabled-label">
                        {renderCheckboxFieldComponent(renderChildren)}
                    </div>
                    <div className="clause-coverage-disabled-content">
                        {renderDisabledCoverageTermDropdownList(displayName)}
                    </div>
                </div>
            );
        }
        return renderCheckboxFieldComponent(renderChildren);
    };

    const getLoadingComponent = useCallback(() => {
        if (loadingIconType === 'small') {
            return (
                <InlineLoader loading className="gw-inline-loader" />
            );
        }
        return (
            <Loader
                showLoader={isLoading}
                loadingMessage={loadingMessage}
            />
        );
    }, [loadingIconType]);

    const renderEditableValue = () => {
        const clauseContainerStyles = `${classNames(styles.clause, containerClassName)} quote-coverage-clause`;
        return (
            <div className={clauseContainerStyles}>
                {isLoading ? getLoadingComponent() : renderCheckboxField()}
            </div>
        );
    };

    const editModeRenderChildren = () => {
        if (children) {
            return children;
        }
        if (!value && expectCoverageTermComponentType === 'DropDownList') {
            return renderDisabledCoverageTermDropdownList();
        }
        return null;
    };

    const renderReadOnlyValue = () => {
        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        { !_.isUndefined(amount) && showAmount ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined
                        }
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>
                    {children}
                </div>
            </div>
        );
    };

    // ================================
    return isEditable ? renderEditableValue() : renderReadOnlyValue();
}

/**
 * @memberof gw-components-platform-react.ClauseComponent
 * @prop {Object} propTypes - the props that are passed to this component
 * @prop {string} propTypes.displayName - name of clause to display
 * @prop {bool} propTypes.readOnly - if the clause selection is read only
 * @prop {bool} propTypes.value - is the clause is seleceted
 * @prop {function} propTypes.onValueChange - callback when change is made
 * @prop {string} propTypes.path - path to value in the view modal
 * @prop {bool} propTypes.isLoading - should the clause be loading
 * @prop {string} propTypes.loadingMessage - message to be shown while loading
 * @prop {string} propTypes.containerClassName - clause container class
 * @prop {bool} propTypes.showAmount - determine to show amount next to displayName
 */
BaseClauseComponent.propTypes = {
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    value: PropTypes.bool,
    path: PropTypes.string,
    isLoading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    checked: PropTypes.bool,
    isEditable: PropTypes.bool,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    containerClassName: PropTypes.string,
    labelPosition: PropTypes.string,
    amount: PropTypes.shape({}),
    showAmount: PropTypes.bool,
    isDisabled: PropTypes.bool,
    coverageTermsCount: PropTypes.number,
    expectCoverageTermComponentType: PropTypes.string,
    isLimitRequired: PropTypes.bool.isRequired,
    loadingIconType: PropTypes.string,
    clausePatternCode: PropTypes.string,
    // ===========================
    /**
     * (Optional) Function: (labelName, labelAmount) => String
     * Used to to get coverable label displayed beside
     * the checkbox.
     */
    getCoverageLabel: PropTypes.func,
    /**
     * (Optional) Function: (clausePatternCode) => object
     * Used to get checkbox Tooltip
     */
    getCheckboxTooltip: PropTypes.func,
};

BaseClauseComponent.defaultProps = {
    readOnly: false,
    required: false,
    path: undefined,
    value: undefined,
    isLoading: false,
    loadingMessage: '',
    checked: false,
    children: undefined,
    isEditable: true,
    description: undefined,
    containerClassName: undefined,
    labelPosition: 'left',
    amount: undefined,
    showAmount: true,
    isDisabled: false,
    coverageTermsCount: 0,
    expectCoverageTermComponentType: 'none',
    loadingIconType: undefined,
    clausePatternCode: undefined,
    getCoverageLabel: undefined,
    getCheckboxTooltip: undefined,
};


export default BaseClauseComponent;
