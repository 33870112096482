import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownSelectField, InputField, CurrencyField, ToggleField, InputNumberField,
} from '@jutro/components';
import { WindowUtil } from 'wni-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateField, WniDropdownSelect } from 'wni-common-base-components';
import SimpleIncidentPopupUtil from './SimpleIncidentPopupUtil';
import metadata from './SimpleIncidentPopupComponent.metadata.json5';
import messages from './SimpleIncidentPopupComponent.messages'; 

function SimpleIncidentPopupComponent(props) {
    const {
        title,
        actionType,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        modalVM,
        modelProps,
        drivers,
        policyDrivers,
        displayStatus,
        viewModelService,
        translator,
        isShowCategoryValidationMessage
    } = props;

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation,
        disregardFieldValidation,
        invalidFields
    } = useValidation('SimpleIncidentPopupComponent');
    const { type, labelMap: labelMapList } = modelProps;
    const [newModalVM, updateNewModalVM] = useState(modalVM);
    const [showErrors, updateShowErrors] = useState(false);
    const [labelMap, updateLabelMap] = useState(labelMapList);
    const bodyAutoFocus = false;

    const specifiedOptions = {
        drivers: drivers,
        policydrivers: policyDrivers
    };

    const setYearsBack = (numOfYears) => {
        const backDate = new Date();
        backDate.setFullYear(backDate.getFullYear() - numOfYears);
        return backDate;
    };

    const setYearsForward = (numOfYears) => {
        const forwardDate = new Date();
        forwardDate.setFullYear(forwardDate.getFullYear() + numOfYears);
        return forwardDate;
    };

    const getReadOnlyStatus = () => {
        if (type.toLowerCase() === 'view') {
            return true;
        }
        if (type.toLowerCase() === 'edit') {
            return false;
        }
    }
    const validValue = (val) => {
        /**
         * check the amount currency value
         */
        if (!_.isNil(val.currency) && (_.isNil(val.value.amount) || (val.value.amount < 0))) {
            return false;
        }
        // for chooseing boolean field, fix user chooseing 'false' button but blocked by validation
        if (_.isBoolean(val.value)){
            return true;
        }
        if (!_.isEmpty(val.value)) {
            return true;
        }
        return false;
    };

    const getLabelKey = useCallback(() => {
        return Object.keys(labelMap);
    }, [labelMap]);

    const validFileds = useCallback(() => {
        const labelKey = getLabelKey();
        const validList = [];
        const validKey = [];
        labelKey.map((key) => {
            const eachDto = newModalVM[key];
            const { driverOptionPath } = labelMap[key];
            if (!_.isNil(driverOptionPath)) {
                let defaultDriverValue = '';
                if (driverOptionPath === 'drivers') {
                    defaultDriverValue = drivers.find((item) => item.code === eachDto.value);
                    if (_.isEmpty(defaultDriverValue) && type!=='View') {
                        eachDto.value = '';
                    }
                    if (eachDto && eachDto.aspects.required){
                        validList.push(eachDto);
                        validKey.push(key);
                    }
                }
            }
            if (eachDto && eachDto.aspects.required) {
                validList.push(eachDto);
                validKey.push(key);
            }
            return {
                validList,
                validKey
            };
        });
        return {
            validList,
            validKey
        };
    }, [drivers, getLabelKey, labelMap, newModalVM]);

    const isModalValid = useCallback(() => {
        const { validList } = validFileds();
        if (validList.every(validValue)) {
            return true;
        }
        return false;
    }, [validFileds]);

    useEffect(() => {
        registerComponentValidation(isModalValid);
    }, [registerComponentValidation, isModalValid]);

    const writeValue = useCallback(
        async (value, path) => {
            if (labelMap[path].noPriorLinkages) {
                const { noPriorLinkages } = labelMap[path];
                if (_.isArray(noPriorLinkages)) {
                    _.forEach(noPriorLinkages, (linkageData) => {
                        const matchedFlag = value === linkageData.linkCode;
                        if (matchedFlag) {
                            labelMap[linkageData.linkPath].unVisible = true;
                        } else {
                            labelMap[linkageData.linkPath].unVisible = false;
                        }
                    });
                    updateLabelMap(labelMap);
                }
            }
            if (labelMap[path].linkage) {
                const { linkage } = labelMap[path];
                if (_.isArray(linkage)) {
                    _.forEach(linkage, (linkageData) => {
                        // replaced old condition since applying to when one field have more than one dependencies
                        const matchedFlag = linkageData.linkCode.includes(value);
                        if (matchedFlag) {
                            labelMap[linkageData.linkPath].unVisible = false;
                        } else {
                            labelMap[linkageData.linkPath].unVisible = true;
                        }
                    });
                    updateLabelMap(labelMap);
                } else {
                    if (value === labelMap[path].linkage.linkCode) {
                        labelMap[labelMap[path].linkage.linkPath].unVisible = false;
                    } else {
                        labelMap[labelMap[path].linkage.linkPath].unVisible = true;
                    }
                    updateLabelMap(labelMap);
                }
            }
            const getNewModalVM = viewModelService.clone(newModalVM);
            _.set(getNewModalVM, path, value);
            /**
             * CLUE enter category switch a new value then description field set empty initially.
             */
            if (labelMap[path].refreshOptionPath) {
                if (!_.isUndefined(newModalVM[path].value) && value !== newModalVM[path].value.code) {
                    _.set(getNewModalVM, labelMap[path].refreshOptionPath, '');
                }
            }
            updateNewModalVM(getNewModalVM);
        },
        [labelMap, newModalVM, viewModelService]
    );

    useEffect(() => {
        const { validKey } = validFileds();
        const disregardField = getLabelKey().filter((item) => !validKey.includes(item));
        disregardFieldValidation(disregardField);
    }, [disregardFieldValidation, getLabelKey, newModalVM, validFileds]);

    const renderControl = (rendorProps, v) => {
        const {
            path, label, aspects: { inputCtrlType, availableValues, required },
            index, unEditable, visible, disabled, tooltip, radioValues, isTypeAlphabet, hidden, checkRatedStatus, initialFilterValues
        } = rendorProps;

        if (hidden) {
            return;
        }
        /**
         * ears needn't to display dispute column value in MVR popup form.
         */
        if (!_.isNil(newModalVM.value.earsFlag) && newModalVM.value.earsFlag && path === 'dispute') {
            return;
        }
        let rendorDom; 
        let availableList;
        let value;
        if (!_.isNil(v) && !_.isNil(v.code)) {
            value = availableValues.find((item) => item.code === v.code);
        } else {
            value = v;
        }
        const categoryValidateCondation = path === 'category' && value && value.code === 'atfaultaccident';
        const defaultRadioValues = [
            {
                code: 'true',
                name: {
                    id: 'quote.clur.userAdd.Accept',
                    defaultMessage: 'Accept'
                },
            },
            {
                code: 'false',
                name: {
                    id: 'quote.clur.userAdd.Decline',
                    defaultMessage: 'Decline'
                }
            }
        ];
        const inputProps = {
            type: "field",
            showRequired: true,
            required,
            onValueChange: writeValue,
            labelPosition: "left",
            label,
            path,
            visible,
            placeholder: required ? '-- Required for Quote --' : '',
            value,
            readOnly: getReadOnlyStatus() || unEditable,
            disabled,
            showErrors,
            tooltip,
            onValidationChange: onValidate
        };
        switch (inputCtrlType) {
            case 'date':
                rendorDom = (
                    <WniDateField
                        id={`date${index}`}
                        {...inputProps}
                        placeholder={undefined}
                    />
                );
                break;
            case 'typelist':
                if (!_.isNil(initialFilterValues)) {
                    availableList = initialFilterValues;
                } else {
                    availableList = SimpleIncidentPopupUtil.getDropDownValues(availableValues, translator);
                }
                if (isTypeAlphabet) {
                    rendorDom = (
                        <DropdownSelectField
                            id={`multiSelect${index}`}
                            {...inputProps}
                            searchable
                            availableValues={availableList}
                        />
                    );
                } else {
                    const dropdownProps = {
                        id: `typelist${index}`,
                        type: 'field',
                        showRequired: true,
                        required: required,
                        labelPosition: 'left',
                        label: label,
                        path: path,
                        visible: visible,
                        searchable: true,
                        placeholder: required ? '-- Required for Quote --' : '',
                        onValueChange: writeValue,
                        availableValues: availableList,
                        value: value,
                        readOnly: getReadOnlyStatus() || unEditable,
                        disabled: disabled,
                        showErrors: showErrors,
                        tooltip: tooltip,
                        validationMessages: isShowCategoryValidationMessage && categoryValidateCondation ? [messages.atFaultCategoryValidationMessage] : null,
                    };
                    if (!_.isNil(checkRatedStatus) && checkRatedStatus && displayStatus === 'Draft') {
                        return;
                    };
                    if (!_.isNil(checkRatedStatus) && checkRatedStatus) {
                        if (_.isNil(value)) {
                            return;
                        }
                    }
                    rendorDom = <WniDropdownSelect {...dropdownProps} />;
                }
                break;
            case 'text':
                rendorDom = (
                    <InputField
                        id={`text${index}`}
                        {...inputProps}
                    />
                );
                break;
            case 'boolean':
                rendorDom = (
                    <ToggleField
                        id={`radiobutton${index}`}
                        {...inputProps}
                        className="radioBtn-horizontal"
                        availableValues={radioValues || defaultRadioValues}
                    />
                );
                break;
            case 'number':
                rendorDom = (
                    <InputNumberField
                        id={`inputNumberFiled${index}`}
                        {...inputProps}
                    />
                );
                break;
            default:
                rendorDom = (
                    <InputField
                        id={`text${index}`}
                        {...inputProps}
                        autoComplete
                    />
                );
                break;
        }
        return rendorDom;
    };

    const rendorDateCtrl = (rendorProps, value, maxDate, minDate) => {
        const {
            path, label, index, aspects, unEditable, visible, disabled,
        } = rendorProps;
        return (
            <WniDateField
                id={`date${index}`}
                type="field"
                showRequired
                showOptional={false}
                required={aspects.required}
                onValueChange={writeValue}
                labelPosition="left"
                label={label}
                path={path}
                visible={visible}
                value={value}
                readOnly={getReadOnlyStatus() || unEditable}
                disabled={disabled}
                showErrors={showErrors}
                maxDate={maxDate}
                minDate={minDate}
                onValidationChange={onValidate}
            />
        );
    };
    const rendorCurrencyCtrl = (rendorProps, value) => {
        const {
            path, label, index, aspects, unEditable, visible, disabled, tooltip
        } = rendorProps;
        return (
            <CurrencyField
                id={`currency${index}`}
                defaultCurrency="USD"
                type="field"
                showRequired
                required={aspects.required}
                onValueChange={writeValue}
                labelPosition="left"
                label={label}
                path={path}
                visible={visible}
                placeholder={aspects.required ? '-- Required for Quote --' : ''}
                value={value}
                readOnly={getReadOnlyStatus() || unEditable}
                disabled={disabled}
                showErrors={showErrors}
                tooltip={tooltip}
                onValidationChange={onValidate}
                validationMessages={aspects.validationMessage? [aspects.validationMessage]: null}
            />
        );
    };
    const rendorSpecifiedDropdownCtrl = (rendorProps, value) => {
        const {
            path, options, label, aspects, unEditable, visible, disabled, tooltip
        } = rendorProps;
        let defaultValue = value;
        if (value) {
            defaultValue = options.find((item) => item.code === value);
            if (_.isNil(defaultValue) && value) {
                defaultValue = value;
            }
        }
        if(_.isNil(defaultValue)){
            defaultValue = options.find((item) => item.name === value);
        }
        if(_.isNil(defaultValue)){
            defaultValue = options.find((item) => item.name === value);
        }
        return (
            <DropdownSelectField
                id={`typelist${path}`}
                type="field"
                showRequired={aspects.required}
                searchable
                required={aspects.required}
                onValueChange={writeValue}
                labelPosition="left"
                label={label}
                path={path}
                visible={visible}
                placeholder={aspects.required ? '-- Required for Quote --' : ''}
                availableValues={options}
                value={defaultValue}
                readOnly={getReadOnlyStatus() || unEditable}
                disabled={disabled}
                showErrors={showErrors}
                tooltip={tooltip}
                onValidationChange={onValidate}
            />
        );
    };

    const onRenderContentFn = () => {
        const labelKey = getLabelKey();
        return (
            <ul>
                {
                    labelKey.map((key, index) => {
                        const eachDto = newModalVM[key];
                        const eachValue = _.get(newModalVM, `${key}.value`);
                        const labelMsg = labelMap[key].label.defaultMessage
                            ? labelMap[key].label.defaultMessage : labelMap[key].label;
                        const { driverOptionPath } = labelMap[key];
                        if (!_.isNil(driverOptionPath)) {
                            const rendorProps = {
                                path: key,
                                options: specifiedOptions[`${driverOptionPath}`],
                                label: labelMsg,
                                aspects: eachDto.aspects,
                                unEditable: labelMap[key].unEditable,
                                visible: !labelMap[key].unVisible,
                                disabled: labelMap[key].disabled,
                                tooltip: labelMap[key].tooltip,
                                radioValues: labelMap[key].radioValues,
                                isTypeAlphabet: labelMap[key].isTypeAlphabet
                            };
                            return (
                                <li id={key} key={key}>
                                    {rendorSpecifiedDropdownCtrl(rendorProps, eachValue)}
                                </li>
                            );
                        }
                        if (eachDto) {
                            SimpleIncidentPopupUtil.getVisibilityOfConditionalFields(title, key, newModalVM, labelMap);
                            if (labelMap[key].unVisible) {
                                return false;
                            }
                            const rendorProps = {
                                path: key,
                                label: labelMsg,
                                aspects: eachDto.aspects,
                                index: index,
                                unEditable: labelMap[key].unEditable,
                                visible: !labelMap[key].unVisible,
                                default: labelMap[key].default,
                                disabled: labelMap[key].disabled,
                                tooltip: labelMap[key].tooltip,
                                radioValues: labelMap[key].radioValues,
                                isTypeAlphabet: labelMap[key].isTypeAlphabet,
                                initialFiled: labelMap[key].initialFiled,
                                triggerFiled: labelMap[key].triggerFiled,
                                initialFilterValues: labelMap[key].initialFilterValues,
                                checkvisible: labelMap[key].checkvisible,
                                checkRatedStatus: labelMap[key].checkRatedStatus,
                                filterVal: labelMap[key].filterVal,
                                hidden: labelMap[key].hidden,
                            };
                            
                            const getNewModalVM = viewModelService.clone(newModalVM);
                            SimpleIncidentPopupUtil.handleVisibilityOfDescriptionField(key, title, rendorProps, newModalVM, getNewModalVM);
                            if (!_.isEqual(getNewModalVM.value, newModalVM.value)) {
                                updateNewModalVM(getNewModalVM);
                            }
                            
                            if ('year' in eachDto) {
                                if (rendorProps.path === 'incidentDate' || rendorProps.path === 'claimDate' && (title === 'Add MVR Entry' || title === 'Edit MVR Entry' || title === 'Edit CLUE Entry' || title === 'Add CLUE Entry' || title === 'Edit Prior Loss Entry' || title === 'Add Prior Loss Entry')) {
                                    return (
                                        <li id={key} key={key}>
                                            {rendorDateCtrl(rendorProps, eachValue, new Date(), setYearsBack(5))}
                                        </li>
                                    );
                                }
                                if (rendorProps.path === 'expirationDate' && (title === 'Add Prior Carrier Entry' || title === 'Edit Prior Carrier Entry')) {
                                    return (
                                        <li id={key} key={key}>
                                            {rendorDateCtrl(rendorProps, eachValue, setYearsForward(10), setYearsBack(5))}
                                        </li>
                                    );
                                }
                                return (
                                    <li id={key} key={key}>
                                        {rendorDateCtrl(rendorProps, eachValue)}
                                    </li>
                                );
                            }
                            if ('currency' in eachDto) {
                                return (
                                    <li id={key} key={key}>
                                        {rendorCurrencyCtrl(rendorProps, eachValue)}
                                    </li>
                                );
                            }
                            return (
                                <li id={key} key={key}>
                                    {renderControl(rendorProps, eachValue)}
                                </li>
                            );
                        }
                        return null;
                    })
                }
            </ul>
        );
    };

    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
            return false;
        },
        [updateShowErrors, invalidFields]
    );
    const handleSave = useCallback(
        () => {
            const clonedNewModalVM = viewModelService.clone(newModalVM);
            if (actionType === 'View') {
                onReject();
                return false;
            }
            SimpleIncidentPopupUtil.removeUnnecessaryDescription(clonedNewModalVM);
            if (!isComponentValid) {
                handleValidation();
            } else if (_.isEqual(modalVM.value, clonedNewModalVM.value)) {
                onReject();
                return false;
            }
            else {
                onResolve(newModalVM.value);
            }
        },
        [onResolve, onReject, handleValidation, isComponentValid, newModalVM, actionType]
    );
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
            showErrors: showErrors
        },
        tableDetailsList: {
            content: onRenderContentFn()
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            newModalVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveComponentMap: {
        },
        resolveCallbackMap: {
        }
    };
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="tablePopupModal" autoFocus={bodyAutoFocus}>
                <ViewModelForm
                    model={newModalVM}
                    overrideProps={overrideProps}
                    uiProps={metadata.componentContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

SimpleIncidentPopupComponent.propTypes = {
    title: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
    size: PropTypes.string,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    modalVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    modelProps: PropTypes.shape({
        type: PropTypes.string.isRequired,
        labelMap: PropTypes.arrayOf.isRequired
    }).isRequired,
    drivers: PropTypes.arrayOf(PropTypes.object),
    policyDrivers: PropTypes.arrayOf(PropTypes.object),
    validationMessages: PropTypes.arrayOf(PropTypes.string || PropTypes.shape({})),
    displayStatus: PropTypes.string.isRequired,
};
SimpleIncidentPopupComponent.defaultProps = {
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    size: 'md',
};

export default SimpleIncidentPopupComponent;
