
const OPERATION_SUCCESS = 'Success';
const OPERATION_FAILURE = 'Failure';

const MODAL_DIALOG_RESULT_CONFIRM = 'confirm';
const MODAL_DIALOG_RESULT_CANCEL = 'cancel';
const MODAL_DIALOG_RESULT_CLOSE = 'close';

const QUOTE_STATUS_DRAFT = 'Draft';
const QUOTE_STATUS_APPROVED = 'Approved';
const QUOTE_STATUS_APPROVED_EXT = 'Approved_Ext';
const QUOTE_STATUS_QUOTED = 'Quoted';
const QUOTE_STATUS_RATED = 'Rated';
const QUOTED_STATUS_ARRAY = [QUOTE_STATUS_APPROVED, QUOTE_STATUS_QUOTED, QUOTE_STATUS_RATED, QUOTE_STATUS_APPROVED_EXT];
const TRANSACTIONTYPE_POLICYCHANGE = 'policychange';

/**
 * Sample usage:
 * INPUT: 'Quoted'
 * OUTPUT: true
 * 
 * INPUT: 'Draft'
 * OUTPUT: false
 * @param {String} status 
 * @returns {boolean}
 */
function isQuoted(status) {
    return QUOTED_STATUS_ARRAY.includes(status);
}

export const PRICE_VIEW_MODES = {
    annually: 'annually',
    monthly: 'monthly',
};


export default {
    PRICE_VIEW_MODES,
    OPERATION_SUCCESS,
    OPERATION_FAILURE,
    MODAL_DIALOG_RESULT_CONFIRM,
    MODAL_DIALOG_RESULT_CANCEL,
    MODAL_DIALOG_RESULT_CLOSE,
    TRANSACTIONTYPE_POLICYCHANGE,

    //
    isQuoted,

    //
    // Instead of get property, another option is to return a sealed object
    get QUOTE_STATUS_DRAFT() { return QUOTE_STATUS_DRAFT },
};
// export { default as WizardConstants } from './WizardConstants';
// export { default as FrontendErrorConstants } from './FrontendErrorConstants';
