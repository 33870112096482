import React, {
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniDateUtil, WniQuestionSetUtil, WniPNIUtil } from 'wni-portals-util-js';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import {
    AgencyAndAgentInfoComponent,
    // PAUnderwritingQuestionsInput,
} from 'wni-capability-gateway-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { ValidationIssuesComponent, AsteriskIsRequiredNotification } from 'wni-components-platform-react';
import metadata from './PAPolicyDetailsPage.metadata.json5';
import messages from './PAPolicyDetailsPage.messages';

// const TERM_TYPE_OTHER = 'Other';

function PAPolicyDetailsReadOnlyPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;

    const shouldShowEffDateNotice = () => {
        const currentEffectiveDate = _.get(submissionVM, 'baseData.periodStartDate.value');
        return WniDateUtil.isMoreThanXDaysInFuture(currentEffectiveDate, 60);
    };

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.firstName.value');
        const lastName = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.lastName.value');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const isShowNotification = useCallback(() => {
        const policyInfoSource = _.get(submissionVM, 'baseData.PolicyInfoSource_Ext');
        if ((policyInfoSource) && policyInfoSource === 'Coverted') {
            return true;
        }
        return false;
    }, [submissionVM]);

    const loadCovertPolicyInfo = useCallback(() => {
        const currentEffectiveDate = _.get(submissionVM, 'baseData.periodStartDate.value');
        const infoDate = WniDateUtil.formatDateWithPattern(moment(currentEffectiveDate).subtract(34, 'days'));
        const covertInfo = messages.covertedPolicyInfoStart.defaultMessage
        + infoDate + messages.covertedPolicyInfoEnd.defaultMessage;
        return covertInfo;
    }, [submissionVM]);

    const handleAgencyChange = (value, path) => {
        if (path === 'producerCode_Ext' && !value) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
        }
        _.set(submissionVM.baseData, path, value);

        updateWizardData(submissionVM);
    };
    const renderPrimaryNamedInsuredValue = () => {
        return (
            <>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{WniPNIUtil.getPrimaryAddressDisplayName(_.get(submissionVM, 'baseData.primaryNamedInsured_Ext.primaryAddress'))}</div>
            </>
        )
    };

    const policyState = _.get(submissionVM.value, 'baseData.baseState_Ext');
    const filterQuestion = WniQuestionSetUtil.getQSFilterForPolicyDetails(policyState);
    const questionSetMapper = WniQuestionSetUtil.getQSMapperForPASubmissionPolicyDetails();

    const overrideProps = {
        '@all': {
            readOnly: true
        },
        '@field': {
            labelPosition: 'left',
        },
        asteriskIsRequiredNotification: {
            visible: false
        },
        effDateNotification: {
            visible: shouldShowEffDateNotice()
        },
        dynamicInlineNotificationContainer: {
            validationIssues: [],
            visible: false,
            scrollToIssues: true,
        },
        coverageStartDate: {
            minDate: new Date().setHours(0, 0, 0, 0),
            vm: submissionVM
        },
        paTermTypedropdownselect: {
        },
        coverageExpirationDate: {
        },
        primaryNamedInsuredField: {
            value: renderPrimaryNamedInsuredValue()
        },
        primaryNamedInsuredEditIcon: {
            visible: false,
        },
        agencyAndAgentInfo: {
            model: submissionVM.baseData,
            onAgencyChange: handleAgencyChange,
            displayFields: {
                agencyOfRecord: true,
                licensedAgent: true,
                servicingAgent: true
            },
            producerCodePath: 'producerCode_Ext',
            isReadOnly: true
        },
        covertNotification: {
            visible: isShowNotification(),
            message: loadCovertPolicyInfo()
        },
        policyUnderwritingQuestionSets: {
            isReadOnly: true,
            contentFilter: filterQuestion,
            contentMapper: questionSetMapper,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyagentinfo: AgencyAndAgentInfoComponent,
            validationissuescomponent: ValidationIssuesComponent,
            questionset: QuestionSetComponent,
            asteriskIsRequiredNotification: AsteriskIsRequiredNotification
        }
    };

    return (
        <WizardPage showPrevious={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PAPolicyDetailsReadOnlyPage.propTypes = {
    ...wizardProps
};
export default PAPolicyDetailsReadOnlyPage;
