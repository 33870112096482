import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { Tooltip, Button } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import SummaryHeading from '../SummaryHeader/SummaryHeader';
import metadata from './LocationSummary.metadata.json5';
import styles from './LocationSummary.module.scss';
import messages from '../../../PECPWizard.messages';

const TerritoryCodeTooltip = () => {
    const translator = useTranslator();
    const territoryCodeTooltipButton = classNames(styles.gwTerritoryCodeTooltip, 'actionIcon');
    return (
        <Tooltip
            id="territoryCodeTooltip"
            placement="bottom"
            trigger="mouseenter"
            content={translator(messages.territoryCodeTooltip)}
        >
            <Button className={territoryCodeTooltipButton} visible icon="mi-help_outline" type="filled" />
        </Tooltip>
    );
};

const LocationSummary = (props) => {
    const {
        submission, summaryIcon, onEditClicked, openByDefault, showIconOnly
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [locationVM, updateLocationVM] = useState({});
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const renderAccordionHeader = (type) => {
        const title = translator(messages.locationSummary);
        return (isOpen) => (
            <SummaryHeading
                isOpen={isOpen}
                type={type}
                displayName={submission.displayName}
                title={title}
                icon={summaryIcon}
                showIconOnly={showIconOnly}
            />
        );
    };

    const initialiseVM = useCallback(() => {
        const localeService = ServiceManager.getService('locale-service');
        const model = {
            address: {
                country: localeService.getDefaultCountryCode()
            }
        };
        const locationViewModel = viewModelService.create(
            model,
            'pc',
            'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.LocationDTO'
        );
        updateLocationVM(locationViewModel);
    }, [viewModelService, updateLocationVM]);

    useEffect(() => {
        if (!isVMInitialised) {
            initialiseVM();
            updateIsVMInitialised(true);
        }
    }, [initialiseVM, locationVM, isVMInitialised]);

    const onEditLocationClicked = () => {
        if (onEditClicked) {
            onEditClicked();
        }
    };

    const getLocationFullAddress = useCallback(() => {
        const displayName = _.get(submission, 'displayName', '');
        return displayName.split(',').map((address) => {
            return <div>{address}</div>;
        });
    }, [submission]);

    const getFireProtectionName = useCallback(() => {
        if (!_.isEmpty(locationVM)) {
            const fireProtectionValues = _.get(
                locationVM,
                'fireProtection.aspects.availableValues'
            );
            const filteredFireProtection = _.get(
                fireProtectionValues.find((value) => {
                    return value.code === submission.fireProtection;
                }),
                'name'
            );
            return filteredFireProtection;
        }
        return null;
    }, [locationVM, submission]);

    const overrideProps = {
        '@field': {
            readOnly: true
        },
        locationSummaryCard: {
            renderHeader: renderAccordionHeader('location'),
            className: classNames({
                [styles.gwLocationSummaryHeaderDisabled]: showIconOnly
            })
        },
        locationSummaryAccordion: {
            defaultOpenedId: openByDefault && 'locationSummaryCard'
        },
        locationFullAddress: {
            value: getLocationFullAddress()
        },
        locationFireProtection: {
            value: translator({
                id: getFireProtectionName(),
                defaultMessage: ''
            })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            territorycodetooltip: TerritoryCodeTooltip
        },
        resolveCallbackMap: {
            onEditLocationClicked: onEditLocationClicked
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submission, id, path, overrideProps);
        },
        [submission, overrideProps]
    );

    if (_.isEmpty(submission, true)) {
        return null;
    }

    return (
        <ViewModelForm
            model={submission}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

LocationSummary.propTypes = {
    submission: PropTypes.shape({
        value: PropTypes.shape({}),
        fireProtection: PropTypes.shape({}),
        displayName: PropTypes.string
    }).isRequired,
    onEditClicked: PropTypes.func.isRequired,
    summaryIcon: PropTypes.string,
    openByDefault: PropTypes.bool,
    showIconOnly: PropTypes.bool
};

LocationSummary.defaultProps = {
    summaryIcon: 'mi-check',
    openByDefault: false,
    showIconOnly: false
};

export default LocationSummary;
