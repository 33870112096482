/**
 * From: gw-portals-wizard-react/Wizard/templates/DigitalWizardPageTemplate.jsx
 * This Component is extracted from gw-portals-wizard-react to avoid the circular
 * dependency problem between gw-portals-wizard-react and gw-capability-gateway-react,
 * since QuoteDetails/Document are currently being used by both.
 */
import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, InlineLoader, Tooltip } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardConstants } from 'wni-portals-config-js';
import { messages } from 'wni-platform-translations';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { pageTemplateProps, WizardContext } from '@xengage/gw-portals-wizard-react';
import { BaseDigitalWizardPageTemplate } from 'wni-portals-wizard-components-ui';
import WizardSidebar from '../components/WizardSidebar';
import MobileWizardSteps from '../components/MobileWizardSteps/MobileWizardSteps';
// import styles from './DigitalWizardPageTemplate.module.scss';

function renderLoader(isLoading) {
    return isLoading
        ? (
            <InlineLoader loading={isLoading} className="gw-inline-loader" />
        ) : null;
}

function DigitalWizardPageTemplate(props) {
    return <BaseDigitalWizardPageTemplate {...props} />;
}

DigitalWizardPageTemplate.propTypes = BaseDigitalWizardPageTemplate.propTypes;

DigitalWizardPageTemplate.defaultProps = BaseDigitalWizardPageTemplate.defaultProps;

export default DigitalWizardPageTemplate;
