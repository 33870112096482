
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { useTranslator } from '@jutro/locale';
import { Link } from '@jutro/components';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { QuoteCoveragesUtil, WniClausesUtil, WindowUtil } from 'wni-portals-util-js';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import metadata from './BaseRecommendCoveragesComponent.metadata.json5';
import styles from './BaseRecommendCoveragesComponent.module.scss';
import messages from './BaseRecommendCoveragesComponent.messages';


function getVaidationIssuesWithUniqueMessage(covIssues) {
    const validationIssues = [];
    covIssues.forEach((covIssue) => {
        const {
            coveragePublicID,
            message: covIssueMessage,
            // vehicleFixedId: covIssueVehicleFixedId,
        } = covIssue;
        const existingValIssue = validationIssues.find((issue) => issue.reason === covIssueMessage);
        if (existingValIssue) {
            // existingValIssue.vehicleFixedIds.push(covIssueVehicleFixedId);
        } else {
            validationIssues.push({
                type: 'fine',
                reason: covIssueMessage,
                coveragePublicID,
                // vehicleFixedIds: [covIssueVehicleFixedId],
            });
        }
    });

    return validationIssues;
}


/**
 * Get a page jump func that will will navigate to the coverage checkbox
 * @param {string} idPrefix
 * @param {string} coveragePublicID
 * @param {string} vehicleFixedIds
 * @returns {function}
 */
function defaultGetCovJumpFn(idPrefix, coveragePublicID) {
    // See SingleClauseComponentVM.generateMetadata()
    return () => {
        // Strange: cannot jump to checkbox, but can jump to <td>, <div>, etc.
        const covId = WniClausesUtil.getClauseContainerId(idPrefix, coveragePublicID);

        // ================tmp solution
        const clauseDivEle = document.getElementById(covId);
        if (clauseDivEle) {
            WindowUtil.scrollTo(covId, { focus: true });
        } else {
            // goToDriverCoveragesPage();
        }
    };
}

/**
 * Base Component Extracted from RecommendCoveragesComponent
 * @param {object} props 
 * @returns {React.Element}
 */
const BaseRecommendCoveragesComponent = (props) => {
    const {
        columnIndex,
        coverageIssues,
        //
        getCovJumpFn,
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const itemMsg = translator(messages.recommendedUpgrades);


    const issueRenderFn = (validationIssue) => {
        const {
            coveragePublicID,
            reason,
        } = validationIssue;

        const jumpFn = getCovJumpFn(columnIndex, coveragePublicID);

        let renderContent = (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link onClick={jumpFn}>{reason}</Link>
        );
        const covLinkText = QuoteCoveragesUtil.findCovLinkText(reason);
        if (covLinkText) {
            const linkTextArray = reason.split(covLinkText);
            renderContent = (
                <span>
                    {linkTextArray[0]}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link onClick={jumpFn}>{covLinkText}</Link>
                    {linkTextArray[1]}
                </span>
            );
        }
        return (
            renderContent
        );
    };

    useEffect(() => {
    }, []);

    //----------------------------------
    const validationIssues = getVaidationIssuesWithUniqueMessage(coverageIssues);
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        recommendCovList: {
            id: `recommendCovContainer${columnIndex}`,
            validationIssues,
            issueRenderFn,
            typeTitleFormatter: (type, issues) => {
                // return issues.length > 1 ? `${itemMsg}s` : itemMsg;
                return itemMsg;
            },
            scrollToIssue: false,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {
            validationissues: ValidationIssuesComponent,
        },
    };
    //---------

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            // resolveValue={readValue}
            // onValueChange={writeValue}
            // showErrors={showErrors}
        />
    );

};

BaseRecommendCoveragesComponent.propTypes = {
    /**
     * (Required) Column index of the policyPeriod the recommendation is based on
     */
    columnIndex: PropTypes.string.isRequired,
    /**
     * (Required) an array of PaCoverageValidationIssueDTO instances
     */
    coverageIssues: PropTypes.arrayOf(PropTypes.shape({
        coveragePublicID: PropTypes.string,
        coverageName: PropTypes.string,
        coveragePatternCode: PropTypes.string,
        message: PropTypes.string,
        vehicleFixedId: PropTypes.number,
    })).isRequired,
    // ==================================================
    /**
     * (Optional): a func generator to provide jump func when the recommend link is clicked
     */
    getCovJumpFn: PropTypes.func,
};

BaseRecommendCoveragesComponent.defaultProps = {
    getCovJumpFn: defaultGetCovJumpFn,
};

export default BaseRecommendCoveragesComponent;
