import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader, Button } from '@jutro/components';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniLoadSaveService, WniSxsQuoteService, WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { SubmissionService, ActivitiesService } from 'gw-capability-gateway';
import { WniSubmissionService, WniActivitiesService, WniDocumentRetrievalService } from 'wni-capability-gateway';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    IncidentsUtil,
    QuoteUtil,
    ErrorsAndWarningsUtil,
    WizardUtil,
    WizardPageJumpUtil,
    ServiceErrorUtil,
    ActivityUtil,
    QuoteCoveragesUtil,
    WindowUtil,
    DocumentsUtil,
    DTOUtil,
    PaymentUtil,
    CoveragesUtil,
    IssuanceValidationUtil,
    WniClausesUtil,
    ActiveQuoteUtil,
} from 'wni-portals-util-js';
import { ComponentRenderHelper as renderHelper, ModalDialogHelper, useProductsData, useBusinessData } from 'wni-portals-util-react';
import {
    SideBySidePeriodsComponentV2,
    // WithdrawTransactionComponent,
    MarkNotTakenComponent,
    QuoteAdditionalProductComponent,
} from 'wni-capability-gateway-react';
import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { QuoteCommonMessages } from 'wni-platform-translations';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PAQuoteUIHelper from './util/PAQuoteUIHelper';
import styles from './PAQuotePage.module.scss';
import metadata from './PAQuotePage.metadata.json5';
import messages from './PAQuotePage.messages';

const {
    generateColumnData,
    generateTableData,
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = PAQuoteUIHelper;

const {
    PAYMENT_PLAN_TYPES,
} = QuoteUtil;

const SXS_LOB_NAME = 'personalAuto';

function PAQuotePage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardSnapshot,
        // history,
        // goNext
        // errorsForStep,
        // stepsWithErrors,
        // underwritingIssues,
        // acknowledgeError,
        // reportErrors,
        wizardStepToFieldMapping,
        markFollowingWizardStepsUnvisited,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        wizardPageData,
        updateWizardPageData,
    } = props;

    const {
        quoteID, sessionUUID,
        baseData: {
            termType,
            periodStatus,
            productCode: currentProductCode,
            selectedVersion_Ext: selectedVersion,
            accountNumber,
            accountHolder: {
                contactType_Ext: accountType,
                primaryAddress: {
                    postalCode,
                }
            },
            // numberOfQuotedOrRatedQuotes_Ext: quoteCount,
            showFullPayDiscount_Ext: showFullPayDiscount,
            displayStatus_Ext: displayStatus,
            //
            effectiveDate_Ext: periodEffectiveDate,
            producerCodePublicID_Ext: periodProducerCodePubliID,
            producerCode_Ext: periodProducerCodeStr,
            policyAddress: {
                state: policyState,
            },
        },
        lobData: {
            [SXS_LOB_NAME]: {
                isForNamedNonOwner_Ext: isForNamedNonOwner,
                quotePageData_Ext: {
                    availableProducts = [],
                    policySummaries = [],
                    serverCurrentDate: pcServerSystemDate,
                },
            }
        },
        bindData,
        quoteData,
    } = submissionVM.value;

    const {
        [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
    } = wizardPageData;
    const { getProductEffectiveDate } = useProductsData();
    const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { enableCommercialLine } = useBusinessData();
    const {
        loadingMask: { setLoadingMask },
        workflowType,
    } = useDependencies(['loadingMask', 'workflowType']);

    const viewModelService = useContext(ViewModelServiceContext);
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('PAQuotePage');
    const [showErrors, updateShowErrors] = useState(false);

    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);

    const defaultPaymentViewType = showFullPayDiscount ? PAYMENT_PLAN_TYPES.annually : PAYMENT_PLAN_TYPES.monthly;
    const [paymentViewType, updatePaymentViewType] = useState(defaultPaymentViewType);
    // const [backendPaymentViewType, updateBackendPaymentViewType] = useState(defaultPaymentViewType);

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    const quoteCount = QuoteUtil.getCountOfRatedQuotedApprovedQuotes(accountActiveQuotes);
    const [isNewAccount, updateIsNewAccount] = useState(quoteCount === 1); // Default value to be removed due to being deprecated

    // const [reservationData, updateReservationData] = useState({});
    const goToIncidentReports = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, 'PAIncidentsAndReportsPage');
    // const goToDriversPage_tmp = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, 'PADriversPage');
    const goToDriverCoveragesPage = WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, 'PACoveragesPage');
    const modalHelper = ModalDialogHelper(modalApi);
    // const accountNumber = _.get(submissionVM.value, 'baseData.accountNumber');
    const [selectedDocument, updateSelectedDocument] = useState('');
    const [alreadyOwnedProductCode, updateAlreadyOwnedProductCode] = useState([]);
    const productCodeToCopyWayMap = { Watercraft : 'PAToWAL', RoadTrail : 'PAToRT', PersonalUmbrella : 'PAToPU'};

    useEffect(() => {
        const alreadyOwnedProducts = ActiveQuoteUtil.getAlreadyOwnedProducts(currentProductCode, policySummaries, accountActiveQuotes);
        updateAlreadyOwnedProductCode(alreadyOwnedProducts)
    }, [accountActiveQuotes, currentProductCode, policySummaries]);

    const goToAccountSummaryPage = useCallback(async () => {
        if (!enableCommercialLine) {
            WizardPageJumpUtil.goToAccountSummaryPage(history, accountNumber);
        } else {
            history.push(`/accounts/${accountNumber}/summary`);
        }
    }, [accountNumber, history]);

    /**
     * To be refactored
     * @param {object} currentPaymentPlan or bindData
     * @param {boolean} retrievePlansData
     * @returns {array}
     */
    const updateSelectedPaymentPlan = async (currentPaymentPlan, retrievePlansData = false) => {
        let cachedPaymentPlans = wizardPageData[WizardConstants.paymentPlans];
        if (!cachedPaymentPlans && retrievePlansData) {
            cachedPaymentPlans = await WniLoadSaveService.retrievePaymentPlans(quoteID,
                sessionUUID, authHeader);
        }
        //
        if (currentPaymentPlan.selectedPaymentPlan) {
            const exitingPaymentPlanType = PaymentUtil.getPaymentPlanType(
                currentPaymentPlan.selectedPaymentPlan, cachedPaymentPlans
            );
            updatePaymentViewType(exitingPaymentPlanType);
            return [];
        }

        const paymentPlanId = PaymentUtil.getPaymentPlanIDByTerm(termType, currentPaymentPlan, cachedPaymentPlans);

        let newBindData = null;
        if (paymentPlanId) {
            newBindData = await WniSxsQuoteService.updatePaymentPlan(quoteID,
                sessionUUID, paymentPlanId, authHeader);
        }
        return [cachedPaymentPlans, newBindData];
    };

    const initPaymentPlanViewType = async () => {
        const cachedValue = wizardPageData[WizardConstants.paymentViewType];
        if (cachedValue) {
            updatePaymentViewType(cachedValue);
            return cachedValue;
        }

        // let retval = paymentViewType;
        // const storedValue = await WniCommonQuoteService.getQuotePriceViewMode(quoteID,
        //     sessionUUID, authHeader);
        // if (!_.isEmpty(storedValue)) {
        //     updateBackendPaymentViewType(storedValue);
        //     if (storedValue !== defaultPaymentViewType) {
        //         updatePaymentViewType(storedValue);
        //     }
        //     retval = storedValue;
        // }
        // return retval;
        return paymentViewType;
    };

    const goToReadOnlyMode = useCallback((isUwLocked) => {
        updateServiceCallInProgress(false);
        updateWizardPageData({
            [WizardConstants.isUwLocked]: isUwLocked,
            [WizardConstants.paymentViewType]: paymentViewType
        });
        updateWizardReadOnly(true, { wizardData: submissionVM });
    }, [paymentViewType, submissionVM, updateWizardPageData, updateWizardReadOnly]);

    const updateSideBySideData = useCallback(async (optConfigs = {}) => {
        const {
            quoteErrorsAndWarnings = {},
            sxsDataRetriever = WniSxsQuoteService.retrieveSxsData,
            extraWizardPageData = {},
        } = optConfigs;
        const sideBySideData = await sxsDataRetriever(quoteID, sessionUUID, authHeader);

        let updatedErrorsWarnings = null;
        if (sideBySideData[SXS_LOB_NAME]) {
            updatedErrorsWarnings = _.get(sideBySideData, 'errorsAndWarnings');
        }
        if (!_.isEmpty(quoteErrorsAndWarnings)) {
            if (ErrorsAndWarningsUtil.hasValidationError(quoteErrorsAndWarnings)
                || _.isEmpty(updatedErrorsWarnings)
                // || !ErrorsAndWarningsUtil.hasValidationIssue(updatedErrorsWarnings)  // Or this one
            ) {
                updatedErrorsWarnings = quoteErrorsAndWarnings;
            }
        }
        updateErrorsAndWarnings(updatedErrorsWarnings);
        // reportErrors(updatedErrorsWarnings);

        //----------------------------------------------------------------------
        if (ErrorsAndWarningsUtil.hasValidationError(updatedErrorsWarnings)) {
            WindowUtil.scrollTo('quoteErrorsAndWarnings');
        }
        //----------------------------------------------------------------------

        if (_.isEmpty(sideBySideData)) {
            return;
        }

        
        const {
            [SXS_LOB_NAME]: lobSxsDataDTO,
            // [SXS_LOB_NAME]: {
            //     periods: sxsPeriods,
            // },
            accountJobs,
        } = sideBySideData;

        if (lobSxsDataDTO) {
            updateSxsDataDTO(sideBySideData);

            const newAccountActiveQuotes = accountJobs || accountActiveQuotes;

            // POI-25231: update isNewAccount status, original design by Adrian
            const newQuoteCount = QuoteUtil.getCountOfRatedQuotedApprovedQuotes(newAccountActiveQuotes);
            updateIsNewAccount(newQuoteCount === 1);

            // record the most recent SideBySideData; to be used by PAQuotePage.readonly;
            updateWizardPageData({
                [WizardConstants.sideBySideData]: sideBySideData,
                [WizardConstants.accountActiveQuotes]: newAccountActiveQuotes,
                ...extraWizardPageData,
            });
        }
    }, [accountActiveQuotes, authHeader, quoteID, sessionUUID, updateWizardPageData]);

    const updateSideBySideDataWithActiveQuotes = useCallback(async (optConfigs) => {
        await updateSideBySideData({
            ...optConfigs,
            sxsDataRetriever: WniSxsQuoteService.retrieveSxsDataWithActiveQuotes,
        });
    }, [updateSideBySideData]);

    const updateWizardData = useCallback((newSubmissionVM) => {
        // updateSubmissionVM(newSubmissionVM);
        updateWizardSnapshot(newSubmissionVM);
    }, [updateWizardSnapshot]);


    const withServiceInProgressUpdated = async (cb, checkUwLockedStatus = false,
        serviceCallInvoker = true) => {
        updateServiceCallInProgress(serviceCallInvoker);
        const isUwLocked = false;
        if (checkUwLockedStatus && isExternalUser) {
            // isUwLocked = await WniCommonQuoteService.isUwLocked(quoteID, sessionUUID, authHeader);
            if (isUwLocked) {
                goToReadOnlyMode(isUwLocked);
            }
        }
        if (!(isUwLocked && isExternalUser)) {
            try {
                await cb();
            } catch (e) {
                const errorMessage = ServiceErrorUtil.getErrorMessage(e);
                modalHelper.alert({ errorMessage });
            } finally{
                updateServiceCallInProgress(false);
                setLoadingMask(false);
            }
        }
    };

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    useEffect(() => {
        // const isCopySubmission = WizardUtil.isCopySubmissionInProgress(wizardPageData);
        // if (isCopySubmission) {
        //     return;
        // }

        // if (showFullPayDiscount) {
        //     updatePaymentViewType(PAYMENT_PLAN_TYPES.annually);
        // }

        const quoteErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');
        // updateIsNewAccount(_.get(submissionVM.value, 'baseData.numberOfQuotedOrRatedQuotes_Ext') === 1);
        withServiceInProgressUpdated(async () => {
            const currentPaymentViewType = await initPaymentPlanViewType();

            const updateSideBySideDataParams = {
                quoteErrorsAndWarnings,
                extraWizardPageData: { [WizardConstants.paymentViewType]: currentPaymentViewType },
            };

            const cachedSxsDataDTO = wizardPageData[WizardConstants.sideBySideData];
            if (cachedSxsDataDTO) {
                updateSideBySideDataParams.sxsDataRetriever = () => cachedSxsDataDTO;
            }
            await updateSideBySideData(updateSideBySideDataParams);
        });
        WindowUtil.scrollToTop();
    }, []);

    const onReferToUnderwriter = async (noteForUW, periodPublicID) => {
        updateServiceCallInProgress(`referToUWBtn${periodPublicID}`);

        if (selectedVersion !== periodPublicID) {
            submissionVM.value = await WniCommonQuoteService.updateSelectedVersion(quoteID,
                sessionUUID, periodPublicID, authHeader);
            updateWizardSnapshot(submissionVM);
        }

        const jobResponse = await WniSubmissionService.referToUnderwriter(quoteID,
            noteForUW, authHeader);

        // const serverErrors = _.get(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
        const isUwLocked = _.get(jobResponse, 'uwlocked');
        const referToUWIssues = ErrorsAndWarningsUtil.extractServerErrors(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
        if (!_.isEmpty(referToUWIssues)) {
            updateExtraBlockingSubmitIssues(referToUWIssues);
            WindowUtil.scrollTo('quoteErrorsAndWarnings');
        } else if (isUwLocked) { // go to readonly mode regardless of Internal/External user
            // update flag before wizard mode is changed, so that PAQuotePage.readonly
            // does not get loading mask turned on
            goToReadOnlyMode(isUwLocked);
        }

        updateServiceCallInProgress(false);
    };

    const onFinishQuote = useCallback(async (periodPublicID) => {
        withServiceInProgressUpdated(async () => {
            // const newErrorsAndWarnings = await WniLoadSaveService.validateFinishQuoteData(quoteID, sessionUUID, 'PAQuote', authHeader);
            // const hasValidationIssue = ErrorsAndWarningsUtil.hasValidationIssue(newErrorsAndWarnings);
            // if (hasValidationIssue) {
            //     _.set(submissionVM.value, 'errorsAndWarnings', newErrorsAndWarnings);
            //     updateErrorsAndWarnings(errorsAndWarnings);
            // } else {
            const newQuoteDataDTO = await WniSxsQuoteService.finishSxsQuote(quoteID,
                periodPublicID, sessionUUID, authHeader);
            const quoteErrorsAndWarnings = _.get(newQuoteDataDTO, 'errorsAndWarnings');

            submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, newQuoteDataDTO);

            // await updateSideBySideData({ quoteErrorsAndWarnings });
            await updateSideBySideDataWithActiveQuotes({ quoteErrorsAndWarnings });
            // }

            updateWizardData(submissionVM);
        }, true, `continueWithQuoteBtn${periodPublicID}`);
    }, [authHeader, quoteID, sessionUUID, updateWizardData]);

    const onSubmitQuote = useCallback(async (sxsPeriodPublicID) => {
        // run final validation
        withServiceInProgressUpdated(async () => {
            // submit flow
            // submissionVM.value = await WniSxsQuoteService.submitSxsQuote(quoteID, sxsPeriodPublicID,
            //     sessionUUID, authHeader);
            const newQuoteDataDTO = await WniSxsQuoteService.submitSxsQuote(quoteID, sxsPeriodPublicID,
                sessionUUID, authHeader);
            if (selectedVersion !== sxsPeriodPublicID) {
                // branch changed, need to refresh the whole lobData
                submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, newQuoteDataDTO);
            } else {
                // branch not changed, only refresh the RiskAnalysis data
                submissionVM.value = DTOUtil.getUpdatedDTOWithNewPARiskData(submissionVM.value, newQuoteDataDTO);
            }

            const accountClearanceData = await WniSxsQuoteService.retrieveRiskReservation(quoteID,
                sxsPeriodPublicID, sessionUUID, authHeader);

            const quoteErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');
            const serviceErrors = ErrorsAndWarningsUtil.getServerErrorsAsValidationIssues(
                quoteErrorsAndWarnings
            );
            if (!_.isEmpty(serviceErrors)) {
                updateExtraBlockingSubmitIssues(serviceErrors);
            }
            // await updateSideBySideData({
            await updateSideBySideDataWithActiveQuotes({
                quoteErrorsAndWarnings,
                extraWizardPageData: { [WizardConstants.accountClearanceData]: accountClearanceData, }
            });

            // To be refactored===========================
            // const updatedStatus = _.get(submissionVM.value, 'baseData.periodStatus');
            // const [cachedPaymentPlans, bindData] = await updateSelectedPaymentPlan(paymentViewType,
            //     updatedStatus === 'Quoted');
            // ===========================================

            updateWizardData(submissionVM);
        }, true, `runFinalValidationBtn${sxsPeriodPublicID}`);
    }, [authHeader, quoteID, sessionUUID, updateWizardData]);

    const onContinueToIssue = useCallback(async (onNext, periodPublicID) => {
        updateServiceCallInProgress(`continueToIssueBtn${periodPublicID}`);
        if (isExternalUser) {
            // const isUwLocked = await WniCommonQuoteService.isUwLocked(quoteID, sessionUUID, authHeader);
            const isUwLocked = false;
            if (isUwLocked) {
                goToReadOnlyMode(isUwLocked);
                return;
            }
        }

        let selectedVersionUpdated = false;
        if (selectedVersion !== periodPublicID) {
            selectedVersionUpdated = true;
            await WniCommonQuoteService.updateSelectedVersion(quoteID,
                sessionUUID, periodPublicID, authHeader);
        }

        const readyForIssueErrorsAndWarnings = await WniSxsQuoteService.getReadyForIssueErrorsAndWarnings(
            quoteID, sessionUUID, authHeader
        );
        const hasValidationError = ErrorsAndWarningsUtil.hasValidationErrorOnQuotePage(readyForIssueErrorsAndWarnings);
        if (hasValidationError) {
            if (selectedVersionUpdated) {
                // To be refactored: No need to retrieve QuoteData and BindData in this case
                submissionVM.value = await WniLoadSaveService.retrieveSubmissionByQuoteId(quoteID, authHeader);
                updateWizardSnapshot(submissionVM);
            }

            // await updateSideBySideData();
            updateErrorsAndWarnings(readyForIssueErrorsAndWarnings);
            WindowUtil.scrollTo('quoteErrorsAndWarnings');
            updateServiceCallInProgress(false);
            return;
        }
        // if (backendPaymentViewType !== paymentViewType) {
        //     const updateResult = await WniCommonQuoteService.updateQuotePriceViewMode(quoteID,
        //         sessionUUID, paymentViewType, authHeader);
        //     if (updateResult === PortalConstants.OPERATION_SUCCESS) {
        //         updateBackendPaymentViewType(paymentViewType);
        //     }
        // }
        const uwIssueUpdated = await WniSxsQuoteService.checkPreIssuanceUWIssue(quoteID, sessionUUID, periodPublicID, authHeader);
        if (uwIssueUpdated) {
            await updateSideBySideData();
        } else {
            if (_.isEmpty(bindData) || _.isEmpty(quoteData) || selectedVersionUpdated) {
                // refresh QuoteDataDTO to make sure bindData/quoteData is available
                // submissionVM.value = await WniLoadSaveService.retrieveSubmission({
                //     quoteID, postalCode
                // }, authHeader);

                if (selectedVersionUpdated) {
                    // Fix defect for POI-15543
                    submissionVM.value = await WniLoadSaveService.retrieveSubmissionByQuoteId(quoteID, authHeader);
                } else {
                    const quoteAndBindData = await WniLoadSaveService.retrieveQuoteAndBindData(quoteID, sessionUUID, authHeader);
                    submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, quoteAndBindData);
                }

                updateWizardSnapshot(submissionVM);
            }

            updateWizardPageData({ [WizardConstants.paymentViewType]: paymentViewType });
            updateServiceCallInProgress(false);

            // BaseWizardPage.onNext() might need to accept latest wizardData in order to
            // get rid of any uncertainty. It is not implemented here due to following pages
            // not existing anymore to accommodate the new workflow introduced in R2.
            // onNext({ wizardData: submissionVM });
            // onNext();
            if (ActiveQuoteUtil.hasOnlyOneActiveJob(accountActiveQuotes)) {
                WizardPageJumpUtil.goToAccountPaymentPage(history, quoteID);
            } else {
                WizardPageJumpUtil.goToAccountSummaryPage(history, accountNumber);
            }
        }
        updateServiceCallInProgress(false);
    }, [accountActiveQuotes, accountNumber, authHeader, bindData, goToReadOnlyMode,
        history, isExternalUser, paymentViewType, quoteData, quoteID, selectedVersion,
        sessionUUID, submissionVM, updateSideBySideData, updateWizardPageData, updateWizardSnapshot]);

    const onAddSideBySidePair = async () => {
        withServiceInProgressUpdated(async () => {
            submissionVM.value = await WniSxsQuoteService.createSxsPair(quoteID, sessionUUID,
                authHeader);
            // await updateSideBySideData();
            await updateSideBySideDataWithActiveQuotes();

            updateWizardData(submissionVM);
        }, false, 'addSxsPairBtn');
    };

    const onRemoveSideBySidePair = async (sxsPeriodPublicID) => {
        modalApi.showConfirm({
            title: messages.deleteSideBySidePairTitle,
            message: isForNamedNonOwner ? messages.deleteSideBySideNamedNonOwnerMessage : messages.deleteSideBySidePairMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.deleteSideBySidePairConfirm,
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            withServiceInProgressUpdated(async () => {
                submissionVM.value = await WniSxsQuoteService.deleteSxsPair(quoteID,
                    sxsPeriodPublicID, sessionUUID, authHeader);
                // await updateSideBySideData();
                await updateSideBySideDataWithActiveQuotes();
                updateWizardData(submissionVM);
                // reset download dropdown options to default
                updateSelectedDocument('');
            }, false, `removeSxsPairBtn${sxsPeriodPublicID}`);
        }, _.noop);
    };

    /**
     * References:
     * 1, PAQuoteUIHelper.generateColumnData()
     * 2, QuoteClauseTableUIHelper.changeSubmissionAndSync()
     *
     * Refactoring Notice: Replace positional parameters with named parameters
     * @param {string} value
     * @param {string} changedPath
     * @param {string} lobOfferingPath
     * @param {number} lobOfferingIndex
     */
    // See LineCoveragesInputComponent.changeSubmissinoAndSync()
    const onUpdateCoverage = async (value, changedPath,
        lobOfferingPath, lobOfferingIndex) => {
        // LineCoveragesInputComponent::changeSubmission()

        // LineCoveragesInputComponent::syncCoverages()
        // LineCoveragesInputComponent::onClauseUpdate();

        // The OOTB ClausesUtil.setClauseValue() throws 
        // TypeError: Cannot read properties of undefined (reading 'getNonVMPath')
        // which is caused by undefined ViewModelUtil. LOL.
        // ClausesUtil.setClauseValue(submissionVM, value, changedPath);
        WniClausesUtil.setClauseValue(submissionVM, value, changedPath);
        
        const lobName = ClausesUtil.getLobNameFromPath(lobOfferingPath);
        // const clauseType = WniClausesUtil.getClauseTypeFromPath(changedPath);
        // const coveragesPath = `${lobOfferingPath}.coverages.${clauseType}`;
        // const clauses = _.get(submissionVM, `${coveragesPath}.value`);
        const offeringPeriodPublicID = _.get(submissionVM, `${lobOfferingPath}.publicID_Ext.value`);
        // const clausesToUpdate = ClausesUtil.structureClausesForServer(clauses, lobName, clauseType);
        const clausesToUpdate = CoveragesUtil.generateUpdatedCoveragesDTO({
            submissionVM,
            lobName,
            selectedVersionOfferingsIndex: lobOfferingIndex,
            structureClausesForServer: WniClausesUtil.structureClausesForServerWithoutScheduleItem,
        });

        const sxsPeriods = _.get(sxsDataDTO, `${SXS_LOB_NAME}.periods`, []);
        const sxsPeriod = QuoteUtil.getSideBySidePeriod(sxsPeriods, offeringPeriodPublicID);
        const previousPeriodStatus = _.get(sxsPeriod, 'periodStatus');

        // the usage of withServiceInProgressUpdated here breaks isClauseLoading. Hmm.
        updateServiceCallInProgress(true);
        // withServiceInProgressUpdated(async () => {
        const response = await WniSxsQuoteService.updateSxsCoverages(
            quoteID, sessionUUID, offeringPeriodPublicID, clausesToUpdate, authHeader
        );
        submissionVM.value = response;
        updateErrorsAndWarnings(_.get(response, 'errorsAndWarnings'));
        updateWizardData(submissionVM);

        if (previousPeriodStatus !== 'Draft') {
            await updateSideBySideData();
        } else {
            const isRecommendedCovInvolved = QuoteCoveragesUtil.isRecommendedCoveragesInvolved(SXS_LOB_NAME,
                clausesToUpdate);
            if (isRecommendedCovInvolved) {
                await updateSideBySideData({
                    sxsDataRetriever: WniSxsQuoteService.retrieveBasicSxsData,
                    // Presever existing errors and warnings
                    quoteErrorsAndWarnings: sxsDataDTO.errorsAndWarnings,
                });
            }
        }
        // });
        updateServiceCallInProgress(false);
        // return newSubmissionVM;
    };

    const onRateAll = async (sxsPeriodPublicID) => {
        if (!isComponentValid) {
            updateShowErrors(true);
            WindowUtil.scrollTo(invalidFields);
            return;
        }
        withServiceInProgressUpdated(async () => {
            submissionVM.value = await WniSxsQuoteService.quoteAll(quoteID, sessionUUID, authHeader);
            updateWizardData(submissionVM);

            const updatedStatus = _.get(submissionVM.value, 'baseData.periodStatus');
            // if (updatedStatus !== 'Draft') {
            // await updateSideBySideData();
            await updateSideBySideDataWithActiveQuotes();
            // }
            // reset download dropdown options to default
            updateSelectedDocument('');
        }, false, `getUpdatedRateBtn${sxsPeriodPublicID}`);
    };

    const onAccountClearance = async (periodPublicID) => {
        const cachedData = _.get(wizardPageData, WizardConstants.accountClearanceData, {});
        return cachedData;
        // if (cachedData) {
        //     return cachedData;
        // }

        // const accountClearanceData = await WniSxsQuoteService.retrieveRiskReservation(quoteID,
        //     periodPublicID, sessionUUID, authHeader);
        // if (!_.isEmpty(accountClearanceData)) {
        //     const activityData = ActivityUtil.getQuoteAccountActivityData(quoteID,
        //         translator(messages.activitySubject), translator(messages.activityDesc));
        //     await ActivitiesService.createNewActivity(activityData, null, authHeader);
        // }
        // updateWizardPageData({ [WizardConstants.accountClearanceDat]: accountClearanceData });
        // return accountClearanceData;
    };

    const postAccountMerge = async () => {
        withServiceInProgressUpdated(async () => {
            const issueCodes = ['ProtalHasRiskIssuePolicy', 'ProtalHasRiskMatch'];
            await WniLoadSaveService.approveUnderwritingIssueByCodes(
                quoteID, sessionUUID, issueCodes, authHeader
            );
            await updateSideBySideData();
            // refresh QuoteDataDTO so that QuoteBaseDataDTO is populated with new account details
            // submissionVM.value = await WniLoadSaveService.retrieveSubmission({
            //     quoteID, postalCode
            // }, authHeader);

            // Fix defect for POI-15543
            submissionVM.value = await WniLoadSaveService.retrieveSubmissionByQuoteId(quoteID, authHeader);

            updateWizardSnapshot(submissionVM);
            updateIsNewAccount(false);
        });
    };

    const handleDownload = useCallback(async (v, downloadOptions = []) => {
        if (_.isNil(v)) {
            return;
        }
        const value = _.find(downloadOptions, (opt) => _.get(opt, 'code') === _.get(v, 'code') && _.get(opt, 'name') === _.get(v, 'name'));
        const selectedVersionPublicID = _.get(value, 'periodPublicID');
        const selectedVersionPairPeriodPublicID = _.get(value, 'pairPeriodPublicID');
        const onDemandDoc = _.get(value, 'docName');
        updateSelectedDocument(value);
        updateServiceCallInProgress(true);
        const doc = await WniSubmissionService.getOnDemandQuoteSummaryDocument(
            [quoteID, selectedVersionPublicID,
                selectedVersionPairPeriodPublicID, onDemandDoc], authHeader
        );
        const successCallback = () => {
            updateServiceCallInProgress(false);
        };
        const errorCallback = () => {
            updateServiceCallInProgress(false);
            modalApi.showConfirm({
                title: 'Error',
                message: ServiceErrorUtil.prependWithFriendMessage(),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: messages.ok,
            }).then(() => {
                _.noop();
            }).catch(() => {
                _.noop();
            });
        };
        await DocumentsUtil.tryDownloadDocument(
            doc, authHeader, history, WniDocumentRetrievalService, successCallback, errorCallback
        );
    }, [authHeader, history, quoteID, updateServiceCallInProgress]);


    // =======================
    const onStartNewSubmission = useCallback(async (productCode, countryCode) => {
        const newEffectiveDate = getProductEffectiveDate(productCode) || pcServerSystemDate;
        withServiceInProgressUpdated(async () => {
            let submissionDTO = null;
            if (Object.keys(productCodeToCopyWayMap).includes(productCode)) {
                const way = productCodeToCopyWayMap[productCode];
                submissionDTO = await WniSubmissionService.copySubmissionByWay(
                    quoteID,
                    way,
                    authHeader
                );
            } else {
                const newSubmissionDTO = {
                    accountNumber,
                    country: countryCode,
                    state: policyState,
                    // effectiveDate: periodEffectiveDate,
                    effectiveDate: newEffectiveDate,
                    productCode: productCode,
                    producerCode: periodProducerCodeStr,
                    producerCodePublicID_Ext: periodProducerCodePubliID,
        
                };
    
                // Invokes SubmissionHandler.createNewSubmission(NewSusbmissionDTO): SubmissionDTO
                submissionDTO = await SubmissionService.createSubmission(newSubmissionDTO, authHeader);
            }
            
            const { jobNumber: newQuoteID } = submissionDTO;
            if (newQuoteID) {
                await WniSubmissionService.addRecentlyViewedSubmission(newQuoteID, authHeader);
                WizardPageJumpUtil.goToNewQuoteWizard(history, {
                    productCode,
                    postalCode,
                    jobID: newQuoteID
                });
            }
            
        });
    }, [pcServerSystemDate]);

    const renderCustomWizardPageFooterContent = useCallback(() => {
        return (<>
            <Button id="wni-back-to-account-summary" type="outlined" onClick={goToAccountSummaryPage}>
                {QuoteCommonMessages.backToAccountSummary}
            </Button>
            <QuoteAdditionalProductComponent
                alreadyOwnedProductCode={alreadyOwnedProductCode}
                availableProducts={availableProducts}
                startSubmissionFn={onStartNewSubmission}
                accountNumber={accountNumber}
                accountType = {accountType}
                baseState={policyState}
                dropUp
            />
        </>);
    }, [goToAccountSummaryPage, alreadyOwnedProductCode, availableProducts, onStartNewSubmission, accountNumber]);


    const generateOverrides = useCallback((onNext) => {
        const {
            [WizardConstants.accountClearanceData]: {
                matchAccounts,
                poMatchAccounts,
            } = {},
        } = wizardPageData;
        const hasMatchingAccount = !(_.isEmpty(matchAccounts) && _.isEmpty(poMatchAccounts));
        const showAccountClearanceLink = isNewAccount && hasMatchingAccount;

        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        const isMvrClueServiceCalled = IncidentsUtil.checkMvrClueServiceCallStatus(submissionVM.value);
        // const {
        //     baseData: {
        //         periodStatus,
        //         displayStatus_Ext: displayStatus,
        //     }
        // } = submissionVM.value;

        const columnData = generateColumnData({
            submissionVM,
            sxsDataDTO,
            // filterLobOfferings: true,
            isClueCompare,
            isMvrCompare,
            isMvrClueServiceCalled,
            showAccountClearanceLink,
            currentAccountNumber: _.get(submissionVM.value, 'baseData.accountNumber'),
            // paymentMethod: paymentViewType,
            isMonthlyPayment: PaymentUtil.isMonthlyPaymentPlan(paymentViewType),
            callbackMap: {
                onFinishQuote,
                onSubmitQuote,
                onReferToUnderwriter,
                onContinueToIssue: async (periodPublicID) => onContinueToIssue(onNext, periodPublicID),
                onRateAll,
                goToIncidentReports,
                onAccountClearance,
                postAccountMerge,
                goToDriverCoveragesPage,
            }
        });
        const tableData = generateTableData(submissionVM, columnData, translator, sxsDataDTO);

        const sxsPeriods = _.get(sxsDataDTO, `${SXS_LOB_NAME}.periods`, []);
        const canAddSxsPair = QuoteUtil.canAddSideBySidePair(sxsPeriods);

        const validationIssues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings, extraBlockingSubmitIssues);
        const filteredValidationIssues = validationIssues.filter(ErrorsAndWarningsUtil.isIssueOnOrBeforeQuotPage);
        const issueJumpFnMap = ErrorsAndWarningsUtil.getValidationIssueJumpFnMap(validationIssues,
            wizardSteps, wizardStepToFieldMapping, wizardJumpTo);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        const calculatutedPeriods = _.filter(sxsPeriods, (period) => _.get(period, 'periodStatus') !== 'Draft');
        const downloadOptions = DocumentsUtil.getOnDemandDcouments(calculatutedPeriods);

        const isDisplayStatusQuotedOrApproved = displayStatus === 'Quoted' || displayStatus === 'Approved';
        const INELIGIBLE_UW_ISSUE_CODES = [
            'PABothKnockout_Ext',
            'PAEligibleSelectOnly_Ext',
            'PAIneligibleSelectSignature_Ext',
            'PAEffectiveDateAfterPendingRate_Ext'
        ];
        const underwritingIssues = _.get(sxsDataDTO, 'errorsAndWarnings.underwritingIssues', []);
        const hasIneligibleUwIssue = ErrorsAndWarningsUtil.hasUnApprovedIneligibleUWIssue(
            underwritingIssues, INELIGIBLE_UW_ISSUE_CODES
        );
        
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            errorsAndWarningsSection: {
                validationIssues: IssuanceValidationUtil.getIssuesMap(filteredValidationIssues),
                issueJumpFnMap,
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator),
                typeFooterFormatter: getQuotePageErrorsAndWarningsFooter({
                    wizardSteps,
                    wizardStepToFieldMapping,
                    wizardJumpTo,
                    resolveButtonLabel: translator(QuoteCommonMessages.resolveErrors),
                    isServiceCallInProgress,
                }),
                issueRenderFn: getQuotePageIssueRenderFn,
                scrollToIssues: true,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
                issueDistinguisher: true,
            },
            quoteStatus: {
                content: displayStatus,
            },
            addSxsPairBtn: {
                visible: canAddSxsPair,
                disabled: periodStatus === 'Draft',
                content: renderHelper.getButtonContent(translator(messages.addSxsPair), isServiceCallInProgress, 'addSxsPairBtn'),
            },
            downloadBtn: {
                placeholder: '---Print Quote Summary---',
                disabled: isServiceCallInProgress || !isDisplayStatusQuotedOrApproved || hasIneligibleUwIssue,
                availableValues: downloadOptions,
                // className: styles.downloadDropDownBtn,
                onValueChange: (v) => handleDownload(v, downloadOptions),
                value: selectedDocument,
                visible: ! (isDisplayStatusQuotedOrApproved && _.isEmpty(downloadOptions)),
            },
            sxsPeriodsInfoSection: {
                quoteID,
                columnData,
                onRemoveSideBySidePair,
                isServiceCallInProgress,
            },
            paymentViewToggle: {
                value: paymentViewType,
                disabled: periodStatus === 'Draft' || isServiceCallInProgress,
                onValueChange: (value, path) => {
                    withServiceInProgressUpdated(async () => {
                        updatePaymentViewType(value);
                        updateWizardPageData({ [WizardConstants.paymentViewType]: value });
                        markFollowingWizardStepsUnvisited();
                        // POI-23476 Point1 current only need for R3 HO requirement
                        if (!workflowType.ClassicalWorkflow) {
                            await updateSelectedPaymentPlan(value, true);
                        }
                        
                    });
                },
                visible: !_.isEmpty(columnData),
            },
            markAsNotTakenBtn: {
                visible: false,
                jobNumber: quoteID,
                isServiceCallInProgress,
                preNotTaken: () => updateServiceCallInProgress(true),
                postNotTaken: (submissionDTO) => {
                    const historyState = { submissionDTO };
                    history.push(`/quotes/${quoteID}/summary`, historyState);
                },
            },
            noSxsDataMessage: {
                visible: !isServiceCallInProgress && _.isEmpty(columnData),
            },
            quoteTable: {
                columnData,
                tableData,
                visible: !_.isEmpty(columnData),
                onValidate,
                showErrors,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, submissionVM, isServiceCallInProgress, errorsAndWarnings,
        sxsDataDTO, translator, extraBlockingSubmitIssues,
        paymentViewType, wizardPageData, isNewAccount,
        selectedDocument, showErrors, workflowType]);

    const renderQuotePage = useCallback(({ onNext }) => {

        const overrideProps = generateOverrides(onNext);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onAddSideBySidePair: onAddSideBySidePair,
                onChangeSubmissionAndSync: onUpdateCoverage,
                // onSyncCoverages: _.noop,
            },
            resolveComponentMap: {
                validationissuescomponent: ValidationIssuesComponent,
                errorsandwarningsdisplaycomponent: ErrorsAndWarningsDisplayComponent,
                sxsquoteperiods: SideBySidePeriodsComponentV2,
                // withdrawtransactioncomponent: WithdrawTransactionComponent,
                // accountClearanceComponent: AccountClearanceComponent
                nottakencomponent: MarkNotTakenComponent,
            }
        };

        // const isLoading = isServiceCallInProgress && !sxsDataDTO;
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        );
    }, [generateOverrides, submissionVM, updateWizardData]); // sxsDataDTO

    //---------------------
    const isInitializing = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            showNext={false}
            showPrevious={false}
            showCancel={false}
            // alwaysCallOnNext
            renderCustomWizardPageFooterContent = {renderCustomWizardPageFooterContent}
        >
            {!isInitializing && renderQuotePage}
        </WizardPage>
    );
}

PAQuotePage.propTypes = wizardProps;
export default PAQuotePage;
