import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Table, TableColumn } from '@jutro/datatable';
import { uwIssuesMessages as UWMessages } from 'gw-components-platform-react';
import defaultUnderWritingComponentMessages from 'gw-capability-gateway-react/Components/UnderwritingComponent/UnderwritingComponent.messages';
import wniMessages from './UnderIssuesTableComponent.messages';
import metadata from './UnderIssuesTableComponent.metadata.json5';
import styles from './UnderIssuesTableComponent.module.scss';

const UWIssuesTable = (props) => {
    const { uwIssues, noRecordsMessage, uwUtil } = props;
    const translator = useTranslator();

    const messages = { ...defaultUnderWritingComponentMessages, ...wniMessages };

    const getBlockingPointDisplayKey = useCallback((blockingPoint) => {
        switch (blockingPoint) {
            case 'BlocksBind':
                return translator(UWMessages.blocksBind);
            case 'BlocksQuote':
                return translator(UWMessages.blocksQuote);
            case 'BlocksQuoteRelease':
                return translator(UWMessages.blocksQuoteRelease);
            case 'NonBlocking':
                return translator(wniMessages.postIssueReview);
            case 'BlocksIssuance':
                return translator(wniMessages.uwReferral);
            default:
                return blockingPoint;
        }
    }, [translator]);

    const getUWIssueStatusByOffering = useCallback(
        (uwIssue, index, id) => {
            const offering = id !== 'Status' ? id : undefined;
            const currentUWIssue = offering
                ? _.find(uwIssue.onOfferings, (offer) => {
                    return offer.offering === offering;
                })
                : uwIssue;

            let displayStatus = translator(messages.notApplicable);
            if (!currentUWIssue) {
                return displayStatus;
            }

            if (currentUWIssue.currentBlockingPoint) {
                displayStatus = getBlockingPointDisplayKey(currentUWIssue.currentBlockingPoint);
            }

            return currentUWIssue.currentBlockingPoint === 'NonBlocking'
                && currentUWIssue.hasApprovalOrRejection
                ? translator(messages.approved)
                : displayStatus;
        },
        [getBlockingPointDisplayKey, translator, messages.notApplicable, messages.approved]
    );

    const getCell = useCallback(
        (items, index, property) => {
            const isIgnoreId = ['longDescription'].includes(property.id);
            if (!isIgnoreId) {
                return getUWIssueStatusByOffering(items, index, property.id);
            }
            return items[property.id];
        },
        [getUWIssueStatusByOffering]
    );
    const getHeader = useCallback((id) => {
        const headerInfo = {
            shortDescription: messages.shortDescription,
            longDescription: messages.description,
            Status: messages.jobStatusUW
        };
        return headerInfo[id] || id;
    }, []);
    const getTableColumns = useCallback(() => {
        // const offeringAndStatus = uwUtil.offerings || ['Status'];
        const bothOfferings = ['longDescription', 'Status'];
        // const flattenOfferings = _.uniq(_.flatten(bothOfferings));
        const dynamicColumn = bothOfferings.map((issueOffer) => {
            return (
                <TableColumn
                    id={issueOffer}
                    textAlign="left"
                    header={getHeader(issueOffer)}
                    cell={getCell}
                />
            );
        });
        return dynamicColumn;
    }, [getCell, getHeader, uwUtil.offerings]);

    const UWIssueTableComponent = (props) => (
        <Table data={uwIssues} placeholder={noRecordsMessage}>
            {getTableColumns()}
        </Table>
    );
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            uwissuestablecomponent: UWIssueTableComponent
        }
    };

    return (
        <ViewModelForm
            model={{}}
            uiProps={metadata.pageContent}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};
UWIssuesTable.propTypes = {
    uwIssues: PropTypes.shape([]).isRequired,
    noRecordsMessage: PropTypes.string.isRequired,
    uwUtil: PropTypes.shape({
        offerings: PropTypes.shape([])
    }).isRequired
};

export default UWIssuesTable;
