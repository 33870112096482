import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import React, { useContext } from 'react';
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalNext,
} from 'wni-common-base-components';

function Modal(props) {
    const { size, isOpen, onReject, onResolve } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const onConfirm = () => {
        onResolve('modal confirm data');
    };
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader onClose={onReject} title="WNI Modal Title" />
            <ModalBody>WNI Modal Content.....</ModalBody>
            <ModalFooter>
                <Flex justifyContent="right" gap="small">
                    <Button onClick={onReject}>Cancel</Button>
                    <Button onClick={onConfirm}>OK</Button>
                </Flex>
            </ModalFooter>
        </ModalNext>
    );
}

export default Modal;
