import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';

import styles from './LocationScreen.module.scss';
import metadata from './LocationScreen.metadata.json5';

import './LocationScreen.messages';

export class Location extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        quoteID: PropTypes.string.isRequired,
        jobID: PropTypes.string.isRequired,
        sessionUUID: PropTypes.string.isRequired,
        labelPosition: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        ...validationPropTypes
    };

    localeService = ServiceManager.getService('locale-service');

    state = {
        locationVM: {}
    };

    componentDidMount() {
        const { viewModelService } = this.props;
        const newLocation = {
            address: {
                country: this.localeService.getDefaultCountryCode()
            },
            buildings: []
        };
        const locationVM = viewModelService.create(
            newLocation,
            'pc',
            'edge.capabilities.policyjob.lob.businessowners.coverables.dto.LocationDTO'
        );
        this.setState({ locationVM });
    }

    onModelChange = (locationVM) => {
        this.setState({ locationVM });
    };

    onSaveLocation = () => {
        const { onClick, BopCoverablesService } = this.props;
        const {
            quoteID, jobID, sessionUUID, authHeader
        } = this.props;
        const { locationVM } = this.state;
        BopCoverablesService.addBOPLocation(
            quoteID || jobID,
            locationVM.value,
            sessionUUID,
            authHeader
        ).then((response) => {
            onClick(response);
        });
    };

    onBackClick = () => {
        const { onClick } = this.props;
        onClick(false);
    };

    render() {
        const { labelPosition, setComponentValidation, isComponentValid } = this.props;
        const { locationVM } = this.state;

        const resolvers = {
            resolveCallbackMap: {
                onSaveLocation: this.onSaveLocation,
                onBackClick: this.onBackClick
            },
            resolveClassNameMap: styles
        };

        const overrideProps = {
            '@field': {
                // apply to all fields
                showOptional: true,
                labelPosition
            },
            phoneNumber: {
                countryCode: this.localeService.getDefaultPhoneCountry()
            },
            locationAddButton: {
                disabled: !isComponentValid
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={locationVM}
                overrideProps={overrideProps}
                onModelChange={this.onModelChange}
                onValidationChange={setComponentValidation}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }
}

export default withValidation(
    withViewModelService(withAuthenticationContext(withDependencies(['BopCoverablesService'])(Location))));
