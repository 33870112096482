import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, IconButton } from '@jutro/components';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { withTracking } from '@xengage/gw-portals-tracking-react';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import singleErrorStyles from './WizardSingleErrorComponent.module.scss';

function WizardSingleErrorComponent(props) {
    const wizardContext = useContext(WizardContext);
    const { acknowledgeError } = wizardContext;
    const { issuesList } = props;

    const hideWarnings = useCallback(
        (index) => {
            const selectedError = issuesList[index];
            acknowledgeError(selectedError.id);
        },
        [acknowledgeError, issuesList]
    );

    const issuesSection = issuesList.map((issue, index) => {
        const issueType = issue.level === ErrorLevel.LEVEL_ERROR ? 'gwIssuesError' : 'gwIssuesWarning';
        const issueIcon = issue.level === ErrorLevel.LEVEL_ERROR ? 'gw-error' : 'gw-warning';
        const issueTypeClass = classNames(
            singleErrorStyles.gwIssuesMessage,
            singleErrorStyles[issueType]
        );
        const closeIcon = issue.level === ErrorLevel.LEVEL_WARN ? (
            <IconButton
                icon="gw-close"
                onClick={() => hideWarnings(index)}
            />
        ) : null;

        return (
            <div className={singleErrorStyles.gwIssuesContainer} key={issue.description}>
                <div className={issueTypeClass}>
                    <div className={singleErrorStyles.gwIssuesMessageContainer}>
                        <Icon icon={issueIcon} className={singleErrorStyles[issueType]} />
                        <span>{issue.description}</span>
                    </div>
                    {closeIcon}
                </div>
            </div>
        );
    });

    return <div>{issuesSection}</div>;
}

WizardSingleErrorComponent.propTypes = {
    acknowledgeError: PropTypes.func.isRequired,
    issuesList: PropTypes.shape([])
};
WizardSingleErrorComponent.defaultProps = {
    issuesList: []
};
export default withTracking(WizardSingleErrorComponent);
