import { inputTypes as customerInputTypes } from 'customer-questionsets-config';
import { simpleDatatypeComponentMap } from '@jutro/uiconfig'

const INPUT_TYPES = {
    Choice: 'typelist',
    String: 'text',
    Boolean: 'boolean',
    Integer: 'number',
    Date: 'date',
};

const allowedInputTypes = Object.keys(simpleDatatypeComponentMap);

function isCustomerInputTypeValid(customerInputType) {
    return allowedInputTypes.includes(customerInputType);
}

/**
 * Check the input type of a Question
 * 
 * @param {object} question
 * @returns {object} - an object with the inputCtrlType of the given Question
 */
export default (question) => {
    return {
        inputCtrlType: {
            get: () => {
                const customerInputType = customerInputTypes[question.questionType];

                if(customerInputType && isCustomerInputTypeValid(customerInputType)) {
                    return customerInputType;
                }

                if (customerInputType && process.env.NODE_ENV !== 'production') {
                    console.error(`unsupported questionsets-config input type of: ${customerInputType}`);
                }

                return INPUT_TYPES[question.questionType];
            }
        }
    };
};
