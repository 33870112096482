import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { QuestionSetsViewModelUtil } from '@xengage/gw-portals-questionsets-js';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    quickIsViewModel
} from '@jutro/uimetadata';
import { MetadataContent, findInvalidFieldsFromMetadata } from '@jutro/uiconfig';
import { validationMessagesToIgnore as CustomerValidationMessagesToIgnore } from 'customer-viewmodel-config';
import { useTranslator } from '@jutro/locale';
import { getFlattenedUiPropsContent } from './flattenUiPropsHelper';


const displayKeysToIgnore = CustomerValidationMessagesToIgnore || [];

/**
 * The ViewModelForm is used to encapsulate many of the objects that need to be rendered in the page. 
 * The ViewModelForm tag passes props using object variables for uiProps, model, and onValidateChange 
 * from the .metadata.json5 file metadata used by the page, the submissionVM, the resolvers, override props
 * and validation objects
 * 
 * @function ViewModelForm
 * 
 * @memberof module:gw-portals-viewmodel-react
 * 
 * @override
 * 
 * @property {Object} uiProps Content metadata or array of metadata
 * @property {Object} overrideProps Override props at different levels e.g field
 * @property {Object} model ViewModel object
 * @property {Function} onValueChange Callback for when individual fields are updated
 * @property {Function} onModelChange Callback for when the view model is updated
 * @property {Object} resolveValue 
 * @property {Boolean} showErrors Override to force showing input errors
 * @property {Function} onValidationChange Callback for when the form validation state changes
 * @property {Object} classNameMap Resolve metadata class names
 * @property {Object} componentMap Resolve component string to component
 * @property {Object} callbackMap Resolve callback fn string to fn
 * @property {String} className Custom class to apply to content wrapper
 * @property {Boolean} showOptional If true, displays the `Optional` span
 * 
 * @returns {React.ReactElement} Instance of the ViewModelForm
 */
function ViewModelForm(props) {
    const {
        uiProps,
        overrideProps,
        model,
        onValueChange,
        onModelChange,
        resolveValue,
        showErrors,
        onValidationChange,
        classNameMap,
        componentMap,
        callbackMap,
        className,
        showOptional
    } = props;

    const translator = useTranslator();

    const flatUiProps = useMemo(() => getFlattenedUiPropsContent(uiProps), [uiProps]);

    const [fieldValidationMessages, setFieldValidationMessages] = useState({});

    const [hasDoneInitialValidation, setHasDoneInitialValidation] = useState(false);

    const formValidationName = uiProps.id;

    const translateMessages = useCallback(
        (messages = []) => {
            return messages
                .filter((message) => !displayKeysToIgnore.includes(message))
                .map((message) => translator(message));
        },
        [translator]
    );

    const readValue = useCallback(
        (id, path) => {
            const uiPropsContent = flatUiProps;
            const componentUiProps = uiPropsContent.find((item) => item.id === id) || {};
            const { componentProps = {} } = componentUiProps;
            const { passViewModel } = componentProps;

            const node = _.get(model, path);

            const overrideValue = _.get(overrideProps, `${id}.value`);
            if (!_.isUndefined(overrideValue)) {
                return overrideValue;
            }

            if (passViewModel) {
                return node;
            }

            if (ViewModelUtil.isViewModelNode(node) || QuestionSetsViewModelUtil.isViewModelNode(node)) {
                return node.value;
            }

            return node;
        },
        [flatUiProps, model, overrideProps]
    );

    /**
     * Formats the availableValue to have a displayName with i18n message shape and id
     * @ignore
     * @param {object} availableValue the availableValue to format
     * @returns {object} the availableValue in the correct format
     */
    const formatAvailableValue = useCallback((availableValue) => {
        if (availableValue && availableValue.hasOwnProperty('displayName') && availableValue.hasOwnProperty('id')) {
            return availableValue;
        }
        return {
            ...availableValue,
            displayName: {
                defaultMessage: availableValue.name || availableValue.code,
                id: availableValue.name
            },
            id: availableValue.code
        };
    }, []);

    const resolveDataProps = useCallback(
        (id, path) => {
            const vmNode = _.get(model, path, {}) || {};
            const { aspects = {} } = vmNode;
            const {
                required,
                availableValues,
                validationMessages,
                visible,
                inputCtrlType
            } = aspects;

            return {
                datatype: inputCtrlType,
                componentProps: {
                    availableValues: availableValues ? availableValues.map(formatAvailableValue) : [],
                    validationMessages: translateMessages(validationMessages),
                    visible,
                    schemaRequired: required
                }
            };
        },
        [formatAvailableValue, model, translateMessages]
    );

    const onFieldValueChange = useCallback(
        (value, path) => {
            _.set(model, path, value);
            onValueChange && onValueChange(value, path);
            onModelChange && onModelChange(model);
        },
        [model, onModelChange, onValueChange]
    );

    const onFieldValidationChanged = useCallback(
        (isValid, path, message) => {
            setFieldValidationMessages((previousMessages) => {
                if (isValid) {
                    return _.omit(previousMessages, path);
                }
                return {
                    ...previousMessages,
                    [path]: message
                };
            });

            if (!hasDoneInitialValidation) {
                setHasDoneInitialValidation(true);
            }
        },
        [fieldValidationMessages, hasDoneInitialValidation]
    );

    const isNodeValid = useCallback(
        (node) => {
            if (ViewModelUtil.isViewModelNode(node)) {
                const { valid, subtreeValid } = node.aspects;
                return valid && subtreeValid;
            }
            return true;
        },
        []
    );

    const fieldOverrides = overrideProps ? overrideProps['@field'] : {};
    const extendedProps = {
        ...overrideProps,
        '@field': {
            onValueChange: onFieldValueChange,
            onValidationChange: onFieldValidationChanged,
            showErrors,
            showOptional,
            ...fieldOverrides
        }
    };

    const resolvers = {
        resolveValue: resolveValue || readValue, // resolve value from data using path
        resolveDataProps,
        resolveCallbackMap: callbackMap, // resolve callback string to callback function,
        resolveComponentMap: componentMap,
        resolveClassNameMap: classNameMap // resolve class names to css module names
    };

    useEffect(() => {
        Object.keys(fieldValidationMessages).forEach((fieldPath) => {
            const invalidField = flatUiProps.find((uiProp) => _.get(uiProp, 'componentProps.path') === fieldPath) || {};
            const dataProps = resolveDataProps(invalidField.id, fieldPath) || {};
            const { visible, schemaRequired, validationMessages } = dataProps.componentProps;
            const node = _.get(model, `${fieldPath}`, {});

            if ((!visible || !schemaRequired || isNodeValid(node)) && _.isEmpty(validationMessages)) {
                setFieldValidationMessages((previousMessages) => {
                    return _.omit(previousMessages, fieldPath);
                })
            }
        });
    });

    const invalidFields = findInvalidFieldsFromMetadata(uiProps, extendedProps, resolvers, uiProps);
    const isFormValid = _.isEmpty(invalidFields) && _.isEmpty(fieldValidationMessages);

    useEffect(() => {
        const invalidFieldIDs = invalidFields.map((field) => field.id);
        onValidationChange && onValidationChange(isFormValid, invalidFieldIDs, {
            validationName: formValidationName,
            newInvalidFields: isFormValid ? [] : invalidFieldIDs,
        });
    }, [isFormValid, onValidationChange, invalidFields.length]);

    const pageContent = <MetadataContent uiProps={uiProps} overrideProps={extendedProps} {...resolvers} />;

    return <div className={className}>{pageContent}</div>;
}

ViewModelForm.propTypes = {
    /**
     * Content metadata or array of metadata
     */
    uiProps: PropTypes.object.isRequired,
    /**
     * Override props at different levels e.g field
     */
    overrideProps: PropTypes.object,
    /**
     * ViewModel object
     */
    model: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
    /**
     * Callback for when the view model is updated
     */
    onModelChange: PropTypes.func,
    /**
     * Callback for when individual fields are updated
     */
    onValueChange: PropTypes.func,
    /**
     * Override to force showing input errors
     */
    showErrors: PropTypes.bool,
    /**
     * Callback for when the form validation state changes
     */
    onValidationChange: PropTypes.func,
    /**
     * Resolve metadata class names
     */
    classNameMap: PropTypes.object,
    /**
     * Resolve component string to component
     */
    componentMap: PropTypes.object,
    /**
     * Resolve callback fn string to fn
     */
    callbackMap: PropTypes.object,
    /**
     * Custom class to apply to content wrapper
     */
    className: PropTypes.string,

    /**
     * If true, displays the `Optional` span
     **/
    showOptional: PropTypes.bool
};

export default ViewModelForm;
