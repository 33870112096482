/* eslint-disable max-len */
import React, {
    Component,
    useContext,
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { ViewModelForm, withViewModelService, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ActivitiesService } from 'gw-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Loader } from '@jutro/components';
import messages from 'gw-capability-gateway-react/NewQuote/NewQuoteAccountSearch.messages';
import 'gw-capability-gateway-react/NewQuote/NewQuote/NewQuotePage.messages';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import { WniAccountService, WniSubmissionService } from 'wni-capability-gateway';
import {
    AccountContactInputComponent,
    AccountClearanceComponent,
    AddressChangeVerify,
    getValidationMap,
    AddressVerifiedUtil,
    getVerifyAddressIssues,
    ExistingAccountMatchPage
} from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import {
    StateUtil, ActivityUtil, WniDateUtil, WindowUtil
} from 'wni-portals-util-js';
import { useProductsData } from 'wni-portals-util-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import NewSubmissionPage from './NewSubmission/NewSubmissionPage';
// import ExistingAccountPage from './ExistingAccount/ExistingAccountMatchPage';
import styles from './NewQuotePage.module.scss';
import metadata from './NewQuotePage.metadata.json5';
import currentMessages from './NewQuotePage.messages';

function NewQuotePage(props) {
    const modalApi = useWniModal();
    const {
        isHideContactType
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { getProductsMap } = useProductsData();
    const {
        interactionModel,
        domainCompany,
        loadingMask: { setLoadingMask },
        workflowType
    } = useDependencies(['interactionModel', 'domainCompany', 'loadingMask', 'workflowType']);


    const history = useHistory();
    const location = useLocation();
    const matchParams = useParams();

    const { state = {}} = location;
    const {
        AOEffectiveDate,
        baseState,
        productSelectedList
    } = state
    const {
        accountNumber: urlAccountNumber,
    } = matchParams;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation,
        invalidFields
    } = useValidation('NewQuotePage');


    const [submissionVM, updateSubmissionVM] = useState({});
    const [producerCodeOptions, updateProducerCodeOptions] = useState([]);
    const [isAnExistingAccount, updateIsAnExistingAccount] = useState(false);
    const [accountHolderDetails, updateAccountHolderDetails] = useState({});
    const [accountHolderViewVM, updateAccountHolderViewVM] = useState({});
    const [newAccountCreate, updateNewAccountCreate] = useState({});
    const [organisationOptions, updateOrganisationOptions] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [isLoading, updateIsLoading] = useState(true);
    const [subTypeLabel, updateSubTypeLabel] = useState('');
    const [contactTypeValue, updateContactTypeValue] = useState('');
    const [dynamicNotifications, updateDynamicNotifications] = useState([]);
    const [showAccountClearance, updateShowAccountClearance] = useState(false);
    const [reserVationData, updateReservationData] = useState({});
    const [sysLocalDate, updateSysLocalDate] = useState({});
    const [isAddressFlag, updateIsAddressFlag] = useState(false);
    const [productSelected, updateProduceSelected] = useState(productSelectedList);

    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const productsMap = getProductsMap('person')
    const PRODUCTS_TO_BLOCK = 'HOPHomeowners'
    // const updateAccountHolderVM = (accountHolderViewVMParam) => {
    //     updateAccountHolderViewVM(accountHolderViewVMParam);
    // };

    useEffect(() => {
        if (_.get(state, 'isNewQuote')) {
            updateShowAccountClearance(false);
        }
    }, [state]);

    const setAccountHolderViewVM = (accountHolder, accountHolderDetailsParam) => {
        const viewModel = viewModelService.create(accountHolder, 'pc',
            'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO',
            {
                ProducerCodeRequired: false,
                PhoneRequired: true
            });
        _.set(viewModel, 'isAgentPortalContact', true);
        _.set(viewModel, 'phoneRequired_Ext', true);
        if (accountHolderDetailsParam.contactType === 'Person') {
            _.set(viewModel, 'subtype.value', 'Person');
            // BA finding 111: Default address type to home and hide it for Personal Auto
            _.set(viewModel.value, 'primaryAddress.addressType', 'home');
        } else {
            _.set(viewModel, 'subtype.value', 'Company');
        }
        // accountHolderViewVM = viewModel;
        updateAccountHolderViewVM(viewModel);
    };

    const filterPolicyWithAgency = (matchAccounts) => {
        const tempList = [];
        matchAccounts.forEach((clearance) => {
            const obj = _.find(producerCodeOptions, (item) => {
                if (!_.isEmpty(clearance.producerCodes)) {
                    const hasMatched = _.find(clearance.producerCodes, (matchCode) => {
                        return matchCode === item.code;
                    });
                    if (hasMatched) {
                        return true;
                    }
                }
                return false;
            });
            if (obj) {
                tempList.push(clearance);
            }
        });
        return tempList;
    };

    const updateLookupValidation = (validations) => {
        const validationsMap = getValidationMap(validations, dynamicNotifications);
        updateDynamicNotifications(validationsMap);
    };


    const getAgencyInfo = async (producerCodeParam) => {
        const agencyRes = await WniAccountService.getAgencyMatchData(producerCodeParam, 5, authHeader);
        if (!_.isNil(agencyRes.length) && agencyRes.length > 0) {
            return agencyRes.slice(0, 5);
        }
        return null;
    };

    const findAccount = async (accNum, submissionVMParam = submissionVM) => {
        const newSubmissionVM = viewModelService.clone(submissionVMParam);
        const newAccountHolderDetails = _.clone(accountHolderDetails);
        const account = await AccountService.getAccountDetails(accNum, authHeader);

        if (account.accountHolder) {
            newAccountHolderDetails.contactType = account.accountHolder.subtype;
            updateContactTypeValue(newAccountHolderDetails.contactType);

            newAccountHolderDetails.newAccount = account;
            // _.set(submissionVM, 'state', account.accountHolder.primaryAddress.state);
            StateUtil.tryToUpdateNewQuoteState(newSubmissionVM, account.accountHolder.primaryAddress.state, domainCompany);
            newAccountHolderDetails.displayAddressArray = account.accountHolder.primaryAddress.displayName.replace(', ', '#@ ').split('#@ ').join('\n');
            _.set(newSubmissionVM, 'accountNumber', account.accountNumber);
        } else {
            modalApi.showAlert({
                title: gatewayMessages.modelError,
                message: messages.accountTypeErrorMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            });
        }

        updateAccountHolderDetails(newAccountHolderDetails);
        updateSubmissionVM(newSubmissionVM);
        // updateIsAnExistingAccount(true);

        setAccountHolderViewVM(newAccountHolderDetails.newAccount, newAccountHolderDetails);
    };


    const generateAccountObjByAOCode = (selectedProducerCode, selectedPublicId) => {
        const newAccount = {};
        newAccount.accountHolder = _.get(accountHolderViewVM, 'value');
        if (accountHolderDetails.contactType === 'Person') {
            newAccount.accountHolder.contactName = `${accountHolderViewVM.firstName.value} ${accountHolderViewVM.lastName.value}`;
            newAccount.accountHolder.primaryAddress.addressType = 'home';
        }

        newAccount.producerCodes = [{
            code: selectedProducerCode,
            publicID: selectedPublicId
        }];
        return newAccount;
    };


    const generateAccountObj = () => {
        const newAccount = {
            accountHolder: {
                primaryAddress: {
                    addressType: 'business'
                }
            }
        };
        newAccount.accountHolder = _.get(accountHolderViewVM, 'value');
        if (accountHolderDetails.contactType === 'Person') {
            newAccount.accountHolder.contactName = `${accountHolderViewVM.firstName.value} ${accountHolderViewVM.lastName.value}`;
            newAccount.accountHolder.primaryAddress.addressType = 'home';
        }

        newAccount.producerCodes = producerCodeOptions.map((codeOption) => {
            return {
                code: codeOption.code,
                publicID: codeOption.publicID
            };
        });
        return newAccount;
    };
    const filterBlockerProductsMap = _.find(productsMap, (item) => { return item.code === PRODUCTS_TO_BLOCK} );
    const getAvailableStateList = () => {
        const productRules = _.get(filterBlockerProductsMap, 'productRules')
        const availableStatesList = productRules.filter((item) => (item.availability === 'Available'))
            .map((item) => {
                return item.state
            })
        return availableStatesList
    }

    const getPolicyStateValidationErrors = useCallback(() => {
        const accountState = _.get(accountHolderViewVM, 'primaryAddress.value.state')
        const availableStatesList = getAvailableStateList()
        if(!(availableStatesList.includes(accountState) || availableStatesList.includes(baseState))&& productSelected.includes(PRODUCTS_TO_BLOCK)) {
            return {
                type: 'Error',
                visible: true,
                reason: `We are working hard to get our new Home product out to your state and as part of that effort, you are now seeing both the Auto tile and the Home tile.  This Home tile will not take you to a live quoting portal until we are live in your state.  Please stay tuned for details!`
            }
        }return {
            type: 'Error',
            visible: false,
            reason: `We are working hard to get our new Home product out to your state and as part of that effort, you are now seeing both the Auto tile and the Home tile.  This Home tile will not take you to a live quoting portal until we are live in your state.  Please stay tuned for details!`
        }
    }, [accountHolderViewVM, baseState, productSelected]);

    const createNewAccount = async (newAccount) => {
        let isExistingAccount = isAnExistingAccount;
        const newAccountCreateClone = _.clone(newAccountCreate);
        const newSubmissionVM = viewModelService.clone(submissionVM);

        setLoadingMask(true);
        const newAccountResponse = await WniAccountService.getOrCreateAccount(newAccount, authHeader);
        setLoadingMask(false);
        if (!_.isEmpty(newAccountResponse.accountNumber)) {
            newAccountCreateClone.newAccount = newAccountResponse;
            newAccountCreateClone.displayAddressArray = newAccountResponse
                .accountHolder.primaryAddress
                .displayName.replace(', ', '#@ ').split('#@ ').join('\n');
            _.set(newSubmissionVM, 'accountNumber', newAccountResponse.accountNumber);
            // _.set(newSubmissionVM, 'state', newAccountResponse.accountHolder.primaryAddress.state);
            StateUtil.tryToUpdateNewQuoteState(newSubmissionVM, newAccountResponse.accountHolder.primaryAddress.state, domainCompany);

            isExistingAccount = true;
        } else {
            modalApi.showAlert({
                title: messages.accountTypeError,
                message: messages.newAccountCreateErrorMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                window.history.back();
            });
        }
        updateShowAccountClearance(false);
        updateNewAccountCreate(newAccountCreateClone);
        updateIsAnExistingAccount(isExistingAccount);
        updateSubmissionVM(newSubmissionVM);

        //
        // history.push(`/new-quote/${_.get(newSubmissionVM, 'accountNumber.value')}`);
        const accountNumber = _.get(newSubmissionVM.value, 'accountNumber');
        if (!workflowType.ClassicalWorkflow) {
            history.push({
                pathname: `/account-quotes/${accountNumber}`,
                state: {
                    isNewAccount: true,
                    productSelected: productSelected,
                    AOEffectiveDate,
                    baseState,
                }
            });
        } else {
            history.push(`/new-quote/${accountNumber}`);
        }
    };

    const onStartNewQuote = async (accountNumberParam) => {
        // const isAnExistingAccount = true;
        await findAccount(accountNumberParam);
        const activityObj = {
            accountNumber: accountNumberParam,
            subject: translator(currentMessages.activitySubject),
            description: translator(currentMessages.activityDescription),
            priority: 'urgent',
            mandatory: true,
            activityPattern: {
                code: 'agent_risk_reservation_referral',
                priority: 'urgent'
            }
        };
        const newActivityData = ActivityUtil.createNewActivityData(activityObj);
        await ActivitiesService.createNewActivity(newActivityData, null, authHeader);

        updateIsAnExistingAccount(true);
        updateShowAccountClearance(false);

        if (workflowType.ClassicalWorkflow) {
            history.push(`/new-quote/${accountNumberParam}`);
        } else {
            history.push({
                pathname: `/account-quotes/${accountNumberParam}`,
                state: {
                    productSelected: productSelected
                }
            });
        }
    };

    const handleValidation = () => {
        WindowUtil.scrollToInvalidField(invalidFields);
        updateShowErrors(true);
    };

    const handleCancel = () => {
        modalApi.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.close
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            interactionModel.goToPage(null, history, 'accountsHomePage');
        }, _.noop);
    };

    const onAccountContactChange = (value, path, customUpdater = undefined) => {
        if (AddressChangeVerify(path, 'primaryAddress')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateIsAddressFlag(false);
        }
        const newAccountHolder = viewModelService.clone(accountHolderViewVM);
        if (!customUpdater) {
            _.set(newAccountHolder, path, value);
            updateAccountHolderViewVM(newAccountHolder);
        } else {
            customUpdater(newAccountHolder);
            updateAccountHolderViewVM(newAccountHolder);
        }
    };
    const validProductsComponent = useCallback(() => {
        const productKey = 'productSelectedValidation';
        const productValidations = {
            key: productKey,
            type: 'error',
            reason: ''
        };
        const validations = dynamicNotifications.filter((msg) => msg.key !== productKey);
        if (_.isEmpty(productSelected)) {
            _.set(productValidations, 'reason', translator(currentMessages.productEmptyMessage));
            updateDynamicNotifications([...validations, productValidations]);
            return false;
        }
        updateDynamicNotifications(validations);
        return true;
    }, [dynamicNotifications, productSelected, translator]);
    const validRegisterComponent = useCallback(() => {
        // if (Object.keys(accountHolderViewVM).length === 0) { return false; }
        if (_.isEmpty(accountHolderViewVM)) {
            return false;
        }
        const {
            firstName,
            lastName,
            dateOfBirth,
            primaryPhoneType,
            homeNumber,
            cellNumber,
            workNumber,
            primaryAddress: {
                addressLine1,
                pobox_Ext: pobox,
                postalCode,
                county,
                city,
                state: addrState
            }
        } = accountHolderViewVM.value;
        const vaild = firstName && lastName && dateOfBirth && primaryPhoneType
                        && postalCode && county && city && addrState && (homeNumber || cellNumber || workNumber)
                        && accountHolderViewVM.aspects.subtreeValid;
        if (pobox) {
            return !!(vaild);
        }
        return !!(vaild && addressLine1);
    }, [accountHolderViewVM]);

    const onCreateNewAccount = async () => {
        if (!workflowType.ClassicalWorkflow) {
            if (!isComponentValid || !validProductsComponent()) {
                handleValidation();
                return false;
            }
        } else if (!isComponentValid) {
            handleValidation();
            return false;
        }
        const uiUtil = AddressVerifiedUtil({
            authHeader,
            addressVM: _.get(accountHolderViewVM, 'primaryAddress'),
            addressPath: 'primaryAddress',
            isAddressFlag,
            updateAddressFlag: updateIsAddressFlag,
            updateValidations: updateLookupValidation,
            writeValue: onAccountContactChange,
            //
            modalApi,
        });
        const accountState = _.get(accountHolderViewVM, 'primaryAddress.value.state')
        if (filterBlockerProductsMap) {
            const availableStatesList = getAvailableStateList()
            if(!(availableStatesList.includes(accountState) || availableStatesList.includes(baseState))&& productSelected.includes(PRODUCTS_TO_BLOCK)) {
                const policyStateValidationErrors = getPolicyStateValidationErrors();
                updateLookupValidation(policyStateValidationErrors);
                return
            }
        }
        const verifiedObj = await uiUtil.onVerified();

        if (!verifiedObj.isVerified) {
            return false;
        }

        const newAccount = generateAccountObj();

        const newReserVationData = await WniAccountService.getReservationAccounts(newAccount, authHeader);
        const matchAccounts = filterPolicyWithAgency(newReserVationData.matchAccounts);
        const poMatchAccounts = filterPolicyWithAgency(newReserVationData.poMatchAccounts);
        if (!_.isEmpty(matchAccounts) || !_.isEmpty(poMatchAccounts)) {
            newReserVationData.matchAccounts = matchAccounts;
            newReserVationData.poMatchAccounts = poMatchAccounts;
            updateShowAccountClearance(true);
            updateReservationData(newReserVationData);
        } else {
            createNewAccount(newAccount);
        }
        return true;
    };

    const getAccountHolderDetails = () => {
        const newAccountHolderDetails = _.clone(accountHolderDetails);
        const accountHolder = {
            contactName: '',
            primaryAddress: {},
        };

        const accountSearchCriteria = (state && state.accountSearchCriteria)
            || { contactType: 'person', country: defaultCountryCode };

        const criteriaContactType = accountSearchCriteria.contactType;
        newAccountHolderDetails.contactType = _.upperFirst(criteriaContactType);

        if (newAccountHolderDetails.contactType === 'Person') {
            accountHolder.firstName = accountSearchCriteria.firstName;
            accountHolder.lastName = accountSearchCriteria.lastName;
            if (accountSearchCriteria.lastNameKanji) {
                accountHolder.firstNameKanji = accountSearchCriteria.firstNameKanji;
                accountHolder.lastNameKanji = accountSearchCriteria.lastNameKanji;
            }
        } else {
            accountHolder.contactName = accountSearchCriteria.contactName;
            if (accountSearchCriteria.contactNameKanji) {
                accountHolder.contactNameKanji = accountSearchCriteria.contactNameKanji;
            }
        }
        accountHolder.primaryAddress.city = accountSearchCriteria.city;
        accountHolder.primaryAddress.state = accountSearchCriteria.state;
        accountHolder.primaryAddress.postalCode = accountSearchCriteria.postalCode;
        accountHolder.primaryAddress.country = accountSearchCriteria.country;
        if (accountSearchCriteria.cityKanji) {
            accountHolder.primaryAddress.cityKanji = accountSearchCriteria.cityKanji;
        }

        setAccountHolderViewVM(accountHolder, newAccountHolderDetails);

        accountSearchCriteria.contactName = '';
        accountSearchCriteria.lastName = '';
        accountSearchCriteria.firstName = '';
        accountSearchCriteria.city = '';
        accountSearchCriteria.state = '';
        accountSearchCriteria.postalCode = '';

        updateAccountHolderDetails(newAccountHolderDetails);
    };

    const onInit = async () => {
        const model = {
            country: defaultCountryCode,
        };
        const newSubmissionVM = viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.job.submission.dto.NewSubmissionDTO'
        );

        const newSysLocalDate = await WniSubmissionService.getSystemDate(authHeader);
        updateSysLocalDate(newSysLocalDate);

        if (state && !_.isEmpty(state.externalData)) {
            const exData = state.externalData;
            const agency = await getAgencyInfo(exData.producerCode);
            if (!_.isNil(agency)) {
                const agencyInfo = agency[0];
                const agencyDisplay = `${agencyInfo.code} - `
                // + `${agencyInfo.orgName_Ext ? `${agencyInfo.orgName_Ext}-` : null} `
                + `${agencyInfo.description ? `${agencyInfo.description}-` : null} `
                + `${agencyInfo.city_Ext ? agencyInfo.city_Ext : null}, `
                + `${agencyInfo.state_Ext ? agencyInfo.state_Ext : null}`;
                _.set(newSubmissionVM, 'producerCodePublicID_Ext', agencyInfo.publicID);
                _.set(newSubmissionVM.value, 'producerDisplay_Ext', agencyDisplay);
            }

            _.set(newSubmissionVM, 'state', exData.stateCode);
            _.set(newSubmissionVM, 'effectiveDate', WniDateUtil.parseDateExternal(exData.effectiveDate));
            _.set(newSubmissionVM, 'producerCode', exData.producerCode);
            _.set(newSubmissionVM, 'productCode', exData.productCode);
        } else {
            _.set(newSubmissionVM, 'effectiveDate', newSysLocalDate);
            _.set(newSubmissionVM, 'productCode', '');
        }

        updateSubmissionVM(newSubmissionVM);

        if (urlAccountNumber) {
            updateIsAnExistingAccount(true);
            await findAccount(urlAccountNumber, newSubmissionVM);
        } else {
            getAccountHolderDetails();
        }
        updateIsLoading(false);
    };

    const onCancelButton = () => {
        updateShowAccountClearance(false);
    };

    const onContinueCreate = () => {
        const selectedAoAgencyCode = _.get(submissionVM.value, 'producerCode');
        const selectedAoPublicId = _.get(submissionVM.value, 'producerCodePublicID_Ext');
        if (!_.isEmpty(selectedAoAgencyCode)) {
            /**
             * Get the producer code from AO path to generate the Account Object
             */
            const selectedAoAccount = generateAccountObjByAOCode(selectedAoAgencyCode, selectedAoPublicId);
            createNewAccount(selectedAoAccount);
        } else {
            const newAccount = generateAccountObj();
            createNewAccount(newAccount);
        }
    };

    useEffect(() => {
        onInit();
    }, []);

    useEffect(() => {
        registerComponentValidation(validRegisterComponent);
    }, [registerComponentValidation, validRegisterComponent]);

    // ========================================
    const render = () => {
        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showOptional: true,
                showErrors: showErrors
            },
            dynamicInlineNotificationContainer: {
                validationIssues: dynamicNotifications,
                visible: dynamicNotifications.length > 0,
                scrollToIssues: true,
            },
            newQuoteDetailsContainer: {
                visible: !isAnExistingAccount && !showAccountClearance,
            },
            // =========================
            // new submission page component
            policyDetailsContainer: {
                visible: isAnExistingAccount,
            },
            contactTypeDetails: {
                value: contactTypeValue
            },
            accountContactContainer: {
                model: accountHolderViewVM,
                updateAccountHolderViewVM,
                onAccountContactChange: onAccountContactChange,
                updateAddressSubType: updateSubTypeLabel,
                onOrganisationOptionsChange: updateOrganisationOptions,
                onProducerCodeOptionsChange: updateProducerCodeOptions,
                onContactTypeChange: updateContactTypeValue,
                showErrors,
                isHideContactType,
                onValidate: setComponentValidation,
                products: {
                    productSelected,
                    updateProduceSelected,
                    visible: true
                }
            },
            addressDetails: {
                value: _.get(newAccountCreate, 'displayAddressArray') ? `${_.get(newAccountCreate, 'newAccount.accountHolder.displayName')
                }\n${_.get(newAccountCreate, 'displayAddressArray')}` : `${_.get(accountHolderDetails, 'newAccount.accountHolder.displayName')
                }\n${_.get(accountHolderDetails, 'displayAddressArray')}`
            },
            accountNumberDetails: {
                value: _.get(submissionVM.value, 'accountNumber')
            },
            // ===================
            newSubmission: {
                visible: isAnExistingAccount,
                isAnExistingAccount: isAnExistingAccount,
                submissionVM: submissionVM,
                postalCode: _.get(newAccountCreate.newAccount, 'accountHolder.primaryAddress.postalCode')
                  || _.get(accountHolderDetails.newAccount, 'accountHolder.primaryAddress.postalCode'),
                updateSubmissionVM,
                producerCodeValue: authUserData && authUserData.userType === 'producer' ? producerCodeOptions : [],
                isAgent: authUserData && authUserData.userType === 'producer',
                allOrganisationValue: organisationOptions,
                authHeader: authHeader,
                sysLocalDate
            },

            // existing account match component
            existingAccountMatch: {
                isVisible: showAccountClearance,
                matchData: reserVationData,
                onStartNewQuote,
                // control show existingAccountMatchPage show or not
                updateExistingAccountMatch: updateShowAccountClearance,
                // existingAccountMatchPage continue button and Previous button
                onContinueCreate,
                onCancelButton
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                validationissuescomponent: ValidationIssuesComponent,
                accountcontactinput: AccountContactInputComponent,
                newsubmission: NewSubmissionPage,
                existingaccountpage: ExistingAccountMatchPage
            },
            resolveCallbackMap: {
                submitCreateAccountForm: onCreateNewAccount,
                onCancel: handleCancel,
                onCancelButton: onCancelButton,
                onContinueCreate: onContinueCreate
            },
        };
        if (isLoading) {
            return <Loader loaded={isLoading} />;
        }

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                accountHolderViewVM,
                id,
                path,
                overrideProps
            );
        };

        // ================
        if (_.isEmpty(accountHolderViewVM)) {
            return null;
        }
        return (
            <div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountHolderViewVM}
                    onModelChange={updateAccountHolderViewVM}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={setComponentValidation}
                    resolveValue={readValue}
                    showErrors={showErrors}
                />
            </div>
        );
    };

    //
    return render();
}

NewQuotePage.propTypes = {
    isHideContactType: PropTypes.bool,
};

NewQuotePage.defaultProps = {
    isHideContactType: undefined,
};

// export const NewQuoteComponent = NewQuotePage;
export default withViewModelService(NewQuotePage);
