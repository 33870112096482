
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import ValidationIssuesComponent from '../../ErrorsAndWarnings/ValidationIssuesComponent/ValidationIssuesComponent';
import message from '../EligibilityIssuesLinkComponent.messages';

function EligibilityIssuesPopup(props) {
    const {
        title,
        size,
        okBtnLabel,
        eligibilityIssues,
        isOpen,
        onResolve,
        translator
    } = props;
    const renderIssues = (issues) => {
        if (_.isEmpty(issues)) {
            return (null);
        }
        const validationIssues = issues.map((item) => {
            return ({
                type: 'error',
                reason: item
            });
        });
        const typeTitle = `${issues.length} ${translator(message.factor)}${issues.length > 1 ? 'S' : ''}`;
        return (
            <ValidationIssuesComponent
                validationIssues={validationIssues}
                typeTitleFormatter={typeTitle}
            />
        );
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <Button className="modal-close" onClick={onResolve}>
                <i className="fa fa-times"> </i>
            </Button>
            <ModalBody id="eligibilityIssuesList">
                {renderIssues(eligibilityIssues.map((eligibilityIssue) => eligibilityIssue.factor))}
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button onClick={onResolve} type="filled">{okBtnLabel}</Button>
                </div>
            </ModalFooter>
        </ModalNext>
    );
}

EligibilityIssuesPopup.propTypes = {
    title: PropTypes.string.isRequired,
    okBtnLabel: PropTypes.string.isRequired,
    eligibilityIssues: PropTypes.arrayOf(PropTypes.string),
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func.isRequired,
};

EligibilityIssuesPopup.defaultProps = {
    eligibilityIssues: [],
    isOpen: false,
    size: 'md'
};

export default EligibilityIssuesPopup;
