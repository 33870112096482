import { DropdownMenuLink,Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm,ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import moment from 'moment';
import React,{ useCallback,useContext,useEffect,useState } from 'react';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { CPLocationService } from 'wni-capability-quoteandbind-cp';
import { useWniModal } from 'wni-components-platform-react';
import { ServiceManager } from '@jutro/services';
import { getFieldModel } from 'wni-components-platform-react/components/FieldSetMode/FieldSetModel.util';
import {
ConfigUtil,QuoteUtil,
ValidationIssueUtil,
WindowUtil,
WniProductsUtil
} from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import WizardPage from '../../templates/CPWizardPage';
import CPLocationImportPopup from './Components/CPLocaitonImport/CPLocationImportPopup';
import CPLocationComponent from './Components/CPLocationComponent';
import messages from './CPLocationsPage.messages';
import metadata from './CPLocationsPage.metadata.json5';
import locationsUtil from './Util/CPLocationsUtil';

const { 
    CPP_PRODUCT_CODE
} = WniProductsUtil;

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};
const linePath = 'commercialProperty'
const COVERABLES_PATH = 'lobData.commercialProperty.coverables';
const unusedLocationsVMPath = `${COVERABLES_PATH}.unusedLocations`
const locationsVMPath = `${COVERABLES_PATH}.locations`;

const CPP_LOCATIONS_PATH = 'lobData.commercialPackage.coverables.locations';

function CPLocationsPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardSnapshot,
        updateWizardSnapshot,
        isReadOnly,
        wizardPageData,
        updateWizardPageData,
        resetWizardDataToSnapshot,
        isPolicyChange = false
    } = props;

    const {
        jobID, sessionUUID,
        baseData,
        baseData: {
            productCode
        } = {},
        lobData: {
            commercialProperty: {
                coverables: {
                    unusedLocations = [],
                    locations = []
                }
            }
        }
    } = submissionVM.value;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CPLocationsPage');
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [currentRow, updateCurrentRow] = useState(null);
    const [currentIndex, updateCurrentIndex] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const highlightRowFn = (index) => {
        WniTableRowUtil.setTablePublicIDSelected(`index${index}`, 'locationTable');
    };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    useEffect(() => {
        highlightRowFn(currentIndex);
    }, [currentIndex]);

    const updateSubmission = (currentVM) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${locationsVMPath}[${currentIndex}]`, currentVM.value);
        return newSubmissionVM;
    };

    const updateCurrentRowInteranl = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRow(rowData);
            return false;
        }
        updateCurrentRow(rowData);
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(rowData);
            updateSubmissionData(newSubmissionVM)
        }
    };

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${locationsVMPath}.children`,[]);
        const itemVM = childrenVM.find((vm) => vm.value.rowIdPath === index);
        const displayables = _.get(itemVM.value, 'displayables');
        const displayableValid = _.find(displayables, (displayable) => {
            const { inputSetMode } = displayable;
            const fieldModel = getFieldModel(inputSetMode);
            return _.isNil(displayable[fieldModel.value]) || _.isEmpty(displayable[fieldModel.value]);
        });
        let type;
        if (
            !_.get(itemVM, 'aspects.valid') ||
            !_.get(itemVM, 'aspects.subtreeValid') ||
            !_.isNil(displayableValid) ||
            _.isEmpty(displayables)
        ) {
            type = 'error';
            // } else if (hasIssuanceInvalidFields) {
            //     type = 'warning';
        } else {
            type = 'success';
        }
        const iconDom = (
            <Icon
                id={`validationIcon${item.publicID}`}
                icon={VALIDATION_ICON_MAP[type]}
                className={`wni-icon-${type}`}
            />
        );
        const locIndex = childrenVM.findIndex((vm) => vm.value?.rowIdPath === index);
        return WniTableRowUtil.renderCell(`index${locIndex}`, iconDom);
    };;

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentIndex);
        return DatatableUtil[sortType](a, b);
    };

    const renderAddressCell = (item) => {
        return locationsUtil.getPrimaryAddressDisplayName(item.address)
    }

    const syncWizardData = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardData);
    };
    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardSnapshot);
    };

    const updateSubmissionVMForResponse = useCallback((res) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${locationsVMPath}.value`, res.locations);
        _.set(newSubmissionVM, `${unusedLocationsVMPath}.value`, res.unusedLocations);
        if(productCode === CPP_PRODUCT_CODE && res.cppLocations) {
            _.set(newSubmissionVM, CPP_LOCATIONS_PATH, res.cppLocations)
        }
        updateWizardSnapshot(newSubmissionVM);
        return newSubmissionVM;
    },[submissionVM, updateWizardSnapshot, viewModelService, productCode]);

    const getUpdatedRiskTreeRows = (updatedSubmissionVM) => {
        const newSubmissionVM = viewModelService.clone(updatedSubmissionVM);
        const newLocations = _.get(newSubmissionVM, `${locationsVMPath}.value`, []).map(elt => {
            return {
                publicID: elt.publicID,
                description: elt.displayName,
                buildingTreeRows: [],
                coverableType: "CP7 Location",
                hasChildren: false,
                specialCLassTreeRows: []
            }
        })
        const riskTreeRows = _.get(newSubmissionVM, 'value.lobData.commercialProperty.riskTreeRows', []);

        const newLocationIDs = newLocations.map(elt => elt.publicID);
        const riskLocationIDs = riskTreeRows.map(elt => elt.publicID);
        // update locations should also update risk tree first location level
        riskTreeRows.forEach(loc => {
            const newLocation = newLocations.find(elt => elt.publicID === loc.publicID);
            if (!_.isNil(newLocation)) {
                Object.assign(loc, {'description': newLocation.description});
            }
        })

        const newAddedLocations = newLocations.filter(elt => !riskLocationIDs.includes(elt.publicID));
        if (!_.isEmpty(newAddedLocations)) {
            newAddedLocations?.forEach(loc => riskTreeRows.push(loc));
        }

        const removedLocationIDs = riskLocationIDs.filter(id => !newLocationIDs.includes(id));
        removedLocationIDs.forEach(elt => {
            const locationIDs = riskTreeRows.map(risk => risk.publicID);
            const toRemoveIndex = locationIDs.findIndex(locId => locId === elt);
            if (toRemoveIndex !== -1) {
                riskTreeRows.splice(toRemoveIndex, 1);
            }
            
        })

        return riskTreeRows
    }

    const updateSubmissionVMForRisks = useCallback((updatedSubmissionVM) => {
        const riskTreeRows = getUpdatedRiskTreeRows(updatedSubmissionVM);
        _.set(updatedSubmissionVM, 'value.lobData.commercialProperty.riskTreeRows', riskTreeRows);
        updateWizardSnapshot(updatedSubmissionVM);
        return updatedSubmissionVM;
    },[updateWizardSnapshot]);

    const viewOrEditLocation = async (item, index, newVM) => {
        if(!index) {
            return false;
        }
        const vm = newVM || submissionVM;
        setLoadingMask(true);
        const childrenVM = _.get(vm, `${locationsVMPath}.children`, []);
        const locationVM = childrenVM.find((itemVM) => itemVM.value.rowIdPath === index);
        const locIndex = childrenVM.findIndex((itemVM) => itemVM.value.rowIdPath === index);
        if(_.get(locationVM, 'publicID.value')) {
            try {
                const res = await CPLocationService.postOnChangeAction(
                    jobID,
                    sessionUUID,
                    locationVM.value,
                    authHeader
                );
                _.set(locationVM, 'value', res.location);
            } catch (e) {
                console.log(e)
            }
        }
        updateCurrentIndex(locIndex);
        updateCurrentRowInteranl(locationVM);
        setLoadingMask(false);
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
    
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const locationService = useCallback(async(serviceName, serviceData, isAdd) => {
        setLoadingMask(true);
        const locationData = _.get(submissionVM, `${locationsVMPath}.value`);
        const oldLocations = _.cloneDeep(locationData) || [];
        const oldLocationIds = oldLocations.map((v) => v.publicID);
        const res = await CPLocationService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        updateSelection([]);
        updateShowErrors(false);
        generateValidationIssues(res.errorsAndWarnings);
    
        updateCurrentRowInteranl(null);
        setLoadingMask(false);
         // for add new vehicle
         if(isAdd) {
            // if find new vehicle, this is add new data
            const newLocationVM = _.get(newSubmissionVM, `${locationsVMPath}.children`).find((vm) => !oldLocationIds.includes(vm.value.publicID));
            updateCurrentRowInteranl(newLocationVM);
            return false;
        }
        return newSubmissionVM
    }, [authHeader, jobID, sessionUUID, setLoadingMask, submissionVM]);

    const addLocation = async () => {
        const locationsVM = _.get(submissionVM, `${locationsVMPath}`);
        const { _dtoName, _xCenter } = locationsVM;

        const newLocationVMValue = viewModelService.create(
            { rowIdPath: ConfigUtil.getUuid(),  address: {country: defaultCountryCode} },
            _xCenter,
            _dtoName
        );
        const existingLocations = _.get(locationsVM, `value`, []);
       
        existingLocations.push(newLocationVMValue.value);
        _.set(submissionVM, `${locationsVMPath}.value`, existingLocations);
        const locIndex = existingLocations.length - 1;
        updateCurrentIndex(locIndex);
        updateWizardData(submissionVM);
        updateCurrentRowInteranl(newLocationVMValue);
        // await locationService('updateLocation', {}, true)
    };

    const addExistingLocation = async(existingLocations, isAll = false) => {
        setLoadingMask(true);
        const allLocations = _.get(submissionVM, `${locationsVMPath}.value`);
        const oldLocations = _.cloneDeep(allLocations) || [];
        const oldLocationIds = oldLocations.map((v) => v.publicID); // get old locations publicIds

        const existingLocationPublicIds = existingLocations.map((item) => item.publicID);
        const res = await CPLocationService.addExistingLocations(jobID, existingLocationPublicIds, sessionUUID, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        setLoadingMask(false);
        if(!isAll) {
            // if add one existing location, will open this added location details
            const locationsVM = _.get(newSubmissionVM, `${locationsVMPath}.children`, []);
            const locIndex = locationsVM.findIndex((vm) => !oldLocationIds.includes(vm.value.publicID));
            const newLocationVM = locationsVM[locIndex];
            updateCurrentRowInteranl(newLocationVM);
            updateCurrentIndex(locIndex);
        }
    };

    const addAllExistingLocation = async () => {
        addExistingLocation(unusedLocations, 'isAll')
    }

    const cancelLocation = async() => {
        updateCurrentIndex(null);
        syncWizardData(null);
        resetWizardDataToSnapshot();
        if(productCode === CPP_PRODUCT_CODE) {
            const res = await CPLocationService.fetchLocations(
                jobID,
                sessionUUID,
                authHeader
            );
            updateSubmissionVMForResponse(res);
            
        }
        
    }

    const updateLocation = async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        const newSubmissionVm = await locationService('updateLocation', currentRow.value);
        return newSubmissionVm;
    };

    const removeData = async() => {
        modalApi.showConfirm({
                title: messages.removeLocationTitle,
                message: messages.removeLocationDescription,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.Ok,
                cancelButtonText: messages.Cancel,
            }).then(async (result) => {
                if (result === 'cancel' || result === 'close') {
                    return _.noop();
                }
                updateCurrentIndex(null);
                syncWizardData(null);
                await locationService('removeLocations', selection);
            });
    };
    const removeLocations = async () => {
        await removeData();
        
    };

    const onNextLocation = useCallback(async (newmVM) => {
        const childrenVM = _.get(newmVM, `${locationsVMPath}.children`);
        let index = _.findIndex(
            childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID
        );
        if (index === childrenVM.length - 1) {
            index = 0;
        } else {
            index += 1;
        }
        const indexID = _.get(childrenVM[index], 'value.publicID');
        syncWizardData(null);
        await viewOrEditLocation(null, indexID, newmVM);
        updateCurrentIndex(index);
        WindowUtil.scrollToTop();
    }, [currentRow, submissionVM, viewOrEditLocation]);

    const allLocationValid = () => {
        const allLocationVMs = _.get(
            submissionVM,
            `${locationsVMPath}.children`
        );
        return allLocationVMs.every((vm) => {
            const displayables = _.get(vm.value, 'displayables', []);
            const displayableValid = _.find(displayables, (displayable) => {
                const { inputSetMode } = displayable;
                const fieldModel = getFieldModel(inputSetMode);
                return (
                    _.isNil(displayable[fieldModel.value]) ||
                    _.isEmpty(displayable[fieldModel.value])
                );
            });
            return (
                vm.aspects.valid &&
                vm.aspects.subtreeValid &&
                !_.isEmpty(displayables) &&
                _.isNil(displayableValid)
            );
        });
    };

    const onPageNext = useCallback(async () => {
        if(!allLocationValid()) {
            return false;
        }
        if (isReadOnly) {
            return submissionVM;
        }
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await CPLocationService.onPageNext(requestData, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        const newUpdatedSubmissionVM = updateSubmissionVMForRisks(newSubmissionVM);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return newUpdatedSubmissionVM;
    }, [authHeader, submissionVM]);

    const disableDeleteBtn = () => {
        if(_.isEmpty(selection)) {
            return true
        } 
        // find if selection has primary location
        const primaryLocation = _.get(submissionVM, `${locationsVMPath}.value`, []).find((item) => item.isPrimary);
        const primaryLocationID = _.get(primaryLocation, 'rowIdPath');
        if(selection.includes(primaryLocationID)) {
            return true
        } 
        return false
    }

    const renderAllUnusedLocationsMenuItem = () => {
        const retval = _.filter(unusedLocations, (elt) => {
            return !_.isUndefined(elt.address.addressLine1);
        }).map((item) => {
            const address = _.get(item, 'address');
            const { publicID, displayName } = address;
            return (
                <DropdownMenuLink
                    type="action"
                    onClick={() => addExistingLocation([item])}
                    key={`${publicID}-MenuLink`}
                >
                    {displayName}
                </DropdownMenuLink>
            );
        });

        return retval;
    };
    const writeValue = (value, path) => {
        if (currentRow) {
            const newValue = _.clone(value);
            _.set(currentRow.value, path, newValue);
            syncWizardData(currentRow);
        }
    };

    const importLocation = useCallback(() => {
        const componentProps = {
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            extendProps: {
                jobID,
                sessionUUID,
                authHeader,
            },
        };
        modalApi
            .showModal(<CPLocationImportPopup {...componentProps} />)
            .then((result) => {
                updateSubmissionVMForResponse(result);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });
    }, [
        authHeader,
        jobID,
        modalApi,
        sessionUUID,
        translator,
        updateSubmissionVMForResponse,
    ]);

    const displayErrorModal = useCallback(() => {
        modalApi.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, [modalApi]);

    const exportLocation = useCallback(() => {
        const date = moment(new Date()).format('YYYYMMDD-hhMM');
        const serviceUrl = getProxiedServiceUrl('cpDownloadService').concat(`/${jobID}?jobID=${jobID}`);
        const fileName =`Submission_${jobID}_CP7_Locations_${date}`;
        // Submission_1061153772_CP7_Locations_20240729-0145
        return WniDocumentRetrievalService.generateDocument(serviceUrl, fileName, authHeader, _.noop, displayErrorModal)
    }, [authHeader, displayErrorModal, jobID]);
    
    //---------------------
    const overrideProps = {
        '@all': {
            isReadOnly
        },
        '@field': {
           labelPosition: 'left',
        },
        importLocation: {
            visible: !isReadOnly
        },
        exportLocation: {
            visible: !isReadOnly
        },
        removeLocation: {
            visible: !isReadOnly,
            disabled: disableDeleteBtn()
        },
        addLocation: {
            visible: !isReadOnly
        },
        addExistingLocation: {
            visible: !isReadOnly,
            disabled: unusedLocations.length === 0,
            content: renderAllUnusedLocationsMenuItem(),
        },
        addAllExistingLocation: {
            visible: !isReadOnly,
            disabled: unusedLocations.length === 0,
        },
        locationTable: {
            onSelectionChange: (rows) => updateSelection(rows)
        },
        locationDetailContainer: {
            visible: !!currentRow,
        },
        viewOrEditLink: {
            label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel
        },
        locationDetails: {
            visible: !!currentRow,
            locationVM: currentRow,
            submissionVM,
            updateWizardData,
            baseData,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            updateValidationIssues,
            generateValidationIssues,
            onValidate,
            showErrors,
            isReadOnly,
            externalData: {
                jobID,
                sessionUUID,
                authHeader
            }
        },
        saveButtons: {
            visible: !isReadOnly
        },
        saveNextButton: {
            visible: locations.length > 1
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            addLocation,
            addAllExistingLocation,
            removeLocations,
            viewOrEditLocation,
            cancelLocation,
            saveLocation: () => {
                updateLocation().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNextLocation: () => {
                updateLocation().then((vm) => {
                    if (vm) {
                        onNextLocation(vm);
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderValidationCell,
            renderPrimaryCell: (item) => _.get(item, `isPrimary`) ? 'X' : '-', 
            renderAddressCell,
            importLocation,
            exportLocation
        },
        resolveComponentMap: {
            locationcomponent: CPLocationComponent
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
            showRequiredInfoForFasterQuote
            disableNext={!allLocationValid()}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}


export default CPLocationsPage;