import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalBody,
    ModalFooter,
    InputField, 
    ToggleField
} from '@jutro/components';
import { Flex } from '@jutro/layout';
import styles from './CoverageContactSearchPopup.module.scss';

function CoverageContactSearchPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        existingContactcandidates
    } = props;

    const [contactType, updateContactType] = useState("person");
    const [firstName, updateFirstName] = useState(null);
    const [lastName, updateLastName] = useState(null);
    const [companyName, updateCompanyName] = useState(null);
    const [searchResults, updateSearchResults] = useState([]);
    const [isSearched, updateIsSearched] = useState(false);
    const handleContactTypeChange = (type) => {
        updateContactType(type)
    }

    const getAvailableOptions = (availableContacts) => {
        return availableContacts.map((item) => {
            const primaryAddressDisplayName = _.get(item, 'primaryAddress.displayName')
            return {
                id: item.publicID,
                displayName: `${item.displayName} ${primaryAddressDisplayName}`,
                firstName: item.firstName,
                lastName: item.lastName,
                companyName: item.contactName
            }
        })
    }

    const handleSearchContacts = useCallback(() => {
        if (contactType === 'company') {
            const availableContacts = existingContactcandidates.filter((item) => {
                if (item.contactType_Ext === "company") {
                    return true
                } 
                return false
            })
            const availableCompanies = getAvailableOptions(availableContacts);
            const results = availableCompanies.filter(item => {
                if (item.companyName.toLowerCase() === _.trim(companyName).toLowerCase()) {
                    return true
                }
                return false
            })
            updateSearchResults(results);
        }
        if (contactType === 'person') {
            const availableContacts = existingContactcandidates.filter((item) => {
                if (item.contactType_Ext === "person") {
                    return true
                } 
                return false
            })
            const availablePeople = getAvailableOptions(availableContacts);
            const results = availablePeople.filter(item => {
                if ((item.firstName.toLowerCase() === _.trim(firstName).toLowerCase()) || (item.lastName.toLowerCase() === _.trim(lastName).toLowerCase())) {
                    return true
                }
                return false
            })
            updateSearchResults(results);
        }
        if (!isSearched) {
            updateIsSearched(true);
        }
    },[companyName, contactType, existingContactcandidates, firstName, isSearched, lastName])

    useEffect(() => {
        updateSearchResults([]);
        updateCompanyName(null);
        updateFirstName(null);
        updateLastName(null);
        updateIsSearched(false);
    }, [contactType])

    const handleSelectContact = (ID) => {
        const policyContact = existingContactcandidates.find((contact) => contact.publicID === ID);
        onResolve(policyContact);
    }

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="coverageContactSearchComponent" autoFocus={false}>
                <h3>Contact Search</h3>
                <hr/>
                <ToggleField
                    availableValues={[{code: 'person', name: 'Person'},{code: 'company',name: 'Company'}]}
                    value={contactType}
                    id="toggle-button"
                    onValueChange={handleContactTypeChange}
                />
                {contactType === "person" && 
                <>
                    <InputField
                        id="FirstName"
                        label="FirstName"
                        labelPosition="left"
                        value={firstName}
                        onValueChange={(value) => updateFirstName(value)}
                    />
                    <InputField
                        id="LastName"
                        label="LastName"
                        labelPosition="left"
                        value={lastName}
                        onValueChange={(value) => updateLastName(value)}
                    />
                </>
                }
                {contactType === "company" && 
                    <InputField
                        id="Company Name"
                        label="Company Name"
                        labelPosition="left"
                        value={companyName}
                        onValueChange={(value) => updateCompanyName(value)}
                    />
                }
                <Flex justifyContent="right"><Button onClick={handleSearchContacts} className={styles.searchBtn}>Search</Button></Flex>
                <hr/>
                <ul id="results" className={styles.noDecorator}/>
                {
                    isSearched && _.isEmpty(searchResults) ? <Flex justifyContent="center"><h4>No Results</h4></Flex>: searchResults.map(item => {
                        return <li className={styles.resultLi} key={item.id}>
                            <Button className={styles.selectBtn} onClick={() => {handleSelectContact(item.id)}}>Select</Button>
                            <span className={styles.resultItem}>{item.displayName}</span>
                            </li>
                    })
                }
            </ModalBody>
            <ModalFooter contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: "middle",
                        justifyContent: "between",
                        wrap: 'reverse',
                        gap: 'medium'
                    }
                }}>
                <Button onClick={()=>{onReject()}} type="outlined">Close</Button>
                {isSearched && contactType === "person" && 
                    <Button onClick={()=>{onReject('person')}}>Create New Person</Button>
                }
                {isSearched && contactType === "company" && 
                    <Button onClick={()=>{onReject('company')}}>Create New Company</Button>
                }
            </ModalFooter>
        </ModalNext>
    );
}
CoverageContactSearchPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
};

CoverageContactSearchPopup.defaultTypes = {
    size: 'lg',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
};

export default CoverageContactSearchPopup;
