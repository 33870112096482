import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';

import GLRiskAnalysisPage from './GLRiskAnalysisPage';


function GLRiskAnalysisReadOnlyPage(props) {

    return <GLRiskAnalysisPage {...props} isReadOnly />
}


export default GLRiskAnalysisReadOnlyPage;