import React, {
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
    ServiceErrorUtil,
    ValidationIssueUtil,
    UWIssueUtil,
    WindowUtil,
    IssuanceValidationUtil,
    PolicyChangeQuoteUtil,
    DTOUtil,
    ActiveQuoteUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';
import {
    ModalDialogHelper,
} from 'wni-portals-util-react';
import { ValidationIssuesComponent, QuoteUnderwritingIssuesList, useWniModal } from 'wni-components-platform-react';
import { WniCommonChangeService } from 'wni-capability-policychange';
import { PolicyDiffService } from 'gw-capability-policyjob';
import {
    SideBySidePeriodsComponent,
    ReferToUnderwriterComponent,
    ChangeSummaryComponent,
    TextEditConfirmComponent,
} from 'wni-capability-gateway-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    PolicyChangeCommonMessages as currentMessages,
    QuoteCommonMessages,
    QuoteSideBySidePeriodMessages,
    BRCLMessages,
} from 'wni-platform-translations';

import { CPMultiQuoteService } from 'wni-capability-quoteandbind-cp';
import { CPMultiQuoteChangeService } from 'wni-capability-policychange-cp';
import {
    CPWizardPage as WizardPage,
    CPQuoteIssueRenderHelper,
    CPQuotePageConstants,
} from 'wni-capability-quoteandbind-cp-react';

import CPPolicyChangeQuoteComponent from './components/CPPolicyChangeQuoteComponent';
import CPAddNewChangeVersionComponent from './components/AddNewVersion/CPAddNewChangeVersionComponent';

import styles from './CPQuoteChangePage.module.scss';
import metadata from './CPQuoteChangePage.metadata.json5';

const {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
} = CPQuoteIssueRenderHelper; // ValidationIssueRenderUtil;

const {
    SXS_LOB_NAME,
    //
    BRANCH_NAME_PATH,
} = CPQuotePageConstants;

function CPQuoteChangePage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        wizardStepToFieldMapping,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        updateWizardReadOnly,
        wizardPageData,
        updateWizardPageData,
    } = props;
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);

    const { isLoggedIn, authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CPQuoteChangePage');

    
    const {
        jobID,
        sessionUUID,
        baseData: {
            effectiveDate_Ext: effectiveDate,
            periodStartDate,
            periodEndDate,
            selectedVersion_Ext: selectedVersion,
            periodStatus: jobStatus,
        },
        // lobData: {
        // },
        changeData: {
            totalCost,
            totalCost: {
                amount: totalCostAmount,
            } = {},
            transactionCost = {},
            transactionCost: {
                amount: transactionCostAmount,
            } = {},
            previousTotalCost,
            isBillingOptionsEditable,
        },
        // errorsAndWarnings: quoteErrorsAndWarnings,
    } = submissionVM.value;

    const {
        [WizardConstants.agentStatus]: cachedAgentStatus,
        [WizardConstants.policyDiffData]: cachedPolicyDiffData,
        [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
        [WizardConstants.sideBySideData]: cachedSxsDataDTO,
    } = wizardPageData;

    const [showErrors, updateShowErrors] = useState(false);

    const [allDrivers, updateAllDrivers] = useState();
    const [allVehicles, updateAllVehicles] = useState();
    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    // const [isShowMulti, updateShowMulti] = useState(false);
    const [hasBlockingIssue, updateHasBlockingIssue] = useState(false);
    const [policyDiffData, updatePolicyDiffData] = useState([]);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);


    const isAgentInfoInitialized = !_.isNil(cachedAgentStatus);

    const modalHelper = ModalDialogHelper(modalApi);

    const updatePolicyDifferences = useCallback(async () => {
        let newPolicyDiffData = cachedPolicyDiffData;
        if ( !newPolicyDiffData ) {
            newPolicyDiffData = await PolicyDiffService.getPolicyDiffWithPrevious(jobID, authHeader);
        }

        updatePolicyDiffData(newPolicyDiffData);

        // if (!quoteErrorsAndWarnings) { // why is there a default value []?
        // if (_.isEmpty(quoteErrorsAndWarnings)) {
        //     const newErrorsAndWarnings = await CPMultiQuoteChangeService.getValidationAndUWIssues(jobID, sessionUUID, authHeader);
        //     // updateErrorsAndWarnings(newErrorsAndWarnings);
        //     _.set(submissionVM.value, 'errorsAndWarnings', newErrorsAndWarnings);
        //     updateWizardData(submissionVM);
        // }
        // return { [WizardConstants.policyDiffData]: newPolicyDiffData };
        return newPolicyDiffData;
    }, []);

    const withServiceInProgressUpdated = useCallback(async (cb) => {
        updateServiceCallInProgress(true);
        try {
            await cb();
        } catch (e) {
            const errorMessage = ServiceErrorUtil.getErrorMessage(e);
            modalHelper.alert({ errorMessage });
        } finally{
            updateServiceCallInProgress(false);
            setLoadingMask(false);
        }
    }, [modalHelper]);

    const handlePrint = () => {
        window.print();
    };

    const updateWizardData = useCallback((newSubmissionVM) => {
        const newVM = viewModelService.clone(newSubmissionVM);
        updateWizardSnapshot(newVM);
    }, [updateSubmissionVM]);

    const updateSideBySideData = useCallback(async (optConfigs = {}) => {
        const {
            quoteErrorsAndWarnings,
            sxsDataRetriever = CPMultiQuoteChangeService.retrieveSxsData,
            extraWizardPageData = {},
            // mockupBranchNames,
        } = optConfigs;
        // =========================================
        const sideBySideData = await sxsDataRetriever(jobID, sessionUUID, authHeader);
        // -----------------------------------------

        let updatedErrorsWarnings = quoteErrorsAndWarnings;
        // In case quoteErrorsAndWarnings are not provided (e.g. after withdrawBranch),
        // try to retrieve errorsAndWarnings from sxsDataDTO
        if (_.isEmpty(updatedErrorsWarnings) && sideBySideData[SXS_LOB_NAME]) {
            updatedErrorsWarnings = _.get(sideBySideData, 'errorsAndWarnings');
        }
        // updatedErrorsWarnings = quoteErrorsAndWarnings;
        updateErrorsAndWarnings(updatedErrorsWarnings);

        //----------------------------------------------------------------------
        if (ErrorsAndWarningsUtil.hasValidationError(updatedErrorsWarnings)) {
            WindowUtil.scrollTo('policyChangeErrorsAndWarnings');
        }
        //----------------------------------------------------------------------

        if (_.isEmpty(sideBySideData)) {
            return;
        }

        
        const {
            [SXS_LOB_NAME]: lobSxsDataDTO,
            accountJobs,
        } = sideBySideData;

        if (lobSxsDataDTO) {
            updateSxsDataDTO(sideBySideData);

            const newAccountActiveQuotes = accountJobs || accountActiveQuotes;
            // record the most recent SideBySideData; to be used by PAQuotePage.readonly;
            updateWizardPageData({
                [WizardConstants.sideBySideData]: sideBySideData,
                [WizardConstants.accountActiveQuotes]: newAccountActiveQuotes,
                ...extraWizardPageData,
            });
        }
    }, [accountActiveQuotes, authHeader, jobID, sessionUUID, updateWizardPageData]);

    const updateSideBySideDataWithActiveQuotes = useCallback(async (optConfigs) => {
        await updateSideBySideData({
            sxsDataRetriever: CPMultiQuoteChangeService.retrieveSxsDataWithActiveQuotes,
            ...optConfigs,
        });
    }, [updateSideBySideData]);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    useEffect(() => {
        // if (isBillingOptionsEditable) {
        //     turnOnWizardPage('CPPaymentPage');
        // }
        WindowUtil.scrollToTop();
        
        // WARNING: this returns [] when errorsAndWarnings is undefined in the service response. 
        // Looks like it's a viewModel error (or should I say, feature?)
        const quoteErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');
        withServiceInProgressUpdated(async () => {
            // if (cachedPolicyDiffData) {
            //     return;
            // }

            const newPolicyDiffData = await updatePolicyDifferences();
            
            const updateSideBySideDataParams = {
                quoteErrorsAndWarnings,
                extraWizardPageData: { 
                    [WizardConstants.policyDiffData]: newPolicyDiffData,
                },
            };
            if (cachedSxsDataDTO) {
                updateSideBySideDataParams.sxsDataRetriever = () => cachedSxsDataDTO;
            }
            await updateSideBySideDataWithActiveQuotes(updateSideBySideDataParams);

        });
        WindowUtil.scrollToTop();
    }, [submissionVM.value]);

    const onReferToUnderwriter = useCallback(async (noteForUW) => {
        withServiceInProgressUpdated(async () => {
            const jobResponse = await WniCommonChangeService.referToUnderwriter(jobID, sessionUUID,
                noteForUW, authHeader);

            // const serverErrors = _.get(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
            const referToUWIssues = ErrorsAndWarningsUtil.extractServerErrors(jobResponse);
            if (!_.isEmpty(referToUWIssues)) {
                updateExtraBlockingSubmitIssues(referToUWIssues);
                WindowUtil.scrollTo('policyChangeErrorsAndWarnings');
            } else if (!_.isEmpty(jobResponse)) {
                // history.push(`/change/${jobID}/summary`);
                updateWizardReadOnly(true);
            }
        });
    }, [authHeader, jobID, sessionUUID, updateWizardReadOnly,
        withServiceInProgressUpdated]);


    // =======================================
    const onSwitchToVersion = useCallback(async (periodPublicID) => {
        withServiceInProgressUpdated(async() => {
            if (selectedVersion === periodPublicID) {
                return;
            }

            submissionVM.value = await WniCommonChangeService.updateCurrentBranchAndRefreshWizardData(jobID,
                sessionUUID, periodPublicID, authHeader);
            // await updateSideBySideData();
            await updateSideBySideDataWithActiveQuotes();
            updateWizardData(submissionVM);
        });
    }, [submissionVM, selectedVersion]);

    const onCreateNewVersion = useCallback(async (createService) => {
        await withServiceInProgressUpdated(async () => {
            submissionVM.value = await createService(jobID, sessionUUID, authHeader);
            await updateSideBySideDataWithActiveQuotes({
                extraWizardPageData: {
                    [WizardConstants.policyChangeNewVersionAdded]: true,
                },
            });
            updateWizardData(submissionVM);
        });
        // go to first page after new version (Draft) is added
        wizardJumpTo(0);
    }, [submissionVM]);

    const onUpdateBranchName = useCallback(async (newBranchName) => {
        withServiceInProgressUpdated(async () => {
            await CPMultiQuoteService.updateBranchName(jobID, sessionUUID, newBranchName, authHeader);
            // await updateSideBySideData();
            
            await updateSideBySideDataWithActiveQuotes();
            // const activeQuotes = await WniCommonChangeService.getActiveQuotes(jobID, sessionUUID, authHeader);
            // updateWizardPageData({
            //     [WizardConstants.accountActiveQuotes]: activeQuotes,
            // });

            //
            // _.set(submissionVM.value, BRANCH_NAME_PATH, newBranchName);
            // updateWizardData(submissionVM);
        });
    }, [submissionVM]);

    const onWithdrawBranch = useCallback(async () => {
        const confirmWithDrawn = await modalApi.showConfirm({
            title: QuoteCommonMessages.deleteThisQuoteVersion,
            message: QuoteCommonMessages.deleteThisQuoteVersionConfirmMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: QuoteCommonMessages.deleteThisQuoteVersionConfirmConfirm,
        }).catch(_.noop);
        if (confirmWithDrawn !== 'confirm') {
            return;
        }
        withServiceInProgressUpdated(async () => {
            const response = await CPMultiQuoteChangeService.withdrawBranch(jobID, sessionUUID, selectedVersion, authHeader);
            submissionVM.value = response;

            await updateSideBySideDataWithActiveQuotes();

            updateWizardData(submissionVM);
        });
    }, [submissionVM, selectedVersion]);


    const onNext = useCallback(async () => {
        if (isBillingOptionsEditable) {
            return submissionVM;
        }

        // submissionVM.value = await HOMultiQuoteService.processQuote(
        // submissionVM.value = await CPMultiQuoteChangeService.processIssuance(
        const issuanceResponse = await CPMultiQuoteChangeService.processIssuanceWithoutWarning(
            jobID, sessionUUID, authHeader
        );
        submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, issuanceResponse);

        const {
            errorsAndWarnings: issuanceErrorsAndWarnings,
        } = submissionVM.value;
        const hasValidationError = ValidationIssueUtil.hasValidationError(issuanceErrorsAndWarnings);
        const hasBlockingUWIssue = UWIssueUtil.hasBlockingOrRejectedUWIssue(issuanceErrorsAndWarnings);
        if (hasValidationError || hasBlockingUWIssue) {
            WindowUtil.scrollTo('policyChangeErrorsAndWarnings');
            
            updateWizardData(submissionVM);
            return false;
        }

        return submissionVM;
    }, [isBillingOptionsEditable]);


    // ================================================
    const generateOverrides = useCallback(() => {
        const cpSxsDataDTO = _.get(sxsDataDTO, SXS_LOB_NAME);
        
        const cpSxsPeriods = _.get(cpSxsDataDTO, 'periods', []);
        const selectedSxsPeriod = QuoteUtil.getSideBySidePeriod(cpSxsPeriods, selectedVersion);
        
        const effectiveDateFormat = WniDateUtil.formatDateWithPattern(effectiveDate);
        const startPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodStartDate);
        const endPeriodDateFormat = WniDateUtil.formatDateWithPattern(periodEndDate);

        const newErrorsAndWarnings = errorsAndWarnings;
        const validationIssues = ErrorsAndWarningsUtil.getValidationIssues(newErrorsAndWarnings,
            [
                // ...oosErrorsAndWarnings,
                ...extraBlockingSubmitIssues
            ]);

        const issueJumpFnMap = ErrorsAndWarningsUtil.getValidationIssueJumpFnMap(validationIssues,
            wizardSteps, wizardStepToFieldMapping, wizardJumpTo);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        const hasValidationError = ErrorsAndWarningsUtil.hasValidationError(newErrorsAndWarnings);

        // const underwritingIssues = _.get(newErrorsAndWarnings, 'underwritingIssues', []);
        const underwritingIssues = ErrorsAndWarningsUtil.filterUWIssuesBasedOnPeriod(
            _.get(newErrorsAndWarnings, 'underwritingIssues', []), selectedVersion
        );
        // const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue(newErrorsAndWarnings);
        const hasBlockUWIssue = ErrorsAndWarningsUtil.hasBlockingOrRejectedUWIssue({ underwritingIssues });

        updateHasBlockingIssue(hasBlockUWIssue || hasValidationError);

        const changeUtil = PolicyChangeQuoteUtil({
            intl, translator, totalCost, transactionCost, currentMessages
        });
        const premiumDiffMsg = changeUtil.getPremiumDiffMessage();
        const premiumDiffTitle = changeUtil.getPremiumDiffTitle();

        const branchName = _.get(selectedSxsPeriod, BRANCH_NAME_PATH);
        const hasMultiplePeriods = ActiveQuoteUtil.hasMultiplePeriodsForJob(accountActiveQuotes, jobID);
        const accountJobPeriodDTOList = ActiveQuoteUtil.filterAccountJobPeriodDTOList(accountActiveQuotes, jobID, selectedVersion);

        return {
            '@field': {
                labelPosition: 'left'
            },
            errorsAndWarningsSection: {
                validationIssues: IssuanceValidationUtil.getIssuesMap(validationIssues),
                issueJumpFnMap,
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator, (issueType) => {
                    return issueType === 'error' ? currentMessages.submitTheChange : undefined;
                }),
                typeFooterFormatter: getQuotePageErrorsAndWarningsFooter({
                    wizardSteps,
                    wizardStepToFieldMapping,
                    wizardJumpTo,
                    resolveButtonLabel: translator(currentMessages.resolveErrors),
                    isServiceCallInProgress,
                }),
                issueRenderFn: getQuotePageIssueRenderFn,
                scrollToIssues: true,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
                // Refactoring Notes: should retrieve validation result for current slice only
                issueDistinguisher: true,
            },
            quoteUWIssues: {
                underwritingIssues,
                // headerObject: currentMessages.IssuesRequiringReferral,
                // issueFilter: _.stubTrue, // show Info Level UWI along with Blocking Level
                issueFilter: UWIssueUtil.isUwIssuePendingApproval,
            },
            referToUWBtn: {
                onReferToUnderwriter,
                isServiceCallInProgress,
                visible: hasBlockUWIssue,
                // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            },
            addNewVersionBtn: {
                sxsDataDTO: cpSxsDataDTO,
                onCreateNewVersion,
                //
                accountJobPeriodDTOList,
                onSwitchToBranch: onSwitchToVersion,
            },
            branchInfoEditSeciton: {
                visible: hasMultiplePeriods,
            },
            branchNameEdit: {
                // visible: hasMultiplePeriods,
                text: branchName,
                editButtonLabel: QuoteSideBySidePeriodMessages.editQuoteVersionName,
            },
            // deleteQuoteVersionButton: {
            //     visible: hasMultiplePeriods,
            // },
            printBtn: {
                disabled: isServiceCallInProgress,
            },
            // printBtnV2: {
            //     disabled: isServiceCallInProgress,
            // },
            // drivers: {
            //     value: allDrivers
            // },
            // vehicles: {
            //     value: allVehicles
            // },
            // =====================
            policyChangeSummary: {
                validationIssues: premiumDiffMsg,
                typeTitleFormatter: premiumDiffTitle,
                previousTotalCost,
                transactionCost,
                totalCost,
                effectiveDateFormat,
                periodDateRange: `${startPeriodDateFormat} ~ ${endPeriodDateFormat}`,
                // transactionCostAmount,
                showPremiumChange: PortalConstants.isQuoted(jobStatus), // jobStatus === 'Quoted',
            },
            // // ==========================
            PAHeadId: {
                // visible: !isShowMulti
            },
            policyChangeDetailId: {
                // visible: !isShowMulti
            },
            paPolicyDiff: {
                policyDiffData,
                isServiceCallInProgress,
                // vehicles,
            },
            // multiVersionsId: {
            //     visible: isShowMulti
            // },
            // multiVersionBtn: {
            //     // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            //     visible: false,
            //     content: renderHelper.getButtonContent(translator(currentMessages.MultiVersions), isServiceCallInProgress),
            // },
            // backToDetailBtn: {
            //     disabled: isServiceCallInProgress,
            // },
            // addNewBtn: {
            //     // disabled: true,
            //     visible: PolicyChangeUtil.canAddSideBySideChangePeriod(_.get(sxsDataDTO, 'personalAuto.periods')),
            //     // renderContent: renderHelper.getButtonRenderFunc(isServiceCallInProgress),
            //     content: renderHelper.getButtonContent(translator(currentMessages.addNewVersion), isServiceCallInProgress),
            // },
            // sxsPeriodsInfoSection: {
            //     columnData,
            //     isServiceCallInProgress,
            //     showActionButtons: false,
            //     sxsPeriodInfo: PolicyChangeSideBySidePeriodInfo,
            //     readyForSxsRemovalCheckFn: PolicyChangeUtil.canRemoveSideBySideChangePeriod,
            // },
            // quoteTable: {
            //     columnData,
            //     tableData,
            //     // isEditable: false,
            //     onValidate,
            //     showErrors,
            // },
        };
    },
    [effectiveDate, periodStartDate, periodEndDate, errorsAndWarnings, extraBlockingSubmitIssues,
        wizardSteps, wizardStepToFieldMapping, wizardJumpTo, intl, translator, totalCost,
        transactionCost, sxsDataDTO, SXS_LOB_NAME, accountActiveQuotes, jobID, selectedVersion,
        isServiceCallInProgress, onReferToUnderwriter, onCreateNewVersion, onSwitchToVersion,
        previousTotalCost, jobStatus, policyDiffData]);

    const renderQuotePage = useCallback(({ onNext: onNextFunc }) => {
        const overrideProps = generateOverrides(onNextFunc);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                // // onStaleQuoteBranchCode: _.noop,
                // onChangeSubmissionAndSync: onUpdateCoverage,
                // // onChangeSubmission: updateWizardData,
                // // onSyncCoverages: _.noop,
                // onShowMultiVersions: showMultiVersions,
                // onBackToDetail: closeMultiVersions,
                // onCreateNewVersion: onAddSideBySideVersion,
                onPrint: handlePrint,
                onWithdrawBranch,
                onUpdateBranchName,
            },
            resolveComponentMap: {
                sxsquoteperiods: SideBySidePeriodsComponent,
                validationissuescomponent: ValidationIssuesComponent,
                quoteunderwritingissueslist: QuoteUnderwritingIssuesList,
                // notificationcomponent: SuccessNotification,
                policychangequotecomponent: CPPolicyChangeQuoteComponent,
                refertouw: ReferToUnderwriterComponent,
                changesummary: ChangeSummaryComponent,
                addnewversioncomponent: CPAddNewChangeVersionComponent,
                texteditconfirm: TextEditConfirmComponent,
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateSubmissionVM}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }, [generateOverrides, submissionVM, updateSubmissionVM]); // sxsDataDTO

    // ==========================================
    const isInitializing = isServiceCallInProgress && !sxsDataDTO;
    const disableNext = hasBlockingIssue || jobStatus === 'Draft';
    return (
        <WizardPage
            disableNext={disableNext}
            onNext={onNext}
            // cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
            nextLabel={ isBillingOptionsEditable ? undefined : currentMessages.submitChange}
        >
            {!isInitializing && renderQuotePage}
        </WizardPage>
    );
}

CPQuoteChangePage.propTypes = {
    ...wizardProps,
    // /**
    //  * (Optional) the lobName key in lobData
    //  */
    // lobDataProductKey: PropTypes.string,
};

CPQuoteChangePage.defaultProps = {
    // lobDataProductKey: LOB_NAME,
}


export default CPQuoteChangePage;