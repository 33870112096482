import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalBody,
    ModalFooter,
    InputField
} from '@jutro/components';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { DataTable,DisplayColumn } from '@jutro/datatable';
import { Flex, FlexItem } from '@jutro/layout';

function SearchClassCodeDescriptionPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        tableData,
        oldRatingType,
        isClassDescriptionSearch
    } = props;

    const [ratingType, updateRatingType] = useState(oldRatingType);
    const [classCode, updateClassCode] = useState(null);
    const [classDescription, updateClassDescription] = useState('');
    const [searchedClassCodeData, updateSearchedClassCodeData] = useState(tableData);

    useEffect(() => {
        if (!_.isNil(oldRatingType)) {
            const filteredTable = tableData?.filter(elt => elt.RatingType === oldRatingType);
            updateSearchedClassCodeData(filteredTable);
        }
        // first enter filter out by rating type, no need dependencies
    }, [])

    const searchClassCode = () => {
        let newTableData;
        if (!_.isNil(ratingType) && (ratingType !== '')) {
            newTableData = tableData?.filter(elt => (elt.RatingType)?.toLowerCase() === ratingType?.toLowerCase());
        } else {
            newTableData = tableData;
        }

        if (!_.isNil(classCode) && (classCode !== '')) {
            if (isClassDescriptionSearch) {
                newTableData = newTableData?.filter(elt => elt.ClassCodeBldg === classCode);
            } else {
                newTableData = newTableData?.filter(elt => elt.Value === classCode);
            }
        }

        if (isClassDescriptionSearch) {
            newTableData = newTableData?.filter(elt => elt.Value?.toLowerCase().includes(classDescription?.toLowerCase()));
        }
        updateSearchedClassCodeData(newTableData);
    }

    const handleSelect = (item) => {
        // updateSelectedCode(item);
        onResolve(item);
    };

    const resetChoice = () => {
        updateRatingType(null);
        updateClassCode(null);
        updateClassDescription(null);
        updateSearchedClassCodeData([]);
    }

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="searchClassCodeDescription" autoFocus={false}>
                <h3>Class Code Search</h3>
                <br/>
                <InputField
                    id="ratingTypeInput"
                    label="Rating Type"
                    labelPosition="left"
                    onValueChange={(value) => updateRatingType(value)}
                    value={ratingType}
                    visible={!_.isNil(ratingType)}
                />
                <InputField
                    id="classCodeInput"
                    label="Class Code"
                    labelPosition="left"
                    onValueChange={(value) => updateClassCode(value)}
                    value={classCode}
                />
                {isClassDescriptionSearch && 
                    <InputField
                        id="classDescriptionInput"
                        label="Class Description"
                        labelPosition="left"
                        onValueChange={(value) => updateClassDescription(value)}
                        value={classDescription}
                    />
                }
                <Flex justifyContent="left">
                    <FlexItem><Button onClick={searchClassCode}>Search</Button></FlexItem>
                    <FlexItem><Button onClick={resetChoice} type="outlined">Reset</Button></FlexItem>
                </Flex>
                <DataTable
                    columnsConfigurable={false}
                    data={searchedClassCodeData}
                    defaultConfig={{
                        // filterValue: '',
                        page: 0,
                        pageSize: 5
                    }}
                    pageSizeOptions={[5, 10, 15, 20]} 
                    id="searchClassCodesTable"
                    showPagination
                    showSearch={false}
                    >
                    <DisplayColumn
                        id="select"
                        textAlign="left"
                        columnProportion="3"
                        renderCell={(item) => {
                            const buttonDom = <Button onClick={() => handleSelect(item)} >Select</Button>
                            return WniTableRowUtil.renderCell(item.classCode, buttonDom)
                        }}
                        sortable={false}
                    />
                    <DisplayColumn
                        header="Rating Type"
                        id="ratingType"
                        path="RatingType"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                        visible={!_.isNil(ratingType)}
                    />
                    <DisplayColumn
                        header="Class Code"
                        id="classCode"
                        path="Value"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                        visible={!isClassDescriptionSearch}
                    />
                    <DisplayColumn
                        header="Class Code"
                        id="classCode"
                        path="ClassCodeBldg"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                        visible={isClassDescriptionSearch}
                    />
                    <DisplayColumn
                        header="Class Description"
                        id="classDescription"
                        path="Value"
                        textAlign="left"
                        columnProportion="5"
                        sortable
                        visible={isClassDescriptionSearch}
                    />
            </DataTable>
            </ModalBody>
            <ModalFooter contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: "middle",
                        justifyContent: "right",
                        wrap: 'reverse',
                        gap: 'medium'
                    }
                }}>
                <Button onClick={()=>{onReject(ratingType)}} type="outlined">Cancel</Button>
            </ModalFooter>
        </ModalNext>
    );
}
SearchClassCodeDescriptionPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func
};

SearchClassCodeDescriptionPopup.defaultProps = {
    size: 'md',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop
};

export default SearchClassCodeDescriptionPopup;
