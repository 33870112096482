import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader, Button } from '@jutro/components';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniSxsQuoteService, WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { WniSubmissionService } from 'wni-capability-gateway';
import { Flex } from '@jutro/layout';
import {
    IncidentsUtil,
    ErrorsAndWarningsUtil,
    PaymentUtil,
    DocumentsUtil,
    QuoteUtil,
    IssuanceValidationUtil,
} from 'wni-portals-util-js';
import { WizardConstants } from 'wni-portals-config-js';
import { SideBySidePeriodsComponent } from 'wni-capability-gateway-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

// import WizardPage from '../../templates/HOWizardPage';

import BaseAdditionalInterestDetailComponent from './BaseAddIComponent/BaseAddIDetailComponent/BaseAdditionalInterestDetailComponent'
import styles from './BaseAdditionalInterestsPageComponent.module.scss';
import metadata from './BaseAdditionalInterestsPageComponent.metadata.json5';
import messages from './BaseAdditionalInterestsPageComponent.messages';

function BaseAdditionalInterestsReadOnlyPageComponent(props) {
    const {
        wizardData: submissionVM,
        wizardPageData,
        updateWizardPageData,
        steps: wizardSteps,
        wizardStepToFieldMapping,
        history,
        WizardPage,
        showAdditionalInterestInsuredType,
        showAdditionalInterestPleaseExplain,
        showAdditionalInterestSpecificInterest,
        showAdditionalInterestNameOfSchool,
        lobDataProductKey: LOB_NAME,
        dtoVMPath: DTOVM_PATH,
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            jobType,
            accountNumber,
            accountContacts_Ext: accountContacts,
            periodStatus,
        },
        lobData: {
            [LOB_NAME]: {
                coverables: {
                    additionalInterests
                }
            }
        }
    } = submissionVM.value;
    const viewModelService = useContext(ViewModelServiceContext);
    const emptyVM = viewModelService.create({}, 'pc', DTOVM_PATH);
    const typeListsAvailableValues = _.get(emptyVM, 'type.aspects.availableValues', []);

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);

    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const [sxsDataDTO, updatesxsDataDTO] = useState(undefined);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);

    const [paymentViewType, updatePaymentViewType] = useState(QuoteUtil.PAYMENT_PLAN_TYPES.monthly);

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [currentRow, updateCurrentRow] = useState(null);
    const [viewEditType, updateViewEditType] = useState('');

    const viewOrEditVehicleFn = useCallback(async (value) => {
        const rowVM = viewModelService.create(value, 'pc', DTOVM_PATH);
        updateCurrentRow(rowVM);
        updateViewEditType('edit');
    }, [DTOVM_PATH, viewModelService]);

    const renderInterestType = useCallback((item, index, property) => {
        const typeValue = _.get(item, property.path);
        let value = '';
        if (typeListsAvailableValues !== null) {
            const interestTypeKey = _.find(typeListsAvailableValues, (ttx) => ttx.code === typeValue);
            value = translator({ id: _.get(interestTypeKey, 'name') });
        }
        return value;
    }, [translator, typeListsAvailableValues]);

    const renderYesNo = useCallback((item, index, property) => {
        const typeValue = _.get(item, property.path);
        const value = typeValue ? 'Yes' : 'No';
        return value;
    }, []);

    const renderViewEditDom = useCallback(() => {
        return (
            <div className="activeRow btn-link">
                {translator(messages.viewLabel)}
            </div>
        );
    }, [translator]);

    const handleCancelFn = useCallback(() => {
        updateCurrentRow(null);
    }, [])

    const handleCustomActionFooterFn = useCallback((
        companyType, showBankDetail, readOnly, showBankSearch, newSearchBtnLabel,
        onReject, cancelBtnLabel, handleSave, actionBtnLabel, selectRow,
        handleSelectBank, getAdditionalInterestVM, innnerIsComponentValid, handleValidation
    ) => {
        const actionLabel = 'Save & Close';
        if (companyType === 'Bank') {
            let btns = null;
            if (showBankDetail) {
                btns = (
                    <Flex justifyContent="between" className="mt-10">
                        <Button onClick={() => handleCancelFn()} type="outlined">{cancelBtnLabel}</Button>
                        <div>
                            <Button disabled={readOnly} onClick={showBankSearch} type="filled">{newSearchBtnLabel}</Button>
                            <Button disabled={_.isEmpty(companyType) || readOnly} onClick={() => handleCancelFn(getAdditionalInterestVM, innnerIsComponentValid, handleValidation)} type="filled">{actionLabel}</Button>
                        </div>
                    </Flex>
                );
            } else {
                btns = (
                    <Flex justifyContent="between" className="mt-10">
                        <Button onClick={() => handleCancelFn()} type="outlined">{cancelBtnLabel}</Button>
                        <Button disabled={_.isEmpty(selectRow) || readOnly} onClick={handleSelectBank} type="filled">{actionBtnLabel}</Button>
                    </Flex>
                );
            }
            return btns;
        }
        return (
            <>
                <Flex justifyContent="between" className="mt-10">
                    <Button onClick={() => handleCancelFn()} type="outlined">{cancelBtnLabel}</Button>
                    <Button disabled={_.isEmpty(companyType) || readOnly} onClick={() => handleCancelFn(getAdditionalInterestVM, innnerIsComponentValid, handleValidation)} type="filled">{actionLabel}</Button>
                </Flex>
            </>
            
            
        );
    }, [handleCancelFn]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            // dynamicInlineNotificationContainer: {
            //     visible: false
            // },
            removeAdditionalInterest: {
                visible: false
            },
            addAdditionalInterest: {
                visible: false
            },
            additionalInterestTable: {
                noDataText: translator(messages.noDataText),
                data: additionalInterests,
                rowIdPath: 'fixedId'
            },
            interestType: {
                path: 'type',
                renderCell: renderInterestType
            },
            sendBill: {
                path: 'sendBill_Ext',
                renderCell: renderYesNo
            },
            loanNumber: {
                path: 'contractNumber'
            },
            viewOrEdit: {
                label: renderViewEditDom(),
                onClick: viewOrEditVehicleFn,
                disabled: currentRow,
            },
            currentEditingAddtionalInterest: {
                visible: currentRow != null,
                model: currentRow,
                emptyVM: emptyVM,
                jobID,
                sessionUUID,
                // handleSaveAdditionalInterestFn,
                handleCancelFn,
                accountContacts,
                accountNumber,
                jobType,
                viewEditType,
                // handleAdditionalInterestTypeValueChangeFn,
                handleCustomActionFooterFn,
                // showLoanNumber: isBank,
                isReadOnly: true,
                productCode: LOB_NAME,
                showAdditionalInterestInsuredType,
                showAdditionalInterestPleaseExplain,
                showAdditionalInterestSpecificInterest,
                showAdditionalInterestNameOfSchool
            }
        };
    }, [LOB_NAME, accountContacts, accountNumber, additionalInterests,
        currentRow, emptyVM, handleCancelFn, handleCustomActionFooterFn,
        jobID, jobType, renderInterestType, renderViewEditDom, renderYesNo,
        sessionUUID, translator, viewEditType, viewOrEditVehicleFn,
        showAdditionalInterestInsuredType, showAdditionalInterestPleaseExplain,
        showAdditionalInterestSpecificInterest, showAdditionalInterestNameOfSchool
    ]);

    // ===================================================
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            additionalinterestdetailcomponent: BaseAdditionalInterestDetailComponent
        }
    };

    return (
        <WizardPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={_.noop}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />

        </WizardPage>
    );
}

BaseAdditionalInterestsReadOnlyPageComponent.propTypes = {
    ...wizardProps,
    lobDataProductKey: PropTypes.string,
    dtoVMPath: PropTypes.string
};

BaseAdditionalInterestsReadOnlyPageComponent.defaultProps = {
    lobDataProductKey: 'homeowners',
    dtoVMPath: 'wni.edge.capabilities.quote.lob.homeowners.dto.HOAdditionalInterestDTO'
};
export default BaseAdditionalInterestsReadOnlyPageComponent;
