import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-policychange-common-react';
import 'gw-capability-quoteandbind-bop-react';
import BOPPolicyDetailsPage from './pages/BOPPolicyDetails/BOPPolicyDetailsPage';
import QuotePage from './pages/Quote/QuotePage';

setComponentMapOverrides(
    {
        BOPPolicyDetailsPage: { component: 'BOPPolicyDetailsPage' },
        QuotePage: { component: 'QuotePage' }
    },
    {
        BOPPolicyDetailsPage,
        QuotePage
    }
);
