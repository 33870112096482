import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import moment from 'moment';
import { Button, Icon, IconButton, Link } from '@jutro/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { BreakpointTrackerContext, Flex } from '@jutro/layout';
import { useWniModal } from 'wni-components-platform-react';
import { WindowUtil, IssuanceValidationUtil, QuoteUtil, ValidationIssueUtil, ConfigUtil } from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { CAVehicleService, CAMultiQuoteService } from 'wni-capability-quoteandbind-ca';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import appConfig from 'app-config';
import { WizardErrorContext } from 'wni-portals-wizard-react';

import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

import WizardPage from '../../templates/CAWizardPage';
import metadata from './CAVehiclesPage.metadata.json5';
import messages from './CAVehiclesPage.messages';
import vehicleComponent from './Components/VehicleComponent/CAVehicleComponent';
import { getVehicleTypeMaps, generateIssuanceValid, isOrigionalCostNewValid } from './Util/CAVehicle.util';
import { COVERABLES_PATH, PATH, CHILDREN_PATH } from './Config/CAVehicle.static';
import ImportModal from './Components/ImportModal/ImportModal';
import ExportModal from './Components/ExportModal/ExportModal';
import CopyModal from './Components/CopyModal/CopyModal';
import OverwrittenModal from './Components/OverwrittenModal/OverwrittenModal';
import ApplyFilterModal from './Components/ApplyFilterModal/ApplyFilterModal';
import PriceDigestModal from './Components/PriceDigestModal/PriceDigestModal';
import CopyVehicleCoverage from './Components/CopyCoverage/CopyVehicleCoverage';

const pageConfig = {
    page: 0,
    pageSize: 5
};

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};

const dtoName = "wni.edge.capabilities.quote.lob.commercialauto.dto.CAVehicleDTO"
const xCenter = "pc"
// const COVERABLES_PATH = 'lobData.commercialAuto.coverables';
// const PATH = `${COVERABLES_PATH}.vehicles`;
// const CHILDREN_PATH = `${PATH}.children`;
function CAVehiclesPage(props) {
    const modalApi = useWniModal();

    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardPageData,
        resetWizardDataToSnapshot,
        updateWizardSnapshot,
        isReadOnly,
        currentStep,
        checkRequiredForIssuance,
        isChangeFlow = false,
        //
        // updateWizardPageData,
        // onPreQuotePageNext,
    } = props;
    const {
        jobID,
        sessionUUID,
        baseData,
        lobData: {
            commercialAuto: {
                coverables:{
                    isUploading = false,
                    copyVehCoveragesBtnAvailable,
                    vehicleTypeMaps = [],
                    vehicleFilterSetMaps = [],
                    vehicleLocations = [],
                    vehicleSuspendMaps = [],
                    renumberingVehicles,
                    filterSizeClassMapsInChange = []
                } = {},
                offerings
            } = {}
        },
    } = submissionVM.value;
    const intl = useContext(IntlContext);
    const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies(['loadingMask']);
    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuanceR2(baseData, wizardPageData);
    const { updateWizardPageStickyIssues } = useContext(WizardErrorContext);

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        invalidFields
    } = useValidation('vehiclesPage');

    const VEHICLE_MAPS = getVehicleTypeMaps(vehicleTypeMaps, translator);
    const [currentRow, updateCurrentRow] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [filterData, updateFilterData] = useState({});
    const [vehicleLocationsData, updateVehicleLocationsData] = useState(vehicleLocations);
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);
    const [isRenumberVehicle, updateIsRenumberVehicle] = useState(renumberingVehicles);
    const [copyCoveragesBtnAvailable, updateCopyCoveragesBtnAvailable] = useState(copyVehCoveragesBtnAvailable);
    const [isImporting, updateIsImporting] = useState(isUploading);

    const selectedVersionPublicID = _.get(submissionVM, 'value.baseData.selectedVersion_Ext');
    const selectedVersionIndex = offerings
    .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const caCoveragesPath = `lobData.commercialAuto.offerings[${selectedVersionIndex}].coverages`;
    
    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'vehicleTable');
    };
    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };
    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        return false;
    }, [currentRow, invalidFields]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);
    
    const updateSubmission = (currentVM) => {
        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const allVehicles = _.get(submissionVM.value, PATH);
        const currentIndex = allVehicles.findIndex((item) => item.rowIdPath === rowIdPath); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${PATH}[${currentIndex}]`, currentVM.value);

        //
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);

        return newSubmissionVM;
    };

    const updateCurrentRowInteranl = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRow(rowData);
            return false;
        }
        const initCurrentRow = viewModelService.create(rowData.value, xCenter, dtoName);
        
        updateCurrentRow(initCurrentRow);
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(initCurrentRow);
            updateSubmissionData(newSubmissionVM)
        }
    };
  
    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardSnapshot);
    };
    const syncWizardData = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardData);
    };

    const generateValidationIssues = (issues) => {
        const displayMssages = _.get(issues, 'serverIssues_Ext.displayMssages', []); 
        const newDisplayMssages = displayMssages.map((item) => {
            const { reason } = item;
            return {
                ...item,
                reason: reason ? _.replace(reason, "::", ':') : reason
            }
        });
        _.set(issues, 'serverIssues_Ext.displayMssages', newDisplayMssages);
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const vehicleService = useCallback(async(serviceName, serviceData, isAdd) => {
        setLoadingMask(true);
        const vehicleData = _.get(submissionVM, `${PATH}.value`);
        const oldVehicles = _.cloneDeep(vehicleData) || [];
        const oldVehicleIds = oldVehicles.map((v) => v.publicID);

        const res = await CAVehicleService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        const coverages = _.get(res, 'caCoverages');
        _.set(submissionVM, `${PATH}.value`, res.caVehicles);
        _.set(submissionVM.value, caCoveragesPath, coverages);
        updateCopyCoveragesBtnAvailable(res.copyVehCoveragesBtnAvailable);
        updateVehicleLocationsData(res.vehicleLocations);
        updateSelection([]);
        updateWizardSnapshot(submissionVM);
        updateShowErrors(false);
        setLoadingMask(false);
        generateValidationIssues(res.errorsAndWarnings);
         // for add new vehicle
         if(isAdd) {
            // if find new vehicle, this is add new data
            const newVehicle = _.get(submissionVM, CHILDREN_PATH).find((vm) => !oldVehicleIds.includes(vm.value.publicID));
            updateCurrentRow(newVehicle);
            return false;
        }
        
    
        syncWizardData(null);
    }, [authHeader, jobID, sessionUUID, setLoadingMask, submissionVM]);


    const removeVehicle = () => {
        modalApi.showConfirm({
            title: messages.removeVehicleTitle,
            message: messages.removeVehicleDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            syncWizardData(null);
            const removeVehicles = _.get(submissionVM, `${PATH}.value`, []).filter((item) => selection.includes(item.rowIdPath));
            const removeData = removeVehicles.map((item) => {
                return {
                    vehicleType: item.vehicleType,
                    publicID: item.publicID
                }
            })
            vehicleService('removeVehicle', removeData);
        })
    };

    // if import vehicle has mutilple pricedigest
    const selectPriceDigestForVehicle = (vehiclesData = []) => {
        // to show mutliple pricedigest popup and select pricedigest for vehicle
        if(!_.isEmpty(vehiclesData)) {
            showPriceDigestModal(vehiclesData[0], vehiclesData)
        }     
    };
    const runBulkPrefill = async() => {
        const res = await CAVehicleService.runBulkPrefill(jobID, sessionUUID, authHeader);
        const vehicles = res.caVehicles;
        const hasMutliPriceDigestVehicles = vehicles.filter((item) => _.get(item, 'priceDigestDetails', []).length > 1);
        selectPriceDigestForVehicle(hasMutliPriceDigestVehicles);  
    }

    useEffect(() => {
        const allVehicles = _.get(submissionVM.value, PATH, []);
        const hasMutliPriceDigestVehicles = allVehicles.filter((item) => _.get(item, 'priceDigestDetails', []).length > 1);
        // to show mutliple pricedigest popup and select pricedigest for vehicle
        selectPriceDigestForVehicle(hasMutliPriceDigestVehicles);  
    }, []);

    const importVehicle = () => {
        const componentProps = {
            title: messages.importTitle,
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        };
        modalApi.showModal(<ImportModal {...componentProps} />).then(async(result) => {
            const isUploadingInProgress = !!result.isUploading
            updateIsImporting(isUploadingInProgress);
            _.set(submissionVM.value, `${COVERABLES_PATH}.isUploading`, isUploadingInProgress);
            if(isUploadingInProgress) {
                updateWizardSnapshot(submissionVM);
                // if uploading in progress, will show warning message, and no need to check if vehicles has mutliple pricedigest.
                return false;
            }
            updateCopyCoveragesBtnAvailable(result.copyVehCoveragesBtnAvailable);
            const vehicleData = result.caVehicles || [];
            _.set(submissionVM.value, caCoveragesPath, result.caCoverages)
            _.set(submissionVM, `${PATH}.value`, vehicleData);
            await updateWizardSnapshot(submissionVM);
           
            const hasMutliPriceDigestVehicles = vehicleData.filter((item) => _.get(item, 'priceDigestDetails', []).length > 1);
            // to show mutliple pricedigest popup and select pricedigest for vehicle
            selectPriceDigestForVehicle(hasMutliPriceDigestVehicles);  
        }).catch(() => {
            // do nothing when close the popup
            _.noop();
        });
        
    };
    const displayErrorModal = useCallback(() => {
        modalApi.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, []);

    const download = (isTemplete, type) => {
        const date = moment(new Date()).format('YYYY-MM-DD');
        const serviceUrl = getProxiedServiceUrl('caDownloadService').concat(`/${jobID}?jobID=${jobID}&isTemplete=${isTemplete}&page=${type==='vehicleDirection'? 'vehicleDirection':'vehicle'}`);
        const fileName = type==='vehicleDirection'?'PE_VehiclesBulkUploadDirections':`${type}_CA_Vehicles_${date}`;
        return WniDocumentRetrievalService.generateDocument(serviceUrl, fileName, authHeader, _.noop, displayErrorModal)
    };

    const exportVehicle = async() => {
        const componentProps = {
            title: messages.exportTitle,
            actionBtnLabel: translator(messages.exportToSpreadsheet),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            download,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        };
        modalApi.showModal(<ExportModal {...componentProps} />).then(() => _.noop).catch(()=> _.noop)
    };

    const downloadTemplate = async(isDirectionDoc) => {
        setLoadingMask(true);
        await download(true, (typeof isDirectionDoc === 'boolean' &&  isDirectionDoc)?'vehicleDirection':'Templete');
        setLoadingMask(false);
    };

    const copyVehicle = async() => {
        const vehicles = _.get(submissionVM, `${PATH}.value`, []);
        const selectedVehicles = vehicles.filter((item) => selection.includes(item.rowIdPath));
        const componentProps = {
            title: messages.copyVehicles,
            actionBtnLabel: translator(messages.copyVehicles),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            selectedVehicles,
            renderDropDownCell,
            intl,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        };
        modalApi.showModal(<CopyModal {...componentProps} />).then((res) => {
            _.set(submissionVM, `${PATH}.value`, res.caVehicles);
            _.set(submissionVM.value, caCoveragesPath, res.cacoverages);
            updateCopyCoveragesBtnAvailable(res.copyVehCoveragesBtnAvailable);
            updateSelection([]);
            updateShowErrors(false);
            generateValidationIssues(res.errorsAndWarnings);
            syncWizardData(null);
            updateWizardData(submissionVM);
        }).catch(() => _.noop)
        
    };

    const copyVehicleCoverage = async() => {
        const vehicles = _.get(submissionVM, `${PATH}.value`, []).map((item) => {
            return {
                code: item.rowIdPath,
                name: item.displayNameOnCopy
            }
        });
        const componentProps = {
            title: messages.copyVehicleCoverage,
            availableVehicles: vehicles,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader,
            }
        };
        modalApi.showModal(<CopyVehicleCoverage {...componentProps} />).then((res) => {
            if(!res || _.isEmpty(res)) {
                return false;
            }
            const allVehicles = _.get(submissionVM.value, PATH, []); 
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.forEach(res, (item) => {
                const currentIndex = allVehicles.findIndex((v) => v.rowIdPath === item.rowIdPath);
                _.set(newSubmissionVM.value, `${PATH}[${currentIndex}]`, item);
            });
            syncWizardData(null);
            updateWizardData(newSubmissionVM);
        }).catch(() => _.noop)
    };
    const addVehicle = async(item) => {
        const newData = {
            vehicleType: item.code,
            rowIdPath: ConfigUtil.getUuid(),
            isUpdate: false
        };
        vehicleService('addVehicle', newData, true)
    };
    const viewAndEdit = async(item) => {
        const currentRowIdPath = _.get(currentRow, 'value.rowIdPath')
        if(item.rowIdPath === currentRowIdPath) {
            return false;
        }
        setLoadingMask(true);
        const serviceParams = {
            publicID: item.publicID,
            vehicleType: item.vehicleType
        }
        const res = await CAVehicleService.viewAndEdit(jobID, sessionUUID, serviceParams, authHeader);
        const initCurrentRow = viewModelService.create(res.caVehicle, xCenter, dtoName);
        updateCurrentRow(initCurrentRow);
        setLoadingMask(false);
    };
    const updateVehicle = useCallback(async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        await vehicleService('updateVehicle', currentRow.value);
        return true;
    }, [currentRow, handleValidation, isComponentValid, vehicleService]);

    const onNextVehicle = useCallback(() => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        let index = _.findIndex(childrenVM, (vm) => vm.value.rowIdPath === currentRow.value.rowIdPath);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const nextVehicle = _.get(childrenVM[index], 'value');
        syncWizardData(null);
        viewAndEdit(nextVehicle)
        WindowUtil.scrollToTop()
    }, [currentRow, submissionVM]);

    const cancelVehicle = () => {
        syncWizardData(null)
    }

    const findItemVMValid = (vm) => {
        const locationValid =  _.get(vm, 'location.aspects.valid') && _.get(vm, 'location.aspects.subtreeValid');
        const displayabledValid = _.get(vm.value, 'bulkVehicleDetails', []).every((displayable) => displayable.isValid);
        const priceDigestInValid = _.get(vm.value, 'priceDigestDetails', []).length > 1 && _.get(vm.value, 'callPriceDigestType') === 'VIN';
        const origionalCostNewValid = isOrigionalCostNewValid(vm.value);
        const vehicleCoveragesInvalid = _.get(vm.value, 'vehicleCoveragesInvalid_Ext')
        return locationValid && displayabledValid && !priceDigestInValid && !vehicleCoveragesInvalid && origionalCostNewValid
    };
    const allVehicleValid = () => {
        const allVehicleVMs = _.get(submissionVM, CHILDREN_PATH);
        return allVehicleVMs.every((vm) => findItemVMValid(vm));
    };


    const onPageNext = async () => {
        if(!allVehicleValid()) {
            return false;
        }

        const {
            onPreQuotePageNext, // = onDefaultPageNext,
        } = props;

        const requestData = {
            jobID,
            sessionUUID,
            renumberingVehicles: isRenumberVehicle
        }
        const res = await CAVehicleService.onPageNext(requestData, authHeader);
        _.set(submissionVM.value, `${COVERABLES_PATH}.renumberingVehicles`, res.renumberingVehicles);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }

        // return submissionVM;
        //
        let retval = submissionVM;
        if (_.isFunction(onPreQuotePageNext)) {
            retval = await onPreQuotePageNext();
        }

        return retval;
    };

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const itemVM = childrenVM.find((v) => v.value.rowIdPath === index) || {};
        let type;
        const isItemValid = findItemVMValid(itemVM);
        if(isItemValid) {
            const issuacneInValidField = generateIssuanceValid(itemVM);
            if(_.isEmpty(issuacneInValidField)) {
                type = 'success';
            } else {
                type = 'warning';
            }
        } else {
            type = 'error';
        }
        const iconDom = <Icon id={`validationIcon${item.rowIdPath}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.rowIdPath, iconDom)
    };

    const renderDropDownCell = (data, index, {path, typeKey}) => {
        const formatValue = data[path] ? translator({id: `${typeKey}.${data[path]}` }) : '-';
        return <span className='text-breakAll'>{formatValue}</span>
    };

    const hanldeApplyFilter = () => {
        const componentProps = {
            title: messages.applyFilters,
            cancelBtnLabel: translator(commonMessages.cancelModel),
            actionBtnLabel: translator(messages.applyFilters),
            vehicleTypeMaps: VEHICLE_MAPS,
            vehicleFilterSetMaps,
            vehicleLocationMaps: vehicleLocationsData,
            vehicleSuspendMaps,
            filterData,
            updateFilterData,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        };
        modalApi.showModal(<ApplyFilterModal {...componentProps} />).then((res) => {
            _.set(submissionVM, `${PATH}.value`, res.caVehicles);
            updateCurrentRow(null);
            updateWizardData(submissionVM)

        }).catch(() => _.noop)
    };

    const renderActionHeader = () => {
        return <Button onClick={hanldeApplyFilter}>{translator(messages.applyFilters)}</Button>
    };

    const viewOverwriteData = (data, diffType) => {
        const componentProps = {
            title: messages[`${diffType}Title`],
            cancelBtnLabel: translator(commonMessages.close),
            data: data,
            diffType
        };
        modalApi.showModal(<OverwrittenModal {...componentProps} />).then(() => _.noop).catch(() => _.noop);
    };

    const showPriceDigestModal = async(data, mutiVehicleData) => {
        const componentProps = {
            title: translator(messages.priceDigestTitle, {vehicleNumber: data.vehicleNumber}),
            publicID: data.publicID,
            vehicleType: data.vehicleType,
            intl,
            renderDropDownCell,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            data: data.priceDigestDetails || [],
        }
        await modalApi.showModal(<PriceDigestModal {...componentProps} />).then(async(result) => {
            if(!result) {
                return false;
            }
            const rowIdPath = _.get(result, 'caVehicle.rowIdPath');
            const allVehicles = _.get(submissionVM.value, PATH);
            generateValidationIssues(result.errorsAndWarnings);
            const currentIndex = allVehicles.findIndex((item) => item.rowIdPath === rowIdPath); 
            // const newSubmissionVM = viewModelService.clone(submissionVM);
            if(!mutiVehicleData && currentRow) {
                _.set(currentRow, 'value', result.caVehicle);
                syncWizardDataSnapshot(currentRow)
            } else {
                _.set(submissionVM.value, `${PATH}[${currentIndex}]`, result.caVehicle);
                updateWizardSnapshot(submissionVM);
            }
            if(!mutiVehicleData || _.isEmpty(mutiVehicleData)){
                return false;
            }
            // to show mutliple pricedigest popup and select pricedigest for vehicle
            const hasMutliPriceDigestVehicles = mutiVehicleData.filter((item) => item.rowIdPath !== rowIdPath);
            selectPriceDigestForVehicle(hasMutliPriceDigestVehicles); 

        }).catch(() => _.noop());
    };

    const renderIcon = (data, index, { type }) => {
        const {
            priceDigestDiffFields_uov: uovDiffFields = [],
            priceDigestDiffFields_vou: vouDiffFields = [],
            checkMultiVINIndicatorVisiable
        } = data;
    
        return (
            <Flex justifyContent='between' gap="none">
                {uovDiffFields.length !==0 && <IconButton title='System Generated Value overridden by User' icon={VALIDATION_ICON_MAP[type]} className='wni-icon-yellow' onClick={() => viewOverwriteData(uovDiffFields, 'uov')} />}
                {vouDiffFields.length !==0 && <IconButton title='User Entered value overridden by System' icon={VALIDATION_ICON_MAP[type]} className='wni-icon-orange' onClick={() => viewOverwriteData(vouDiffFields, 'vou')} />}
                {checkMultiVINIndicatorVisiable && <IconButton title='Entered Vin has resulted in MULTIPLE matches.' icon={VALIDATION_ICON_MAP[type]} className='wni-icon-error' onClick={() => showPriceDigestModal(data)} />}
            </Flex>
        )
    };

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };
 
    const generateVehicleItemsOverrides = () => {
        const overrides = VEHICLE_MAPS.map((item, index) => {
            return {
                [`vehicleItem${index}`]: {
                    onClick: () => addVehicle(item)
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    const writeValue = (value, path) => {
        if(currentRow) {
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(currentRow.value, path, undefined);
            } else {
                _.set(currentRow.value, path, value);
            }
            syncWizardData(currentRow);
        }
    };
    //---------------------
    const overrideProps = {
        '@action': {
            visible: !isReadOnly
        },
        '@field': {
            labelPosition: 'left',
            readOnly: isReadOnly,
            isChangeFlow
        },
        pageMask: {
            visible: isImporting
        },
        uploadingNotification: {
            visible: isImporting
        },
        vehicleNotification: {
            message: !isChangeFlow ? messages.vehiclePageMessage : messages.vehicleChangePageMessage,
            linkProps:!isChangeFlow?{
                children: 'directions document',
                onClick: ()=>{
                    downloadTemplate(true)
                }
              }: null
        },
        renumberingVehicles: {
            value: isRenumberVehicle,
            onValueChange: (val) => updateIsRenumberVehicle(val)
        },
        copyCoverages: {
            disabled: !copyCoveragesBtnAvailable
        },
        runBulkPrefill: {
            visible: false
        },
        downloadTemplate: {
            visible: !isChangeFlow && !isReadOnly
        },
        importVehicle: {
            visible: !isChangeFlow && !isReadOnly
        },
        exportVehicle: {
            visible: !isChangeFlow && !isReadOnly
        },
        addVheicle: {
            visible: !isReadOnly
        },
        addVehicleData: {
            data: VEHICLE_MAPS
        },
        vehicleDetailContainer: {
            visible: !!currentRow
        },
        vehicleTable: {
            onSelectionChange: (rows) => updateSelection(rows),
            selectionType: isReadOnly ? 'none' : 'multi',
            selectedRows: selection,
            // showPagination: _.get(submissionVM, `${PATH}.value`, []).length > 5,
            // config: tablePageConfig,
            // onConfigChange,
            // pageSizeOptions: [5, 10, 15, 20]
        },
        viewAndEdit: {
            header: renderActionHeader()
        },
        viewAndEditLink: {
            label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel
        },
        vehicleDetails: {
            currentRow,
            syncWizardData,
            syncWizardDataSnapshot,
            showPriceDigestModal,
            onValidate,
            showErrors,
            onValueChange: writeValue,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader,
                baseData
            },
            submissionVM,
            updateWizardData,
            isReadOnly,
            isChangeFlow,
            filterSizeClassMapsInChange,
            generateValidationIssues
        },
        copyVehicle:{
            disabled: _.isEmpty(selection) || isReadOnly,
            visible: !isReadOnly
        },
        removeVehicle: {
            disabled: _.isEmpty(selection) || isReadOnly,
            visible: !isReadOnly
        },
        cancelButton: {
            visible: true
        },
        ...generateVehicleItemsOverrides()
    };
    const resolvers = {
        resolveCallbackMap: {
            copyVehicleCoverage,
            copyVehicle,
            removeVehicle,
            downloadTemplate,
            importVehicle,
            exportVehicle,
            viewAndEdit,
            cancelVehicle,
            saveVehicle: () => {
                updateVehicle().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNextVehicle: () => {
                updateVehicle().then((valid) => {
                    if (valid) {
                        onNextVehicle();
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderDropDownCell,
            renderValidationCell,
            renderIcon,
            runBulkPrefill
        },
        resolveComponentMap: {
            vehiclecomponent: vehicleComponent
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            // showEntityNameInPageLevelIssues
            disablePrevious={isImporting}
            disableNext={!allVehicleValid() || isImporting}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CAVehiclesPage.propTypes =  {
    ...WizardPage.propTypes,
    onPreQuotePageNext: PropTypes.func,
};
CAVehiclesPage.defaultProps = {
    ...WizardPage.defaultProps,
    onPreQuotePageNext: undefined,
};
export default CAVehiclesPage;