/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IconButton, Link, Icon } from '@jutro/components';
import {
    WindowUtil,
    ErrorsAndWarningsUtil,
    IssuanceValidationUtil,
} from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { ValidationIssueRenderUtil } from 'wni-portals-util-react';

import ValidationIssuesComponent from '../ValidationIssuesComponent/ValidationIssuesComponent';

/*
 * Enhance ValidationIssuesComponent by providing the folliwng convenient props
 * 1)validationIssues: IssuanceValidationUtil.getIssuesMap(filteredValidationIssues),
 * 2)issueRenderFn: getQuotePageIssueRenderFn,
 * 3) 
 * 
 * See also:
 * 1, PQuotePage.errorsAndWarningsSection
 * 2, IssuanceValidation.getIssuesMap()
 * 3, DomRenderUtil.issueRenderFn
 * 4, 
 * 
 */
function ConsolidatedValidationIssuesComponent(props) {
    const translator = useTranslator();
    const {
        validationIssues: initialValidationIssues = [],
        issueRenderFn: initialIssueRenderFn, // will be ignored here
        // getSortKeyForIssueWithSameType: initialGetSortKeyForIssueWithSameType,
        scrollToIssues: initialScrollToIssues,
        showEntityNameInPageLevelIssues,
        ...otherProps
    } = props;

    const newValidationIssues = IssuanceValidationUtil.getIssuesMap(initialValidationIssues, showEntityNameInPageLevelIssues);
    // To be refactored
    // const newIssueRenderFunc = ValidationIssueRenderUtil.getQuotePageIssueRenderFn;
    const newIssueRenderFunc = ValidationIssueRenderUtil.issueRenderFn;

    // =============================
    if (_.isEmpty(newValidationIssues)) {
        return null;
    }

    return (
        <ValidationIssuesComponent
            validationIssues={newValidationIssues}
            issueRenderFn={newIssueRenderFunc}
            scrollToIssues
            {...otherProps} />
    );
    
}


ConsolidatedValidationIssuesComponent.propTypes = ValidationIssuesComponent.propTypes;

ConsolidatedValidationIssuesComponent.defaultProps = ValidationIssuesComponent.defaultProps;

export default ConsolidatedValidationIssuesComponent;
