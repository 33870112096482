import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Icon, DropdownMenuLink } from '@jutro/components';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { CALocationService } from 'wni-capability-quoteandbind-ca';
import {
    WindowUtil,
    QuoteUtil,
    WniPNIUtil,
    ValidationIssueUtil,
} from 'wni-portals-util-js';

import { WizardConstants, PortalConstants, } from 'wni-portals-config-js';

import WizardPage from '../../templates/CAWizardPage';
import CALocationComponent from './Components/LocationComponent/CALocationComponent'
import CALocationsUtil from './Util/CALocationsUtil';

import styles from './CALocationsPage.module.scss';
import metadata from './CALocationsPage.metadata.json5';
import messages from './CALocationsPage.messages';

const DEFAULT_LINE_PATH = 'commercialAuto';

const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};
function CALocationsPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardSnapshot,
        updateWizardSnapshot,
        isReadOnly,
        //
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        resetWizardDataToSnapshot,
        caLocationService,
        linePath
    } = props;

    const {
        jobID, sessionUUID,
        baseData,
        lobData: {
            [linePath]: {
                offerings
            }
        },
    } = submissionVM.value;

    const {
        selectedVersion_Ext: selectedVersionPublicID,
    } = baseData

    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const coveragesPath = `lobData.${linePath}.offerings[${selectedVersionIndex}].coverages`

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CALocationsPage');
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const locationsVMPath = `lobData.${linePath}.coverables.locations`;
    const locationsVM = _.get(submissionVM, locationsVMPath);

    const unusedLocationsVMPath = `lobData.${linePath}.coverables.unusedLocations`;
    const unusedLocationValues = _.get(submissionVM, unusedLocationsVMPath).value || [];
    // const territoryCodeVMPath = `lobData.${linePath}.coverables.territoryCodesExt`;
    // const territoryCodeValues = linePath === DEFAULT_LINE_PATH && _.get(submissionVM, territoryCodeVMPath).value || [];
    
    const statesVMPath = `lobData.${linePath}.coverables.stateOptions`;
    const statesValues = _.get(submissionVM.value, statesVMPath, []);
    const stateOptions = statesValues.map((item) => {
        return {
            name: translator({id: `typekey.State.${item}` }),
            code: item
        }
    });

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'locationTable');
    };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const updateSubmission = (currentVM) => {
        const publicID = _.get(currentVM.value, 'publicID');
        const allLoations = _.get(submissionVM.value, locationsVMPath);
        const currentIndex = allLoations.findIndex((item) => item.publicID === publicID); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${locationsVMPath}[${currentIndex}]`, currentVM.value);
        return newSubmissionVM;
    };

    const updateCurrentRow = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        // const {
        //     _dtoName,
        //     _xCenter,
        // } = rowData;
        // const initCurrentRow = viewModelService.create(rowData.value, _xCenter, _dtoName);
        updateCurrentRowInteranl(rowData);
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(rowData);
            updateSubmissionData(newSubmissionVM)
        }
    };

    const findItemVMValid = (vm) => {
        if(!vm) { return true }
        const newVM = viewModelService.clone(vm);
        _.set(newVM, 'isUpdate', true);
        return _.get(newVM, 'aspects.valid') && _.get(newVM, 'aspects.subtreeValid')
    };

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${locationsVMPath}.children`);
        const itemVM = childrenVM.find((vm) => vm.value.rowIdPath === index);
        const hasIssuanceInvalidFields = CALocationsUtil.hasIssuanceInvalidFields(itemVM);
        let type;
        if(!findItemVMValid(itemVM)) {
            type = 'error';
        } else if (hasIssuanceInvalidFields) {
            type = 'warning';
        } else {
            type = 'success';
        }
        const iconDom = <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.publicID, iconDom)
    };

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const renderAddressCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${locationsVMPath}.children`);
        const itemVM = childrenVM.find((vm) => vm.value.rowIdPath === index);
        return CALocationsUtil.getPrimaryAddressDisplayName(_.get(itemVM, `address_Ext`))
    }

    const syncWizardData = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardData);
    };

    const viewOrEditLocation = (value, index) => {
        setLoadingMask(true);
        const childrenVM = _.get(submissionVM, `${locationsVMPath}.children`);
        const locationVM = childrenVM.find((item) => item.value.rowIdPath === index);
        _.set(locationVM.value, 'isUpdate', true);
        syncWizardData(locationVM);
        setLoadingMask(false);
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        const filteredNewValidationIssues = newValidationIssues.filter((issue) => issue.flowStepId !== 'Portal:WCStateSpecificInformationPage');
        // updateWizardPageStickyIssues(currentStep.id, []);
        updateValidationIssues(filteredNewValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(filteredNewValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(filteredNewValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const updateSubmissionVMForResponse = useCallback((res) => {
        const resLocationPath = linePath === DEFAULT_LINE_PATH ? 'calocations' : 'wclocations';
        const resUnusedLocationPath = linePath === DEFAULT_LINE_PATH ? 'unusedCALocations' : 'unusedWCLocations';
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${locationsVMPath}.value`, res[resLocationPath]);
        _.set(newSubmissionVM, `${unusedLocationsVMPath}.value`, res[resUnusedLocationPath]);

        const coverages = _.get(res, 'coverages')
        if (!_.isNil(coverages)) {
            _.set(newSubmissionVM.value, coveragesPath, coverages)
        }
        const stateSpecificInfo =  _.get(res, 'stateSpecificInfo')
        if(!_.isNil(stateSpecificInfo)) {
            _.set(newSubmissionVM.value, `lobData.${linePath}.coverables.stateSpecificInfo`, stateSpecificInfo)
        }
        const modifier =  _.get(res, 'modifier')
        if(!_.isNil(modifier)) {
            _.set(newSubmissionVM.value, `lobData.${linePath}.modifiers_Ext`, modifier)
        }
        //
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);

        updateWizardSnapshot(newSubmissionVM);
        return newSubmissionVM;
    }, [coveragesPath, linePath, locationsVMPath, submissionVM, unusedLocationsVMPath]);
    
    const locationService = useCallback(async(serviceName, serviceData) => {
        setLoadingMask(true);

        const res = await caLocationService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        updateSubmissionVMForResponse(res);
        updateSelection([]);
        updateShowErrors(false);
        generateValidationIssues(res.errorsAndWarnings);
    
        updateCurrentRow(null);
        setLoadingMask(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask, submissionVM]);

    const addLocation = () => {
        const locationObj = CALocationsUtil.setDefaultLocationObj();
        const {
            _dtoName,
            _xCenter,
        } = locationsVM;
        const locationVM = viewModelService.create(locationObj, _xCenter, _dtoName);
        const addedLocation = locationsVM.pushElement(locationVM);
        updateCurrentRow(addedLocation);
        updateWizardData(submissionVM);
        updateShowErrors(false);
    };

    const writeValue = (value, path) => {
        if(currentRow) {
            let newValue = _.clone(value);
            if(path === 'address_Ext') {
                const state = _.get(value, 'state');
                if(!_.includes(statesValues, state)) {
                    newValue = _.set(value, 'state', '')
                }
            }
            _.set(currentRow.value, path, newValue);
            syncWizardData(currentRow);
        }
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardSnapshot);
    };

    const cancelLocation = () => {
        resetWizardDataToSnapshot();
        syncWizardData(null)
    }

    const updateLocation = useCallback(async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid || CALocationsUtil.hasIssuanceInvalidFields(currentRow)) {
            handleValidation();
            return false;
        };

        // const questionSetsAnswers = _.get(currentRow.value,'locationQuestionSets[0].answers');
        // const locationQuestionSetsAnswers = CALocationsUtil.transferAnswerstoNull(questionSetsAnswers)
        // _.set(currentRow.value,'locationQuestionSets[0].answers', locationQuestionSetsAnswers)
        await locationService('updateLocation', currentRow.value);
        return true;
    }, [currentRow, handleValidation, isComponentValid, locationService]);

    const removeLocations = () => {
        modalApi.showConfirm({
            title: messages.removeLocationTitle,
            message: messages.removeLocationDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.Ok,
            cancelButtonText: messages.Cancel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            syncWizardData(null);
            locationService('removeLocations', selection);
        })
    };

    const addExistingLocation = async(existingLocations, isAll = false) => {
        setLoadingMask(true);
        const location = _.get(submissionVM, `${locationsVMPath}.value`);
        const oldLocations = _.cloneDeep(location) || [];
        const oldLocationIds = oldLocations.map((v) => v.publicID); // get old locations publicIds

        const existingLocationPublicIds = existingLocations.map((item) => item.publicID);
        const res = await caLocationService.addExistingLocations(jobID, existingLocationPublicIds, sessionUUID, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        setLoadingMask(false);
        if(!isAll) {
            // if add one existing location, will open this added location details
            const newLocation = _.get(newSubmissionVM, `${locationsVMPath}.children`).find((vm) => !oldLocationIds.includes(vm.value.publicID));
            updateCurrentRow(newLocation);
        }
    };

    const addAllExistingLocation = async () => {
        addExistingLocation(unusedLocationValues, 'isAll')
    }

    const onNextLocation = useCallback(() => {
        const childrenVM = _.get(submissionVM, `${locationsVMPath}.children`);
        let index = _.findIndex(childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const indexID = _.get(childrenVM[index], 'value.publicID');
        syncWizardData(null);
        viewOrEditLocation(null, indexID)
        WindowUtil.scrollToTop()
    }, [currentRow, submissionVM]);

    const allLocationValid = () => {
        const allLocationVMs = _.get(submissionVM, `${locationsVMPath}.children`);
        return allLocationVMs.every((vm) => findItemVMValid(vm));
    };

    const onPageNext = useCallback(async () => {
        if(!allLocationValid()) {
            return false;
        }
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await caLocationService.onPageNext(requestData, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, submissionVM]);

    const disableDeleteBtn = () => {
        if(_.isEmpty(selection)) {
            return true
        } 
    
        // find if selection has primary location
        const primaryLocation = _.get(submissionVM, `${locationsVMPath}.value`, []).find((item) => item.isPrimary);
        const primaryLocationID = _.get(primaryLocation, 'rowIdPath');
        if(selection.includes(primaryLocationID)) {
            return true
        } 
        return false
    }

    const renderAllUnusedLocationsMenuItem = () => {
        const retval = unusedLocationValues.map((item) => {
            const address = _.get(item, 'address_Ext', {})
            const { publicID, displayName } = address;

            return (
                <DropdownMenuLink
                    type="action"
                    onClick={() => addExistingLocation([item])}
                    key={`${publicID}-MenuLink`}
                >
                    {displayName}
                </DropdownMenuLink>
            );
        })

        return retval;
    }

    //---------------------
    const overrideProps = {
        '@all': {
            isReadOnly
        },
        '@field': {
           labelPosition: 'left',
        },
        removeLocation: {
            visible: !isReadOnly,
            disabled: disableDeleteBtn()
        },
        addLocation: {
            visible: !isReadOnly
        },
        addExistingLocation: {
            visible: !isReadOnly,
            disabled: unusedLocationValues.length === 0,
            content: renderAllUnusedLocationsMenuItem(),
        },
        addAllExistingLocation: {
            visible: !isReadOnly,
            disabled: unusedLocationValues.length === 0,
        },
        locationTable: {
            path: `${locationsVMPath}.value`,
            onSelectionChange: (rows) => updateSelection(rows)
        },
        address: {
            renderCell: renderAddressCell
        },
        primary: {
            renderCell: (item) => {
                return _.get(item, `isPrimary`) ? 'X' : '-'
            }
        },
        locationValidationIcon: {
            renderCell: renderValidationCell
        },
        locationDetailContainer: {
            visible: currentRow != null,
        },
        viewOrEditLink: {
            disabled: currentRow || selection.length > 1,
            label: isReadOnly ? messages.caViewLabel : messages.caViewAndEditLabel
        },
        locationDetails: {
            visible: !_.isEmpty(currentRow),
            locationVM: currentRow,
            baseData,
            stateOptions,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            updateValidationIssues,
            generateValidationIssues,
            onValidate,
            showErrors,
            isReadOnly,
            linePath,
            extendProps: {
                jobID,
                authHeader,
                sessionUUID,
                baseData
            }
        },
        saveButtons: {
            visible: !isReadOnly
        },
        saveNextButton: {
            visible: _.get(submissionVM, `${locationsVMPath}.children`, []).length > 1
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addLocation,
            addAllExistingLocation,
            removeLocations,
            viewOrEditLocation,
            cancelLocation,
            saveLocation: () => {
                updateLocation().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            saveAndNextLocation: () => {
                updateLocation().then((valid) => {
                    if (valid) {
                        onNextLocation();
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
        },
        resolveComponentMap: {
            locationcomponent: CALocationComponent
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            pageLevelValidationIssues={validationIssues}
            showEntityNameInPageLevelIssues
            showRequiredInfoForFasterQuote
            disableNext={!allLocationValid()}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CALocationsPage.propTypes = WizardPage.propTypes;
CALocationsPage.defaultProps = WizardPage.defaultProps;

CALocationsPage.propTypes = {
    ...wizardProps,
    caLocationService: PropTypes.func,
    linePath: PropTypes.string,
};

CALocationsPage.defaultProps = {
    caLocationService: CALocationService,
    linePath: DEFAULT_LINE_PATH
};
export default CALocationsPage;