import React, {
    useContext,
    useCallback,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent, findInvalidFieldsFromMetadata } from '@jutro/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { withRouter,
    useHistory,
    useLocation,
    useParams
} from 'react-router-dom';
import { AccountService } from 'gw-capability-gateway-policy';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import NewSubmissionPage from '../NewSubmission/NewSubmissionPage';
import metadata from './NewQuoteForExistingAccount.metadata.json5';
import styles from './NewQuoteForExistingAccount.module.scss';

function NewQuoteForExistingAccount(props) {   
    const {
        accountNumber,
    } = useParams();
    const [accountData, updateAccountData] = useState({});

    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask }, } = useDependencies(['loadingMask']);
    const initData = async() => {
        setLoadingMask(true);
        const accountDetails = await AccountService.getAccountDetails(accountNumber, authHeader);
        updateAccountData(accountDetails);
        setLoadingMask(false);
    };

    useEffect(() => {
        initData()
    }, []);


    const renderAddress = () => {
        if(!accountData || _.isEmpty(accountData)) {
            return null
        };
        const addressCity = _.get(accountData, 'accountHolder.primaryAddress.city')
        const addressState = _.get(accountData, 'accountHolder.primaryAddress.state')
        const addressPostalCode = _.get(accountData, 'accountHolder.primaryAddress.postalCode')
        return (<>
            <div>{_.get(accountData, 'accountHolder.displayName')}</div>
            <div>{_.get(accountData, 'accountHolder.primaryAddress.addressLine1')}</div>
            <div>{`${addressCity}, ${addressState}, ${addressPostalCode}`}</div>
        </>)
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        addressDetails: {
            value: renderAddress()
        },
        newSubmission: {
            accountHolder: _.get(accountData, 'accountHolder'),
            accountNumber: accountNumber
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            newsubmission: NewSubmissionPage
        }

    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            accountData,
            id,
            path,
            overrideProps
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={accountData}
            overrideProps={overrideProps}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    )
}

export default withRouter(NewQuoteForExistingAccount);
