import { setComponentMapOverrides } from '@jutro/uiconfig';

import { PaymentDetailsPage, QualificationPage } from 'gw-capability-quoteandbind-common-react';

import HOYourInfoPage from './pages/YourInfo/YourInfoPage';
import HOYourHomePage from './pages/YourHome/YourHomePage';
import HOConstructionPage from './pages/Construction/ConstructionPage';
import HODiscountPage from './pages/Discount/DiscountPage';
import HOQuotePage from './pages/Quote/QuotePage';
import HOAdditionalInformation from './pages/AdditionalInformation/AdditionalInformationPage';
import HOPolicyInformationPage from './pages/PolicyInformation/PolicyInformationPage';
import HOSuccessPage from './pages/Success/SuccessPage';

setComponentMapOverrides(
    {
        HOYourInfoPage: { component: 'HOYourInfoPage' },
        HOQualificationPage: { component: 'HOQualificationPage' },
        HOYourHomePage: { component: 'HOYourHomePage' },
        HOConstructionPage: { component: 'HOConstructionPage' },
        HODiscountPage: { component: 'HODiscountPage' },
        HOQuotePage: { component: 'HOQuotePage' },
        HOAdditionalInformation: { component: 'HOAdditionalInformation' },
        HOPolicyInformationPage: { component: 'HOPolicyInformationPage' },
        HOPaymentDetailsPage: { component: 'HOPaymentDetailsPage' },
        HOSuccessPage: { component: 'HOSuccessPage' }
    },
    {
        HOYourInfoPage,
        HOQualificationPage: QualificationPage,
        HOYourHomePage,
        HOConstructionPage,
        HODiscountPage,
        HOQuotePage,
        HOAdditionalInformation,
        HOPolicyInformationPage,
        HOPaymentDetailsPage: PaymentDetailsPage,
        HOSuccessPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as HOWizard } from './HOWizard';
