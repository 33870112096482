import React, { useRef } from 'react';
import _ from 'lodash';
import { CheckboxField, Icon, Tooltip } from '@jutro/components';

function WniImageCheckboxGroup(props) {
    const { icon, code, name, tooltip, value, disabled } = props;
    const elementRef = useRef(null);
    

    const renderCheckboxCard = () => {
        return (
            <div
                id={`imageCheckboxCardFor${code}`}
                className={`imageCheckboxCard ${value && 'checked'} ${disabled && 'disabled'}`}
            >
                <div className="imageCheckboxWrapper">
                    <Icon className="imageCheckboxIcon" icon={icon} />
                    <div className="imageCheckboxTitle">{name}</div>
                </div>
            </div>
        );
    };

    const checkboxFieldProps = _.omit(props, ['icon', 'code', 'name', 'tooltip']);
    if (tooltip) {
        return (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className="imageCheckboxContainer">
                <CheckboxField
                    id={`imageCheckboxCardFieldFor${code}`}
                    className="imageCheckboxCardField"
                    checkboxRef={elementRef}
                    {...checkboxFieldProps}
                />
                <Tooltip
                    id={`imageCheckboxTooltipFor${code}`}
                    content={tooltip}
                    placement='bottom'
                >
                    {renderCheckboxCard()}
                </Tooltip>
            </label>
        );
    }
    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className="imageCheckboxContainer">
            <CheckboxField
                id={`imageCheckboxCardFieldFor${code}`}
                className="imageCheckboxCardField"
                checkboxRef={elementRef}
                {...checkboxFieldProps}
            />
            {renderCheckboxCard()}
        </label>
    );
}

export default WniImageCheckboxGroup;
