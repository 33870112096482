/* eslint-disable max-len */
import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { ViewModelForm, withViewModelService, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ActivitiesService } from 'gw-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import {
    ExistingAccountMatchPage,
    CommercialAccountContact,
    PersonalAccountContact,
    AccountReferToUnderwriter
} from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { WindowUtil, ActivityUtil, WniAccountsUtil, WniProductsUtil, WniAddressUtil } from 'wni-portals-util-js';
import { useProductsData, useBusinessData } from 'wni-portals-util-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from 'gw-capability-gateway-react/NewQuote/NewQuoteAccountSearch.messages';
import metadata from './NewQuotePage.metadata.json5';
import currentMessages from './NewQuotePage.messages';

const {
    COMMERCIAL_ACCOUNT_CODE,
    PERSONAL_ACCOUNT_CODE
} = WniAccountsUtil;

const ACCOUNTHOLDER_DTO_PATH = "edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO";
const xCenter = 'pc';

// for PL: primaryPhoneType = 'mobile'
// for CL: primaryPhoneType = 'work
const DEFAULT_PL_ACCOUNT_VALUE = {
    isNewAccount_Ext: true,
    phoneRequired_Ext: true,
    contactType_Ext: PERSONAL_ACCOUNT_CODE,
    subType: PERSONAL_ACCOUNT_CODE,
    primaryPhoneType: 'mobile',
    industry_Ext: {},
    primaryAddress: {
        addressType: 'home',
        country: 'US'
    }
};

const DEFAULT_CL_ACCOUNT_VALUE = {
    isNewAccount_Ext: true,
    phoneRequired_Ext: true,
    contactType_Ext: COMMERCIAL_ACCOUNT_CODE,
    subType: COMMERCIAL_ACCOUNT_CODE,
    primaryPhoneType: 'work',
    industry_Ext: {},
    primaryAddress: {
        addressType: 'business',
        country: 'US'
    }
};
const SPECIAL_VALID_PRODUCTS_FOR_STATE = 'HOPHomeowners';

function NewQuotePage(props) {
    const modalApi = useWniModal();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { producerCodes_Ext : producerCodes = [],
        businessData_Ext: {
            industryCodes = [],
            enableCommercialLine
    } = {}} = authUserData;
    const { getProductsMap } = useProductsData();
    const productsMap = getProductsMap();
    const { getFeatureCodeValue } = useBusinessData();
    const enablePOI53227GoLiveFeatureForPE = getFeatureCodeValue('EnablePOI53227GoLiveFeatureForPE');

    const {
        interactionModel,
        loadingMask: { setLoadingMask }
    } = useDependencies(['interactionModel', 'loadingMask']);

    const history = useHistory();
    const location = useLocation();
    const matchParams = useParams();

    const { state = {}} = location;
    const {
        AOEffectiveDate,
        baseState,
        productSelectedList = []
    } = state
    const {
        accountNumber: urlAccountNumber,
    } = matchParams;

    const {
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        invalidFields
    } = useValidation('NewQuotePage');

    const [accountHolderVM, updateAccountHolderVM] = useState({});
    const [accountData, updateAccountData] = useState({});
    const [showErrors, updateShowErrors] = useState(false);
    // validation issues messages
    const [dynamicNotifications, updateDynamicNotifications] = useState([]);
    // account match page
    const [showAccountMatchData, updateShowAccountMatchData] = useState(false);
    const [accountMatchData, updateAccountMatchData] = useState({});
    // verify address 
    const [isAddressFlag, updateIsAddressFlag] = useState(false);
    const [addressVerifyResponse, updateAddressVerifyResponse] = useState({});

    // for PL, selected Products
    const [productSelected, updateProductSelected] = useState(productSelectedList);
    // for outside match, if true, update showBlockPage
    const [shouldBlock, updateShouldBlock] = useState(false);
    // for outside match, if true, show referToUnderwriter page
    const [showBlockPage, updateShowBlockPage] = useState(false);
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();

    // If selected products from AO include both CL and PL, locate on PL new account page
    // Only when all products in CL line, directs user to CL new account page
    // If not from AO, default on CL new account page
    const allSelectedProductsInCL = productSelectedList.isNil ? true : productSelectedList.every(elt => WniProductsUtil.isCLProduct(elt))

    const isCLAccount = (accountDTO) => {
        const contactType = _.get(accountDTO, 'value.contactType_Ext');
        return WniAccountsUtil.isCLAccount(contactType);
    };

    const initData = () => {
        setLoadingMask(true);
        let DEFAULT_ACCOUNT_VALUE
        if(!enableCommercialLine || !enablePOI53227GoLiveFeatureForPE) {
            DEFAULT_ACCOUNT_VALUE = DEFAULT_PL_ACCOUNT_VALUE;
        }else {
            DEFAULT_ACCOUNT_VALUE = allSelectedProductsInCL ? DEFAULT_CL_ACCOUNT_VALUE : DEFAULT_PL_ACCOUNT_VALUE ;
        }

        const viewModel = viewModelService.create(DEFAULT_ACCOUNT_VALUE, xCenter, ACCOUNTHOLDER_DTO_PATH);
        updateAccountHolderVM(viewModel);
        setLoadingMask(false);
    };

    useEffect(() => {
        initData()
    },[enableCommercialLine]);

    const handleValidation = () => {
        WindowUtil.scrollToInvalidField(invalidFields);
        updateShowErrors(true);
    };
    const filterPolicyWithAgency = (matchAccounts = []) => {
        const tempList = [];
        matchAccounts.forEach((clearance) => {
            const obj = _.find(producerCodes, (item) => {
                if(clearance.isLegacyAccount) {
                    return true;
                }
                if (!_.isEmpty(clearance.producerCodes)) {
                    const hasMatched = _.find(clearance.producerCodes, (matchCode) => {
                        return matchCode === item.code;
                    });
                    if (hasMatched) {
                        return true;
                    }
                }
                return false;
            });
            if (obj) {
                tempList.push(clearance);
            }
        });
        return tempList;
    };
    
    const generateNewAccount = (address) => {
        const newAccountHolder = viewModelService.clone(accountHolderVM);
        if(address) {
            _.set(newAccountHolder.value, 'primaryAddress', address);
            updateAccountHolderVM(newAccountHolder);
        }
        const accountHolderData = _.get(newAccountHolder, 'value', {});
        return {
            accountHolder: accountHolderData
        };
    };

    const validProductsComponent = useCallback(() => {
        const productKey = 'productSelectedValidation';
        const productValidations = {
            key: productKey,
            type: 'error',
            reason: translator(currentMessages.productEmptyMessage)
        };
        const validations = dynamicNotifications.filter((msg) => msg.key !== productKey);
        if (_.isEmpty(productSelected)) {
            updateDynamicNotifications([...validations, productValidations]);
            return false;
        }
        updateDynamicNotifications(validations);
        return true;
    }, [dynamicNotifications, productSelected, translator]);

    const createAccountService = async(newAccount, isBlock) => {
        setLoadingMask(true);
        const newAccountData = {
            ...newAccount,
            shouldBlock_Ext: isBlock
        }
        const newAccountResponse = await WniAccountService.getOrCreateAccount(newAccountData, authHeader);
        updateAccountData(newAccountResponse);
        updateShowAccountMatchData(false);
        setLoadingMask(false);
        return newAccountResponse;
    };

    const createNewAccount = async (newAccount, isBlock) => {
        const newAccountResponse = await createAccountService(newAccount, isBlock);
        const {
            accountNumber,
            accountHolder: {
                contactType_Ext: accountType
            } = {}
        } = newAccountResponse || {};

        if(!accountNumber){
            modalApi.showAlert({
                title: messages.accountTypeError,
                message: messages.newAccountCreateErrorMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                window.history.back();
            });
            return false;
        };
       
        const isCL = WniAccountsUtil.isCLAccount(accountType);
        if(isCL){
            return history.push({
                pathname: `/cl-account-quotes/${accountNumber}/policy-holder`,
                state: {
                    isNewAccount: true,
                    productSelected: productSelected,
                    AOEffectiveDate,
                    baseState,
                }
            });
        } 
        return history.push({
            pathname: `/account-quotes/${accountNumber}`,
            state: {
                isNewAccount: true,
                productSelected: productSelected,
                AOEffectiveDate,
                baseState,
            }
        });
    };

    const updateLookupValidation = (validations) => {
        const validationsMap = WniAddressUtil.getValidationMap(validations, dynamicNotifications);
        updateDynamicNotifications(validationsMap);
    };

    const writeValue = (value, path) => {
        /**   for Address Validation  */
        if (WniAddressUtil.AddressChangeVerify(path, 'primaryAddress')) { // when address filed change
            // address change, the warning message about invaild address set hide
            const verifyMsg = WniAddressUtil.getVerifyAddressIssues(false);
            updateLookupValidation(verifyMsg);
            // set the flag false, and click next button, verify address again
            updateIsAddressFlag(false);
        };
        
        const newAccountHolderVM = viewModelService.clone(accountHolderVM);
        const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
        if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
            _.set(newAccountHolderVM, path, undefined);
        } else {
            _.set(newAccountHolderVM, path, value);
        }
        updateAccountHolderVM(newAccountHolderVM); 
    };

    const specialValidForProduct = () => {
        const accountState = _.get(accountHolderVM, 'primaryAddress.value.state')
        const filterSpecialValidProduct = _.find(productsMap, (item) => item.code === SPECIAL_VALID_PRODUCTS_FOR_STATE );
        if(!filterSpecialValidProduct) {
            return true;
        }
        const specialValidProductRules = _.get(filterSpecialValidProduct, 'productRules');
        const specialValidAvailableStatesList = specialValidProductRules.filter((item) => (item.availability === 'Available'))
            .map((item) => {
                return item.state
            });
        const specialValidation = {
            type: 'Error',
            visible: true,
            reason: translator(currentMessages.homeSpecialValid)
        };
        if(!(specialValidAvailableStatesList.includes(accountState) || specialValidAvailableStatesList.includes(baseState))&& productSelected.includes(SPECIAL_VALID_PRODUCTS_FOR_STATE)) {
            _.set(specialValidation, 'visible', true);
            updateLookupValidation(specialValidation);
            return false
        }
        _.set(specialValidation, 'visible', false);
        updateLookupValidation(specialValidation);
        return true
        
    };

    const industryCodeValid = () => {
        const {
            industryCode_Ext: industryCode,
            industryDescription_Ext: industryDescription
        } = accountHolderVM.value;
        const findMatchData = industryCodes.filter((item) => item.code === industryCode && item.classification === industryDescription);
        const key = 'industryValidation';
        const industryValidations = {
            key: key,
            type: 'error',
            reason: translator(currentMessages.industryEmptyMessage)
        };
        const validations = dynamicNotifications.filter((msg) => msg.key !== key);
        if (_.isEmpty(findMatchData)) {
            updateDynamicNotifications([...validations, industryValidations]);
            return false;
        }
        updateDynamicNotifications(validations);
        return true;
    };

    const handleCancel = () => {
        modalApi.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.close
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            const accountType = _.get(accountHolderVM, 'value.contactType_Ext');
            if(accountType === PERSONAL_ACCOUNT_CODE) {
                return interactionModel.goToPage(accountType, history, 'accountsHomePage');
            }
            return window.history.back();
        }, _.noop);
    };

    const verifyAddress = async() => {
        let retval = addressVerifyResponse;
        if(!isAddressFlag) {
            const addressData = _.get(accountHolderVM.value, 'primaryAddress', {});
            const verifyAddressResult = await WniAddressUtil.onVerified(addressData, authHeader);
            updateIsAddressFlag(true);
            updateAddressVerifyResponse(verifyAddressResult);
            retval = verifyAddressResult
        }
        
        return retval;
    };

    const handleNext = async() => {
        /**  required field validtion */
        
        const isCL = isCLAccount(accountHolderVM);
        // if PL, need to check product selected, if CL, no need product
        const isVerfiyProduct = isCL ? true : validProductsComponent();
        if (!isComponentValid || !isVerfiyProduct) {
            handleValidation();
            return false;
        };
        if(isCL && !industryCodeValid()) {
            return false;
        }
        if(!specialValidForProduct()){
            return false;
        };
        
        const verifyAddressData = await verifyAddress();

        const {
            isVerified,
            addressValue,
            validationIssues = []
        } = verifyAddressData;
        
        if(!isVerified && !isAddressFlag) {
            updateDynamicNotifications(validationIssues);
            return false;
        };
        
        const newAccountHolderData = generateNewAccount(addressValue);

        /** Search Match or Create New Account */
        setLoadingMask(true);
        const newReserVationData = await WniAccountService.getReservationAccounts(newAccountHolderData, authHeader);
        const matchAccounts = filterPolicyWithAgency(newReserVationData.matchAccounts);
        const poMatchAccounts = filterPolicyWithAgency(newReserVationData.poMatchAccounts);
        await updateShouldBlock(newReserVationData.shouldBlock);
        setLoadingMask(false);
        
        if(newReserVationData.shouldBlock) {
            updateShowBlockPage(true);
            return false;
        };
        if (!_.isEmpty(matchAccounts) || !_.isEmpty(poMatchAccounts)) {
            const newAccountMatchData = {
                matchAccounts: matchAccounts,
                poMatchAccounts: poMatchAccounts
            };
            updateShowAccountMatchData(true);
            updateAccountMatchData(newAccountMatchData);
        } else {
            await createNewAccount(newAccountHolderData, newReserVationData.shouldBlock);
        }
        return true;
    };

    const handleContactTypeChange = (value, path) => {
        /**
         * change contacType, need disregardEfieldValidation for hide contactType details
         * contactType == person, show personalAcountContact
         * contactType == company, show commercialAcountContact
         */
        const newAccountHolderVM = viewModelService.clone(accountHolderVM);
        _.set(newAccountHolderVM, path, value);
        if(value === PERSONAL_ACCOUNT_CODE) {
            disregardFieldValidation(['commercialAcountContact']);
            _.set(newAccountHolderVM, 'value', DEFAULT_PL_ACCOUNT_VALUE);
        }else{
            disregardFieldValidation(['personalAcountContact']);
            _.set(newAccountHolderVM, 'value', DEFAULT_CL_ACCOUNT_VALUE);
        }
        updateAccountHolderVM(newAccountHolderVM);   
    };

    /**  For Account Matching Page --- start   */
    const onStartNewQuote = async (accountNumberParam, accountType) => {
        const activityObj = {
            accountNumber: accountNumberParam,
            subject: translator(currentMessages.activitySubject),
            description: translator(currentMessages.activityDescription),
            priority: 'urgent',
            mandatory: true,
            activityPattern: {
                code: 'agent_risk_reservation_referral',
                priority: 'urgent'
            }
        };
        setLoadingMask(true);
        const newActivityData = ActivityUtil.createNewActivityData(activityObj);
        await ActivitiesService.createNewActivity(newActivityData, null, authHeader);
        updateShowAccountMatchData(false);
        setLoadingMask(false);
        const isCL = WniAccountsUtil.isCLAccount(accountType);
        if(!isCL) {
            history.push({
                pathname: `/account-quotes/${accountNumberParam}`,
                state: {
                    productSelected: productSelected
                }
            });
            return false;
        }
        // CL
        history.push(`/cl-account-quotes/${accountNumberParam}/policy-holder`);
        
    };
    /**  For Account Matching Page --- end  */

    if(_.isEmpty(accountHolderVM)){
        return null;
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false
        },
        notification: {
            visible: enableCommercialLine
        },
        dynamicInlineNotificationContainer: {
            validationIssues: dynamicNotifications,
            visible: dynamicNotifications.length > 0,
            scrollToIssues: true,
        },
        newAccountContainer: {
            visible: !showAccountMatchData && !showBlockPage,
        },
        contactType: {
            readOnly: !enableCommercialLine || !enablePOI53227GoLiveFeatureForPE,
            availableValues: WniAccountsUtil.getAvailableAccounts(),
            onValueChange: handleContactTypeChange,
            value: _.get(accountHolderVM, 'value.contactType_Ext')
        },
        personalAcountContact: {
            visible: !isCLAccount(accountHolderVM),
            model: accountHolderVM,
            updateAccountHolderVM,
            writeValue,
            onValidate,
            showErrors,
            products: {
                productSelected,
                updateProductSelected,
                visible: true
            }
        },
        commercialAcountContact:{
            visible: isCLAccount(accountHolderVM),
            model: accountHolderVM,
            updateAccountHolderVM,
            writeValue,
            onValidate,
            showErrors
        },
        // existing account match component
        existingAccountMatch: {
            // control show existingAccountMatchPage show or not
            isVisible: showAccountMatchData && !showBlockPage,
            matchData: accountMatchData,
            accountType: _.get(accountHolderVM.value, 'contactType_Ext'),
            onStartNewQuote,
            // existingAccountMatchPage continue button and Previous button
            onContinueCreate: () => createNewAccount(generateNewAccount(), shouldBlock),
            onCancelButton: () => updateShowAccountMatchData(false)
        },
        accountReferrToUW: {
            pageVisible: showBlockPage,
            updatePageVisible: updateShowBlockPage,
            visible: showBlockPage,
            authHeader,
            createAccount: () => createAccountService(generateNewAccount(), shouldBlock)
        }
    };
    const resolvers = {
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            personalacountcontact: PersonalAccountContact,
            commercialaccountcontact: CommercialAccountContact,
            accountrefertounderwriter: AccountReferToUnderwriter,
            existingaccountpage: ExistingAccountMatchPage,
        },
        resolveCallbackMap: {
            handleCancel,
            handleNext
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            accountHolderVM,
            id,
            path,
            overrideProps
        );
    };

   
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={accountHolderVM}
            onModelChange={updateAccountHolderVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={onValidate}
            resolveValue={readValue}
            showErrors={showErrors}
        />
    );
}

export default withViewModelService(NewQuotePage);
