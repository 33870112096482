import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { WindowUtil } from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import metadata from './Deletion.metadata.json5';

function DeletionModal(props) {
    const {
        title,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        size,
        drivers
    } = props;
    const translator = useTranslator();

    const [getDriverVM, updateDriverVM] = useState(drivers);
    const [showErrors, updateShowErrors] = useState(false);
    const {
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('DriverPage');

    const writeValue = useCallback(
        (value, path) => {
            const newDriverVM = _.clone(getDriverVM);
            _.set(newDriverVM, path, value);
            updateDriverVM(newDriverVM);
        },
        [getDriverVM]
    );
    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const handleSave = useCallback(
        () => {
            if (!isComponentValid) {
                handleValidation();
                return false;
            }
            onResolve(getDriverVM);
        }, [getDriverVM, handleValidation, isComponentValid, onResolve]
    );

    const checkExplainationRequired = useCallback(() => {
        let valid = true;
        _.forEach(getDriverVM, (node) => {
            if (valid) {
                if (node.value.person.removePrefillReason_Ext === 'Other'
                    && _.isEmpty(node.value.person.explaination_Ext)) {
                    valid = false;
                }
            }
        });
        return valid;
    }, [getDriverVM]);

    const generateHeaderOverrides = useCallback(() => {
        const overrides = drivers.map((driver, index) => {
            const content = `Driver - ${driver.value.person.displayName}`;
            const removePrefillReason = driver.person.removePrefillReason_Ext
                .aspects.availableValues
                .map((option) => {
                    return { code: option.code, name: translator({ id: option.name }) };
                });
            return {
                [`driverName${index}`]: {
                    content,
                },
                [`removePrefillReason${index}`]: {
                    showRequired: true,
                    availableValues: removePrefillReason
                },
                [`explanationText${index}`]: {
                    showRequired: true,
                    required: _.get(getDriverVM, `${index}.value.person.removePrefillReason_Ext`) === 'Other'
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [drivers]);

    const overrideProps = {
        ...generateHeaderOverrides(drivers),
        '@field': {
            showOptional: false,
            showRequired: true
        },
        driverRemoveForm: {
            data: drivers
        },
        
    };

    const resolvers = {
        resolveCallbackMap: {

        }
    };

    useEffect(() => {
        registerComponentValidation(checkExplainationRequired);
    }, [checkExplainationRequired, registerComponentValidation]);

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="DeletionModal">
                <ViewModelForm
                    model={getDriverVM}
                    uiProps={metadata.componentContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    );
}

DeletionModal.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    DriverVM: PropTypes.shape({}).isRequired,
    size: PropTypes.string,
    drivers: PropTypes.shape({
        map: PropTypes.func
    }),
};

DeletionModal.defaultProps = {
    size: 'md',
    drivers: {
        map: _.noop
    }
};

export default DeletionModal;