import React, {useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWniModal, useFeatureToggle } from "wni-components-platform-react";
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import _, { update } from 'lodash';
import moment from 'moment';
import {
    CurrencyValue,
    Chevron,
    CurrencyField,
    Loader, 
    formatCurrency,
    formatDate,
} from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import appConfig from 'app-config';
import { PaymentComponent, Link } from 'gw-components-platform-react';
import { useTranslator, LocaleContext, IntlContext } from '@jutro/locale';
import { PaymentUtil, WniDateUtil } from 'wni-portals-util-js';
import { error } from '@jutro/logger';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { WniBillingAndPaymentService, WniGatewayBillingService } from 'wni-capability-gateway';
import { InvoiceCloudPopup } from 'gw-components-platform-react-ext';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import AutomaticPaymentPolicyAffectedValueIterableComponent
    from 'gw-capability-gateway-react/Accounts/BillingAndPayment/AutomaticPaymentPolicyAffectedValueIterableComponent';
import accountBillingStyles from 'gw-capability-gateway-react/Accounts/BillingAndPayment/BillingAndPayment.module.scss';
import { WniCLBillingSubmissionService, WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import messages from 'gw-capability-gateway-react/Accounts/Accounts.messages';
import makePaymentMessages from 'gw-capability-gateway-react/Accounts/BillingAndPayment/MakePayment/MakePayment.messages';
import PaymentSetup from '../PaymentSetup/PaymentSetup';
import metadata from './BillingAndPayment.metadata.json5';
import customMessages from './BillingAndPayment.messages';

function AccountBillingAndPayment(props) {

    const {
        fromAccountLanding: { 
            accountDetailsData, 
            accountDetailsData: { 
                accountNumber 
            }, 
            billingData: accountBillingData,
        },
        history: {
            location: {
                billingDataFromMakePayment 
            } 
        }
    } = props;
    const {history} = props;
    const [showInnerLoader, updateShowInnerLoader] = useState(false);
    const [isSetupPayment, updateIsSetupPayment] = useState(false);
    const [isSetupPaymentComplete, updateIsSetupPaymentComplete] = useState(false);
    const [isDetailsedScheduleOpen, updateIsDetailsedScheduleOpen] = useState(false);
    const [noBillingData, updateNoBillingData] = useState(false);
    const [xCenter, updateXCenter] = useState('');
    const [invoiceStreamNumber, updateInvoiceStreamNumber] = useState(null);
    const [expandInvioceItem, updateExpandInvioceItem] = useState(false);
    const [expandInvioceItems, updateExpandInvioceItems] = useState([]);
    const [expandPreInvioceItemIndex, updateExpandPreInvioceItemIndex] = useState(null);
    const [directBillInfo, updateDirectBillInfo] = useState({});
    const [directBillPolicies, updateDirectBillPolicies] = useState([]);
    const [paymentScheduleData, updatePaymentScheduleData] = useState([]);
    const [initAccountAgencyBillPolicies, updateInitAccountAgencyBillPolicies] = useState([]);
    const [responseDataAccountBilling, updateResponseDataAccountBilling] = useState({});
    const [primaryPayerAddress, updatePrimaryPayerAddress] = useState([]);
    const [directBillVisiblity, updateDirectBillVisiblity] = useState(false);
    const translator = useTranslator();
    const modalApi = useWniModal();
    const { authHeader, authUserData} = useAuthentication();
    const intl = useContext(IntlContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { loadingMask: { setLoadingMask }, interactionModel, domainCompany } = useDependencies(['loadingMask', 'interactionModel', 'domainCompany']);
    const localeService = ServiceManager.getService('locale-service');
    const featureToggle = useFeatureToggle();
    const enableInvoiceCloudForPE = featureToggle.getFeatureCodeValue('EnableInvoiceCloudForPE');
    
    const { routerBaseName } = appConfig;

    const isCL = _.get(accountDetailsData, 'accountHolder.contactType_Ext') !== 'person';

    const createVM = (model) => {
        return viewModelService.create(model,
            'bc', 'edge.capabilities.billing.dto.BillingInfoDTO');
    };
    
    const billingData = {
        accountDetailsPrimaryPayerAddress: primaryPayerAddress,
        getAccountDetails: accountDetailsData,
        getAccountBillingData: accountBillingData,
        getAccountBillingDetails: _.isEmpty(responseDataAccountBilling)
            ? { paymentGroups: [] }
            : responseDataAccountBilling,
        paymentGroup: {
            relatedPolicies: []
        },
        directBillInfo,
        directBillPolicies,
        paymentScheduleData,
        billingDataFromMakePayment,
        initAccountAgencyBillPolicies,
    };

    const getResponse = async () => {
        setLoadingMask(true);
        const responseDataAccountBillingSummary = await GatewayBillingService
            .getAccountBillingSummary(accountNumber, authHeader)
            .catch(() => {
                updateNoBillingData(true);
            });
        setLoadingMask(false);
        updateResponseDataAccountBilling(responseDataAccountBillingSummary);
        const model = {};
        const billingInfoVM = createVM(model);
        billingInfoVM.paymentGroups = !_.isEmpty(responseDataAccountBillingSummary)
            ? responseDataAccountBillingSummary.paymentGroups
            : [];
        const { _xCenter } = billingInfoVM;
        if (billingDataFromMakePayment) {
            // updateBillingData(billingDataFromMakePayment);
            updateXCenter(_xCenter);
        } else {
            let primaryPayerAddressData = _.get(accountDetailsData, 'accountBillingSummary.primaryPayer.address', []);
            if(!_.isEmpty(primaryPayerAddressData)){
                primaryPayerAddressData = _.get(accountDetailsData, 'accountBillingSummary.primaryPayer.address', []).split(', ');
            }
            primaryPayerAddressData.push(_.get(accountDetailsData, 'accountBillingSummary.primaryPayer.phoneNumber'));
            updatePrimaryPayerAddress(primaryPayerAddressData);
            updateXCenter(_xCenter);
        }
    };
    const getAccountAgencyBillPolicies = useCallback(async () => {
        setLoadingMask(true);
        const accountAgencyBillPolicies = await WniGatewayBillingService.getAccountAgencyBillPolicies(accountNumber, authHeader);
        setLoadingMask(false);
        updateInitAccountAgencyBillPolicies(accountAgencyBillPolicies);
    }, [accountNumber, authHeader]);

    useEffect(()=>{
        if(!_.isNil(accountNumber)){
            getResponse();
            getAccountAgencyBillPolicies(accountNumber);
        }
    },[accountNumber])

    const renderAccordionHeader = (isOpen) => {
        let accordionHeaderTitle = translator(messages.viewPaymentScheduleDetail);
        if (isOpen) {
            accordionHeaderTitle = translator(messages.hidePaymentScheduleDetail);
        }
        return (
            <React.Fragment>
                <Chevron isOpen={isOpen} align="left" className={accountBillingStyles.chevronStyle} />
                <h4 className={accountBillingStyles.accordionTitle}>{accordionHeaderTitle}</h4>
            </React.Fragment>
        );
    };

    const getAccountStatusIcon = (billingInfo) => {
        const accountBillingInfo = billingInfo.getAccountBillingData;
        let isDelinquent = false;
        if (!_.isEmpty(accountBillingInfo) && !_.isEmpty(accountBillingInfo.ownedPolicies)) {
            isDelinquent = (accountBillingInfo.ownedPolicies.length > 0
                && _.filter(accountBillingInfo.ownedPolicies, {
                    isDelinquent: true
                }).length > 0);
        }
        if (!_.isEmpty(accountBillingInfo.unownedPolicies)) {
            isDelinquent = (accountBillingInfo.unownedPolicies.length > 0
                && _.filter(accountBillingInfo.unownedPolicies, {
                    isDelinquent: true
                }).length > 0);
        }
        return isDelinquent ? 'exclamation-triangle' : 'thumb_up';
    };

    const getAccountStatusClassname = (billingInfo) => {
        if (!_.isEmpty(billingInfo.getAccountDetails.accountBillingSummary)) {
            if (_.get(billingInfo, 'getAccountDetails.accountBillingSummary.isDelinquent')) {
                return 'accountBillingIcon accountBillingDelinquent';
            }
        }
        return 'accountBillingIcon accountBillingGoodStanding';
    };

    const getAccountStatusText = (billingInfo) => {
        let statusText = translator(messages.inGoodStanding);
        if (!_.isEmpty(billingInfo.getAccountDetails.accountBillingSummary)) {
            if (_.get(billingInfo, 'getAccountDetails.accountBillingSummary.isDelinquent')) {
                statusText = translator(messages.delinquent);
            }
        }
        return statusText;
    };

    const generateSetupPaymentOverrides = () => {
        const relatedPoliciesPath = 'paymentGroup.relatedPolicies';
        const relatedPolicies = _.get(billingData, relatedPoliciesPath, []);

        const overrides = relatedPolicies.map((relatedPolicy, index) => {
            return {
                [`tabAutomaticPaymentsSetupInvoicePolicy${index}`]: {
                    value: <Link to={`/policies/${relatedPolicy}/summary`}>{relatedPolicy}</Link>
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    const handleSetupAutomaticPayment = (invoiceStreamIndex) => {
        const invoiceStream = billingData.getAccountBillingDetails
            .paymentGroups[invoiceStreamIndex];
        updateIsSetupPayment(true);
        updateShowInnerLoader(true);
        GatewayBillingService.getPaymentGroupPaymentInformation(
            accountNumber, invoiceStream.internalId, authHeader
        ).then(
            (responseData) => {
                billingData.paymentGroup = responseData;
                updateShowInnerLoader(false);
            }
        );
    };


    const getFirstUnpaidBillDueDate = (invoiceSummary, returnType) => {
        const unpaidStat = _.minBy(
            _.filter(invoiceSummary, (invoice) => { return invoice.invoiceStatus === 'planned'; }),
            (invoice) => {
                return new Date(invoice.dueDate).getTime();
            }
        );
        
        if (unpaidStat) {
            if (returnType === 'label') {
                return translator(messages.nextDueDate,
                    { firstUnpaidBillDueDate: WniDateUtil.formatDateWithPattern(new Date(unpaidStat.dueDate)) });
            }
            return unpaidStat.amountDue;
        }

        if (returnType === 'label') {
            return translator(messages.nextDue);
        }
        return ({ amount: 0, currency: localeService.getDefaultCurrencyCode() });
    };

    const aggregateBilling = (invoiceSummary) => {
        if (invoiceSummary.length > 0) {
            let totalAmountDue = 0;
            let earliestDue = new Date(invoiceSummary[0].dueDate);
            _.each(invoiceSummary, (invoice) => {
                totalAmountDue += invoice.amountDue.amount;
                const newDueDate = new Date(invoice.dueDate);
                if (newDueDate.getTime() < earliestDue.getTime()) {
                    earliestDue = newDueDate;
                }
            });

            return {
                amountDue: {
                    amount: totalAmountDue,
                    currency: invoiceSummary[0].amountDue.currency
                },
                dueDate: earliestDue
            };
        }
        return null;
    };

    const getOverdueStatEarliestDue = (invoiceSummary, returnType) => {
        const overdueStat = aggregateBilling(_.filter(invoiceSummary, (invoice) => { return invoice.invoiceStatus === 'due'; }));
        if (overdueStat) {
            if (returnType === 'label') {
                return translator(messages.pastDueDate,
                    { overdueStatEarliestDate: WniDateUtil.formatDateWithPattern(new Date(overdueStat.dueDate)) });
            }
            return overdueStat.amountDue;
        }

        if (returnType === 'label') {
            return translator(messages.pastDue);
        }
        return ({ amount: 0, currency: localeService.getDefaultCurrencyCode() });
    };

    const getInvoiceStreamTotalDue = (invoiceSummary) => {
        const overdueStat = aggregateBilling(_.filter(invoiceSummary, (invoice) => { return invoice.invoiceStatus === 'due'; }));
        const billedStat = aggregateBilling(_.filter(invoiceSummary, (invoice) => { return invoice.invoiceStatus === 'billed'; }));
        const amountDue = {
            amount: 0,
            currency: localeService.getDefaultCurrencyCode()
        };
        if (overdueStat && billedStat) {
            amountDue.amount = overdueStat.amountDue.amount + billedStat.amountDue.amount;
            amountDue.currency = overdueStat.amountDue.currency;
        } else if (overdueStat) {
            amountDue.amount = overdueStat.amountDue.amount;
            amountDue.currency = overdueStat.amountDue.currency;
        } else if (billedStat) {
            amountDue.amount = billedStat.amountDue.amount;
            amountDue.currency = billedStat.amountDue.currency;
        }
        return amountDue;
    };

    const generateAutomaticBillingOverrides = () => {
        const paymentGroupsPath = 'getAccountBillingDetails.paymentGroups';
        const paymentGroups = _.get(billingData, paymentGroupsPath, []);
        const invoiceSummaryPath = 'invoiceSummary';

        const overrides = paymentGroups.map((paymentGroup, index) => {
            const invoiceSummary = _.get(paymentGroup, invoiceSummaryPath);
            return {
                [`tabAutomaticHeaderTitle${index}`]: {
                    message: translator(messages.invoiceStream,
                        { periodicity: paymentGroup.displayName })
                },
                [`tabAutomaticPaymentsPaymentInstrumentSummaryText${index}`]: {
                    value: paymentGroup.paymentInstrumentSummary
                        ? translator(messages.automaticPaymentsEnabled)
                        : translator(messages.automaticPaymentsNotEnabled)
                },
                [`tabAutomaticPaymentsPaymentInstrumentSummaryIconCheck${index}`]: {
                    visible: _.has(paymentGroup, 'paymentInstrumentSummary')
                },
                [`tabAutomaticPaymentsPaymentInstrumentSummarySetup${index}`]: {
                    visible: true,
                    onClick: () => { handleSetupAutomaticPayment(index); }
                },
                [`tablePaymentScheduleDetail${index}`]: {
                    data: paymentGroup.invoiceSummary
                },
                [`tabAutomaticPaymentNextDue${index}`]: {
                    label: getFirstUnpaidBillDueDate(invoiceSummary, 'label'),
                    value: getFirstUnpaidBillDueDate(invoiceSummary, 'value')
                },
                [`tabAutomaticPaymentPastDue${index}`]: {
                    label: getOverdueStatEarliestDue(invoiceSummary, 'label'),
                    value: getOverdueStatEarliestDue(invoiceSummary, 'value')
                },
                [`tabAutomaticPaymentTotalDue${index}`]: {
                    value: getInvoiceStreamTotalDue(invoiceSummary)
                },
                [`accordionPaymentScheduleDetailsSection${index}`]: {
                    header: renderAccordionHeader
                },
                [`tabAutomaticPaymentPolicyAffectedValuesComponent${index}`]: {
                    data: {
                        paymentGroup: paymentGroup,
                        policySummaries: billingData.getAccountDetails.policySummaries
                    }
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    const getCell = (item, index, property) => {
        return item[property.id];
    };

    const getPolicyLink = (item, index, property) => {
        return <Link to={`/policies/${item[property.id]}/summary`}>{item[property.id]}</Link>;
    };

    const getAgencyBillLink = (item, index, property) => {
        return <Link to={`/policies/${item[property.path]}/billing`}>View Agency Bill</Link>;
    };

    const getFromattedDate = (item, index, property) => {
        return WniDateUtil.formatDateWithPattern(item[property.id], "MM/DD/YYYY");
    };

    const renderFormattedCurrencyField = (item) => {
        return (
            <div className={accountBillingStyles.currencyContainer}>
                <CurrencyField
                    id="currency"
                    value={item}
                    readOnly
                    hideLabel
                />
            </div>
        );
    };

    const getFormattedCurrency = (item, index, property) => {
        return renderFormattedCurrencyField(item[property.id]);
    };

    const getFormattedCurrencyPaymentSchedule = (item, index, property) => {
        const { amount, currency } = item.breakdown[property.id];
        return renderFormattedCurrencyField({ amount, currency });
    };

    const getFormattedCurrencyCharges = (item) => {
        const { charges, taxes } = item.breakdown;
        return (
            <CurrencyValue
                amount={charges.amount + taxes.amount}
                currency={charges.currency}
                showFractions
            />
        );
    };

    const getBillStatus = (item, index, property) => {
        return translator(messages[item[property.id]]);
    };

    const getAltBillingAccount = (item, index, property) => {
        return item[property.id] || '-';
    };

    const getIsDelinquent = (item, index, property) => {
        const isDelinquent = item[property.id];
        if (isDelinquent) {
            return translator(messages.delinquent);
        }
        return translator(messages.inGoodStanding);
    };

    const getSetupPaymentNextPayment = (invoiceSummary) => {
        const { amount, currency } = getFirstUnpaidBillDueDate(invoiceSummary, 'value');
        return `${translator(messages.setupPaymentConfirmNextPayment)} ${formatCurrency(amount, currency, LocaleContext)}`;
    };

    const getInvoiceStreamDetail = async()=>{
        setLoadingMask(true);
        const response = await WniBillingAndPaymentService.retriveInvoiceStreamDetail(accountNumber, invoiceStreamNumber, authHeader);
        setLoadingMask(false);
        updateDirectBillInfo(response.infoData);
        updateDirectBillPolicies(response.policyData);
        updatePaymentScheduleData(response.paymentScheduleData);
    };

    useEffect(()=>{
        const newInvoiceStreamNumber = _.get(responseDataAccountBilling, 'paymentGroups[0].displayName');
        updateInvoiceStreamNumber(newInvoiceStreamNumber);
        updateDirectBillVisiblity(!_.isEmpty(_.get(responseDataAccountBilling, 'paymentGroups', [])));
    },[responseDataAccountBilling])

    useEffect(()=>{
        if(!_.isNil(invoiceStreamNumber)){
            getInvoiceStreamDetail()
        }
    }, [invoiceStreamNumber])

    const handleBillAccountSelect = (value) => {
        updateInvoiceStreamNumber(value);
    };

    const handlePaymentScheduleRowClick = (rowData, index) => {
        updateExpandInvioceItems(rowData.invoiceItems);
        updateExpandPreInvioceItemIndex(index);
        if (expandInvioceItem && index !== expandPreInvioceItemIndex){
            updateExpandInvioceItem(expandInvioceItem);
        } else {
            updateExpandInvioceItem(!expandInvioceItem);
        }
         
    };

    
    const handleAgencySweep = useCallback(async (invoiceStreaCode, amount) => {
        setLoadingMask(true);
        let rs;
        if (isCL) {
            rs = await WniCLBillingSubmissionService.makeAgencySweepPayment([invoiceStreaCode, amount], authHeader);
        } else {
            rs = await WniGatewayBillingSubmissionService.makeAgencySweepPayment([invoiceStreaCode, amount], authHeader);
        }
        
        if (!rs) {
            modalApi.showAlert({
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(() => {
                _.noop();
            });
        }
        setLoadingMask(false);
    }, [authHeader, isCL, modalApi, setLoadingMask]);

    const showMakeAPaymentPopup = () => {
        const displayAgencySweep = _.get(billingData, 'directBillPolicies[0].registeredForAgencySweep', false);

        const invoices = _.get(billingData, 'paymentScheduleData', []);
        const totalDueInvoices = _.filter(invoices, (invoice) => _.get(invoice, 'statusForUI') === 'Due');
        let totalDueAmount = 0;
        _.each(totalDueInvoices, (invoice) => {
            totalDueAmount += _.get(invoice, 'due.amount') * 100;
        });
        totalDueAmount /= 100;

        const modalProps = {
            title: translator(customMessages.paymentSetupTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: customMessages.paymentSetupPayNow,
            cancelBtnLabel: customMessages.paymentSetupCancel,
            authHeader,
            minimumDueAmount: _.get(billingData, 'directBillInfo.minimumDueAmount'),
            invoiceStreamNumber,
            displayAgencySweep,
            domainCompany,
            totalDueAmount: { amount: totalDueAmount, currency: 'usd' },
            minimumDueWithFullPayDiscountAmount: _.get(billingData, 'directBillInfo.fullPayAmount'),
            totalBalance: _.get(billingData, 'directBillInfo.totalBalance')
        }
        modalApi.showModal(<PaymentSetup {...modalProps} />)
            .then(async (res) => {
                // validate and issue policies
                const isInvoiceCloud = _.get(res, 'isInvoiceCloud');
                const requestParam = _.get(res, 'requestParam');
                if (isInvoiceCloud) {
                    try {
                        // update request params
                        const invoiceCloudBaseUrl = PaymentUtil.getInvoiceCloudBaseUrl();
                        const baseUrl = `${invoiceCloudBaseUrl}/-/vendors/ic/pay-invoice`;
                        // const icUrl = `${baseUrl}?accountNum=${accountNumber}`
                        const referenceNumber = _.get(requestParam, 'reference');
                        const paymentDue = _.get(requestParam, 'amtValue.amount');
                        const paymentType = _.get(requestParam, 'paymentType');
                        
                        // const invoiceStreamDescription = _.get(requestParam, 'invoiceStreamDescription');;
                        // let icUrl
                        // if(invoiceStreamDescription!== undefined){
                        //     icUrl = `${baseUrl}?accountNum=BC$${invoiceStreamDescription}&referenceNum=${referenceNumber}&paymentDue=${paymentDue}&paymentType=${paymentType}`;
                        // }else{
                        //     const invoiceTypeId = PaymentUtil.getinvoiceTypeId(isCL, domainCompany.code === "WNI")
                        //     icUrl = `${baseUrl}?accountNum=BC$downpayment&referenceNum=${referenceNumber}&invoiceTypeId=${invoiceTypeId}&paymentDue=${paymentDue}&paymentType=${paymentType}`;
                        // }

                        // use bill accountNumber as referenceID
                        const icUrl = `${baseUrl}?accountNum=BC$${invoiceStreamNumber}&referenceNum=${referenceNumber}&paymentDue=${paymentDue}&paymentType=${paymentType}`;
                        const componentProps = {
                            icTitle: 'IC CloudSSO - Manage Payment Methods',
                            icUrl: icUrl
                        };
                        modalApi.showModal(<InvoiceCloudPopup {...componentProps} />).then(async () => {
                            // do nothing
                        }, _.noop);
                    } catch (ex) {
                        error(ex);
                        PaymentUtil.showBCCFailPopup(
                            modalApi, commonMessages.genericError,
                            messages.bccErrorMessage, commonMessages.ok
                        );
                        return false;
                    }
                } else {
                    // agency sweep
                    const otherSelected = _.get(res, 'otherSelected');
                    const otherValue = _.get(res, 'otherValue');
                    const amount = otherSelected ? otherValue : _.get(res, 'agencySweepValue');
                    await handleAgencySweep(invoiceStreamNumber, amount);
                }
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    }

    const handleMakeAPayment = () => {
        if (enableInvoiceCloudForPE) {
            showMakeAPaymentPopup();
        } else {
            const url = interactionModel.getURLObj(null, 'accountBilling', accountNumber);
            window.location.href = _.get(url, 'href') || _.get(url, 'to');
        }
    }

    const handleCancelSetupPayment = () => {
        updateIsSetupPayment(false);
        updateShowInnerLoader(false);
    };

    const handleReturnSetupPayment = () => {
        updateShowInnerLoader(true);
        GatewayBillingService.getAccountBillingSummary(accountNumber, authHeader)
            .then((response) => {
                billingData.getAccountBillingDetails = _.isEmpty(response)
                    ? { paymentGroups: [] }
                    : response;
                updateIsSetupPayment(false);
                updateIsSetupPaymentComplete(false);
                updateShowInnerLoader(false);
            });
    };

    const handleRemoveAutoPayment = () => {
        const invoiceStreamInternalId = billingData.paymentGroup.internalId;
        updateShowInnerLoader(true);
        WniBillingAndPaymentService
            .removeAutoPayment(accountNumber, invoiceStreamInternalId, authHeader)
            .then(() => {
                updateIsSetupPayment(false);
                updateIsSetupPaymentComplete(false);
                updateShowInnerLoader(false);
                modalApi.showAlert({
                    title: customMessages.autoPayRemoved,
                    message: '',
                    status: 'info',
                    icon: 'gw-error-outline',
                    confirmButtonText: customMessages.ok
                });
            }).catch(() => {
                _.noop();
            });
    };

    const handleConfirmSetupPayment = (paymentData, paymentMethod) => {
        const invoiceStreamInternalId = billingData.paymentGroup.internalId;

        const paymentInstrument = {};

        if (paymentMethod === 'wire') {
            const bankAccountData = {
                bankAccountType_Ext: _.get(paymentData, 'bankAccountType_Ext.value.code'),
                bankAccountNumber: _.get(paymentData, 'bankAccountNumber.value'),
                bankABANumber: _.get(paymentData, 'bankABANumber.value'),
                bankName: _.get(paymentData, 'bankName.value'),
                effectiveDate_Ext: moment(_.get(paymentData, 'effectiveDate_Ext.value')).toDate()
            };

            paymentInstrument.paymentMethod = paymentMethod;
            paymentInstrument.bankAccountData = bankAccountData;
            paymentInstrument.creditCardData = null;
        } else {
            const creditBankAccountData = {
                creditCardIssuer: _.get(paymentData, 'creditCardIssuer.value.code'),
                creditCardNumber: _.get(paymentData, 'creditCardNumber.value'),
                creditCardExpDate: _.get(paymentData, 'creditCardExpDate.value'),
            };

            paymentInstrument.paymentMethod = 'creditcard';
            paymentInstrument.creditCardData = creditBankAccountData;
            paymentInstrument.bankAccountData = null;
        }
        updateShowInnerLoader(true);
        GatewayBillingService.setPaymentGroupPaymentInformation(
            accountNumber, invoiceStreamInternalId, paymentInstrument, authHeader
        ).then(() => {
            updateIsSetupPayment(false);
            updateIsSetupPaymentComplete(true);
            updateShowInnerLoader(false);
        }).catch(() => {
            modalApi.showAlert({
                title: messages.setupPaymentFailed,
                message: messages.setupPaymentFailedSorry,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: customMessages.ok
            });
        });
    };

    const getAutomaticPaymentScheduleTitle = () => {
        const paymentScheduleTitle = translator(messages.setupAutomaticPaymentSchedule,
            { displayName: billingData.paymentGroup.displayName });
        return paymentScheduleTitle;
    };

    const getAutomaticPaymentScheduleMessage = () => {
        const { paymentGroup: { displayName = '' } } = billingData;
        const displayValue = displayName.replace(/[\d()]/g, '').trim();
        let formattedDate = billingData.paymentGroup.scheduleTerms
            ? billingData.paymentGroup.scheduleTerms[0].startDate
            : '';
        formattedDate = WniDateUtil.formatDateWithPattern(new Date(formattedDate.split('T')[0]));
        const paymentScheduleMessage = (
            <>
                <span>
                    {translator(messages.scheduleTermTitle)}
                    &nbsp;
                </span>
                <strong>
                    {
                        translator(messages.scheduleTermDescription,
                            { periodicity: displayValue, startDate: formattedDate })
                    }
                </strong>
            </>
        );

        return paymentScheduleMessage;
    };

    const getAutomaticPaymentScheduleDueDate = () => {
        const formattedDate = billingData.paymentGroup.scheduleTerms
            ? billingData.paymentGroup.scheduleTerms[0].startDate
            : '';
        return `${translator(customMessages.setupPaymentConfirmDue)} ${WniDateUtil.formatDateWithPattern(new Date(formattedDate.split('T')[0]))}`;
    };

    const getPaymentConfirmPhone = () => {
        return translator(customMessages.setupPaymentConfirmForm);
    };

    const getTotalPremium = () => {
        let totalPremium = 0;
        _.each(billingData.paymentGroup.invoiceSummary, (invoice) => {
            totalPremium += invoice.amountDue.amount;
        });
        return totalPremium;
    };

    const handleTogglePaymentSchedule = () => {
        updateIsDetailsedScheduleOpen(!isDetailsedScheduleOpen);
    };

    const getMakeAPaymentButtonVisible = useCallback(() => {
        // minimumDue
        const minimumDue = _.get(billingData, 'directBillInfo.minimumDueAmount');
        // totalBalance
        const totalBalance = _.get(billingData, 'directBillInfo.totalBalance');
        // do not show when minimumDue and totalBalance is 0
        const notShowButtonCheck = _.get(minimumDue, 'amount', undefined) === 0 && _.get(totalBalance, 'amount', undefined) === 0
        // return visible value
        return !notShowButtonCheck;
    }, [billingData]);

    const render = () => {
        if (_.isEmpty(billingData)) {
            return true;
        }
        const minimumDueDate = _.get(billingData, 'directBillInfo.minimumDue');
        let minimumDueDateUI;
        if (!_.isNil(minimumDueDate)) {minimumDueDateUI = WniDateUtil.formatDateWithPattern(minimumDueDate, "MMMM Do");}
        const overrideProps = {
            billingPageAccountStatusIcon: {
                icon: getAccountStatusIcon(billingData),
                className: getAccountStatusClassname(billingData)
            },
            billingPageAccountStatus: {
                value: getAccountStatusText(billingData)
            },
            tabBillingMakeAPayment: {
                visible: getMakeAPaymentButtonVisible(),
                    // && _.get(billingData, 'getAccountBillingData.totalDue.amount') > 0,
                onClick: handleMakeAPayment
            },
            agencyBillPolicyCard: {
                visible: _.get(accountDetailsData, 'accountHolder.contactType_Ext') !== 'person' ? !_.isEmpty(billingData.initAccountAgencyBillPolicies) : false
            },
            agencyBillPolicyTable: {
                data: initAccountAgencyBillPolicies
            },
            tabdirectBillHeaderEditBilling: {
                onClick: () => {
                    window.location.href = `${window.location.origin}/${routerBaseName}/agentsonline/external-billing/${accountNumber}`;
                }
            },
            tabDirectBillingAllSectionContent: {
                visible: directBillVisiblity,
            },
            tabdirectBillHeaderBillAccountDropdown: {
                alwaysShowPlaceholder: false,
                availableValues: billingData.getAccountBillingDetails.paymentGroups.map(({displayName}) => {return {"code" : displayName, "name":  `Bill Account(${displayName})`}}),
                onValueChange: handleBillAccountSelect,
                value: invoiceStreamNumber,
            },
            billingPageBankAccountDetails: {
                visible: (_.get(billingData, 'directBillInfo.overrideDefaultPaymentMethod') || _.get(billingData, 'directBillInfo.defaultPaymentMethod')) === 'AutoPay'
            },
            tabBillingSummaryMinimumDue: {
                label: {
                    defaultMessage: minimumDueDateUI ? `Minimum Due (on ${minimumDueDateUI})` : 'Minimum Due'
                }
            },
            tabBillingSummaryCollateralRequirement: {
                visible: _.get(accountDetailsData, 'accountHolder.contactType_Ext') !== 'person'
            },
            tabBillingSummaryCollateralHeld: {
                visible: _.get(accountDetailsData, 'accountHolder.contactType_Ext') !== 'person'
            },
            tabBillingSummaryHelpText: {
                visible: enableInvoiceCloudForPE,
                content: translator(customMessages.paymentHelpText),
                readOnly: true
            },
            tabBillingPoliciesOwnedTable: {
                data: _.get(billingData, 'directBillPolicies', [])
            },
            tabBillingPaymentScheduleTable:{
                data: _.get(billingData, 'paymentScheduleData', [])
            },
            tabAccountBillingInvoiceItemsTable: {
                visible: expandInvioceItem,
            },
            tabBillingInvoiceItemsTable: {
                data: expandInvioceItems
            },
            tabBillingPoliciesUnownedTable: {
                // data: billingData.getAccountBillingData.unownedPolicies
            },
            tabAutomaticPaymentsContent: {
                visible: !isSetupPayment && !isSetupPaymentComplete && !noBillingData,
                data: _.get(billingData, 'getAccountBillingDetails.paymentGroups', [])
            },
            setupAutomaticPaymenetsSetup: {
                visible: isSetupPayment && !showInnerLoader
            },
            setupAutomaticPaymentsSuccess: {
                visible: isSetupPaymentComplete && !showInnerLoader
            },
            setupPaymentLoader: {
                visible: showInnerLoader,
                showLoader: showInnerLoader
            },
            tabAutomaticPaymentsScheduleTitle: {
                message: getAutomaticPaymentScheduleTitle()
            },
            paymentScheduleMessageLabel: {
                content: getAutomaticPaymentScheduleMessage()
            },
            paymentSchedulePremium: {
                value: getTotalPremium()
            },
            paymentScheduleDetailToggle: {
                icon: isDetailsedScheduleOpen ? 'caret-up' : 'caret-down',
                message: isDetailsedScheduleOpen
                    ? translator(messages.hideDetailedSchedule)
                    : translator(messages.showDetailedSchedule)
            },
            detailedScheduleContainer: {
                visible: isDetailsedScheduleOpen
            },
            detailedScheduleTable: {
                data: _.get(billingData, 'paymentGroup.invoiceSummary', [])
            },
            paymentSetupSuccessNextPayment: {
                value: getSetupPaymentNextPayment(billingData.paymentGroup.invoiceSummary)
            },
            paymentSetupSuccessDue: {
                value: getAutomaticPaymentScheduleDueDate()
            },
            paymentSetupSuccessPhone: {
                message: getPaymentConfirmPhone()
            },
            noBillingData: {
                visible: noBillingData
            },
            directBillPolicyCard: {
                visible: !noBillingData
            },
            // override some fields
            billingPageInfoDueDate: {
                value: _.get(billingData, 'directBillInfo.overrideDayofMonth.day') || _.get(billingData, 'directBillInfo.defaultDayOfMonth.day')
            },
            billingPagePaymentMethod: {
                value: _.get(billingData, 'directBillInfo.overrideDefaultPaymentMethod') || _.get(billingData, 'directBillInfo.defaultPaymentMethod')
            },
            paymentPageComponent: {
                xCenter: xCenter,
                isSetupPayment: isSetupPayment,
                paymentGroup: billingData.paymentGroup,
                handleRemoveAutoPayment: handleRemoveAutoPayment,
                nextLabel: isSetupPayment ? translator(messages.setupPaymentConfirm)
                    : translator(makePaymentMessages.payNowLabel)
            },
            ...generateAutomaticBillingOverrides(),
            ...generateSetupPaymentOverrides()
        };

        const resolvers = {
            resolveClassNameMap: accountBillingStyles,
            resolveCallbackMap: {
                getCell,
                getPolicyLink,
                getAgencyBillLink,
                getFromattedDate,
                getFormattedCurrency,
                getAltBillingAccount,
                getIsDelinquent,
                getBillStatus,
                getFormattedCurrencyPaymentSchedule,
                handleMakeAPayment,
                handleTogglePaymentSchedule,
                getFormattedCurrencyCharges,
                handleCancelSetupPayment,
                handleConfirmSetupPayment,
                handleReturnSetupPayment,
                onCancelPayment: handleCancelSetupPayment,
                onRowClick: (value, index) => handlePaymentScheduleRowClick(value, index),
            },
            resolveComponentMap: {
                loader: Loader,
                paymentpagecomponent: PaymentComponent,
                automaticpaymentpolicyaffectedvalueiterablecomponent:
                    AutomaticPaymentPolicyAffectedValueIterableComponent
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                billingData,
                id,
                path,
                overrideProps
            );
        };

        return (
            <div className={accountBillingStyles.accountbilling}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={billingData}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </div>
        );
    };

    return render();

}
AccountBillingAndPayment.propTypes = {
    fromAccountLanding: PropTypes.shape({
        accountDetailsData: PropTypes.shape({
            accountNumber: PropTypes.string
        }),
        billingData: PropTypes.shape({})
    }).isRequired,
};

export default withRouter(AccountBillingAndPayment);
