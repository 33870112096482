import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button
} from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
// import { useValidation } from '@xengage/gw-portals-validation-react';
// import { WNIAddressLookupService } from 'wni-capability-address';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './RecommendedAddress.metadata.json5';

function RecommendedAddressModal(props) {
    const {
        suggestedAddress,
        originalAddress,
        close,
        ok
    } = props;

    const [selectedValue, updateSelectedValue] = useState(undefined);
    const [originalValue, updateoriginalValue] = useState(undefined);

    const generateAvailableValues = (addressList) => {
        const availableValues = addressList ? addressList.map((item) => {
            return {
                code: `${item.addressLine1 ? item.addressLine1 : item.pobox_Ext}, ${item.city}, ${item.county}, ${item.state}, ${item.postalCode}`,
                name: `${item.addressLine1 ? item.addressLine1 : item.pobox_Ext}, ${item.city}, ${item.county}, ${item.state}, ${item.postalCode}`,
                ...item
            };
        }) : [];
        return availableValues;
    };

    useEffect(() => {
        const defaultValue = generateAvailableValues([originalAddress])[0];
        updateoriginalValue(defaultValue);
    }, []);
    const onValueChange = (value, path) => {
        if (path === 'suggested') {
            updateSelectedValue(value);
            updateoriginalValue(undefined);
            return false;
        }
        updateSelectedValue(undefined);
        updateoriginalValue(value);
        return true;
    };

    const handleSave = () => {
        const selectData = selectedValue || originalAddress;
        ok(selectData);
    };

    const overrideProps = {
        originalAddress: {
            availableValues: generateAvailableValues([originalAddress]),
            value: originalValue,
            onValueChange: onValueChange
        },
        suggestedAddress: {
            availableValues: generateAvailableValues(suggestedAddress),
            value: selectedValue,
            onValueChange: onValueChange
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            suggestedAddress,
            id,
            path,
            overrideProps
        );
    };
    const resolvers = {
        resolveCallbackMap: {
        }
    };
    return (
        <div>
            <ViewModelForm
                model={suggestedAddress}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
            />
            <div className="align-right mt-20">
                <Button onClick={close} type="outlined">Cancel</Button>
                <Button onClick={handleSave} type="filled">Ok</Button>
            </div>
        </div>
    );
}

RecommendedAddressModal.propTypes = {
    suggestedAddress: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    originalAddress: PropTypes.shape({}).isRequired,
    close: PropTypes.func.isRequired,
    ok: PropTypes.func.isRequired
};
RecommendedAddressModal.defaultProps = {
};

export default RecommendedAddressModal;
