import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Chevron, IconButton } from '@jutro/components';
import { Flex, FlexItem } from '@jutro/layout';
import styles from './BuildingHeaderComponent.module.scss';

class BuildingHeaderComponent extends Component {
    /**
     * @memberof gw-components-platform-react.BuildingHeaderComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.buildingName - name of building
     * @prop {string} propTypes.path - path of building
     * @prop {func} propTypes.onEditBuilding - callback to edit building
     * @prop {bool} propTypes.isAccordionOpen - is accordion open
     * @prop {bool} propTypes.isEditable - is it editable
     * @prop {func} propTypes.onRemoveBuilding - remove building callback
     */

    static propTypes = {
        id: PropTypes.string,
        onEditBuilding: PropTypes.func,
        buildingName: PropTypes.string,
        path: PropTypes.string.isRequired,
        isAccordionOpen: PropTypes.bool,
        isEditable: PropTypes.bool,
        onRemoveBuilding: PropTypes.func
    };

    static defaultProps = {
        id: undefined,
        isAccordionOpen: false,
        isEditable: true,
        buildingName: undefined,
        onEditBuilding: undefined,
        onRemoveBuilding: undefined
    };

    render() {
        const {
            id,
            path,
            buildingName,
            onEditBuilding,
            isAccordionOpen,
            isEditable,
            onRemoveBuilding
        } = this.props;

        return (
            <Flex
                alignItems="center"
                gap="medium"
                id="Flex"
                justifyContent="between"
            >
                <FlexItem
                    className={styles.buildingDetails}
                    grow={0}
                    shrink={0}
                    textAlign="left"
                >
                    <div className={styles.chevronContainer}>
                        <Chevron isOpen={isAccordionOpen} />
                    </div>
                    <IconButton
                        icon="mi-apartment"
                        iconColor="dark"
                        id="IconButton"
                    />
                    <span id="buildingName">{buildingName}</span>
                </FlexItem>
                {isEditable && isAccordionOpen ? (
                    <FlexItem
                        className={styles.buildingActions}
                        grow={0}
                        shrink={0}
                        textAlign="right"
                    >
                        <>
                            <Button
                                id={`${id}_editBuilding`}
                                className="actionIcon"
                                onClick={onEditBuilding}
                                path={path}
                                icon="mi-edit"
                                type="text"
                            />
                            <Button
                                id={`${id}_removeBuilding`}
                                className="actionIcon"
                                onClick={onRemoveBuilding}
                                path={path}
                                icon="mi-delete"
                                type="text"
                            />
                        </>

                    </FlexItem>
                ) : null}
            </Flex>
        );
    }
}

export default BuildingHeaderComponent;
