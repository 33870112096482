import React from 'react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './OverwrittenModal.metadata.json5';

function OverwrittenModal(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        data,
        diffType,
        useDependenciesData
    } = props;
   
    const renderSortNumber = (item, index) => {
        return `${index + 1}.`
    }
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true
        },
        priceDigestOverWriteEnter: {
            data: data,
            visible: diffType === 'vou'
        },
        enterOverwritePriceDigest: {
            data: data,
            visible: diffType === 'uov'
        }

    };
    const resolvers = {
        resolveCallbackMap: {
            renderSortNumber
        },
        resolveComponentMap: {
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="overwrittenModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

OverwrittenModal.propTypes = {
   
};
OverwrittenModal.defaultProps = {
    size: 'md'
};
export default OverwrittenModal;
