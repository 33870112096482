import { AccordionCard } from '@jutro/components';
import React, { useCallback, useEffect } from 'react';
import _ from 'lodash'
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { GLCoverageService } from 'wni-capability-quoteandbind-gl';
import CoverageContext from '../context/GLCoverageContext';
import GLCoverageUtil from '../util/GLCoverageUtil';
import GLClausesComponentVM from '../components/GLClausesComponentVM/GLClausesComponentVM';
import GLSingleClauseComponentVM from '../components/GLSingleClauseComponentVM/GLSingleClauseComponentVM';

const GLSingleSublineAdditionalInsuredAccordionCard = (props) => {
    const {
        sublineClauses,
        setSublineClauses,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: multiSublinesOnValidate,
        setUnmannedAircraftAvailable,
        loadingClauses,
        setLoadingClauses,
        peReferredUWIssue,
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { publicID, sublineName, additionalInsureds } = sublineClauses;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const validationID = `SingleSublineAdditionalInsuredCoverage_${publicID}`

    const {
        onValidate,
        isComponentValid,
    } = useValidation(validationID)

    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (multiSublinesOnValidate) {
            multiSublinesOnValidate(isComponentValid, validationID);
        }
        return () => {
            if (multiSublinesOnValidate) {
                multiSublinesOnValidate(true, validationID);
            }
        }
    }, [isComponentValid, multiSublinesOnValidate, validationID])

    const changeSubmission = useCallback(
        (value, changedPath) => {
            const newClauses = GLCoverageUtil.setClauseValue(additionalInsureds, 'additionalInsureds', value, changedPath);
            const newSublineClauses = _.clone(sublineClauses)
            _.set(newSublineClauses, 'additionalInsureds', newClauses);
            setSublineClauses(newSublineClauses);
            setIsEditing(false);
            return newSublineClauses;
        },
        [additionalInsureds, setIsEditing, setSublineClauses, sublineClauses]
    );

    const updateVMToServer = useCallback(async (newSublineClauses, setLoadingClausesFunc, setEditingFunc) => {
        const subLineClausesToUpdate = GLCoverageUtil.generateUpdatedSingleSublineClausesDTO(newSublineClauses);
        const updatedSublineClausesPublicIDs = GLCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedSublineClausesDTO(subLineClausesToUpdate);
        setLoadingClausesFunc(updatedSublineClausesPublicIDs)
        setLoadingMask(true)
        const response = await GLCoverageService.updateSingleSublineClauses(
            jobID,
            sessionUUID,
            subLineClausesToUpdate,
            authHeader
        )
        const sublineClausesFromServer = _.get(response, 'gl7SingleSublineClauses')
        const isUnmannedAircraftAvailable = _.get(response , 'isUnmannedAircraftAvailable')
        setLoadingClausesFunc(false)
        setLoadingMask(false)
        setEditingFunc(false)
        setSublineClauses(sublineClausesFromServer);
        setUnmannedAircraftAvailable(isUnmannedAircraftAvailable)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setSublineClauses, setUnmannedAircraftAvailable]);

    const updateNewSubLineClausesToServer = useCallback(async (newSublineClauses) => {
        updateVMToServer(newSublineClauses, setLoadingClauses, setIsEditing)
    }, [updateVMToServer, setLoadingClauses, setIsEditing]);



    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            const newSublineClauses = changeSubmission(value, changedPath);
            
            updateNewSubLineClausesToServer(newSublineClauses);
        },
        [changeSubmission, updateNewSubLineClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewSubLineClausesToServer(sublineClauses);
        },
        [sublineClauses, updateNewSubLineClausesToServer]
    )

    return (
        <AccordionCard
            id={`${publicID}_AdditionalInsured`}
            chevron
            className="coveragesAccordion"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={sublineName}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardData,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <GLClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        loadingClause={loadingClauses}
                        path="additionalInsureds"
                        value={additionalInsureds}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            GLSingleClauseComponentVM: GLSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        peReferredUWIssue={peReferredUWIssue}
                        hideCheckBoxForRequired
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default GLSingleSublineAdditionalInsuredAccordionCard;
