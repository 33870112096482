import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ServiceManager } from '@jutro/services';
import { StateUtil, WindowUtil } from 'wni-portals-util-js';
import { withModalContext } from '@jutro/components';
import messages from 'gw-capability-gateway-react/NewQuote/NewQuoteAccountSearch.messages';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import styles from './NewQuoteAccountSearch.module.scss';


const ACCOUNT_TYPE = {
    commercial: 'company',
    personal: 'person'
};

class NewQuoteAccountSearchWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    localeService = ServiceManager.getService('locale-service');

    state = {
        submissionVM: {},
        showErrors: false
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                prevLocation: PropTypes.object
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        interactionModel: PropTypes.shape({ goToPage: PropTypes.func }).isRequired,
        ...validationPropTypes
    }

    searchAccounts = async () => {
        const { submissionVM: { value } } = this.state;
        const {
            history, location: {
                search
            }
        } = this.props;
        history.push(`/new-quote-account-search/matches${search}`, value);
    }

    handleValidation=() => {
        const { invalidFields } = this.props;
        WindowUtil.scrollToInvalidField(invalidFields);
        this.setState({ showErrors: true });
    }

    componentDidMount = () => {
        let { submissionVM } = this.state;
        const model = {
            // contactType: ACCOUNT_TYPE.commercial,
            contactType: ACCOUNT_TYPE.personal,
            country: 'US'
        };
        submissionVM = this.createVM(model);
        this.setState({
            submissionVM
        });
    }

    createVM = (model) => {
        const { viewModelService } = this.props;
        return viewModelService.create(model,
            'pc', 'edge.capabilities.gateway.account.search.dto.AccountSearchCriteriaDTO');
    };

    writeValue = (value, path) => {
        const { submissionVM } = this.state;
        _.set(submissionVM, path, value);
        this.setState({ submissionVM });
    };

    handleNewQuoteCancel=() => {
        const { location: { state }, history, interactionModel } = this.props;
        if (state && !state.prevLocation) {
            history.goBack();
        } else {
            interactionModel.goToPage(null, history, 'homePage');
        }
    };

    handleCancel=() => {
        this.props.modalContext.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.close
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            this.handleNewQuoteCancel();
        }, _.noop);
    };

    newCustomerBtnClick=() => {
        const {
            history,
            match: {
                params
            }
        } = this.props;
        const { submissionVM } = this.state;
        const nextLocation = {
            accountSearchCriteria: _.get(submissionVM, 'value'),
            externalData: params
        };
        history.push('/new-quote', nextLocation);
    };

    render() {
        const {
            submissionVM,
            showErrors
        } = this.state;
        const { setComponentValidation, isComponentValid } = this.props;
        const accountType = _.get(submissionVM, `${'contactType'}.value.code`);
        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showOptional: false,
                showRequired: true,
                showErrors: showErrors,
            },
            accountTypeToggle: {
                disabled: true,
                value: accountType
            },
            companyDiv: {
                visible: accountType === ACCOUNT_TYPE.commercial
            },
            ssn: {
                visible: false,
                path: 'TBD',
            }
        };

        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onSearchAccounts: isComponentValid ? this.searchAccounts : this.handleValidation,
                onCancel: this.handleCancel,
                newCustomerBtnClick: this.newCustomerBtnClick
            }

        };

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            );
        };

        return (
            <div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    onValidationChange={setComponentValidation}
                    onValueChange={this.writeValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    showErrors={showErrors}
                />
            </div>
        );
    }
}
const NewQuoteAccountSearch = withModalContext(NewQuoteAccountSearchWithoutModalContext);
export const NewQuoteAccountSearchComponent = NewQuoteAccountSearch;
export default withValidation(
    withViewModelService(withAuthenticationContext(withDependencies(['interactionModel'])(NewQuoteAccountSearch)))
);
