import React, {
    useState, useEffect, useContext, useCallback, useMemo
} from 'react';
import _ from 'lodash';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';

import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ServiceManager } from '@jutro/services';

import metadata from './EditLocation.metadata.json5';
import styles from './EditLocation.module.scss';

const locationDTOName = 'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.LocationDTO';

const EditLocation = (props) => {
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData, setLocationSubmission, updateSelectedLocation, onValidate, id
    } = props;
    const { submissionVM: initialSubmission, selectedLocation } = wizardData;
    const [locationSubmissionVM, updateLocationSubmissionVM] = useState(null);
    const [availableLocations, updateAvailableLocations] = useState(null);
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    const [selectedExistingLocation, updateSelectedExistingLocation] = useState('');
    const [locationType, setLocationType] = useState(
        _.get(selectedLocation, 'locationType') || 'existingLocation'
    );
    const localeService = ServiceManager.getService('locale-service');

    useEffect(() => onValidate(isComponentValid, id), [id, isComponentValid, onValidate]);

    useEffect(() => {
        const model = {
            address: {
                country: localeService.getDefaultCountryCode()
            }
        };
        const existingLocations = _.get(
            initialSubmission,
            'lobData.commercialProperty.coverables.locations.children',
            []
        );

        const filteredSubmission = existingLocations.find((loc) => {
            return loc.value.publicID === selectedLocation.location.publicID;
        });

        if (filteredSubmission) {
            _.set(filteredSubmission, 'value', selectedLocation.location);
            updateLocationSubmissionVM(filteredSubmission);
        } else {
            const locationSubmission = viewModelService.create(model, 'pc', locationDTOName);
            updateLocationSubmissionVM(locationSubmission);
        }

        const locationValues = existingLocations.map((loc) => {
            return loc.value;
        });
        updateAvailableLocations(locationValues);
        updateSelectedLocation(locationType, 'locationType');
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(locationSubmissionVM);
            if (path === 'locationType') {
                setLocationType(value);
                if (value === 'newLocation') {
                    setLocationSubmission(newSubmissionVM, 'newLocation');
                }
            } else {
                _.set(newSubmissionVM, path, value);
                updateLocationSubmissionVM(newSubmissionVM);
                setLocationSubmission(newSubmissionVM, 'newLocation');
            }
        },
        [viewModelService, locationSubmissionVM, setLocationSubmission]
    );

    const existingLocationsOptions = useMemo(() => {
        const existingLocations = initialSubmission
            && _.get(initialSubmission, 'lobData.commercialProperty.coverables.locations.value');

        return existingLocations.map((location) => {
            const { address, displayName } = location;

            const addressDetails = {
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                addressLine3: address.addressLine3,
                city: address.city,
                state: address.state,
                postalCode: address.postalCode
            };

            const formattedAddress = Object.values(addressDetails)
                .filter((data) => data)
                .join(', ');

            return {
                code: displayName,
                name: formattedAddress
            };
        });
    }, [initialSubmission]);

    const selectExistingLocation = useMemo(() => {
        const locationsCount = availableLocations ? availableLocations.length : 0;
        if (locationsCount === 0) {
            return '';
        }

        const location = locationsCount > 1
            ? _.get(selectedLocation, 'location.displayName', availableLocations[0].displayName)
            : _.get(availableLocations[locationsCount - 1], 'displayName');

        return location || '';
    }, [availableLocations, selectedLocation]);

    const toggleExistingLocation = useCallback(
        (value) => {
            updateSelectedExistingLocation(value);
            const existingLocations = _.get(
                initialSubmission,
                'lobData.commercialProperty.coverables.locations.value',
                []
            );
            const chosenLocation = existingLocations.find((loc) => {
                return loc.displayName === value;
            });
            setLocationSubmission(chosenLocation, 'existingLocation');
        },
        [initialSubmission, setLocationSubmission]
    );

    const toggleLocation = useCallback(
        (value) => {
            if (value === 'existingLocation') {
                const existingLocations = _.get(
                    initialSubmission,
                    'lobData.commercialProperty.coverables.locations.value'
                );
                const chosenLocation = existingLocations.find((loc) => {
                    return loc.displayName === selectedExistingLocation || selectExistingLocation;
                });
                setLocationSubmission(chosenLocation, value);
            } else {
                const newSubmissionVM = viewModelService.clone(locationSubmissionVM);
                const intialModel = {
                    address: {
                        country: localeService.getDefaultCountryCode()
                    },
                };
                _.set(newSubmissionVM, 'value', intialModel);
                updateLocationSubmissionVM(newSubmissionVM);
            }
            setLocationType(value);
            updateSelectedLocation(value, 'locationType');
        },
        [initialSubmission, localeService, locationSubmissionVM,
            selectExistingLocation, selectedExistingLocation,
            setLocationSubmission, updateSelectedLocation, viewModelService]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        locationToggle: {
            value: locationType,
            showOptional: false,
            onValueChange: toggleLocation
        },
        exisitngLocationContainer: {
            visible: locationType === 'existingLocation'
        },
        newLocationContainer: {
            visible: locationType === 'newLocation'
        },
        exisitingLocation: {
            availableValues: existingLocationsOptions,
            value: selectedExistingLocation || selectExistingLocation,
            onValueChange: toggleExistingLocation,
            showOptional: false
        },
        phoneNumber: {
            countryCode: localeService.getDefaultPhoneCountry()
        }
    };

    const readValue = useCallback(
        (elementID, path) => {
            return readViewModelValue(
                metadata.pageContent,
                locationSubmissionVM,
                elementID,
                path,
                overrideProps
            );
        },
        [locationSubmissionVM, overrideProps]
    );

    if (!locationSubmissionVM) {
        return null;
    }

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={locationType === 'existingLocation' ? {} : locationSubmissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onModelChange={updateLocationSubmissionVM}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

EditLocation.propTypes = {
    id: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    setLocationSubmission: PropTypes.func.isRequired,
    updateSelectedLocation: PropTypes.func.isRequired,
    ...wizardProps
};

EditLocation.defaultProps = {
    id: 'EditLocation'
};

export default EditLocation;
