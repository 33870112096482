import React from 'react';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import WniImageCheckboxField from './ImageCheckboxField';

function WniImageCheckboxGroup(props) {
    const {
        availableValues = [],
        value = [],
        onValueChange = _.noop,
        disabled,
        className
    } = props;
    const handleCheckboxField = (val, code) => {
        if (val) {
            const checkedValues = value.concat([code]);
            onValueChange(checkedValues);
        } else {
            const checkedValues = value.filter((v) => v !== code);
            onValueChange(checkedValues);
        }
    };

    return (
        <Flex justifyContent="left" gap="small" className={`imageCheckboxGroup ${className}`}>
            {availableValues.map((item) => {
                const isChecked = value.some((v) => v === item.code);
                return (
                    <WniImageCheckboxField
                        key={item.code}
                        {...item}
                        value={isChecked}
                        disabled={item.disabled || disabled}
                        onValueChange={(val) =>
                            handleCheckboxField(val, item.code)
                        }
                    />
                );
            })}
        </Flex>
    );
}

export default WniImageCheckboxGroup;
