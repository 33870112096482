
import React, {
    useCallback, useState, useEffect
} from 'react';
import { Button, ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './HouseholdResidentsPopup.metadata.json5';
import messages from './HouseholdResidentsPopup.messages';
import styles from './HouseholdResidentsPopup.module.scss';

const ANI_RELATIONSHIP_AVAILABLE_VALUES = ['Insured_Ext', 'Spouse_Ext', 'Partner_Ext'];
const DP_RELATIONSHIP_TO_INSURED = ['Insured_Ext' ,'Spouse_Ext', 'Partner_Ext', 'OtherFamilyMember_Ext', 'Other_Ext'];
const CHILD_UNDER_12_YEAR_OLD = 'Child_Under_12_Ext';

/**
 * Dummy Component provides a blank template  that based on which
 * new component could be created easily
 * @param {object} props
 * @returns {object} React Component
 */
function HouseholdResidentsPopup(props) {
    const {
        contactVM,
        onValueChange,
        title,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        size,
        exsitingContactsOptions,
        editMode,
        baseState,
        nextButtonVisible,
        saveAndNextMessageLabel,
        // handleSaveAndNext,
        defaultList,
        selectedContactPublicID,
        viewModelService,
        translator,
        productCode,
        isDPLine,
        displayFields: {
            additionalNamedInsured: showAdditionalNamedInsured = false,
        },
    } = props;

    const [list, updateList] = useState(defaultList)

    const[customPublicID, setCustomPublicID] = useState(selectedContactPublicID)
    const dtoVM = list.find((elt) => elt.publicID.value === customPublicID)

    // const [dtoVM, updateDtoVM] = useState(contactVM);

    const updateDtoVM = (newDtoVM) => {
        const newList = _.clone(list)
        const currentList = newList.map((item) => {
            if (item.value.publicID === customPublicID) {
                return newDtoVM
            }
            return item
        })
        updateList(currentList)
    }

    const handleSaveAndNext = () => {
        const currentIndex =  list.findIndex((elt) => elt.value.publicID === customPublicID)
        let nextIndex = ''
        if (currentIndex < list.length-1) {
            nextIndex = currentIndex+1
        } else {
            // when reach the last line,back to to second one(except the PNI line)
            nextIndex = 1
        }
        const nextCustomPublicID= list[nextIndex].value.publicID
        setCustomPublicID(nextCustomPublicID)
    }

    const [selectedContact, updateSelectedContact] = useState();
    const  yesNoavailabelValues = [
        {
            code: 'true',
            name: 'Yes'
        },
        {
            code: 'false',
            name: 'No'
        }
    ];
    const [relationshipToInsuredOptions, updateRelationshipToInsuredOptions] = useState([]);
    const { isComponentValid, onValidate } = useValidation('HouseholdResidentsPopUp');
    const [showErrors, updateShowErrors] = useState(false);

    const filterRelationshipToInsured = useCallback(() => {
        const relationshipToInsuredValues = _.get(contactVM, 'relationshipToInsured_Ext.aspects.availableValues', []);
        if (productCode === 'DwellingProperty') {
            return relationshipToInsuredValues
                .filter((role) => {
                    return DP_RELATIONSHIP_TO_INSURED.includes(role.code);
                })
                .map((role) => {
                    return {
                        code: role.code,
                        name: {
                            id: role.name
                        }
                    };
                });
            }
        
        return relationshipToInsuredValues
            .filter((role) => role.code !== "Child_Ext")
            .map((role) => {
                return {
                    code: role.code,
                    name: {
                        id: role.name
                    }
                };
            });
    }, [contactVM, productCode]);

    const filterAddlRelationshipToInsured = useCallback(() => {
        const relationshipToInsuredValues = _.get(contactVM, 'relationshipToInsured_Ext.aspects.availableValues', []);
        return relationshipToInsuredValues
            .filter((role) => {
                return ANI_RELATIONSHIP_AVAILABLE_VALUES.includes(role.code);
            })
            .filter((role) => role.code !== "Child_Ext")
            .map((role) => {
                return {
                    code: role.code,
                    name: {
                        id: role.name
                    }
                };
            });
    }, [contactVM]);

    useEffect(() => {
        const isAdditionalNamedInsured = _.get(dtoVM, 'value.additionalNamedInsured');
        let relationshipToInsuredDropDownOptions = []
        if (!isDPLine) {
            relationshipToInsuredDropDownOptions =
                isAdditionalNamedInsured ? filterAddlRelationshipToInsured() : filterRelationshipToInsured();
        } else {
            relationshipToInsuredDropDownOptions = filterRelationshipToInsured()
        }
        updateRelationshipToInsuredOptions(relationshipToInsuredDropDownOptions);
    }, [_.get(dtoVM, 'value.additionalNamedInsured')]);


    const writeValue = useCallback((value, path) => {
        const newVM = viewModelService.clone(dtoVM);
        _.set(newVM, `value.${path}`, value)
        updateDtoVM(newVM);
    }, [dtoVM, viewModelService]);

    const changeContact = useCallback((value) => {
        updateSelectedContact(value);
        const newVM = viewModelService.clone(dtoVM);
        if (value !== 'new') {
            // update values for selected COntact
            const person = JSON.parse(value);
            _.set(newVM, 'value.publicID', _.get(person, 'publicID'));
            _.set(newVM, 'value.firstName', _.get(person, 'firstName'));
            _.set(newVM, 'value.lastName', _.get(person, 'lastName'));
            if (_.get(person, 'dateOfBirth')) {
                _.set(newVM, 'value.dateOfBirth', _.get(person, 'dateOfBirth'));
            }
            _.set(newVM, 'value.relationshipToInsured_Ext', _.get(person, 'relationshipToInsured_Ext'));
            _.set(newVM, 'value.subType', _.get(person, 'subType'));
            if (_.get(person, 'subType') === 'PolicyAddlNamedInsured') {
                _.set(newVM, 'value.additionalNamedInsured', true);
            } else {
                _.set(newVM, 'value.additionalNamedInsured', false);
            }
            if (_.get(person, 'westernNationalEmployee')) {
                _.set(newVM, 'value.westernNationalEmployee', _.get(person, 'westernNationalEmployee'));
            } else {
                _.set(newVM, 'value.westernNationalEmployee', false);
            }
        } else {
            // remove publicID for New Contact
            _.set(newVM, 'value.publicID', null);
        }
        updateDtoVM(newVM);
    }, [dtoVM, updateDtoVM, viewModelService]);

    const changeAdditionalNamedInsured = useCallback((value) => {
        const newVM = viewModelService.clone(dtoVM);
        if (value === true) {
            _.set(newVM, 'value.additionalNamedInsured', true);
            _.set(newVM, 'value.subType', 'PolicyAddlNamedInsured');
        } else {
            _.set(newVM, 'value.additionalNamedInsured', false);
            _.set(newVM, 'value.subType', 'PolicyHouseholdResident_Ext');
        }
        updateDtoVM(newVM);
    }, [dtoVM, viewModelService]);

    const changeRelationshipToInsured = useCallback((value, path) => {
        const newVM = viewModelService.clone(dtoVM);      
        if ((value === 'OtherFamilyMember_Ext' || value === 'Other_Ext') && productCode === "DwellingProperty"){
            _.set(newVM, 'value.westernNationalEmployee', null)
        }
        if (value === 'Insured_Ext') {
            _.set(newVM, `value.${path}`, value);
            if (productCode === 'HOPHomeowners') {
                _.set(newVM, 'value.additionalNamedInsured', true);
            }
            if (_.isNil(_.get(newVM, 'value.westernNationalEmployee'))) {
                _.set(newVM, 'value.westernNationalEmployee', false)
            }
            _.set(newVM, 'value.subType', 'PolicyAddlNamedInsured');
            updateDtoVM(newVM);
        } else if ((value === 'Spouse_Ext' || value === 'Partner_Ext') && _.isNil(_.get(newVM, 'value.westernNationalEmployee'))){
            _.set(newVM, `value.${path}`, value);
            _.set(newVM, 'value.westernNationalEmployee', false)
            updateDtoVM(newVM);
        } else {
            writeValue(value, path);
        }
    }, [dtoVM, productCode, updateDtoVM, viewModelService, writeValue]);

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        contact: {
            label: translator(messages.contactName),
            availableValues: exsitingContactsOptions,
            value: selectedContact,
            onValueChange: changeContact,
            visible: false
        },
        firstName: {
            label: translator(messages.firstName),
            path: 'firstName'
        },
        lastName: {
            label: translator(messages.lastName),
            path: 'lastName'
        },
        relationshipToInsured: {
            label: translator(messages.relationshipToInsured),
            path: 'relationshipToInsured_Ext',
            availableValues: relationshipToInsuredOptions,
            onValueChange: changeRelationshipToInsured
        },
        dateOfBirth: {
            label: translator(messages.dateOfBirth),
            path: 'dateOfBirth',
            required: _.get(dtoVM, 'dateOfBirth.aspects.required'),
            visible: _.get(dtoVM, 'value.relationshipToInsured_Ext') !== CHILD_UNDER_12_YEAR_OLD,
        },
        additionalNamedInsured: {
            label: translator(messages.additionalNamedInsured),
            availableValues: yesNoavailabelValues,
            path: 'additionalNamedInsured',
            onValueChange: changeAdditionalNamedInsured,
            visible: productCode === 'DwellingProperty' ? showAdditionalNamedInsured : (ANI_RELATIONSHIP_AVAILABLE_VALUES.includes(_.get(dtoVM, 'value.relationshipToInsured_Ext')))
        },
        westernNationalEmployee: {
            label: translator(messages.westernNationalEmployee),
            availableValues: yesNoavailabelValues,
            path: 'westernNationalEmployee',
            visible: baseState !== 'AK' && ANI_RELATIONSHIP_AVAILABLE_VALUES.includes(_.get(dtoVM, 'value.relationshipToInsured_Ext'))
        }
        
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        },
    };

    const handleSave = useCallback(() => {
        const listDto = list.map((elt) => elt.value)
        if (!isComponentValid) {
            updateShowErrors(true);
            return false;
        }
        // onResolve(_.get(dtoVM, 'value'));
        onResolve(listDto)
    }, [isComponentValid, list, onResolve]);

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            dtoVM,
            id,
            path,
            overrideProps
        );
    };
    //---------

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="householdResidentsPopup">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={dtoVM}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    // onModelChange={updateModelValue}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                {nextButtonVisible ? (
                    <Button onClick={handleSaveAndNext}>{saveAndNextMessageLabel}</Button>
                ) : null}
                <Button onClick={handleSave} type="filled">{actionBtnLabel}</Button>
            </ModalFooter>
        </ModalNext>
    )
}

HouseholdResidentsPopup.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onValueChange: PropTypes.func,
    showErrors: PropTypes.bool,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    size: PropTypes.string,
};

HouseholdResidentsPopup.defaultProps = {
    model: {},
    onValueChange: _.noop,
    showErrors: false,
    size: 'md',
    type: null,
    title: '',
    isOpen: true,
};

export default HouseholdResidentsPopup;
