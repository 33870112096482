import React, { useCallback, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card
} from '@jutro/components';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { CAVehicleService } from 'wni-capability-quoteandbind-ca';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { Flex } from '@jutro/layout';
import metadata from './ImportDriverModal.metadata.json5';
import messages from '../../CADriversPage.messages';

function ImportDriverModal(props) {
    const modalApi = useWniModal();
    const {
        size,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        translator,
        useDependenciesData,
        extendProps: { jobID, sessionUUID, authHeader },
    } = props;
    const { loadingMask: { setLoadingMask } } = useDependenciesData;
    const [file, updateFile] = useState(null);
    const [isUploadSuccess, updateIsUploadSuccess] = useState(false);
    const [uploadData, updateUploadData] = useState({});

    const getDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID =
                await GatewayDocumentService.generateUploadToken(authHeader);
            return uploadTokenID;
        } catch (e) {
            return modalApi
                .showAlert({
                    title: commonMessages.errorUploadTitle,
                    message: commonMessages.errorGenerateUploadToken,
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok,
                })
                .catch(_.noop);
        }
    }, [authHeader]);

    const uploadfile = (newfile) => {
        updateFile(newfile);
    };
    const handleImport = async () => {
        if (!file) {
            return false;
        }
        setLoadingMask(true);
        const documentMetaDataTemplate = {
            jobNumber: jobID,
            docUID: '001',
            documentType: 'Exc_Ext',
            status: 'approved',
            name: file.name,
            mimeType: file.type,
            sessionID: await getDocUploadToken(),
            sessionUUID: sessionUUID,
            page: 'driver',
        };
        const res = await CAVehicleService.upload(
            file,
            documentMetaDataTemplate,
            authHeader
        );
        updateIsUploadSuccess(true);
        updateUploadData(res);
        setLoadingMask(false);
        // if(vm.value) {
        //     onResolve(vm.value);
        // }
    };

    const handleAcceptChanges = () => {
        onResolve(uploadData.caDrivers);
    };

    const renderChanges = () => {
        const summaryLog = _.get(uploadData, 'summaryLog', []);
        return summaryLog.map((log, index) => {
            return (
                <Card
                    key={index}
                    className="wizardSection pv-0"
                    headerClassName="wizardTitle font-capitalize"
                    title={log.displayName}
                >
                    <Flex justifyContent="between">
                        <span className='font-NeutralUltraDark-light-14'>{translator(messages.driverRead, {driverType: log.displayName})}</span>
                        <div className='font-NeutralUltraDark-bold-14'>{log.beansRead}</div>
                    </Flex>
                    <Flex justifyContent="between">
                        <span className='font-NeutralUltraDark-light-14'>{translator(messages.driverEdited, {driverType: log.displayName})}</span>
                        <div className='font-NeutralUltraDark-bold-14'>{log.beansEdited}</div>
                    </Flex>
                    <Flex justifyContent="between">
                        <span className='font-NeutralUltraDark-light-14'>{translator(messages.driverAdded, {driverType: log.displayName})}</span>
                        <div className='font-NeutralUltraDark-bold-14'>{log.beansAdded}</div>
                    </Flex>
                    <Flex justifyContent="between">
                        <span className='font-NeutralUltraDark-light-14'>{translator(messages.driverRemoved, {driverType: log.displayName})}</span>
                        <div className='font-NeutralUltraDark-bold-14'>{log.beansRemoved}</div>
                    </Flex>
                </Card>
            );
        });
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
        uploadSection: {
            value: _.get(file, 'name', null),
        },
        importScreen: {
            visible: !isUploadSuccess,
        },
        uploadChanges: {
            data: _.get(uploadData, 'summaryLog', [])
        },
        changesSection: {
            visible: isUploadSuccess
        },
        acceptChangesSection: {
            // data: _.get(uploadData, 'summaryLog', [])
            content: renderChanges(),
        },
        fatalErrorCard: {
            visible: !_.isEmpty(_.get(uploadData, 'fatalErrors'))
        },
        fatalErrorMessage: {
            content:  _.get(uploadData, 'fatalErrors')
        },
        errorCard: {
            visible: !_.isEmpty(_.get(uploadData, 'errors'))
        },
        errorMessage: {
            content:  _.get(uploadData, 'errors')
        },
        warningCard: {
            visible: !_.isEmpty(_.get(uploadData, 'warnings'))
        },
        warningMessage: {
            content:  _.get(uploadData, 'warnings')
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            uploadfile,
        },
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="importVehicle">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            {!isUploadSuccess ? (
                <ModalFooter
                    contentLayout={{
                        component: 'flex',
                        componentProps: {
                            alignItems: 'middle',
                            justifyContent: 'between',
                        },
                    }}
                >
                    <Button onClick={onReject} type="outlined">
                        {cancelBtnLabel}
                    </Button>
                    <Button onClick={handleImport} disabled={!file}>Import</Button>
                </ModalFooter>
            ) : (
                <ModalFooter
                    contentLayout={{
                        component: 'flex',
                        componentProps: {
                            alignItems: 'middle',
                            justifyContent: 'right',
                        },
                    }}
                >
                    {/* <Button onClick={handleAcceptChanges} type="outlined">
                        {cancelBtnLabel}
                    </Button> */}
                    <Button onClick={handleAcceptChanges}>Accept Changes</Button>
                </ModalFooter>
            )}
        </ModalNext>
    );
}

ImportDriverModal.propTypes = {};
ImportDriverModal.defaultProps = {
    size: 'md',
};
export default ImportDriverModal;
