import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniProductsUtil } from 'wni-portals-util-js';

import metadata from './PoliciesSummaryDetail.metadata.json5';

function PoliciesSummaryDetail(props) {
    const {
        model
    } = props;

    const accountContactType = _.get(model, 'account.accountHolder.contactType_Ext')

    const getLinesIncluded = () => {
        const policyLines = _.get(model, 'latestPeriod.policyLines').map(item => WniProductsUtil.getPolicyLinePatternName(item.lineOfBusinessCode));
        return policyLines.join(', ');
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            preRenewalDirection: {
                visible: accountContactType !== 'person',
            },
            agencyOfRecord: {
                labelPosition: 'top',
                value: `${_.get(model, 'latestPeriod.producerCodeOfRecordOrg')}(${_.get(model, 'latestPeriod.producerCodeOfRecord')})`
            },
            agencyOfService: {
                labelPosition: 'top',
                value: `${_.get(model, 'latestPeriod.producerCodeOfServiceOrg')}(${_.get(model, 'latestPeriod.producerCodeOfService')})`
            },
            linesIncluded: {
                visible: _.get(model, 'product.productCode') === 'CommercialPackage',
                value: getLinesIncluded()
            }
        };
    }, [accountContactType, model]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={model}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PoliciesSummaryDetail.propTypes = {
    model: PropTypes.shape({}),
};
PoliciesSummaryDetail.defaultProps = {
    model: {}
};
export default PoliciesSummaryDetail;