import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Button } from '@jutro/components'
import { Flex } from '@jutro/layout'
import { useValidation } from '@xengage/gw-portals-validation-react';
import {React, useState, useCallback, useContext, useEffect} from 'react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CPRisksService, CPRetrieveRiskItemSummaryService } from 'wni-capability-quoteandbind-cp';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {ValidationIssuesComponent } from 'wni-components-platform-react';
import { ValidationIssueUtil } from 'wni-portals-util-js';
import metadata from '../Common/CommonRiskItemDetails.metadata.json5';
import DetailsFieldMap from '../Common/DetailsFieldMap'
import SpecClsBIClauses from '../SpecClsBIClauses';
import riskItemMessages from '../RiskItemComponent/RiskItemComponent.messages'


const SpecClsBI = (props) => {

    const translator = useTranslator();

    const {
        onValidate,
        isComponentValid,
    } = useValidation('SpecClsBI');

    const { authHeader } = useAuthentication();
    const [specClsBIClauses, setSpecClsBIClauses] = useState()
    const [showErrors, updateShowErrors] = useState(false)
    const [validationIssues, updateValidationIssues] = useState([]);
    const {
        riskItem,
        jobID,
        sessionUUID,
        submissionVM,
        updateSubmissionVM,
        isReadOnly,
        handleCloseRisk,
    } = props

    const {
        locationPublicID,
        specialClassPublicID,
        publicID: specClsBIPublicID,
        locationDescription,
        specialClassDescription
    } = riskItem

    const serviceProps = {
        jobID,
        sessionUUID,
        authHeader
    }

    const viewModelService = useContext(ViewModelServiceContext);
    const dtoName = 'wni.edge.capabilities.quote.lob.commercialproperty.dto.CPSpecialClassBusinessIncomeDTO';
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [currentRowVM, setCurrentRowVM] = useState({})

    const getSpecClsBIDetailsFunc = useCallback(async () => {
        const res = await CPRisksService.getSpecialClassBusinessIncomeDetails(jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, authHeader);
        return res
    }, [authHeader, jobID, locationPublicID, sessionUUID, specClsBIPublicID, specialClassPublicID])

    const updateSpecClsBIDetailsFunc = useCallback(async (dto) => {
        const [needsRefresh, notNeedRefresh] = _.partition(dto.specialClassBusinessIncomeDisplayables?.filter(elt => elt.updated), 'needsRefresh');
        const orderedDisplayables = notNeedRefresh.concat(needsRefresh);
        Object.assign(dto, {'changedSpecClsBIDisplayables': orderedDisplayables});
        const res = await CPRisksService.updateSpecialClassBusinessIncomeDetails(jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, dto, authHeader);
        return res
    }, [authHeader, jobID, locationPublicID, sessionUUID, specClsBIPublicID, specialClassPublicID])

    const getSpecClsBIClausesFunc = useCallback(async () => {
        const res = await CPRisksService.getSpecClsBIClauses(jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, authHeader)
        return res
    }, [jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, authHeader])

    const updateSpecClsBIClausesFunc = async (dto) => {
        const res = await CPRisksService.updateSpecClsBIClauses(jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, dto, authHeader)
        return res
    }

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true)
        const res = await serviceCallFunc()
        setLoadingMask(false)
        return res
    }


    const initFieldData = useCallback(async () => {
        const [
            specClsBIDto,
            newSpecClsBIClauses
        ] = await withLoadingMask(() => Promise.all([
            getSpecClsBIDetailsFunc(),
            getSpecClsBIClausesFunc()
        ]))
        
        const res = viewModelService.create(specClsBIDto, 'pc', dtoName);
        setSpecClsBIClauses(newSpecClsBIClauses)
        setCurrentRowVM(res)
        
    // !!! Never add setLoadingMask into dependency, which always different after render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSpecClsBIClausesFunc, viewModelService])



    const updateService = async(serviceData) => {
        const {
            specialClsBI: updatedDetailsDto,
            specialClsBIClauses: newSpecClsBIClauses
         } = await withLoadingMask(() => updateSpecClsBIDetailsFunc(serviceData));
        setSpecClsBIClauses(newSpecClsBIClauses);
        const displayables = updatedDetailsDto?.specialClassBusinessIncomeDisplayables;
        const clonedDisplayables = _.set(_.clone(updatedDetailsDto), 'specialClassBusinessIncomeDisplayables', displayables);
        const res = viewModelService.create(clonedDisplayables, 'pc', dtoName);
        setCurrentRowVM(res);
        return res
    };


  
    const writeValue = (fieldItem, path, fieldModel = {}) => {
        const newVm = viewModelService.clone(currentRowVM);
        _.set(newVm.value, path, fieldItem);
        setCurrentRowVM(newVm);
        _.set(currentRowVM.value, path, fieldItem);
        if(fieldModel.triggerFunc === 'onValueChange' && fieldItem.needsRefresh) {
            updateService(currentRowVM.value);
        }
    }

    const onBlur = (value, fieldItem) => {
        updateService(currentRowVM.value, value, fieldItem)
    };

    useEffect(() => {
        initFieldData()
    }, [initFieldData])

    const handleClose = async () => {
        if (isReadOnly) {
            handleCloseRisk((oldTreeRows) => oldTreeRows)
            return
        }
        if (!isComponentValid) {
            updateShowErrors(true)
            return
        }
        const newVm = viewModelService.clone(currentRowVM);
        const allUpdatedDisplayables = _.get(newVm, 'value.specialClassBusinessIncomeDisplayables', [])?.filter(elt => elt.updated);
        if (!_.isEmpty(allUpdatedDisplayables)) {
            updateService(newVm.value);
        }
        const specialClassTree = await withLoadingMask(() => CPRetrieveRiskItemSummaryService.retrieveSpecClsBISummary(
            jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, authHeader
        ))
        const newSpecClsBI = specialClassTree.specialClassBusinessIncomeTreeRows?.find(elt => elt.publicID === specClsBIPublicID);
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(newSpecClsBI.errorsAndWarnings);
        updateValidationIssues(newValidationIssues);
        const generateNewTiskTreeRowFunc = (oldRiskTreeRows) => {
            const newRiskTreeRows = _.clone(oldRiskTreeRows)
            const locationIndex = oldRiskTreeRows.findIndex(location => location.publicID === locationPublicID)
            const location = oldRiskTreeRows[locationIndex]
            const specialClasses = location.specialCLassTreeRows
            const specialClassIndex = specialClasses.findIndex(specialClass => specialClass.publicID === specialClassPublicID)
            _.set(newRiskTreeRows, `[${locationIndex}].specialCLassTreeRows[${specialClassIndex}]`, specialClassTree)

            return newRiskTreeRows
        }
        if (!_.isEmpty(newValidationIssues)) {
            updateShowErrors(true)
            return
        }
        handleCloseRisk((oldTreeRows) => generateNewTiskTreeRowFunc(oldTreeRows))
    }
    
    const overrideProps = {
        fieldSetModel: {
            vm: currentRowVM,
            dataPath: 'specialClassBusinessIncomeDisplayables',
            onValueChange: writeValue,
            // onSearch,
            onValidate,
            onBlur,
            showErrors,
            isReadOnly,
            // filterSizeClassMapsInChange
        },
        riskItemDetailsCard: {
            title: 'Special Class Business Income Details'
        },
        locationInput: {
            visible: true,
            value: locationDescription
        },
        specialClassInput: {
            visible: true,
            value: specialClassDescription
        }
    }
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            fieldsetmap: DetailsFieldMap
        }
    };
    return (
        <>
            <ValidationIssuesComponent validationIssues={validationIssues}/>
            <ViewModelForm
                uiProps={metadata.componentContent}
                // model={vehicleVM}
                overrideProps={overrideProps}
                // onValueChange={onValueChange}
                onValidationChange={onValidate}
                // resolveValue={readValue}
                // classNameMap={resolvers.resolveClassNameMap}
                // callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            <SpecClsBIClauses
            	riskItem={riskItem}
                serviceProps={serviceProps}
                submissionVM={submissionVM}
                updateSubmissionVM={updateSubmissionVM}
                updateClauseFunc={updateSpecClsBIClausesFunc}
                riskClauses={specClsBIClauses}
                setRiskClauses={setSpecClsBIClauses}
                showErrors={showErrors}
                isEditable={!isReadOnly}
                onValidate={onValidate}
            />
            <Flex justifyContent="right">
                <Button onClick={handleClose}>
                    {translator(riskItemMessages.Close)}
                </Button>
            </Flex>
        </>
    );
}

export default SpecClsBI